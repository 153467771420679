export const HANDS = [
  '2m3m4m3s3s3s4s4s4s5s6s8s8s+7s+23',
  '2m2m3m3m4m4m4m4m4s5s6s8s8s+8s+24',
  '7m7m7m6p6p6p8p3s4s5s7z7z7z7p+23',
  '6m7m8m3p3p5p6p7p4s4s4s5s6s+3p+13',
  '3p4p5p6p7p3s3s5s6s7s7s7s7s+2p+11',
  '2p2p2p3p3p3p4p5s5s6s6s7s7s+3p+24',
  '1p2p3p5p6p6p6p7p7p8p1z1z1z+6p+14',
  '4m4m4m5m6m6m6m6m3p4p6s7s8s2p+21',
  '1m2m3m4p4p4p5p6p6p7p8p1s1s4p+13',
  '2m2m3m4m5m5p6p7p4s5s6s7s7s+7s+11',
  '2m3m4m6s6s6s7s7s7s8s8s6z6z8s+12',
  '4m5m5m6m6m7m8m8m7s8s9s6z6z6z+14',
  '2m2m3m3m4m4m4s5s6s7s8s9s2z+2z+24',
  '1m2m2m3m3m4m3s3s4s4s4s4s5s+2s+24',
  '7m8m9m8p8p9p9p9p3s4s7z7z7z+5s+11',
  '2m2m7m8m9m5p6p6p7p7p7s8s9s+5p+12',
  '2m3m7p7p8p8p9p9p5s6s7s5z5z1m+14',
  '2m2m2m4m4m1p2p3p2s2s3s4s4s+3s+13',
  '2p3p3p3p5p5p5p2s2s2s5s6s7s+4p+14',
  '3m3m4m4m5m6p7p8p1s2s3s8s8s+2m+21',
  '5m5m5m6m7m1s2s2s3s3s3s4s5s4s+24',
  '3m4m5m3s4s5s5s5s6s7s7s8s8s9s+24',
  '4m5m6m1p2p3p7p8p8p9p9p5s5s+7p+21',
  '2m3m1p2p3p7p7p7s7s8s8s9s9s+4m+24',
  '2m3m4m6p7p8p1s2s3s8s8s8s9s9s+23',
  '2m3m3m3m3p4p5p7p8p9p6s7s8s+1m+11',
  '1p2p3p7p7p8p8p9p9p2s3s4s9s9s+22',
  '3m3m5m6m7m5p6p2s3s4s5s6s7s+7p+24',
  '5m6m7m3p4p6p6p2s3s4s5s6s7s+5p+24',
  '5m6m7m7m8m9m2p2p3s3s4s4s5s+5s+12',
  '1m2m3m8m8m2p3p4p6p7p8p3s4s2s+11',
  '3m4m4m5m6m4p5p6p7p7p6s7s8s+2m+13',
  '5m6m7m8m8m7p8p2s3s4s5s6s7s6p+33',
  '1m1m6m6m3p3p9p9p1s1s5s5s3z+3z+21',
  '1m2m3m2p3p4p4p5p6p5s6s4z4z+7s+11',
  '4m5m6m1p2p3p4p5p6p9p9p3s4s5s+14',
  '5p6p7p7p8p9p3s4s6s7s8s3z3z5s+12',
  '1p2p3p6p7p8p9p9p5s6s7s8s9s4s+24',
  '4m4m6m6m7m8m8m3s3s3s7z7z7z+7m+14',
  '5m6m7m1p2p4p5p6p6p6p7p8p9p3p+21',
  '1m2m3m1z1z1z2z2z3z3z3z7z7z2z+11',
  '3m4m6m7m8m4p5p6p2s3s4s6s6s+2m+21',
  '6m7m8m2p3p4p6p6p7p8p9p4s5s6s+23',
  '2m2m5m6m6m7m8m3p4p5p6p7p8p+4m+22',
  '2m3m4m5p6p7p1s1s2s3s5s6s7s+4s+12',
  '4m5m2p3p3p4p4p5p7p7p2s3s4s6m+14',
  '4m4m5m5m2p2p9p9p1z1z3z3z5z+5z+22',
  '4m5m6m4p5p6p7p8p9p7s8s3z3z9s+11',
  '1m2m3m7m8m9m9p9p6s7s7s8s9s8s+12',
  '4m5m6m7m9m1s1s2s2s3s3s4s4s+8m+21',
  '1m2m3m6m7m8m2p2p5p6p7s8s9s+7p+22',
  '5m6m7m2p3p4p4p5p6p6s6s8s8s+6s+11',
  '6m7m8m3p4p4p5p6p8p8p6s7s8s+2p+12',
  '2m2m7m7m6p6p7p7p8p8p4s4s4z+4z+11',
  '5m6m7m1p1p2p3p3p4s4s6s7s8s+2p+11',
  '5m6m7m8m8m3p4p5p3s4s5s6s7s2s+12',
  '2s3s4s5s5s6s6s7s9s9s3z3z3z7s+21',
  '4m5m6m2p2p2p3p4p5p8p8p4s6s5s+13',
  '1m2m3m4m4m7m8m9m5p6p7p4s5s+6s+22',
  '3m4m5m2p2p3p4p5p6p7p2s3s4s2p+23',
  '3m4m5m8m8m5p7p2s3s4s6s7s8s+6p+23',
  '2m4m4m5m6m3p4p5p2s3s4s5s5s+3m+11',
  '6m6m2p3p4p4p5p7p8p9p6s7s8s3p+23',
  '2m2m3m4m5m2p3p4p7p8p9p5s6s4s+12',
  '5m6m7m4p5p6p1s2s2s3s3s3z3z+1s+14',
  '4m5m6m7m7m5p6p6p7p7p1s2s3s+8p+22',
  '3m4m5m6m7m9m9m3p4p5p5s6s7s5m+11',
  '3p4p5p7p8p9p1s2s3s4s4s5s6s4s+11',
  '5m6m4p4p5p5p6p6p1s2s3s3s3s+7m+14',
  '3m4m5m3p3p4p4p5p2s2s5s6s7s+2p+13',
  '5m7m3p3p3p6p7p8p3s4s5s5s5s6m+21',
  '6m6m4p5p1s2s3s4s5s5s6s6s7s6p+14',
  '6m6m5p6p7p1s2s4s5s6s7s8s9s3s+13',
  '5m6m7m9m9m7p8p1s2s3s4s5s6s+9p+24',
  '5m6m6m7m7m8m8m9m7p7p4s5s6s+7m+22',
  '1m2m3m5m6m5s6s7s8s8s7z7z7z7m+11',
  '4m5m6m8m8m7p8p2s3s4s6s7s8s9p+23',
  '1m2m3m2p3p7p8p9p1s2s3s5s5s1p+11',
  '2m3m4m4m5m6m4p6p4s5s6s8s8s+5p+22',
  '3p4p5p6p7p3s4s5s6s7s8s8s8s5p+12',
  '1p2p3p4p5p7p7p5s6s7s7s8s9s+3p+11',
  '9m9m1p7p7p8p8p2s2s6s6s2z2z+1p+14',
  '3m4m5m2p3p4p5p5p6p7p8p6s8s7s+21',
  '5p5p7p8p2s3s4s5s6s7s7s8s9s+9p+24',
  '2m3m4m6m7m1p2p3p3s3s5s6s7s+5m+14',
  '1m1m2m3m4m4p5p5p6p6p7s7s7s+4p+24',
  '3m4m4m5m5m6m7m7m2p3p3s4s5s+4p+23',
  '1m1m5m6m7m2p3p4p2s3s5s6s7s+4s+23',
  '2m3m5m6m7m4p5p6p5s6s7s8s8s+1m+12',
  '2m3m4m5m6m7m3p3p1s2s3s5z5z+5z+13',
  '4m5m6m7m7m3p4p5p5p6p7p4s5s3s+22',
  '1m1m9p9p4s4s6s6s9s2z2z7z7z9s+11',
  '2m3m4m4p4p6p7p3s4s5s6s7s8s8p+21',
  '4m5m6m7m8m9m1p2p3p8p8p4s5s3s+11',
  '1m2m3m4p5p6p2s3s4s7s8s9s9s6s+14',
  '2m3m3m4m4m1p1p1s2s3s5s5s5s+2m+14',
  '4m5m6m2p2p4p5p7p8p9p6s7s8s+3p+24',
  '2m3m4m2p3p7p8p9p3s4s5s7s7s+4p+22',
  '4m5m6m9m9m5p6p7p3s4s5s6s7s+2s+14',
  '1m2m3m5m6m7m2s3s4s8s5z5z5z+8s+23',
  '7m7m9m6p6p6s6s8s8s1z1z5z5z+9m+14',
  '3m5m4p5p6p6p6p2s3s4s5s6s7s4m+11',
  '3m3m3m7m7m7p8p9p1z1z7z7z7z1z+11',
  '3m4m5m7m7m3p4p6p7p8p5s6s7s5p+11',
  '1m2m3m5m6m1p1p4s5s6s7s8s9s7m+14',
  '3m4m5m7m8m4p5p6p4s5s6s8s8s9m+22',
  '2m3m5m5m7m8m9m4s5s6s7s8s9s+1m+14',
  '7m8m2p3p4p4p4p6p7p8p4s5s6s+9m+14',
  '1m5m5m5p5p6p6p3s3s6s6s9s9s+1m+13',
  '5m6m7m7m8m9m5p6p7p9p5s6s7s+9p+11',
  '2m3m4m7m7m2p3p4p2s3s4s8s8s7m+12',
  '3m4m5m6m7m8m4p4p3s3s3s5s5s+4p+13',
  '4m5m7m7m4p5p6p7p8p9p6s7s8s3m+13',
  '7m8m4p5p6p7p8p9p2s3s4s9s9s+9m+11',
  '2m3m4m7m7m7m7p8p3s4s5s7s7s6p+12',
  '2m3m4m5m6m7m8m8m6p7p7s8s9s+5p+12',
  '2m3m4m4m5m4p5p6p8p8p4s5s6s6m+13',
  '2m3m6m7m8m3p4p5p2s2s6s7s8s1m+22',
  '7m8m9m5p6p7p7p8p4s5s6s9s9s+6p+22',
  '2m2m2m3m4m5m6m7m4p5p6p7s8s+9s+13',
  '3p3p5p5p8p8p2s6s6s8s8s9s9s2s+14',
  '4m5m6m7m8m3p4p5p2s3s4s1z1z+6m+22',
  '3m4m6m7m8m4p4p6p7p8p2s3s4s+2m+22',
  '1m2m3m5m6m7m1p2p3p2s3s4z4z1s+13',
  '3m4m5m5m6m1p2p3p5p5p5s6s7s+7m+13',
  '3m4m1p2p3p5p6p7p6s6s2z2z2z2m+22',
  '4m5m6m7m8m9m2p3p4p6p7z7z7z6p+14',
  '5m6m7m2p3p4p4p5p6p3s4s9s9s5s+23',
  '2m2m5m7m3p4p5p4s5s6s7s7s7s6m+23',
  '1p2p3p5p6p7p8p9p2z2z2z3z3z7p+23',
  '6m7m8m1p2p3p6p7p8p3s3s5s6s4s+21',
  '2m3m5m6m7m3p4p5p7s8s9s3z3z1m+22',
  '5m7m1p2p3p4p5p6p7p8p9p7z7z6m+12',
  '3m4m5m6m7m8m6p8p3s3s6s7s8s7p+22',
  '3m4m5m3p4p5p8p8p3s5s6z6z6z+4s+11',
  '4m4m7m8m4p5p6p1s2s3s7s8s9s6m+12',
  '3m5m6m7m8m2p3p3p3p4p8p8p8p4m+12',
  '2p2p3p4p5p2s2s4s5s6s7z7z7z+2s+12',
  '4m5m6m2p3p6p7p8p5s6s7s2z2z+4p+14',
  '4m5m6m2p3p4p4p4p2s3s4s4s5s6s+23',
  '1m2m3m6m6m2p3p2s3s4s6s7s8s+4p+11',
  '3m4m5m7m8m9m2p2p7p8p3s4s5s+9p+24',
  '5m6m7m6p6p7p7p8p8p2s4s2z2z3s+21',
  '1m2m3m5m6m7m3p4p7s8s9s3z3z5p+12',
  '2m3m4m7m8m9m1p2p3p4p4p5p6p7p+14',
  '1m2m3m4m4m5m5m6m6m8m9m7s7s7m+24',
  '1m2m3m3p4p5p1s2s3s4s4s7s8s6s+14',
  '2m3m4m6m7m8m2p3p4p7p7p5s6s7s+24',
  '4m4m7m7m3p3p8p8p9p9p2s2s6z6z+21',
  '2m2m3m3m4m6p7p8p2s3s4s5s5s1m+11',
  '4m5m6m2p3p4p4s4s5s5s6s6s7s+7s+22',
  '5m6m7m4p5p6p8p8p4s5s5s6s6s+7s+11',
  '1m2m3m3m4m5m6m7m7m7m9m9m9m6m+23',
  '4p4p7p7p4s6s6s7s7s2z2z3z3z+4s+22',
  '1m1m4m5m6m7m8m3p4p5p1s2s3s9m+23',
  '1m1m2m3m5m6m7m3p4p5p6s7s8s+1m+23',
  '6m6m4p5p6p2s3s4s4s6s2z2z2z5s+21',
  '2m3m4m7m8m8m9m9m5p5p4s5s6s7m+14',
  '1m2m3m7p8p9p4s4s5s6s7s6z6z6z+22',
  '3m4m5m5m6m5p5p2s3s3s4s4s5s+7m+23',
  '1m1m1m5m6m7m3p3p5p6p7p6s7s+5s+13',
  '2m3m4m1p2p3p7p7p8p8p9p2s2s6p+11',
  '4p4p4p7p7p9p9p9p4s4s4s7s8s+9s+13',
  '3m4m5m6m7m8m2p3p3p4p5p5s5s+1p+11',
  '4m5m6m5p5p1s2s3s4s5s7s8s9s3s+11',
  '1m2m3m4m5m6m7m8m9m6p7p5s5s5p+21',
  '3m3m8m6p6p7p7p8p8p3s3s1z1z+8m+24',
  '3m4m6m6m2p3p3p4p4p5p5s6s7s5m+14',
  '3m3m2p3p5p6p7p2s3s4s6s7s8s+1p+14',
  '6m7m8m2p3p4p4p5p2s3s4s4s4s+3p+23',
  '2m2m4m4m6m7m8m2p3p4p5s6s7s+4m+21',
  '2p3p8p8p1s2s3s3s4s5s6s7s8s+4p+11',
  '6m6m7m8m9m7p8p9p5s6s6s7s7s+8s+12',
  '5m7m8m9m2s2s3s3s4s4s9s9s9s5m+21',
  '2m2m3m3m6m7m8m2p3p4p4s5s6s2m+11',
  '2m3m4m6m6m8m8m8m6p7p8p7s8s6s+13',
  '6m7m8m2p2p2p4p4p5p5p6p1z1z+6p+21',
  '4p5p1s2s3s3s4s5s7s8s9s3z3z+6p+12',
  '1m2m3m6m6m7m7m8m9m9m1p2p3p5m+12',
  '1m2m3m3p4p6p6p1s2s3s3s4s5s+2p+21',
  '1m2m3m2p3p3p4p4p3s4s5s7s7s+2p+12',
  '5m7m5p6p7p3s3s3s4s5s6s8s8s+6m+14',
  '3m3m3p3p8p8p6s6s7s7s9s9s5z5z+11',
  '2m3m7m8m9m3p4p5p6p6p6z6z6z+1m+24',
  '5m5m5m7m8m9m7p7p2s3s3s4s4s+2s+23',
  '4m5m6m1p1p2p3p3p4p5p5s6s7s1p+13',
  '9m9m9m6p6p6p2s2s4s4s5s5s5s+2s+13',
  '4m5m6m7m8m1s1s2s3s4s6s7s8s+9m+14',
  '1m1m5m6m7m7m8m9m6p7p8p2s3s+4s+14',
  '6m6m2p3p4p4p5p2s3s4s6s7s8s+6p+14',
  '3m4m5m7m7m3p4p2s3s4s5s5s5s+2p+23',
  '1m2m3m9m9m3p4p5p1s2s3s5s6s+4s+14',
  '4m5m2p2p1s2s3s4s5s6s7s8s9s3m+21',
  '5m6m7m2p2p6p7p8p3s4s4s5s6s+5s+12',
  '1m1m6m7m8m2p3p4p6p7p8p6s7s5s+14',
  '3m3m7m8m9m4p5p6p6p7p8p3s4s2s+23',
  '1m2m3m4m5m9m9m6p7p8p4s5s6s+6m+13',
  '2m3m5m6m7m1s2s3s5s5s6s7s8s+4m+12',
  '1m2m3m6p7p8p9p9p4s4s5s5s6s3s+14',
  '1m2m3m3p3p4p5p1s2s3s7s8s9s6p+13',
  '2m3m2p3p4p6p6p2s3s4s5s6s7s+4m+22',
  '2m3m4m4m7m8m9m3p4p5p1s2s3s4m+22',
  '4m5m6m9m9m4p5p6p7p8p9p6s7s+8s+13',
  '3m4m5m6m7m3p4p5p6p6p3s4s5s8m+23',
  '5p6p7p3s5s6s7s8s1z1z5z5z5z4s+14',
  '3m4m5m3p3p5p6p7p1s2s3s7s8s+9s+22',
  '1m2m3m4m4m6m7m8m3p4p6s7s8s+5p+14',
  '9m9m9m2p2p3p4p4p3s4s5s8s8s3p+14',
  '1m1m3m4m2p3p4p4s5s6s6s7s8s+5m+23',
  '2p3p5p6p7p2s2s2s3s4s5s6s7s+1p+23',
  '1p2p3p4p4p4p5p6p7p8p9p4s5s+3s+22',
  '5m5m3p3p2s3s4s5s6s7s7z7z7z+5m+21',
  '5m3p4p5p2s3s4s4s5s6s6s7s8s+5m+12',
  '4m5m4p5p5p6p6p7p4s4s5s6s7s+3m+11',
  '2m3m4m7m7m3p4p7p8p9p1s2s3s2p+12',
  '7m8m9m1p2p3p1s2s3s5s5s1z1z+1z+11',
  '1m2m3m6m7m8m2s3s4s5s5s7z7z7z+12',
  '1m1m7m7m8m2p2p8p8p7s7s4z4z+8m+23',
  '3m4m7p7p3s4s5s6s7s8s2z2z2z+5m+21',
  '2p2p7p8p9p3s3s4s4s5s7z7z7z+5s+12',
  '2m3m4m3p4p7p8p9p2s3s4s5s5s+5p+11',
  '4m5m5p6p7p7p7p6s7s8s5z5z5z6m+13',
  '3m4m4p5p6p7p7p2s3s3s4s4s5s+2m+12',
  '5m6m7m5p6p5s6s7s1z1z1z4z4z4p+21',
  '2m3m4m7m8m9m7p7p3s4s7s8s9s2s+14',
  '4m5m6m3p4p5p3s4s4s5s5s7s7s+6s+12',
  '4m5m6m3p4p7p8p9p4s5s5s5s6s2p+12',
  '6m7m5p6p7p3s4s5s5s6s7s1z1z5m+21',
  '6m7m1p2p3p4p5p6p4s4s6s7s8s8m+11',
  '8m8m2p3p4p4p5p6p3s4s5s7s8s6s+12',
  '1m2m3m1p2p4p5p6p1s2s3s9s9s+3p+14',
  '1m2m3m7m8m9m3p4p5p6s7s9s9s+8s+22',
  '3m4m5m1p1p5p6p7p2s3s7s8s9s1s+13',
  '1m2m3m3m4m7m7m4p5p6p7p8p9p+5m+22',
  '3m4m5m3p3p3s4s5s1z1z7z7z7z+3p+12',
  '1m1m5m6m7m2p3p4p6s6s7s7s8s+5s+21',
  '1m3m4p5p5p6p6p7p4s4s2z2z2z2m+24',
  '1m2m3m4m4m7m8m9m3p4p5p2s3s4s+23',
  '3m4m5m5m6m7m8m9m9s9s7z7z7z+7m+14',
  '1m2m3m4m4m6m7m7p8p9p2s3s4s+8m+23',
  '6m7m9m9m7p8p9p4s5s6s7s8s9s8m+21',
  '2m2m5m6m7m6p8p2s3s4s6z6z6z+7p+11',
  '4m5m1s2s3s4s4s5s5s6s6s8s8s+6m+24',
  '8m8m2p3p4p5p6p7p3s4s7z7z7z+5s+21',
  '4m5m6m7m8m2p2p4p5p6p7s8s9s9m+12',
  '2m3m3m4m5m6m7m8m2s3s4s3z3z+1m+14',
  '2p3p4p7p8p1s2s3s4s5s6s7s7s6p+13',
  '2m3m4m4m5m6m3p3p5p5p2s3s4s3p+23',
  '1m2m3m5m6m7m1s1s5s5s6s6s7s4s+23',
  '3m4m4p5p6p6p7p8p3s3s5s6s7s2m+13',
  '1m2m3m4p5p6p1s2s3s5s6s9s9s+4s+14',
  '2m2m1s1s2s2s3s3s4s6s7s8s9s+5s+11',
  '2p3p4p1s2s3s4s5s6s7s8s9s4z+4z+21',
  '3m4m5m2p3p4p5p5p5p7p7p5s6s7s+22',
  '1m2m2m3m3m4m2s3s5s5s7s8s9s+1s+13',
  '2m3m4m5m6m7m3p5p3s3s3s6s6s4p+21',
  '5m6m7m7m4s5s6s6s7s7s8s8s9s4m+22',
  '6m6m7m8m9m2p3p4p5s5s3z3z3z5s+13',
  '3m3m4m5m6m2p3p4p1s2s3s5s6s7s+11',
  '5m6m7m9m5p6p7p4s4s4s2z2z2z9m+22',
  '1m2m3m3p4p4p5p5p6s7s8s9s9s6p+24',
  '7m8m9m1p2p3p5p6p3s4s5s9s9s4p+22',
  '6m7m8m3p4p5p7p8p9p2s2s5s6s+4s+12',
  '3m4m5m1p2p3p5p6p2s3s4s7s7s+4p+23',
  '3m3m4m5m6m8m8m8m4s4s4s5z5z+5z+23',
  '3m4m5m6m7m8m3p4p6p7p8p9s9s5p+12',
  '2m3m4m4m5m7m7m3p4p5p1s2s3s+6m+13',
  '6m6m7m7m8m2p3p4p5p5p2s3s4s+5m+13',
  '2m3m4m6m7m8m4p5p6p7p2s3s4s4p+23',
  '4m5m6m2p3p4p2s2s3s4s5s5s6s7s+11',
  '2m3m1p2p3p3s4s5s7s7s2z2z2z4m+22',
  '4m4m3p3p4p4p3s3s7s8s8s9s9s+7s+21',
  '2m3m6m7m8m2p3p4p8p8p5s6s7s1m+11',
  '2m3m3m4m4m5m3p4p2s3s4s8s8s+2p+24',
  '4m4m6m6m9m9m7p8p8p1s1s7s7s7p+12',
  '1m1m5m5m5p5p8p9p9p1s1s5s5s8p+12',
  '5m6m6m7m8m4s4s5s6s6s7s7s8s+7m+11',
  '5m6m6m7m7m8m4p4p4p6p8p7s7s7p+21',
  '2m3m4m4p5p5p6p7p5s5s5s6s7s+6p+11',
  '4m4m4m5m7p7p8p8p9p9p4s5s6s+5m+22',
  '7m8m9m5p6p7p3s4s5s5s5s6s7s8s+13',
  '2m2m6m7m8m2p3p4p7p8p4s5s6s+9p+24',
  '3m4m5m3p4p5p7p7p2s3s6s7s8s4s+23',
  '2m3m4m5m5m5m6m7m1p2p3p7p8p+6p+24',
  '1m1m6m6m8m8m9m5p5p8p8p1z1z9m+14',
  '3m4m1p2p3p4p4p7p8p9p4s5s6s+5m+23',
  '2m3m4m1p2p3p5p6p7p8p9p2s2s+7p+11',
  '3m4m5m6m6m6m7p8p4s4s6s7s8s6p+21',
  '9m9m1p1p3p3p6p6p1s1s5s5s9s+9s+21',
  '1m1m3m3m4m4m7m7m3z3z6z7z7z+6z+11',
  '6m7m8m2p3p4p2s3s4s4s5s6s7s+1s+24',
  '6m7m8m5p6p7p3s4s6s7s8s9s9s2s+21',
  '1m2m3m5m6m2p2p7p8p9p5s6s7s+4m+14',
  '6m7m8m1p2p3p3p4p5p4s5s7s7s6s+24',
  '4m5m6m3p5p7p7p4s4s5s5s6s6s+4p+21',
  '2m3m4m3p4p5p7p7p8p8p2s3s4s+8p+24',
  '1m2m3m5m5m7m8m9m7p8p9p7s9s+8s+13',
  '1m1m6m6m5p5p6s6s4z5z5z6z6z4z+22',
  '3m4m5m7m8m3p4p5p6s7s8s4z4z+9m+23',
  '3p3p8p8p8p4s6s7s7s8s8s9s9s+5s+11',
  '2m3m6m7m8m2p3p4p2s3s4s5s5s+1m+23',
  '2m2m6m7m8m5p6p6p7p7p8p8p9p+4p+33',
  '3s3s4s5s6s6s7s8s8s9s4z4z4z+7s+21',
  '5m5m6m7m8m3p3p4p4p5p6s7s8s+2p+24',
  '3m3m3m5m5m7m7m8m8m9m4p5p6p9m+11',
  '7m8m9m1p2p3p6p6p7p8p8p2s2s7p+21',
  '2m3m4m6m7m8m6p7p8s8s6z6z6z5p+24',
  '2m3m4m6m7m8p8p1s2s3s6s7s8s8m+23',
  '5m6m2p3p4p5p6p7p2s2s4s5s6s+4m+14',
  '1m2m3m4p5p8p8p1s2s3s5s6s7s6p+13',
  '2m3m4m5m5m3p4p5s5s5s5s6s7s5p+24',
  '2m3m4m3p3p4p5p6p8p8p6s7s8s+8p+24',
  '3m3m9m9m9m2p2p8p8p8p3s3s3s2p+24',
  '3m3m4m5m6m7m8m9m7p8p9p5s6s+7s+12',
  '1m2m3m6m7m8m1p2p3p4p5p5s5s6p+22',
  '2m3m4m6m7m6p7p8p4s4s6s7s8s+8m+22',
  '5m6m7m5p6p7p8p8p2s3s6s7s8s+4s+11',
  '4m5m6m6p7p8p9p9p1s2s3s4s5s+3s+24',
  '1m2m3m4m4m6m7m8m5p6p7p4s5s+3s+22',
  '1p2p3p4p6p6p6p4s5s6s7z7z7z+5p+21',
  '6m7m8m2p3p4p6p6p7p8p6s7s8s9p+24',
  '5m5m1p2p2p3p3p4p1s2s3s3z3z+3z+13',
  '3m3m6m7m8m7p8p9p2s3s4s5s6s+1s+21',
  '1m1m3p3p4p4p5p6p7p8p6s7s8s2p+11',
  '5m6m6m6m7m7m8m9m6s6s7s7s8s8s+13',
  '2m3m4m6m8m5p6p7p3s3s4s5s6s7m+21',
  '3m4m5m6m7m8m9m9m4p5p6p5s6s+7s+12',
  '4m4m7m8m9m4p5p5p6p6p1s2s3s4p+14',
  '1m3m6m7m8m1p2p3p1s2s3s7z7z2m+14',
  '1m2m3m6m7m8m9m9m7p8p9p7s8s+9s+13',
  '1m2m3m7m8m9m7p8p9p1s7s8s9s+1s+24',
  '3m3m4m5m4p5p6p3s4s5s5s6s7s+6m+22',
  '5m5m4p6p6p7p8p5s6s7s8s8s8s+5p+14',
  '5m6m7m2p2p3p3p4p1s1s1s5s5s4p+23',
  '9m9m5p6p8p8p8p5s6s7s5z5z5z+7p+13',
  '2m3m8m8m1p2p3p6p7p8p4s5s6s+1m+11',
  '6m7m1p2p3p3p3p4p4p5p5p2z2z5m+14',
  '6m7m5p6p7p9p9p2s3s4s7s8s9s+8m+12',
  '2m3m4m4p5p6p1s2s3s4s5s8s8s3s+22',
  '6m7m8m4p5p6p6p3s4s5s7s8s9s+3p+24',
  '4m5m2p2p4p4p4p5p5p6p6p7p7p+3m+13',
  '1p1p1p2p2p5p6p6p7p7p2z2z2z+8p+13',
  '1m1m2m2m3m3m6m6m7m6z6z7z7z+7m+24',
  '3m4m2p3p4p5p5p4s5s6s8s8s8s+2m+12',
  '5m6m7m4p5p6p2s3s5s6s7s9s9s+4s+22',
  '4m5m6m7m8m9m2p2p3p4p4p3s3s3p+11',
  '7m8m9m6p6p7p8p9p6s7s7s8s9s+5s+21',
  '5m5m5m1s2s3s4s5s6s7s9s7z7z+8s+12',
  '2p3p4p7p7p1s2s3s6s7s5z5z5z8s+12',
  '6p6p7p7p8p9p9p3s4s5s6s7s8s5p+13',
  '5m6m4p5p6p7p8p9p2s3s4s9s9s+4m+13',
  '5m5m6m6m7m5p5p5p1s1s2s3s4s+7m+11',
  '7m8m9m1p2p3p4p6p7p8p9p3s3s+5p+24',
  '5m6m7m2p3p4p6p6p3s4s5s6z6z+6z+13',
  '1p2p3p4p4p9p9p5s5s6s6s7s7s9p+13',
  '7m8m4p5p6p6p7p8p2s3s4s4z4z9m+21',
  '5m5m9m9m3p3p4p4p5s5s8s9s9s+8s+12',
  '2m2m7m8m9m3p3p4p5p5p5s6s7s+4p+13',
  '2m3m4m7m8m2p2p6p7p8p6s7s8s9m+12',
  '4m4m8m8m3p3p4p4p1s1s8s8s1z+1z+14',
  '2m3m4m6m7m2p3p4p4p4p2s3s4s8m+11',
  '2m3m4m3p4p7p8p9p2s2s5s6s7s2p+11',
  '7m8m9m8p8p1s2s3s4s5s6s8s9s7s+12',
  '4m4m6m6m2s2s8s8s5z5z6z6z7z+7z+22',
  '4m5m5m6m6m7m5p6p4s4s7s8s9s4p+14',
  '2m3m4m7m8m9m4p5p5p6p6p7s7s7p+21',
  '1m2m3m3m4m5m5m7m8m9m4p5p6p5m+22',
  '4m5m7m8m9m1s2s3s5s6s7s9s9s6m+23',
  '1m1m5m5m8p8p9p9p9s1z1z2z2z+9s+12',
  '1m1m6m7m8m4s5s6s7s8s9s3z3z+3z+13',
  '1m2m3m4m5m6m7m8m5p5p7s8s9s+9m+21',
  '2m2m7m7m9m9m1p1p2s2s8s8s6z6z+12',
  '1m2m2m3m3m4m7m8m3s3s5s6s7s6m+22',
  '2p3p4p7p7p4s6s7s8s9s5z5z5z5s+13',
  '8p8p9p9p3s4s4s8s8s9s9s3z3z+3s+13',
  '1m1m9p9p1s2s3s4s5s6s7s8s9s1m+12',
  '2m3m4m5m5m7m8m9m5s6s7s8s9s+7s+11',
  '2m3m4m4m5m6m3p3p3p6p8p4s4s+7p+24',
  '1m1m2m3m4m7m8m9m7p8p9p3s4s2s+11',
  '1m1m1m3m3m3p4p2s3s4s5z5z5z+2p+13',
  '1m2m3m4m4m5p6p7p3s4s5s5s6s7s+24',
  '1p1p7p8p9p7s8s9s3z3z3z6z6z+6z+12',
  '2m3m4m2p3p4p5p6p7p8p2s3s4s2p+13',
  '6m7m8m3p4p5p1s1s2s3s3s4s5s+4s+22',
  '2m3m4m5m6m7m6p7p8p6s6s3z3z3z+33',
  '2m3m4m6m7m8m6p6p7p7p8p3s3s8p+22',
  '2m2m4m4m6m6m2p2p3p1s1s2s2s+3p+14',
  '6m6m7p8p3s4s5s6s6s7s7s8s8s+9p+21',
  '3m4m5m2p3p4p6p6p3s4s4s5s6s2s+11',
  '3m4m5m6m7m8m6p7p2s2s7s8s9s+8p+12',
  '1m2m3m7m8m9m4p5p6s7s8s9s9s3p+22',
  '5m6m8m8m6p7p8p4s5s6s7s8s9s4m+12',
  '6m6m6p6p2s2s5s5s8s8s6z7z7z6z+24',
  '4m5m6m5p6p7p1s1s3s4s5s5s6s7s+23',
  '7m7m6p7p7p8p9p2s3s4s5z5z5z+8p+13',
  '6m7m8m5p5p5p4s5s6s6s6s7s7s6s+11',
  '1s1s1s2s3s6s7s7s8s9s3z3z3z5s+12',
  '1m2m3m5m6m7m3p4p5p3s3s4s5s+3s+12',
  '2p3p4p4p5p6p8p9p5s5s5z5z5z+7p+11',
  '3m4m4m4m5m6m7m7m8m8m9m6s7s+5s+12',
  '2m3m4m2p3p4p2s3s4s5s5s6s7s+8s+23',
  '6m7m8m1p2p3p5p6p8p8p2s3s4s+7p+22',
  '1m2m3m6m8m2p2p6p7p8p6s7s8s7m+23',
  '1m1m2m2m1p1p4s4s8s8s2z2z4z+4z+24',
  '5m6m7m3p4p1s2s3s4s4s6s7s8s5p+11',
  '1m2m3m7m7m1p2p3p4p5p6s7s8s+6p+12',
  '4m4m3p3p5p5p6p6p7p7p2s2s3s+3s+23',
  '2m5m5m4p4p7p7p9p9p6s6s9s9s+2m+12',
  '4m5m6m8m9m4p5p6p4s5s6s7z7z+7m+11',
  '4m5m8m8m4p5p5p6p6p7p6s7s8s+3m+23',
  '5m6m4p4p6p7p8p2s3s4s1z1z1z+4m+13',
  '2m3m4m6m7m8m4p4p4p5p5p6p7p8p+14',
  '5p6p7p1s2s3s4s5s6s7s9s5z5z+8s+23',
  '5m6m2p3p4p5p6p7p2s2s5s5s5s+4m+11',
  '1p1p5p6p7p2s3s4s7s7s8s8s9s+9s+33',
  '3m3m6m7m4p5p6p4s5s6s2z2z2z5m+21',
  '2m3m4m7p7p8p8p9p4s4s2z2z2z9p+23',
  '4m6m2p3p4p7p7p3s3s4s4s5s5s5m+13',
  '4m5m7m7m7m2p2p5p6p7p5s6s7s+6m+23',
  '4m5m6m4p5p2s3s4s5s5s7s8s9s+3p+13',
  '4m4m6m7m8m4p5p6p3s3s4s4s5s+2s+24',
  '2m3m3m4m4m5m3p4p3s3s5s6s7s2p+23',
  '1m1m2m2m3m3m2p4p4p2s2s3s3s+2p+13',
  '3m4m5m6m7m8m6p7p8p2s3s9s9s+1s+13',
  '6m6m1p1p2p2p4p4p7s7s2z3z3z2z+14',
  '2m3m4m4m5m6m3p3p4p5p6s7s8s+6p+14',
  '3m3m3m5m6m7m5p6p7p8p8p5s7s+6s+12',
  '1m2m3m4m5m6m2p2p5p6p6p7p7p+5p+21',
  '3m3m6m7m7m8m8m9m6p7p8p6s8s7s+21',
  '5m5m6m6m6m2p3p4p2s4s6s6s6s3s+11',
  '1p2p3p5p6p2s3s4s5s5s6s7s8s+7p+14',
  '8m8m9m9m1p1p2p2p6p6p2s4s4s2s+23',
  '2m2m7m8m9m2p3p4p1s2s3s7s8s+9s+23',
  '5m5m1p1p2p2p3p3p6s6s6s7s8s+9s+22',
  '4m4m2p3p4p2s3s4s7s7s8s9s9s+8s+21',
  '6m8m8m8m4p5p6p6p7p8p2s3s4s7m+12',
  '4m5m5m5m6m6m7m8m3p4p5s6s7s+5p+23',
  '1m6m6m7m7m3p3p4s4s9s9s5z5z1m+13',
  '3m3m3m4m4m5m6m7m5p6p7p6s7s8s+23',
  '5m6m7m5p6p2s2s3s4s5s5s6s7s4p+12',
  '3m3m4m5m6m2p3p4p7p8p3s4s5s9p+21',
  '2m3m2p3p4p7p7p4s4s5s5s6s6s+1m+23',
  '1m2m3m5m6m7m5p6p3s4s5s6s6s7p+24',
  '6m6m7m7m8m3p4p5p3s4s5s3z3z+8m+13',
  '2m2m5m6m7m2s2s5s6s6s7s7s8s+2s+14',
  '4m5m6m3p3p4p5p6p4s5s6s7s8s+9s+22',
  '4m5m6m6p7p8p4s5s5s6s6s7s7s+7s+23',
  '2m3m3m4m4m5m7m8m4p4p6s7s8s+9m+12',
  '2m3m4m3p4p2s3s4s5s6s7s8s8s+5p+22',
  '3m4m5m3p3p5p5p5p7p7p7p3s4s+2s+24',
  '1m2m3m3m4m5m1p2p3p4p5p9s9s6p+12',
  '6m7m8m6p7p8p9p9p4s6s6s7s8s+5s+11',
  '5m6m3p4p5p1s2s2s3s3s4s5s5s4m+21',
  '3m3m4m4m5m5m6m7m8m5p6p6p6p+4p+11',
  '1s1s4s5s6s6s7s8s2z2z7z7z7z+1s+12',
  '4m4m7m7m5p5p1s1s2s2s3s3s4s+4s+24',
  '2m3m5m6m7m2p2p5p6p7p3s4s5s+1m+12',
  '5m6m7m8m8m5p5p5p6p7p8p3s4s5s+22',
  '1m2m3m7m8m5p6p7p4s4s5s6s7s+9m+12',
  '1m2m4m5m6m7m8m9m4p5p6p1z1z3m+12',
  '7m8m9m4p4p7p8p9p1s2s3s7s9s+8s+14',
  '3m3m4m4m5m5m9p9p2s2s5s6s7s+2s+14',
  '6m7m8m2p3p3p4p4p4s5s6s2z2z2p+22',
  '1m2m3m1s1s2s2s3s3s6s7s8s9s+6s+14',
  '3m3m6m6m6m3p3p3p4p5p6p6s7s+8s+13',
  '5m6m7m5p6p7p9p9p4s5s6s7s8s+6s+22',
  '2m2m1p2p3p3s4s8s8s8s5z5z5z+2s+22',
  '4m5m6m2p3p4p6p6p2s4s5s6s7s3s+24',
  '2m3m9m9m1p2p3p6p7p8p7s8s9s+1m+21',
  '5m6m1p1p5p6p7p1s2s3s5s6s7s+7m+24',
  '5m6m7m5p5p5p6p6p7s8s9s6z6z6z+24',
  '9m9m8p8p3s3s6s6s9s2z2z4z4z+9s+24',
  '2m2m3m3m6m6m3p3p7p7p5s5s9s+9s+22',
  '2m3m4m5m6m7m7m8m4p4p3s4s5s+9m+12',
  '1m1m2m2m3m6m6m6m3p4p5p6s6s+3m+23',
  '2m3m4m2p4p5p6p7p9p9p2z2z2z3p+22',
  '3m4m5m9m9m1p2p3p5p6p4s5s6s7p+14',
  '2m3m2p3p4p3s3s4s5s6s6s7s8s4m+12',
  '3m4m4m5m5m6m7m8m9m2s3s5s5s4s+14',
  '1m2m3m1p2p3p6p7p8p1s3s5s5s+2s+12',
  '6m7m8m8m5p6p6p7p7p8p6s7s8s5m+12',
  '1m1m2m2m3m3m2p4p4p9p9p4s4s+2p+23',
  '3m3m7m7m7m3p4p5p3s4s5s5s6s+7s+13',
  '2m3m4m6m7m2p2p4p5p6p2s3s4s+8m+14',
  '3m4m7m8m9m6p7p8p2s2s7s8s9s+2m+12',
  '7m8m9m2p2p3p4p5p5s6s6s7s8s7s+21',
  '3m4m6m7m8m6p6p8p8p8p3s4s5s+2m+22',
  '2m2m6m6m1p1p2p2p3p3p7p7p8s8s+23',
  '2m2m3m3m4m4m3p3p3s3s6s7s7s+6s+21',
  '2m3m4m6m7m8m5p6p7p6s8s8s8s7s+11',
  '7m7m9m9m3p3p9p9p4s4s2z2z4z4z+11',
  '4m5m1s2s2s3s3s4s6s7s8s2z2z+3m+11',
  '2m2m9m9m6s6s7s7s1z1z2z5z5z+2z+22',
  '1m2m3m6m7m8m9m9m6p7p7p8p9p+5p+11',
  '3m4m4m5m6m3p3p4p5p6p5s6s7s+2m+13',
  '3m4m4m5m6m6p7p8p5s6s7s9s9s5m+11',
  '3m5m7m8m9m4p4p5p6p7p7z7z7z4m+24',
  '4m5m6m3s4s5s7s7s7s3z3z7z7z+7z+24',
  '3m4m5m7m7m8m8m9m9m5s5s7s8s6s+14',
  '1m2m3m7m8m9m9p9p9p2z2z4z4z+4z+14',
  '2m2m4m6m2p3p4p2s3s4s6s7s8s5m+13',
  '3m4m5m7p8p9p6s6s7s7s8s3z3z+5s+22',
  '3m4m5m6m7m3p4p5p5p6p7p5s5s+2m+11',
  '2m3m1p2p3p1s2s3s4s4s6s6s6s+1m+14',
  '5m5m6p6p8p8p1s1s2s2s1z1z6z+6z+12',
  '2m2m2m3m4m6m7m8m3p4p5p3s4s2s+13',
  '1m2m3m5m6m7m8m9m9p9p1z1z1z+4m+21',
  '2m3m4m6m7m1p2p3p7p8p9p1s1s5m+12',
  '3p4p5p6p7p7p8p9p5s6s7s9s9s+2p+23',
  '5m6m6m6m7m2p3p3p4p5p4s5s6s4p+22',
  '1m2m3m4m4m2p3p4p7p8p3s4s5s9p+14',
  '3m4m5m3p4p5p1s2s3s5s6s9s9s7s+14',
  '3m3m5m5m4p5p6p4s5s6s8s8s8s+5m+12',
  '2m3m4m8m8m2p3p4p4p5p6p5s7s6s+24',
  '8m8m9m9m7p7p2s2s6s6s2z3z3z+2z+12',
  '1m1m4m4m6p6p8p8p2s2s3z7z7z3z+24',
  '2m2m4m5m6m7m8m9m3p4p3s4s5s+2p+14',
  '1m2m3m5m6m7m9m9m3p4p5s6s7s+2p+13',
  '2m3m4m7m8m9m2p3p4p9p9p3s4s+5s+21',
  '4p5p6p1s2s3s4s5s5s5s6s8s9s+7s+24',
  '3m4m5m5p5p7p8p9p3s4s7s8s9s+2s+22',
  '3m4p4p8p8p1s1s2s2s4s4s8s8s3m+23',
  '5m6m7m1p1p3p4p5p6p7p8p3s4s5s+11',
  '1m3m3m7m7m2p2p3p3p5p5p5s5s1m+13',
  '4m5m6m5p6p7p7p8p6s7s7s7s8s9p+21',
  '1m2m3m7m8m9m1p2p3p3p3p6s7s+8s+12',
  '5p5p6p6p2s2s4s4s6s6s1z5z5z+1z+11',
  '3m4m7m8m9m1p2p3p4s5s6s9s9s2m+12',
  '3m4m5m5m6m7m8m9m9p9p6s7s8s+4m+14',
  '2m3m4m5m5m2p3p4p5p7p6z6z6z6p+11',
  '3m4m6m7m8m9m9m5p6p7p3s4s5s+2m+14',
  '6m6m6m3p4p4p5p5p6p7p7p3s4s5s+22',
  '2m2m6m7m8m2p3p4p5p6p7p2s2s2s+13',
  '4m5m6m3p4p5p6p7p1s2s3s8s8s2p+21',
  '1m1m1p1p3p3p5p5p1s3s3s6s6s+1s+12',
  '6m7m7m8m8m3p4p5p3s4s5s8s8s+9m+12',
  '4m4m6p8p3s4s5s6s6s6s7z7z7z+7p+12',
  '1s2s2s3s3s4s4s4s6s7s7s8s9s+5s+11',
  '1m1m2m2m4m4m4m4m5m6m6m7m8m+1m+11',
  '7m8m9m1p2p7p8p9p1s2s3s9s9s+3p+11',
  '2m2m6m6m1p1p6p6p9p9p4s4s1z1z+11',
  '1m2m3m4m5m6m1s2s3s4s4s4s5s+3s+23',
  '7m7m7p8p9p1s2s2s3s3s1z1z1z+1s+24',
  '6m6m5p6p6p7p7p1s2s3s7s8s9s+5p+14',
  '4m5m6m6m7m8m4p5p6p7p8p6s6s3p+11',
  '1p1p2p2p3p3p6p7p8p2s3s5s5s+1s+11',
  '3m4m5m3p4p5p6p6p6p7p8p6s7s+5s+11',
  '1m2m3m1p2p3p3p4p5p7s8s3z3z+6s+14',
  '1m1m2m2m3m3m3p4p5p9p9p8s9s+7s+21',
  '3m4m5m7m8m9m1p2p3p4p4p6p7p+8p+21',
  '4m5m5m6m6m7m3p4p5p6s6s6z6z+6z+14',
  '2m3m4m5m6m7m4p5p6p7p4s5s6s+7p+13',
  '5m6m7m5p6p7p8p8p1s2s3s5s6s+4s+23',
  '4p5p6p1s2s2s3s3s3s4s5s8s8s4s+12',
  '4m5m6m1p1p4p5p6p4s5s6s6s7s8s+11',
  '3m3m4m4m7p7p8p8p2s2s3s7s7s+3s+22',
  '3m4m5m5m6m6m7m8m2s2s6s7s8s+4m+14',
  '2p3p4p7p8p9p6s6s7s8s8s5z5z+7s+14',
  '1m2m3m9m1p2p3p7p8p9p1s2s3s+9m+22',
  '4m4m5m5m6m6m4p5p6p3s3s5s6s+7s+14',
  '2m3m4m4m5m4p5p6p7p8p9p2z2z3m+14',
  '6m7m8m2p3p5p6p7p2s2s6s7s8s1p+23',
  '5m7p7p3s3s5s5s6s6s1z1z3z3z5m+11',
  '1p5p5p8p8p9p9p6s6s2z2z5z5z+1p+14',
  '3m4m5m2p3p4p5s6s6s7s8s8s8s+7s+22',
  '2p3p4p5p6p7p8p9p3s3s6s7s8s4p+21',
  '5m6m6m7m8m7p7p1s2s3s3s4s5s7m+14',
  '1m2m3m3p4p5p7p7p2s3s6s7s8s1s+12',
  '5m6m7m9m9m7p8p9p3s4s5s6s7s+8s+11',
  '3m3m3m5p5p6p6p6p4s5s6s6s8s+7s+14',
  '1m2m3m8m8m6p7p8p3s3s4s4s5s2s+13',
  '3m4m5m3p5p6p6p1s2s3s3s4s5s+4p+14',
  '6m7m8m4p5p6p7p8p9p9p4s5s6s+3p+14',
  '6m7m6p7p8p1s1s6s7s8s5z5z5z8m+12',
  '2m3m4m6m7m8m4s5s5s6s6s8s8s4s+13',
  '2m3m4m4m5m6m5p5p6p6p6p3s3s+3s+22',
  '3m4m5m7p8p9p2s3s4s6s6s6z6z+6z+13',
  '3m4m5m5p6p6p7p8p1s2s3s7s7s+4p+12',
  '2m3m3m4m5m5m6m7m2s3s4s9s9s+1m+23',
  '5m6m1p2p2p2p3p4p5p6p7p8p9p+4m+13',
  '2m3m4m4p5p5p6p7p9p9p7s8s9s6p+21',
  '4m5m6p6p1s2s3s4s5s6s6s7s8s6m+23',
  '2m2m2p3p4p6p7p7p8p8p9p4s5s+6s+12',
  '3m3m4m4m5m5m3p4p5p3s4s6s6s+5s+22',
  '2m3m4m3p4p5p6p7p3s3s3s7s7s+8p+21',
  '3m4m5m4p5p5p5p6p5s6s7s7s8s+9s+21',
  '1p2p3p4p5p6p7p8p5s6s7s4z4z+6p+21',
  '6m7m8m2p3p4p5p6p7p3s3s6s7s8s+24',
  '9m9m9m2p3p4p5p5p3s4s7z7z7z5s+21',
  '1m2m3m5m6m7m7p7p3s4s7s8s9s2s+11',
  '4m4m5m5m5m6m7m8m4p4p4p7s8s6s+23',
  '5m6m6m7m7m8m5p7p2s2s2z2z2z+6p+12',
  '2m3m4m5m3p4p5p6p7p8p6s7s8s+2m+22',
  '2p2p4p5p6p7p8p2s3s4s5s6s7s9p+12',
  '4m5m1p2p3p5p6p7p3s4s5s8s8s3m+14',
  '3m4m5m2p3p1s2s3s3s3s6s7s8s4p+11',
  '6m7m8m2p2p5p6p7p4s5s6s7s8s+9s+24',
  '5m6m1s1s2s2s3s3s5s6s7s6z6z+4m+21',
  '2m2m7m8m3p4p5p7p8p9p3s4s5s+9m+21',
  '4m4m4m6m7m8m5p6p7p3s3s5s5s+5s+11',
  '3m4m5m6m6m4p5p1s2s3s5s6s7s+6p+13',
  '1p3p4p5p5p5p6p7p8p9p7s8s9s+2p+21',
  '2m3m4m5p5p6p6p7p4s4s6s6s6s7p+13',
  '2m2m3p4p4p5p5p5s5s6s6s7s7s+6p+14',
  '3m4m4m5m6m6m7m8m5p6p7p5s5s5m+11',
  '4m5m2p2p4p5p6p7p8p9p2s3s4s+6m+13',
  '2m2m5m6m7m4p5p7p8p9p6s7s8s+3p+13',
  '1m2m1p2p3p1s2s3s6s6s2z2z2z+3m+11',
  '2m3m2p3p4p3s4s5s5s5s7s8s9s1m+11',
  '3m4m6m7m8m5p6p7p1s1s4s5s6s5m+11',
  '2m3m4m7p8p9p1s2s3s6s6s7s8s9s+13',
  '1p2p3p4p5p7p8p9p7s7s7s8s9s+3p+13',
  '5m5m6m6m7m4p4p4p7s7s3z3z3z+7m+21',
  '1m2m3m4p5p4s4s9s9s9s7z7z7z+3p+23',
  '2m3m4m2p3p4p6p8p2s3s4s5z5z+7p+12',
  '1m1m6m7m8m3p4p6p7p8p4s5s6s2p+22',
  '2m3m4m6m6m7m8m9m5p6p7p5s6s+7s+13',
  '2m3m4m6m7m8m3p3p3p5p5s6s7s5p+21',
  '6m7m6p6p7p8p9p1s2s3s7s8s9s5m+11',
  '1m3m5m5m5m1p1p7s7s8s8s9s9s2m+13',
  '5m6m6m7m8m6p7p8p5s6s7s9s9s4m+11',
  '3m3m3m4m5m6m4p4p6p7p2s3s4s5p+12',
  '4m5m4p4p6p7p8p2s3s4s6s7s8s+3m+23',
  '2m3m4m5m6m7m7m7m4p5p6p2s4s+3s+13',
  '5m6m1p2p3p5p6p7p3s3s7s8s9s4m+14',
  '9m9m1p2p3p5p6p6p7p7p2s3s4s+8p+11',
  '2m3m4m2p2p3p4p7p7p7p4s5s6s5p+23',
  '3p3p7p7p8p8p9p9p7s7s8s8s9s+9s+24',
  '7m7m8m8m2p2p2s2s4s4s8s8s4z4z+24',
  '1m2m3m6m7m8m3p4p5p5p3s4s5s+2p+11',
  '3m4m5m6m7m5p5p7p8p9p5s6s7s2m+22',
  '1m2m3m4m5m7m8m9m3p3p3s4s5s+3m+13',
  '3m4m6m7m8m2s2s4s5s6s7s8s9s5m+14',
  '2m2m2m3m4m5m5p5p2s3s4s6s7s8s+23',
  '3m3m6m7m8m1p2p3p5p6p3s4s5s+4p+21',
  '7m8m9m2p2p4p5p7p8p9p1s2s3s3p+13',
  '5m5m5m6m7m1p2p3p4p5p6s7s8s6p+22',
  '3m4m2p3p4p4s5s6s7s8s9s3z3z2m+24',
  '4m5m6m8m8m3p4p5p7p8p9p4s5s6s+13',
  '5m6m3p3p4s4s5s5s6s6s7s8s9s+4m+24',
  '2m2m3m3m5m6m7m2s3s4s6s7s8s+2m+13',
  '6m7m8m6p7p8p3s3s4s4s5s3z3z2s+12',
  '1m2m3m7m8m9m1s1s4s4s5s5s6s+3s+21',
  '4m5m3p4p5p7p8p9p5s6s7s3z3z3m+12',
  '1m2m3m3p4p6p7p8p4s5s6s4z4z5p+22',
  '7m8m9m3p4p4p5p6p7p8p9p5s5s2p+22',
  '1m1m5m5m7m7m5s5s2z3z3z5z5z+2z+12',
  '1m2m3m4p5p7p8p9p2s2s4s5s6s6p+24',
  '1m1m3m3m7m2p2p4p4p7p7p8p8p+7m+13',
  '4m5m6m7m8m9m5p5p2s3s5s6s7s+1s+14',
  '1p2p3p4p5p6p7p8p1s2s3s5s5s+9p+13',
  '3m4m5m6m7m8m5p5p6p7p2s3s4s5p+11',
  '3m4m5m5m6m7m3p3p4s5s6s6s7s+8s+23',
  '1m1m3m3m9m9m5p5p7s7s1z5z5z+1z+14',
  '2m3m1p2p3p7p7p8p8p9p9p2s2s4m+22',
  '6m6m4p5p6p7p8p1s2s3s6s7s8s6p+11',
  '4m5m5p5p5p6p7p8p4s4s6s7s8s+6m+21',
  '1m1m2m2m3m3m1p2p1s2s2s2s3s+3p+24',
  '3m3m3m9m9m6p7p8p5s7s7z7z7z+6s+14',
  '1p1p1p4p4p4p5p6p7p8p8p6z6z+6z+24',
  '4m5m6m7m8m9m7p8p1s2s3s5s5s+9p+14',
  '7m8m9m3p4p7p7p3s4s5s5s6s7s+5p+12',
  '3m4m5m2p3p5p6p7p7p8p9p8s8s+4p+24',
  '4m4m5m5m6m9m9m4p5p6p3s4s5s+6m+11',
  '4m5m6m4p5p6p7p8p9p4s5s7z7z6s+24',
  '4m5m5m6m7m7m8m9m3s4s5s5s5s6m+21',
  '1m2m3m6m6m1p2p3p4p5p1s2s3s6p+11',
  '2m3m3m4m4m2p3p4p2s3s4s2z2z5m+21',
  '1p2p3p4p6p7p8p9p7s8s9s5z5z+5p+22',
  '3m4m5m9m9m9m3p4p5p4z4z7z7z7z+23',
  '4m5m6m2p3p1s2s3s5s6s7s9s9s+1p+21',
  '2m3m4m4m5m6m7m2s3s4s6s7s8s1m+12',
  '7m8m9m9m6p7p8p3s4s5s6s7s8s+6m+23',
  '4m4m5m5m6m6m5p7p4s5s5s5s6s6p+22',
  '2m3m4m6m7m8m1s2s3s4s5s9s9s+6s+13',
  '6m7m8m1p1p2p2p3p3p2s3s5s5s+4s+12',
  '1p2p2p2p3p7p8p3s4s5s6s7s8s+6p+13',
  '5m6m7m4p5p1s2s3s4s5s6s7s7s3p+23',
  '1m1m5m5m2p3p4p2s2s2s1z1z1z+1m+14',
  '1p1p3p4p7p8p9p2s3s4s5s6s7s2p+12',
  '2m3m4m5m6m7m4p4p4p5p2s3s4s+3p+21',
  '1m1m7p8p9p4s5s6s7s7s8s8s9s6s+24',
  '3m4m1p1p2p3p3p4p4p5p1s2s3s+2m+13',
  '2m2m2m5m5m4p5p6p5s6s7s7s7s4s+23',
  '3m4m5m7m8m9m7p8p9p1s7s8s9s+1s+23',
  '4m5m6m2p3p4p2s3s4s4s5s7s7s+6s+24',
  '1m2m3m2p3p7p8p8p8p9p1s2s3s4p+14',
  '3m4m2p3p4p2s3s4s6s7s8s9s9s+5m+14',
  '4m5m6m2p2p3p4p5p6p7p8p5s6s+4s+22',
  '2m3m4m6m6m6m2p3p4p2s2s5s6s+7s+11',
  '5m6m3p4p4p5p5p6p8p8p5s6s7s4m+12',
  '2p3p4p5p6p7p8p9p5s5s5s1z1z+1p+22',
  '6m7m8m2p3p5p6p7p3s4s5s9s9s4p+11',
  '1m1m2m3m4m7m8m9m3p4p7s8s9s+2p+14',
  '5m5m7m8m9m5p6p7p3s4s7s8s9s+5s+11',
  '1m2m3m6m7m3p4p5p7p7p3s4s5s+8m+22',
  '7m8m9m1p2p3p4p4p5p6p4s5s6s7p+12',
  '3m4m5m6m7m8m3p4p5z5z7z7z7z+5p+14',
  '3m4m5m6m7m8m4p5p6p4s5s9s9s3s+21',
  '2m2m5m6m5p6p7p1s2s3s3s4s5s7m+13',
  '2m3m4m7p8p9p4s4s4s5s7s8s9s3s+12',
  '3m4m5m7m8m3p4p5p5p5p5s6s7s6m+12',
  '4p5p6s6s7s8s9s2z2z2z7z7z7z+6p+12',
  '4m6m8m8m8m5p6p7p8p8p2s3s4s+5m+13',
  '2p2p7p7p8p8p9p4s4s5s5s2z2z+9p+14',
  '6m7m9m9m6p7p8p4s5s6s7s8s9s+8m+23',
  '3m4m5m4s5s5s6s7s7s8s9s9s9s+6s+12',
  '3m3m4m4m9m9m3p3p3s4s4s8s8s+3s+14',
  '1p2p3p4p5p6p8p8p5s6s7s7s8s9s+12',
  '1m2p2p3p3p1s1s3s3s4s4s6s6s+1m+21',
  '2m2m5m6m1p2p3p5p6p7p4s5s6s+4m+21',
  '7m8m9m6p7p8p9p9p2s3s4s7s8s+6s+11',
  '3m3m4m5m6m7m7m8m8m9m4p5p6p9m+13',
  '3p4p5p7p8p9p4s5s6s7s8s9s9s3s+23',
  '1m2m3m3p4p4p5p5p2s3s4s4z4z6p+21',
  '4m5m6m2s3s4s4s5s6s6s7s8s8s8s+13',
  '3p4p7p8p9p2s3s3s4s4s5s9s9s+5p+13',
  '5m5m6m6m7m3p3p5p5p4z4z5z5z+7m+21',
  '2m3m4m6m7m8m2p3p4p6p6p6s8s7s+21',
  '1m2m3m5m6m7m3p4p7p8p9p4z4z5p+22',
  '4m5m6m7m7m5p6p7p8p9p4s5s6s4p+12',
  '4m5m6m1p2p3p9p9p4s5s6s7s8s9s+14',
  '2m3m4m7m8m8p8p2s3s4s4s5s6s+9m+12',
  '2p3p4p6p6p7p8p9p2s3s7s8s9s+4s+22',
  '7m7m8m8m9m9m2p2p7p8p5s6s7s6p+13',
  '2m3m4m5m6m7m1s1s5s5s5s2z2z+2z+22',
  '3m4m5m6m6m7p8p9p3s3s4s5s5s+4s+14',
  '6m7m4p5p6p2s2s3s3s4s4s2z2z5m+11',
  '7m8m9m1p2p3p6p6p7p8p8p1s1s+7p+12',
  '3m4m4m5m5m3p3p3p6p7p8p8s8s6m+14',
  '3m4m5m6m7m5p6p7p4s5s6s6s6s8m+13',
  '4m5m7p8p9p1s1s2s3s4s5s6s7s6m+11',
  '3m4m5m4p5p6p8p8p2s3s6s7s8s4s+22',
  '2m3m4m2p2p2p3p4p5p5s6s8s8s4s+23',
  '5m6m2p3p4p5p5p3s4s5s7s8s9s7m+12',
  '1p2p2p3p3p4p1s2s3s4s4s6s7s8s+23',
  '3m4m5m6m7m8m1p2p3p5p6p4s4s+4p+13',
  '2m3m4m5m6m7m1p1p6p7p8p3s4s+5s+14',
  '9m9m5p5p2s2s4s4s3z3z4z6z6z4z+12',
  '9m9m9p9p2s3s4s6s7s8s5z5z5z+9m+12',
  '1m2m3m4m4m5m6m7m2p3p4p2s3s4s+11',
  '4m5m6m7m7m7m8m1p2p3p7s8s9s6m+12',
  '4m5m9m9m3p4p4p5p5p6p6p7p8p+3m+12',
  '5m5m7m8m9m3p4p5p6p7p8p6s7s+8s+23',
  '2m3m4m5m6m7m2p3p4p6p7p8s8s8p+11',
  '1m2m3m3m4m5m9m9m4p5p6p5z5z+5z+11',
  '2m3m2p3p4p4s5s6s6s7s8s4z4z4m+23',
  '2m3m4m6m7m8m8m3s3s4s4s5s5s8m+11',
  '6m7m8m2p2p1s3s4s5s6s7s8s9s+2s+23',
  '2m2m3m4m5m6m7m2p3p4p3s4s5s+8m+22',
  '5m5m3p4p7p8p9p2s3s4s6s7s8s+5p+24',
  '5m6m7m8m8m4p5p6p6p7p8p6s8s+7s+24',
  '7m8m9m3p4p4p5p6p6p7p8p4s4s5p+22',
  '3m5m3p4p5p6p6p7p7p7p5s6s7s+4m+14',
  '4m5m5m6m6m7m2p2p3p4p5p6s8s+7s+24',
  '7m7m1p2p3p4p5p6p8p9p4s5s6s+7p+11',
  '1m2m2m3m3m5m6m7m3s3s4s5s6s1m+14',
  '4m6m6m3s3s5s5s7s7s8s8s7z7z4m+12',
  '2m3m4m5m6m2s2s3s4s4s5s5s6s1m+11',
  '3m4m4m5m6m6m6m7m8m9m2z2z2z+5m+12',
  '4m5m6m5p6p1s2s3s5s6s7s4z4z+4p+22',
  '5m5m5m6m6m6m2p3p4p4s5s5s5s6s+12',
  '2m3m1p2p3p5p6p7p1s2s3s8s8s4m+11',
  '7m8m9m2p3p6p7p8p9p9p6s7s8s+1p+14',
  '1m1m2m2m3m3m5m6m2p2p5p6p7p+7m+14',
  '4m5m4p5p6p8p8p4s5s6s7s8s9s+3m+23',
  '2m3m4m5m6m2p2p3s4s5s7s8s9s+7m+13',
  '4m5m6m9m9m6p7p8p3s4s4s5s6s+5s+13',
  '4m5m6m2p2p5p6p7p1s2s3s6s7s5s+12',
  '2m3m4m2p3p4p7p8p9p2s3s4s8s8s+23',
  '1m1m3m4m5m5p6p7p3s4s5s6s7s+8s+23',
  '5m6m6m7m7m8m4p5p6p2s2s5s6s+7s+11',
  '3m4m5m6m7m8m4p4p3s3s5s6s7s3s+11',
  '3m4m5m7m8m5p6p7p1s2s3s9s9s+9m+13',
  '3m4m7m8m9m2p3p4p4p5p6p2s2s+2m+24',
  '1m2m7p7p2s2s3s3s4s4s3z3z3z+3m+12',
  '2m3m4m6m8m3s3s6s7s8s7z7z7z+7m+11',
  '7m8m9m5p6p7p3s4s5s6s7s9s9s2s+14',
  '3m4m5m5m6m7m4p5p6p7p8s8s8s+4p+11',
  '1m2m3m4m5m6m7m8m9m7p8p5z5z6p+14',
  '4m6m4p4p4p3s4s5s1z1z7z7z7z+5m+13',
  '6m7m8m6p7p8p3s4s5s7s8s2z2z+6s+13',
  '4m4m4m4m5m6m7m8m8p8p3s4s5s3m+24',
  '1m2m3m3p3p5p6p7p7s8s9s1z1z+1z+11',
  '3m3m5m6m7m2p3p4p2s4s6s7s8s3s+21',
  '1p2p3p4p5p6p7p8p4s5s6s3z3z+9p+12',
  '2m3m4m6m7m8m2p3p4p7p7p8p8p7p+23',
  '4m4m5m6m7m5p5p6p6p7p2s3s4s7p+11',
  '5m6m7m2p3p4p3s4s5s8s8s1z1z+1z+12',
  '2m3m4m3p4p5p2s2s3s3s4s4s5s2s+24',
  '4m5m6m7m7m7p8p9p2s3s4s5z5z+5z+11',
  '3m4m5m7m8m9m5p6p7p3s3s7s8s9s+14',
  '5m6m7m8m8m2s2s2s3s3s4s5s6s8m+22',
  '4m5m6m9m9m9m9p9p6s6s6s6z6z9p+13',
  '3m3m3p4p5p6p7p8p5s5s6s6s7s+4s+13',
  '3m4m7m8m9m5p6p7p5s6s7s4z4z+2m+13',
  '1m1m6m7m8m5p6p6p7p8p1s2s3s+7p+14',
  '2m3m7m8m9m3p4p5p3s4s5s8s8s+1m+14',
  '2p3p4p9p9p3s4s5s8s9s5z5z5z+7s+12',
  '6m7m4p5p6p6p7p8p2s3s4s6s6s+8m+21',
  '1m1m3m3m8m8m3p3p7p7p7s7s1z+1z+21',
  '1p9p9p2s2s6s6s9s9s4z4z5z5z+1p+11',
  '4m5m6m3p4p5p6p7p8p3s3s5s6s+4s+12',
  '7m8m9m1p2p3p4p5p6p7p7p7s8s+9s+23',
  '4m5m6m7p7p8p8p9p9p1s2s3s8s8s+12',
  '5m6m4p5p6p6s6s7s7s8s8s9s9s+4m+23',
  '5m6m2p3p4p9p9p4s4s5s5s6s6s7m+21',
  '4m5m5m6m6m9m9m3s4s5s7s8s9s7m+14',
  '2m3m4m2p3p4p9p9p3s4s5s6s7s8s+14',
  '1m2m3m4m5m6m1p2p3p5p5p7s8s+6s+22',
  '3m3m3m7m7m7m8m3p4p5p5s6s7s8m+12',
  '3m4m5m4p4p1s2s3s4s5s6s7s8s6s+11',
  '1m2m3m4m5m7m7m4p5p6p7p8p9p+6m+23',
  '1m2m3m4m5m7m7m6p7p8p6s7s8s3m+23',
  '4m4m4p4p1s1s2s2s6s6s4z6z6z+4z+11',
  '1m2m3m6m7m7p7p4s5s6s7s8s9s5m+12',
  '2m3m4m6m7m8m6p6p2s3s6s7s8s1s+14',
  '4m5m5p5p1s2s3s4s5s6s7s8s9s+6m+21',
  '2p4p5p6p6p7p7p8p1z1z4z4z4z+3p+13',
  '5m6m7m4s5s5s5s6s7s8s9s5z5z+5z+13',
  '2m3m4m5m5m5m2p3p4p8p8p5s6s+4s+24',
  '2m3m4m6m7m8m5p5p3s4s5s5z5z+5z+13',
  '5m6m7m4p5p6p6p7p1s2s3s8s8s5p+24',
  '6m7m8m2p3p5p6p7p3s3s3s7s7s4p+21',
  '3m5m7m7m3p4p5p2s3s4s6s7s8s+4m+22',
  '4m6m2p2p4s4s5s5s6s6s8s8s8s+5m+14',
  '2m3m4m7m7m1p1p2p2p3p3p3p5p4p+12',
  '6m7m3p4p5p1s2s3s4s4s5s6s7s+5m+23',
  '5m6m5p6p7p2s2s3s4s5s7s8s9s+7m+12',
  '5m6m7m7m8m9m4s5s7s8s9s4z4z3s+23',
  '2m3m4m2p3p4p3s4s4s4s4s5s6s+5s+14',
  '4m5m6m7m8m9m5p6p7p2s2s7s8s+9s+13',
  '4m4m7m8m9m3p4p2s3s4s6s7s8s+5p+13',
  '5m6m7m6p7p8p5s6s6s7s8s4z4z+4s+13',
  '3m4m5m7m7m3p4p5p7p7p7p6s7s+5s+14',
  '2m3m6m7m8m7p8p9p1s2s3s9s9s+4m+14',
  '1m1m3m3m5p3s3s6s6s9s9s7z7z+5p+13',
  '4m4m4m7m7m3p4p5p5p6p7p4s5s3s+21',
  '5m7m6p7p8p3s4s5s5s6s7s8s8s+6m+14',
  '4m5m6m7m8m9m3p3p4p5p5p7s7s4p+11',
  '6m6m5p6p7p8p8p3s4s5s5z5z5z+8p+23',
  '5m6m7m3p4p5p6p2s3s4s4s5s6s+3p+12',
  '5m5m3p3p5p5p3s3s4s4s3z3z6z6z+23',
  '6m7m8m9m9m3p4p6p7p8p4s5s6s5p+14',
  '5m6m6m7m7m3p4p5p4s5s5s5s6s5m+21',
  '1p1p1p2p2p2p3p4p5p1z1z7z7z+7z+13',
  '4m5m6m4p4p4p6p6p6p2s2s5s6s+7s+24',
  '4m5m6m4p5p6p6p2s2s3s3s4s4s+6p+23',
  '1m2m3m4m5m6m7p7p7p8p3s4s5s+9p+13',
  '2m3m5m6m7m9m9m5p6p7p2s3s4s+1m+21',
  '5m6m7m7m2p3p4p3s4s5s6s7s8s+4m+22',
  '1p2p3p3p4p5p7p8p9p2s3s9s9s+1s+11',
  '3m4m2p3p4p3s3s3s4s5s7s8s9s+5m+21',
  '3m4m5m7m7m4p5p6p2s3s5s6s7s+1s+13',
  '6m7m2p2p4p5p6p2s3s4s5s6s7s+8m+24',
  '5m6m2p3p4p7p7p8p8p9p9p8s8s7m+13',
  '5m6m7m4p5p6p7p8p2s3s3s3s4s+3p+11',
  '2m2m2m4m5m6m2p2p2p6p6p3s4s+2s+21',
  '2m3m4m7m7m2p3p4p2s3s3s4s4s5s+11',
  '5m6m7m5p6p1s1s2s3s4s5s6s7s+7p+12',
  '3m4m4p5p6p8p8p2s3s4s5z5z5z+5m+23',
  '1m2m3m2p3p4p5p5p1s2s3s5s6s+7s+21',
  '2m3m4m4m4m4s5s7s7s8s8s9s9s+6s+22',
  '3m4m7m8m9m9m9m3s4s5s5s6s7s2m+23',
  '5m6m7m8m8m3p4p5p2s3s7z7z7z+1s+11',
  '5m6m6m7m7m8m7p8p5s5s6s6s6s6p+13',
  '2m3m4m6m7m8m4p5p5p5p6p7s8s9s+14',
  '2m3m3m4m4m5m2p4p8p8p4s5s6s+3p+24',
  '3m3m3m3p4p2s2s4s5s5s6s6s7s+5p+13',
  '1m2m3m4m5m6m6m5p6p7p2s3s4s6m+22',
  '1m2m3m4m4m7m8m3p4p5p5s6s7s+9m+14',
  '3m4m5m6m7m8m6p7p3s4s5s7s7s8p+12',
  '4m4m1p2p3p5p6p7p2s3s5s6s7s1s+23',
  '1m1m4m4m7p7p4s4s8s8s9s9s3z+3z+22',
  '3m4m6m7m8m8p8p4s5s6s7s8s9s5m+23',
  '2m2m2m2p3p4p5p2s3s4s6s6s6s+2p+13',
  '2m3m4m8p8p2s3s3s4s4s7s8s9s5s+12',
  '3m4m4p4p5p6p7p3s3s3s6s7s8s+5m+22',
  '3m4m5m6m6m6m6m7m8m7p7p5s6s+7s+24',
  '1m2m3m4m5m6m7m8m9m6s7s8s2z+2z+22',
  '4m5m6m3p3p4p5p6p6p7p8p2s3s1s+14',
  '5m5m6m6m7m7m3p4p2s3s4s4s4s+2p+12',
  '1m2m3m6m6m4p5p3s4s5s6s7s8s6p+22',
  '2m3m4m4p5p6p7p7p6s7s8s2z2z+2z+23',
  '1p2p3p4p5p6p7p8p9p2z2z2z5z+5z+23',
  '2m3m3m4m4m5m8m8m5p6p6s6s6s+4p+23',
  '2m3m4m2p3p7p8p9p1s2s3s5s5s+4p+11',
  '2m2m3m3m4m7m7m7m8m9m7p8p9p4m+11',
  '7m8m9m2p3p3s3s5s6s7s2z2z2z1p+22',
  '4m4m5p5p8p8p3s4s4s4z4z5z5z+3s+12',
  '3p3p4p4p5p2s2s3s3s3s6s7s8s2p+21',
  '1m2m3m7m8m9m9p9p8s8s8s5z5z+5z+14',
  '6m7m8m4p4p5p6p6p1s2s3s3z3z+5p+14',
  '2m3m4m5m3p4p4p5p5p6p3s4s5s2m+13',
  '7m7m1p2p3p4p5p6p6p7p8p4s5s+6s+11',
  '1m2m3m4m5m6m3p3p3s4s5s6s7s8s+21',
  '2m3m4m5m5m6p7p2s3s4s5s6s7s+5p+22',
  '4m5m6m7m9m2p2p7p8p9p7s8s9s8m+12',
  '2m3m4m4m5m6m3p3p3s4s5s6s8s+7s+23',
  '3m4m5m6p7p7p7p8p4s4s4s5s7s+6s+24',
  '1m2m3m6m7m8m5p5p6p7p1s2s3s5p+11',
  '4m5m6m7m8m6p6p2s3s4s6s7s8s+9m+12',
  '2m3m4m5m6m7m8m9m2s2s3s4s5s+4m+11',
  '4m5m6m7m8m8m9m9m2p2p4s5s6s7m+11',
  '1m2m3m4m5m6m5p6p1s1s6s7s8s+7p+12',
  '2p2p2p6p6p1s3s4s5s6s7s8s9s+2s+12',
  '2m3m6m7m8m7p8p9p2s2s3s4s5s+1m+21',
  '2m3m4m6m7m5p5p6p6p7p7p8p8p8m+13',
  '5m6m5p6p7p1s2s3s5s6s7s8s8s+4m+13',
  '1m1m3p3p7p7p3s3s5s5s7s4z4z+7s+11',
  '3m4m5m5m6m2s3s4s6s6s7z7z7z+4m+13',
  '2m3m4m5m6m7p8p9p1s2s3s5s5s+7m+12',
  '5p5p6p6p7p8p8p2s2s2s4s5s6s4p+22',
  '3m3m3m4m5m6m7m8m5s5s5s7s8s6s+22',
  '3m4m3p4p5p6p7p8p1s2s3s5s5s2m+13',
  '5m5m6m6m7m7m7m8m9m4s5s9s9s3s+12',
  '6m7m8m3p3p7p8p9p5s6s7z7z7z4s+14',
  '5m5m8m8m6p6p1s1s2s2s4s1z1z+4s+12',
  '2m2m7m7m8m8m9m4p5p6p4s5s6s+6m+24',
  '3p4p5p1s2s3s3s4s6s7s8s9s9s5s+24',
  '6m7m7m8m9m1s2s3s3s4s5s9s9s+8m+21',
  '6m7m7m8m9m1p2p3p5p5p2s3s4s5m+24',
  '1m1m4p5p6p3s4s5s8s8s6z6z6z+1m+21',
  '3m3m4m4m5m7m7m8m8m6s6s6z6z5m+24',
  '5m6m7m1p2p2p3p3p4p4s5s5s5s6s+11',
  '2m3m4m5m5m1p2p3p2s3s5s6s7s+1s+22',
  '3m4m2p2p4p4p4p4p5p6p6s7s8s2m+24',
  '2m2m2m3m3m4p5p6p6p7p5s6s7s+5p+23',
  '3m4m5m6m7m6p7p8p4s5s6s8s8s+8m+21',
  '2m2m4m4m4p4p5p5p1s1s2s2s3z3z+22',
  '5m6m7m7m8m9m6p7p8p4s5s7s7s+3s+13',
  '2m2m3m4m5m7m8m9m2p3p7p8p9p4p+13',
  '1m2m3m1p2p3p1s2s2s2s3s6s7s+5s+13',
  '1m2m3m5m5m6m6m7m7m4p5p8s8s+6p+11',
  '6m7m8m1p2p3p5p6p7p1s1s4s5s+6s+24',
  '1m1m2m2m3p3p8p8p9s9s1z1z2z+2z+23',
  '6m6m7m7m8m8m3p4p5p6s7s1z1z8s+13',
  '6m7m8m2p3p4p1s1s2s3s4s7s8s+6s+22',
  '3m3m7m8m9m7p8p9p1s2s3s3s4s+5s+13',
  '1m2m3m2p2p4s4s7s8s9s5z5z5z2p+23',
  '1m1m7m8m9m6p7p1s2s3s3s4s5s8p+11',
  '1p2p3p5p5p6p6p7p3s4s4s4s5s+4p+24',
  '5m6m1p2p2p3p3p4p5s6s7s3z3z+4m+24',
  '2m2m2m8m8m8m5p5p6p7p8p5s6s+4s+24',
  '3m4m5m7m7m2p3p4p6s7s2z2z2z8s+22',
  '2m2m3p4p5p7p8p4s5s6s7s8s9s9p+12',
  '3m4m6m7m8m1s1s5s5s6s6s7s7s5m+11',
  '5m6m7m3p4p5p6p7p2s2s2s5s5s+2p+21',
  '1m1m6m8m1s2s3s4s4s5s5s6s6s+7m+22',
  '2m3m4m5m6m9m9m3p4p5p5p6p7p+7m+14',
  '3m3m3m4m5m4p5p6p4s5s6s7s7s+7s+24',
  '9m9m4p5p6p8p8p8p1z1z1z6z6z+6z+22',
  '3m3m3m5m7m5p6p7p2s2s3s4s5s+6m+21',
  '2m3m3m4m4m5m5m6m4p5p6p8s8s7m+14',
  '4m5m8p8p9p9p9p4s5s6s2z2z2z6m+21',
  '1m2m3m5m6m7m2p3p9p9p5s6s7s1p+14',
  '6m8m2p3p4p2s3s4s8s8s6z6z6z7m+14',
  '2m3m9m9m1p2p3p3s4s5s6s7s8s+1m+14',
  '2m2m7m9m1p2p3p7p8p9p7s8s9s8m+24',
  '4p4p7p8p9p3s4s5s5s6s7z7z7z+7s+12',
  '3m3m5m6m7m5p7p2s2s2s4s5s6s+6p+14',
  '3m4m5m6p6p7p8p9p3s4s5s5z5z+5z+24',
  '3m3m6m7m7m8m9m2p3p4p3s4s5s+8m+14',
  '1m1m9m9m3p3p7p7p4s4s1z1z7z7z+13',
  '1p2p3p4p5p6p8p8p3s4s5s6s7s8s+13',
  '1m2m3m5m6m3p3p4p5p6p5z5z5z+4m+12',
  '3m4m7m8m9m1p1p3p4p5p7p8p9p+2m+13',
  '2m3m4m7m8m9m2s2s6s7s8s6z6z+6z+12',
  '2m2m4m5m6m2p3p4p5p6p2s3s4s1p+23',
  '4m5m6m2p3p4p2s4s4s5s6s6s6s+3s+23',
  '3m4m5m1p2p3p5p6p7p5s5s5s6s4s+14',
  '1p2p3p4p5p6p7p8p9p6s7s7s7s5s+24',
  '1m2m3m7m8m2s3s4s5s6s6s6s7s+9m+14',
  '2m3m4m5m6m3p3p3p4p5p6p2s2s+4m+13',
  '2m3m4m5m6m7m6p7p4s5s6s4z4z+5p+11',
  '1m1m3m4m5m7m8m4s5s6s7s8s9s9m+21',
  '3p4p5p6p7p8p7s8s9s9s6z6z6z+6s+13',
  '4m4m5m6m7m7m8m9m6p7p8p2s3s+1s+11',
  '2m3m4m5p6p7p7p8p9p5s6s8s8s7s+22',
  '4m4m5m5m6m6m5p5p8s9s9s1z1z+8s+21',
  '6m7m8m2s3s4s7s8s1z1z1z6z6z+6s+12',
  '3m4m4m5m5m6m4p5p6p7p8p2s2s9p+14',
  '5m5m3p4p5p7p8p9p3s4s7s8s9s2s+12',
  '5m6m6m7m8m6p6p7p7p8p8p5s5s+7m+13',
  '6m7m4p5p6p1s2s3s4s4s5s6s7s+8m+11',
  '4p5p6p1s2s3s4s5s6s8s9s4z4z7s+13',
  '3p4p1s2s3s4s5s6s7s8s9s2z2z+5p+14',
  '1m2m3m4p5p6p6p7p8p4s4s6s7s8s+23',
  '2m3m4m2p4p4p5p6p3s3s6s7s8s3p+22',
  '4m5m6m5p6p7p2s2s6s6s7s7s7s+2s+12',
  '5m6m4p4p5p6p6p7p7p8p4s5s6s+4m+22',
  '5m6m7m3p3p4p5p6p7p8p9p6s7s5s+12',
  '4m5m5m6m6m7m1p2p3p5p5p4s5s6s+21',
  '2m3m4m8m8m2p3p4p7p8p6s7s8s+9p+13',
  '2m3m4m5m6m2s3s4s5s5s6s7s8s+7m+14',
  '1m1m2m3m3m4m5m6m6m6m7m8m9m+4m+11',
  '2m3m4m5m6m7m2p2p3p4p5p7p7p7p+24',
  '3m4m5m5p6p7p4s5s6s6s8s8s8s+7s+14',
  '1m2m3m4m5m6m7m8m1p2p3p9p9p+3m+22',
  '2m2m4p5p6p1s1s5s5s6s6s7s7s+1s+21',
  '3p4p4p5p5p6p7p8p4s5s6s7s7s9p+14',
  '3m3m5m6m7m2p2p2p5p6p8s8s8s+4p+13',
  '2m3m3m3m5m6m7m2s3s4s4s5s6s4m+11',
  '3m4m5m8m8m4p5p6p6s8s6z6z6z7s+11',
  '2m3m4m6m6m7m9m7p8p9p7s8s9s+8m+14',
  '1m2m3m2p3p4p5p5p6p7p8p5s6s+4s+12',
  '5m5m6m6m7m7m8m8m7p8p5s6s7s+9p+22',
  '3m4m5m3p4p5p8p8p2s3s4s7s8s+6s+23',
  '4m5m5m6m7m9m9m1p2p3p7p8p9p3m+14',
  '1m2m3m3m3m1p2p3p7p8p9p5s6s+7s+23',
  '4m5m6m8m8m1p2p3p5p6p7p2s3s4s+13',
  '4m5m6m2p3p4p6p7p1s2s3s1z1z5p+22',
  '2m3m4m5p5p7p8p9p2s3s6s7s8s4s+11',
  '1m2m3m5p6p6p7p8p5s6s7s7s7s7p+11',
  '2m3m4m2p2p4p4p4p2s2s5s6s7s+2p+22',
  '3m4m4m7m7m2p2p4p4p2s2s3s3s+3m+12',
  '1m1m3m4m4p5p6p7p8p9p7s8s9s5m+12',
  '2m3m4m3p3p6p7p7p8p8p9p5s6s7s+32',
  '8m8m9m9m1p1p4p4p9p9p3z3z7z+7z+12',
  '6m7m5p5p6p6p7p7p2s2s7s8s9s5m+11',
  '2m2m4m5m6m3p4p5p7s8s8s9s9s+7s+12',
  '3m4m7m8m9m6p6p2s3s3s4s4s5s+2m+22',
  '4m5m6m5p5p3s4s4s4s5s5s6s6s+5s+22',
  '2m3m4m6m8m9m9m6p7p8p5z5z5z+7m+14',
  '1m2m3m6m7m8m4p5p6p7p7p2s3s+1s+22',
  '6m8m1p1p2p2p3p3p3p4p5p2s2s+7m+21',
  '2m3m4m7m7m3p4p5p6p7p2z2z2z+8p+12',
  '4m5m6m9m9m2s3s4s5s6s7s7s8s+6s+12',
  '4m5m4p4p4p6p6p3s4s5s5s6s7s+6m+23',
  '3m4m4m5m5m6m7m7m5p6p1s2s3s+7p+13',
  '5m6m7m4p4p6p7p2s2s2s4s5s6s+8p+21',
  '9m9m5p6p6p7p7p8p3s4s5s5s6s7s+13',
  '3m3m6m7m8m2p2p2p5s6s6s7s7s+8s+12',
  '4m5m6m1p1p2p3p3p9p9p5s6s7s+2p+13',
  '3m4m5m6m7m8m4p5p6p6p3s4s5s6p+14',
  '1m1m1m3m4m5m7p8p9p2z2z4z4z+4z+24',
  '4p5p6p6p7p8p3s3s3s4s6s7s8s4s+24',
  '5m6m7m3p4p5p5p1s2s3s5s6s7s+2p+23',
  '1m2m3m4m5m6m4p5p6p9p9p4s5s6s+14',
  '3m3m6p7p3s4s4s5s5s6s6s7s8s+8p+12',
  '4m5m3p3p3p4p5p5p6p6p7p7s7s3m+13',
  '7m8m9m8p9p1s2s3s6s6s7s8s9s+7p+22',
  '8m8m2p2p6p6p7p7p2z2z4z7z7z+4z+14',
  '1m2m3m6m7m8m6p6p7p8p9p2s3s+1s+11',
  '5m5m7m8m2p3p4p1s2s3s4s5s6s6m+14',
  '3m4m5m6m7m8m9m6p7p8p6s7s8s+9m+23',
  '5m6m7m2p3p4p5p6p7p5s6s3z3z+4s+24',
  '3m4m4m5m5m6m4p5p6p2s2s7s8s6s+13',
  '2m2m4m5m6m2s3s7s8s9s5z5z5z+1s+14',
  '2m3m4m7m8m1p2p3p3p3p6s7s8s+6m+21',
  '5m5m6m7m8m6p7p2s3s4s6s7s8s+8p+21',
  '1m2m3m1p2p3p5p5p7p8p7s8s9s+6p+14',
  '4m5m3p4p5p5p6p7p8p8p2s3s4s6m+11',
  '4m4m7m8m9m5p6p1s2s3s3s4s5s4p+24',
  '2m2m4m5m6m4p5p6p4s5s5s6s6s4s+12',
  '1m2m3m4m4m2p3p4p7p8p9p1z1z+1z+13',
  '2m3m4m7m8m9m5p6p7p8p9p4s4s+7p+12',
  '3m3m3m4m5m7p8p1s2s3s5s6s7s+9p+22',
  '2m2m2m3m4m4m5m7m8m9m2p3p4p6m+22',
  '2m3m4m5m6m2p3p4p9p9p2s3s4s+1m+13',
  '2m3m4m4m5m6m6p7p8p3s4s5s5s+2s+23',
  '3m4m6m7m8m6p7p8p6s7s8s8s8s+2m+12',
  '2m3m4m5m6m7m6p6p3s4s7s8s9s+5s+23',
  '7m7m2p3p4p6p7p7p8p8p6s7s8s+9p+12',
  '3m4m5m1p2p3p5p6p7p6s6s1z1z+1z+12',
  '3m4m5m6m7m8m8m2p3p4p1s2s3s5m+11',
  '2m3m4m5p6p2s3s4s5s6s7s4z4z7p+13',
  '2m3m4m7m8m9m4p5p6p7p8p9p9p+9p+22',
  '4m5m6m4p4p4p5p2s2s2s3z3z3z5p+13',
  '4m5m6m2p3p4p8p8p2s3s4s5s5s8p+13',
  '4m5m5m6m6m4p4p4p4s4s4s7s7s7m+13',
  '7m8m1s2s3s5s6s7s5z5z5z6z6z9m+24',
  '4m5m2p3p4p6p7p8p9p9p4s5s6s+6m+13',
  '2m2m3m4m5m3p4p5p7p7p2s3s4s+2m+22',
  '6m6m7m8m9m4p5p6p7p8p9p5s6s+4s+23',
  '4m5m6m3p3p4p5p6p6p7p8p3s3s+3p+14',
  '4m5m6m4p6p3s3s4s4s5s5s6s6s+5p+21',
  '1m2m3m4m5m9m9m4s5s5s6s6s7s+6m+13',
  '4m4m5m5m6m6m6p6p8p9p6s7s8s+7p+22',
  '3m4m5m6m7m8m1p1p5s6s3z3z3z+7s+23',
  '1m1m4m5m6m7p7p7p5s6s7s7z7z+7z+21',
  '5m6m7m7m8m6p7p7p7p8p5s6s7s9m+14',
  '2m3m4m5m6m7m1p2p3p4s4s6s7s+8s+14',
  '3m4m5m2p2p6p7p8p4s4s5s5s6s6s+23',
  '3m4m5m3p3p4p4p5p4s5s6s4z4z5p+22',
  '5m6m7m2p3p4p4p3s4s5s7s8s9s+1p+12',
  '4m5m6m4p4p5p5p8p8p8p6s7s8s5p+11',
  '5m5m3p3p4p4p5p5p2s3s4s6s6s+6s+14',
  '4m4m2p2p5p5p6p6p2s3s3s5s5s+2s+12',
  '3m4m5m7p8p9p2s2s3s4s5s7s8s9s+12',
  '8m8m3p4p5p1s2s3s4s5s6s7s9s+8s+12',
  '2m3m4m7m8m9m2p2p4p5p6s7s8s+3p+23',
  '1m2m3m5m5m4p5p7p8p9p7s8s9s+6p+14',
  '1m1m4m5m6m1p2p2p3p3p4s5s6s4p+11',
  '3m4m2p3p4p6p6p7p8p9p4s5s6s2m+14',
  '7m8m9m3p4p6p7p8p9p9p2s3s4s2p+11',
  '3m3m4m5m1p2p3p4p5p6p2s3s4s+6m+24',
  '1p2p3p3p4p5p3s4s6s6s5z5z5z+5s+14',
  '4m5m6m7m7m7m8m5p6p7p4s5s6s+8m+11',
  '3m3m3m5m5m5m3p3p4s4s6s7s8s+3p+12',
  '4m5m6m7m7m7m5p5p2s3s4s6s8s+7s+21',
  '3m4m5m6m7m8m1p2p3p7p7p2s3s+1s+23',
  '1m2m3m5m5m7p8p9p1s2s3s5s6s+4s+21',
  '1m2m3m5m6m1p1p2p3p4p3s4s5s+7m+12',
  '2m3m4m7m8m9m3p4p5s5s6s7s8s5p+11',
  '2m3m4m5p6p7p8p8p8p3s5s6s7s3s+12',
  '1m2m3m2s2s4s5s6s7s8s9s1z1z+1z+13',
  '1m2m3m4m5m6m6p7p8p4s5s6s6s3s+22',
  '5m6m2p3p4p4p5p6p4s5s6s8s8s+4m+24',
  '4m5m4p4p6p6p6p2s3s4s8s8s8s+6m+12',
  '6m8m2p3p4p6p6p8p8p8p5s6s7s+7m+21',
  '5m6m7m9m9m4p5p6p3s4s6s7s8s+5s+12',
  '2m3m4m5m6m8m8m5p6p7p4s5s6s+1m+24',
  '3m4m7m8m9m4s4s6s7s8s5z5z5z+2m+24',
  '2p2p3p4p5p7p8p9p1s2s3s5s6s7s+14',
  '2m3m4m5m5m5m5p6p7p3s3s6s7s5s+23',
  '3m4m6m6m6m2p2p4s5s6s5z5z5z5m+14',
  '3m4m5m6m6m6m6p6p2s2s1z1z1z+2s+13',
  '3m3m5m6m7m2p3p4p5p6p7p4s5s+6s+22',
  '2m3m4m6m7m7p8p9p6s6s7s8s9s5m+22',
  '1p3p4p5p6p7p7p7p8p9p7z7z7z+2p+14',
  '4m5m6m5p5p6p6p7p7p2s2s6s6s+2s+14',
  '7m7m7m4p4p8p9p3s3s4s4s5s5s+7p+13',
  '3m4m5m5p5p5p6p7p2s3s7s8s9s+4s+11',
  '6m7m8m7p8p9p3s4s3z3z3z5z5z2s+23',
  '3m4m5m1p1p5p6p7p2s3s4s5s6s+4s+21',
  '4p4p5p5p6p8p8p3s4s5s7s7s7s6p+13',
  '3m4m4m5m6m6p7p8p4s4s5s6s7s+2m+12',
  '3m4m5m6m7m1s2s3s4s5s6s4z4z5m+23',
  '5m6m3s4s5s5s5s6s6s6s6z6z6z4m+11',
  '6p7p7p7p8p3s4s5s6s7s8s5z5z+5z+14',
  '3m3m3m6m7m8m2p2p6p7p8p3s4s+2s+13',
  '2p3p4p6p7p9p9p3s4s5s6s7s8s+5p+14',
  '8m8m1p2p3p4p5p7p8p9p7s8s9s+3p+22',
  '1m2m3m7m8m9m1p2p3p1s2s9s9s+3s+14',
  '6m7m8m4p4p7p8p2s3s4s6s7s8s+9p+23',
  '3p4p5p6p7p8p3s4s4s5s5s3z3z+6s+11',
  '2m2m4m5m6m7m8m9m4p5p6p3s4s+2s+11',
  '8m8m9m9m9m3p3p4p4p5p5p4s5s6s+23',
  '2m2m3m5m3p4p5p3s4s5s8s8s8s+4m+11',
  '1m2m3m6m7m8m3s5s8s8s1z1z1z+4s+12',
  '1m2m3m1p1p3p4p5p6p7p3s4s5s+8p+24',
  '5m6m7m7m4p5p6p2s3s4s5s6s7s+7m+11',
  '4m4m7m7m7m2s2s3s3s4s4s7s7s4m+12',
  '6m7m5p6p7p8p8p2s3s4s5s6s7s+8m+12',
  '2m3m7m8m9m7p8p9p4s5s6s4z4z1m+21',
  '3p4p5p6p7p8p1s2s2s2s3s6s7s+8s+13',
  '4m5m6m2p3p4p5p5p4s5s7s8s9s+3s+13',
  '1m1m7m8m9m2p3p4p7p8p9p4s5s3s+23',
  '3m4m5m1p2p3p4p5p4s4s6s7s8s+6p+11',
  '3m4m5m6m7m6p6p6p7p8p4s5s6s+5m+24',
  '6m7m8m1p2p3p5p6p7p5s5s7s8s+6s+22',
  '4p4p2s3s4s5s6s7s1z1z3z3z3z+1z+11',
  '4m5m6m1p1p2p3p6p7p8p6s7s8s+4p+13',
  '4m4m3p4p4p5p5p3s3s3s7s8s9s+3p+12',
  '1m2m3m5p6p6p7p7p3s4s5s3z3z8p+11',
  '5m6m6m7m8m5s5s5s6s7s5z5z5z7m+22',
  '1m2m3m2p3p4p5p6p7p5s5s7s8s+9s+13',
  '4p4p5p6p7p7p8p2s3s3s4s4s5s+6p+14',
  '1m2m3m5m6m2p3p4p4s5s6s7s7s7m+14',
  '3m3m7m8m9m5p6p7p7s7s4z4z4z+7s+14',
  '3m3m6m7m8m4p5p6p7p8p6s7s8s3p+22',
  '1m1m2m2m3m3m6m7m8m4s4s6s7s+5s+22',
  '1m2m3m4m4m6m7m6p7p8p4s5s6s8m+21',
  '1m2m3m6m6m5p6p7p2s3s6s7s8s1s+11',
  '2m3m4m7m8m6p7p8p2s2s6s7s8s6m+11',
  '2m3m5m6m7m3p4p5p8p8p1s2s3s1m+12',
  '7m8m9m5p6p8p8p6s7s8s2z2z2z+4p+12',
  '3m4m5m6m6m5p7p2s3s4s5s6s7s+6p+14',
  '3m4m3p4p5p1s2s3s3s4s5s9s9s+2m+23',
  '2m3m4m6m4p4p5p5p6p6p6s7s8s+6m+14',
  '6m7m8m3p3p3p5p5p6p8p4s5s6s+7p+22',
  '7m8m9m2p2p5p6p5s6s7s7s8s9s+4p+14',
  '6m8m2p2p2p5p5p5p6p7p3s4s5s+7m+24',
  '4m5m4p4p5p6p7p2s3s4s6s7s8s6m+23',
  '6m7m8m2p3p4p7p7p4s5s7s8s9s+6s+12',
  '7m7m1p4p4p6p6p7p7p4s4s3z3z+1p+14',
  '3m4m7m8m9m2p3p4p7p7p5s6s7s5m+21',
  '4m4m5m5m5p6p6p2s2s8s8s5z5z+5p+22',
  '2m3m4m9m9m4p4p5p5p6p8s8s8s6p+11',
  '4m4m4p5p6p7p8p9p3s4s6s7s8s2s+21',
  '3p4p5p6p7p1s1s2s2s3s3s5s5s5p+24',
  '2m3m4m6m7m8m9m9m2p3p7p8p9p+4p+21',
  '5m5m4p5p1s2s3s7s8s9s5z5z5z3p+22',
  '3m4m5m5m6m7m3p5p6p6p6p7p8p+4p+13',
  '3m4m2p3p4p9p9p3s4s5s7s8s9s2m+21',
  '5m6m7m7p9p1s1s2s2s3s3s5s5s+8p+22',
  '2m3m4m4p5p6p8p8p2s3s4s6s7s+8s+12',
  '5m5m5m6m7m8m3s3s6s6s1z1z1z6s+23',
  '4m5m2p3p4p2s2s2s3s3s3s4s5s+3m+12',
  '2m2m3m4m5m6m7m8m7p8p4s5s6s6p+11',
  '3m3m2p3p4p4p5p6p7p8p3s4s5s+3p+13',
  '3p3p3p7p8p9p6s7s8s1z1z5z5z+5z+21',
  '3m4m5m5p5p7p7p7p8p8p7s7s7s+8p+12',
  '8m8m1p2p3p3p4p5p6p7p8p2s3s+1s+12',
  '4m4m6m7m8m4p5p5p6p7p4s5s6s+6p+11',
  '1m1m7m7m8m9m9m4p5p6p3s4s5s+8m+12',
  '6m7m7m8m8m9m3p4p5p7s8s1z1z+9s+24',
  '3m4m4m5m5m6m4p5p6p7p7p5z5z+5z+12',
  '3m4m5m7m7m6p7p8p2s4s6s7s8s3s+12',
  '4m5m7m7m3p4p5p7p8p9p2s3s4s+3m+23',
  '2m2m2m4m5m8m8m5p6p7p5s6s7s+3m+13',
  '1m1m2m3m4m4p5p7p8p9p5s6s7s+3p+12',
  '1p2p3p5p5p6p6p7p7p2z2z3z3z+2z+11',
  '3m4m5m8m8m3p4p1s2s3s5s6s7s+2p+23',
  '2m2m4m4m6m6m8m8m6p6p7p7z7z+7p+21',
  '3p3p4p5p6p2s3s3s4s4s7s7s7s+5s+24',
  '5m5m4p4p5p5p6p6p6s7s7s8s9s+8s+14',
  '3m4m5m8m8m7p8p9p3s4s5s7z7z+7z+12',
  '2m3m4m3s4s4s5s5s7s8s9s6z6z+3s+11',
  '4m5m2p3p4p5p6p7p8p8p2s3s4s6m+12',
  '4m5m6m6m6m7m8m9m2s3s4s4s5s3s+11',
  '5m5m7m8m9m6p6p6p7p9p7s8s9s+8p+14',
  '9m9m1p6p6p1s1s2s2s3s3s6z6z+1p+12',
  '1m1m3m4m5m3p4p5p2s3s7s8s9s+1s+12',
  '2m3m4m5m6m7m3p4p5p5p5p7s8s9s+24',
  '2s2s6s7s8s2z2z2z3z3z3z7z7z2s+13',
  '6m7m8m2p3p4p4p5p6p7p8p7s7s+3p+13',
  '6m7m7m8m8m9m7p8p9p9p7s8s9s+6p+11',
  '5m6m7m5p6p7p8p9p8s8s6z6z6z+4p+14',
  '2m4m6m6m2p3p4p4p5p6p4s5s6s3m+14',
  '6m7m2p3p4p4p4p7p8p9p5s6s7s5m+14',
  '2m2m6m7m6p7p8p3s4s5s6s7s8s8m+11',
  '4m5m6m7m8m9m6p7p2s3s4s7s7s+8p+22',
  '3m3m6m7m8m3p3p4p4p5p6p7p8p2p+13',
  '1m2m3m5m6m7m8m9m6p7p8p8s8s+4m+11',
  '6m7m2p3p4p7p8p9p1s2s3s3z3z+5m+12',
  '2m3m3m4m4m5m7p8p9p4s5s6s6s+3s+13',
  '3m4m4m5m6m3p3p5p6p7p5s6s7s+2m+12',
  '1m2m3m6m7m8m1p1p6p7p8p3s4s2s+21',
  '2m3m4m6m7m8m1p1p3s4s5s6z6z6z+21',
  '4m4m1p2p3p5p6p5s5s6s6s7s7s7p+14',
  '1m2m3m5m6m1p1p4p5p6p4s5s6s+7m+21',
  '2m2m5p6p6p7p7p8p1s2s3s7s8s9s+12',
  '4p4p5p6p7p7p8p9p3s4s6s7s8s5s+12',
  '2m2m4m4m5m5m6p6p1s1s3s3s5s+5s+22',
  '5m6m7m9m9m2p3p2s3s4s5s6s7s4p+23',
  '2m3m4m6m7m8m8m2p3p4p4s5s6s+5m+24',
  '1p2p3p3p4p5p6p7p8p6s6s7s8s+6s+13',
  '2m3m4m4m5m6m7m8m7p7p6s7s8s3m+11',
  '2m3m6m7m8m1p1p5p6p7p5s6s7s+1m+21',
  '5m6m3p4p5p6p7p8p3s4s5s4z4z+7m+32',
  '4m4m6p8p2s2s3s3s4s4s6s7s8s+7p+13',
  '2m2m6m7m8m5p6p7p4s5s5s6s7s6s+14',
  '4m4m5m5m6m1p2p3p5p6p7p6s6s+3m+22',
  '5m6m7m7m7m7p8p1s2s3s4s5s6s+6p+13',
  '3m4m4m4m5m5p6p7p7p8p2s3s4s9p+22',
  '4m6m2p2p6p7p8p2s3s4s6s7s8s+5m+14',
  '4m4m5p6p7p7p8p9p3s4s5s6s7s8s+24',
  '6m7m7p8p9p5s6s7s7s8s9s1z1z5m+24',
  '6m7m8m8m8m6p7p8p1s2s3s5s6s4s+22',
  '6m7m8m2p3p4p4p5p6p1s1s5s6s4s+21',
  '7m7m8m8m9m9m4p4p7p8p2s3s4s+9p+24',
  '4m5m6m4p4p4p6p7p3s3s4s5s6s5p+21',
  '1m2m3m4m5m6m7m8m3z3z5z5z5z+6m+21',
  '3p3p3p6p6p7p7p7p7p8p4s5s6s+5p+12',
  '2m2m7m7m9m9m1p1p1z1z3z3z5z+5z+13',
  '7m8m9m7p8p9p3s4s5s8s9s3z3z+7s+21',
  '1m1m4m5m6m7m8m9m5p6p5s6s7s+7p+22',
  '3m4m5m7m8m1s2s3s4s5s6s3z3z9m+21',
  '3m3m6m7m3p4p5p5s6s7s7s8s9s+5m+24',
  '7m8m9m3p4p5p9p9p2s3s7s8s9s+1s+14',
  '1m2m3m4m5m6m2p3p5s6s7s8s8s+1p+11',
  '5m5m1p2p3p1s2s3s4s5s6s8s9s+7s+24',
  '3m5m2p3p3p4p4p5p3s4s5s6s6s+4m+12',
  '1m2m3m5p5p7p8p3s4s5s7s8s9s+9p+12',
  '1m1m3m3m3p3p6p6p2s2s5s9s9s+5s+13',
  '2m3m4m4p4p6p6p6p7p7p7p3s5s4s+24',
  '5m5m2p3p4p6p7p4s5s5s6s6s7s+8p+13',
  '3m3m5p5p8p8p9p4s4s8s8s4z4z+9p+23',
  '6m6m7m7m7m7p9p5s5s5s3z3z3z+8p+14',
  '1m2m3m7m8m9m1s1s5s6s7s8s9s+7s+13',
  '3m4m5m3p3p4p6p2s3s4s5s6s7s5p+21',
  '5m6m7m4p5p7p8p9p4s4s6s7s8s+3p+24',
  '2m3m4m5m6m7m8m8m8m3p5p7s7s4p+13',
  '5m5m2p3p4p4s4s4s7s7s7s9s9s9s+12',
  '7m8m9m2p3p4p6p7p8p1s1s4s5s3s+11',
  '3m4m5m3p4p6p7p8p3s4s5s9s9s2p+21',
  '4m5m6m5p6p7p3s3s5s5s7s7s7s+5s+14',
  '2m3m4m5m6m7m9p9p3s4s5s3z3z3z+13',
  '1m1m7m8m9m5p6p7p4s5s7z7z7z3s+23',
  '4m5m6m7m7m7m2p2p3p5p3s4s5s4p+12',
  '2m3m4m2p3p4p3s3s6s7s8s6z6z+6z+11',
  '3m4m4m5m5m6m2p3p3p4p4p3z3z2p+12',
  '6m7m8m7p8p9p5s5s5s3z3z7z7z+7z+13',
  '1m2m3m4m5m5p6p7p2s2s5s6s7s+3m+11',
  '6m7m7m8m9m3s4s5s6s6s7s8s9s+5m+24',
  '2p3p4p5p6p7p7p8p3s4s5s8s8s+9p+13',
  '2m3m4m5p6p8p8p2s3s4s6s7s8s7p+14',
  '6m7m8m3p4p5p2s2s3s4s4s5s5s+3s+21',
  '3m3m6m8m2p3p4p5p6p7p3s4s5s7m+11',
  '1m2m3m2p3p4p8p8p1s2s3s6s7s8s+11',
  '3m4m5m7m8m9m1p1p5p6p2s3s4s7p+11',
  '3m5m8m8m8m6p6p3s4s5s5s6s7s+4m+24',
  '4m5m6m1p1p2p3p7p8p9p7s8s9s+1p+14',
  '2m2m9m9m4p4p2s2s6s6s8s8s6z+6z+14',
  '2m3m4m4m5m6m6m7m2s2s5s6s7s5m+24',
  '3m3m1p2p3p4p5p6p2s3s4s6s7s+8s+13',
  '5m6m1p2p3p5p6p7p1s2s3s5s5s7m+11',
  '5m5m6m6m3p3p7s7s8s8s9s6z6z9s+23',
  '5m6m7m4p5p6p7p7p2s3s4s6s7s+5s+13',
  '3m4m3p3p4p5p6p2s3s4s5s6s7s2m+14',
  '1m2m3m6p7p8p3s4s6s7s8s4z4z5s+11',
  '2m2m3m4m5m3p4p5p7p7p7p3s3s+2m+24',
  '2m3m4m5m2p3p4p6p7p8p4s5s6s+2m+22',
  '4m4m8m8m8m2p2p2p4p5p6p3s3s+4m+24',
  '2m3m6p7p8p4s5s5s5s5s6s8s8s4m+13',
  '3m3m6m7m6p7p8p1s2s3s6s7s8s5m+14',
  '4m4m5m5m6m6m9m9m3s3s5z5z7z+7z+12',
  '5m6m6m4p4p6p6p7p7p3s3s4s4s5m+12',
  '1m2m3m4m5m3p4p5p7p7p6s7s8s3m+14',
  '2m3m4m6m6m3p4p4p5p5p1s2s3s6p+23',
  '5m6m7m4p4p7p8p1s2s3s6s7s8s9p+11',
  '6m7m8m2p3p4p6p7p8p6s8s7z7z+7s+13',
  '2m3m3m4m4m5m6p6p5s5s6s6s7s7s+11',
  '6m7m8m2p2p6p7p8p2s3s4s3z3z+3z+13',
  '2m2m5m5m5m2p3p3p4p4p3s4s5s+2p+12',
  '9m7p7p8p8p3s3s4s4s7s7s4z4z+9m+13',
  '2m3m4m4m5m6m3p4p1s1s2s3s4s+2p+24',
  '2m3m4m7m8m9m4p4p3s4s4s5s5s+6s+12',
  '2p3p4p5p6p7p1s1s3s4s7s8s9s+5s+22',
  '1m2m3m3m4m5m3p3p4p5p6p2s3s4s+13',
  '2m2m6p7p7p8p9p6s7s8s7z7z7z5p+21',
  '6m6m4p4p2s7s7s9s9s2z2z6z6z2s+13',
  '6m6m2p4p4s5s6s7s8s9s6z6z6z3p+13',
  '8m8m9p9p1s1s2z3z3z5z5z7z7z+2z+22',
  '1m1m2m2m2p7p7p8p8p9p9p5s5s+2p+23',
  '4m5m6m4p4p7p8p9p3s4s7s8s9s+5s+24',
  '6m7m8m4p5p6p2s3s5s5s6s7s8s+4s+13',
  '5m6m7m3p4p5p6p8p5s5s6s7s8s+7p+11',
  '3m3m5p6p7p2s3s3s4s4s5s6s7s5s+21',
  '2m3m5m6m7m3p4p5p7p8p9p8s8s1m+24',
  '8m8m2p3p4p6p6p3s4s5s7s7s7s+8m+12',
  '3m4m5m6p7p7p8p9p1s2s3s4s4s+5p+24',
  '5m6m7m9m9m1p2p3p5p6p7p6s7s+5s+13',
  '6m6m7m7m8m6p7p7p8p8p9p9s9s+5m+12',
  '6m8m3p3p4p4p5p5p2s3s4s6s6s7m+13',
  '5m5m6m6m8m8m6p6p1s3s3s5s5s1s+11',
  '4m5m6m7p8p9p2s2s4s5s7s8s9s6s+12',
  '2m3m6m7m8m7p8p9p3s3s5s6s7s1m+21',
  '2m3m4m6m7m8m3p4p5p9p9p3s4s+2s+12',
  '1m2m3m2p2p7p8p2s3s4s7s8s9s+6p+12',
  '6m7m7m8m9m3p3p1s2s3s5z5z5z+8m+24',
  '5m5m7m8m2p3p4p5p6p7p6s7s8s6m+12',
  '4m4m1p1p2p2p3p3p5p5p4s7z7z+4s+11',
  '3m4m1p2p3p4p4p6p7p8p7s8s9s+2m+13',
  '4m5m6m2p3p4p6p8p2s2s5s6s7s7p+22',
  '3m3m3m6m6m4p5p6p6p7p8p6s8s+7s+14',
  '1m2m3m6m7m1p2p3p5p6p7p1s1s8m+14',
  '1p2p3p7p8p5s5s6s6s7s7s8s8s+9p+13',
  '1m2m1p2p3p8p8p1s2s3s6s6s6s3m+24',
  '3m3m4m4m9m9m9p9p5s8s8s9s9s+5s+14',
  '7m8m4p5p6p6p7p8p2s2s4s5s6s+9m+13',
  '1m2m3m4m5m6m4p4p2s3s5s6s7s+1s+13',
  '3m4m5m6m6m4p5p6p4s5s7s8s9s3s+23',
  '5m6m7m7m8m9m8p8p5s6s7s7s8s+9s+13',
  '2m2m3m3m4m4m7m8m9m7p7p5s7s+6s+24',
  '7m8m3p4p5p8p8p2s3s4s6s7s8s+9m+11',
  '3m3m3m4m5m6m6m6m7m7m7m5z5z+5z+14',
  '2m3m4m6m7m8m2p2p4p5p6p7s8s9s+22',
  '5m6m2p3p4p4p4p6p7p8p5s6s7s4m+24',
  '4m4m4m5m6m4p5p6p4s4s6s7s8s+4m+23',
  '4m5m6m2p2p6p6p6p2s2s3s4s5s+2p+11',
  '1m1m3m3m5p5p9p9p6s6s8s8s3z+3z+13',
  '6p7p7p8p8p9p3s4s5s6s7s9s9s2s+12',
  '1m1m2p2p9p9p5s5s6s6s7s7s4z+4z+21',
  '2p3p4p7p7p9p9p9p4s5s7z7z7z+6s+14',
  '1m2m3m4m5m6m1p1p6p7p4s5s6s+8p+21',
  '5m6m7m1p1p2p3p6p7p8p5s6s7s1p+21',
  '5m5m7p8p9p3s4s7s7s8s8s9s9s+5s+14',
  '5m5m7m7m7p7p3s8s8s9s9s3z3z3s+11',
  '2m3m4m5m6m7m8m3p4p5p5s6s7s+2m+12',
  '3m3m3m3p4p5p6p6p3s4s4s5s6s2s+22',
  '3m4m5m7m8m6p7p8p6s7s7s7s8s+9m+23',
  '2m3m5m6m7m5p6p7p1s2s3s5s5s+1m+12',
  '3m4m5m7m7m2p3p3p4p4p3s4s5s+2p+21',
  '2m3m4m7m8m9m6p7p8p3s4s4s4s2s+21',
  '3m4m5m7m8m2p3p4p5s5s7s8s9s6m+23',
  '4m5m3p4p5p5p6p7p3s3s4s5s6s+6m+14',
  '2m3m4m5m5m6m7m8m5p6p7p6s7s5s+23',
  '2m3m6m7m8m3p4p5p5p6p7p9p9p+1m+11',
  '7m7m7p7p8p8p9p9p5s6s6z6z6z+4s+13',
  '6m7m8m3p4p5p6p7p8p8p6s7s8s+2p+23',
  '4m5m6m2p3p4p6p7p8p3s4s5s6s+3s+21',
  '3m4m5m5m5m5p6p7p7p8p9p4s5s+6s+24',
  '2m3m7m8m9m1p2p3p5s6s7s4z4z4m+12',
  '6m7m2p2p3p4p5p7p8p9p6s7s8s5m+24',
  '4p4p7p8p9p1s2s3s7s7s6z6z6z4p+22',
  '2m3m4m2p3p4p5p5p2s3s5s6s7s1s+12',
  '3m3m4m5m4p4p4p5p6p7p5s6s7s6m+11',
  '4p5p6p7p8p9p1s2s3s5s6s8s8s+4s+13',
  '1m2m3m6m7m8m1p2p3p2s3s6s6s4s+12',
  '3m3m5m5m6m7m8m6p7p8p5s6s7s+3m+12',
  '3m4m6m7m8m5p6p7p8p8p2s3s4s2m+14',
  '2m2m4m5m6m3p4p5p4s5s7s8s9s+3s+24',
  '5m5m5m2p3p4p4p6p4s4s5s6s7s+5p+12',
  '1m1m1m2m3m3m4m5m3p4p7s8s9s+2p+23',
  '3m4m5m7m8m9m4p5p7p7p4s5s6s6p+22',
  '3m4m5m2p3p4p5p5p6p7p8p6s7s8s+12',
  '4m5m6m3p4p4p4p7p8p9p7s8s9s+2p+23',
  '1m2m3m3p4p6p6p7p7p8p8p9p9p+2p+13',
  '2m3m4m4m5m4p4p4p8p8p6s7s8s+6m+23',
  '4m5m6m3p3p4p5p6p7p8p9p7s8s+9s+12',
  '6m7m8m4p5p6p9p9p7s8s4z4z4z9s+14',
  '1m2m3m5m6m7m3p4p5p9p9p6s7s+8s+14',
  '2m3m5p5p7p8p9p3s4s5s7s8s9s1m+12',
  '7m8m9m1p2p2p3p3p4p5p5p6p7p5p+11',
  '5m6m7m7m8m9m5p6p7p3s4s9s9s5s+21',
  '3m4m4p4p5p6p7p4s5s5s6s6s7s2m+12',
  '2m3m4m3p4p5p1s2s3s3s3s5s6s7s+14',
  '1m1m3m4m5m6m7m8m2p3p4s5s6s+4p+13',
  '3m4m5m5p7p3s3s3s4s5s6s6s6s+6p+14',
  '3m4m5m5m5m6m7m8m4p5p7s8s9s+6p+24',
  '3m4m5m6m7m8m2p2p5p5p2s3s4s+5p+11',
  '1m2m3m5m6m2p2p5s6s7s7s8s9s+7m+11',
  '6m7m1p1p6p7p8p2s3s4s4s5s6s+8m+23',
  '5m6m7m4p5p7p8p9p6s7s8s9s9s+6p+22',
  '6m6m7m7m8m3p4p5p6p7p8p3z3z+8m+14',
  '1m1m1m2m3m7m8m7s7s8s8s9s9s+9m+12',
  '2m3m4m5m6m9m9m2p3p3p4p4p5p7m+11',
  '3m4m5m2p3p4p1s1s2s3s4s6s7s5s+13',
  '1m2m3m7m8m1p2p3p6p7p8p6s6s+9m+12',
  '2m3m4m5m5m5m6m7p8p9p4s5s6s1m+21',
  '5m6m7m3p4p4p5p5p1s2s3s4s4s3p+13',
  '1m1m1m3p3p7p8p1s2s3s5z5z5z+9p+23',
  '7m8m8m9m9m3p4p5p6p7p8p3s3s+7m+24',
  '2m2m5m6m7m1p2p3p2s3s4s7s8s6s+14',
  '1m2m3m7m7m6p6p6p3s5s1z1z1z+4s+13',
  '4m5m6m7m8m6p6p7p8p9p1s2s3s+9m+21',
  '3m3m4m4m5m9m9m1p2p3p5p6p7p+2m+13',
  '1m1m7m7m1p1p5p5p7p7p5s5s2z+2z+11',
  '5m6m6m7m7m8m4p5p3s3s4s5s6s6p+23',
  '7m8m9m2p3p3p4p4p5p5p2s3s4s2p+11',
  '2m3m4m6m7m8m3p4p2s3s4s7z7z2p+12',
  '1p3p4p4p7p7p7p5s5s6s6s7s7s+2p+12',
  '1m2m3m5m6m3p3p3p4p5p2s3s4s+4m+24',
  '3m4m5m1p2p3p7p8p5s6s7s8s8s+9p+13',
  '2m3m4m7m8m9m4p4p6p7p8p6z6z6z+24',
  '2m3m4m1p2p3p5p6p8p8p4s5s6s7p+24',
  '5m6m7m4p5p6p2s3s4s5s6s7s8s2s+14',
  '3m4m5m2p3p4p8p9p1z1z5z5z5z+7p+14',
  '1m1m2p3p4p5p6p7p1s2s3s4s5s3s+21',
  '3p4p5p7p7p2s3s4s5s5s5s6s8s+7s+14',
  '6m7m8m2p3p4p3s3s5s5s6s6s7s+7s+11',
  '1m2m3m3m4m4m5m5m7m8m9m4p4p6m+22',
  '6m7m8m4p4p6p7p8p6s6s7s8s8s+7s+21',
  '1m1m6m7m8m1p2p3p6p7p5s6s7s+8p+22',
  '3m4m5m8p8p8p3s4s5s5s6s7s8s5s+23',
  '3m3m5m6p6p1s1s2s2s7s7s4z4z+5m+13',
  '3m4m6m6m2p2p3p3p4p4p4s5s6s+5m+22',
  '4m4m4m3p3p4p5p6p2s2s4s5s6s+2s+14',
  '2m2m6m7m4p5p6p1s2s3s3s4s5s+8m+24',
  '1m2m3m5m6m3p4p5p8p8p3s4s5s7m+21',
  '3m4m5m6m7m8m4p5p2s3s4s7s7s+3p+22',
  '2m3m3m4m4m5m6m7m7p8p9p2s2s8m+23',
  '3m5m5m5p5p4s4s7s7s9s9s3z3z3m+14',
  '5m6m7m2p2p6p7p8p3s4s5s7s8s9s+22',
  '1m2m3m5p5p7p8p9p2s3s4s6s7s8s+22',
  '7m8m9m9m5p6p7p1s2s3s6s7s8s+9m+14',
  '2m3m4m6p7p8p1s1s2s3s4s6s7s+8s+22',
  '6m7m5p6p7p9p9p4s5s6s7s8s9s+5m+23',
  '2m3m2p3p4p6p7p8p3s3s4s5s6s1m+13',
  '6m6m4p4p5p5p6p6p2s3s5s6s7s+1s+14',
  '4m4m5p5p6p6p7p7p1s3z3z4z4z1s+14',
  '3m4m5m4p5p6p7p8p1s1s4s5s6s6p+11',
  '2m2m3m4m4m5m5m2s3s4s6s7s8s+6m+24',
  '1m2m6m6m1p2p3p1s1s2s2s3s3s3m+13',
  '6m8m5p5p6p7p8p3s4s5s6s7s8s7m+23',
  '2m2m2m4m5m6m3p3p3s4s5s6s7s+8s+24',
  '2m3m4m5m5m6m6m7m5p6p7p8p8p+1m+14',
  '4m4m4p5p6p7p9p6s7s8s5z5z5z+8p+12',
  '1m1m6m7m8m2p3p1s2s3s1z1z1z4p+14',
  '1m2m3m4m6m7m8m9m3p4p5p7s7s5m+11',
  '5m6m7m5p6p7p3s3s3s4s4s5s6s+2s+14',
  '2m3m4m6m7m1p2p3p3p4p5p7p7p8m+11',
  '3m4m5m5m6m6m7m8m6p6p6s6s6s4m+21',
  '2m2m2p3p4p5p5p6p7p7p3s4s5s+6p+12',
  '4m5m3p4p5p7p8p9p3s4s5s7s7s+6m+22',
  '1p2p3p4p4p5p5p6p6p5s7s7z7z6s+11',
  '6m8m6p7p8p9p9p6s7s7s8s8s9s7m+22',
  '7m8m9m3p4p5p5p5p2s3s6s7s8s+1s+22',
  '3m4m3p4p5p6p6p2s2s2s4s5s6s+5m+22',
  '4m5m6m3p3p4p5p6p7p8p5z5z5z9p+14',
  '1m2m3m6m6m7m7m8m8m6s7s8s9s+9s+11',
  '4m5m6m7m8m9m1p1p4p5p4s5s6s+3p+12',
  '6m7m8m6p7p8p2s3s5s6s7s1z1z1s+24',
  '2m3m4m6m7m4p5p6p7p8p9p3s3s5m+23',
  '1m1m7m8m9m2s3s5s6s7s7s8s9s+1s+24',
  '2m3m4m5m5m6m6m7m2p2p6p7p8p7m+22',
  '2m2m6m6m6m3p4p4s4s5s5s6s6s2p+11',
  '5m6m7m1p2p3p5p5p6p7p8p2s3s+4s+12',
  '5m6m7m4p6p8p8p8p5s6s6s6s7s+5p+11',
  '4m4m4m5m6m3p4p5p7p8p2s3s4s+9p+12',
  '1m2m3m4m5m6m7m8m9m5p5p7z7z+5p+11',
  '1m2m3m4m5m6m7m8m9m2p4p9s9s3p+22',
  '7m7m8m8m9m9m1p2p3s4s5s7s7s3p+23',
  '4m5m6m6m5p6p7p1s2s3s4s5s6s6m+23',
  '5m6m3p4p5p6p7p8p1s1s2s3s4s+7m+22',
  '3m4m5m6m2p2p2p4p5p6p3s4s5s3m+12',
  '2p2p3p4p5p5p6p7p3s3s3s5s6s+7s+14',
  '1m2m3m5p6p7p3s4s6s7s8s1z1z2s+23',
  '2m3m4m6m7m3p3p3p4p4p6p7p8p5m+22',
  '1m2m3m4m5m6m7p7p7p8p9p2s3s+1s+21',
  '6m7m8m2p3p4p7p8p9p6s6s1z1z+1z+12',
  '9m9m4p5p6p6p7p8p2s3s4s2z2z+2z+12',
  '6m7m8m1p1p3p4p6p7p8p4s5s6s2p+11',
  '1p1p3p4p5p6p7p8p1s2s3s3s4s+5s+14',
  '2m3m4m5m5m5p6p1s2s3s6s7s8s+7p+24',
  '1m2m3m7p8p1s2s3s4s4s5s6s7s+6p+12',
  '4m4m7p8p9p2s3s4s7s9s6z6z6z+8s+13',
  '3m3m7m8m3p4p5p2s3s4s7s7s7s+6m+21',
  '3m4m5m5p6p3s4s5s6s7s8s9s9s+7p+13',
  '2m3m4m7m7m1p2p3p5s6s6s7s8s+7s+22',
  '2m2m3m6p6p7p7p9p9p2s2s4s4s+3m+12',
  '5m6m1p2p3p4p5p6p1s1s3s4s5s+7m+12',
  '5m6m6m7m7m1p2p3p6p7p8p8s8s+8m+24',
  '4m5m5m6m6m7m8m9m4p5p6p4s4s+4m+21',
  '3m4m1p2p3p6p6p1s2s3s3s4s5s+5m+11',
  '1m1m1m2m3m4m6m6m7m8m9m5z5z+5z+14',
  '3m4m5m2p2p2p4p4p4p6p7p7s7s+8p+24',
  '6m7m8m6p6p6p7p4s5s6s6s7s8s+7p+14',
  '4m4m4p5p6p2s3s4s4s5s6s6s7s+8s+13',
  '1m2m3m6p7p3s3s4s5s6s5z5z5z+8p+14',
  '2m3m4m4m5m6m7p8p1s1s5s6s7s6p+21',
  '4m5m7m7m8m8m9m9m1s1s5s6s7s+6m+12',
  '3m4m5m2p2p3p4p5p7p8p2s3s4s6p+13',
  '3m3m3m6m7m2p3p4p6s6s6s7s7s8m+13',
  '5m6m8m8m1p2p3p5p6p7p5s6s7s+7m+13',
  '3p3p5p6p7p3s4s6s7s8s5z5z5z+2s+23',
  '5m5m5m6m7m8m3s4s5s1z1z4z4z+1z+14',
  '1m2m3m3p4p2s2s3s3s4s4s7s7s5p+14',
  '1p2p3p7p8p9p2s3s4s3z3z6z6z+3z+23',
  '5m6m7m2p2p4p5p6p6p6p2s3s4s+3p+22',
  '3m4m7m7m7m3p3p6p7p8p6s7s8s+2m+12',
  '2m3m3m4m5m6m7m8m5s5s6s7s8s4m+13',
  '2m2m6m7m8m3p4p5p4s5s6s7s8s3s+21',
  '6m6m7m8m9m2p3p5p6p7p3s4s5s1p+23',
  '3m3m5m6m7m3p4p5p2s4s2z2z2z3s+12',
  '5m6m7m1p2p3p7p7p3s4s6s7s8s+2s+21',
  '4m4m7p8p2s3s3s4s4s5s7s8s9s9p+24',
  '3m4m5m6m1p1p2p2p3p3p2s3s4s+6m+12',
  '2m2m6p7p8p2s3s4s6s7s7s8s9s5s+21',
  '1m2m3m5m6m2p3p4p6s7s7s7s8s+4m+13',
  '1m2m3m1p2p3p5p5p3s4s4s5s6s5s+12',
  '5m6m3p3p5p6p6p7p7p8p3s4s5s4m+11',
  '6m7m4p4p7p7p8p8p9p9p6s7s8s5m+21',
  '2m2m2m5p5p6p7p3s4s5s5s6s7s+8p+13',
  '5m6m2p3p4p6p7p8p4s4s6s7s8s4m+12',
  '4m4m6m7m3p4p5p4s5s6s6s7s8s+8m+11',
  '2m3m4m9m9m2p3p4p5p6p7p2s3s+4s+24',
  '1m7m7m7p7p8p8p4s4s5s5s7z7z+1m+22',
  '6p7p8p5s5s7s7s8s8s9s9s2z2z2z+11',
  '1m2m3m5m5m3p4p6p7p8p6s7s8s+5p+11',
  '3m4m6m6m7p8p9p3s4s4s5s5s6s2m+14',
  '6m7m8m2p3p9p9p2s3s3s4s4s5s1p+11',
  '2m4m4m7m7m4p4p5p5p6p6p5z5z2m+22',
  '2p2p3p3p4p4p5p7p7p7p6s7s8s6p+22',
  '7m8m9m3p4p5p7p8p7s8s9s4z4z+6p+23',
  '3m4m5m1p2p3p2s3s4s5s6s2z2z+4s+13',
  '5m5m6m6m7m7m8m7p7p9p9p8s8s8m+12',
  '2m4m4m5m6m8m8m4p5p6p6s7s8s+3m+11',
  '2m3m4m4p5p7p8p9p5s6s7s8s8s6p+24',
  '2m3m4m7m7m2p3p4p2s3s6s7s8s+4s+13',
  '2m3m4m6m7m1p1p3p4p5p5s6s7s+8m+13',
  '1m2m3m3m4m5m7m8m1p1p3s4s5s+6m+21',
  '3m3m7m8m9m1p2p3p2s3s4s5s6s1s+11',
  '7m8m9m3p4p5p5p6p7p9s9s1z1z+1z+13',
  '2m3m4m6m6m2p3p4p7p8p9p2s3s+1s+22',
  '2m4m6m7m8m2p2p3p4p5p6p7p8p+3m+14',
  '2m2m2m3m4m5m6m6m5p6p7p6s7s+5s+24',
  '6m7m7m8m9m4p5p6p9p9p1s2s3s+8m+21',
  '5m6m7m5p6p7p8p8p1s2s3s6s7s+8s+12',
  '5m6m7m5p6p7p8p8p2s3s5s6s7s+4s+14',
  '3m4m2p3p4p2s3s4s5s6s7s9s9s5m+12',
  '4m4m5m5m8p8p2s2s5s5s8s6z6z+8s+14',
  '2m3m4m2p3p4p2s3s4s6s7s7z7z8s+14',
  '4m5m6m7m8m9m2p3p3s3s6s7s8s1p+11',
  '4m5m6m8m8m3p4p5p5p7p2s3s4s+6p+11',
  '2m4m5m6m7m5p6p7p2s3s4s6s6s3m+21',
  '2m3m2p3p4p8p8p2s3s4s7s8s9s+1m+12',
  '4m5m6m4p5p6p4s5s7s7s3z3z3z3s+13',
  '3m3m5m6m7m7m8m9m4p5p6p4s5s+3s+13',
  '6m7m8m2p3p4p4p5p6p7p7p3s4s5s+11',
  '2m3m4m6m7m8m6p7p5s5s6s7s8s5p+22',
  '1m1m7m8m9m5p5p2s3s4s1z1z1z+1m+13',
  '5m5m7m8m9m7p8p9p2s3s3s4s4s+5s+21',
  '2m2m2m3m4m5m6p7p4s4s5s6s7s+8p+11',
  '1p1p3p4p5p1s2s4s5s6s7s8s9s+3s+11',
  '4m5m3p4p5p2s3s4s5s5s7s8s9s3m+14',
  '2m2m2m7m7m5p6p6p7p7p8p6s7s5s+13',
  '2m3m2p3p4p6p6p1s2s3s5s6s7s+4m+22',
  '1m3m5m5m5m5p5p3s4s5s7z7z7z+2m+13',
  '3m4m3p3p4p5p5p6p6p7p4s5s6s5m+23',
  '5m6m7m5p5p6p7p8p2s3s4s2z2z+2z+21',
  '4m4m7m7m2s2s3s3s7s7s5z5z7z7z+11',
  '3m4m5m6m7m4p4p4s5s6s6s7s8s+8m+14',
  '3m4m1p2p3p5p5p7s8s9s2z2z2z+2m+24',
  '5m6m3p4p5p6p7p8p3s3s5s6s7s4m+13',
  '4m5m6m4p5p2s2s4s4s4s6s7s8s6p+24',
  '7m7m8m8m9m2p2p8s8s1z1z6z6z+9m+14',
  '1m2m3m2p3p4p5p6p7p4s4s3z3z3z+23',
  '8m8m5p6p7p7p8p5s5s6s6s7s7s6p+12',
  '2m3m3m4m4m5m2p2p5p6p7p5s6s+4s+14',
  '2p2p5p6p7p6s6s7s7s8s4z4z4z8s+12',
  '3m4m5m1p1p2p3p6p7p8p3s4s5s+4p+24',
  '2m2m4m4m7m7m4p4p4s6s6s8s8s+4s+14',
  '6m7m8m9m9m3p4p5p6p7p8p6s7s5s+11',
  '4m5m6m7m8m4p5p6p7p7p7s8s9s6m+14',
  '3m4m6m7m8m4p5p6p1s1s4s5s6s+2m+23',
  '1m2m3m5m6m7m7m4s5s6s7s8s9s+4m+13',
  '3m3m4m5m5m1p2p3p6p6p7s8s9s4m+13',
  '2m3m1s2s3s4s5s6s6s7s8s2z2z1m+14',
  '9m9m4p5p5p8p8p5s5s3z3z4z4z4p+12',
  '2m3m4m6m7m8m2s2s2s5s6s7s7s7s+24',
  '1m2m3m6m7m3p3p5p6p7p5s6s7s+5m+14',
  '3m4m5m3p4p6p7p8p3s4s5s7s7s+2p+23',
  '4p5p6p6p7p8p8p3s4s5s7s7s7s+5p+24',
  '3m3m3m4m5m5p5p5z5z5z6z6z6z+5p+12',
  '2m3m4m3p4p4p5p6p3s3s4s5s6s5p+11',
  '2m3m3p4p5p6p7p8p1s1s4s5s6s+4m+13',
  '1m2m3m3m4m5m6m7m1s1s4s5s6s2m+12',
  '5m6m8m8m2p3p4p7p8p9p2s3s4s+7m+24',
  '2m3m4m7m7m5p6p7p7p8p7s8s9s+6p+22',
  '2m2m3m3m4m4m6m6m7m7m5s5s8s+8s+12',
  '5m6m7m1p2p3p4p5p7p8p9p2z2z+6p+23',
  '3m4m5m7m8m5p6p7p2s2s7s8s9s9m+11',
  '1m2m3m3p4p6p6p6s6s7s7s8s8s5p+14',
  '3m4m1p2p3p5p6p7p2s3s4s7s7s5m+23',
  '7m8m9m3p4p4p4p5p7p8p2z2z2z+9p+12',
  '3m4m5m6m7m8m6p6p2s2s2s4s5s6s+12',
  '1m2m3m4m4m1p2p3p3s4s7s8s9s5s+24',
  '1m2m3m4m5m1s1s3s4s5s7s8s9s+3m+21',
  '2m3m3m4m5m1p2p3p4p5p6p7p7p1m+23',
  '2m3m4m3s4s5s6s7s7s8s8s6z6z6s+11',
  '4m5m6m7m8m2p2p4p5p6p6s7s8s+3m+11',
  '3m3m3m5m6m2p2p4p5p6p3s4s5s4m+12',
  '2m2m4m5m6m4p5p6p6p7p8p4s6s+5s+11',
  '5m5m5m8p8p2s3s4s4s5s6s2z2z+2z+12',
  '2m3m3m4m4m8m8m4p5p5p6p6p7p2m+11',
  '1p2p3p3p3p4p5p6p3s4s5s1z1z+1z+11',
  '2m3m8m8m5p6p7p3s4s5s8s8s8s+4m+12',
  '3m4m5m9p1s1s1s3s4s5s6z6z6z+9p+11',
  '4m5m5m5m6m7m7m8m8m9m9m4s5s3s+24',
  '1m2m3m6m7m7p8p9p2s2s4s5s6s+8m+22',
  '1m1m3m3m3m6m6m7m7m8m9m9m9m+5m+21',
  '1m2m3m8m8m5p6p7p3s4s5s7z7z+7z+11',
  '5m6m7m1p2p3p5p6p7p9p9p3s4s5s+24',
  '1m2m3m4p5p8p8p4s5s6s6s7s8s+3p+13',
  '7m8m3p4p4p5p5p6p1s2s3s8s8s+9m+11',
  '3m4m5m2p3p4p5p6p7p8p9p5s5s+1p+22',
  '1m2m3m5m6m6m7m7m8m1p1p5s6s+7s+11',
  '1m2m3m5m6m7m5p6p7p6s7s7s7s8s+12',
  '3m3m6m7m8m7p8p9p3s4s4s5s6s5s+11',
  '3m4m5m2p3p4p6p7p9p9p7s8s9s+8p+11',
  '2m3m4m3p4p5p6p7p8p5s6s8s8s7s+11',
  '6m7m8m4p5p6p2s2s5s6s7s7s8s+9s+24',
  '2m2m2m7p7p2s2s2s3s4s5s6s7s8s+21',
  '3m3m3p4p5p6p7p8p2s3s4s6s7s+5s+12',
  '5m6m1p1p5p6p7p1s2s3s5s6s7s7m+13',
  '2p2p3p3p4p4p9p9p6s6s6s7s7s+7s+12',
  '3m3m2p3p4p3s3s4s5s6s6s7s8s+3m+22',
  '5p6p7p7p8p8p8p2s3s4s5s6s7s4p+21',
  '1m1m3m4m5m7m8m9m4s5s6s7s8s+6s+13',
  '5m5m7m7m5p5p1s2z2z3z3z7z7z1s+14',
  '4m5m6m7m8m9m4p5p7p7p2s3s4s+3p+13',
  '2m4m2p3p4p7p8p9p2s3s4s1z1z+3m+12',
  '3m4m5m9m9m6p7p8p1s2s3s6s7s+8s+24',
  '2m2m7m8m2p3p4p7p8p9p5s6s7s6m+24',
  '6m7m8m2p4p4p5p6p3s3s5s5s5s+3p+11',
  '2p2p3p4p5p2s3s5s6s7s7s8s9s4s+11',
  '5m6m7m4p6p9p9p4s5s6s6z6z6z+5p+11',
  '2m2m2m2m4m6m7m8m2p2p6p7p8p+3m+24',
  '4m5m6m8m8m3p4p5p6p7p7s8s9s+8p+13',
  '1m2m3m2p2p7p8p9p4s5s5s6s7s3s+11',
  '1m1m4m5m5m6m6m7p8p9p1s2s3s4m+14',
  '3p3p6p6p8p2s2s3s3s4s4s3z3z8p+14',
  '3m4m5m4p5p6p7p8p9p2s2s5s6s+7s+24',
  '4m5m6m2s3s4s6s6s7s8s9s3z3z+3z+23',
  '6m6m7m7m8m8m5p5p6p7p8p5s6s7s+21',
  '1m1m7m8m9m3p4p3s4s5s7s8s9s5p+12',
  '4m5m6m6m7m8m1p2p3p6p6p7p8p+9p+13',
  '1m3m3m6m6m7m7m9m9m3s3s4s4s+1m+12',
  '2m3m4m4m5m6m7m7m4s5s6s7s8s6s+14',
  '4m5m6m2p2p3p4p5p6p7p8p7s8s+9s+12',
  '3m4m5m2p2p4p5p6p8p8p8p5s6s+7s+11',
  '6m7m8m4p4p5p6p2s3s4s5s6s7s7p+11',
  '5m5m6m7m7m8m8m9m7p8p6s7s8s9p+22',
  '6m6m4p5p6p6p7p8p4s5s5s6s6s+7s+14',
  '5m6m7m4p4p5p6p7p8p8p6s7s8s+4p+24',
  '4m5m1p1p3p4p5p1s2s3s5s6s7s6m+11',
  '1m1m4m6m4p5p6p7p8p9p4s5s6s+5m+23',
  '3m4m6m7m8m4p4p5p5p5p2s3s4s2m+13',
  '4m5m6m2s3s4s6s6s7s8s9s1z1z+1z+12',
  '4m4m4m1p2p3p3s3s6s7s5z5z5z+8s+11',
  '4p5p6p7p8p9p1s2s3s3s4s7s7s+2s+12',
  '5m6m3p3p4p5p5p6p6p7p5s6s7s4m+13',
  '3p3p3p7p8p9p6s7s7s8s8s7z7z+6s+14',
  '5m6m7m4p5p6p7p7p8p8p9p1s1s6p+13',
  '1m2m3m4m5m6m2p2p5s6s6s7s7s5s+12',
  '4m5m6m7m7m2p3p6p7p8p7s8s9s1p+23',
  '4m5m6m5p6p6p7p7p4s4s7s8s9s8p+11',
  '2m2m3p4p5p6p7p8p2s3s6s7s8s+1s+12',
  '3m3m1s1s2s2s3s3s5s5s2z2z2z+3m+22',
  '6m7m8m2p3p4p7p7p3s4s6s6s6s+2s+21',
  '4m5m3p3p3p4p5p5p6p7p7p8p9p6m+14',
  '2p2p4p5p7p8p9p1s2s3s4s5s6s6p+11',
  '5m5m6m6m1p1p3p3p5p5p7p7p8p8p+13',
  '2m3m4m7m8m2p3p4p7p8p9p5s5s+9m+23',
  '7m8m9m1s2s2s3s3s7s8s9s1z1z+1s+22',
  '6m6m7m8m9m3p4p5p7p8p9p4s5s3s+23',
  '4m4m2p2p5p6s6s8s8s4z4z5z5z5p+12',
  '2m3m4m5p5p5p2s3s3s3s4s8s8s+8s+22',
  '1m2m3m2p3p7p8p9p4s5s6s9s9s+1p+22',
  '2m3m6m7m8m8p8p5s6s6s7s7s8s1m+13',
  '4m6m5p6p7p8p8p8p7s7s8s8s8s5m+23',
  '1m2m3m5m6m7m6p6p7p8p3s4s5s+9p+13',
  '2m3m4m6p7p2s2s2s3s4s6s7s8s+8p+21',
  '4m5m6m2p2p5p6p7p7p8p9p4s5s+3s+21',
  '6m8m5p5p1s2s3s4s5s6s7s8s9s7m+13',
  '3m4m5m3p5p5p6p7p2s2s6s7s8s+4p+13',
  '5m5m6m7m8m7p8p5s6s6s7s7s8s+6p+24',
  '6p7p8p2s3s7s8s9s1z1z1z4z4z+1s+13',
  '3m4m5m8m8m6p6p6p2s3s4s4s5s6s+23',
  '1m2m3m4p5p6p4s4s5s6s7s7s8s+6s+14',
  '1m1m5m5m2p2p9p9p4s4s2z2z3z+3z+24',
  '1m2m3m1s2s3s4s5s6s7s8s1z1z+9s+12',
  '4p5p6p2s2s6s6s6s7s8s9s5z5z+5z+14',
  '2m3m4m1p2p2p3p3p5s6s7s5z5z1p+24',
  '6m6m8m8m1p8p8p9p9p7s7s5z5z1p+11',
  '1m2m3m7m7m1p2p3p5s6s5z5z5z4s+11',
  '2m3m4m6m7m8m2s3s4s5s5s7s8s+6s+23',
  '3m4m5p5p5p6p7p8p2s2s4s5s6s+2m+24',
  '1p2p3p4p5p7p8p9p7s8s9s4z4z+6p+12',
  '1m2m3m3m4m5m5p6p2s3s4s7s7s7p+13',
  '1m2m3m3m4m5m6m6m4p5p6p7p8p+3p+22',
  '2m2m7m7m8m8m9m9m3p4p6s7s8s5p+12',
  '2m3m5m6m7m5p6p7p7s8s9s4z4z4m+12',
  '2m3m4m3p3p3p3s4s5s6s6s6s8s7s+24',
  '2m3m9m9m3p4p5p1s2s3s7s8s9s1m+14',
  '1m2m3m6m7m2p3p4p7p7p7s8s9s+5m+11',
  '3m4m3p4p5p6p7p7p7p8p1s2s3s+2m+11',
  '4m5m3p3p5p6p7p4s5s6s7s8s9s6m+13',
  '1m2m3m5p6p7p2s2s3s4s5s6s7s+8s+23',
  '8m8m4p4p5p5p8p2s2s5s5s8s8s8p+13',
  '2m4m5m6m8m8m8m3p4p5p3s4s5s+2m+13',
  '6m7m6p7p8p2s3s4s6s7s8s6z6z8m+22',
  '4m4m7m8m9m2p3p4s5s6s7s8s9s+1p+24',
  '1m1m1m2p2p3p3p4p4p7p8p9p7s7s+12',
  '3p3p4p4p5p1s1s5s5s6s6s7s7s+2p+12',
  '2m3m4m9m9m4p5p1s2s3s7s8s9s+3p+12',
  '3m3m2p3p4p5p6p7p3s4s5s7s8s+6s+23',
  '2p2p2p3p4p6p7p8p5s6s7s8s9s7s+12',
  '4m4m5m6m6m2p2p5p6p7p5s6s7s+5m+12',
  '2m3m6m7m8m1p1p3p4p5p5s6s7s1m+14',
  '3p3p3p5p6p7p7p8p4s4s6z6z6z6p+22',
  '5m6m7m4p5p6p3s4s6s6s6s7s8s+5s+12',
  '3m4m6m7m8m9m9m3p4p5p1s2s3s+2m+22',
  '3m4m5m6m7m8m2s3s4s7s8s9s9s6s+13',
  '2m4m4m5m5m7m7m8m8m9p9p2s2s2m+13',
  '6m7m8m2p2p5p6p7p1s2s3s6s7s5s+14',
  '1m2m3m1p2p3p6p7p1s2s3s6z6z+8p+11',
  '3m3m4m5m1p2p3p5p6p7p4s5s6s+3m+11',
  '1m1m1m8m8m5p6p7p5z5z5z7z7z7z+14',
  '3m3m4m4m4m3p4p5p2s3s4s6s7s5s+22',
  '3m4m5m5p6p8p8p1s2s3s3s4s5s7p+21',
  '5m5m7p7p2s3s3s4s4s6s6s7s7s+2s+13',
  '2m2m2m4m6m8m8m3s4s5s5z5z5z+5m+12',
  '5m5m3p4p4p5p5p3s4s5s6s7s8s+6p+13',
  '4m5m6m4p4p1s3s4s5s6s2z2z2z+2s+23',
  '2m2m3m4m4m6m7m8m6p7p8p8p8p3m+12',
  '3m4m5m1p2p3p4s5s9s9s7z7z7z+6s+12',
  '1m2m2m3m3m4m2p3p4p6p6p6s7s5s+21',
  '1m2m5m5m5m6m7m5s6s7s6z6z6z3m+21',
  '4m5m6m7m8m9m3p4p5p3s4s5s5s+2s+21',
  '1m2m3m5p6p7p2s2s4s4s5s5s6s3s+12',
  '4m5m6m3p4p6p7p8p2s2s2s3s4s2p+14',
  '3m4m5m3p4p5p6p6p4s5s6z6z6z+6s+23',
  '4m5m6m4p5p6p7p8p5s5s6s7s8s+3p+22',
  '3p4p5p7p7p8p8p9p4z4z4z7z7z6p+22',
  '5m6m7m4p5p8p8p3s4s4s5s5s6s+3p+11',
  '2m2m5m6m7m6p7p7p8p8p4s5s6s9p+11',
  '6m7m8m9m9m6p8p2s3s4s6s7s8s7p+14',
  '6m7m8m3p4p5p6p6p7p7p8p6s6s2p+11',
  '3m4m4m4m5m7m8m9m7p7p8p9p9p+8p+24',
  '5m6m7m9m9m1p2p3p7p8p9p6s7s+8s+22',
  '1p2p3p5p5p7p8p2s3s4s6s7s8s6p+21',
  '3m3m6m7m8m3p4p5p7p8p3s4s5s6p+21',
  '3m4m6m7m7m7m8m1p2p3p4s5s6s5m+22',
  '3m4m5m8m8m6p7p8p2s4s4s5s6s+3s+13',
  '6m7m8m2p3p6p7p8p1s2s2s2s3s+1p+14',
  '4m5m4p5p6p1s1s3s4s5s7s8s9s+3m+11',
  '3m4m5m2p3p4p2s2s4s5s6z6z6z+6s+24',
  '5p6p7p2s3s4s6s6s1z1z5z5z5z+1z+13',
  '4m4m5m5m8s8s9s9s1z1z2z6z6z2z+12',
  '5m5m3p4p4p5p5p6p7p7p7p7s8s6s+13',
  '7m8m9m2p2p7p8p9p4s5s7s8s9s+6s+13',
  '1m1m1p1p2p2p3p3p7p7p4z6z6z4z+21',
  '2m3m5m6m7m2p2p2p4p5p6p2s2s+4m+14',
  '5m5m6m6m7m7m1p2p3p4p5p3s3s+6p+11',
  '3m4m6m7m8m2s3s4s5s6s7s9s9s+2m+22',
  '2m2m2m3m4m5m3p4p4s4s5s6s7s+2p+11',
  '3m4m5m6m6m5p6p7p2s3s4s6s7s8s+11',
  '2m3m4m7m8m9m1p1p2p3p4p6s7s8s+21',
  '2m3m4m4m5m6m7m3p4p5p5s6s7s4m+13',
  '2m2m2m3m3m2s4s4z4z4z7z7z7z3s+24',
  '2p3p4p4p5p6p1s1s2s3s4s7s8s+6s+11',
  '2p3p6p6p7p8p9p2s3s4s6s7s8s+1p+24',
  '4m5m6m7m8m1p1p4s5s6s6s7s8s3m+14',
  '2m2m2m3m5m6m7m4p5p6p6s7s8s+4m+11',
  '2m3m4m6m7m8m5p6p4s4s4s5s6s7p+13',
  '5m5m2p4p4p8p8p9p9p3s3s4s4s+2p+12',
  '1m2m3m4m5m6m7m9m3s4s5s6s6s+8m+14',
  '3m3m4m5m6m6p7p8p2s3s4s6s7s+8s+14',
  '1m2m3m4m4m4m5m6m7m8m4p5p6p3m+24',
  '3p3p3p7p9p3s3s5s5s6s6s7s7s+8p+14',
  '3m4m4m5m6m1p2p3p1s1s5s6s7s+5m+23',
  '3m3m4m5m6m4p5p7p8p9p4s5s6s+6p+11',
  '6m7m8m4p4p6p7p8p2s3s4s5s6s+7s+11',
  '7m8m1p2p3p6p6p6p7p8p3s4s5s+9m+12',
  '6m7m8m6p6p1s1s2s3s3s4s5s6s2s+22',
  '1m2m2m3m3m4m2p3p4p4p5p5s5s+3p+13',
  '2m3m5m5m5m6p7p8p2s3s4s6s6s4m+12',
  '4m4m2p2p3p3p4p4p7p8p5s6s7s+9p+12',
  '6m7m7m8m8m3p3p8p8p8p5s6s7s+6m+22',
  '5m6m4p5p6p8p8p9p9p9p3z3z3z7m+13',
  '5m6m2p3p4p5p6p7p8p8p7s7s7s+4m+24',
  '3m4m5m7m8m9m2p2p4p5p6p3s4s5s+11',
  '1m3m3m2p2p3p3p5p5p1s1s4s4s+1m+23',
  '2m2m2p3p4p1s2s3s9s9s6z6z6z+2m+24',
  '2m3m5m6m7m4p5p6p7p8p9p8s8s+1m+22',
  '3m4m5m3p4p6p7p8p1s2s3s9s9s2p+21',
  '3m4m3p4p4p5p5p6p1s2s3s7s7s+2m+14',
  '4m5m3p3p2s3s4s5s6s7s6z6z6z+3m+24',
  '3m4m4p5p5p6p6p7p6s7s8s3z3z5m+11',
  '5m5m5m1p2p3p3p5p6s6s6z6z6z4p+11',
  '1m1m4m5m6m7m8m9m2s3s3s4s4s+2s+21',
  '5m5m2p3p4p4p5p6p7p8p9p4s5s+3s+12',
  '5m5m3p4p5p5p6p7p2s3s4s4s5s+3s+24',
  '5m5m4p4p7p7p9p5s5s7s7s5z5z+9p+13',
  '3m3m3p4p5p4s5s6s7s8s9s5z5z+5z+11',
  '2m3m5m5m1p2p3p1s2s3s7s8s9s+4m+13',
  '4m5m7m8m9m4p5p6p1s2s3s8s8s6m+21',
  '3m3m4m4m5m1p1p2s3s4s5s6s7s+5m+11',
  '3m4m6m6m5p5p6p6p7p7p4s5s6s+5m+14',
  '3m4m5m6m7m8m4p6p6p6p2s3s4s+5p+22',
  '1m2m3m1p2p3p7p8p1s2s3s9s9s+9p+21',
  '4m5m6m6m8m6p7p8p4s5s6s7s7s7m+23',
  '4m5m6m7m8m9m2p3p5p5p2z2z2z1p+12',
  '1m1m2m2m3m3m3s4s5s8s8s6z6z+8s+12',
  '6m7m8m6p7p8p4s4s4s1z1z6z6z+6z+12',
  '3m4m6m7m8m4p4p6p7p8p5s6s7s2m+13',
  '2m3m4m6m6m2p3p4p7p7p7p2s4s+3s+22',
  '1s3s4s4s4s6s7s8s3z3z5z5z5z+2s+13',
  '1m2m3m4m5m6m7m8m9m5s7s9s9s+6s+23',
  '2p3p3p3p4p5p6p2s3s4s5s6s7s+1p+24',
  '1m2m3m8p1s1s1s5s6s7s3z3z3z8p+23',
  '1m3m4m5m6m7m8m9m3p3p3s4s5s2m+14',
  '2m3m4m4m5m6m7m7m8m9m2p3p4p+4m+12',
  '4p4p7p7p8p8p5s5s8s1z1z7z7z+8s+13',
  '3m4m7m7m1p2p3p6p7p8p1s2s3s+5m+22',
  '3m4m5m5m6m2p3p4p6p6p7s8s9s+7m+22',
  '4m5m5m6m6m1p2p3p5p5p5p6p7p4m+13',
  '5m6m7m2p2p3p4p5p6p7p2s3s4s+8p+13',
  '2m3m4m7p8p2s2s7s8s9s1z1z1z+9p+13',
  '7m7m2p3p4p4p5p6p6p7p4s5s6s+8p+12',
  '3p4p5p7p7p7p8p8p8p4s5s5s6s5s+12',
  '7m8m9m3p3p7p8p1s2s3s5s6s7s+9p+21',
  '1m2m3m6m7m8m4p5p7p8p9p6s6s+3p+22',
  '1m2m3m1p1p3p4p7p8p9p4s5s6s2p+21',
  '7m8m8m8m2p3p4p2s3s4s4s5s6s9m+11',
  '2m2m2m4m4m3p4p5p5p6p7p3s4s+5s+22',
  '2m2m2m5m5m6m7m8m4p4p7p7p7p+5m+24',
  '4m5m6p7p8p1s2s3s4s5s6s7s7s6m+11',
  '3m4m5m5m6m7m4p4p4p5p6p6s7s+8s+22',
  '4m5m6m2p3p4p6p7p8p2s2s3s5s+4s+24',
  '2m3m4m6m7m2p2p4p5p6p5s6s7s8m+24',
  '2m2m3m4m4m1p1p1p4p5p6p3s3s3m+23',
  '1m2m3m1p2p3p5p6p2s3s4s6s6s4p+12',
  '3m5m3p4p5p6p6p3s4s5s6s6s6s+4m+13',
  '4m4m5m5m6m2p3p4p6p7p8p8s8s+3m+23',
  '1m1m4m5m6m7m8m6p7p8p1s2s3s3m+12',
  '4m5m7m7m2p3p4p6p7p8p4s5s6s+6m+12',
  '4m4m4m5m6m4p6p6p7p8p5s6s7s5p+21',
  '5m6m6m7m7m8m6p6p7p7p8p6z6z+8p+12',
  '5m5m6m6m7m4p5p6p6p7p8p5s5s+4m+11',
  '6m6m3p4p5p6p7p4s4s4s6s7s8s8p+21',
  '3m3m4p4p7p7p1s8s8s4z4z7z7z+1s+21',
  '5m5m6m7m8m3p4p7p8p9p3s4s5s2p+12',
  '2m2m3m3m6m7m8m3s4s5s6s7s8s3m+21',
  '3m4m5m4p4p5p6p1s2s3s6s7s8s7p+21',
  '2m3m4m6m7m8m4p5p6s7s7s7s8s+6p+24',
  '1m2m3m5m6m2p2p4p5p6p1s2s3s7m+13',
  '3m4m5m3p4p5p2s3s5s6s7s3z3z+1s+11',
  '6m6m2p3p4p7p8p9p3s3s3s5z5z5z+23',
  '7m8m9m2p3p4p6p7p8p3s4s9s9s+2s+13',
  '4m5m6m9m9m6p7p2s3s4s4s5s6s+8p+13',
  '2m2m4m5m2p3p4p3s4s5s5s6s7s+6m+24',
  '6m7m7m8m8m9m6p6p6s7s8s5z5z5z+13',
  '2m3m4m4m4m5m6m1s2s3s4s5s6s+7m+14',
  '5m6m7m6p7p8p2s3s4s4s5s6s8s+8s+11',
  '1m2m3m4m5m6m7m8m9m2p4p6s6s+3p+11',
  '2m2m3m3m4m4m1p2p6s6s2z2z2z+3p+22',
  '1m2m3m6m7m2s3s4s4s5s6s3z3z5m+24',
  '3m3m3m1p2p3p3s3s7s7s8s9s9s8s+13',
  '3m4m6m7m8m5p6p7p5s6s7s3z3z5m+22',
  '3m4m5m7m7m7m7p7p7p4s4s4s5s+3s+22',
  '3m4m5m6m7m8m5p6p7p7p2s3s4s+4p+13',
  '4p4p4p5p5p4s5s6s3z3z3z5z5z+5z+14',
  '8m8m2p3p4p1s2s3s7s8s4z4z4z+9s+14',
  '2m3m4m5m5m6m7m8m4p5p5s6s7s+3p+14',
  '2m4m7m8m9m5p5p2s3s4s7z7z7z+3m+13',
  '3m4m5m1p2p3p5p6p7p7p3s4s5s+7p+22',
  '1m1m2m2m7m7m6p6p7p7p3s3s7z+7z+12',
  '4m5m6m6m7m8m2p2p5s5s6s7s8s+5s+13',
  '4m4m4m8m8m9p9p1s2s3s9s9s9s9p+21',
  '2m3m4m2p3p3p4p4p5p2s2s7s7s+2s+11',
  '2m3m4m8m8m6p7p8p5s6s7s5z5z5z+21',
  '9m9m3p3p6p6p6s6s8s8s9s9s6z+6z+14',
  '4m5m7m8m9m1p2p3p4p4p5s6s7s+6m+11',
  '3m4m5m7m8m4p4p2s2s3s3s4s4s9m+21',
  '6m7m3p4p5p7p7p3s3s3s4s5s6s+5m+11',
  '1m2m3m4m5m6m6p7p9p9p3s4s5s8p+13',
  '3m4m5m1p1p3s4s5s1z1z1z2z2z+2z+14',
  '3m4m6m7m8m2s3s4s4s5s6s9s9s+2m+24',
  '1m2m3m7m8m9m5p6p7p9p9p6s7s+8s+21',
  '5m6m4p5p6p6p6p7p8p9p7s8s9s+7m+12',
  '3m4m5m7m8m9m8p8p4s5s6s6s7s+8s+11',
  '2p2p7p7p9p9p1s1s5s5s9s5z5z+9s+23',
  '2m2m5m6m7m3p4p5p6p7p2s3s4s+2p+12',
  '6p7p7p8p8p9p1s1s4s5s5s6s7s+6s+11',
  '5m6m7m3p4p4s4s7s8s9s1z1z1z2p+14',
  '6m7m3p3p3p7p7p8p8p9p9p9s9s+8m+12',
  '5m5m6m6m7m3p4p5p4s4s7s8s9s7m+12',
  '1p2p3p4p5p6p7p9p1s2s3s7s7s8p+24',
  '2m3m4m6m6m6m8m8m5p6p7p5z5z+5z+14',
  '1m1m1m5m6m1s1s3s4s5s1z1z1z+7m+21',
  '3m4m5m1p2p3p8p8p2s3s4s4s5s+6s+12',
  '8m8m3p4p5p6p7p2s3s4s6s6s6s+5p+14',
  '5m6m7m5p5p6p6p7p7p8p9p5s5s+4p+12',
  '1m2m3m9m9m3p5p2s3s4s7z7z7z4p+11',
  '2m3m4m6m8m7p8p9p7s7s7z7z7z+7m+23',
  '3m4m6m7m8m7p8p9p3s3s7s8s9s+5m+11',
  '4m5m6m8m8m3p4p5p6p7p4s5s6s8p+11',
  '4m5m7m8m9m5p6p7p1s1s2s3s4s+3m+11',
  '2m2m6m7m8m5p6p7p2s3s4s5s6s7s+13',
  '5m6m7m5p6p7p4s4s6s6s7s7s8s8s+14',
  '2m3m1p2p3p3s4s5s6s6s6s7s8s+1m+11',
  '3m4m5m2p3p4p5p6p7p8p8p5s5s+8p+11',
  '4m5m6m7m7m3p4p6p7p8p2s3s4s5p+21',
  '3m3m3p4p6p7p8p2s3s4s7s8s9s+5p+13',
  '4p5p7p8p9p9p9p2s3s4s6s7s8s+3p+24',
  '4m5m6m6m7m8m6p7p1s2s3s5s5s+8p+22',
  '5m6m7m4p5p6p7p8p9p4s4s6s7s+5s+11',
  '3m4m5m7m8m3p4p5p6s7s8s2z2z9m+14',
  '1m2m3m4m4m5p5p5p2s3s4s1z1z+1z+21',
  '5m5m5m3p4p5p3s3s3s1z1z2z2z2z+23',
  '3m4m1p2p3p4p5p6p7p8p9p8s8s+2m+24',
  '5m6m1p2p3p5p6p7p8p8p5s6s7s+7m+12',
  '3m4m5m4p5p9p9p5s6s7s7s8s9s3p+13',
  '6m7m8m4p5p5p6p6p7p8p6s7s8s+5p+12',
  '6m7m6p7p8p6s7s8s3z3z3z6z6z8m+22',
  '5m5m2p2p3s3s4s4s2z2z4z7z7z+4z+22',
  '1m2m3m4m5m6m7m8m9m5s5s6s8s7s+23',
  '5m6m7m2p3p3p3p4p7p8p5s6s7s+9p+24',
  '4m5m6m5p5p7p7p7s7s8s8s9s9s5p+24',
  '2m3m3m6m6m3p3p3z3z4z4z5z5z+2m+12',
  '1m2m3m4m5m6m7m8m6s7s8s9s9s6m+13',
  '4m4m4m5m6m6p7p2s3s3s4s4s5s+8p+12',
  '8m6p6p4s4s6s6s7s7s9s9s7z7z+8m+13',
  '4m4m5m5m6m6m3p4p5p1s1s2s3s+4s+11',
  '5m6m7m8m9m5p6p7p1s2s3s8s8s+4m+23',
  '6m7m3p3p6p7p8p4s5s6s7s8s9s+8m+12',
  '1m2m3m1p2p3p1s2s9s9s3z3z3z3s+12',
  '3m4m5m7m8m9m3p4p5p4s5s7z7z3s+12',
  '3m4m6p6p7p8p9p3s4s5s7s8s9s+2m+14',
  '2p4p6p6p2s2s3s3s4s4s5s6s7s+3p+11',
  '6m6m2p3p3p4p4p3s3s5s5s7s7s+2p+12',
  '1m1m7m8m9m1p2p7p8p9p7s8s9s+3p+22',
  '4m5m6m8m8m6p7p8p3s4s5s6s8s+7s+13',
  '6m7m3p4p5p6p7p8p6s7s8s3z3z+5m+14',
  '2m3m4m3p4p5p6p7p8p5s5s6z6z+6z+11',
  '1p2p3p7p8p3s4s5s6s7s8s9s9s6p+11',
  '4m5m4p5p5p6p6p7p8p8p3s4s5s+3m+22',
  '2m3m4m3p4p5p8p8p2s3s5s5s5s4s+12',
  '2m3m4m6m7m8m8p8p5s6s6s7s8s+7s+14',
  '3m4m2p2p7p8p9p2s3s4s6s7s8s+2m+14',
  '2m3m4m5m6m7m8m8m5p6p6p7p7p+8p+11',
  '4m5m6m6m7m2p3p4p5p5p2s3s4s+8m+22',
  '2m2m4m4m5m5m6m5p6p7p5s6s7s+3m+21',
  '6m7m8m1p1p6p7p7p8p9p3s4s5s+8p+12',
  '2m3m4m6m6m1s2s3s4s5s5s6s6s+4s+12',
  '6m6m7p7p8p8p9p1s2s3s4s5s6s6p+11',
  '5m6m7m1p1p1p5p6p3s3s5s6s7s+7p+22',
  '4m4m1p2p3p4p5p6p5s6s6s7s8s7s+21',
  '5m6m7m2p3p4p6p6p7p8p9p3s4s5s+13',
  '3m4m6m7m8m5p5p6p6p7p7p5s5s+5m+24',
  '6m7m8m1p2p3p7p8p9p2s3s7s7s+4s+21',
  '3m4m5m7m8m9m7p8p4s5s6s8s8s9p+13',
  '2p3p4p7p8p9p1s2s3s4s4s7s8s9s+23',
  '2p3p3p3p5p6p7p1s2s3s5s6s7s4p+13',
  '5m5m1s1s1s2s2s4s4s5s5s6s6s+5m+22',
  '5m6m7m2p2p5p6p1s2s3s4s5s6s+4p+11',
  '1p2p3p7p7p4s5s5s6s6s7s8s9s7s+11',
  '2m3m4m8m8m5p6p7p3s4s7s7s7s2s+11',
  '1m2m3m8m8m5p6p2s3s4s7z7z7z+4p+11',
  '2m2m6p6p1s1s2s4s4s6z6z7z7z2s+22',
  '6m6m7p8p9p4s5s5s6s6s7s7s8s+9s+21',
  '1m2m3m4m5m6m6m7m8m5p6p7p7p7p+22',
  '1p2p3p5p6p1s2s3s1z1z6z6z6z+7p+11',
  '1m1m2m2m4m4m5s5s9s9s3z5z5z+3z+21',
  '3m4m5m6m6m8m8m8m4p5p2s3s4s+3p+22',
  '4m5m6m6m7m4p5p6p7p8p9p4s4s8m+22',
  '4m4m5m5m6m6m3p3p3p5p4s5s6s+4p+21',
  '6m7m8m2p3p4p4p5p6p7p7p3s4s5s+23',
  '3p3p4p4p5p5p6p6p6p3s4s6s6s+5s+21',
  '1m1m2m2m4m4m7m7m7p7p3z3z6z+6z+14',
  '5p5p7p8p9p3s4s5s5s6s7s8s9s+7s+14',
  '4m5m6m1p3p4p5p6p7p8p9p3s3s2p+14',
  '2m2m4m4m6m7m8m5p6p7p2s3s4s4m+21',
  '4m4m4m1p1p1p3p3p7s7s8s9s9s8s+13',
  '1m2m3m9m9m7p8p9p7s7s8s8s9s+9s+14',
  '1m2m3m4m5m6m7m8m9m5p7p4s4s+6p+22',
  '5p6p7p9p9p4s4s4s1z1z6z6z6z+1z+22',
  '4m4m1p1p2p2p3p3p2s2s2z2z7z+7z+23',
  '5m6m1p2p3p9p9p1s2s3s5s6s7s7m+24',
  '2m3m4m4m5m6m5p5p6p7p8p5s6s+4s+24',
  '4m5m7m7m3p4p5p1s2s3s7s8s9s+6m+24',
  '2m3m3m4m4m5m6p6p6s6s7s8s8s+7s+11',
  '3m4m5m4p5p4s5s6s6s6s7s8s9s+3p+11',
  '2m3m3m3m4m5p6p1s2s3s4s5s6s+4p+11',
  '2m3m5p6p7p2s3s4s3z3z6z6z6z4m+11',
  '3m4m1p2p3p3s4s5s5z5z6z6z6z5m+21',
  '1m1m6m7m8m4p4p5p5p6p3s4s5s+3p+21',
  '1p2p3p5p5p7p8p1s2s3s3s4s5s+9p+14',
  '2m3m5m5m2p3p4p7p8p9p3s4s5s+1m+21',
  '2m2m7m8m9m4p5p6p7p8p6s7s8s3p+14',
  '2m3m3m4m5m2p3p4p6p7p8p2z2z1m+14',
  '3m3m3m5m6m7m5p7p3s4s5s6s6s+6p+24',
  '4m4m5m6m7m1s2s3s4s5s6z6z6z6s+22',
  '1m3m4m5m6m5s5s6s7s8s4z4z4z2m+14',
  '1m2m3m1p2p3p4p5p6p1s2s5s5s3s+24',
  '3m3m6m7m8m3p4p5p7p8p1s2s3s9p+13',
  '3p4p5p6p6p7p8p9p5s6s7s8s9s7s+13',
  '3m3m4m4m5m2p3p4p5p5p6s7s8s+2m+11',
  '1m1m2m2m7m7m6p6p4s7s7s8s8s+4s+21',
  '1m1m1m5p5p5p9p9p4s4s6s7s8s4s+13',
  '4m4m6m6m8m4p4p2s2s5s5s6s6s+8m+24',
  '5m6m7m4p5p1s1s2s3s4s6s7s8s3p+11',
  '2m3m3m4m4m5m2p3p2s2s4s5s6s4p+22',
  '5m6m6m7m7m3s4s4s5s5s6s8s8s5m+11',
  '5m6m7m1p1p5p6p7p7p8p5s6s7s+6p+14',
  '1m1m5m5m8m8m2p2p4s8s8s9s9s+4s+22',
  '4m5m6m7m8m9m8p8p2s3s5s6s7s4s+14',
  '2m3m3m4m4m5m2s3s4s4z4z7z7z+7z+24',
  '5m6m7m8m8m8m6p8p4s4s5s6s7s7p+11',
  '6m6m6m2p3p4p6p6p5s6s6s7s7s5s+23',
  '3m4m5m1s1s1s2s3s4s5s7s8s9s+3s+24',
  '2m3m4m3p3p4p5p6p7p8p6s7s8s+9p+13',
  '3m3m3p4p5p2s3s4s4s5s6s7s8s9s+14',
  '3p4p5p2s3s4s5s6s7s4z4z7z7z+7z+13',
  '5m5m6m6m7m7m8m8m4s4s4s8s8s+8m+24',
  '3p4p5p1s2s3s6s7s8s3z3z7z7z7z+13',
  '6m6m4p5p6p7p8p3s4s5s6z6z6z+3p+22',
  '3m4m5m2p2p7p8p3s3s3s8s8s8s+6p+13',
  '2m3m5m6m7m2p3p4p4s5s6s4z4z+1m+11',
  '2m3m7m7m7p8p9p2s3s4s5s6s7s1m+12',
  '2m3m4m7p8p9p2s3s5s6s7s9s9s+1s+11',
  '2m3m4m3p3p6p7p3s4s5s8s8s8s+8p+13',
  '3m3m2p2p3p3p5p5p8p8p1s1s3z+3z+24',
  '1m2m3m6m7m7p7p1s2s3s3s4s5s+8m+13',
  '1m2m3m4m5m6m7m8m9m4p5p6p6s+6s+11',
  '2m2m2m7p8p9p1s1s7s7s2z2z2z7s+21',
  '5m6m7m2p3p5p6p7p4s5s6s8s8s+1p+14',
  '5m6m7m2p3p4p6p6p5s6s7s8s9s+7s+11',
  '6m7m8m4p5p6p8p8p2s3s4s6s8s+7s+12',
  '4m5m6m9m9m1p2p3p5s6s7s8s9s7s+24',
  '5m6m7m2p2p1s3s6s7s8s3z3z3z+2s+23',
  '5m6m7m7m8m8p8p4s5s6s7s8s9s9m+14',
  '1m2m3m6m7m6p7p8p5s5s6s7s8s+5m+11',
  '5m6m7m2p2p3p4p5p2s3s4s7s8s+6s+13',
  '6m6m5p6p7p2s3s3s4s5s6s7s8s+1s+11',
  '3m4m5m7m8m6p6p3s3s3s6s7s8s+6m+24',
  '6m7m3p4p5p6p7p8p9p9p2s3s4s5m+22',
  '1m2m3m8p8p1s2s3s4s5s5s6s7s+3s+23',
  '1m1m1p2p3p4p5p6p8p9p1s2s3s+7p+24',
  '2m3m4m4m4m6m6m3p4p5p6s7s8s6m+21',
  '6m7m5p6p6p6p7p3s4s4s5s5s6s+8m+22',
  '2m3m4m3p3p5p5p5p3s3s4s4s5s+2s+13',
  '1m2m3m1p2p3p3s3s3s4s6s7s8s+2s+14',
  '5m6m6m7m8m6p6p4s5s6s8s8s8s7m+14',
  '5m6m7m4p5p6p2s3s4s5s5s6s7s+2s+21',
  '1m2m3m1p2p3p3p4p5p6p7p9s9s2p+11',
  '6m7m8m3p3p3p7p8p3s4s5s6s6s+6p+12',
  '2p3p3p4p4p5p9p9p3s4s4s5s5s+6s+14',
  '3m4m5m7m8m9m6p7p7p8p9p4s4s5p+13',
  '4m5m6m3p4p6p7p8p4s5s6s4z4z+2p+21',
  '7m8m9m3p4p5p6p7p2s3s4s2z2z+5p+13',
  '6m7m8m3p4p7p7p8p8p9p9p8s8s5p+12',
  '3m3m3p3p4p4p5p5p7p8p7s8s9s+6p+12',
  '4m5m9m9m1p2p3p5p6p7p5s6s7s+3m+22',
  '6m7m8m4p5p6p6p1s2s3s4s5s6s+3p+12',
  '3m4m5m5m7m3p4p5p6p7p8p6s6s6m+11',
  '2m3m1p2p3p1s2s3s5s6s7s7s7s1m+22',
  '3m4m5m3p3p6p7p5s5s6s6s7s7s8p+13',
  '7m8m4p4p5p5p6p6p2s3s4s6z6z+9m+14',
  '4m5m1p2p3p4p5p6p1s2s3s6s6s+6m+23',
  '2m3m7m8m9m2p3p4p2s3s4s3z3z+1m+21',
  '1m2m3m4m5m6m8m9m1p2p3p3s3s+7m+22',
  '4m4m4m8m8m1p1p1p2p2p2p6p6p8m+13',
  '4m5m2p2p2p3p4p3s4s5s6s7s8s3m+11',
  '4m5m3p3p1s1s2s2s3s3s4s5s6s3m+13',
  '3m3m7m8m9m7p8p1s2s3s7s8s9s9p+23',
  '4m4m1p1p2p2p4p4p9p7s7s2z2z+9p+13',
  '1m1m1m3m5m7p8p9p3s3s5z5z5z+4m+21',
  '4m4m4m5m5m2p3p4p6p7p8p5s6s4s+34',
  '1m2m3m1p2p3p3p4p6p6p1s2s3s+2p+21',
  '1m1p9p1s9s1z1z2z3z4z5z6z7z+9m+14',
  '6m7m1p2p3p4p5p6p4s4s5s6s7s+5m+11',
  '6m7m3p4p5p4s4s5s5s6s6s7s7s8m+12',
  '4m4m4m8m8m8m8p8p2s4s4z4z4z3s+14',
  '1m3m4m4m1p2p3p1s2s3s6s7s8s2m+21',
  '2p3p4p5p6p7p2s3s4s5s6s7s8s8s+21',
  '4m5m6m1p2p3p4p5p6p4s6s4z4z+5s+21',
  '4m5m6m7m8m6p7p8p9p9p4s5s6s9m+14',
  '2m3m4m6m7m8m2p3p6p7p8p4s4s1p+23',
  '4m4m4m5m5m8m8m4p4p4p2s2s2s+8m+11',
  '2m3m4m6m7m8m7p8p3s4s5s6s6s+9p+22',
  '3m4m5m7m8m5p5p4s4s4s1z1z1z+9m+11',
  '4m4m2p2p4p4p5p5p6p6p8p8p3s+3s+22',
  '2m3m6m7m8m4p5p6p1s1s6s7s8s+1m+11',
  '3m4m5m6p7p8p2s3s4s5s6s9s9s+7s+12',
  '1p2p3p3p4p6p7p8p9p9p4s5s6s+2p+12',
  '5m6m7m1p2p3p5p5p7p8p3s4s5s+6p+23',
  '4m4m4m8m8m3p4p4p5p5p6p7z7z7z+24',
  '1m2m3m4m5m6m8m8m7p8p6s7s8s+9p+14',
  '5m6m7m7p8p2s3s4s5s5s7s8s9s+6p+23',
  '3m4m9m9m7p8p9p2s3s4s5s6s7s5m+12',
  '3m4m4m5m5m6m8m8m4p5p4s5s6s+3p+14',
  '1m2m3m5m6m7m2s3s5s6s7s9s9s4s+22',
  '4p5p6p7p8p9p2z4z4z4z5z5z5z+2z+11',
  '7m8m9m7p8p9p1s2s3s8s9s3z3z+7s+14',
  '4p5p6p7p8p9p2s3s4s4s5s7s7s3s+11',
  '3m4m5m4p5p6p3s3s6s6s7s7s8s5s+24',
  '3m3m6m6m7m8p8p9p9p7s7s7z7z+7m+13',
  '8m2p2p4p4p6p6p1s1s2s2s3s3s+8m+11',
  '2m3m4m8m8m2p3p4p7p7p6s7s8s8m+22',
  '1m2m3m5m5m2p3p4p6p7p7s8s9s8p+13',
  '2m2m3m3m4m4m5m3p4p5p6p7p8p5m+13',
  '1m2m3m5m6m7m7m7m3s4s6s7s8s+2s+12',
  '3m3m3m2p2p3p3p6p7p8p3s4s5s3p+13',
  '2m3m4m8m8m3p4p7p8p9p2s3s4s+2p+12',
  '5m6m6m7m7m8m9m9m3s4s5s7s8s9s+11',
  '4m5m6m2p3p4p5p5p6p7p4s5s6s+5p+14',
  '3m4m5m3p4p5p6p6p7p7p3s4s5s+7p+13',
  '6m7m8m9m9m4p5p7p8p9p3s4s5s3p+13',
  '2m3m6p7p8p1s2s3s3s4s5s3z3z4m+14',
  '3m4m4m5m6m2s3s4s6s7s8s2z2z+5m+14',
  '3m4m4p5p6p4s4s4s5s6s7s8s8s+5m+11',
  '2m3m4m5m5m2p3p4p6p7p8p5s7s+6s+22',
  '2m3m4m2s2s3s3s3s4s5s6s6s8s+7s+14',
  '6m7m8m3p4p5p7p8p9p6s7s8s8s8s+11',
  '5m6m2p3p4p7p7p2s3s4s5s6s7s+7m+11',
  '2m2m4m4m1p1p6s6s8s5z5z7z7z+8s+12',
  '3m3m3m5m5m5m3s4s5s5s6s7s7s+4s+23',
  '3m2p2p5p5p8p8p3s3s4s4s7s7s3m+22',
  '3m4m6m7m8m9m9m7p8p9p3s4s5s2m+14',
  '4m4m6m7m8m1p2p3p7p7p8p8p9p+6p+11',
  '2m3m4m2s3s5s6s6s7s7s8s4z4z1s+12',
  '9m9m7p8p9p1s1s2s2s3s5s6s7s3s+13',
  '2m3m4m1p2p2p2p3p5p6p7p6s7s+8s+13',
  '2m3m5m6m7m5p6p7p8p8p2s3s4s4m+12',
  '2m3m4m6p7p8p3s3s6s7s8s7z7z+7z+13',
  '5m6m7m1p2p3p4p5p6p8s8s3z3z+3z+23',
  '2m2m2m3m4m6m8m3p4p5p6s6s6s+7m+12',
  '2p3p4p5p6p7p8p9p2z2z3z3z3z7p+24',
  '4p5p6p7p7p8p9p9p5s5s5s6s7s+8p+24',
  '2m3m4m6m7m3p4p5p5p5p3s4s5s8m+11',
  '6m6m1p2p3p7p8p2s3s4s7s8s9s9p+21',
  '5m6m3p4p5p1s2s3s7s8s9s3z3z4m+12',
  '4m5m6m5p5p2s3s4s7s7s8s9s9s+8s+12',
  '2m2m4m4m8p8p9p9p2s2s3s3s7z7z+13',
  '7m7m3p4p5p2s3s4s5s5s6s6s7s+1s+21',
  '6m6m7m8m9m4p5p5p6p6p6s7s8s4p+11',
  '5m6m7m2p3p2s3s4s4s4s4s5s6s+1p+12',
  '3m3m5m6m7m4p5p6p2s3s5s6s7s+1s+11',
  '3m4m5m6m7m8m2p3p4p2s2s5s6s4s+22',
  '5m6m7m7m8m9m2p2p4p5p2s3s4s+6p+11',
  '2m3m4m5m6m3p3p5p6p7p4s5s6s1m+11',
  '5m6m7m3p4p5p5p6p7p5s7s7s7s5s+14',
  '5m6m7m7m8m9m9m9m7p8p9p4s5s6s+13',
  '1m2m3m4m6m7m8m9m2p2p3p4p5p+5m+14',
  '1m1m5m6m7m2p3p4p4p5p6p4s5s+3s+22',
  '2p2p3p3p4p4p2s3s4s5s7s7s7s+5s+13',
  '1m1m2m2m3m3m9m9m2p3p5s6s7s+1p+14',
  '5m6m7m9p9p5s6s7s8s9s5z5z5z+4s+22',
  '4m5m7m8m9m5p6p7p5s6s7s9s9s3m+14',
  '2m3m4m4m5m6m7m8m1s1s3s4s5s+9m+22',
  '1m2m3m6m6m4p5p6p7p8p9p2s3s1s+24',
  '2m3m4m5m6m7m5p6p8p8p4s5s6s7p+14',
  '1m1m4m5m7p8p9p1s2s3s5s6s7s3m+11',
  '5m5m7m8m9m2p3p4p7p8p7s8s9s+9p+14',
  '5m7m3p4p5p6p6p6p8p8p6s7s8s+6m+11',
  '1m2m3m8m8m1p2p3p4s5s6s6s7s5s+12',
  '1p1p4p4p5p5p6p2s3s4s4s5s6s+3p+21',
  '3m3m4m5m6m7m8m9m5p6p6p7p8p+4p+11',
  '2m2m2m3m7m8m9m4p5p6p3s4s5s+1m+12',
  '3m4m7m8m9m1p2p3p8p8p1s2s3s+5m+11',
  '5m6m7m2p2p4p5p3s4s5s8s8s8s+6p+11',
  '5m6m7m8m8m1p2p3p5p6p5z5z5z+7p+23',
  '3p3p6p7p8p2s3s4s6s7s7s8s9s+8s+13',
  '2m3m4m2p3p4p5p5p4s5s6s6s6s+3s+13',
  '1m1m4m5m6m1p2p3p5p6p7p6s7s+8s+24',
  '4m5m2p2p2p3p4p4s5s6s7s8s9s+3m+11',
  '7m7m5p6p6p7p7p3s3s8s8s9s9s+5p+12',
  '4m5m6m7m8m9m7p8p9p5s6s3z3z+7s+22',
  '2m3m4m2p3p4p8p8p3s3s8s8s8s+8p+24',
  '6m6m7p7p8p8p9p9p5s5s7s8s9s+5s+23',
  '4m5m6m7m8m9m9m1p2p3p5p6p7p+3m+11',
  '6m8m5p6p7p8p8p4s5s6s6s7s8s+7m+14',
  '2p3p8p8p1s2s3s4s5s6s7s8s9s+1p+11',
  '4m5m6m6m6p7p8p2s3s4s5s5s5s3m+22',
  '1m2m3m5m6m6m7m8m9m9m6s7s8s+4m+22',
  '4m5m2p3p4p4s4s5s5s5s6s7s8s+3m+11',
  '2m2m3m5m6m7m8m6p7p8p2s3s4s+4m+13',
  '3m5m5m9m9m6p6p8s8s9s9s2z2z+3m+13',
  '2m3m4m6m6m7m8m9m2p3p3p4p4p2p+14',
  '1m2m3m4m5m9p9p3s4s5s7s8s9s+6m+21',
  '6m7m5p6p6p7p7p8p9p9p5s6s7s8m+11',
  '2m2m5m6m1p2p3p2s3s4s6s7s8s+4m+14',
  '3m4m4p5p6p7p8p9p4s5s6s9s9s5m+14',
  '6m6m4p4p5p5p6s6s9s9s4z4z7z+7z+14',
  '1m2m3m4m5m6m5p6p7p8p8p7z7z+7z+13',
  '2m3m4m2p3p4p5p6p7p8p8p3s4s+2s+21',
  '2m2m3m4m5m2p3p4p7p8p3s4s5s6p+23',
  '6m6m7m7m8m3p3p3p4p5p3s4s5s+8m+13',
  '3m4m5m7p7p7p3s3s7s8s1z1z1z6s+11',
  '2m2m6m6m3p4p4p7s7s9s9s1z1z3p+14',
  '1m1m4m4m3p3p5p5p5s6s6s7s7s5s+24',
  '6m6m7m7m8m8m2p2p9p9p9p3s4s5s+22',
  '3m3m4m4m5m5m4p4p9p9p9p3s5s+4s+14',
  '5m6m7m1p2p3p5p6p7p8p8p6s7s+8s+13',
  '2m3m4m4m4m7m8m9m2p3p4p3s4s+5s+14',
  '3m4m7m8m9m7p8p9p3z3z3z4z4z+2m+23',
  '3m3m6m7m7m8m8m5p6p7p7s8s9s9m+12',
  '2m2m3m4m5m5m6m4p5p6p5s6s7s+7m+14',
  '7m8m9m1p2p3p4p5p6p7p9p3z3z+8p+11',
  '2m3m3m4m4m5m5m6m7m7p7p4s5s6s+11',
  '3m4m5m5m7m6p7p8p8p8p8p3s3s+6m+12',
  '3p4p5p5p6p7p7p8p2s3s3s3s4s9p+22',
  '3m4m5m6m7m8m4p5p2s2s3s4s5s+6p+24',
  '3m4m6p7p8p9p9p3s4s5s5s6s7s+2m+14',
  '1m2m3m5p5p5p6p3s4s5s5s6s7s7p+13',
  '1m1m3p3p4p4p8p8p4s4s5z6z6z5z+22',
  '1m2m3m7m8m9m1p2p3p8p9p9s9s+7p+12',
  '1m2m3m3p4p5p5p5p6p7p7p4z4z+6p+12',
  '2m3m4m6m8m2s3s4s6s6s8s8s8s+7m+13',
  '3m3m6m8m2p3p4p5p6p7p4s5s6s7m+11',
  '5m6m6m7m7m2p2p7p7p9p9p7z7z5m+14',
  '5m6m7m6p6p2s2s3s3s4s6s7s8s4s+13',
  '3m3m2p2p8p8p8p3s3s3s4s5s6s+2p+22',
  '1p2p3p4p6p3s3s6s6s7s7s8s8s5p+23',
  '3m4m5m5m6m7m2p3p4p5p5p2s3s1s+13',
  '4m4m5m4p4p7p7p1s1s7s7s3z3z+5m+13',
  '1m2m3m3p4p5p7p8p1s2s3s5s5s+9p+13',
  '5m6m7m5p6p7p2s2s5s6s2z2z2z4s+12',
  '1m1m1m2m5m6m7m1p2p3p1s2s3s+3m+14',
  '2m3m4m5m6m7m2p3p4p6p6p2s4s+3s+14',
  '5m5m7m8m9m5p6p2s3s4s6z6z6z+7p+22',
  '1m2m2m3m3m4m5m6m7m3p4p8s8s5p+11',
  '4m6m6m7m8m2p2p4p5p6p5s6s7s+5m+21',
  '2p3p4p5p6p7p1s1s3s4s5s7s8s+6s+22',
  '3m4m6p6p1s2s3s4s5s6s5z5z5z5m+11',
  '3m3m4m5m5m7m8m9m1p2p3p7p7p+4m+13',
  '5m7m3p4p5p6p7p8p2s3s4s8s8s6m+22',
  '2m6m6m7m7m9m9m5p5p8s8s5z5z2m+21',
  '5m5m6m7m5p6p7p3s4s5s7s8s9s+8m+11',
  '2m2m3m3m4m2p3p4p1s1s5s6s7s+4m+14',
  '3m4m5m5m6m2p2p2p3p4p1s2s3s7m+11',
  '2m3m4m4p4p6p6p6p5s5s6s6s7s+4s+21',
  '6m7m1p1p2p3p4p6p7p8p3s4s5s5m+12',
  '3m4m5m3p4p1s1s3s4s5s6s7s8s5p+11',
  '3m4m5m6m6m2p3p4p4p5p6p4s5s+3s+22',
  '3m3m4m5m5m7m8m9m1p1p3s4s5s+4m+24',
  '6m6m6m1p2p3p6p6p2s3s5z5z5z+1s+13',
  '3m4m4m4m4m3p4p5p3s4s5s6s6s+5m+14',
  '4m5m4p5p6p1s2s3s4s5s6s8s8s+3m+23',
  '6m7m4p5p6p3s3s4s5s5s6s6s7s5m+21',
  '5m6m7m5p6p6p7p8p4s4s5s6s7s+7p+23',
  '4m5m6m6m7m8m3s3s3s5s5s7z7z7z+12',
  '3m4m5m7p7p8p8p9p9p3s4s5s6s+6s+12',
  '2m3m4m7m7m3p3p4p4p5p5p4s5s3s+13',
  '3m3m6m7m8m6p7p2s3s4s5s6s7s5p+11',
  '2m2m2m2p2p3p5p4s5s6s3z3z3z4p+13',
  '5m5m7m8m1p2p3p7p8p9p2s3s4s+9m+31',
  '2p3p3p4p5p2s3s4s5s5s7s8s9s1p+24',
  '3m4m5m7m8m9m1s1s2s2s3s9s9s+3s+14',
  '2m3m4m8m8m1p2p3p7p8p9p2s3s+1s+11',
  '4m5m6m1p2p3p4p5p6p5s6s1z1z+7s+24',
  '2m2m2m3m4m6m7m2p3p4p6p6p6p8m+11',
  '4m4m4p4p5p5p6p5s5s6s6s7s7s3p+14',
  '4m5m6m6m7m1p1p1s2s3s7s8s9s+8m+14',
  '7m8m9m3p3p1s2s3s7s8s5z5z5z+9s+12',
  '1m2m3m4m5m7m8m9m3s4s5s3z3z6m+24',
  '3m4m2p3p4p4p5p6p8p8p3s4s5s+5m+13',
  '1m1m5m6m4p5p6p7p8p9p4s5s6s4m+11',
  '2m3m3m3m4m6m7m8m7p8p7s8s9s+9p+14',
  '5m6m7m7m8m9m1p2p2p3p3p6s6s+1p+22',
  '2m3m4m4m5m6m7m7m4p4p4p6s7s+8s+23',
  '4m5m6m6m7m8m8p8p8p6s6s8s8s+8s+22',
  '1m2m3m7m7m4p4p5p5p6p5s6s7s3p+22',
  '1m1m8m8m7p7p8p9s9s3z3z6z6z8p+11',
  '5m6m7m3p3p5p6p7p2s3s7s8s9s+1s+23',
  '2p2p3p3p6p6p8p8p1s3s3s9s9s+1s+12',
  '2m3m4m4m4m7m8m1p2p3p4p5p6p9m+13',
  '8m8m4p4p4p3s4s7s7s7s5z5z5z2s+21',
  '6m7m4p5p6p2s3s4s4s4s6s7s8s8m+13',
  '1p2p3p6p6p6p3s4s4s5s5s3z3z3s+14',
  '4m5m6m6m7m1p2p3p2s3s4s8s8s8m+14',
  '2m3m4m3p3p7p8p9p3s4s5s6s7s+8s+11',
  '7m8m5p6p7p1s1s2s3s3s4s4s5s+9m+21',
  '1m2m3m4m5m6m3p4p6p7p8p4z4z+2p+12',
  '4m5m6m6m7m3p4p5p6p7p8p1s1s8m+12',
  '8m8m2p3p4p1s1s3s3s4s4s5s5s+1s+11',
  '2m2m5m6m2p2p2p6p6p6p7z7z7z4m+14',
  '6m7m8m5p6p7p9p9p4s5s5s6s6s+4s+21',
  '2m4m6p6p6p2s3s3s3s4s4s5s6s+3m+11',
  '3m4m5m6m7m8m5s5s6s6s7s4z4z+4s+22',
  '1m2m3m7m8m9m1p1p3p4p5p3s4s+5s+11',
  '4m5m6m2p3p4p2s3s4s5s2z2z2z+2s+21',
  '9m9m2p3p4p1s2s3s5s6s6s7s8s+4s+11',
  '4m5m6m8m8m2p3p4p4s5s7s8s9s+6s+14',
  '4m4m6m7m8m1p2p3p3s4s5s5s6s7s+12',
  '8m8m3p4p4p5p6p2s3s4s6s7s8s2p+24',
  '2m3m4m5m6m7m4p4p5p7p4s5s6s6p+21',
  '2m4m4m5m6m2p2p4s5s5s6s6s7s+3m+24',
  '1p2p3p4p5p6p6p7p8p8p8p2z2z2z+13',
  '3m4m5m5m6m7m2p2p5p7p4s5s6s+6p+12',
  '4m5m6m5p5p7p8p2s3s4s5s6s7s+9p+12',
  '4m4m4m6m6m7m8m8m4p5p6p4s4s7m+11',
  '2m3m7m8m9m7p8p9p4s5s6s7s7s+1m+22',
  '3m3m4m5m5m9p9p3s4s5s7s7s7s+4m+11',
  '4m5m6m4s5s6s7s8s9s2z2z7z7z+7z+13',
  '3m4m5m5m6m7m7m8m4p5p6p4z4z+6m+11',
  '2m4m4m5m6m8m8m4p5p6p6s7s8s+3m+23',
  '6m7m7m8m9m1p1p5p6p7p1s2s3s5m+21',
  '5m5m8m8m8m4p5p6p6p7p7p7p8p+5m+23',
  '1p1p2p3p4p6p7p8p2s3s4s5s6s7s+23',
  '3m4m4m5m5m3s3s4s5s6s7s8s9s+6m+24',
  '2m3m4m7m7m4p5p6p3s3s3s4s5s+6s+12',
  '6m6m5p8p8p1s1s2s2s3s3s5s5s5p+13',
  '5m6m7m3p4p7p8p9p2s2s4s5s6s+2p+24',
  '6m7m7m8m8m2p3p4p6p6p2s3s4s+9m+13',
  '2m3m1p2p3p5p6p7p2s2s4s5s6s4m+22',
  '5m6m7m4p6p2s2s3s3s4s4s3z3z+5p+22',
  '2m3m4m3p4p5p8p8p3s4s6s7s8s5s+13',
  '7p8p9p2s2s3s4s5s7s7s8s9s9s+8s+23',
  '2m3m4m4m5m6m2p4p6s6s6s7s8s3p+21',
  '8m8m8m2p3p4p5p6p7p8p9p1s1s+1p+12',
  '4m5m8m8m5p6p7p3s4s5s5s6s7s+3m+12',
  '5m6m6m7m7m6p7p8p5s5s2z2z2z8m+21',
  '6m7m1p2p3p4p4p5s5s5s1z1z1z+5m+13',
  '6m7m8m5p6p6p7p8p1s1s6s7s8s+7p+12',
  '6m7m5p5p6p7p8p4s5s6s7s8s9s+5m+12',
  '2m3m4m5m6m7m8m3p4p5p5s6s7s2m+22',
  '1m2m3m4p4p6p7p2s3s4s4s5s6s5p+22',
  '1m2m3m3m4m3p4p5p6p7p8p9p9p+5m+13',
  '3p4p5p1s1s2s2s3s3s4s6s7s8s+1s+22',
  '6m6m7m8m9m6p7p8p3s3s4s5s5s+4s+12',
  '3m4m4m5m5m3p4p5p5p6p7p8p8p3m+23',
  '6m6m7m8m9m6p7p7p8p9p4s5s6s5p+14',
  '4m4m5m7m6p7p8p4s5s6s6s7s8s+6m+13',
  '3p4p5p2s2s3s3s4s4s8s8s6z6z6z+12',
  '4m5m6m6p6p7p7p8p8p1s2s3z3z3s+23',
  '6m6m6m2p3p4p6p7p3s4s5s7s7s+5p+24',
  '3m4m5m8m8m6p7p8p5s6s7s8s8s8s+23',
  '7m8m9m4p4p5p6p7p8p9p4s5s6s+7p+12',
  '4m5m6m1p1p5p6p7p4s5s6s4z4z+4z+14',
  '1m2m3m1p2p3p5p5p6p7p8p2s3s1s+12',
  '2m3m6m7m8m1p2p3p4p5p6p6p6p4m+11',
  '4m5m6m7m7m5s7s4z4z4z5z5z5z+6s+11',
  '3m4m4p5p6p7p7p2s3s4s6s7s8s5m+13',
  '3m4m5m5m6m7m8m8m5p6p7p5s7s6s+24',
  '2m3m4m5m5m7m8m9m2p3p4p5s6s7s+11',
  '1m1m5m5m7m7m7p7p9p9s9s6z6z+9p+11',
  '1m2m2m3m3m4m3p4p5p1s1s6s7s8s+21',
  '4m5m2p2p7p8p9p4s4s4s5z5z5z3m+23',
  '3m4m1p1p4p5p6p2s3s4s6s7s8s+2m+21',
  '2m3m4m5m6m7m9m9m5p6p7p4s5s+6s+24',
  '3m4m5m3p4p5p2s3s4s5s6s9s9s1s+11',
  '2m3m4m4m5m6p7p8p5s5s6s7s8s6m+13',
  '4m5m6m4p5p5p6p6p7p9p9p2s3s+4s+11',
  '4m4m5m5m6m7m7m3p4p5p7p8p9p3m+24',
  '3m4m5m6m6m6m8m5p6p7p4s5s6s+7m+13',
  '6m7m6p7p8p9p9p2s2s2s6s7s8s+8m+23',
  '5m5m2p6p6p7p7p2s2s5s5s3z3z+2p+24',
  '6m6m1p2p3p4p5p6p3s4s7s8s9s+5s+12',
  '3m3m7m8m9m8p9p7s8s9s5z5z5z7p+22',
  '3m4m5m1p2p3p7p7p4s5s5s6s7s6s+11',
  '4m4m1p1p2p2p3p3p2s3s3s4s5s+1s+22',
  '1m9m9m1p1p6p6p7p7p7s7s5z5z+1m+11',
  '1p2p3p5s6s4z4z4z5z5z5z6z6z+7s+23',
  '1m1m1p2p3p5p6p7p8p9p3s4s5s+7p+21',
  '2m3m4m4m5m6m7m1p2p3p4p5p6p+1m+23',
  '6m6m6p6p7p7p8p9p9p9p3s4s5s+8p+22',
  '2m2m3m3m4m6m6m7m7m8m8m8p8p+1m+11',
  '4m5m6m8m8m5p6p2s3s4s7s8s9s+7p+12',
  '3m4m2p3p4p6p6p7p7p7p4s5s6s+2m+11',
  '4m5m1p1p3p4p4p5p5p6p2s3s4s3m+11',
  '6m7m6p7p8p1s2s3s5s6s7s4z4z+8m+13',
  '2m3m5m6m7m2p2p2s3s4s6s6s6s+4m+11',
  '5m6m7m2p2p1s2s3s4s5s6s7s8s+6s+14',
  '1m2m3m5m6m1p2p3p4p4p7p8p9p+7m+22',
  '3m4m5m9m9m6p7p8p5s6s7s8s9s4s+11',
  '5m6m2s3s3s4s4s5s7s7s7s8s9s+7m+12',
  '3m4m2p3p4p8p8p2s3s4s7s8s9s2m+22',
  '4m5m6m7m8m1p1p2p3p4p4s5s6s+3m+24',
  '6m8m2p3p4p4p5p6p7p7p7z7z7z+7m+14',
  '4m5m6m3s4s5s7s7s8s8s9s9s5z+5z+11',
  '2m3m4m5m6m1p1p4p5p6p6s7s8s+1m+13',
  '4m5m6m6m7m8m3s5s5s5s6s7s8s+4s+23',
  '2m2m6m7m8m2p3p4p7p8p9p6s7s+8s+22',
  '5m6m7m1p1p2p3p4p5s5s6s6s7s4s+22',
  '1s2s2s2s2s3s4s5s6s8s8s5z5z+5z+12',
  '4m5m6m6m1p2p3p4p5p6p7p8p9p+6m+11',
  '1m2m3m3m4m5m1p2p3p5p5p3s4s+2s+13',
  '3m4m5m7m7m8m8m9m9m1s3s6z6z+2s+11',
  '2m3m4m4m5m6m7m8m9m6p7p7s7s+8p+12',
  '4m4m8m8m4p4p4s4s6s6s4z4z5z5z+13',
  '6m6m6p7p8p2s3s4s6s7s7s8s9s+8s+22',
  '6m7m3p4p5p6p7p8p3s3s5s6s7s8m+22',
  '2m3m4m2p3p4p8p8p2s4s4s5s6s+3s+12',
  '1m2m3m1p2p3p6p7p8p9p9p2s3s+4s+11',
  '2m3m4m6m7m8m2p2p3p4p7s8s9s+2p+12',
  '7m7m8m8m9m9m6p6p7p9p4s5s6s+8p+14',
  '4m5m6m6m6m2p3p4p4s4s5s6s7s3m+22',
  '4m5m5p6p7p9p9p3s4s5s7s8s9s6m+13',
  '2m4m7p8p9p3s4s5s4z4z5z5z5z+3m+14',
  '6m6m1p2p3p5p6p2s3s4s6s7s8s+4p+24',
  '3m4m5m6m7m8m4p5p6p7p7p7s8s6s+11',
  '7m8m9m3p3p7p8p9p2s3s7s8s9s+4s+21',
  '3m4m5m5p6p6p7p7p4s4s6s7s8s+8p+12',
  '4m5m6m1p2p5s6s7s9s9s7z7z7z+3p+21',
  '2m3m4m5m6m7m2p3p3p4p5p8s8s+4p+21',
  '2m3m3m4m4m6m6m6m7m7m3s4s5s+5m+11',
  '2m3m3p3p4p5p6p6p7p8p4s5s6s+4m+12',
  '2m2m4m5m6m2p3p5p6p7p5s6s7s+4p+12',
  '5m5m5m4p5p6p7p7p2s3s4s4s5s6s+24',
  '4p4p7p8p9p3s4s5s7s8s9s5z5z+5z+24',
  '3m4m5m3p4p4p5p6p1s2s3s6s6s2p+14',
  '3m3m4m6m2p3p4p6p7p8p3s4s5s+5m+22',
  '1m1m4p5p6p5s5s3z3z3z7z7z7z+1m+11',
  '8m8m2p3p3p4p4p6p7p8p7s8s9s5p+23',
  '7m8m9m2p3p4p3s3s4s5s6s7z7z+7z+22',
  '2m2m4m5m6m6m7m6p7p8p3s4s5s+8m+11',
  '2p2p3p3p4p4p6p6p6s7s8s9s9s+9s+24',
  '3m4m6m7m8m6p6p7p8p9p6s7s8s+5m+21',
  '2m3m6m7m8m3p4p5p2s2s2s3s4s+1m+24',
  '2m3m4m5m6m7m3p3p7p8p9p5s6s4s+14',
  '1m1m3m4m5m6m7m8m3p5p3s4s5s+4p+12',
  '2m2m3m3m4m4m3p4p2s2s6s7s8s5p+14',
  '1m2m3m4m5m6m3p4p5s6s7s9s9s+2p+24',
  '1m2m3p4p5p7p8p9p6s6s2z2z2z3m+21',
  '2m3m4m5m5m7p8p9p1s2s3s6s7s+8s+22',
  '5m6m7m3p4p5p2s2s4s4s5s6s6s+5s+11',
  '2m3m4m7m8m9m5p6p7p7s8s9s9s+9s+12',
  '3m4m5m6m6m3p4p5p3s4s5s6s7s2s+14',
  '4m5m6m7p8p9p2s2s3s4s5s7s8s6s+14',
  '1m2m3m6m7m7m7m8m4s5s6s3z3z+3z+23',
  '2m3m4m4p5p6p6p7p4s4s5s6s7s8p+23',
  '2m3m4m5m6m5p6p7p3s4s5s6s6s+7m+23',
  '2m3m4m5m5m7m8m9m4s5s6s6z6z+6z+21',
  '7m7m8m8m8m3p4p5p3s4s5s6s7s2s+21',
  '4m4m6m7m8m2p3p4p4s5s6s7s8s6s+23',
  '1m1m8m8m9m9m1p1p7p7p2z2z5z+5z+13',
  '2m3m4m5m5m6m7m1s2s3s6s7s8s5m+14',
  '3m3m4m4m8m8m1p1p8p9p9p5z5z8p+13',
  '3m4m5m3p4p6p7p8p1s2s3s1z1z+2p+23',
  '7m7m8m8m9m9m3p4p5p3s4s7s7s+2s+22',
  '3m4m5m9p9p1s2s3s3s4s5s6s7s+2s+21',
  '4m5m7m8m9m4p5p6p3s4s5s2z2z6m+13',
  '4m5m6m4p4p5p7p4s5s6s2z2z2z+6p+24',
  '5p5p5p6p6p7p7p7p8p9p2s3s4s+8p+12',
  '2p3p4p6p7p8p7s8s9s1z1z6z6z+6z+14',
  '1s2s3s3s4s5s7s8s9s9s7z7z7z6s+22',
  '1m1m4m5m6m2p3p4p5p7p7z7z7z+6p+11',
  '6m7m8m3p3p6p8p3s4s5s6s7s8s+7p+22',
  '3m4m5m6m6m3p3p3p2s3s4s6s8s7s+14',
  '1m2m3m2p2p6p7p7p8p9p6s7s8s5p+21',
  '4m5m6m8m8m6p7p1s2s3s5s6s7s8p+13',
  '3p3p4p5p5p9p9p1s2s3s6z6z6z4p+24',
  '3p4p5p7p8p9p2s2s7s8s9s5z5z+5z+11',
  '3m4m5m7p8p9p5s7s1z1z6z6z6z6s+12',
  '6p6p7p7p8p9p9p9p2s3s4s9s9s8p+14',
  '4m4m5m5m6m6m1p3p6s7s8s3z3z2p+14',
  '4m5m6m6m7m8m4p6p3s3s4s5s6s+5p+21',
  '7m8m1p2p3p4p5p6p7p8p9p9s9s+9m+14',
  '5m7m8m8m2p2p2p5p6p7p4s5s6s+6m+24',
  '5m5m8m8m8m4p5p6p2s2s4s5s6s5m+11',
  '4m5m2p3p4p4p5p6p7p7p6s6s6s3m+14',
  '6m6m6m5p6p7p7p4s5s6s4z4z4z4p+24',
  '1m2m3m4m5m6m7m8m9m9m9m4p5p+3p+12',
  '6m7m8m1p1p4p5p5s6s7s7s8s9s6p+22',
  '6m6m6m7m8m2p3p4p5p6p7p5s6s+7s+24',
  '3m3m4m4m5m5m2p4p5p5p6s7s8s+3p+21',
  '2m3m4m7m8m9m3p4p5p2s2s3s4s2s+13',
  '4m5m6m1p2p3p6p7p8p4s5s9s9s6s+22',
  '2m3m4m5m6m4p4p4s5s6s7s8s9s+7m+21',
  '1m2m3m1p2p3p4p5p7p8p9p6z6z6p+21',
  '4m6m2p3p3p4p4p5p7p7p3s4s5s+5m+11',
  '1m2m3m4m5m6m6m5s6s7s5z5z5z+3m+11',
  '3m4m3p4p5p7p8p9p6s7s8s9s9s+2m+11',
  '1m2m3m6m7m8m6s7s8s2z2z6z6z+6z+14',
  '6m7m4p5p6p8p8p5s5s5s6s7s8s8m+11',
  '4m5m6m4p4p7p8p5s5s6s6s7s7s+9p+11',
  '1m1m2m5m5m7m7m7p7p6s6s5z5z+2m+12',
  '2m3m4m6m7m8m2p3p4p4s5s7s7s+6s+11',
  '4m4m5m5m6m2p2p2p5p5p7s8s9s+6m+24',
  '3m4m5m4p5p6p2s3s6s7s8s2z2z4s+13',
  '3m4m2p2p6p7p8p2s3s4s5s6s7s2m+21',
  '2m2m4m5m6m7p8p2s3s4s6s7s8s6p+11',
  '2m3m4m6m6m3p3p4p5p5p5s6s7s+4p+23',
  '2m3m4m7m8m1p2p3p5p5p6p7p8p+9m+24',
  '1m2m3m7m8m9m7p8p2s3s4s6s6s+9p+11',
  '5m6m7m1s2s3s4s5s6s5z5z6z6z+5z+11',
  '6m7m8m4p5p6p7p8p9p4s5s4z4z+3s+23',
  '9m9m7p8p1s2s2s3s3s4s6s7s8s+9p+23',
  '1m2m3m5m5m5p6p7p1s2s3s3s4s+5s+13',
  '2m3m4m4m5m6m3p3p4p5p5s6s7s+6p+11',
  '3m4m5m2p3p1s2s3s5s5s7s8s9s+1p+12',
  '4m4m5m6m3p4p5p2s3s4s5s6s7s+7m+24',
  '6m6m7m7m9m9m1p2p2p1s1s2z2z+1p+21',
  '6m6m4p4p5p5p3s3s8s8s9s4z4z+9s+21',
  '2m3m4m6m6m1p2p3p6p7p3s4s5s+5p+21',
  '4m5m6m7m7m2p3p4p5p5p6p6p7p1p+12',
  '5m5m6m7m8m1p2p3p6p8p6s7s8s+7p+13',
  '3m4m5m2p3p4p7p8p9p4s5s8s8s6s+14',
  '3m3m4m4m5m3p3p5p6p7p4s5s6s+5m+23',
  '6m6m7m7m8m7p8p9p7s7s5z5z5z+5m+22',
  '3m3m3m6m7m8m4p4p5p6p6p7p7p+8p+24',
  '7m8m8m8m7p8p9p7s8s9s5z5z5z7m+14',
  '1m2m3m5m6m1p2p3p6p6p6s7s8s+7m+22',
  '3m4m5m5p7p1s1s5s6s7s2z2z2z6p+22',
  '4m5m6m3p4p5p6p7p9p9p5s6s7s+8p+23',
  '5m5m6m7m7m2s2s2s3s3s6s7s8s+6m+13',
  '2p3p4p4p5p5p6p7p3s3s7s8s9s+3p+14',
  '2m3m4m4m5m5m6m6m1p2p3p4s4s+7m+12',
  '2p3p3p4p5p1s2s3s3s4s5s7s7s+4p+14',
  '2m3m4m7m8m3p3p5p6p7p6s7s8s6m+11',
  '7m8m5p5p2s3s4s5s5s5s2z2z2z9m+12',
  '2m3m4m4p5p6p7p9p4s4s4z4z4z+8p+24',
  '5m5m6m7m8m5p6p7p3s4s6z6z6z+5s+14',
  '4m4m5m5m6m6m7p7p6s7s1z1z1z+5s+21',
  '7m8m9m1p2p3p1s3s7s8s9s4z4z2s+23',
  '3m4m5m3p4p5p7p8p9p4s5s7z7z+3s+21',
  '2m3m4m3p4p4p5p5p6p4s4s6s7s8s+14',
  '5m7m4p4p5p6p6p7p7p8p5s6s7s6m+23',
  '2m2m5m6m6m7m7m3p4p5p2s3s4s+5m+22',
  '5m6m6m7m7m3p3p4p4p4p4p5p5p+5m+13',
  '2m3m4m5m6m7m2p3p6p7p8p9p9p+1p+13',
  '5m6m7m5p6p7p2s3s5s6s7s2z2z+4s+13',
  '2m3m4m3p3p3p4p5p6p7p8p3s5s4s+22',
  '1m1m2m2m6m6m5p6p6p5z5z7z7z+5p+11',
  '5m5m7m7m9m9m4p4p8p3s3s8s8s+8p+23',
  '4m5m2p2p2p3p4p4p5p6p5s6s7s+3m+14',
  '7p7p3s4s5s5s6s6s7s8s5z5z5z+4s+12',
  '5m5m7m7m7m3p4p4p5p5p6p6s8s7s+11',
  '2m3m4m6m6m6m7m8m2p3p5p6p7p4p+11',
  '2m2m7m8m8m9m9m4p5p6p5s6s7s7m+12',
  '1m2m3m6m7m7m8m9m4p5p6p7s7s8m+11',
  '3m3m4m5m5m1p2p3p6s6s8s8s8s4m+14',
  '1s2s3s4s4s5s6s6s7s7s8s6z6z+6z+24',
  '5m5m4p4p5p6p6p4s4s5s5s6s6s5p+14',
  '4m4m4m5m6m2p3p4p5p5p2s3s4s+4m+12',
  '2m3m3m4m4m5m6m7m8m3p6s7s8s+3p+13',
  '1m2m3m4p5p6p6p7p8p4s5s7s7s+3s+22',
  '9m9m5p5p9p9p2s2s3s3s3z3z7z+7z+11',
  '4m5m2p3p4p4p5p6p3s3s6s7s8s6m+11',
  '1m1m7m8m9m1p2p3p7p9p7s8s9s+8p+11',
  '8m8m7p7p7p5s6s7s8s8s2z2z2z+8m+23',
  '6m7m2p2p6p7p8p3s4s5s7s7s7s5m+13',
  '1m2m3m7m7m6p7p8p3s4s5s5z5z+5z+22',
  '1m2m3m6m7m8m4p5p9s9s5z5z5z+3p+14',
  '2m3m4m5m6m7m5p6p7p4s4s6s7s+8s+13',
  '3m4m5m7m8m9m4p5p6p9p9p6s7s8s+11',
  '4m5m2p2p3p4p4p5p5p6p5z5z5z+3m+13',
  '6m6m9m9m9m7p7p7p2s2s2s9s9s6m+24',
  '3m3m9m9m9m7p8p9p2s3s3s4s4s2s+22',
  '5m5m6m6m7m7m1p1p3p4p5p2s2s+1p+13',
  '1m2m2m3m3m4m8m8m2p3p4p3s4s+5s+11',
  '5m6m7m7m8m9m9p9p3s3s4s4s5s5s+24',
  '7m8m3p4p5p7p7p1s2s3s4s5s6s9m+11',
  '1m2m3m1p2p3p6p6p1s2s1z1z1z+3s+21',
  '1m2m3m3m4m3p4p5p2s3s4s6s6s+2m+14',
  '4m4m2p3p4p6p7p7s8s9s5z5z5z+8p+12',
  '3m4m4m5m6m7m8m9m5p6p7p2s2s+5m+21',
  '9m9m1p2p3p5p6p7p8p9p3s4s5s7p+23',
  '2m3m4m3p3p4p5p6p4s5s7z7z7z3s+11',
  '4m4m6m7m8m6p7p8p2s3s4s6s7s+8s+13',
  '3m3m5m6m4p5p6p8p8p8p3s4s5s7m+21',
  '2m9m9m8p8p1s1s2s2s1z1z6z6z2m+13',
  '7m8m9m6p7p1s2s3s4s4s5z5z5z+8p+24',
  '1m1m3m4m5m6m7m8m2p3p4p2s3s1s+24',
  '6m6m7m7m7m6p8p6s6s7s7s8s8s+7p+24',
  '3p3p3p4p5p5p5p5p2s3s4s7s7s+6p+22',
  '3m4m5m7m7m5p5p6p6p7p7p3s4s5s+21',
  '2m6m6m2p2p4p4p5p5p8p8p6s6s+2m+11',
  '2m3m4m4m5m5m5m7p8p9p6s7s8s6m+13',
  '4m5m6m8m8m8m6p6p3s4s5s1z1z+1z+12',
  '2m3m4m2p2p2p3p4p5p5s6s7s7s4s+13',
  '1m5m5m1p1p3p3p4p4p7s7s9s9s+1m+12',
  '1m2m4m5m6m7m8m9m7p8p9p1z1z+3m+12',
  '5m6m5p6p7p2s3s4s6s7s8s4z4z7m+22',
  '2m3m4m2p3p4p4p5p6p4s4s6s7s+8s+14',
  '2m3m4m8m8m2p3p4p5p6p2s3s4s+7p+24',
  '2m3m4m6p7p8p1s2s3s5s6s7s7s4s+14',
  '1m2m3m1p2p3p9p9p1s3s4s5s6s+2s+13',
  '5m6m7m8m8m5s6s7s7s8s4z4z4z+6s+24',
  '6m7m8m2p3p4p3s3s4s5s5s6s6s7s+21',
  '5m5m6m7m8m3p4p5p5p6p2s3s4s+7p+21',
  '6m6m3p5p6p6p6p2s2s2s5s6s7s+4p+14',
  '2p3p4p4p5p6p7p8p1s1s3s4s5s+9p+24',
  '1p1p2p2p1s1s2s2s3s7s7s9s9s3s+11',
  '2m3m4m6m7m8m4s4s5s6s6s7s8s7s+11',
  '4m5m6m6m6m3p4p5p4s4s4s6s7s8s+13',
  '2m3m3m4m4m5m5m3p4p5p1s2s3s+2m+23',
  '4m4m5m5m6m7m8m9m6p7p8p4z4z3m+12',
  '2m2m5m6m3p4p5p6p7p8p6s6s6s+7m+23',
  '8m8m6p7p1s2s3s3s4s4s5s5s6s8p+14',
  '1m2m3m4m5m6m2p3p5p5p4s5s6s+1p+23',
  '3m4m5m6m7m6p7p8p6s6s6s8s8s+8m+11',
  '9m9m1p1p2p2p3p3p4p5p2s3s4s+3p+13',
  '3m4m5m6m7m8m5p5p7p8p7s8s9s+6p+12',
  '4m5m6m4p5p6p6p6s6s6s4z4z4z+3p+14',
  '2m3m4m6m7m7m7m8m4s5s5z5z5z3s+12',
  '2m3m4m6m7m8m8m8m2s2s3s3s4s4s+24',
  '4m5m4p5p6p6p6p3s4s5s6s7s8s+3m+11',
  '1m2m3m6m7m8m1p2p3p5p5p2s3s4s+23',
  '3m4m5m7m8m9m1s1s3s4s5s2z2z+2z+12',
  '4m5m6m7m8m9m5p5p2s3s7s8s9s4s+14',
  '6m7m8m3p4p5p2s3s4s4s7s8s9s1s+12',
  '1m1m2m2m4m4m3s3s4s4s5s9s9s+5s+13',
  '4m5m6m8m8m5p6p2s3s4s6s7s8s+4p+14',
  '2m3m4m6m7m8m5p6p4s4s6s7s8s+4p+21',
  '6m7m5p5p6p7p8p4s5s6s6s7s8s+8m+14',
  '3m4m5m6m7m8m8m4p5p6p7s8s9s2m+24',
  '3p5p5p6p7p2s3s4s9s9s5z5z5z+4p+22',
  '4m4m2p3p4p5p6p7p4s5s7s8s9s+6s+11',
  '4m4m5m5m6m6m3p4p3s4s5s7s7s+2p+12',
  '7m7m2p3p4p5p6p7p2s3s5s6s7s+4s+23',
  '6m6m7m7m8m8m7p8p6s7s8s6z6z+9p+13',
  '2m2m3m4m5m6m6m6m4p4p3s4s5s4p+21',
  '5m7m7m8m9m7p8p9p2s2s7s8s9s+6m+21',
  '4m5m5m6m6m7m7s8s8s9s9s3z3z+7s+14',
  '4m5m6m2p3p4p4s4s6s7s8s5z5z+5z+14',
  '5m6m7m7m8m9m5p6p7p8p9p5s5s+4p+13',
  '2m3m4m5m5m6m7m8m4p5p5s6s7s3p+23',
  '3m4m5m6p6p1s1s2s2s3s3s7s9s8s+14',
  '5m5m5m4p4p4s5s7s7s7s4z4z4z+3s+21',
  '3m4m5m6m7m3p3p7p8p9p1s2s3s+5m+12',
  '3m4m5m5m6m7m8m8m4p5p6p7s8s6s+12',
  '2m3m4m4m5m6m4p5p6p7p7p2s3s+4s+22',
  '2m2m2m4m5m6m6p7p3s3s5s6s7s+8p+24',
  '4m5m2p3p4p5p5p7p8p9p2z2z2z6m+12',
  '7m8m9m7p7p3s4s7s8s9s1z1z1z2s+13',
  '2m3m6m7m8m4s4s5s6s7s1z1z1z1m+21',
  '2m3m6m6m1p2p3p7p8p9p3s4s5s+1m+23',
  '1m2m2m3m3m1p2p3p3s3s5s6s7s4m+22',
  '4m4m5m5m6m6m3p4p5p3s4s7s7s+2s+11',
  '5m6m7m7m8m6p6p2s3s4s7s8s9s+6m+14',
  '1m1m2m2m3m3m1p3p6p7p8p1s1s2p+21',
  '2m3m7m8m9m2p3p4p3s3s6s7s8s+4m+22',
  '7m8m2p2p5p6p7p7p8p9p5s6s7s+6m+11',
  '4m5m5m6m6m7m2p3p6s7s8s9s9s+1p+12',
  '9m9m3p3p1s1s3s6s6s7s7s1z1z+3s+24',
  '2m4m5m5m5m2p3p4p5p5p5s6s7s+3m+11',
  '2m3m4m5m5m5m6m7m2s3s7s8s9s+1s+21',
  '3m4m5m9m9m5p6p7p3s4s5s6s7s+2s+22',
  '4m5m6m2p2p6p6p7p7p8p8p6s8s+7s+13',
  '1m1m3m4m5m5m6m4p5p6p7s8s9s4m+24',
  '5m7m7m8m9m7p8p9p4s4s7s8s9s+6m+23',
  '2m2m7m8m9m3p4p5p2s3s4s5s6s1s+24',
  '2m3m4m4m5m6m6m6m6m4p4p6s7s+8s+22',
  '4m5m6m7m8m6p7p8p3s4s5s7s7s6m+14',
  '4m5m6m6m3p4p5p6p7p8p5s6s7s+3m+12',
  '1m1m5m6m7m2s2s3s3s4s5s6s7s4s+22',
  '3m9m9m9p9p4s4s1z1z3z3z6z6z+3m+12',
  '3m4m5m6m7m7p7p7p5s5s6s7s8s+8m+14',
  '5m5m4p4p6p6p9p9p1s6s6s6z6z1s+14',
  '3m4m5m5m6m7m5p6p7p4s5s6s7s+4s+21',
  '2p3p4p5p6p7p8p9p1s2s3s5s5s1p+12',
  '9m9m9m1p1p1p5p5p6s7s4z4z4z5s+12',
  '2m3m3m4m4m5m4p5p6p1s1s5s6s7s+23',
  '3m3m3m5m6m7m2p2p6p7p8p3s5s4s+11',
  '5m5m6m7m8m5p6p7p8p9p2s3s4s4p+13',
  '4m5m6m2p2p2p3p4p4s4s6s6s6s5p+23',
  '2m3m4m5m6m7m8m9m7p8p9p6s6s+4m+24',
  '7m7m7m8m6p6p6p2s3s4s6s7s8s6m+14',
  '5m6m7m4p5p6p7p7p7p8p9p4s5s+3s+24',
  '5m6m7m8m8m3p4p5p6p7p8p6s7s8s+21',
  '4m6m3p4p5p5p6p7p2s3s4s5s5s+5m+24',
  '4m5m6m7m8m9m2p3p4p5s6s7s7s7s+13',
  '5m6m7m3p4p5p5p6p7p2s5s6s7s+2s+11',
  '1m1m2m3m4m3p4p5p7p8p9p5s6s+7s+14',
  '3m4m5m6m7m8m3p4p4p5p6p8p8p+5p+13',
  '2m3m4m5m5m6m7m5p6p7p3s4s5s2m+23',
  '1m2m3m6m7m3p4p5p7p8p9p1z1z+8m+23',
  '2m2m3m4m4m1p2p3p3p3p4p5p6p3m+12',
  '5m5m5p5p6p6p7p7p3s4s5s7s9s8s+12',
  '3m4m5m1p2p3p3p4p9p9p3s4s5s+2p+12',
  '1m2m3m2p3p4p4p6p7p8p1s2s3s+1p+11',
  '3m4m5m6m7m8m6p7p2s2s3s4s5s8p+21',
  '4m5m5m6m6m7m1p2p3p4p5p5s5s6p+12',
  '5m5m5m4p4p4p9p9p5s7s1z1z1z6s+13',
  '3m4m5m6m7m8m3p3p5p6p7p7s8s+9s+12',
  '8m8m1p2p3p4p5p2s3s4s6z6z6z6p+24',
  '3m4m4m5m6m6m7m8m5s6s7s9s9s2m+14',
  '9m9m1p2p3p4p5p6p7p8p9p5s5s5s+14',
  '4m4m4m5m6m3p4p5p6p6p3s4s5s+6p+21',
  '3m4m5m6m7m8m3p4p5p5s6s8s8s+7s+14',
  '3m4m1p2p3p4p5p6p3s4s5s4z4z5m+23',
  '3m4m5m8m8m8m1p1p2p2p3p4s4s3p+13',
  '2m3m4m7m7m3p4p5p5s6s7s7z7z7z+13',
  '5m6m7m3p4p5p6p6p1s2s3s6s7s+8s+21',
  '2m3m4m3p3p3p5p6p5s6s7s7s7s4p+22',
  '2m2m2m5m6m6m7m8m3p3p4p5p6p4m+12',
  '4m4m6m6m6m2p3p4p4s5s5s6s6s+4s+14',
  '7m8m9m3p4p5p1s2s3s5s5s7s8s+9s+13',
  '2p2p3p4p5p6p7p1s2s3s4s5s6s+8p+24',
  '7m8m2p3p3p4p4p5p1s1s6s7s8s6m+13',
  '4m4m6p6p1s1s7s7s8s8s1z1z3z3z+22',
  '6m7m8m1p2p3p2s3s4s9s9s2z2z2z+21',
  '2m2m5m5m7m7m2p2p5s5s1z3z3z+1z+11',
  '2p3p4p5p6p7p3s3s5s6s7s8s9s+4s+21',
  '6m7m1s2s3s4s5s6s7s8s9s6z6z5m+12',
  '7m7m3p4p5p1s2s3s4s5s7s8s9s+6s+11',
  '3m3m5m5m7m7m3p3p4s4s5s1z1z5s+23',
  '5m6m7m2p2p3p4p5p6p6p7p7p7p+6p+21',
  '7m7m2p3p4p4p5p6p7p8p9p4s5s+6s+12',
  '7m8m9m1p2p3p4p5p6p2s3s5s5s1s+13',
  '3m4m5m7m7m3p4p1s2s3s7s8s9s+2p+23',
  '4m5m6m8m8m8p9p1s2s3s2z2z2z+7p+24',
  '5m6m7m1p2p3p3s3s6s7s7s8s9s5s+14',
  '6p6p7p7p9p5s5s7s7s2z2z3z3z+9p+12',
  '1m2m3m7m7m1p2p3p4p5p6p2s3s+1s+21',
  '3m4m5m3p4p5p7p7p4s5s7s7s7s+3s+23',
  '9m9m3p4p5p6p7p8p3s4s5s7s8s6s+13',
  '5m6m7m6p6p7p8p9p4s5s6s7s8s9s+13',
  '3p4p5p5p7p8p9p2s3s4s6s7s8s+2p+12',
  '1m2m3m4m5m6m7m8m5p5p5s6s7s+9m+13',
  '2m3m4m5m6m7m5p5p7p8p9p4s5s6s+11',
  '7m8m1p2p3p4p5p6p6p7p8p1z1z+9m+22',
  '6m8m5p5p6p7p8p5s6s6s7s7s8s+7m+13',
  '2m3m5m6m7m4p5p6p6p7p8p3s3s4m+13',
  '1m2m3m6m7m8m5p6p7p3s3s5s6s7s+24',
  '4m4m6m7m8m1p2p3p5p6p6s7s8s7p+12',
  '1m1m3m3m7m7m1s1s8s8s3z3z5z5z+12',
  '3m4m5m8m8m6p7p8p2s3s5s6s7s4s+21',
  '3m4m5m5m6m7m5p6p7p2s3s7s7s4s+12',
  '4m5m5m5m6m3s4s4s5s5s6s6z6z6z+11',
  '2m3m4m5m6m7m2p3p4p6p6p5s6s4s+14',
  '3m4m5m6p7p2s2s2s3s3s6s7s8s5p+24',
  '3m4m5m4p5p9p9p3s4s5s4z4z4z3p+11',
  '1m1m2m3m4m5m6m7m2p3p5p6p7p4p+14',
  '5m5m6m6m7m7m5p5p6p7p8p3s4s2s+14',
  '2m3m4m2p3p6p7p8p9p9p5s6s7s+1p+14',
  '2p2p4p5p6p1s2s4s5s6s7s8s9s+3s+21',
  '1m1m1m2m2m2m3m5m6m7m7m8m9m4m+11',
  '1m2m2m3m3m4m2p2p3p4p4p5s5s3p+22',
  '5m6m1p1p4p5p6p4s5s6s7s7s7s+4m+24',
  '1m1m6m7m8m3p4p4p5p5p7s8s9s6p+13',
  '1m2m3m4m5m6m1p2p3p5p6p8p8p+7p+22',
  '4m4m8m8m9m2s2s4s4s9s9s1z1z9m+24',
  '9m9m1p2p3p3p4p5p2s3s5s6s7s4s+11',
  '6m7m8m3p4p5p2s2s3s4s5s6z6z+6z+14',
  '1m2m3m5m5m6m6m7m5p6p7p1z1z+7m+14',
  '1p2p3p7p8p9p1s1s2s3s4s6s7s+8s+24',
  '4m5m6m7m7m4p5p6p4s5s6s7s8s+9s+22',
  '2m3m4m7m8m9m6p6p2s3s7s8s9s+1s+11',
  '6m7m7m8m8m1p2p3p2s3s4s6s6s9m+33',
  '1m2m3m7m8m6p6p4s5s6s6z6z6z+9m+11',
  '6m6m6m2s3s4s7s8s8s9s9s4z4z+7s+13',
  '2m3m3m4m5m5p5p3s4s5s8s8s8s4m+22',
  '7m7m7p8p8p9p9p7s7s2z2z3z3z+7p+14',
  '7m8m9m1p1p2s3s4s6s7s8s6z6z+6z+14',
  '5m5m7m8m9m2p4p3s4s5s1z1z1z+3p+14',
  '3m4m5m6m6m6m8m4p5p6p4s5s6s+7m+21',
  '2m3m4m5m6m7m6p6p7p7p7p4s5s+6s+14',
  '4m5m6m1p1p3p4p5p7p8p9p6s7s5s+21',
  '3m3m8m8m8m7p7p7p3s3s5s6s7s+3m+12',
  '8m8m8p8p3s3s4s4s5s1z1z5z5z+5s+22',
  '1m2m3m6m7m8m3s3s4s5s5s6s7s6s+21',
  '1m2m3m8m8m4p5p6p7p8p9p3s4s5s+11',
  '7m7m9m9m4p4p8p2s2s7s7s8s8s8p+24',
  '1m2m3m6m6m7m7m8m8m7p8p9p5z+5z+24',
  '5m6m1p1p3p3p4p4p5p5p7s8s9s+4m+13',
  '5m6m7m2p3p4p7p8p9p5s5s6s7s8s+13',
  '2m3m4m9m9m2p3p3p4p4p4s5s6s5p+13',
  '4m5m6m2p3p4p7p8p5s6s7s8s8s+9p+11',
  '2m3m4m3p4p5p6p7p1s1s3s4s5s+5p+14',
  '6m7m8m3p4p5p7p7p3s4s5s6s7s+8s+22',
  '3m3m5m5m6m6m8m8m8s8s9s9s3z+3z+22',
  '2m3m4m7m8m4p4p6p7p8p6s7s8s+9m+11',
  '3p4p4p5p6p7p8p9p4s5s6s9s9s+5p+21',
  '2m2m5m6m2p3p4p5p6p7p7p8p9p7m+12',
  '2m3m4m2p3p4p5p6p7p5s6s4z4z7s+21',
  '3m4m4m5m6m4p5p6p4s5s6s8s8s+2m+11',
  '5m6m7m4p4p4p8p8p2s3s4s6s6s6s+23',
  '4m4m4m6m7m8m3p3p3p6p7p4s4s+8p+11',
  '5m6m5p6p7p9p9p2s3s4s6s7s8s+7m+11',
  '7m8m4p5p6p7p8p9p5s6s7s9s9s+6m+12',
  '1m1m3m3m5m5m2p2p3p7s7s1z1z+3p+22',
  '4m4m6m7m4s5s6s6s7s8s7z7z7z5m+24',
  '2m3m4m7m8m3p4p5p7p7p1s2s3s9m+23',
  '2m3m4p5p6p4s4s3z3z3z5z5z5z4m+24',
  '3m3m7m8m6p7p8p1s2s3s4s5s6s+9m+23',
  '1m2m3m5m6m7p7p3s4s5s3z3z3z7m+13',
  '2p2p2p5p6p7p8p8p2s3s6s6s6s+4s+13',
  '4m4m3p3p4p4p5p5p3s4s5s6s7s+5s+11',
  '1m1m3m4m5p6p7p2s3s4s7s8s9s5m+12',
  '2m2m4p5p6p7p8p9p1s2s3s5s6s7s+22',
  '2m3m4m5m5m3p3p5p6p7p3s4s5s5m+21',
  '3p4p4p5p5p9p9p3s4s5s7s8s9s6p+14',
  '3m4m5m6m6m2p3p4p7p8p5s6s7s+9p+14',
  '3p4p5p2s3s4s5s7s1z1z2z2z2z+6s+23',
  '2m2m8m8m8m2p3p4p3s4s5s6s7s+5s+23',
  '1m2m3m9m9m9m1p2p3p6p6p1s3s2s+21',
  '4m5m6m7m8m1p2p3p4p5p6p2s2s9m+13',
  '2m2m7m7m8m8m3p3s3s4s4s6s6s+3p+21',
  '2m3m4m6m6m7m8m9m5p6p7p3s4s5s+21',
  '2m3m4m6m7m8m6p7p8p4s4s7s8s+6s+13',
  '3m4m5m7m7m2p3p4p4s4s5s6s7s+4s+22',
  '4m5m6m7m8m2p3p4p7p7p4s5s6s+6m+22',
  '4m6m2p2p3p4p5p5p6p7p6s7s8s+5m+13',
  '3m4m5m7m8m9m3p4p5p7p8p9p9p+9p+21',
  '3m4m6m6m6m8m8m2s3s4s5s6s7s2m+12',
  '4m4m5m5m6m6m5p5p4s4s6s6s6s5p+22',
  '2m3m4m6m6m4p4p4p7p7p7p4s4s+4s+23',
  '2m3m2p3p3p4p4p5p1s1s4s5s6s+4m+11',
  '8m8m8m4p4p5s6s7s1z1z6z6z6z+4p+14',
  '2m3m4m6m7m7m8m9m3p4p5p5p5p+8m+22',
  '2m3m4m1p2p3p4p5p6p7p5z5z5z7p+11',
  '4m5m6m7m8m9m5p6p7s7s7s8s9s+7p+23',
  '1m2m3m5m5m7m8m6p7p8p6s7s8s6m+21',
  '4p4p5p5p9p9p8s8s9s9s2z7z7z+2z+12',
  '2m4m6m7m8m2p3p4p2s3s4s9s9s+3m+24',
  '2m2m4m4m5m5m6m6m5p6p2s3s4s7p+14',
  '1m1m2m3m4m2p3p4p5p6p7p3s4s+2s+12',
  '3m4m5m2p2p2p5p5p2s3s3s4s4s5s+11',
  '6m7m3p4p5p4s4s5s5s5s7s7s7s5m+11',
  '5m5m7m8m9m1p2p3p4p5p3s4s5s+3p+21',
  '3m4m5m1p2p3p7p8p9p9p3s4s5s6p+12',
  '2m2m6m7m8m6p7p6s7s8s1z1z1z+5p+11',
  '4m5m7m8m9m3p4p5p5s6s7s9s9s+3m+11',
  '1m2m3m2p3p4p6p6p4s5s6s7s8s+9s+12',
  '5m6m7m3p4p6p6p5s5s5s6s7s8s5p+11',
  '5m5m1p2p3p6p7p3s3s4s4s5s5s+5p+24',
  '4m4m1p2p3p4p5p6p7p8p9p4s5s+3s+13',
  '4m4m1p3p4p5p6p7p8p9p5s6s7s+2p+23',
  '6m6m4p5p6p3s3s4s4s5s5s6s7s+2s+22',
  '2p2p6p7p8p5s6s7s7s8s8s9s9s+4s+24',
  '4m4m5m6m1p2p3p5p6p7p3s4s5s+7m+12',
  '6m7m8m1p2p3p4p5p6p8p8p6s7s8s+24',
  '1m2m2m2m3m5m6m7m8m9m6p7p8p7m+13',
  '7m7m7m2p2p5p5p5p5p6p6s7s8s+4p+23',
  '2m3m4m6m7m2p3p4p1s1s6s7s8s8m+23',
  '7m8m9m4p5p7p8p9p5s5s7s8s9s+3p+13',
  '7m8m9m7p8p9p1s2s3s5s5s7s9s+8s+14',
  '3m4m5m2p2p2p3p4p5p3s4s6s6s5s+24',
  '6m6m7m7m2p3p4p5p6p7p6s7s8s+7m+24',
  '3p3p4p4p5p5s5s6s7s8s5z5z5z5p+24',
  '6m7m8m9m9m2p3p5p6p7p6s7s8s+4p+22',
  '5m5m6m6m7m4p5p6p4s5s6s9s9s+7m+21',
  '2m2m5m6m5p6p7p5s6s7s7s8s9s+4m+21',
  '9m9m1p2p3p4p5p6p7p8p9p3s3s+3s+21',
  '1m1m7m8m9m5p6p3s4s5s6s7s8s7p+24',
  '3m4m5m6m7m8m3p4p5p7p7p4s5s+6s+13',
  '2m3m5m6m7m6p7p8p9p9p7s8s9s+1m+14',
  '1m1m3m4m5m5m6m7m6p7p1s2s3s5p+13',
  '2m2m3m3m4m4m5m5m5s6s4z4z4z+4s+23',
  '5m5m7m8m2p3p4p7p8p9p4s5s6s9m+11',
  '5m6m7m5p6p7p4s5s6s2z2z6z6z6z+21',
  '4m5m6m7m8m9m3p4p7p8p9p1s1s5p+23',
  '3m4m5p5p6p7p8p1s2s3s5s6s7s2m+12',
  '2m2m8m8m1p1p4s4s9s9s5z6z6z5z+11',
  '1m1m3m3m3p3p5p1s1s4s4s6s6s5p+12',
  '4m4m5m6m3p4p5p7p8p9p3s4s5s+4m+11',
  '2m2m4m4m7m7m9m9m3p3p2s2s6z+6z+23',
  '7m8m9m1p1p5p5p5p7p9p7s8s9s8p+12',
  '1m2m3m5m5m5p6p6s7s8s6z6z6z+4p+21',
  '1m2m3m4m5m6m2s3s4s5s5s5s6s+1s+12',
  '3m4m5m6m7m8m5p6p9s9s7z7z7z4p+24',
  '3m4m5m1p2p3p5p6p8p8p4s5s6s+4p+11',
  '1m2m3m3p4p6p7p8p3s4s5s8s8s+2p+22',
  '6m7m8m4p4p5p5p6p7p7p5s6s7s3p+22',
  '2m2m2m4m4m5m5m6m6m7p7p7s8s9s+11',
  '3m4m6m7m8m3p4p5p1s2s3s7s7s2m+23',
  '2m2m6m7m8m4s4s4s6s7s7s7s7s5s+21',
  '2m3m4m6m7m8m2s3s4s4s5s9s9s+6s+11',
  '7m8m9m8p9p2s3s4s7s8s8s8s9s+7p+12',
  '3m3m2p3p4p5p6p1s2s3s5z5z5z+1p+14',
  '3m4m5m4p5p2s3s3s4s4s5s4z4z6p+12',
  '5m5m3p3p4p4p5p5p7p8p9p6s7s+5s+12',
  '2m2m8m8m3p3p5p6p6p2s2s5s5s+5p+22',
  '4m5m6m7m8m9m4p6p4s4s5z5z5z+5p+14',
  '4m5m6m7m8m9m7p8p6s7s8s3z3z+6p+14',
  '1s2s2s3s3s4s6s6s6s7s8s9s9s+6s+11',
  '2m2m2m5m5m6m6m7m7m5s5s5s7s+7s+24',
  '6m7m5s6s7s8s8s5z5z5z6z6z6z+8m+11',
  '3m3m2p2p4p4p6p6p9s9s4z4z7z+7z+13',
  '5m6m6m7m8m5p5p7p8p9p1z1z1z+7m+14',
  '3p4p5p5p6p7p1s2s3s5s5s7s8s+9s+12',
  '1m2m3m3m4m5m2s3s4s5s6s8s8s+7s+13',
  '7m8m9m1p1p4p5p6p5s5s6s6s7s4s+11',
  '2m3m4m7m7m4p5p2s2s3s3s4s4s3p+24',
  '3m3m4m4m5m5m1p2p3p2s2s2s4s+3s+23',
  '5m6m7m3p4p5p7p8p2s3s4s7s7s+9p+21',
  '2m3m4m1p2p3p4p5p6p7p8p3z3z+9p+12',
  '2m3m4m1p2p3p4p4p3s4s4s5s5s3s+13',
  '4m5m6m7p8p9p2s3s5s6s7s3z3z4s+12',
  '9m9m6p6p7p7p9p9p5s5s1z1z7z+7z+11',
  '3m4m5m8m8m2p3p3p4p4p5p2s4s3s+11',
  '7m8m9m2p2p1s2s3s3s4s7s8s9s+5s+11',
  '3m4m5m1p1p3p4p5p3s4s5s7s8s+6s+14',
  '3m4m2p3p3p4p4p5p7p7p4s4s4s+5m+12',
  '3m4m6m7m8m2p3p4p7p7p6s7s8s+2m+13',
  '3m3m8m8m6p6p3s3s4s4s6z6z7z+7z+13',
  '6m6m9m9m9m9p9p9p3s4s5z5z5z+2s+21',
  '4m4m5m6m6p7p8p2s3s4s6s7s8s+7m+22',
  '4m4m5p6p7p1s2s3s4s5s7s8s9s3s+11',
  '7m8m9m5p5p6p6p6p8s8s6z6z6z8s+12',
  '1m2m3m5s5s6s6s7s7s2z6z6z6z+2z+23',
  '3m3m4m4m5m2p3p4p5p5p5s6s7s5m+21',
  '3m4m8m8m1p2p3p4p5p6p4s5s6s+5m+23',
  '1m2m3m3m4m5m7m8m4s5s6s2z2z+9m+14',
  '1m2m3m4m5m6m2p3p3p3p4p4s5s+3s+12',
  '1m2m3m4m5m4p5p6p3s3s5s6s7s+3m+12',
  '7m8m2p3p4p6p7p8p4s4s6s7s8s6m+14',
  '3m4m5m3p4p5p3s4s4z4z4z7z7z+5s+14',
  '3m4m5m3p4p5p6p7p8p8p5s6s7s5p+21',
  '6m6m7m7m8m4p4p6p6p6p1s2s3s8m+22',
  '2m3m5m5m1s2s3s5s5s6s6s7s7s1m+13',
  '3m4m3p4p4p4p5p7p8p9p3s4s5s+5m+12',
  '2m2m2p3p4p6p7p2s3s4s7s8s9s5p+21',
  '4m5m7m8m9m1p1p6p7p8p2s3s4s+3m+13',
  '4p5p5p6p6p2s2s3s4s5s6s7s8s7p+12',
  '2m3m4m5m5m7m8m9m1s2s3s3s4s5s+22',
  '2m2m2m4m3p4p5p5p6p7p5s6s7s4m+11',
  '2m2m4m4m1p1p4p4p6s6s2z2z6z+6z+11',
  '4m4m4m6m6m7m7m8m6p6p4s5s6s8m+11',
  '3m4m5m8m8m6s7s8s2z2z6z6z6z+2z+21',
  '5m7m5p6p7p9p9p2s3s4s5s6s7s6m+24',
  '3m4m5m3p4p5p5p7p3s4s5s6s6s+6p+13',
  '3m3m7m8m9m4p4p5p5p6p6p7s8s6s+11',
  '2m2m5m6m7m1p2p3p6p7p8p4s5s3s+11',
  '4p5p5p6p7p2s3s4s4s5s6s7s7s6p+21',
  '2m2m7m7m8m8m2p2p4p4s4s5s5s+4p+21',
  '1m2m3m6m7m7p7p5s6s6s7s7s8s8m+11',
  '2m3m4m4p5p7p8p9p2s3s4s5s5s+3p+14',
  '5m5m6m6m6m3p4p5p6p8p4s5s6s7p+12',
  '3m4m5m7m8m9m7p8p9p4s5s8s8s3s+14',
  '3m3m3m4m4m4m2p3p4p5p3s4s5s+2p+23',
  '2m2m8m8m1p1p2s4s4s5s5s6s6s2s+11',
  '3p4p4p5p6p7p7p4s5s6s6s7s8s5p+13',
  '5m6m7m7m8m4p5p6p2s2s5s6s7s+6m+11',
  '5p5p1s1s5s5s6s6s2z2z3z7z7z+3z+11',
  '6m7m8m2p3p4p6p7p8p6s8s2z2z7s+24',
  '1m1m1p2p3p5p6p2s3s4s6s7s8s+4p+22',
  '4m5m7m8m9m4p5p6p7p8p8p8p9p+6m+13',
  '3m4m5m6m7m8m2p3p4p4s5s8s8s+3s+13',
  '2m3m4m3p4p5p5p6p9p9p1s2s3s+4p+11',
  '1m1m3m3m3m4m4m4m4p6p9p9p9p+5p+12',
  '3m4m5m9m1p2p3p4p4p5p5p6p6p+9m+22',
  '1p2p3p4p5p6p8p9p6s7s8s5z5z+7p+22',
  '1m2m3m6m7m8m5p6p1s1s7s8s9s4p+21',
  '2m2m2m4m5m4p5p6p4s4s5s6s7s+6m+22',
  '3m3m4m4m5m2p3p4p7p8p9p7s7s+2m+23',
  '6m7m8m3p4p6p7p8p9p9p6s7s8s5p+12',
  '4m5m6m4p4p5p6p6p2s2s4s4s4s5p+21',
  '1m2m3m7p8p9p3s4s5s5s5s1z1z+1z+21',
  '5m5m5m6m7m8m3p4p4p5p5p7p7p6p+11',
  '3m4m5m8p8p3s5s7s8s9s5z5z5z+4s+14',
  '1m2m3m3m4m2p3p4p2s3s4s6s6s5m+21',
  '2m2m4m5m6m7m8m3p4p5p6s7s8s+9m+24',
  '2m3m4m5m6m7m3s3s5s5s6s6s7s+7s+24',
  '5m5m1p2p3p4p5p6p1s2s3s5s6s4s+21',
  '3m4m5m7m8m8m8m9m3p4p5p5p6p+7p+13',
  '6m6m8m9m7p8p9p4s4s4s7s8s9s+7m+23',
  '4m4m5m5m5m3p4p5p6p7p3s4s5s2p+21',
  '3m3m6p7p8p2s2s2s3s4s5s6s6s+3m+13',
  '2m3m4m4m5m4p5p6p5s5s6s7s8s+3m+12',
  '2m3m3m3m4m6m7m8m2p3p4p5p7p+6p+23',
  '1m1m3m4m5m7m8m9m2p3p4p2s3s+1s+11',
  '2m3m6m6m2p3p4p6p7p8p6s7s8s+1m+21',
  '5m6m7m7m8m3p4p5p8p8p2s3s4s+9m+21',
  '4m5m5m5m6m6m7m8m5p6p6p7p8p4p+14',
  '3m4m5m6m6m1p2p2p3p4p7z7z7z3p+24',
  '4m4m5m6m7m2p3p4p5p7p4s5s6s+6p+24',
  '2m3m9m9m9m8p8p8p9p9p9p2s2s+1m+13',
  '3m4m5m7m8m9m3p4p5p3s3s7s8s9s+13',
  '3m3m3m5m3p4p4p5p5p6p4s5s6s+5m+11',
  '4m5m6m2p2p2p3p3p4p5p4s5s6s+1p+12',
  '3m4m5m4p5p6p6p7p8p2s2s6s7s+8s+12',
  '4m5m6m6m5p6p7p1s2s3s1z1z1z6m+13',
  '1m2m3m6m7m8m1p1p3p4p6p7p8p+2p+22',
  '5m6m6p6p1s2s3s4s5s6s7s8s9s+7m+11',
  '5m6m7m2p3p7p8p9p7s8s9s3z3z+4p+24',
  '4m5m6m6p7p8p3s4s4s5s5s8s8s+3s+13',
  '4m4m4m5m5m6p7p8p2s3s4s7s7s7s+11',
  '1m1m2m2m3m3m6m7m3s4s5s7s7s+8m+12',
  '4m4m5m6m3s4s5s5s6s7s7s8s9s+7m+21',
  '7m9m4p4p5p6p7p7p8p9p7s8s9s+8m+13',
  '6m8m3p3p2s2s3s3s4s4s5s6s7s+7m+11',
  '2m3m4m6m7m8m1p2p3p3s4s6s6s+2s+22',
  '3p4p5p6p7p8p9p9p4s5s6s7s8s+9s+22',
  '4m5m6m2p3p4p4p5p1s1s6s7s8s+3p+12',
  '3m4m5m6m7m6p7p8p4s4s6s7s8s2m+22',
  '2m3m4m5m6m7m1s1s1s2s3s7s8s9s+11',
  '3m3m2p3p4p2s2s2s4s5s6s7s8s+3s+14',
  '1m2m3m3m4m5m6m7m3p3p6s7s8s+8m+24',
  '3m4m5m3p4p5p6p7p1s1s5s6s7s+2p+11',
  '4m5m6m1s2s2s2s3s5s6s7s8s9s7s+21',
  '5m5m3p4p5p6p7p7p7p8p8p9p9p8p+24',
  '3m4m5m3p4p2s2s2s4s4s5z5z5z+2p+23',
  '4m5m7m8m9m3p3p7p8p9p4s5s6s3m+12',
  '1m1m4m5m7p8p9p7s8s9s1z1z1z3m+14',
  '1m1m2m3m4m5p6p7p7s8s1z1z1z6s+13',
  '3m4m6m7m8m1s2s3s4s5s5s5s6s5m+14',
  '1p3p4p5p6p7p8p9p2s3s4s6s6s+2p+12',
  '4m5m3p4p5p7p8p9p3s3s6s7s8s6m+23',
  '5p5p6p6p8p8p2s2s4s4s7s7s8s+8s+13',
  '5m5m2p2p4s4s7s7s9s4z4z7z7z+9s+13',
  '4m5m1p2p3p2s2s4s5s6s6s7s8s+3m+12',
  '1m1m3m4m5m4p5p4s5s6s7s8s9s+3p+21',
  '5m5m7m8m4p5p6p6p7p8p4s5s6s9m+13',
  '4m4m4p5p1s2s3s7s8s9s1z1z1z6p+11',
  '3m3m5m6m7m4p5p6p2s4s5s6s7s3s+12',
  '1m2m3m4m5m6m4p5p4s5s6s3z3z6p+12',
  '6m7m8m1p1p1s2s3s6s7s8s3z3z+3z+13',
  '5m5m2p3p4p5p6p7p1s2s3s7s8s+9s+24',
  '1p1p2p3p3p7p8p9p7s8s9s5z5z+2p+23',
  '1m2m3m5m6m7m1p1p2s3s5s6s7s+1s+12',
  '2m3m4m6m7m8m5p6p6s7s8s4z4z4p+21',
  '5m5m2p2p7p7p8p8p1s1s4s4s7s+7s+22',
  '6m7m8m3p4p5p6p7p7p8p8p4s4s9p+13',
  '2m3m3m4m4m5m3s4s5s9s9s1z1z+1z+21',
  '4m5m6m2p3p4p5p5p7p8p5z5z5z+6p+14',
  '1m2m3m5m6m2p3p4p6p7p8p6s6s+4m+11',
  '1m2m3m5m6m7m4p5p6p3s4s7s7s+2s+24',
  '1m2m3m5m6m7m4p5p6p2s2s3s4s2s+13',
  '4m5m6m1s2s3s4s5s6s7s8s9s9s6s+23',
  '3m3m2p3p4p5p6p7p5s6s7s2z2z2z+12',
  '1m2m3m7m8m4p5p6p5s6s7s8s8s+9m+12',
  '3m4m5m2p3p5p6p6p6p7p5s6s7s4p+12',
  '7m7m8m8m9m9m6p7p8p6s7s3z3z+5s+23',
  '3m3m4m4m9m9m3p5p5p4s4s1z1z+3p+22',
  '2m3m4m5m6m2p3p4p7p8p9p5s5s+7m+13',
  '2m3m4m5m6m4p5p6p3s4s4s4s5s7m+12',
  '5m5m1p2p3p4p5p6p7p8p5s6s7s3p+23',
  '4m5m5m6m6m7m5p7p5s5s7z7z7z+6p+14',
  '4m4m7m8m9m6p6p7p8p9p7s8s9s+4m+24',
  '2m3m4m1p2p3p5p5p6p7p8p2s3s+4s+12',
  '5m7m2p3p4p6p6p2s3s3s4s4s5s6m+14',
  '6m7m8m1p1p2p3p4p6p7p7s8s9s5p+13',
  '5p6p6p7p7p8p8p8p2s3s4s6z6z+6z+11',
  '1m3m6m7m8m4p4p5p5p6p6p4z4z+2m+13',
  '5m6m7m7m8m9m3p5s5s5s5z5z5z+3p+21',
  '1m2m3m1p2p3p4p5p6p1s3s4z4z+2s+11',
  '5m6m7m3p4p5p5p6p7p4s4s6s7s5s+24',
  '2m3m4m5m6m7m4p5p5s6s7s8s8s3p+11',
  '2m3m3m4m5m5p5p6p7p8p4s5s6s4m+12',
  '3m3m5m6m7m2p3p4p6p7p8p2s3s+1s+12',
  '4m5m6m2p3p5p5p5p6p7p2s3s4s4p+13',
  '5m6m7m2p3p6p7p8p4s5s6s8s8s4p+21',
  '1m2m3m2p3p4p2s2s3s3s4s6s6s1s+13',
  '1m2m3m1p2p2p3p3p4p5s6s2z2z7s+14',
  '2m3m3p4p5p6p6p4s5s5s6s6s7s+1m+13',
  '2m2m4m5m6m4p5p2s3s4s5s6s7s+3p+21',
  '1m2m3m6m7m3p4p5p7s8s9s2z2z+8m+14',
  '4m5m3p4p5p7p8p9p9p9p2s3s4s+3m+24',
  '2m2m7m8m9m2p3p3p4p4p4s5s6s+2p+12',
  '1m2m3m3p4p7p7p1s2s3s7s8s9s+2p+11',
  '6m7m8m3p3p6p7p8p6s7s7s8s9s+5s+11',
  '3m4m5m6m7m9p9p6s6s7s7s8s8s+2m+21',
  '3m4m5m2p2p2p5p5p5p6p5s6s7s+4p+11',
  '4m5m6m3p4p4s4s4s9s9s3z3z3z5p+23',
  '3m4m5m6p6p3s4s4s5s5s6s7s8s3s+13',
  '5m6m7m8m3p4p5p5s6s6s7s7s8s+5m+23',
  '1m2m2m3m3m4m1p2p3p1s2s3s4z+4z+12',
  '3m4m5m9m9m3p5p7p8p9p3s4s5s+4p+22',
  '2m3m5m6m7m3p4p5p7p7p6s7s8s4m+21',
  '5m6m7m7p8p3s3s4s5s5s6s6s7s+9p+21',
  '3m4m5m5p6p7p4s4s6s7s7s8s8s9s+14',
  '2p3p4p5p6p3s4s5s3z3z7z7z7z1p+12',
  '2m3m4m4m5m6m6m6m5s6s7s6z6z+6z+21',
  '3m3m7m8m9m2p3p4p2s3s5s6s7s4s+14',
  '7m7m7m5p5p5p8p8p8p9p9p8s9s7s+14',
  '4m5m5m6m7m7m8m9m4s4s5s6s7s+6m+23',
  '2m3m3m4m4m5m6m7m8m6p7p6s6s8p+11',
  '7m8m9m3p4p5p7p9p7s7s7z7z7z8p+14',
  '2m3m4m5m6m7m6p6p2s3s4s5s6s4s+24',
  '3m3m7m2p2p3p3p4p4p5p5p5s5s+7m+12',
  '2m3m4m2p3p4p5p6p7p1s1s7s8s+9s+14',
  '4m4m5m6m7m4p5p6p8p8p3s4s5s+4m+13',
  '1m2m3m4m5m6m6p7p9p9p7s8s9s8p+23',
  '1m2m2m3m3m7m7m1p2p3p3s4s5s+1m+12',
  '2m3m4m7m7m1p2p3p3p4p5p3s4s5s+22',
  '6m6m8m8m5p5p6p6p4s4s9s3z3z9s+13',
  '3p4p5p6p7p3s4s4s5s5s6s7s7s+2p+12',
  '4m5m7m8m9m4p5p6p8p8p2s3s4s+3m+21',
  '1m2m3m5m6m1p2p3p1s2s3s3s3s7m+21',
  '4m4m4m7m7m6p7p3s4s5s6s7s8s5p+13',
  '3m4m5m6m7m8m1z1z4z4z4z5z5z5z+22',
  '4m5m6m7m8m9m6p7p8p6z6z7z7z6z+11',
  '2m3m1p2p3p7s7s8s8s9s9s5z5z1m+13',
  '2m3m4m4m5m6m4p4p7p8p5s6s7s+9p+22',
  '4m5m5m5m6m7m8m9m4s5s6s6s7s+8s+13',
  '4m4m5m6m4p5p6p2s3s4s5s6s7s+4m+11',
  '3m4m5m7m7m4p5p6p3s4s5s7s8s+9s+12',
  '4m5m6m6m7m7m7m8m2p3p8p8p8p4p+11',
  '3m4m5m7p8p1s1s3s4s5s6s7s8s6p+23',
  '2m2m7m9m7p8p9p3s4s5s7s8s9s+8m+12',
  '7m8m4p5p6p7p8p9p2s2s4s5s6s6m+14',
  '3m4m5m2p3p4p4p5p6p7p7p5s6s4s+12',
  '4m5m5p6p7p6s7s8s3z3z3z6z6z+3m+23',
  '7m8m2p2p6p7p8p2s2s2s3s3s3s+6m+12',
  '1m1m6m7m8m9p9p8s8s8s6z6z6z+1m+21',
  '6m7m8m9m9m3p4p3s4s5s6s7s8s5p+11',
  '1p2p3p9p1s2s3s7s8s9s5z5z5z+9p+11',
  '2m2m8m8m8m5p7p8p8p8p5s6s7s6p+21',
  '4m5m6m8m8m2p3p4p5p6p7z7z7z4p+13',
  '6m7m9m9m3p4p5p6p7p8p6z6z6z+5m+12',
  '2m3m4m6m7m7m7m1p2p3p5s6s7s5m+11',
  '2m2m5m5m6m6m3p3p4p4p3z3z6z6z+22',
  '4m5m6m6m7m1p1p1p2p3p5s6s7s+5m+11',
  '4m4m5m5m6m6m2p2p7p8p9p4s6s+5s+12',
  '3m3m4m5m6m2p3p4p6p6p3s4s5s+6p+14',
  '5m5m7p8p9p1s2s2s3s3s4s5s6s4s+11',
  '1m2m3m3p3p4p5p6p2s3s4s6s7s5s+21',
  '1m2m3m7m8m9m2s2s3s4s5s5z5z+5z+11',
  '3m4m5m5p5p6s7s8s2z2z2z5z5z+5z+12',
  '4m4m5p6p7p3s4s5s6z6z7z7z7z4m+14',
  '2m7m7m8m8m3p3p2s2s3s3s7s7s+2m+12',
  '5m6m7m8m8m2p3p4p6p7p8p5s6s+7s+14',
  '3m4m4m5m6m6m7m8m4p4p5s6s7s+5m+24',
  '2p3p4p6p7p7p8p9p2z2z6z6z6z8p+13',
  '4m5m6m3p4p4p4p6p7p8p4s5s6s2p+11',
  '3m4m6m7m8m5p6p7p1s1s3s4s5s5m+21',
  '7m7m1p1p1p4p4p5p5p6p4z4z4z+6p+24',
  '2m3m4m3p4p5p5p5p2s3s4s4s5s6s+24',
  '3m3m4m5m4p5p6p3s4s5s5s6s7s3m+21',
  '1m6m6m2p2p4p4p2s2s5s5s2z2z+1m+22',
  '3m3m1p2p3p6p7p1s2s3s7s8s9s+8p+13',
  '1m3m4m4m1p2p3p7p8p9p1s2s3s+2m+14',
  '3m3m4m5m6m5p6p6p7p7p8p6s7s+5s+22',
  '8m8m3p4p5p5p6p7p1s2s3s6s7s8s+24',
  '2m3m4m4m4p5p6p1s2s3s7s8s9s+1m+21',
  '4m5m7m8m9m4p4p6p7p8p2s3s4s+6m+12',
  '7m8m9m3p3p6p7p8p2s3s4s7s8s+9s+11',
  '4m5m3p3p4p5p6p4s5s6s7z7z7z+3m+13',
  '3m4m5m4p5p6p1s1s4s5s6s3z3z+3z+23',
  '3m4m5m6m7m8m1s2s3s3s4s1z1z+5s+22',
  '1p2p3p4p5p5p6p6p1z1z2z2z2z4p+22',
  '3m4m5m6m7m8m6p7p3s3s4s5s6s+5p+12',
  '7m8m9m1p2p3p9p9p3s4s5s7s8s6s+21',
  '3m4m5m5p5p6p6p7p7p2s2s3s5s4s+13',
  '3m3m4m5m8m8m8m6p7p8p7s7s7s6m+13',
  '6m7m8m2p3p8p8p2s3s4s6z6z6z4p+14',
  '6m6m7m8m9m2p3p4p6p7p4s5s6s+5p+23',
  '1m2m3m3p4p6p7p8p5s6s7s8s8s2p+12',
  '4m4m7m8m3p4p5p2s3s3s4s4s5s9m+22',
  '1m1m4m5m6m4p5p6p3s4s4s5s6s5s+22',
  '3m4m5m6p7p7p8p9p5s6s7s2z2z+5p+14',
  '4m5m5p5p6p7p8p4s4s5s5s6s6s3m+11',
  '2m2m5m5m6m6m6m4p5p6p4s5s6s5m+14',
  '4m5m7m8m9m2p2p1s2s3s5s6s7s6m+12',
  '8m8m3p3p4p4p5p5p6p6p5s5s5s+8m+22',
  '2m3m4m5m6m3p4p5p7p7p3s4s5s+1m+23',
  '1m2m3m4m5m6m7m8m2s2s2s2z2z+9m+14',
  '2s2s3s3s5s5s4z4z5z5z6z6z7z+7z+22',
  '6m7m8m9m9m2s3s4s5s6s7s8s9s1s+21',
  '3p4p5p6p6p6p7s7s2z2z2z7z7z+7z+22',
  '7m8m9m4p4p2s2s2s6s7s1z1z1z5s+13',
  '2m6m6m1p1p3s3s4s4s5s5s6s6s2m+12',
  '1m2m3m5m6m7m5p6p7p8p8p3s4s2s+21',
  '1m2m3m7m8m3p4p5p4s4s6s7s8s9m+13',
  '1m2m3m4p4p2s3s3s4s4s6z6z6z+2s+22',
  '2m3m4m4m5m6m2p2p2p3p3p5p5p+3p+21',
  '2m3m4m8m8m3p4p5p6p8p5s6s7s+7p+21',
  '4m4m6m7m7m8m8m9m7p8p9p6s7s5s+12',
  '5m5m9m9m9p9p6s6s1z1z4z7z7z+4z+11',
  '5m5m6m6m7p7p8p4z4z5z5z7z7z+8p+22',
  '7m7m9m9m9m4p5p7p7p7p4s4s4s+3p+23',
  '2m3m4m6m7m1p1p6p7p8p7s8s9s+8m+24',
  '2m3m4m4p5p1s1s3s4s5s6s7s8s+6p+14',
  '2m3m5m6m7m3p4p5p7p8p9p9s9s4m+11',
  '6m7m8m3p4p5p5p6p7p3s4s6s6s5s+11',
  '4m4m7m8m9m5p6p1s2s3s3s4s5s7p+21',
  '2m3m3p3p4p5p5p6p6p7p7s8s9s+4m+12',
  '2m3m1p2p3p7p8p9p5s6s7s9s9s4m+21',
  '4m5m6m6m8m4p5p6p4s5s6s2z2z+7m+21',
  '7m8m9m2p2p3p3p4p7p8p9p5s5s+4p+13',
  '4m5m6m7m8m3p4p5p1s1s3s4s5s+9m+11',
  '1m2m4m5m6m7m8m9m2p2p6p6p6p3m+22',
  '2m3m5m6m7m1p2p3p1s2s3s1z1z+1m+12',
  '1m1m2m3m4m3p4p5p5s5s6s6s7s+7s+13',
  '2m2m5m6m5p5p5p7p8p9p6z6z6z+7m+13',
  '3m4m5m7m8m9m4p5p6p2s3s7s7s+1s+11',
  '1m1m4p5p6p5s5s7s7s8s8s9s9s+1m+14',
  '5m5m7m6p6p7p7p5s5s1z1z7z7z+7m+24',
  '2m2m4m5m6m7m7m8m8m9m3p4p5p+6m+14',
  '2m2m6m7m8m3p4p5p5s6s7s7s8s6s+22',
  '1m2m3m5m5m1p2p3p7p8p9p5z5z5z+14',
  '1m2m3m5m5m6m7m8m4p5p5s6s7s+6p+21',
  '5m5m7m7m3p3p4p4p7s7s9s9s2z+2z+14',
  '3p3p5p6p6p7p7p7p8p8p9p7z7z+3p+12',
  '3m3m3m7m8m2p2p3p3p4p4p9s9s9m+22',
  '4m4m6m7m8m2p3p4p5p6p7p7s8s6s+11',
  '1m1m1p2p3p1s2s3s3s4s7s8s9s2s+11',
  '2m2m2m3m3m3m4m5m6m4p4p2s3s+4s+22',
  '1p2p3p5p6p7p7p3s4s5s6z6z6z7p+13',
  '4m4m3p4p4p5p5p6p3s4s5s6s7s+8s+22',
  '8m8m1p2p3p4p5p2s3s4s6s7s8s3p+21',
  '1m1m2p3p3p4p5p1s2s3s6s7s8s1p+12',
  '3m4m4m5m5m6m4p4p4s5s7s8s9s6s+11',
  '6m6m6p7p7p8p8p9p3s4s7s8s9s+2s+22',
  '5m5m2p3p4p7p8p9p1s2s5z5z5z3s+21',
  '4m4m5m6m7m5p7p2s3s4s5s6s7s+6p+21',
  '3m3m4m5m6m6m7m2s3s3s4s4s5s8m+14',
  '3m4m4m5m5m6m1s2s3s4s4s7s8s+9s+22',
  '3p3p3p4p5p6p2s4s6s7s7s7s8s+3s+11',
  '7m7m7p8p9p1s2s3s4s5s6s7s8s+6s+22',
  '3m4m5m9p9p3s4s5s6s7s7s8s9s2s+22',
  '2m3m3m4m4m6m7m8m1p2p3p6p6p5m+13',
  '3m4m5m9m9m5p6p7p2s3s6s7s8s+1s+13',
  '1s1s3s4s4s5s5s6s7s8s6z6z6z9s+11',
  '7m8m9m1p2p3p4p5p7p8p9p6s6s+6p+11',
  '3m4m5m7m7m6p7p3s4s5s5s6s7s+5p+11',
  '2m3m4m1p2p3p6p7p3s3s4s5s6s8p+22',
  '5m6m7m3p4p5p2s3s4s6s7s1z1z+5s+24',
  '3m4m5m7m7m6p7p8p1s2s3s7s8s6s+13',
  '3m3m8m3p3p5p5p4s4s3z3z6z6z8m+24',
  '2m3m4m5m6m4p5p6p1s2s3s4s4s4m+21',
  '4p4p5p5p6p6p7p7p1s2s3s5s6s+7s+22',
  '2m2m8m8m5p5p7p7p9p9p2s2s7z+7z+21',
  '4m5m6m5p6p7p7p8p2s3s4s7s7s9p+14',
  '5m5m2p2p3p3p4p4p9p9p9p6s7s+8s+22',
  '1m1m2m3m3m4m5m6m7m8m9m3p3p+2m+23',
  '2m3m4m5m6m7m2p3p5p5p6s7s8s+1p+23',
  '2m3m4m5m6m8m8m3s4s5s6s7s8s+1m+23',
  '3m3m1p1p2p2p4s4s9s3z3z4z4z9s+22',
  '2m3m2p3p4p4p5p6p6s7s8s9s9s4m+22',
  '4m5m6m9m9m2p3p4p5p6p7s8s9s4p+23',
  '3m4m5m2p2p6p7p2s3s4s5s6s7s+8p+23',
  '1m1m4m4m7m7m3s4s4s5s5s3z3z+3s+11',
  '6m7m8m6p7p1s1s3s4s5s6s7s8s8p+23',
  '2m2m6m6m7m7m4s4s5s7s7s9s9s5s+21',
  '2p4p3s3s5s5s5s7s8s9s6z6z6z3p+13',
  '1m2m3m3p3p5p6p7p7p8p9p2s3s1s+13',
  '2m3m2p3p4p5p5p6p6p7p7p3z3z+4m+14',
  '4m5m5m6m6m4p5p6p6s7s8s4z4z7m+21',
  '2m3m4m5m6m1p2p3p1s1s3s4s5s+1m+21',
  '6m7m2p2p3p4p5p2s3s4s6s7s8s8m+22',
  '4m5m6m5p6p7p7p7p3s4s5s7s8s+9s+21',
  '5m6m7m6p7p8p8p5s6s6s7s7s8s8p+14',
  '3m4m5m5m6m7m3p4p5p6p7p8p8s8s+22',
  '4m4m6m7m8m2p3p4p1s2s3s4s5s+3s+23',
  '2m3m4m6m7m3p4p5p2s3s4s7s7s+8m+23',
  '1m1m4m5m6m4p6p4s4s4s6z6z6z5p+13',
  '5m6m6m6m7m1p2p3p6p7p3s4s5s+8p+21',
  '6m7m8m5p6p6p7p7p8p5s5s5s7s+6s+14',
  '3m4m5p6p7p1s2s3s5s5s7s8s9s2m+21',
  '6m6m5p6p6p7p7p8p2s3s4s6s8s+7s+13',
  '5m6m7m4p5p3s4s5s7s8s8s8s9s3p+22',
  '4m5m6m7p7p8p8p9p9p6s7s8s2z+2z+11',
  '4m5m6m6m7m8m5p5p3s4s5s7s7s5p+13',
  '1m1m2m3m4m6m7m8m5p6p1s2s3s4p+22',
  '5m6m7m3p5p6p6p6p5s6s7s7s7s4p+24',
  '2m3m5m6m7m2p3p4p8p8p5s6s7s+1m+23',
  '5m5m6m7m2p3p4p6s7s7s8s8s9s+5m+14',
  '3m4m5m8m8m5p6p2s3s4s5s6s7s+4p+11',
  '5m6m7m9m9m7p8p9p4s5s7s8s9s6s+11',
  '1m2m3m4m4m4m1s1s7s9s7z7z7z8s+14',
  '1m2m3m5m6m7m1p1p2p3p4p4s5s+3s+24',
  '1m2m3m7m8m3p3p7p8p9p5s6s7s6m+23',
  '5m6m6m7m8m4p5p5p6p6p7p8s8s4m+24',
  '6m7m8m1p2p3p6p6p6s8s3z3z3z+7s+23',
  '3m4m5m6m6m7m7m3s4s5s5s6s7s+7m+14',
  '1p2p3p4p5p6p7p9p4s5s6s2z2z+8p+23',
  '4m5m6m6m7m2p3p4p5s5s6s7s8s5m+14',
  '3m4m5m5m6m7m7m8m4p5p6p1s1s+9m+12',
  '4m5m6m3p4p5p6p7p7p7p8p5s6s4s+14',
  '3m4m5m7m8m9m3s3s4s5s5s9s9s+4s+14',
  '4m4m1p2p3p4p5p6p7s8s9s5z5z+5z+21',
  '1m2m4m5m6m6m6m1p1p1p3z3z3z3m+13',
  '2m3m4m7p7p1s2s3s5s6s7s6z6z6z+14',
  '6m7m7m8m8m9m2p3p6p7p8p8s8s4p+12',
  '6m7m8m7p7p8p8p9p9p1s1s8s9s+7s+22',
  '3m4m5m6m7m3p4p5p5s5s7s8s9s+5m+22',
  '1m2m3m2p2p3p4p5p4s5s5s6s7s6s+13',
  '4m5m6m1p2p3p3p3p4p5p6s7s8s6p+24',
  '5m5m4p5p1s2s2s3s3s4s6s7s8s+6p+12',
  '5m5m7m8m8m1p1p5s5s7s7s4z4z7m+11',
  '1m2m3m5m6m7m1p2p3p2s3s3s3s+1s+12',
  '2m3m4m4m5m2p3p3p3p4p5p6p7p+3m+11',
  '3m4m5m8m8m3p4p5p6p7p5s6s7s+2p+12',
  '1m1m2m3m3p4p5p3s4s5s6s7s8s1m+23',
  '2m2m2m5m6m7m3p3p6p8p3s4s5s7p+12',
  '6m7m8m4p4p4p6p8p5s5s6s7s8s7p+21',
  '1m2m4m5m6m7m8m9m9p9p3s4s5s+3m+13',
  '3m4m6m7m8m3p4p5p5p6p7p1s1s5m+14',
  '1m2m3m1p2p3p4p5p6p5s6s8s8s4s+23',
  '2m2m5m6m2p3p4p5p6p7p7s7s7s7m+23',
  '2m2m6m6m5s5s6s6s7s7s6z7z7z6z+34',
  '3m4m5m3p4p4p5p5p6p3s3s4s6s+5s+22',
  '4m5m9m9m1s2s3s7s8s9s7z7z7z6m+23',
  '1m1m6m6m2p2p1s1s5s5s9s4z4z9s+22',
  '1m4m4m8m8m4p4p6s6s1z1z2z2z+1m+13',
  '2m2m3m4m5m7m8m7p8p9p3s4s5s+9m+13',
  '2m3m3m4m4m5m6m8m5p5p3s4s5s+7m+14',
  '2m3m4m5m6m5p6p7p9p9p5s6s7s1m+14',
  '5m6m7m7m8m2p3p4p1s2s3s5s5s+6m+13',
  '1m2m3m5p6p7p2s3s5s6s7s9s9s4s+21',
  '3m4m6p6p1s2s3s5s6s7s5z5z5z+5m+22',
  '1m2m3m1p1p1p3p7p8p9p1s2s3s+2p+13',
  '1m2m3m5m6m6p7p8p3s3s5s6s7s4m+22',
  '2m2m3p3p4p4p5p2s2s2s3s4s5s2p+21',
  '1m1m6m7m8m1p2p2p3p3p4p6z6z+6z+11',
  '2m3m4m1p1p1p3p4p5p1z1z6z6z+6z+11',
  '5m6m7m2p3p9p9p1s2s3s4s5s6s+1p+11',
  '6m6m1p1p2p2p5p5p8p8p9p7s7s+9p+14',
  '4m5m6m3p4p4p5p5p7p7p5s6s7s+6p+13',
  '4m5m6m6m6m3p4p5p4s5s6s7s8s+3s+13',
  '2m2m7m7m8m8m9m9m4p4p2s3z3z+2s+14',
  '2m3m4m1p2p3p4p5p6p3s3s5s6s+4s+24',
  '6m6m7m7m8m8m6p7p4s5s6s7s7s+8p+21',
  '6m7m4p4p4p6p6p6p5s6s7s8s8s5m+13',
  '4m4m3p4p6p7p8p2s2s2s6s7s8s+2p+23',
  '2m4m4m2p2p3s3s5s5s6s6s7z7z+2m+21',
  '9m9m2p3p3p4p4p7p8p9p7s8s9s+2p+14',
  '4m5m6m6m7m8m2p3p4p7p8p8s8s+9p+14',
  '4m5m6m8m8m3p4p5p5p6p7p3s5s+4s+14',
  '4m5m6m1s2s3s7s9s6z6z7z7z7z+8s+22',
  '1m2m3m5m6m7m6p7p1s2s3s8s8s+8p+13',
  '4m5m6m7m8m2p3p4p6p7p8p5s5s+9m+23',
  '3m3m6m7m5p6p7p7p8p9p6s7s8s+8m+13',
  '5m5m2p2p3p3p9p9p4s4s5s9s9s+5s+22',
  '1m2m2m3m3m4m4m5m6m9p9p6s7s5s+12',
  '4m5m2p2p2s3s4s4s5s6s8s8s8s3m+21',
  '2m2m4m5m6m1p2p2p3p3p4p5z5z+5z+22',
  '7m7m8m8m8m2p3p4p7p7p5s6s7s+7m+12',
  '3m4m5m5m6m7m4p5p6s6s7s8s9s+6p+22',
  '2m3m4m5m5m5m6p7p8p5s6s7s8s+8s+14',
  '5m6m7m8m3s4s5s6s6s6s8s8s8s5m+12',
  '2m2m2m4m4m6m7m8m4p5p6p6s7s+5s+22',
  '2p2p4p5p6p1s2s2s3s3s5s6s7s4s+24',
  '7m7m8m8m4s4s7s7s9s2z2z3z3z9s+23',
  '3m3m5p6p7p2s3s4s4s5s5s6s7s3s+14',
  '6m7m5p5p1s2s3s4s5s6s7s8s9s5m+21',
  '3m4m5p6p7p8p8p1s2s3s7s8s9s+2m+22',
  '3m5m6m7m8m2p2p5s5s5s7s7s7s4m+12',
  '5m6m7m4p4p6p8p6s7s8s7z7z7z+7p+11',
  '2m3m4m4m5m1p2p3p7p7p1s2s3s+6m+23',
  '4m5m6m7m8m9m6p6p6s7s7s8s9s5s+12',
  '2m2m4m5m6m6p7p1s2s3s5s6s7s5p+13',
  '3m4m5m7m8m9m5p6p6s6s7s8s9s7p+11',
  '5m6m7m1p1p3p4p5p5p6p7p5s6s4s+14',
  '4m5m6m2p2p5p6p7p3s4s5s5s7s+6s+12',
  '3m4m2p3p4p7p8p9p2s3s4s4z4z+2m+14',
  '7p8p1s1s2s2s3s3s5s5s7s8s9s+9p+31',
  '6m6m8m8m3p3p4p8p8p4s4s5s5s+4p+14',
  '4m5m3p3p4p5p6p5s6s7s2z2z2z3m+12',
  '6m7m8m4p5p6p2s2s2s3s3s4s4s+3s+12',
  '2m3m4m6m6m3p4p5p5p6p7p7s8s6s+12',
  '2m3m4m5m6m6m7m7m8m4p4p6s8s+7s+12',
  '4m5m3p3p4p5p6p2s3s4s6s7s8s+6m+12',
  '3m3m5m6m3p4p5p4s5s6s6s7s8s4m+21',
  '4m5m6m7m8m9m5p5p3s4s5s5s6s+7s+11',
  '5m5m3p4p5p5p6p7p2s3s4s6s7s8s+21',
  '2m2m4m5m6m3p3p8p8p8p2s3s4s2m+12',
  '1m2m3m9m9m9m1p1p7p8p9p7s8s+9s+22',
  '3m3m4m4m5m5m7m7m2s3s4s7s9s8s+11',
  '3m3m6m7m8m2p3p3s4s5s5s6s7s+1p+23',
  '5m6m7m5p7p4s4s5s5s6s6s8s8s6p+14',
  '1m2m3m3m3m6p7p8p5s6s7z7z7z7s+14',
  '5m6m1p2p3p4p4p5p6p7p6s7s8s7m+14',
  '1m2m3m4m5m6m3p3p4p4p5p8p8p5p+22',
  '1m2m3m6m7m9m9m4p5p6p6s7s8s8m+21',
  '1m1m4m5m5m6m6m7m4p5p6s7s8s+3p+12',
  '4m5m6m8m8m5p6p6p7p8p2s3s4s7p+14',
  '4m5m6m1p3p3p4p4p5p5p2s3s4s+1p+12',
  '3p3p3p5p2s2s3s3s4s4s6s7s8s4p+12',
  '2m3m4m8m8m4p5p6p4s5s6s7s8s6s+21',
  '4m5m6m4p4p5p5p6p6p8p8p3s4s2s+23',
  '9m9m1p2p3p6p7p8p3s4s7s8s9s+5s+12',
  '7m8m5p6p7p1s1s7s8s9s6z6z6z+9m+14',
  '7m7m8m9m9m4p4p5s5s7s7s5z5z+8m+11',
  '5m6m7m1p2p3p3p3p5s7s4z4z4z6s+24',
  '3m3m7m8m3p4p5p5p6p6p7p7p8p6m+14',
  '1m2m3m5m6m4p5p6p5s6s7s1z1z4m+23',
  '9m9m9m2p2p2p5p5p6p6p6p8s8s+5p+21',
  '2m3m3m4m4m5m2p2p3p4p5p3s5s4s+24',
  '1m2m3m1p2p3p4p4p2s3s7s8s9s+4s+12',
  '4m5m6m1p2p3p3s4s6s6s5z5z5z+2s+21',
  '2m3m5m6m7m5p5p3s4s5s6s7s8s4m+14',
  '2m3m4m4m6m3p4p4p4p5p6p7p8p5m+14',
  '5m6m7m4p4p4p5p6p7p8p4s5s6s+3p+21',
  '1m2m3m2p2p4p5p6p3s4s7s8s9s2s+11',
  '2m3m4m7m8m9m7p7p4s5s6s7s8s+6s+21',
  '5m6m7m7m8m1p1p2p3p4p1s2s3s+6m+24',
  '6m7m8m2p3p4p5p6p9p9p2s3s4s+7p+22',
  '2m3m4m5m6m7m4p5p5p6p7p4z4z6p+22',
  '2m2m7m8m9m3p4p5p5p6p6s7s8s+4p+23',
  '3m4m5m2p3p5p6p7p8p8p4s5s6s+1p+12',
  '5m6m7m8m9m2p3p4p3s3s5s6s7s+4m+13',
  '1m9m1p9p1s9s1z3z4z4z5z6z7z+2z+12',
  '3m4m6m7m8m3p4p4p4p5p7s8s9s+2m+22',
  '2m4m7m7m4p5p6p7p7p7p4s5s6s+3m+23',
  '3m3m9m9m6p6p9p9p6s1z1z7z7z6s+11',
  '6m7m6p7p8p2s3s4s5s5s6s7s8s8m+22',
  '7m8m9m3p4p5p1s1s2s3s3s9s9s+2s+14',
  '1m2m3m3m4m3p4p5p6s6s1z1z1z5m+11',
  '1m2m3m5m6m7m9m9m4p5p7s8s9s+6p+21',
  '5m5m8m8m3p3p4p1s1s6s6s2z2z4p+11',
  '4m5m6m1p1p2p2p3p3p6p7p5s5s+8p+12',
  '3m4m5m6p7p1s1s1s2s3s7s8s9s8p+24',
  '1m2m3m6m6m7m8m9m1p3p1s2s3s2p+21',
  '4m4m7m8m1s2s3s3s4s5s6s7s8s+6m+14',
  '1m1m4p4p5p5p6p1s2s3s7s8s9s6p+14',
  '4m4m2p3p6p7p8p3s4s5s3z3z3z+1p+13',
  '2m2m4m5m6m3p4p5p6p7p2s3s4s+5p+24',
  '3m3m5m6m6m7m8m6p7p8p5s6s7s+4m+21',
  '1m1m4m5m6m4p6p1s2s3s4s5s6s+5p+14',
  '3m4m4m4m5m2p4p5s5s6s6s7s7s3p+12',
  '4m5m7p7p1s1s1s4s4s5s5s6s6s3m+12',
  '2m2m3m4m5m3p4p3s4s5s5s6s7s+5p+11',
  '5m6m7m8m9m4p5p6p7p7p4s5s6s4m+23',
  '2m3m4m3p3p4p5p1s2s3s4s5s6s3p+22',
  '2m3m4m3p4p4p5p6p7p7p1s2s3s2p+12',
  '1m2m2m3m3m4m9p9p3s4s5s7s8s+9s+23',
  '4m5m6m6p8p3s3s3s6z6z7z7z7z7p+24',
  '2m3m4m4m5m6m2p2p5p6p7p5s5s+2p+14',
  '4p4p5p5p7p7p5s5s8s8s9s7z7z+9s+23',
  '3m4m5m7m7m7m8m9m5p6p7p2s3s+1s+14',
  '6m6m6m3p4p5p5s7s2z2z2z6z6z+6s+21',
  '1m2m3m4m5m2p3p4p5s6s7s2z2z3m+13',
  '3m4m5m3p4p5p7p8p9p3s4s7s7s+5s+14',
  '3m4m5m3p4p5p7p7p2s3s4s4z4z+4z+14',
  '5m6m7m3p4p5p6p7p1s2s3s5s5s+2p+11',
  '1m2m3m4m5m6m1p2p1s2s3s2z2z+3p+14',
  '2m3m4m6m7m1p2p3p6p7p8p3s3s8m+12',
  '1m1m3m4m5m6m7m8m1p2p3p2s3s+1s+23',
  '4m4m5m6m7m4p5p6p7p8p7s8s9s9p+24',
  '2m2m3m3m4m4m8m3p4p5p7p8p9p8m+23',
  '2m2m3m3m7p7p6s6s8s2z2z7z7z+8s+13',
  '4m5m6m1p2p3p4p5p2s2s7s8s9s+6p+21',
  '5m5m7m8m1s2s3s5s6s6s7s7s8s6m+14',
  '4p4p5p5p6p7p7p7p3s4s5s5z5z6p+23',
  '4m5m6m4p5p6p4s5s6s7s7s7z7z+7s+13',
  '2m3m4m4p5p6p7p8p9p5s6s7s7s7s+12',
  '3m3m3m4m2p2p3p3p4p4p7s8s9s+5m+23',
  '4m5m6m3p4p5p2s3s4s6s7s3z3z+8s+24',
  '3m4m5m6m6m6p7p8p8s8s6z6z6z+8s+23',
  '4m4m1p2p3p4p5p6p8p9p4s5s6s+7p+23',
  '7m8m9m2s2s4s5s6s6s6s7s8s8s+7s+14',
  '5m6m7m3p3p5p6p7p3s4s4s5s6s2s+11',
  '2m3m4m6m7m1p2p3p6p7p8p8s8s+5m+23',
  '2m3m4m5p6p7p8p9p1s2s3s7s7s+7p+14',
  '3m4m5m1p2p3p9p9p3s4s5s6z6z+6z+11',
  '4m5m6m1p2p3p5p6p7p8p9p6s6s7p+12',
  '2m3m4m6m6m2p3p6p7p8p6s7s8s4p+11',
  '1m2m3m4m5m6m3p4p4s4s6s7s8s+2p+11',
  '6m7m8m2p2p3p5p6p7p8p4s5s6s+4p+12',
  '2m3m4m6m8m7p7p4s4s4s5s6s7s7m+13',
  '2m3m4m9m9m2p3p4p5p6p5s6s7s+4p+21',
  '5m5m6m7m8m5p6p6s6s7s7s8s8s4p+14',
  '3m4m4m4m5m7p7p8p8p9p5s6s7s9p+21',
  '3p4p5p5p6p7p8p9p4s5s6s9s9s+4p+22',
  '3m3m3m4m5m2p3p4p5s6s7s8s9s+4s+14',
  '5m6m7m8m8m8m3p3p2s3s4s6s8s7s+23',
  '3m3m4m4m5m5m7m8m9m7p5s6s7s7p+22',
  '3m4m5m6m6m7m7m8m3p3p1s2s3s+5m+23',
  '3m3m7m7m8m8m9m9m3s4s5s8s9s7s+24',
  '3m4m5m1p2p3p5p6p6p7p8p3s3s+4p+11',
  '4m5m3p4p5p2s3s3s4s4s5s8s8s+6m+22',
  '5m6m7m4p5p6p3s4s5s7s8s8s8s6s+22',
  '2m3m3m4m4m5m3p4p5p2s3s3z3z+1s+14',
  '1m2m3m4m5m6m1p2p3p8p8p6s7s+5s+12',
  '3p3p4p5p6p2s3s3s4s4s5s5s7s6s+12',
  '1m2m3m7m7m8m9m9m2p2p6p7p8p8m+24',
  '3m4m3p3p4p5p6p7p8p9p4s5s6s+2m+11',
  '5p6p7p8p8p2s2s3s4s4s4s5s6s+3s+14',
  '4m5m6m7m8m4p5p6p3s3s3s4s4s6m+11',
  '2m3m4m6m7m8m4p5p7p7p4s5s6s+3p+21',
  '6m7m8m1p2p3p5p6p8p8p4s5s6s4p+12',
  '4m5m6m7m7m3p4p1s2s3s5s6s7s2p+23',
  '3m3m4m4m4m5m6m7m3s4s5s6s8s+7s+21',
  '6m7m7m8m9m3p3p4p5p6p2s3s4s5m+11',
  '3p4p5p5p6p6p7p7p5s5s7s8s9s8p+12',
  '5p5p6p6p7p7p2s3s4s5s1z1z1z+5s+12',
  '7m8m9m4p5p6p6p7p8p9p9p3s4s2s+13',
  '1p1p3p3p4p4p5p5p7p7p2s6s6s2s+12',
  '1p2p3p5p6p7p4s5s6s7s8s3z3z+3s+14',
  '4m4m6m7m8m2p3p4p5s6s7s8s8s+4m+21',
  '2m2m5p5p6p7p7p3s4s5s6s7s8s+6p+14',
  '3m4m5m6m7m8m2s2s2s3s3s7s8s+6s+24',
  '2m3m4m2p2p3p4p2s3s4s6s7s8s5p+11',
  '3m3m4m5m6m1s2s3s4s5s6s7s8s+9s+14',
  '1m2m3m1p2p1s2s3s5s6s7s1z1z+3p+12',
  '2p2p2p8p8p5s6s6s7s7s8s8s8s+8s+14',
  '3m4m6m7m8m3p4p4p5p5p6p4s4s+2m+24',
  '6m7m8m9m9m2p3p5p6p7p3s4s5s1p+22',
  '7m8m9m2p2p1s2s3s3s4s5s5z5z5z+24',
  '6m7m8m2s4s6s6s7s8s9s1z1z1z3s+13',
  '5m6m6m7m7m8m2p2p5p6p7p2s4s+3s+12',
  '3m4m5m1p2p2p3p3p5p5p1s2s3s+1p+14',
  '2p2p2p8p8p8p2s3s4s5s5s6s6s6s+14',
  '4m5m9m9m1p2p3p5s6s7s7s8s9s+3m+12',
  '2m3m4m6m6m3p4p5p6p7p5s6s7s2p+21',
  '2m3m4m6m7m8m4p5p1s2s3s7s7s+3p+24',
  '2m3m4m5m5m5m7m8m1z1z1z5z5z+9m+23',
  '2m3m4m2p2p7p8p1s2s3s5s6s7s9p+12',
  '3m4m3p3p6p7p8p1s2s3s7s8s9s+2m+22',
  '2m2m4m5m6m2p4p5p6p7p6s7s8s3p+21',
  '3m3m4m5m6m1p2p3p4s5s6s5z5z+5z+13',
  '2m2m2m4m5m6m4p5p7p7p7p7s7s+6p+12',
  '5m6m7m1p2p3p5p6p7p5s7s9s9s+6s+24',
  '3m4m5m7m7m2p3p3p3p4p3s4s5s+7m+24',
  '2m2m3m4m4m7p8p9p3s4s4s4s5s3m+22',
  '2m3m4m6m7m1p2p3p5s5s6s7s8s+5m+12',
  '5m5m7m9m7p8p9p4s5s6s7s8s9s+8m+23',
  '1m2m3m5m6m7m8m8m7p7p8p8p9p+9p+24',
  '1m2m3m1p2p3p5p6p8p8p3s4s5s+4p+13',
  '1m2m3m6m7m8m3p3p5s6s7s6z6z6z+11',
  '7m8m9m5p6p7p8p9p4s5s6s4z4z+4p+23',
  '3m4m5m2p3p4p6p6p3s4s5s6s7s2s+11',
  '6m7m8m2p2p3p4p4p5p5p7s8s9s+6p+12',
  '1m2m3m4m5m2p3p4p4p5p6p9p9p+6m+12',
  '2m3m4m6m6m7m8m9m6p7p8p2s3s1s+12',
  '2m3m4m2p3p4p6p6p7p7p8p8p9p+9p+13',
  '6m8m8p8p2s3s4s5s6s7s5z5z5z+7m+14',
  '4m5m6m6m7m3p3p6p7p8p5s6s7s+8m+22',
  '2m3m3m4m5m7m8m9m4s5s6s9s9s1m+13',
  '5m6m6m7m7m8m3p4p5p6s7s4z4z8s+11',
  '6m7m1p1p5p6p7p3s4s5s5s6s7s+8m+21',
  '1m1m4m5m6m7p8p1s2s3s6s7s8s+9p+21',
  '3m4m5m4p5p6p6p7p8p9p9p5s6s+7s+12',
  '8m8m2p3p5p6p7p1s2s3s4s5s6s+1p+11',
  '1m2m3m1p1p2p3p3s4s5s6s7s8s4p+21',
  '7m8m9m2p3p4p6p7p2s3s4s6s6s+5p+14',
  '2m2m6m6m9m9m6p7p7p3s3s7s7s+6p+13',
  '2m3m4m3p4p5p1s1s3s3s4s4s5s2s+21',
  '2m2m4m5m6m3p4p5p1s2s2s3s3s1s+13',
  '6m6m3p4p5p2s3s7s8s9s5z5z5z+1s+12',
  '2m3m4m5p5p2s3s3s4s4s4s5s6s2s+11',
  '2m2m4m5m6m1p2p3p4p5p6p6s7s+8s+11',
  '2m3m5m5m7m8m9m6p7p8p3s4s5s1m+21',
  '6m7m8m2p3p4p3s4s4s5s5s6s6s+3s+12',
  '7m8m3p3p4p5p6p6p7p8p6s7s8s6m+21',
  '2m2m6p7p7p8p9p4s5s6s6s7s8s8p+13',
  '3m3m7m8m9m3p4p2s3s4s4s5s6s5p+21',
  '5m5m6m7m8m5p6p7p2s3s7s8s9s1s+12',
  '5m5m3p4p7p8p9p1s2s3s6s7s8s+2p+22',
  '2m3m4m5m6m7m5p5p7p8p4s5s6s+6p+13',
  '3m5m3p4p5p7p7p3s4s5s6z6z6z+4m+24',
  '2m2m3m3m4m5p6p7p4s5s6s3z3z1m+11',
  '7m7m7p7p8p8p9p9p5s5s6s6s2z+2z+23',
  '4m5m6m4p5p5p6p7p2s2s2s6s6s+6p+24',
  '2m3m4m6m6m2p2p2p5p5p5p6s7s5s+21',
  '5m6m7m1p2p3p3p4p5p6s7s1z1z+8s+24',
  '4p4p5p6p7p8p9p4s5s6s7s8s9s+4p+12',
  '3m3m6m7m8m1p2p3p7p8p9p5s6s+7s+24',
  '1p3p4p5p6p6p6p7p8p9p4z4z4z2p+11',
  '1m1m2m3m4m4p5p6p2s3s5s6s7s+1s+21',
  '1m2m3m4m4m3p4p1s2s3s3s4s5s2p+11',
  '6m6m7m7m9m9m1p1p2p2p8p1z1z+8p+14',
  '3m4m4m5m5m6m7m8m9m1p1p3s4s5s+22',
  '2p3p4p4p5p7p7p4s5s6s6s7s8s+6p+14',
  '1m2m3m6m6m7m8m9m3p4p5p7s8s6s+14',
  '1m1m2p2p6p6p8p8p9p9p4s4s8s+8s+13',
  '4m5m9m9m1p2p3p7p8p9p1s2s3s+6m+12',
  '3m4m5m6m6m5p5p6p7p8p3s4s5s+6m+24',
  '2m3m4m5m6m7m8m2p3p4p4s5s6s+2m+11',
  '4m5m6m9m9m1p2p3p1s2s3s3s4s+5s+14',
  '1m1m1m1p1p1p4s6s6s6s5z5z5z+4s+12',
  '5m6m7m3p4p5p9p9p2s3s6s7s8s+1s+11',
  '2m3m4m4p4p5p5p6p4z4z4z6z6z+6p+12',
  '6m7m8m3p4p7p8p9p3s4s5s7s7s2p+11',
  '3m4m5m3p4p4p4p5p1s2s3s3s4s+2s+12',
  '2m3m4p5p6p3s3s4s4s5s5s5z5z1m+24',
  '1m2m3m3m4m5m4p4p1s2s6z6z6z+3s+22',
  '7m8m9m2p3p4p6s6s6s7s4z4z4z+8s+14',
  '2m3m3m4m4m5m1p2p2p3p3p3s3s+1p+24',
  '7m7m2p2p2p4p5p7p8p9p1z1z1z6p+21',
  '7m7m8m8m6p7p8p2s3s4s6s6s6s+8m+22',
  '2m3m4m6m7m8m2p2p6s7s7s8s8s9s+12',
  '2m2m4m5m6m8m8m3p4p5p4s5s6s+2m+11',
  '3m4m1p2p3p6p7p8p5s6s7s8s8s+2m+14',
  '1m2m3m7m8m4p4p7p8p9p3s4s5s6m+22',
  '2m2m3m4m5m7m8m9m5s6s6s7s8s+7s+22',
  '1m2m3m5m6m7m3p4p5p2s3s5s5s1s+31',
  '2m3m4m5m5m2p3p4p4p5p4s5s6s+3p+13',
  '2m3m4m5m6m7m5p6p8p8p5s6s7s7p+12',
  '3m4m5m7m7m1p2p3p5s6s6s7s7s8s+11',
  '2m3m2p2p6p7p8p6s7s7s8s8s9s+1m+12',
  '2m4m6m7m8m2p3p4p8p8p2s3s4s+3m+13',
  '2m3m3m4m4m8m8m3p4p5p7p8p9p2m+23',
  '2m3m4m5m6m1p1p6p7p8p4s5s6s7m+12',
  '1m2m3m5m6m7m1p2p3p1s2s6z6z+3s+23',
  '1m2m3m5p6p7p2s2s3s3s4s4s5z5z+21',
  '5m5m7m8m9m3p4p5p2s3s6s7s8s4s+14',
  '2m3m4m4p5p6p7p8p3s4s5s6s6s+3p+24',
  '5m6m7m1p2p3p7p7p3s4s4s5s6s+5s+12',
  '4m5m6m1p2p3p6p7p8p8p8p4s5s+3s+13',
  '1m2m3m7m8m9m6p6p2s3s5s6s7s+1s+11',
  '4m4m4m5m5m6m6m7m2s3s4s5s6s+4s+14',
  '4m5m6m3p3p5p6p7p3s4s7s8s9s+5s+13',
  '4m5m6m8m8m5p6p7p2s3s4s6s7s+8s+13',
  '3m4m4m4m5m5m6m7m4p4p4p5p7p6p+11',
  '1m2m3m4m5m2p3p4p4z4z7z7z7z3m+12',
  '4m5m6m3p4p5p8p8p1s2s3s5s6s4s+11',
  '4m5m5m6m7m9m9m2p3p4p5p6p7p6m+24',
  '6m7m8m2p3p4p1s1s5s5s6s6s7s4s+11',
  '3p3p4p5p5p7p8p9p2s3s4s5s5s+4p+12',
  '5m6m6m7m7m8m1p1p7p8p4s5s6s+6p+14',
  '7m8m4p5p6p6p7p8p5s5s6s7s8s+9m+11',
  '2m3m4m6m8m2z2z2z5z5z5z6z6z+7m+24',
  '3m3m7s7s8s8s3z4z4z5z5z7z7z+3z+12',
  '5m6m7m7m7m3p4p5p6p7p8p3s4s5s+14',
  '7m8m9m2p3p4p4p5p1s2s3s5s5s+3p+13',
  '4m4m4m5m5m5m6m7m8m4p5p4s4s+6p+23',
  '1m2m3m3p4p4p5p5p6p7p8p3z3z+9p+12',
  '2m2m2m2p3p4p4p5p6p3s3s6s8s+7s+14',
  '1m2m4m4m5m5m6m6m9p9p6s7s8s3m+12',
  '7m7m6p6p8p8p1s1s8s8s3z7z7z+3z+14',
  '2m2m3m4m4m5m6m6p7p8p3s4s5s2m+11',
  '1m2m3m4m5m6m2p3p4s5s6s8s8s+1p+23',
  '4m4m6m7m8m4p5p6p2s3s4s5s6s7s+12',
  '5m6m7m8m8m1p2p3p9p9p5z5z5z+8m+12',
  '3m4m4m5m6m6p7p8p3s3s7s8s9s+2m+21',
  '2m2m6m7m8m3p4p5p7p8p9p3s4s2s+14',
  '1m2m3m4m5m6m8m8m6p7p8p5s6s7s+13',
  '3m4m6m7m8m3p3p3p5s5s6s7s8s2m+12',
  '7m8m1s2s2s3s3s4s6s7s8s3z3z+9m+21',
  '6m7m8m4p4p4p3s4s5s5s6s7s7s+4s+24',
  '1m2m3m9m9m1p2p2p3p3p4s5s6s+4p+12',
  '2m4m4m8m8m2p2p4p4p7p7p8p8p2m+21',
  '3m4m5m3p3p5p5p5p6p7p8p5s6s+7s+23',
  '1m1m1p2p3p2s3s5s6s7s7s8s9s+1s+22',
  '4m5m8m8m6p6p6p2s2s2s6s7s8s+6m+11',
  '2m3m4m5m6m5p6p7p7p7p7s8s9s+4m+22',
  '7m8m9m3p4p5p7p8p9p7s8s7z7z+9s+24',
  '1m3m4m5m6m7m8m9m4p5p6p5s5s+2m+23',
  '5m5m6m6m7m7m5p6p7p4s5s7s7s3s+22',
  '4m5m5m6m6m7m1p1p4p5p6p3s4s5s+24',
  '2m3m4m4m4m4m2p4p2s2s3s4s5s+3p+12',
  '3m4m5m3p4p5p7p8p2s3s4s8s8s+6p+21',
  '1m2m3m2p2p4p5p2s3s4s7z7z7z+6p+11',
  '6m7m2p3p4p2s3s4s5s6s7s1z1z+8m+23',
  '2m2m4p4p9p9p1s1s3s3s6z7z7z+6z+12',
  '4m4m7m8m3p4p5p2s3s4s5s6s7s+9m+22',
  '5m5m1p2p3p4p5p5p6p6p7p4s5s3s+13',
  '1m2m3m6m7m7p7p3s4s5s6s7s8s5m+21',
  '1m2m3m7m1p2p3p1s2s3s7s7s7s+7m+11',
  '3m4m1p2p3p4p4p6s7s7s8s8s9s5m+23',
  '6m7m8m7p7p2s3s7s7s7s7z7z7z4s+13',
  '6m6m9m9m4p4p1s1s2s8s8s6z6z+2s+12',
  '3m4m4m5m6m7m7m5p6p7p7p8p9p5m+12',
  '2m3m5p6p7p3s4s4s5s5s6s9s9s+1m+13',
  '6m7m8m1p2p3p5p6p1s2s3s3z3z+4p+12',
  '5m5m6m7m7m6p6p6p7p8p9p6s6s6m+12',
  '4m5m6m5p6p7p7p8p4s4s5s6s7s+9p+12',
  '2m2m4m5m6m5p5p5s5s6s6s7s7s2m+21',
  '2m3m3m4m4m5m5m6m7m2s2s6s8s7s+14',
  '3m4m5m5p6p2s3s4s9s9s7z7z7z+7p+11',
  '2p2p3p4p5p6s7s7s8s8s7z7z7z9s+21',
  '7m7m3p4p5p7p7p3s3s3s8s8s8s+7m+12',
  '3m4m4m5m5m6m1p2p3p5p5p2s3s1s+11',
  '7m8m9m1p2p1s1s1s7s8s9s1z1z+3p+13',
  '1m1m3m4m5m4p5p1s2s3s3s4s5s6p+14',
  '2m3m7m8m9m4p5p6p2s2s6s7s8s+1m+12',
  '2p2p3p4p5p6p7p2s3s4s5s6s7s+5p+21',
  '5m5m6m6m7m2p2p2p6p7p8p5s5s+7m+22',
  '2p3p4p5p5p5p6p7p4s4s7z7z7z+5p+14',
  '9m9m2p2p4p4p6p6p7p7p8p8p5z5z+12',
  '1m2m3m1p2p3p7p8p9p5s6s7s7s+7s+24',
  '4m4m5m1p1p6p6p7p7p7s7s5z5z5m+11',
  '4m4m3p4p5p7p8p9p3s4s7s8s9s5s+13',
  '3m3m5m6m7m4p5p5s5s6s6s7s7s3p+22',
  '5m5m6m6m7m8m8m2p3p4p7s8s9s4m+11',
  '7m8m9m4p5p1s1s1s2s3s3s4s5s+6p+23',
  '1m1m2m3m7m8m9m3s4s5s7s8s9s1m+14',
  '6m7m1p2p3p1s2s3s4s5s6s8s8s+5m+21',
  '1m2m3m4m5m1p2p3p4p4p6s7s8s+6m+12',
  '2m2m2m7m7m5s5s6s7s7s6z6z6z+6s+12',
  '4m4m6m6m9m9m4p4p7p7p8p8p3s3s+14',
  '5m6m7m4p6p7p7p2z2z2z6z6z6z+5p+11',
  '2m2m3m3m4m6m7m8m1s2s3s4s4s+1m+14',
  '1m2m2m3m3m4m4m5m4p4p6p7p8p3m+11',
  '6m7m3p4p5p7p8p9p4s5s6s8s8s5m+21',
  '4m4m6m6m8m7p7p8p8p7s7s8s8s8m+11',
  '3m4m5m3p4p5p6p7p8p5s5s6s8s7s+13',
  '2m2m4m5m6m6m7m8m3p5p4s5s6s4p+12',
  '1m2m3m5m6m1p2p3p1s2s3s3z3z+4m+12',
  '2m3m2p3p4p5p6p7p2s3s4s9s9s+1m+24',
  '2m3m4m3p4p4p5p6p5s5s6s7s8s+2p+21',
  '4m4m4m2p3p1s2s3s7s7s6z6z6z1p+23',
  '1m2m3m5m6m6m7m7m8m3p4p6p6p2p+24',
  '5m5m1p2p3p5p6p4s5s6s7s8s9s+7p+21',
  '2m3m4m5m6m7m4p4p4p4s5s6s6s3s+12',
  '2m2m3m3m4m4m3p4p2s3s4s3z3z+2p+14',
  '4m4m8p8p9p9p5s5s6s6s8s6z6z+8s+21',
  '6m7m8m4p5p6p2s2s3s5s7z7z7z+4s+13',
  '6m7m8m3p4p6p6p7p8p9p5s6s7s+2p+11',
  '1m3m4m5m6m7m8m9m2p2p3s4s5s+2m+12',
  '3m4m5m4p4p5p6p7p3s5s7z7z7z4s+21',
  '2m3m4m2p3p4p5p5p7p8p9p4s5s+3s+21',
  '3m3m2p3p4p6p6p7p7p8p8p2s3s4s+24',
  '7m8m9m5p6p2s3s4s5s6s7s2z2z7p+11',
  '5m6m7m7m8m3p3p7p8p9p7s8s9s+9m+13',
  '2m3m4m4p5p5p6p6p2s2s4s5s6s7p+14',
  '1m2m3m6m7m8m7s7s8s8s5z5z5z+7s+13',
  '5m6m7m2p2p3p4p5p3s4s5s7s8s+9s+21',
  '3m4m5m6m6m7m7m7m5p6p7p8p8p+6m+23',
  '4m4m4m3p4p5p3s4s5s5s6s8s8s4s+14',
  '2m3m4m5m3p4p5p3s4s5s5s6s7s5m+11',
  '3p4p5p8p8p8p1s1s1s6s6s6s6z6z+12',
  '1s3s3s4s4s9s9s1z1z4z4z5z5z1s+11',
  '2m3m6m7m8m6p6p5s6s7s7s8s9s4m+13',
  '4m5m6m2p2p3p4p5p6p7p8p5s6s4s+13',
  '2m2m5m6m7m6p7p8p5s5s6s6s6s+5s+13',
  '3m3m3m2p2p4p4p4s5s6s6s7s8s4p+22',
  '7m8m9m1p1p1s2s3s7s8s4z4z4z+9s+21',
  '4p4p6p6p9p9p1z4z4z5z5z6z6z1z+24',
  '3p3p3p6p7p8p4s4s6s7s8s1z1z+1z+14',
  '4p4p6p7p8p1s2s3s4s5s6s7z7z+7z+12',
  '1m1m4m5m6m7m8m2p3p4p2s3s4s6m+21',
  '1m1m2m2m3m3m5m6m9p9p3s4s5s+4m+24',
  '1m2m3m7m8m9m6p6p7p8p9p4s5s+3s+14',
  '5m6m1p2p3p4p5p6p7p8p9p5s5s+4m+21',
  '4m5m7m7m3p4p5p5p6p7p4s5s6s+3m+21',
  '2m3m4m3p3p4p5p6p7p8p9p5s6s7s+12',
  '3m4m5m2p3p4p5p6p7p8p4s5s6s+5p+11',
  '2p2p3p4p5p1s2s3s5s6s6z6z6z+7s+13',
  '4m5m6m2p3p4p4p6p8p8p3s4s5s+5p+12',
  '2m3m4m5m8m8m8m2p3p4p5p6p7p5m+13',
  '5m6m6m7m7m8m5p6p7p2s2s5s5s2s+14',
  '1m3m1p1p7p8p9p1s2s3s7s8s9s+2m+11',
  '7m8m1p1p5p6p7p1s2s3s5s6s7s9m+22',
  '5m6m7m4p5p2s2s4s5s6s7s8s9s+3p+14',
  '2m3m6m7m8m3p3p4p5p6p6s7s8s4m+23',
  '3m4m5m3p4p5p6p6p2s3s4s6s7s+5s+24',
  '3m4m1p2p3p4p5p6p4s4s7s8s9s2m+12',
  '6m7m8m2p3p4p5p5s6s7s6z6z6z2p+22',
  '2m2m4m5m6m1p2p3p5p6p7p4s5s3s+14',
  '3m3m3m4p5p9p9p6s6s7s7s8s8s6p+11',
  '4m5m6m7m7m3p4p4p5p5p6p2s3s4s+13',
  '1m2m3m4m5m6m8m8m6p7p4s5s6s5p+21',
  '7m8m3p4p5p1s2s3s4s4s5s6s7s6m+13',
  '5m6m8m8m8m3p3p6p7p8p4s5s6s7m+23',
  '1m2m3m1p2p3p4p5p2s2s2s3s4s6p+24',
  '4m5m6m4p5p6p8p8p2s3s3s4s5s1s+21',
  '2m3m4m6m6m4p5p7p8p9p4s5s6s6p+11',
  '4m5m4p5p6p6p7p8p1s2s3s4s4s+3m+11',
  '1m4m4m3s3s5s5s1z1z5z5z7z7z1m+12',
  '2m3m4m5m6m6p7p8p6s6s7s7s7s+7m+21',
  '1m2m3m4m5m6m4p5p5s6s7s8s8s+3p+24',
  '2m2m3m3m4m4m6m7m4s4s6s7s8s+8m+13',
  '3m4m4m5m6m6p7p8p4s4s5s6s7s+2m+14',
  '3m4m5m2p3p4p2s3s4s5s6s7s8s8s+12',
  '6m7m2p3p4p9s9s9s5z5z5z7z7z+5m+23',
  '2p2p5p6p4s5s6s4z4z4z7z7z7z4p+11',
  '1m2m3m6m7m2p2p5p6p7p7s8s9s8m+11',
  '2m2m5m5m6m6m6p6p4s4s8s2z2z+8s+23',
  '1m1m2m2m3m3m1s1s3s3s5s5s5s+3s+14',
  '3m4m5m3p3p7p7p2s3s4s7s7s7s+3p+11',
  '3m3m7m7m1s1s9s9s2z2z3z3z5z+5z+12',
  '2m3m4m4p4p1s2s3s6s7s7s8s9s+5s+22',
  '7m7m3p4p5p6p7p8p3s4s5s7s8s+9s+21',
  '1m2m3m7m7m2p3p4p2s3s4s6s7s8s+11',
  '1m2m3m7m8m9m4p5p6p3s3s5s6s+7s+24',
  '5m6m6m7m7m8m5s5s6s6s7s7s7s7s+11',
  '6m7m4p4p5p5p6p6p1s2s3s6s6s+8m+11',
  '6m7m8m2p3p4p7p8p9p9p9p7s8s+6s+22',
  '6m7m3p4p5p2s2s6s6s6s2z2z2z5m+22',
  '1m1m4m5m6m5p6p7p7p8p9p7z7z+7z+22',
  '6m6m4p5p6p3s4s5s6s8s1z1z1z+7s+12',
  '2m3m4m5m6m7m2p3p1s2s3s7s7s+1p+21',
  '1m1m6m7m8m8m9m4p5p6p7z7z7z7m+24',
  '3m4m8m8m2p3p4p5p6p7p3s4s5s+5m+13',
  '3m3m6p7p8p2s3s4s5s6s6s7s7s+8s+14',
  '7m8m2p3p4p3s4s5s6s7s8s1z1z+6m+23',
  '2m3m2p2p4p5p6p2s3s4s5s6s7s+1m+12',
  '2m3m4m6m6m5p6p7p6s7s7s8s9s+8s+23',
  '2m3m4m1p1p2p4p7s7s8s8s9s9s3p+13',
  '4m5m5m6m6m7m3p4p3s3s4s5s6s+2p+14',
  '2p2p3p4p5p4s4s5s6s6s7s7s8s2p+13',
  '3m4m5m5p6p6p7p8p4s5s6s8s8s4p+11',
  '5m6m7m2s2s3s3s4s4s4z5z5z5z+4z+14',
  '5m6m7m1p1p1p7p7p1s1s1s3s3s7p+14',
  '2m3m4m5m6m1p2p3p1s2s3s1z1z+7m+22',
  '1p1p1p2p2p2p5p5p2s4s3z3z3z+3s+22',
  '3m3m6m6m9s9s2z3z3z5z5z7z7z+2z+14',
  '4m4m6m6m5p5p6p6p7p7p5s5s4z+4z+14',
  '4p4p7p8p9p1s2s3s5s6s7s8s9s+7s+13',
  '1m2m2m2m3m4m5m2p3p4p4s5s6s6m+11',
  '6m6m6m6p7p8p9p9p4s4s4s8s8s9p+14',
  '6m6m7m7m8m3p4p5p7p7p7p8s8s+8m+22',
  '5m5m6m6m7m2p2p8p8p3s3s4s4s+7m+14',
  '3m4m5m4p4p2s3s4s8s8s5z5z5z8s+13',
  '3p4p5p6p7p8p3s3s5s6s7s8s9s4s+11',
  '3m4m5m6m7m8m8p8p4s5s5s6s7s3s+22',
  '1m2m2m3m3m4m1p2p1s2s3s3s3s+3p+14',
  '1m2m3m4m4m5m5m6m6m7m7m5s6s7s+11',
  '4m5m6m8m8m4p5p3s4s5s7s8s9s+3p+13',
  '4m5m6m5p6p7p7p1s2s3s5s6s7s+4p+11',
  '2m3m5p6p6p7p7p8p3s4s5s8s8s4m+13',
  '3m3m5m6m6m8m8m4p4p8p8p1z1z+5m+11',
  '3m4m5m7m7m3p4p5p5p6p7p4s5s6s+14',
  '1m3m3m6m6m7m7m9m9m5p5p6z6z+1m+21',
  '3m4m5m7m8m4p4p4p3s4s5s6s6s6m+12',
  '4m5m6m2p3p4p6p6p2s3s6z6z6z+1s+22',
  '3m4m2p3p4p5s6s7s8s8s1z1z1z5m+13',
  '4m4m4m1s1s2s3s3s5s6s7s7z7z+2s+11',
  '2m3m4m5m5m1p2p3p3s4s5s6s7s2s+21',
  '4m5m6m6m7m8m2p3p4p3s4s5s6s3s+11',
  '2p2p7p8p3s4s5s5s6s7s7z7z7z+6p+14',
  '1m2m3m3m5m5m6m6m7m7m5p6p7p3m+23',
  '2m3m4m6m7m6p7p8p2s3s4s6s6s+5m+13',
  '2m3m4m5m5m7m8m9m6p7p8p6s7s5s+14',
  '6m6m7m7m8m8m6p6p1s1s1z1z7z+7z+12',
  '6m6m7m7m8m8m9p9p3s4s5s6s7s+2s+23',
  '1m1m1m2m3m7p8p9p2s3s4s5s6s+1s+12',
  '4m4m6m7m8m6p7p5s6s7s8s8s8s+5p+13',
  '5m6m7m3p4p6p7p8p5s6s7s9s9s+5p+13',
  '2m3m4m5m6m7m2p3p9p9p4s5s6s+1p+11',
  '3m4m5m1p2p3p5p6p7p1s1s7z7z+7z+21',
  '5m5m6m7m1p2p3p1s2s3s6s7s8s8m+12',
  '1m2m3m7m8m9m2p3p5p5p1s2s3s+1p+22',
  '4m5m6m1p2p3p4p5p6p7p8p9p5s+5s+21',
  '2m3m4m1p2p3p5p6p7p4s4s5s6s4s+22',
  '4m4m4m7m8m9m9m9m3s4s5s5z5z+5z+21',
  '2m4m5m5m5m3p4p5p6p7p8p3s3s3m+11',
  '1m2m3m7m8m9m9m4p5p6p1z1z1z6m+14',
  '3m3m6m6m7m7m4p5p5p6p6p3s3s+4p+13',
  '5m6m7m3p4p5p5p6p3s3s5s6s7s4p+22',
  '5m6m7p7p8p8p9p9p3s4s5s2z2z+4m+23',
  '2m2m2m5p6p7p2s3s4s5s5s7s8s6s+12',
  '3m4m4m5m5m6m2p3p4p9s9s7z7z+7z+14',
  '7m8m9m2p3p4p7p8p9p3s3s7s8s+9s+24',
  '3m3m3m2p3p4p8p8p2s3s3s4s4s5s+14',
  '4m5m7m7m2p3p4p7p8p9p7s8s9s+3m+23',
  '2m3m4m6m7m8m9m9m3p4p6p7p8p2p+13',
  '6m7m8m2p2p3p3p4p3s4s5s6s6s+1p+22',
  '2m3m3m4m4m6m6m7p8p9p5s6s7s+5m+14',
  '4m4m5m6m2p3p4p2s3s4s5s6s7s7m+14',
  '1m2m3m5m6m7m4s4s6s7s7s8s8s+6s+11',
  '2m2m3m3m5m5m6p8p8p1s1s3s3s+6p+12',
  '3m3m5m5m5m3p4p4s5s6s7s7s7s2p+23',
  '4m5m6m6m7m8m3p4p5p5p6p5s5s+7p+12',
  '4m4m4m5m6m7m3p3p4p4p2s3s4s+3p+22',
  '3m4m5m9p9p2s3s4s7s7s8s8s9s+6s+21',
  '4m5m6m2p2p7p8p2s2s3s3s4s4s6p+13',
  '1m3m3p3p3p1s1s1s4s4s4s5z5z+2m+24',
  '6m6m4p4p5p5p7p7p3s3s2z2z5z+5z+23',
  '3m4m2p3p4p2s2s2s4s4s5s6s7s5m+22',
  '6m7m8m4p4p5p6p6p7p8p2s3s4s7p+13',
  '1m1m2m3m4m1p2p3p6p7p5s6s7s+8p+13',
  '2m3m4m5m6m5p6p7p1s1s7s8s9s7m+23',
  '2m3m4m5m6m7m2p4p6p6p6s7s8s+3p+22',
  '2m2m4m5m6m6p7p2s3s4s4s5s6s+5p+13',
  '4m5m6m6m7m5p6p7p3s3s4s5s6s8m+21',
  '3m4m5m5p6p1s2s3s6s7s8s8s8s7p+21',
  '1m2m3m3p4p7p8p9p9p9p4s5s6s5p+12',
  '1m2m2m3m3m4m4m5m7m8m9m7s7s+6m+22',
  '5m5m7m7m2p2p5p5p2s6s6s3z3z2s+12',
  '1m2m3m7m7m2p3p7p8p9p2s3s4s1p+21',
  '4m5m6m2p2p6p7p1s2s3s5s6s7s+5p+13',
  '2m3m4m5m6m7m2p3p4p7p7p6s8s+7s+14',
  '4p4p5p6p7p1s2s3s6s7s7s8s9s+5s+12',
  '5m6m7m8p8p3s4s4s5s5s6s6s7s+2s+11',
  '4m4m2s3s4s4s5s6s8s9s5z5z5z7s+21',
  '1p2p4s4s6s6s7s7s8s8s2z2z2z3p+22',
  '5m7m5p6p7p7p8p9p5s6s7s2z2z+6m+23',
  '3m4m7m8m9m1p1p3p4p5p7p8p9p5m+24',
  '4m5m6m8m8m1p1p2p2p3p3p6p6p+6p+22',
  '2m3m4m4m5m6m5p5p6p7p8p7s8s+9s+12',
  '4m4m5m6m7m3p4p5p5p6p7s7s7s4p+12',
  '2m3m4m4m5m6m1p2p3p9p9p5s6s+7s+12',
  '2m3m4m5m5m7m9m4p5p6p5z5z5z+8m+14',
  '6m7m6p6p7p7p8p8p4s4s4s7s7s+5m+11',
  '1p2p3p4p5p6p7p8p9p5s7s4z4z+6s+12',
  '8m9m5p5p6p6p7p7p3s4s5s6s6s+7m+13',
  '5m6m7m3p4p5p5p6p7p8p2s3s4s8p+24',
  '4m5m2p3p4p6p7p8p2s2s3s4s5s+3m+24',
  '2m3m4m9m9m3p4p5p6p7p8p7s8s+6s+12',
  '1m2m3m5m6m7m3p4p6s6s6s7s8s+5p+12',
  '5m6m6p6p6p7p8p9p3s3s6z6z6z+4m+11',
  '7m9m6p7p8p2s3s4s6s6s6z6z6z8m+12',
  '4m5m6m3p4p6p6p1s2s3s7s8s9s2p+21',
  '3m4m3p4p5p6p7p7p8p8p9p5s5s5m+23',
  '5m5m3p4p5p7p8p9p2s3s4s4s5s+3s+11',
  '4m5m6m4p6p3s3s4s5s6s8s8s8s+5p+22',
  '1m1m1m2m3m5p7p7p8p9p5z5z5z+6p+14',
  '1m1m5m5m7m7m5p5p6s6s8s8s7z7z+13',
  '1m2m3m5p6p7p9p9p2s3s7s8s9s+4s+11',
  '8m8m2s2s3s3s4s4s6s6s9s9s5z5z+14',
  '2m2m2m4p4p4p3s4s5s6s6s7s7s+6s+23',
  '1p2p3p7p7p8p8p9p9p4s5s5s5s+3s+13',
  '5m6m7m3p4p5p5p6p7p8p9p6s6s+7p+13',
  '4m5m6m8m8m8m2s2s5s6s7s7s7s+7s+24',
  '3m4m5m2p3p4p5s5s6s7s4z4z4z+8s+24',
  '2m3m4m6m6m7m7m7m5p6p6s7s8s4p+23',
  '1m2m3m7m8m9m1p2p3p2s3s8s8s1s+11',
  '4m5m6m1p1p2p2p3p3p6p7p3s3s8p+11',
  '6m7m8m2p3p4p2s2s4s5s7s8s9s3s+23',
  '4m4m5m5m8m4s4s7s7s8s8s1z1z+8m+12',
  '4m5m6m7m8m2p3p4p2s2s3s4s5s6m+13',
  '6m7m2p3p4p5p5p3s4s4s5s5s6s8m+14',
  '1m2m3m1p1p3p4p4p5p5p2s3s4s3p+12',
  '1m1m1m5p9p9p9p6s6s7s7s8s8s+5p+23',
  '3m4m3p4p4p4p4p5p6p7p8p3s3s5m+23',
  '5m6m7m1p1p3p4p4p5p6p3s4s5s+5p+12',
  '4m5m6m7m7m4p5p6p7p8p9p5s6s7s+24',
  '1m2m3m7m8m9m7p9p7s8s9s6z6z8p+12',
  '3m4m5m4p5p1s2s3s5s5s6s7s8s+3p+12',
  '1m2m3m5p6p1s2s3s7s8s9s3z3z+7p+14',
  '2m3m4m5m6m7m9m9m2p3p4p4s5s+3s+14',
  '1p2p3p3p4p5p5p6p7p8p9p6z6z+4p+13',
  '2m3m4m5p6p4s5s5s5s6s7s8s9s+4p+23',
  '1m2m3m6m6m2p3p4p6p6p7p8p8p+7p+21',
  '4m4m5m5m1p1p2p2p4p5s5s4z4z+4p+23',
  '3p3p4p7p7p9s9s2z2z5z5z6z6z+4p+12',
  '1m2m3m6m8m4p5p6p3s3s6z6z6z7m+21',
  '2m2m1p2p3p6p7p8p2s3s5s6s7s+4s+23',
  '7m7m7p7p8p9p9p3s3s3s6s6s6s8p+21',
  '2m3m2p3p4p5p6p7p7p8p9p2s2s1m+22',
  '2m3m4m3p4p7p7p4s5s6s7s8s9s5p+12',
  '6m7m8m1p2p3p4p5p1s1s1s2s3s+6p+12',
  '3m3m3p4p5p6p7p1s2s3s6s7s8s8p+13',
  '2m3m4m7m8m9m3p4p5p9p9p6s7s+8s+12',
  '1m1m8m8m1p1p4p4p9p9p4z4z7z7z+21',
  '5m6m7m8m9m5p5p7p8p9p7s8s9s4m+22',
  '1m1m6m7m8m7p8p9p3s4s5s7z7z+7z+12',
  '1m1m3m3m7p7p5s5s7s7s1z7z7z+1z+23',
  '4m5m6m3p4p5p6p6p7s7s8s8s9s6s+22',
  '4m5m6m3p4p7p8p9p1s1s4s5s6s+5p+11',
  '4m4m5m5m6m6m6m7m9m9m4p5p6p+8m+22',
  '5m6m6m7m7m8m5p6p7p4s5s7s7s3s+11',
  '2m2m7m8m9m4p5p2s3s4s7s8s9s+3p+13',
  '6m7m8m1s2s3s5s7s7s7s7z7z7z+5s+22',
  '3m4m5m7m7m7m3p5p4s5s6s8s8s+4p+12',
  '3m4m5m1p1p3p4p5p6p7p8p4s5s3s+12',
  '2m3m4m5m7m7m7m6p7p8p3s4s5s+5m+22',
  '1m2m3m2p3p6p6p7p8p9p1s2s3s+1p+13',
  '2m2m4m5m6m2p3p6p7p8p4s5s6s4p+13',
  '2m3m3m4m5m7m7m3p4p5p6s7s8s1m+11',
  '2m2m7m7m4p5p5p6p6p8p8p2s2s4p+14',
  '5m5m1p2p3p4p5p3s4s5s7s8s9s+3p+13',
  '2m3m4m6m7m8m3p3p5p6p3s4s5s+7p+24',
  '2m3m4m7m9m5p5p3s4s5s5z5z5z8m+14',
  '2m3m4m6p7p7p7p2s3s4s5s6s7s8p+13',
  '4m4m2p3p4p6s6s7s7s8s1z1z1z+8s+12',
  '2m3m4m4m5m6m1p2p3p6p6p5z5z+5z+22',
  '3m4m5m5m6m2p3p4p6p7p8p2s2s+7m+21',
  '5m6m7m2p2p2p4p5p6p2s2s4s4s+4s+11',
  '8m8m2p3p4p5p6p7p3s4s5s5s6s+7s+12',
  '1m2m3m2p3p3s4s5s5z5z6z6z6z+4p+11',
  '2m3m4m5m5m6m7m8m6p8p2s3s4s+7p+22',
  '1m1m5m6m2p3p4p7p8p9p6s7s8s7m+24',
  '6m7m8m3p4p5p1s1s2s2s3s5s5s3s+14',
  '3m4m5m3p3p4p5p6p7p7p7p6s7s5s+22',
  '4m5m5m6m6m7m8m9m1p2p3p8s8s4m+22',
  '7m8m9m4p5p6p1s2s3s4s5s8s8s6s+21',
  '4m4m5m5m6m8m8m6p7p8p4s5s6s3m+21',
  '5m6m7m1s2s3s3s4s4s5s6s8s8s2s+12',
  '3m3m5m5m8m8m2p2p3p3p7p7p7s+7s+14',
  '7m8m9m6p7p9p9p3s4s4s5s5s6s5p+11',
  '6m7m8m3p4p5p6p7p8p1s1s6s7s8s+13',
  '2m3m4m3p3p1s2s3s4s5s7s8s9s6s+12',
  '5m5m6m6m7m7m4p5p6p6p8p3s3s+7p+31',
  '3m4m5m1p2p3p6p7p4s5s6s4z4z8p+21',
  '6m7m3p4p5p7p7p3s4s5s5s6s7s5m+12',
  '1m3m6m6m1p2p3p7s8s9s1z1z1z2m+12',
  '5m6m7m2p3p4p5p6p7p3s3s6s7s+8s+14',
  '2m3m4m4m5m6m2p2p3p4p3s4s5s+2p+14',
  '1m2m3m4m4m7p7p8p8p9p2s3s4s9p+24',
  '1m1m2m3m4m3p4p6p7p8p4s5s6s2p+12',
  '3m4m5m7m8m8m9m9m6p7p8p6s6s+7m+22',
  '2m3m4m7m8m9m3p3p5p6p2s3s4s4p+22',
  '3p4p5p1s1s4s5s5s6s7s7s8s9s+3s+23',
  '3m3m2p3p4p5p6p7p2s2s3s4s5s+3m+24',
  '1m1m2m3m4m1p2p3p5s6s7s8s9s7s+12',
  '2m2m5m6m7m5p6p6p6p7p7z7z7z2m+12',
  '4m5m6m1p2p3p4s4s5s5s6s2z2z+3s+13',
  '3m3m4m4m2p2p3p3p5p5p6p6z6z+6p+21',
  '1m2m3m4m5m6m8m9m4s4s4z4z4z+7m+13',
  '4m4m2p2p3s3s4s4s8s8s9s9s4z4z+13',
  '5m6m7m8m9m7p8p9p5s5s7s8s9s+4m+22',
  '7m8m8m9m9m3p3p3p7s8s9s4z4z7m+11',
  '1m2m3m8m8m7p8p9p5s6s6s7s8s+7s+11',
  '1m2m3m5m6m7m1p2p3p7s8s1z1z6s+22',
  '7m8m9m3p3p7p8p9p1s2s3s8s9s7s+21',
  '3m4m5m4p5p6p2s3s4s5s6s7s8s+5s+23',
  '4m4m7m7m9m9m5p6s6s3z3z7z7z+5p+23',
  '7m8m9m5p6p7p2s3s6s6s7s8s9s+1s+24',
  '2m2m3m3m4m4m5m6m7m5s5s6s8s+7s+14',
  '3m4m4m5m6m1p1p2p3p4p5p6p7p+2m+11',
  '7m7m3p3p8p8p9p9p1s1s1z1z3z3z+12',
  '3m4m5m7m8m9m5p6p3s3s4s5s6s+7p+13',
  '1p2p3p3p4p3s4s4s4s5s6s7s8s2p+11',
  '2m3m4m5m6m5p5p6p7p8p1s2s3s+7m+11',
  '2m2m7p8p2s3s4s5s5s6s6s7s7s6p+22',
  '5m6m3p3p3p6p6p4s5s6s8s8s8s+4m+12',
  '6m7m1s2s3s3s4s5s6s7s8s4z4z+5m+12',
  '4m4m4m8m8m7p8p1s2s3s7z7z7z+9p+22',
  '1p2p3p7p7p8p8p9p9p3s4s6s6s+5s+23',
  '1m2m3m4p5p6p6p7p4s5s6s7s7s+5p+11',
  '3m3m3m6m7m4p4p6p7p8p2s3s4s8m+24',
  '3m4m5m6m6m2p3p4p6p7p8p3s4s+2s+12',
  '5m6m7m5p6p7p2s3s4s6s7s2z2z8s+11',
  '4m4m6m7m8m4p5p6p7p8p4s5s6s+6p+12',
  '2m3m4m7m8m9m7p8p3s3s7s8s9s9p+22',
  '2m3m4m6m7m8m3p4p5p5p6s7s8s+2p+14',
  '3m3m4m5m5m7m8m9m4p4p6p6p6p+4m+22',
  '3m4m4m5m5m3p4p5p8p8p4s5s6s6m+14',
  '5m6m7m1p1p5p6p3s4s5s7s8s9s+7p+22',
  '4m4m7m8m9m4p5p2s3s4s5s6s7s+6p+23',
  '1m1m1m2m3m5m6m7m3p4p3s4s5s5p+22',
  '6m7m8m2s2s3s3s4s5s6s7s9s9s+1s+12',
  '2m3m4m5m5m7m8m9m3s4s5s6s7s2s+14',
  '4m4m1p8p8p9p9p8s8s3z3z4z4z+1p+22',
  '1m2m2m3m3m7m7m2p2p2p5s6s7s+1m+23',
  '2m3m4m5m6m7m8m9m3p4p5p6s6s+4m+11',
  '1p1p2p2p2p4p4p4p5p5p5p6p7p+1p+23',
  '7m8m2p3p4p6p6p7p8p9p2s3s4s+9m+11',
  '2m2m6m6m8m8m2p3p3p4s4s3z3z2p+23',
  '2m3m4m6m7m5p6p7p2s2s7s7s7s5m+11',
  '2m3m4m9m9m4p5p6p3s4s5s7s8s6s+21',
  '2m2m4m5m3p4p4p5p5p6p4s5s6s3m+23',
  '5m6m7m4p5p6p3s4s5s5s6s7s8s+2s+23',
  '2m4m7m7m7m2p3p4p9p9p2s3s4s3m+14',
  '4m4m1p1p2p2p3p3p5p5p6p7p8p5p+14',
  '6m7m8m3p3p3p4s4s1z1z2z2z2z1z+12',
  '6m7m8m8m3p4p5p2s3s4s4s5s6s8m+11',
  '4m5m6m7p8p9p2s2s5s6s7s7s8s+9s+21',
  '5m5m5m6m7m8m4p5p6p8p8p5s6s7s+21',
  '4m5m7m8m9m5p5p3s4s5s6s7s8s+6m+11',
  '5m5m7m8m3p4p5p1s2s3s5s6s7s9m+14',
  '5m6m7m1p1p6p7p7p8p8p9p2s3s+1s+23',
  '5m6m7m2p2p6p7p8p5s5s6s7s8s+2p+23',
  '1m2m3m4m5m6m7m8m9m5s6s9s9s+7s+24',
  '2p3p4p5p6p7p1s2s3s5s6s4z4z+7s+12',
  '6m6m4p5p6p6p7p2s3s3s4s4s5s+8p+12',
  '2m2m4m5m6m7m8m9m2p3p4p4s5s3s+24',
  '2m2m4m5m5m6m6m7m4p5p7p8p9p6p+13',
  '4m5m6m4p5p6p7p8p3s3s3s4s5s+9p+11',
  '5m6m6m6m7m5p6p4s4s4s5s6s7s+7p+11',
  '1m2m3m5p6p7p8p9p2s2s3s4s5s4p+21',
  '7m8m9m3p4p5p6p6p3s4s7s8s9s+5s+12',
  '6m6m2p3p3p4p5p7p8p9p3s4s5s+1p+13',
  '3m3m4m5m6m2p3p4p1s2s3s4s5s6s+13',
  '4m5m3p4p5p6p7p8p2s3s4s8s8s6m+11',
  '6m6m5p7p8p8p8p5s6s6s7s7s8s6p+24',
  '2m3m4m4m5m6m2p3p2s3s4s4z4z4p+12',
  '4m5m6m5p6p7p7p8p1s1s5s6s7s6p+13',
  '2m2m3m3m6m6m7m7m1p1p9s9s7z7z+14',
  '1m2m3m7m8m8m8m3s4s5s6s7s8s+9m+22',
  '4m5m5m6m6m4p4p6p7p8p5s6s7s+7m+13',
  '2m4m2p3p4p4p4p4s5s6s2z2z2z+3m+22',
  '4m5m3p4p5p6p7p8p3s4s5s6s6s+6m+13',
  '4p4p6p6p7p7p8p8p1z1z2z2z3z+3z+12',
  '5m4p4p8p8p6s6s8s8s3z3z7z7z+5m+23',
  '2m3m4m5m6m3p4p5p7p8p9p9s9s+1m+21',
  '6m7m8m3p4p5p5p4s5s6s7s8s9s2p+12',
  '1p1p1p2p3p7p8p9p5s6s7s7s8s+9s+24',
  '2p3p4p7p8p9p9p9p4s5s5z5z5z6s+14',
  '4m5m6m6m7m8m2p2p3s4s4s5s5s+6s+23',
  '5m6m7m8m8m7p8p9p5s6s7s7z7z7z+14',
  '6m7m7m8m8m6p7p8p2s3s4s6z6z6m+13',
  '4m5m6m6m7m7m8m2p3p4p4s5s6s+4m+23',
  '5m6m7m3p3p3p4p6p7p7p2s3s4s5p+13',
  '6m6m5p6p7p1s2s3s7s7s1z1z1z+6m+14',
  '5m5m6m7m8m3p4p5p6p7p8p6s7s8s+22',
  '3m3m4m5m5m2p3p4p6p7p8p3z3z4m+11',
  '2p3p4p6p7p8p2s2s3s3s5s6s7s+2s+11',
  '1m1m2m2m4m4m1p1p2p3p3p8s8s+2p+12',
  '6m7m1p2p3p3p4p5p4s5s6s9s9s5m+13',
  '2m3m4m6m7m8m2p2p2s3s5s6s7s+4s+24',
  '7m7m1p1p6p6p5s5s2z3z3z4z4z+2z+21',
  '3m4m5m7m8m5p6p7p7p8p9p6s6s+9m+13',
  '3m4m5m6m7m8m3p4p5p6p6p5s7s+6s+24',
  '4m4m5m5m6m6m5p6p5s5s7s8s9s+7p+13',
  '1m1m3m4m6m7m8m7p8p9p6s7s8s2m+21',
  '1m1m4m4m4m3s4s5s5s6s7s1z1z+1z+11',
  '5m5m1p2p3p3p4p6p7p8p5s6s7s2p+11',
  '3m4m5m6m7m8m5p6p7p8p9p4s4s4p+11',
  '6m7m8m2p3p4p6p6p2s3s6s7s8s1s+22',
  '2m3m4m5m6m7m3p4p5p3s3s3s7s7s+22',
  '5m6m7m1p2p3p7p8p9p4s4s7s8s9s+14',
  '4m5m6m7m7m2p3p4p6p7p1s2s3s+8p+12',
  '6p7p8p2s3s5s6s7s8s8s7z7z7z+4s+23',
  '2m2m4m4m1s1s4s4s8s8s9s9s3z3z+21',
  '7m7m9m9m8p8p2s2s5s5s6s6s1z1z+12',
  '2p3p4p6p7p8p1s1s2s3s6s7s8s4s+12',
  '6m7m3p4p5p6p7p8p7s7s8s8s8s8m+13',
  '5m6m6m7m7m8m2p2p3p4p6p7p8p+2p+24',
  '2m3m4m6m7m8m3p4p5p7p7p4s5s3s+23',
  '2m2m8m8m1s2s3s4s5s6s7s8s9s+2m+11',
  '2m3m4m5p6p7p3s4s5s7s8s3z3z+9s+12',
  '2m3m5m6m7m2p3p4p2s2s5s6s7s1m+12',
  '2m3m4m5m6m7m3p4p5p6p7p5s5s+2p+23',
  '1p2p3p7p8p4s5s6s6s7s7s7s8s+9p+14',
  '1m2m3m7m7m3p4p5p7p8p9p5s6s+4s+12',
  '2m3m7m8m9m1p2p3p3p4p5p8p8p+4m+14',
  '1m2m2m5m5m9m9m4p4p5s5s2z2z1m+11',
  '2m3m4m5m6m6m7m7m8m8m9m4s4s+7m+24',
  '5m6m7m8m8m2p3p4p5p6p7p4s6s+5s+23',
  '1m2m3m8m8m2p3p4p3s4s6s7s8s+5s+12',
  '4m5m6m7m8m2p2p3p4p5p7p8p9p6m+21',
  '7m8m9m3p4p5p3s4s6s7s8s9s9s+2s+22',
  '4m5m5m6m6m3p3p7p8p9p1s2s3s+7m+23',
  '3m4m5m6m6m6m2p2p5s5s5s6s7s2p+21',
  '2m3m3m4m4m5m3p4p4p5p5p6p3s+3s+23',
  '4m5m6m7m7m6p8p3s4s5s6s7s8s+7p+14',
  '4m5m6m5p5p5p7p7p5s5s5s6s7s+5s+13',
  '1m2m3m4m5m6m7m7m5p6p5s6s7s+7p+14',
  '3m4m5m6p7p9p9p2s3s4s5s6s7s+8p+14',
  '3m4m5m5m6m7m6p7p4s5s6s7s7s5p+14',
  '6m7m8m3p4p8p8p3s4s5s7s8s9s2p+13',
  '3m4m5m4p5p6p7p7p3s4s5s6s7s+2s+12',
  '1m1m5m5m6m6m3p3p6p6p8p8p3z+3z+23',
  '1m3m1p2p3p4p5p6p7p8p9p3s3s+2m+13',
  '4m5m7m7m9m9m9m3p4p5p7z7z7z3m+14',
  '8m8m3p4p6p7p8p4s5s6s8s8s8s+2p+22',
  '5m6m6m7m7m8m4p5p8p8p1z1z1z+6p+13',
  '4m5m6m5p6p7p8p8p5s6s7s8s9s4s+14',
  '1m1m3p4p5p7p8p9p5s6s6s7s8s4s+21',
  '4m5m6m7m7m2p3p2s3s4s5s6s7s4p+22',
  '5m6m7m2p3p4p5p5p5s6s7s7s8s6s+11',
  '2m3m4m5m6m6m7m7m8m2p3p3s3s1p+11',
  '4m5m6m2p3p3p4p5p7p7p7s8s9s1p+13',
  '2m3m4m7m8m9m5p6p7p7s7s7z7z7z+11',
  '2m3m4m8p8p3s4s6s6s7s7s8s8s5s+14',
  '4m5m5m6m6m7m6p8p8p8p3s4s5s+7p+22',
  '2m3m4m6m8m6p7p8p3s3s4s5s6s+7m+22',
  '1p1p7p7p8p8p9p9p7s8s9s5z5z+5z+24',
  '3m3m6p6p4s4s7s7s1z1z5z7z7z5z+11',
  '7m7m8m8m3p3p7p7p1s5z5z7z7z+1s+13',
  '4m5m6m5p6p6p7p8p5s5s4z4z4z+4p+14',
  '6m6m2p2p2p6p6p7p7p8p3s4s5s+8p+22',
  '2m3m4m6m7m8p8p2s3s4s4s5s6s5m+24',
  '2m3m4m5m6m7m1p1p4p5p6p6p7p+8p+11',
  '1m2m3m4m4m6m7m8m3p4p7p8p9p+2p+11',
  '2m2m3m4m5m6m7m7m8m8m9m2s3s4s+22',
  '3m4m5m7m7m8m8m9m9m5p5p2s3s1s+12',
  '4m5m5m6m6m7m3p4p7p7p3s4s5s2p+12',
  '7m7m9m9m1p1p2p3p3p7p7p2s2s+2p+23',
  '8m8m3p4p5p7p8p3s4s5s7s8s9s+9p+22',
  '2m3m4m2s2s2s3s3s4s5s5s6s7s1s+23',
  '3m4m5m5p5p1s2s3s8s9s5z5z5z7s+22',
  '4m5m6m6m7m8m3p4p6p6p6s7s8s+2p+12',
  '3m3m3m5m7m1p1p5p6p7p5s6s7s+6m+11',
  '1m2m3m5m5m6m7m7m4p4p6s7s8s+6m+21',
  '4m5m6m4p5p6p4s5s6s6s7s7s8s+4s+12',
  '7m8m9m2p3p2s3s4s5s5s6s7s8s+4p+14',
  '2m3m4p4p6p7p8p2s3s4s4s5s6s4m+21',
  '6m6m9m9m9m4p4p4p8p8p8p8s8s6m+21',
  '2m3m4m5m5m2p2p2p4p5p5s6s7s+6p+13',
  '7m8m4p4p6p7p8p2s3s4s5s6s7s+9m+12',
  '4m5m6m2p2p1s2s3s3s4s5s5s6s+7s+14',
  '2p3p3p4p4p5p3s4s5s6s7s8s8s+5s+14',
  '3m4m8m8m5p6p7p3s4s5s6s7s8s+5m+14',
  '1p1p2p3p4p4p5p6p6p7p5s6s7s8p+24',
  '2m3m4m9m9m3p4p5p2s3s7s8s9s+1s+11',
  '6m7m8m1p2p3p4p6p7p8p9p3z3z+5p+12',
  '5m5m6m7m8m9m9m9m5p6p7p5z5z5z+12',
  '3p4p4p4p5p6p7p8p3s4s4s5s6s+2s+23',
  '7m8m9m4p5p6p5s5s6s6s7s8s8s7s+11',
  '2m3m4m6m7m2s3s4s5s6s7s2z2z+8m+14',
  '3m4m5m3s4s5s7s8s9s1z1z6z6z1z+11',
  '2m4m5m6m6m7m7m8m4p4p2s3s4s3m+24',
  '3m5m6m7m9m9m9m3s3s4s4s5s5s+3m+12',
  '2m3m4m3p4p5p5p6p7p2s3s9s9s4s+13',
  '3m4m5m5m6m7m8m8m2s3s4s7s7s+7s+12',
  '5m6m2p3p4p7p8p9p7s7s6z6z6z4m+12',
  '5m6m7m5p6p7p8p9p3s4s5s7s7s+4p+13',
  '2m3m4m5m6m7m5p6p6p7p8p3s3s4p+22',
  '3m4m5m4p4p5p5p6p3s4s5s9s9s6p+14',
  '2m3m4m5m6m7m5p6p8p8p5s6s7s+4p+14',
  '2m3m4m1p1p1p2p3p5p6p7p4s5s+3s+21',
  '2m3m4m4p5p6p4s5s7s8s9s4z4z+3s+13',
  '2m3m4m5m5m2p3p4p5p5p6p6p7p+1p+12',
  '2m3m4m5m6m9m9m1p2p3p1s2s3s+1m+24',
  '9m9m6p6p7p7p8p8p1s2s3s5s6s+7s+23',
  '1m2m3m2p3p4p7p8p9p5s6s7s7s4s+14',
  '2m3m4m5m6m7m4p5p6p4s4s5s6s+7s+13',
  '2m3m4m7m8m9m3p3p7p8p7s8s9s+9p+22',
  '1m2m3m3m3m4m5m6m2p3p4p3s4s+2s+24',
  '5m6m1p1p3p4p5p2s2s2s6z6z6z+7m+11',
  '5m6m4p4p4p4s4s5s5s6s6s7s7s+7m+11',
  '6m7m2p3p4p8p8p3s4s5s8s8s8s+5m+12',
  '4m5m6m8m8m7p8p9p2s3s4s6s7s+8s+12',
  '2m3m3m4m4m8m8m2s3s4s6s6s6s+2m+13',
  '5m6m7m1p2p3p5p6p7p6s7s2z2z+5s+21',
  '3m4m5m4p4p6p7p8p4s5s7s8s9s3s+21',
  '1m2m3m4m5m6m4p5p6p3s4s3z3z+2s+14',
  '4p5p6p1s2s3s4s5s6s7s9s1z1z8s+23',
  '7m8m9m4p5p6p7p7p2s3s4s6s7s5s+11',
  '1m2m3m8m8m2p3p4p5p6p4s5s6s+4p+14',
  '3m4m5m3p4p7p7p7p5s5s5s8s8s2p+11',
  '1m2m3m7m7m8m8m9m9m3p4p5s5s+2p+11',
  '1m2m3m4m4m5m6m7m2s3s7s8s9s+1s+12',
  '1m2m3m5m5m1p2p3p8p8p8p1s2s3s+12',
  '7m9m4p5p6p7p8p9p7s8s9s6z6z8m+23',
  '7m8m9m7p8p9p1s2s3s7s8s9s6z6z+24',
  '1m2m3m4m5m6m7m8m9m1p1s2s3s1p+13',
  '1m2m3m4m5m7m8m9m9p9p1s2s3s+6m+14',
  '4m5m6m1p1p3p4p7p8p9p3s4s5s+2p+21',
  '3m4m5m6p6p2s3s4s5s6s6s7s7s8s+22',
  '3m4m3p4p5p9p9p2s3s4s7s8s9s+5m+11',
  '2m3m3m3m3m4m6m6m7p7p7p6s8s7s+12',
  '1m2m3m1p1p4p5p1s2s3s5s6s7s3p+22',
  '7m8p8p9p9p1s1s3s3s5s5s6s6s+7m+23',
  '3m3m4m5m6m3p3p3p6p7p5s6s7s+8p+21',
  '5m6m7m4p5p6p6p7p2s2s6s7s8s8p+22',
  '4m5m6m7m2p3p4p5p6p7p2s2s2s7m+12',
  '1m1m2m3m4m4p5p2s3s3s4s4s5s3p+13',
  '1m2m3m4m5m3p4p5p2s2s4s5s6s+6m+24',
  '4m4m4m6p7p8p4s4s6s6s4z4z4z4s+12',
  '6m7m8m6p7p8p1s1s2s3s4s7s8s6s+14',
  '7m7m4p5p6p7p8p9p3s4s7s8s9s+2s+13',
  '3m4m5m5m6m3p4p5p1s1s5s6s7s4m+24',
  '1m2m3m4m4m5m5m6m5p6p7p9s9s3m+12',
  '5m5m5m4p5p8p8p4s5s5s6s6s7s6p+22',
  '1m1m5p5p6s6s8s8s9s9s4z7z7z+4z+12',
  '5m5m6m6m6m3p4p5p7p8p1z1z1z9p+12',
  '5m6m7m2p2p5p5p5p5p6p7p6s7s+8s+11',
  '2m3m4m6m6m4p5p1s2s3s3s4s5s6p+13',
  '1p1p4p5p1s2s3s4s5s6s7s8s9s+3p+14',
  '4m5m6m7m7m7m3p4p5p6p4s5s6s3p+21',
  '6p7p8p9p9p1s2s4s5s6s7s8s9s+3s+21',
  '3m4m4m5m5m8m8m2p3p4p5s6s7s3m+22',
  '4m4m5m6m6m4p5p6p7p7p5s6s7s5m+22',
  '1m2m3m4m5m6m1p1p7p8p9p3s4s+2s+14',
  '2m3m4m1p2p3p7p8p9p5s6s2z2z+4s+14',
  '3m3m4m5m6m5p6p3s3s3s4s5s6s7p+24',
  '5m6m7m3p3p4p4p5p5p7s7s4z4z+4z+14',
  '2m2m2m3m4m2p3p2s3s4s9s9s9s4p+14',
  '4m5m6m7m7m8m9m9m1p1p1p2p2p+8m+13',
  '1m2m3m4m5m6m3p4p1s2s3s6s6s+5p+11',
  '2m3m4m5m6m7m2p3p2s3s4s9s9s1p+14',
  '3m4m5m5p5p6p7p8p5s5s6s6s7s7s+12',
  '6m7m8m3p4p5p6p7p2s3s4s5s5s2p+14',
  '1m2m3m5m6m7m3p4p2s2s7s8s9s+2p+22',
  '1m2m3m5m6m7m7m8m9m6p6p4s5s+3s+21',
  '2m3m4m6m7m1p2p3p5p5p4s5s6s+5m+12',
  '5m6m1p2p3p3s4s5s5s5s1z1z1z+4m+21',
  '2m2m4m4m6m7p7p8p8p1s1s7s7s6m+22',
  '2m2m4m6m2p3p4p4p5p6p3s4s5s+5m+12',
  '5m5m6m6m7m7m1p2p3p7p8p3s3s+9p+11',
  '2p2p2p4p4p5p5p6p6p1s1s1z1z+1z+13',
  '8m8m2p3p4p5p6p6p7p7p8p2s3s4s+22',
  '4m5m6m7m8m3p4p5p6p7p8p9s9s6m+22',
  '6m7m8m1p2p3p5p6s6s7s7s8s8s+5p+21',
  '4p5p7p8p9p4s5s6s6s7s8s8s8s6p+22',
  '5m6m7m7m7m5p6p7p6s6s7s7s8s+5s+23',
  '2m2m3m4m7m8m9m1p2p3p5p6p7p+2m+24',
  '4m4m6m6m7m7m4s4s1z3z3z6z6z+1z+12',
  '1m2m3m3m3m4m4m5m1p2p3p4s4s5m+12',
  '3m3m2p2p2p3p3p4p5p6p4s5s6s3m+21',
  '5m5m8m8m5p5p4s4s5s5s6s7s7s+6s+12',
  '4m5m6m3p3p4p5p6p3s4s6z6z6z+5s+12',
  '3m3m5m6m7m5p6p7p4s5s6s7s8s9s+13',
  '5m6m7m3p4p5p7p8p2s2s7s8s9s6p+13',
  '3m3m6m7m3p4p5p7p8p9p4s5s6s5m+24',
  '6m7m2p3p4p7p8p9p5s6s7s8s8s8m+12',
  '1m2m3m5m6m9p9p2s3s4s5s6s7s+4m+13',
  '2m3m5m6m7m9m9m3p4p5p6p7p8p+1m+23',
  '3m4m6m6m7m7m8m8m6p6p1z1z1z5m+14',
  '4m5m6m7m7m3p3p3p3s5s7s7s7s4s+23',
  '1m2m3m3m4m5m5m5p6p7p3s4s5s5m+24',
  '1s1s4s4s4s5s6s7s8s9s3z3z3z7s+12',
  '2p3p4p6p7p2s3s4s6s7s8s3z3z5p+22',
  '1m2m3m5m5m5p6p7p8p9p7s8s9s+7p+13',
  '1m2m3m5m6m7m2p3p4p2s3s6s6s4s+12',
  '2m3m4m5m6m7m8m3p4p5p3s4s5s8m+11',
  '3m4m5m5p5p2s4s4s5s6s5z5z5z3s+12',
  '4m5m6m8m8m4p5p6p5s6s7s8s9s4s+11',
  '3m4m5m6m7m8m3p4p5p3s5s6z6z4s+12',
  '1m2m3m1p2p3p5p6p7p2s3s6s6s+1s+24',
  '5m6m6m7m8m2p2p2p6p6p2s3s4s7m+23',
  '3m4m5m6m7m8m3p3p4p5p6p5s6s7s+21',
  '1m2m3m2p3p6p7p8p1s1s5s6s7s4p+12',
  '1m2m3m3p4p7p8p9p7s8s9s4z4z+2p+21',
  '2m3m4m5m6m7p7p1s2s3s4s5s6s4m+11',
  '2m3m4m7m8m9m1p1p5p6p3s4s5s4p+11',
  '2m3m4m4p5p6p7p8p9p9p6s7s8s3p+12',
  '3m4m5m5p6p7p5s6s7s7s8s1z1z+9s+22',
  '3m4m6m7m8m7p8p9p2s3s4s1z1z+2m+22',
  '1m1m1m2m3m6m7m7m8m8m9m3p4p+2p+14',
  '4p5p7p8p9p1s2s3s3s4s5s4z4z3p+21',
  '5m5m5m6m7m3p4p5p2s3s4s7s8s+9s+11',
  '2m4m5m6m7m7p7p3s4s5s6s7s8s+3m+14',
  '1m2m3m1p2p3p4p4p7p8p9p1s2s3s+12',
  '4m5m6m7m8m9m3p4p4p4p6s7s8s5p+23',
  '2m2m3m4m5m2p3p4p2s3s4s5s6s+4s+13',
  '1m1m2m3m3m6s6s6s7s8s1z1z1z+2m+21',
  '3m3m5m6m6m6m7m3p4p5p6s7s8s3m+24',
  '2m3m4m2p3p4p5p5s5s6s6s7s7s+2p+13',
  '1m1m7m8m8m9m9m2p3p4p4s5s6s+7m+24',
  '5m5m7m7m3p4p5p6p7p8p3s4s5s7m+24',
  '1m2m3m3p4p6p7p8p2s2s7s8s9s5p+12',
  '6m7m8m4p5p6p5s6s7s8s8s7z7z+7z+12',
  '4m5m6m8m8m3s4s5s6s7s8s2z2z+2z+21',
  '4m5m6m6m7m8m4p4p4s5s7s8s9s+6s+14',
  '6m6m7m7m2p2p5p5p7p7p8p8p4z+4z+14',
  '2m3m4m6m7m8m2p4s5s6s2z2z2z+2p+22',
  '2m2m8m8m8m2p3p4p5p6p7p2s4s+3s+14',
  '3p4p5p5p1s2s3s5s5s6s6s7s7s2p+13',
  '4m4m7m8m9m1p2p3p4p5p5s6s7s+6p+22',
  '3m3m3m8m8m3p5p6p7p8p4s5s6s4p+23',
  '7m7m9m9m4p5p6p7s8s9s4z4z4z+7m+14',
  '1m2m3m5m6m7m8m9m2p2p1s2s3s+7m+23',
  '2m2m1p1p2s2s3z3z4z4z6z6z7z+7z+22',
  '8p9p9p1s1s3s3s7s7s9s9s5z5z+8p+12',
  '1m1m6m6m4p4p9p5s5s6s6s7s7s+9p+11',
  '6m7m8m5p6p3s3s3s4s5s6s7s8s4p+21',
  '5m6m7m1p2p3p6p7p8p9p9p3s4s2s+21',
  '3m4m5m7m8m9m1s1s2s2s3s4s4s+3s+14',
  '3m4m5m6m7m8m3p3p4p5p6p3s4s5s+12',
  '3m4m2p3p4p5p6p7p9p9p2s3s4s+5m+24',
  '4m5m6m7m7m2p3p4p6p7p7z7z7z8p+12',
  '1m2m3m3m3m1p2p3p6p7p7s8s9s5p+14',
  '2m3m4m5m6m7m7m8m9m1s1s6s7s8s+14',
  '2m3m4m6m7m8m4s5s7s8s9s3z3z+6s+21',
  '3m4m5m6m7m8m9m9m2s3s4s6s7s+5s+14',
  '2m3m4m1s2s3s4s5s6s8s9s1z1z+7s+12',
  '4m5m6m8m8m6p7p1s2s3s5s6s7s+5p+23',
  '1m1m9p9p2s2s3s3s8s8s4z4z7z7z+11',
  '9m9m3p4p5p7p8p4s5s5s6s6s7s+9p+14',
  '3m4m4m6m6m8m8m2p2p3p3p6p6p+3m+14',
  '1m2m3m3m4m5p6p7p4s5s6s7s7s+2m+11',
  '3m4m7m8m9m2p3p4p6p7p8p8p8p+2m+13',
  '1m2m2m3m3m4m2p3p6p7p8p5s5s+1p+12',
  '1m2m3m5m6m7m3p4p2s2s7s8s9s+2p+22',
  '2m3m4m7m8m3p4p5p6p7p8p6s6s9m+24',
  '4m4m5m5m6m3p4p5p6s7s8s1z1z6m+12',
  '4m4m6m7m8m1p2p3p5p6p7p4s5s3s+33',
  '2m4m5p6p7p2s3s4s5s5s6s6s6s3m+21',
  '3m5m7m7m8m8m9m9m4p5p6p9s9s+4m+22',
  '1m3m1p2p3p3p3p2s3s4s7z7z7z2m+21',
  '4p4p5p5p6p6p7p9p9p3s3s9s9s7p+21',
  '3m4m5m6p7p8p1s2s3s3s4s8s8s+5s+13',
  '3m4m5m6m6m1s2s3s4s5s6s7s8s9s+24',
  '1m2m3m5m5m7m8m7p8p9p3s4s5s+9m+11',
  '2m3m4m6m6m3p4p5p6p7p1s2s3s2p+22',
  '4m5m2p3p3p4p4p5p5p6p7p5s5s+6m+12',
  '3m4m6m6m6m3p4p5p2s3s4s8s8s+5m+21',
  '5m6m8m8m5p6p7p3s4s5s7s8s9s7m+11',
  '1m2m3m3m4m5m6p7p1s2s3s6s6s+8p+24',
  '3m3m6p6p9p9p5s5s7s7s2z5z5z+2z+12',
  '5m6m6m7m8m9m9m2p3p4p4p5p6p4m+23',
  '2m2m3m3m4m4m6m6m4p5p2s3s4s+3p+14',
  '3m3m4m4m4m5p6p7p2s4s4s5s6s3s+12',
  '3m4m5m6m7m8m1s1s2s3s3s4s5s+1s+33',
  '1m2m3m3m4m4m5m6m7m8m7z7z7z+1m+12',
  '2m2m6m7m8m3p4p5p6p7p6s7s8s8p+11',
  '6m8m4p4p5p6p7p2s3s4s4s5s6s7m+24',
  '2m2m6m6m2s3s3s5s5s8s8s5z5z+2s+21',
  '4m4m4m5m6m6m7m2p3p4p5s6s7s+6m+23',
  '3m3m3m1p1p2p2p3p3p5p6p7s7s+7p+21',
  '7m8m9m3p4p5p2s3s4s6s7s3z3z8s+12',
  '5m6m7m2p2p6p7p7p8p9p5s6s7s+5p+23',
  '6m7m8m4p5p6p7p8p5s6s7s8s8s+6p+11',
  '6m7m8m7p8p9p3s3s5s6s6s7s8s+7s+12',
  '2m3m4m1p2p3p4p5p7p7p7s8s9s6p+24',
  '8m8m1p2p3p5p6p7p7s7s7s7z7z7z+23',
  '3m4m5m5p6p7p8s9s9s9s6z6z6z+7s+11',
  '2p2p3p3p5p5p2s4s4s2z2z6z6z+2s+14',
  '6m6m6p6p9p9p3s3s9s3z3z5z5z+9s+13',
  '2m3m6m7m8m1p2p3p3s4s5s5s5s+1m+13',
  '2m2m2m3m4m5m2p2p6p6p6s7s8s+6p+12',
  '3m4m5m8p8p8p1s2s8s8s7z7z7z3s+11',
  '4m5m6m7m8m1p1p5p6p7p7s8s9s3m+22',
  '1m1m1m3m3m3m7m9m6p6p6p7p7p+8m+14',
  '4m5m6m2p3p4p7p8p9p6s6s7s8s+6s+21',
  '2m2m3m3m4m4m5m4p5p6p6s7s8s+5m+13',
  '3p4p5p6p6p6p2s3s4s6s7s7s7s5s+13',
  '7m8m4p4p6p6p7p7p8p8p5s6s7s9m+12',
  '5m6m7m8m8m1p2p3p4p5p3s4s5s+3p+13',
  '4m6m3p4p5p3s4s4s5s5s6s7s7s5m+12',
  '1m2m3m6m7m8m5p6p5s5s7s8s9s7p+13',
  '3m4m5m5m6m7m6p7p4s4s7s8s9s5p+22',
  '2m3m5m5m2p3p4p4p5p6p5s6s7s+4m+11',
  '3m4m5m3p4p5p5p6p7p3s4s6s6s+5s+24',
  '3m3m1p2p3p4p5p7p8p9p6s7s8s+3p+11',
  '2m2m3m4m5m6m8m6p7p8p8s8s8s7m+11',
  '1m2m3m4p5p6p8p8p2s3s5s6s7s+1s+12',
  '6m7m8m2p3p4p7p8p9p3s3s7s8s9s+11',
  '2m3m4m5m6m3p3p4p5p6p7s8s9s+1m+12',
  '1m2m3m7m8m9m5p6p7p4s5s9s9s+6s+12',
  '2m2m2m3m3m4p5p5p6p6p7p4s5s3s+21',
  '2m2m4m5m6m6m8m4p5p6p3s4s5s+7m+13',
  '7m8m9m2p2p3p4p5p6p7p4s5s6s2p+22',
  '1m2m3m4m5m5p6p7p7p8p9p7s7s6m+22',
  '2s3s4s4s5s6s6s6s3z3z3z7z7z+3s+12',
  '4m4m5m6m6m6m7m8m1s1s5s6s7s5m+12',
  '4m5m6m6m7m5p6p7p8p8p4s5s6s8m+24',
  '5m6m6m7m7m8m3p5p2s2s3s4s5s+4p+11',
  '1m2m3m5m6m7m2p2p3p3p4p7s7s+1p+14',
  '2m2m8m8m2p4p4p9p9p2z2z7z7z+2p+13',
  '3m3m9m9m1p1p2p2p6s6s4z4z7z7z+11',
  '6m7m8m2p3p4p4s4s4s5s7s8s9s6s+21',
  '4m5m6m2p3p4p5p6p6p6p3s4s5s+7p+13',
  '3m4m7m8m9m1s2s3s6s7s8s4z4z+5m+13',
  '4m5m9m9m1p2p3p6p7p8p4s5s6s+6m+12',
  '1m1m3m4m5m5p6p7p8p9p5s6s7s7p+23',
  '1m2m3m4m4m7m8m9m2p3p4p5z5z+5z+11',
  '2m3m4m2p2p7p7p3s3s3s6s7s8s+7p+11',
  '3m4m7m8m9m2p2p3p3p4p4p4z4z+2m+23',
  '5m6m7m3p4p5p6p7p3s3s6s7s8s8p+12',
  '9m9m9m3p4p5p4s5s6s4z4z6z6z+6z+13',
  '9m9m7p8p9p2s3s4s5s6s7s8s9s+1s+14',
  '4m5m4p5p6p6p7p8p9p9p2s3s4s3m+24',
  '6m7m8m4p5p7p8p9p2s3s4s5s5s+3p+22',
  '2m2m3m4m5m5m6m7m1s2s3s4s5s6s+13',
  '2m3m4m6m7m9p9p4s5s6s6s7s8s5m+11',
  '3m4m5m3p3p6p7p2s3s4s5s6s7s+5p+11',
  '3m3m3m4m5m6m2p4p6p7p8p2s2s+3p+23',
  '3m3m4m5m6m5p6p7p2s2s5s6s7s+2s+21',
  '2m3m3m4m4m5m1p1p3s4s4s5s5s6s+13',
  '5m5m7m7m7m4p4p6p7p8p2z2z2z+5m+12',
  '3m4m5m2p2p2p4p5p6p4s4s6s8s+7s+12',
  '5m5m7m1p1p2p2p7p7p9p9p4z4z+7m+12',
  '7m8m9m3p4p5p5p6p8p8p5s6s7s4p+11',
  '5m5m6m6m6m7m8m9m7p8p9p8s9s+7s+11',
  '6m6m2p3p4p7p8p4s5s6s7s8s9s+9p+13',
  '3m4m4m5m5m3p4p5p6p7p8p4s4s+3m+21',
  '3m4m5m6m7m1p2p3p3s4s5s7s7s2m+24',
  '3m4m5m6p7p8p4s5s6s6s7s7s8s+4s+14',
  '1m1m1p2p3p3p4p6p7p8p1s2s3s+2p+12',
  '1m2m1p2p3p4p5p6p1s2s3s8s8s3m+14',
  '5m6m7m7m2p3p4p7p7p7p5s6s7s+4m+11',
  '4m5m5m6m7m1p1p6p7p8p3s4s5s3m+21',
  '2m3m4m7m7m1p2p3p4p5p6p4s5s+3s+12',
  '1m1m3m3m4m4m6m4p4p5p5p4s4s6m+13',
  '3m4m5m2p3p6p7p8p3s3s6s7s8s+4p+14',
  '8m8m9m9m9m2p3p4p9p9p3z3z3z+9p+13',
  '5m6m7m1p2p3p4s4s5s6s6s7s7s8s+13',
  '2m3m7m8m9m1p2p3p1s2s3s9s9s+1m+11',
  '2m2m5m6m7m6p7p7p8p8p3s4s5s9p+11',
  '2m3m7m8m9m2p2p5p6p7p4s5s6s1m+13',
  '6m7m7m8m9m3s4s5s6s7s8s4z4z+5m+23',
  '6m7m4p4p5p6p7p2s3s4s6s7s8s+8m+23',
  '2m3m4m7m8m9m3p4p5p3s4s6s6s+2s+21',
  '1m1m7m8m9m7p8p9p2s4s7s8s9s3s+12',
  '4m4m6m7m8m5p6p4s5s6s5z5z5z+7p+24',
  '3m4m5m7m8m9m3p4p2s2s7s8s9s+2p+12',
  '2m3m4m5m7p7p8p8p9p9p6s7s8s+5m+13',
  '3m3m1p2p3p3p4p5p6p7p8p7s8s9s+24',
  '2m3m7p7p7p8p9p1s2s3s2z2z2z+1m+24',
  '1m1m1m2m3m7p8p9p1s2s3s4s5s6s+12',
  '5p5p6p6p7p7p5s6s7s8s7z7z7z8s+14',
  '1m1m6m6m2p2p1s1s9s9s2z5z5z+2z+11',
  '7m7m1p2p3p1s2s3s4s5s6s5z5z+5z+21',
  '3m4m4m5m5m6m3p4p1s2s3s2z2z+2p+14',
  '2m3m4m7m7m7m8m8m5p6p6z6z6z+7p+21',
  '1m2m3m7m8m9m5p6p7p9p9p4s5s+6s+22',
  '6m7m5p6p7p7p8p9p1s2s3s3z3z+5m+24',
  '1m2m3m3m4m5m7m8m9m6p7p8s8s+5p+13',
  '3m4m5m5m6m7m3p4p5p6s6s7s8s+9s+21',
  '3m3m4m5m6m8m8m8m5p6p3s4s5s+4p+24',
  '4m5m6m1p1p4p5p4s5s6s7s8s9s3p+22',
  '4m5m6m2p3p4p5p5p1s2s3s7s8s+9s+22',
  '6m7m2p3p4p5p6p6p7p7p8p5s5s+8m+21',
  '2m3m4m7m7m4p5p6p2s3s4s4s5s+6s+14',
  '4m5m6m1p2p3p6p6p7p8p2s3s4s+9p+13',
  '7m8m9m2p3p4p5p6p4s4s5z5z5z+1p+11',
  '8m8m1p2p3p4p5p5p6p6p7p2s3s4s+23',
  '7m7m9m9m1p2p2p6s6s8s8s9s9s+1p+11',
  '5m5m6m6m7m7m9m9m2p3p5s6s7s1p+11',
  '5m5m6m6m8m8m1p1p7s7s8s8s7z+7z+24',
  '2m2m2m5m6m7m5p6p8p8p2s3s4s+4p+24',
  '2m3m5m6m7m6p7p8p2s3s4s7s7s4m+12',
  '1m2m2m3m3m4m5m6m4p5p5p5p6p4m+24',
  '6m6m2p4p4p5p6p7p7p7p3s4s5s+3p+14',
  '5m6m1p2p3p2s2s3s4s4s5s5s6s+7m+13',
  '3m4m5m5m6m7m3p4p6s7s8s9s9s+5p+13',
  '2m2m6m6m9m9m1p1p3s3s9s9s4z+4z+12',
  '2m3m3m7m7m9m9m4p4p6p6p1z1z+2m+11',
  '1m7m7m3p3p6p6p3s3s5s5s6z6z+1m+13',
  '1m1m7m8m9m4p5p6p4s5s7s8s9s+3s+11',
  '2m2m5m6m7m1p2p3p7p8p7s8s9s+9p+22',
  '4m5m7p8p9p2s3s4s4s5s6s2z2z3m+11',
  '4m5m1p1p3p4p5p3s4s5s6s7s8s+3m+23',
  '4m5m6m6m6m7m8m9m3p4p7p8p9p+2p+14',
  '2m3m4m6m7m8m6p7p8p3s4s6s6s2s+23',
  '3m4m5m6m7m7m8m8m9m6p7p9p9p+8p+12',
  '6m7m7m8m9m4p4p7p8p9p7s8s9s+8m+12',
  '6m7m8m6p8p1s1s1s2s3s6s7s8s+7p+12',
  '1m2m3m6m7m8m1p2p3p2s3s1z1z+1s+21',
  '1m1m2m3m4m3p4p1s2s3s5s6s7s+2p+24',
  '1m2m3m8m9m7p8p9p7s8s9s7z7z7m+11',
  '3m4m5m5p6p1s1s3s3s4s4s5s5s4p+11',
  '5p6p7p8p8p2s3s4s6s7s7s8s8s+9s+14',
  '7m8m9m1p2p3p2s2s3s4s5s7s8s+9s+22',
  '3m4m5m5m5m2p2p2p3p4p5p6p7p+8p+23',
  '3m3m7m8m9m5p5p5p2s3s3s4s4s2s+12',
  '3m4m5m6m7m8m4p5p5s5s6s7s8s6p+13',
  '1m2m3m3m4m5m2s3s5s5s6s7s8s1s+23',
  '7m7m1p1p8p8p5s5s9s3z3z4z4z9s+11',
  '2m3m4m6m7m8m7p8p9p6s7s7s7s5s+12',
  '1p2p2p3p3p4p5p7p8p8p6z6z6z+6p+13',
  '1m1m1p1p8p8p2s2s4s4s8s8s5z+5z+12',
  '4p5p1s2s3s4s5s6s7s7s7s8s9s6p+14',
  '2m2m4m5m6m6m7m8m4p5p6p5s6s7s+11',
  '1m2m3m4m5m5m6m7m1p1p3p4p5p6m+22',
  '5m6m7m1p2p3p5p6p6p7p8p3z3z+4p+22',
  '6m7m8m2p3p4p1s2s3s1z1z7z7z+7z+11',
  '1m1m2m2m3m5m5m9p9p1s1s3s3s+3m+11',
  '3m3m3p4p5p1s2s3s6s7s7z7z7z8s+23',
  '4m5m6m3p3p4p5p6p1s2s4s5s6s+3s+13',
  '6m6m1p2p3p3p4p5p7p8p3s4s5s+9p+11',
  '4m5m6m6m7m7m7m8m3s4s6s7s8s+5s+11',
  '2m3m5m6m7m7m8m9m1p1p7p8p9p+4m+22',
  '5m5m5m3p4p5p1s2s2s3s3s8s8s+1s+12',
  '1p1p7p8p9p1s2s2s3s3s4s5s6s1s+23',
  '1m2m3m6m7m2p3p4p3s3s5s6s7s5m+14',
  '1m2m3m6m6m5p6p7p2s3s4s4s5s3s+23',
  '3m4m5m7m7m7m3p3p2s4s5s6s7s3s+13',
  '1m2m3m3p4p5p6p7p3s3s7s8s9s+5p+12',
  '3m3m4m5m6m7m8m9m1p2p3p7s8s6s+22',
  '1m2m3m5m6m7m2s3s6s7s8s3z3z4s+11',
  '2m3m4m1p1p2p3p3p5p5p3s4s5s+2p+22',
  '6m7m8m2p3p4p6p7p5s5s6s7s8s5p+11',
  '3m3m4m5m6m7m8m7p8p9p5s6s7s+9m+13',
  '6m6m5p6p7p4s4s5s6s7s8s8s8s+6m+14',
  '2m2m4m5m5p6p7p1s2s3s6s7s8s+3m+21',
  '2m3m4m6m7m2p2p4p5p6p6s7s8s5m+13',
  '5m6m7m8m8m3p4p4p5p5p6p8p8p8p+12',
  '3m4m5m6m3p4p5p2s3s4s8s8s8s+3m+21',
  '3m4m6m7m8m3s4s5s3z3z7z7z7z2m+22',
  '2m2m7m3p3p5p5p4s4s8s8s3z3z+7m+22',
  '9m9m4p5p6p7p8p9p5s6s7s8s9s+7s+13',
  '4m5m6m7m8m3p3p2s3s4s6s7s8s6m+24',
  '3m4m5m6m6m7m7m8m8m9m5p6p7p+6m+21',
  '3m4m7m7m2p3p4p6p7p8p4s5s6s2m+21',
  '1m2m3m1p2p3p5p6p7p5s6s4z4z+7s+23',
  '7m7m2p3p4p5p6p6p7p8p5s6s7s+4p+11',
  '2m2m2m6m7m8m5p6p8p8p8p4s4s7p+13',
  '4m5m6m7m8m3p4p5p8p8p2s3s4s9m+12',
  '2m3m4m3p3p4p4p5p6p6p2s3s4s+5p+13',
  '2m4m6m6m4p4p4p6p7p8p4s5s6s+3m+21',
  '4m5m6m5p6p7p7p8p9p1s1s4s5s3s+12',
  '3m4m5m7m7m5p6p7p2s3s6s7s8s1s+11',
  '1m1m7m7m5p5p7p7p3s3s1z2z2z+1z+11',
  '1m2m2m3m3m4m4p5p3s4s5s9s9s+6p+33',
  '3m4m5m6m6m1p2p3p3s4s6s7s8s+2s+11',
  '1s1s1s4s4s5s6s7s9s9s3z3z3z+9s+11',
  '4m4m5m5m6m6m3p3p3p5s5s2z2z+5s+14',
  '2m3m6m7m8m5p6p7p1s2s3s5s5s+1m+22',
  '3m4m5m6m7m8m3p4p1s1s2s3s4s+2p+12',
  '7m7m8m4p4p5p5p3s3s5s5s6s6s+8m+13',
  '6m7m8m3p4p5p7p8p9p2s3s6s6s+1s+24',
  '6m7m1p2p3p1s2s2s3s3s4s8s8s+5m+14',
  '6p6p8p8p8p1s2s3s9s9s9s1z1z+1z+11',
  '3m3m6m6m7m6p6p8p8p2z2z7z7z7m+12',
  '3m3m3m4p6p6p6p2s3s4s6s7s8s+5p+14',
  '5m5m6m6m7m5p6p7p4s5s6s9s9s7m+13',
  '7m8m8m8m9m1p2p3p7p8p1s2s3s+9p+23',
  '1m2m3m4m4m2p3p4p5p6p7p3s4s5s+14',
  '4m4m4m3p4p5p5p5s6s6s7s7s8s+2p+11',
  '1m2m3m5m6m7m7m8m7p7p4s5s6s9m+12',
  '3m4m5m9m9m4s4s4s6s6s7s7s8s8s+22',
  '2m2m3p4p5p6p7p8p4s5s7z7z7z6s+11',
  '5m5m6m7m8m1p2p3p3s4s5s5s6s4s+11',
  '1m2m3m1p1p2p2p3p3p1s3s4z4z2s+13',
  '3m4m6m7m8m3p3p3p4s4s4s5s5s2m+22',
  '1m2m3m4m5m6m1p1p5p6p6s7s8s7p+21',
  '3m3m6m6m7m7m8m8m5p6p7p5s6s4s+11',
  '3m4m5m5m6m7m6p6p2s3s4s5s6s+7s+21',
  '4m4m6m7m8m2p3p6p7p8p5s6s7s+1p+14',
  '1m2m3m4m5m6m2s3s5s5s6s7s8s4s+14',
  '5s6s7s8s8s8s9s9s5z5z6z6z6z+5z+11',
  '1m1m5m5m6m6m7m7m5s5s9s9s1z1z+22',
  '5m6m7m4p5p6p7p7s8s9s6z6z6z+7p+22',
  '1m2m2m3m3m4m3p3p5p6p7p2s3s+1s+12',
  '4m5m2p3p4p6p7p8p3s4s5s9s9s+3m+11',
  '2p2p5p6p6p2s2s4s4s7s7s4z4z+5p+12',
  '3m4m5m5m6m7m4p5p6p9p9p2s3s+4s+24',
  '2m3m6m7m8m2s2s3s4s5s7s8s9s4m+22',
  '1p2p3p4p5p7p8p9p2s2s4s5s6s3p+12',
  '7m8m8m8m9m1p2p3p2s3s4s7s8s6s+24',
  '4m5m5m6m6m2p2p7s8s9s5z5z5z+7m+13',
  '2m3m4m4m5m6m7m8m6p7p8p5s5s+9m+11',
  '2m3m3m4m5m2p3p4p5p5p2s3s4s4m+24',
  '4m5m6m7m5p5p5p6p7p8p3s4s5s7m+13',
  '3m4m5m1p1p4p5p6p3s4s6s7s8s+2s+21',
  '3p3p6p6p7p7p7s7s8s2z2z5z5z+8s+11',
  '1m2m3m4m5m2p2p5p6p7p1s2s3s+6m+14',
  '3m4m5m5m6m7m5p6p6p7p7p5s5s5p+13',
  '2m3m4m7m8m1p2p3p7p7p5s6s7s9m+22',
  '1p2p3p4p5p6p8p9p3s4s5s7s7s7p+23',
  '4m6m9m9m6p7p8p2s2s3s3s4s4s+5m+13',
  '5m6m7m3p5p2s2s3s4s5s6z6z6z+4p+13',
  '2m3m3m4m4m5m4p5p5s5s6s7s8s3p+13',
  '7m7m2p2p1s1s3s3s9s9s5z7z7z5z+11',
  '6m7m2p3p4p6p7p8p4s4s7s8s9s8m+13',
  '5m5m2p3p4p7p8p9p4s5s6s7s8s+9s+21',
  '1m2m3m4m5m2p2p4p5p6p7s8s9s+3m+24',
  '7m8m9m3s4s7s7s7s9s9s4z4z4z+5s+14',
  '2m2m2p2p6p6p9p3s3s6s6s8s8s+9p+13',
  '5m6m4p5p6p3s3s4s4s5s5s3z3z+7m+14',
  '3m4m5m5m6m2p2p3p4p5p7p8p9p+7m+21',
  '3m4m5m8m8m6p7p8p1s2s3s5s6s+7s+22',
  '1m2m3m4m5m6m6m7m8m3s3s6s7s+5s+22',
  '2m3m4m3p4p5p2s3s4s5s5s2z2z2z+21',
  '1m2m2m3m3m4m2p3p4p5p6p1s1s7p+23',
  '4m5m6m7m8m5p6p7p7p7p2s3s4s3m+12',
  '2m3m4m6m7m7m7m4p5p6p3s4s5s+8m+21',
  '3m4m5m7m7m6p7p8p3s4s5s6s7s+8s+13',
  '4m5m6m7m8m6p6p4s5s6s6s7s8s+3m+22',
  '2s2s4s4s4s5s6s7s7s8s4z4z4z9s+22',
  '5m6m7m3p4p5p5p5p7p7p2s3s4s2p+12',
  '2m3m4m7m8m7p8p9p1s1s3s4s5s9m+24',
  '4m4m5m6m7m2s3s4s7s8s9s3z3z+3z+13',
  '8m8m9m9m9m3p4p5p4z4z5z5z5z8m+24',
  '8m8m1p1p9p9p1s1s2s2s8s8s7z+7z+13',
  '6m6m3p4p5p6p7p5s5s5s6s7s8s+8p+21',
  '7m7m8m8m8p8p9p9p4s4s8s8s4z+4z+23',
  '3m2p3p4p2s3s4s5s6s6s7s7s8s+3m+12',
  '3m4m6m6m7m8m9m2s3s4s6s7s8s5m+11',
  '3m3m4m4m5m5m6m7m8m5p6p5s5s7p+22',
  '4m4m5m6m7m2p3p3p4p4p7s8s9s+5p+12',
  '7m7m8m8m9m9m1p2p3p5s5s8s9s7s+24',
  '2p3p4p3s3s5s6s7s7s8s9s3z3z+3z+13',
  '5m6m6m7m8m9m9m5p6p7p7s8s9s+4m+11',
  '3m4m5m5m6m7m5s6s7s8s9s4z4z+4s+13',
  '4m4m3p4p5p7p8p3s4s5s6s7s8s+6p+21',
  '4m5m7m8m9m2p3p4p9p9p3s4s5s+6m+24',
  '3m4m4m4m5m1s2s3s6s6s6s5z5z+5z+11',
  '3m4m5m5m6m2s3s4s5s6s7s8s8s+7m+21',
  '2p3p6p7p8p1s2s3s6s6s5z5z5z4p+23',
  '1m1m7m8m9m4p5p6p7p8p9p7s8s6s+11',
  '2m2m6m7m8m3p4p5p3s3s6s7s8s+3s+24',
  '2p3p4p5p1s2s3s5s6s7s1z1z1z+5p+14',
  '1m3m2p3p4p6s7s8s3z3z7z7z7z2m+14',
  '1m2m3m6p7p8p2s3s4s4s4s4s5s6s+22',
  '5m6m7m2p3p7p8p9p3s3s6s7s8s+1p+23',
  '2m3m4m5m5m1p2p3p7p8p9p4s5s3s+22',
  '5m6m7m7m3p4p5p4s5s6s6s7s8s4m+12',
  '7m7m2p2p3p3p4p5p6p7p4s5s6s+1p+13',
  '3m4m6m7m8m5p5p7p8p9p2s3s4s+2m+22',
  '1m1m7m7m2p2p3p3p5p3s3s4s4s5p+11',
  '7m8m1p2p3p1s2s2s3s3s4s8s8s+6m+21',
  '2m2m7m8m9m5p6p7p8p9p3s4s5s+7p+11',
  '2m3m4m4p6p2s3s4s6s6s6s7s8s5p+21',
  '4m4m3p4p5p6p7p8p3s4s6s7s8s+5s+13',
  '3m4m5m6m6m2p4p8p8p8p6s7s8s+3p+24',
  '5m5m6p7p8p1s2s3s5s6s1z1z1z+4s+11',
  '2m3m4m4m5m6m2p2p3p4p5p6s7s8s+24',
  '6m7m4p5p6p4s5s5s6s6s7s2z2z+5m+14',
  '3m4m5m2p2p6p8p3s4s4s5s5s6s+7p+21',
  '4m5m6m2p3p4p4p5p4s5s6s9s9s6p+22',
  '1m2m3m1p2p3p1s2s3s7s8s5z5z+6s+22',
  '2m3m4m5m6m2p2p7p8p9p6s7s8s+4m+24',
  '3m4m5m7m8m9m2p3p4p5p5p4s5s6s+22',
  '2m2m6m6m4s4s5s5s8s8s9s9s4z4z+12',
  '4m5m6m9m9m3p4p5p6s7s7s8s9s+8s+24',
  '4m5m6m8m8m2p3p4p6p7p8p5s7s6s+11',
  '1m1m1m2m3m2s3s4s5s6s7s8s9s4s+11',
  '3m3m5m6m5p6p6p7p7p8p1s2s3s4m+23',
  '2p3p3p4p4p5p5p6p7p8p9p2z2z+7p+12',
  '1s1s2s2s3s4s4s4s5s6s7s8s8s+3s+13',
  '2m3m4m6m6m5p5p6p6p7p7p2s3s+1s+11',
  '3m4m5m6m7m3p3p2s3s4s4s5s6s2m+23',
  '4m4m6m6m7m7m6p6p2s2s3s3s3z+3z+22',
  '6m7m8m7p8p7s7s8s8s9s9s6z6z9p+21',
  '1p1p2p2p3p3p5p6p7p7p7p6s7s+5s+11',
  '2m3m5m6m7m5p6p7p1s2s3s7s7s1m+12',
  '1m1m5m6m7m8m9m1s2s3s6s7s8s7m+24',
  '3m4m6p7p7p8p8p9p1s1s2s3s4s2m+11',
  '5m5m6p6p8p8p1s3s3s5s5s4z4z+1s+12',
  '2m3m4m4p5p9p9p2s2s3s3s4s4s+3p+12',
  '6m7m8m9m9m3p3p7p7p7p7z7z7z+9m+21',
  '3m4m5m2p3p3p3p4p8p8p2s3s4s+8p+11',
  '3m4m5p5p7p8p9p4s5s6s7s8s9s2m+22',
  '1m2m2m3m3m6m7m8m4p5p6p5s5s1m+13',
  '2m3m4m6p7p8p4s4s4s7s7s8s8s+8s+13',
  '6m7m8m3p4p5p3s4s5s3z3z5z5z+5z+11',
  '5m6m7m8m8m3p4p5p8p8p8p7s8s6s+12',
  '1m2m3m4m5m2p3p4p6p7p8p9p9p6m+12',
  '9m9m9m1p2p3p8p9p1s2s3s9s9s+7p+21',
  '2m3m4m5m6m7m2p2p4p5p4s5s6s6p+21',
  '1m1m2m3m3m3m4m5m7m7m4p5p6p2m+11',
  '6m6m8p8p9p9p3s3s4s4s3z7z7z+3z+13',
  '3m4m5m3p4p5p7p8p9p1s1s4s5s+3s+22',
  '3p4p5p6p7p3s4s5s7s8s9s9s9s8p+24',
  '2m3m4m5m5m5p6p6p7p8p2s2s2s+4p+14',
  '2m3m3p4p5p8p8p4s4s4s6s7s8s4m+12',
  '3m4m5m2p2p6p7p7p8p8p1s2s3s9p+11',
  '7m8m9m1p2p3p5p6p4s5s6s7s7s+4p+24',
  '4m5m6m3p4p5p5p8p8p8p4s5s6s2p+22',
  '1m2m3m4m5m6m3p3p3p4p5s6s7s5p+22',
  '7m8m9m5p6p7p2s3s7s7s6z6z6z+1s+22',
  '1m2m7m8m9m1s1s1s2s3s7s8s9s+3m+12',
  '2m4m3p3p7p8p9p6s7s8s2z2z2z3m+12',
  '6m7m8m2p3p4p7p8p1s2s3s8s8s6p+11',
  '3m3m4m4m1p1p3p3p5p5p7s7s1z+1z+13',
  '3m4m5m5m6m7m6p7p2s3s4s6s6s5p+13',
  '2m3m5m6m7m6p7p8p3s4s5s2z2z+1m+11',
  '1m2m3m4m4m4s5s6s7s8s3z3z3z+9s+13',
  '5m6m7m1p1p4p5p6p7p8p7s8s9s+3p+14',
  '1m2m3m3p4p5p2s3s3z3z5z5z5z+4s+12',
  '2m3m1p2p3p4p5p6p2s2s7s8s9s+4m+13',
  '5m5m6m6m7m7m2p3p4p7p8p7s7s+6p+13',
  '5m6m6m7m7m2s3s4s5s5s7s8s9s+5m+14',
  '2m3m4m3p4p5p6p7p8p3s4s4z4z5s+11',
  '3p3p7p7p8p8p9p1s1s1s6s7s8s+9p+14',
  '1m2m3m7m8m9m4p5p1s1s4s5s6s3p+12',
  '3m4m5m1s2s3s5s6s7s8s9s2z2z7s+31',
  '2m3m1p1p2p3p3p4p4p5p3s4s5s4m+22',
  '1p2p2p2p3p7p8p9p9p9p1z1z1z+6p+11',
  '3m3m3m4m5m6m4z4z4z5z5z7z7z7z+23',
  '1m2m3m5m6m6m7m8m5p6p7p9s9s+4m+11',
  '2p3p4p4p5p1s2s3s5s6s7s4z4z6p+13',
  '7m8m9m3p4p5p6p7p8p4s5s2z2z+3s+13',
  '2m3m4m3p3p5p5p5p6p7p8p5s6s4s+12',
  '3m4m5m1p2p5p5p6p6p7p7p4s4s+3p+12',
  '7m7m6p7p8p8p8p2s3s4s4s5s6s7m+13',
  '5m5m1p2p3p6p7p3s4s5s7s8s9s+8p+11',
  '5m6m7m8m8m8m5p6p7p6s7s2z2z5s+22',
  '2m2m5m6m6m7m7m4p5p6p3s4s5s+5m+12',
  '2m3m4m3p3p8p8p8p4s4s6s7s8s4s+11',
  '5m9m9m1p1p5p5p6p6p7p7p7z7z5m+14',
  '1p2p3p3p3p5p6p7p7p7p4z4z4z4p+13',
  '3p4p4p4p5p6p6p6p2s4s6s7s8s+3s+14',
  '3p4p6p7p8p1s1s5s5s6s6s7s7s+2p+11',
  '1m2m3m7m8m9m4p5p6p7p7p7s8s6s+23',
  '3m4m7m8m9m1p2p3p5s6s7s4z4z2m+21',
  '2m2m2m4m5m5p5p6p7p8p3s4s5s+3m+12',
  '5m6m5p6p7p4s5s5s6s6s7s8s8s7m+12',
  '1m2m3m5m6m7m8m9m5p5p5s5s5s4m+21',
  '8m9m9m5p5p6p6p7p7p2s2s6z6z8m+13',
  '7m8m9m3p4p1s2s3s4s5s6s7s7s5p+14',
  '1m2m2m3m3m9m9m9m2p2p4p5p6p+1m+21',
  '5m5m4p5p6p7p8p9p1s2s3s6s7s8s+13',
  '3m4m5m5m6m6m7m7m8m5p5p5s6s4s+22',
  '2m2m2m2p3p4p7p7p6s7s8s5z5z+5z+21',
  '5m6m3p4p5p6p7p8p9p9p6s7s8s4m+14',
  '1m2m3m7m8m9m3p4p5p5p3s4s5s+2p+22',
  '3m4m5m6m7m5p6p7p5s5s7s8s9s2m+14',
  '1m2m3m4m5m8m8m5p6p7p4s5s6s+3m+12',
  '1m1m4m5m5m6m6m7m8m9m4s5s6s+7m+13',
  '3m4m5m7p8p9p2s2s3s3s4s6s6s1s+13',
  '5m5m6m6m6m2p3p4p2s3s4s6s7s8s+13',
  '3m4m5m4p5p6p6p7p8p2s3s9s9s+1s+21',
  '1m2m3m4m5m6m6m7m2s3s4s7s7s5m+11',
  '2m3m9p9p4s4s5s5s6s6s7s7s7s+1m+13',
  '1m2m3m4m5m6m7m7m5p6p7p4s5s3s+11',
  '2m2m5m6m3p4p5p2s3s4s6s6s6s4m+23',
  '2p2p4p5p7p8p9p5s6s7s7s8s9s+6p+21',
  '2m3m4m2p2p2s3s4s7s8s1z1z1z6s+14',
  '3m4m5m5m7m4p5p6p8p8p4s5s6s+6m+22',
  '5m5m5m7m8m9m3p3p8s8s8s2z2z2z+22',
  '2m2m4m5m6m6p7p8p3s4s5s7s8s+6s+11',
  '9m9m4p5p6p7p7p7p4s5s6s7z7z+7z+22',
  '1m2m3m5m6m7m8m9m7p8p9p9s9s4m+24',
  '4m4m5m6m7m2s2s2s3s4s6s7s8s+4m+21',
  '2m3m4m5p3s4s5s7s7s7s4z4z4z+5p+24',
  '3m3m4m5m1p2p3p5p6p7p4s5s6s3m+13',
  '6m6m7m5p5p1s1s2s2s3s3s4s4s+7m+23',
  '3m4m5m6m8m8m8m2p3p4p4p5p6p7m+14',
  '2m3m4m6p7p8p9p9p3s4s5s6s7s5s+24',
  '1m1m8m8m8p8p7s7s1z1z3z3z7z+7z+13',
  '1m1m8m8m2p3p3p2s2s4s4s6s6s2p+23',
  '6m6m4p4p5p5p5p3s4s5s5s6s7s6m+14',
  '4m5m6m9m9m4p5p6p4s5s6s6s7s8s+23',
  '3m4m6m7m8m1p1p2s3s4s6s7s8s+2m+12',
  '6m8m8m8m3p4p5p5p6p7p5z5z5z+7m+21',
  '2m2m4m5m6m2p3p4p6p7p8p5s6s7s+12',
  '2m3m4m5m6m4p5p6p2s3s4s5s5s7m+13',
  '3m4m5m7m8m9m2p3p4p5p6p4z4z+7p+12',
  '3m4m5m4p5p6p4s5s6s7s7s8s8s7s+14',
  '3m4m5m3p4p5p7p7p1s2s3s6s7s5s+13',
  '1m2m3m6m7m8m3p4p5p2s2s4s5s3s+13',
  '3p4p5p6p7p8p5s6s7s2z2z7z7z+7z+11',
  '3p3p4p4p5p7p7p4s5s5s6s6s7s+5p+14',
  '5m7m5p5p6p7p8p2s3s4s5s5s5s+6m+14',
  '2m2m3m3m1p1p4p4p5p5p4s4s5s5s+22',
  '6m7m8m3p4p5p4s4s8s8s8s5z5z+5z+11',
  '5m5m7m7m9m9m8p8p5s5s7s9s9s+7s+13',
  '3p4p9p9p1s2s3s4s5s6s7s8s9s+5p+23',
  '1p2p4p4p8p8p8p2s2s3s3s4s4s+3p+11',
  '3m4m5m7m7m3p4p5p3s5s6s7s8s+4s+24',
  '2m2m3m3m3m7m7m7m2p3p4p4s6s5s+21',
  '4m5m2p2p2p6p7p8p4s4s6s7s8s+3m+12',
  '2m3m4m6m7m2p3p4p7p7p3s4s5s+8m+14',
  '3m3m5m6m6m7m7m8m5p7p5s6s7s+6p+22',
  '7m7m9m9m2p6p6p8p8p4s4s5z5z+2p+23',
  '4m5m6m7m8m9m6p7p7s7s2z2z2z8p+21',
  '4p5p6p3s4s5s6s7s2z2z4z4z4z2s+14',
  '3m4m5m1p2p3p4p4p1s2s3s7s8s9s+22',
  '3m4m5m3p4p5p6p7p8p5s6s7s8s+8s+12',
  '2m3m4m4m4m5p6p2s3s4s7s8s9s4p+21',
  '1m1m6m7m8m4p5p6p4s5s6s1z1z+1z+12',
  '3m4m5p5p6p7p8p2s3s4s5s6s7s5m+11',
  '2p2p5p6p7p2s3s2z2z2z5z5z5z4s+13',
  '3m4m5m6m6m7m7m8m6s7s8s3z3z+2m+22',
  '2m3m4m9m9m3p4p5p6p7p4s5s6s+8p+23',
  '1m2m5m6m7m1p2p3p4p4p1s2s3s+3m+23',
  '5p6p7p2s3s3s3s4s7s8s5z5z5z+6s+22',
  '6m6m7m7m8m8m2s4s5s6s7s8s9s+2s+23',
  '5m6m7m4p4p4p6p6p6p8p4s5s6s8p+11',
  '3m3m4m4m5m5m6m6m4p5p6p5s5s+6m+14',
  '1m2m3m5m5m7m8m9m2s3s7s8s9s1s+21',
  '2m3m4m3p4p5p6p7p3s3s5s6s7s+8p+12',
  '1m2m3m3m4m4m4m7m8m9m1p2p3p+5m+24',
  '3m3m7m8m9m2p3p4p2s3s5s6s7s4s+11',
  '3m5m5m5m2p3p4p5s6s6s7s7s8s+4m+14',
  '2m3m5m5m2p3p4p5p6p7p6s7s8s1m+12',
  '6m7m8m6p7p8p9p9p5s5s6s6s7s+4s+11',
  '3m4m5m8m8m4p5p6p7p8p4s5s6s9p+21',
  '4p4p6p6p7p7p3s3s1z6z6z7z7z1z+14',
  '5m6m7m1p2p3p5p5p4s5s6s7s8s+9s+21',
  '1m2m3m4m5m6m9m9m5p6p1s2s3s4p+11',
  '1p1p2p3p3p4s5s5s5s6s7s8s9s+2p+24',
  '6m6m4p5p6p6p7p7p8p9p2s3s4s+5p+22',
  '3m3m6m7m8m2p3p4p7p8p9p5s6s4s+13',
  '3m4m2p3p4p6p7p8p1s2s3s6s6s+5m+24',
  '3m3m6m6m3p4p5p3s4s5s6s7s8s6m+23',
  '4m4m2p3p7p8p9p2s3s4s4s5s6s+4p+14',
  '3m4m5m5m3p4p5p5p6p7p3s4s5s2m+13',
  '1m2m3m3m4m5m5m6m7m3p4p2s2s+5p+11',
  '6m7m8m2p3p4p5p5p7p9p5z5z5z+8p+14',
  '6m6m2p3p4p1s2s3s4s5s6s6s7s+8s+23',
  '4m5m6m3p4p5p6p6p6p7p8p3s5s4s+12',
  '7m8m9m2p3p4p6p7p1s1s5s6s7s+8p+13',
  '2m2m2m3m4m7m8m9m5p6p7s8s9s+7p+11',
  '5m5m6m6m7m2p3p4p6p6p3s4s5s+4m+13',
  '3m4m5m6m6m2p3p4p4p5p6p4s6s5s+12',
  '2m2m4m5m2p3p4p3s4s5s7s8s9s6m+11',
  '4m5m6m6m7m8m8p8p4s5s7s8s9s+3s+23',
  '4m4m4p5p6p1s2s3s4s5s6s8s9s7s+21',
  '2m3m4m5m6m3p4p5p5p6p7p5s5s+7m+12',
  '1m3m4m5m6m1p2p3p1s2s3s7s7s2m+11',
  '2p3p3p4p4p5p1s1s5s6s7s7s8s6s+12',
  '1p2p3p7p8p9p1s1s2s3s4s5s6s7s+12',
  '2m3m4m6m7m7m8m8m8m9m9m1z1z+7m+21',
  '3m4m4m5m6m5p6p7p3s4s5s9s9s2m+21',
  '3m4m4m5m5m4p4p1s2s3s6s7s8s6m+13',
  '2m3m4m8m8m5p6p6p7p7p8p4s6s+5s+11',
  '1m1m6m6m7m7m3p3p1s1s2s2s3z3z+13',
  '9m9m3p4p7p8p9p2s3s4s5s6s7s+2p+13',
  '4m5m6m3p4p4p5p5p6p5s6s8s8s+4s+11',
  '1m2m3m6p6p7p8p1s2s3s6s7s8s+9p+12',
  '4m4m6m7m8m4p5p7p8p9p2s3s4s+6p+12',
  '2m3m4m1p2p3p7p7p8p9p9p3z3z+8p+21',
  '2m3m4m7m8m9m7p8p8s8s1z1z1z6p+13',
  '3m3m7m8m9m4p5p6p7p8p9p7s9s+8s+21',
  '2m4m8m8m8m3p4p5p6s6s7s7s7s+3m+23',
  '4m5m8m8m3p4p5p5p6p7p7s8s9s6m+21',
  '2m3m4m8m8m3p4p5p6p7p8p4s4s+8m+12',
  '3m4m5m6m7m8m8p8p4s4s5z5z5z+4s+24',
  '2m8m8m2p2p2s2s3s3s6s6s3z3z+2m+21',
  '1m2m6m7m8m1p2p3p7p7p1s2s3s+3m+23',
  '1m2m3m7m8m9m1p1p1p1s3s5z5z2s+14',
  '3m3m3m1p1p2p2p4s5s6s7z7z7z+1p+14',
  '3m4m6m7m8m3p4p5p1s2s3s4z4z+5m+11',
  '1m1m2m2m3m3m7m9m7s8s9s1z1z+8m+12',
  '3m4m5m6m8m7p7p5s6s7s5z5z5z+7m+13',
  '1m2m3m5m6m4p4p7p8p9p4s5s6s+7m+22',
  '3m4m4m5m5m6m7m8m5s5s7s8s9s+3m+24',
  '5m6m7m8m8m4p5p6p2s3s4s5s6s+7s+14',
  '6p6p6p3s4s5s2z2z2z3z3z3z4z+4z+12',
  '4m4m5m6m7m1p2p3p2s3s4s5s6s1s+11',
  '1m2m3m7m8m4p4p5p6p7p4s5s6s+6m+21',
  '2m3m4m7m8m9m5p6p3s4s5s7s7s7p+24',
  '4m5m6m2p3p4p8p8p6s7s7s8s9s5s+11',
  '2m2m5m6m7m2p3p6p7p8p5s6s7s+1p+23',
  '5m6m7m4p5p6p7p8p9p2s3s5s5s+1s+13',
  '5m5m5m6m7m8m4p4p6p7p8p2s3s4s+11',
  '2m3m3m4m4m6m7m8m2p2p6p7p8p5m+14',
  '1m2m3m2p3p4p6p6p2s3s5s6s7s4s+13',
  '1m1m2m3m4m6p7p8p5s6s7s6z6z6z+22',
  '4m5m6m2p3p4p2s2s5s6s7s8s9s+7s+12',
  '3m4m5m5m6m9m9m3p4p5p4s5s6s4m+14',
  '1m2m3m4m5m7m8m9m5p6p7p2z2z6m+11',
  '1m2m7m8m9m1p2p3p7p8p9p2z2z3m+22',
  '3m4m2p3p4p3s4s5s7s8s9s4z4z2m+11',
  '2p3p4p6p6p7p7p8p3s4s5s6s6s+8p+12',
  '2m3m4m7m8m9m4p5p6p2s3s4s4s+1s+21',
  '1m2m3m2p2p5p6p7p1s2s3s7z7z+7z+13',
  '6m6m8m8m9p9p4s8s8s2z2z6z6z+4s+12',
  '6m6m6m6m7m8m5p6p8p8p5s6s7s+7p+11',
  '2m3m4m6m7m8m1p2p3p5p6p8p8p4p+11',
  '2m3m4m4m5m7p8p9p3s4s5s8s8s6m+11',
  '2m3m3m2p2p5s5s7s7s9s9s1z1z2m+11',
  '7m7m8m8m4s4s6s6s8s8s2z2z7z+7z+24',
  '2m2m3m3m8m8m9p9p3s3s7s7s5z+5z+11',
  '2m2m3m3m4m4m1p2p3p6p7p5s5s+5p+23',
  '1m1m6m7m2p3p4p3s4s5s6s7s8s8m+12',
  '6m7m7m8m8m9m6p7p8p7s8s6z6z6s+14',
  '1m2m3m5m6m7m3p4p7p8p9p3s3s+2p+22',
  '2m2m4m4m4m2p3p4p4p5p6p5s6s7s+12',
  '2m3m4m4m4m2p3p4p6p7p2s3s4s+5p+14',
  '3m4m5m3p4p2s3s4s5s6s7s8s8s+2p+22',
  '3m3m6m7m8m3p4p7p8p9p4s5s6s+5p+23',
  '2m3m4m5m5m6m7m8m2s3s3s4s4s5s+24',
  '2m3m4m7m8m9m7p9p3s3s7s8s9s8p+21',
  '3m3m4m6m3p3p4p4p5p5p7s8s9s+5m+13',
  '2p2p2p4p5p5p6p6p7p7p8p8p9p+8p+22',
  '5m6m7m8m8m8m4p5p8p8p5s5s5s3p+13',
  '2m3m4m7m8m2p2p6p7p8p6s7s8s6m+13',
  '6m6m6m8m8m4p5p6p7p7p7p8p8p+8p+21',
  '3m5m6p6p7p7p8p8p5s6s7s5z5z+4m+13',
  '4m5m6m6p6p4s5s5s6s6s2z2z2z4s+13',
  '2m3m4m6m7m3p4p5p3s3s3s6s6s+5m+23',
  '4m5m6m7m8m9m2p3p4p3s4s8s8s+2s+24',
  '3m4m5m5m6m7m5p6p2s3s4s8s8s+7p+14',
  '1m2m3m5m5m2p3p4p4p5p6p4s5s3s+24',
  '8m8m5p5p5p6p7p8p2s2s3s4s4s+3s+11',
  '1m2m3m9m6p7p8p5s6s7s6z6z6z+9m+11',
  '5m6m7m7m8m2p2p2s3s4s4s5s6s9m+12',
  '2m3m4m4m5m4p5p6p2s3s4s3z3z+3m+22',
  '4m4m6m7m1p2p3p5p6p7p5s6s7s5m+13',
  '3m3m4m4m5m5m1p1p6p7p8p8s8s+8s+14',
  '3m4m5m7m8m9m4p5p5p6p7p9p9p6p+24',
  '2m2m3m4m5m3s4s5s5s6s6s7s8s+7s+21',
  '3m4m5m3p4p3s4s5s6s6s3z3z3z5p+24',
  '2m3m4m2p3p4p5p6p7p3s4s7s7s5s+24',
  '1m2m3m3m4m5m5m6m4p4p7s8s9s7m+24',
  '3m3m5m6m7m3p4p5p4s4s6s7s8s4s+12',
  '2m3m3m3m4m6m7m5p6p7p5s6s7s+5m+24',
  '1p1p2p3p4p6p7p8p4s5s6s7s8s3s+11',
  '4m4m4m2p2p2p3p4p2s3s4s7s8s+6s+12',
  '4m5m6m6m7m8m6s6s7s7s8s9s9s+8s+23',
  '2m3m4m7m7m5p6p7p4s5s7s8s9s+3s+11',
  '2m3m4m6m7m8m3p4p5p7p8p2z2z6p+14',
  '4m5m6m6m8m3p4p5p6p6p6s7s8s+7m+11',
  '4m4m5m5m3p4p4p7p7p4s4s6s6s+3p+12',
  '3p4p5p6p7p8p9p9p3s4s5s7s8s+9s+13',
  '2m2m3m4m4m5m5m6m1p2p3p4s5s3s+21',
  '4m5m6m6m7m4s4s6s6s7s7s8s8s+8m+13',
  '4m5m6m5p7p2s3s4s5s6s7s8s8s6p+12',
  '5m3p4p5p1s2s3s4s5s6s7s8s9s+5m+12',
  '6m7m8m1p2p3p3p4p4s5s6s2z2z+2p+13',
  '5m5m6m6m5p6p7p7s8s9s1z1z1z+6m+13',
  '1m2m3m6m7m8m1p2p3p6p7p4z4z+5p+13',
  '5m5m6m7m8m4p5p2s3s4s6s7s8s+3p+22',
  '1m2m3m8m8m3p4p2s3s4s5s6s7s2p+22',
  '5m5m6p8p3s4s5s6s7s8s1z1z1z+7p+12',
  '4m5m6m3p3p3p6p7p2s2s2s4s4s+5p+12',
  '2p2p2p3p4p6p7p7p7p7p8p8p9p+5p+13',
  '8m8m6p6p4s4s2z2z3z5z5z7z7z3z+13',
  '4m5m6m2p3p4p6p7p8p1s1s6s7s+8s+22',
  '2m4m9m9m5p6p7p2s3s4s6z6z6z+3m+12',
  '3m4m5m3p4p1s2s3s5s6s7s7s7s+5p+24',
  '4m6m9m9m6p6p7p7p8p8p4s4s4s5m+13',
  '5m6m7m8m8m2p3p4p6p7p8p3s4s+2s+22',
  '2m2m2m6p7p8p2s2s4s5s6z6z6z3s+11',
  '3m4m5m6m2p3p4p5p6p7p2s3s4s+3m+13',
  '5m5m5m8m8m3p4p5p2s3s4s6s8s+7s+22',
  '2m2m5m6m7m2p3p4p7p8p7s8s9s+9p+12',
  '6m7m8m9m9m7p7p7p1s1s2s3s3s+2s+24',
  '8m8m2p2p2p3p4p5p5p7p5s6s7s6p+14',
  '1m2m3m3m4m5m7m8m9m4p4p7p8p+9p+21',
  '7m8m9m5p5p9p9p9p6s6s7s8s8s+7s+23',
  '1m2m3m5m5m1p2p3p1s3s6s7s8s+2s+24',
  '1m2m3m4m5m6m2p3p4p5p5p4s5s+6s+23',
  '2m3m4m2p3p2s2s4s5s6s6s7s8s+4p+14',
  '1m1m3p4p5p7p8p1s2s3s5s6s7s9p+11',
  '3m4m7m8m9m2p2p5p6p7p1s2s3s+2m+13',
  '3m4m5m5m7m2p3p3p3p4p4s5s6s+6m+11',
  '5m6m7m7m8m9m3p4p5p5p7z7z7z2p+13',
  '7m8m1p2p3p5p6p7p9p9p2s3s4s+9m+23',
  '2m3m4m5m6m7m1p2p3p2s3s3z3z+1s+21',
  '1m2m3m4m5m6m2p3p4p5s6s8s8s4s+14',
  '2m4m4m5m6m7m7m7m5p5p2s3s4s3m+14',
  '4m5m6m7m8m9m1p1p3p4p4p5p5p+3p+22',
  '4m5m6m7m8m9m4p5p4s5s6s7s7s+6p+24',
  '2m2m7m8m9m1p2p3p4p5p7p8p9p3p+21',
  '2m3m3m4m4m7p7p1s1s2s2s3s3s+2m+22',
  '7m7m4p4p5p5p6p1s1s1s3s4s5s6p+12',
  '1m2m3m5m6m2s3s4s7s8s9s3z3z+4m+11',
  '2m3m4m5m6m7m8m8m6p7p4s5s6s5p+12',
  '1m2m3m7m8m1p2p3p5p6p7p5s5s+9m+13',
  '6m4p4p5p5p6p6p7p7p8p8p7s7s+6m+11',
  '4m5m6m1p2p3p6p7p8p7s8s4z4z+6s+12',
  '5m6m7m1p1p2p2p3p1s1s6s7s8s+3p+11',
  '3m4m5m8m8m3p4p5p7p8p3s4s5s6p+24',
  '1m2m3m7m8m2p3p4p5p5p3s4s5s+9m+22',
  '1m1m3m4m4p5p6p6p7p8p2s3s4s+2m+23',
  '2m3m4m5m6m8m8m3p4p5p6p7p8p+7m+22',
  '4m4m5p6p7p2s2s3s3s4s5s6s7s1s+12',
  '5m6m7m7m8m9m5p6p7p8p8p5s7s+6s+11',
  '7m7m3p4p1s2s3s4s4s5s5s6s6s2p+22',
  '2m3m1p2p3p6p7p8p1s2s3s6s6s4m+23',
  '2m3m5m6m7m2s2s3s3s3s2z2z2z4m+12',
  '1m2m3m5m6m7m7p8p9p1s1s3s4s+5s+12',
  '2m3m4m5m5m3p3p4p5p5p6s7s8s+4p+14',
  '2m3m3m3m4m6p7p1s2s3s4s5s6s+5p+13',
  '3m4m5m7m8m2p3p4p6p6p6s7s8s+9m+12',
  '3m4m2p2p3p4p5p6p7p8p7s8s9s2m+13',
  '4m5m6m6m6m7m8m9m2p3p4p4s5s+3s+14',
  '3m4m5m6m7m8m2p3p4p5p4s5s6s2p+23',
  '1m2m3m3m4m5m3s3s5s6s6z6z6z+7s+11',
  '5m6m7m2p3p4p7p8p5s5s6s7s8s6p+24',
  '7m8m2p3p4p5p5p6p7p8p6s7s8s9m+14',
  '3m4m4m5m5m6m7m7m3p4p5p3s4s+5s+13',
  '1m2m2m3m3m4m5m6m6m6m7m6p7p8p+21',
  '7m8m7p8p9p2s3s4s7s8s9s4z4z9m+14',
  '4m5m6m8m8m1p2p3p3s4s6s7s8s+5s+11',
  '2p3p6p7p8p9p9p2s3s4s6s7s8s+1p+21',
  '4m5m6m9m9m6p7p3s4s4s5s5s6s5p+21',
  '1m2m3m4m4m2p3p6p7p8p1s2s3s+4p+13',
  '2m3m4m6m7m3s3s3s4s4s5s6s7s5m+14',
  '2p3p4p5p5p6p7p8p3s4s5s7s7s+5p+12',
  '4m5m6m5p6p7p4s5s7s7s7s8s9s+3s+12',
  '2m3m4m5m5m2p3p4p7p8p4s5s6s9p+13',
  '2m2m2m3m4m5m6m7m2s3s5s6s7s1s+23',
  '2m3m4m5m6m1p1p5p6p7p5s6s7s+7m+22',
  '5m6m6m7m7m8m3p4p5p7p8p1s1s+6p+14',
  '3m4m6m6m8p8p8p2s3s4s5s6s7s5m+13',
  '1m2m3m3m4m5m1p2p3p6p6p2s3s+1s+21',
  '7m7m8m8m8m4p5p6p3s3s4s4s5s5s+11',
  '6m7m8m1p2p3p2s2s3s3s3s2z2z2z+24',
  '2m2m3m3m4m5m6m7m7m8m9m2z2z1m+21',
  '2m3m4m5m5m2p3p3p4p4p5s5s5s2p+11',
  '1m1m2m3m3m1s1s2s3s4s7s8s9s+2m+12',
  '1m2m3m7m8m9m5p6p2s3s4s6s6s+4p+21',
  '2m3m3m4m4m2p3p4p5p5p2s3s4s+5m+13',
  '3m4m5m5p6p7p2s3s3s3s4s4s5s6s+22',
  '3m4m5m5m6m7m4p4p4p5p6p3s4s2s+14',
  '3m4m5m4p5p3s4s5s6s7s8s6z6z3p+22',
  '5m5m6m6m9m9m2p2p9p9p5s8s8s5s+14',
  '4m4m4m5m5m6m7m8m6p7p8p7s7s+5m+32',
  '5m6m7m1p2p3p4p5p6p2s2s5s6s4s+11',
  '4m5m6m7m8m8m8m5p6p7p2s3s4s4m+14',
  '1m2m3m5m5m6m7m7m8m9m3s4s5s+5m+13',
  '1m1m7p7p8p8p3s3s4s4s5s5s9s+9s+12',
  '2m2m5m6m7m3p4p6s7s7s8s8s9s+5p+11',
  '2m2m2m3m4m2p2p5s5s5s1z1z1z5m+23',
  '5m6m7m4p5p5p6p6p6p7p8p4s4s7p+13',
  '3m3m9m9m8p8p9p9p7s7s4z6z6z+4z+23',
  '9m9m3p3p6p6p7p7p3z4z4z5z5z3z+23',
  '5m6m7m9m9m2p3p6p7p8p2s3s4s4p+13',
  '2m2m5p6p1s2s3s7s8s9s6z6z6z+4p+13',
  '1p2p3p3s4s5s7s8s9s3z3z6z6z+6z+23',
  '2m3m4m7m8m9m2p3p1s1s4s5s6s+4p+13',
  '6m7m2p2p6p7p8p3s4s5s7s8s9s+8m+21',
  '4m5m5m6m6m7m4p5p5p6p6p7p5s5s+11',
  '1m2m3m5m6m7m7p8p1s2s3s6s6s+9p+11',
  '1m1m4p5p1s1s2s2s3s3s8s8s8s+3p+11',
  '4m5m6m7m8m2p3p4p2s2s3s4s5s9m+12',
  '4m5m6m7m8m9m5p6p7p4s4s6s7s+5s+23',
  '2m2m5m6m7m1s3s4s5s6s7s8s9s+2s+14',
  '4m4m6m8m2s3s4s4s5s6s6s7s8s+7m+22',
  '2p3p4p5p5p5p7p8p3s3s3s6s6s6p+22',
  '2p2p4p5p6p7p8p9p2s3s4s5s6s4s+12',
  '2m3m3m4m4m1p1p7p8p9p3s4s5s+5m+14',
  '3p4p5p5s6s6s7s7s8s5z6z6z6z+5z+23',
  '5m6m7m5p5p7p8p9p1s2s3s5z5z+5z+12',
  '3m4m5m8m8m4p5p6p7p7p7p4s6s+5s+23',
  '8m8m5p5p7p7p8p8p7s7s9s9s5z+5z+13',
  '1m2m3m5p6p1s2s3s7s8s9s9s9s4p+22',
  '5m5m8m8m6p6p6p3s4s5s6s7s8s5m+23',
  '2m3m3m4m4m6m6m1p2p3p5p6p7p+5m+22',
  '2m3m4m6m7m8m5p6p7p4s5s5s5s3s+11',
  '7m8m3p4p5p5p5p2s3s4s7s8s9s9m+11',
  '5m6m6m6m7m5p6p6p7p7p8p4s6s+5s+12',
  '3m3m4m5m6m5p5p5p7p7p5s5s5s+7p+24',
  '2p3p5p6p7p2s3s4s5s6s7s9s9s1p+11',
  '5m6m7m2p3p4p2s3s4s4s5s6s6s+3s+24',
  '8m8m9m9m1p1p8p8p7s7s3z3z5z+5z+12',
  '7m8m9m2p2p3p4p4p5p5p6p7p8p+9p+12',
  '3m4m5m4p4p5p6p7p2s3s4s5s6s+1s+23',
  '2m3m4m2p3p4p9p9p2s3s4s9s9s9s+24',
  '1p1p5p6p7p1s2s2s3s3s6s7s8s1s+14',
  '4m5m6m5p6p7p9p9p5s6s7s4z4z4z+14',
  '5m6m4p4p4p5p6p7p2s2s5s6s7s+7m+14',
  '2m3m5m6m7m1p2p3p9p9p3s4s5s+4m+23',
  '3m3m3m4m5m6m4p5p7p7p4s5s6s+6p+11',
  '3m4m5m3p4p5p6p8p3s4s5s7z7z+7p+14',
  '5m6m6p6p3s4s4s5s5s6s7s8s9s+4m+24',
  '1m1m2m3m3m4m5m6m1p1p4p5p6p+2m+22',
  '3m3m7m8m9m7p8p2s3s4s5s6s7s6p+24',
  '1m2m3m7m8m5p6p7p7p7p3s4s5s6m+23',
  '7m8m9m4p5p7p7p1s2s3s3s4s5s6p+14',
  '4m5m1p1p4p4p4p3s3s4s4s5s5s+3m+23',
  '4m5m6m2p3p4p5p5p2s3s4s8s8s+8s+21',
  '6m7m5p5p1s2s3s5s6s7s7s8s9s8m+22',
  '3m4m5m7m8m6p7p8p2s3s3s3s4s+9m+22',
  '4m5m6m3p3p3p4p4p4p8p8p4s5s+3s+21',
  '5m6m7m7m8m4p5p6p2s3s4s8s8s9m+22',
  '5m5m6m6m8m4s4s5s5s8s8s2z2z8m+24',
  '7m8m2p3p4p3s4s5s5s6s7s4z4z+9m+23',
  '3m3m4m4m4p4p3s3s4s4z4z5z5z+4s+22',
  '5m6m7m6p7p8p2s3s4s7s7s6z6z6z+12',
  '5m6m3p3p5p6p7p2s3s4s7s8s9s+4m+24',
  '2m3m4m6m7m8m5p6p4s5s6s9s9s7p+24',
  '3m3m5m6m7m1s2s3s6s7s1z1z1z+5s+12',
  '2m3m6p7p8p3s4s5s6s7s8s1z1z+1m+24',
  '3m3m4m5m6m3p4p5p6p7p8p3s4s2s+23',
  '4m4m5m5m6m6m5p7p1s1s6s7s8s6p+11',
  '3m4m5m7m8m9m6p7p1s1s6s7s8s8p+24',
  '4m4m1p2p3p2s3s5s5s6s6s7s7s4s+21',
  '1m2m3m6m7m8m2p3p4p4p4p7p8p+9p+24',
  '3m4m7m8m9m5p5p6p6p7p7p6s6s5m+22',
  '2m3m4m5p5p1s2s3s5s6s6s7s8s+7s+23',
  '4m4m7m8m9m3p4p5p7p8p9p5s6s+4s+12',
  '5m5m1p1p3p3p9p9p3s3s2z6z6z+2z+12',
  '5m5m5p6p7p1s2s3s3s4s5s6s7s5s+12',
  '2m2m3m3m1p1p5p5p6p6p9s9s7z+7z+12',
  '2p3p4p1s1s3s4s5s9s9s3z3z3z+1s+13',
  '1m2m3m6m7m8m2p3p5p5p1s2s3s+1p+21',
  '6m7m8m2p3p6p7p8p6s7s8s2z2z4p+12',
  '2p3p2s2s3s4s5s5s6s7s7s8s9s4p+23',
  '3m4m5m6m6m3p4p5p1s2s2s3s3s4s+14',
  '2m3m4m2p3p4p5p6p7p2s2s5s6s7s+13',
  '3m4m5m6m6m7m7m8m2s3s4s9s9s5m+22',
  '2p2p2p3p5p6p7p4s5s5s6s6s7s1p+24',
  '4m5m6m5p6p8p8p4s5s6s6s7s8s+7p+13',
  '7m8m5p5p2s3s3s4s4s5s6s7s8s+6m+11',
  '2m2m2m3m4m5p6p7p2s3s6s7s8s4s+23',
  '9m9m3p3p8p8p1s1s7s7s3z4z4z+3z+22',
  '5m6m7m2p3p4p7p8p6s7s8s9s9s+9p+12',
  '1m1m2m3m5p6p7p3s4s5s6s7s8s+4m+22',
  '1m2m1p2p3p5p5p1s2s3s5s6s7s+3m+22',
  '7m8m9m2p2p4p5p6p3s4s5s7s8s9s+13',
  '4m4m4p4p5p5p6p6p1s2s3s5s6s7s+13',
  '3m4m5m5p6p7p3s3s4s5s7s8s9s+6s+12',
  '2m3m4m5m6m7m3p4p5p6p6p7s8s6s+24',
  '2m3m4m6m7m8m8m3p4p4p5p5p6p+5m+24',
  '6m6m8m8m9m9m4p4p5p5p3s3s4z+4z+22',
  '5m5m4p5p6p6p7p8p4s5s6s7s8s+9s+21',
  '3m3m6p6p7p7p8p2s3s4s5s6s7s5p+21',
  '5m6m3p4p5p7p8p9p4s4s6s7s8s7m+23',
  '1m1m1m6m7m6p7p8p8s8s3z3z3z+5m+23',
  '5m6m6m6m7m5p6p7p8p9p5s6s7s4p+22',
  '6m7m8m1p2p3p5p5p6p8p6s7s8s+7p+12',
  '1m2m3m5m5m6p7p8p2s2s3s3s4s+1s+24',
  '1p2p3p3p3p6p7p1s2s3s4s5s6s5p+14',
  '4m4m5m5m6m7m7m6p6p2s2s5s5s+6m+13',
  '6m7m8m3p3p3p5p5p4s4s5s5s6s+6s+13',
  '1m1m4m5m6m4p5p6p4s5s6s9s9s+1m+23',
  '5m6m6m7m8m1p2p3p1s2s3s6s6s+4m+11',
  '5m6m7m1p2p3p4p5p6p5s6s3z3z+4s+22',
  '1m2m3m5m6m7m5p6p2s2s5s6s7s+7p+14',
  '5m6m2p2p5p6p7p5s6s7s7s8s9s7m+21',
  '4m5m6m4p4p5p6p8p8p8p5s6s7s4p+23',
  '4m5m6m4p5p5p6p6p6s7s8s2z2z4p+22',
  '2m2m2m2p3p4p4p5p6p4s6s6s6s+4s+14',
  '1m2m3m4p4p7p8p9p1s2s3s4s5s+6s+14',
  '5m7m5p6p7p1s2s3s5s6s7s9s9s6m+13',
  '2m2m2m5p3s4s5s6s6s7s7s8s8s+5p+11',
  '3m3m3m1p1p4s5s7s8s9s7z7z7z+3s+23',
  '2m3m4m7m8m9m2p2p4p5p6p4s5s3s+22',
  '1p2p3p3p4p5p5p6p5s6s7s2z2z7p+13',
  '1m1m7m3p3p1s1s2z2z3z3z4z4z7m+22',
  '6m6m2p2p3p3p4p4p9p9p9p5s6s4s+11',
  '2m3m4m6m7m8m2p2p3p4p5p6s7s+5s+12',
  '3m3m4p5p6p6p7p2s3s4s5s6s7s+5p+21',
  '3m4m5m9m9m4p5p2s3s4s6s7s8s3p+21',
  '2m3m4m2p3p4p5p6p8p8p4s5s6s7p+14',
  '5m6m7m7m8m5p6p7p4s4s7s8s9s+6m+24',
  '6m7m8m3p4p5p7p8p9p4s4s7s8s+9s+12',
  '3m3m3p4p5p7p8p3s4s5s6s7s8s9p+11',
  '2m3m4m6m7m8m5p6p7p3s3s5s6s7s+13',
  '2m3m9m9m1p2p3p4p5p6p3s4s5s+1m+22',
  '4m4m7m8m9m4p4p4p3s4s5s6z6z+6z+11',
  '6m6m7m7m8m8m6p6p3s5s5s6s7s4s+12',
  '2p3p4p4p5p6p7p8p9p8s8s6z6z+6z+13',
  '2m3m4m5m6m7m8m8m2s3s4s7s8s+9s+12',
  '5m6m7m7m7m5p7p1s2s3s5s6s7s6p+21',
  '5m6m7m3p3p4p4p5p5p3s3s7s9s8s+21',
  '3m3m9m9m3p3p5p5p1s3s3s5s5s+1s+14',
  '3m3m6m7m8m4p4p5p5p6p6p2s4s+3s+22',
  '2m2m3m3m2p2p3p3p4p4p4s7s7s+4s+14',
  '4m4m6m6m4s4s8s8s1z1z6z6z7z+7z+13',
  '2m3m4m4p5p6p7p8p9p6s6s7s8s+9s+21',
  '3m4m5m5m6m7m7m8m4p5p6p7p7p+9m+12',
  '5m6m7m2p2p2p3p4p7p8p5s6s7s9p+12',
  '3m4m1p1p4p5p6p1s2s3s3s4s5s+5m+21',
  '2m3m4m5m5m6m7m3s4s5s8s8s8s+8m+11',
  '3m4m5m2p3p4p9p9p3s4s7s8s9s+2s+13',
  '4m5m6m2p2p4p5p6p7p7p4s5s6s+2p+23',
  '2m3m4m5m5m1p2p3p3s4s5s6s7s8s+11',
  '5m5m7m7m9p9p1s1s2s2s6s6s5z+5z+12',
  '4m4m6m7m8m6p7p4s5s6s6s7s8s+8p+24',
  '2m3m3m4m4m2p2p3p3p3p5z5z5z5m+11',
  '2m3m5m6m7m7m8m9m2p2p7s8s9s+1m+12',
  '1m2m3m3m4m5m1p1p6p7p7s8s9s+8p+12',
  '3m4m5m8m8m8m3p3p3p4p5p7s7s6p+23',
  '3m4m4m5m5m6m8m8m3p4p5p6p7p2p+24',
  '3m4m5m2p3p4p2s2s2s3s4s6s7s8s+21',
  '4m5m6m6m7m8m2p3p5p5p5s6s7s+1p+23',
  '3m3m4m4m5m5m2p2p2p4p4p5s6s+4s+23',
  '1m2m3m6m7m8m4s5s7s8s9s2z2z6s+11',
  '1m2m2m3m3m5m5m5m6m7m9m9m9m+4m+14',
  '4m5m6m2p3p7p8p9p3s3s4s5s6s+1p+14',
  '5m5m8m8m2p2p5s5s1z2z2z3z3z1z+13',
  '1m2m3m3m4m3p4p5p7p8p9p8s8s+2m+13',
  '2m3m4m4m5m6m7m4p5p6p7p8p9p1m+13',
  '2m2m3m3m3m8m8m8m5p5p3s3s3s+5p+14',
  '4m4m6m6m7m7m8m8m5p5p6p7p7p+6p+13',
  '1m2m3m3m4m5m5p6p4s5s6s7s7s7p+11',
  '2m3m3m4m5m6m7m8m5p5p6s7s8s1m+14',
  '1m2m3m3m4m5m5m5m4s5s7s8s9s3s+21',
  '5m6m3p4p5p6p6p8p8p8p6s7s8s+7m+23',
  '2p3p4p4p5p1s2s3s3s4s5s6s6s+3p+11',
  '4m4m2p2p2p5p6p6p7p7p8p6s7s5s+11',
  '2m3m4m6m7m1p2p3p4p5p6p8s8s+5m+22',
  '1m3m4m4m6m7m8m7s7s7s5z5z5z+2m+21',
  '2m3m4m5m6m7m5p6p1s2s3s7s7s4p+21',
  '2m3m4m6m8m5p5p6p6p7p7p7s7s+7m+14',
  '1p1p4p5p6p6p7p8p1s2s3s4s5s+3s+22',
  '3m4m6m7m8m4p5p5p5p6p6s7s8s5m+14',
  '3m4m3p4p5p6p7p8p4s4s5s6s7s+2m+24',
  '1m2m3m3m5m6m6m3p4p5p3s4s5s+4m+24',
  '9m2p2p8p8p3s3s7s7s5z5z7z7z9m+11',
  '4m5m5m2p2p4p4p9p9p6s6s7s7s+4m+11',
  '5m5m5m6m7m8m3p4p4p4p5p2s4s+3s+12',
  '2m3m4m7m8m9m3p3p1s2s3s6s7s+8s+23',
  '3m3m4m4m7m7m8p2s2s4s4s6z6z+8p+12',
  '1m1m9m9m2p2p5p9p9p3s3s6s6s5p+14',
  '4m4m5m6m7m7m8m9m5p6p2s3s4s4p+21',
  '2m4m5m6m7m4p5p5p6p6p7p4s4s+3m+11',
  '2m3m3m4m4m6m7m8m3p4p5p4s4s+5m+11',
  '3m3m7m7m9m9m9p9p1s1s3s3s9s+9s+11',
  '3m3m4m4m5m6m7m8m4p5p6p7p7p+2m+13',
  '1m1m3m3m1p5p5p7p7p1s1s4z4z+1p+14',
  '3m4m5m5m6m7m1p2p3p1s1s4s5s6s+11',
  '1m2m3m5m6m7m8m9m5s5s7s8s9s+4m+24',
  '1m1m4m5m4p5p6p3s4s5s6s7s8s+6m+13',
  '3m4m5m2p2p3p3p5p6p7p6s7s8s+2p+11',
  '3m3m6m7m8m5p6p2s3s4s5s6s7s+4p+13',
  '1m2m3m1p1p1s2s3s6z6z7z7z7z+6z+24',
  '4m5m6m3p4p5p5s7s5z5z7z7z7z+6s+13',
  '3m4m5m7m8m9m7p7p3s5s5z5z5z4s+22',
  '2m3m4m7m8m1p2p3p5p6p7p5s5s+9m+12',
  '5m5m3p4p5p5p6p3s4s5s6s7s8s+7p+11',
  '4m5m6m4p5p6p3s4s4s5s5s7s7s6s+22',
  '4m5m6m3p4p4p5p5p6p7p8p3s3s+9p+13',
  '4m5m6m2p3p4p5p6p6s7s8s9s9s1p+21',
  '3m4m5m6m7m8m8p8p2s3s5s6s7s+1s+23',
  '3m3m7m8m9m5p6p7p7p8p7s8s9s9p+23',
  '2m3m4m5m5m7m8m1p2p3p6p7p8p+6m+14',
  '2m3m4m6m8m1p1p2p2p3p3p6s6s+7m+12',
  '1m2m3m6m7m2p3p3p3p4p4s5s6s5m+12',
  '4m5m6m5p5p6p6p7p7p7p4s5s6s+4p+13',
  '7m8m9m2p3p4p5p6p3s3s4s5s6s+1p+12',
  '1m1m2m2m3m3m5p5p5p6p3s4s5s+4p+21',
  '1m2m3m6m7m8m4p4p6p7p5s6s7s+8p+21',
  '2p3p4p5p6p7p7p8p5s6s7s4z4z9p+21',
  '1m1m7m7m3p3p7p7p9p1s1s9s9s+9p+11',
  '4m4m4p5p6p7p8p9p1s2s3s5s6s4s+11',
  '4m5m7m7m4p5p6p1s2s3s7s8s9s+3m+23',
  '2m3m9m9m9m1p2p3p1s2s3s8s8s1m+13',
  '5m6m7m8m5p5p5p6p7p8p3s3s3s+8m+12',
  '4m5m6m8m8m8m5p5p7p8p5z5z5z+6p+13',
  '3m4m4p4p5p6p7p3s4s5s6s7s8s5m+14',
  '3m4m5m2p3p4p6p7p8p3s4s5s5s2s+12',
  '7m8m9m1p2p3p4p4p7p8p5s6s7s9p+21',
  '2m3m4m4m4m2s3s4s5s6s7s5z5z5z+13',
  '4m5m6m7m4p4p5p5p6p6p1s2s3s4m+24',
  '4m5m6m3p3p7p8p9p4s5s7s8s9s+3s+13',
  '1m2m3m6m7m8m3p4p7p8p9p9s9s+2p+11',
  '5m6m7m8m9m2p2p4p5p6p3s4s5s4m+12',
  '5m5m6m7m8m3p4p4p5p6p4s5s6s+2p+11',
  '3m4m5m6m6m3p3p3p6p7p5s6s7s5p+23',
  '1m2m3m5m6m7m8m9m4s5s6s3z3z+4m+22',
  '2m3m4m1p1p2p3p4p6p7p8p4s5s6s+11',
  '6m7m7p8p9p4s5s6s4z4z6z6z6z+5m+21',
  '2m3m3m4m5m6m7m8m1p2p3p1s1s+1m+13',
  '5m6m7m6p7p8p2s2s2s3s3s6s7s+8s+12',
  '2m2m4m5m3p4p5p3s4s5s7s8s9s+6m+22',
  '4m5m7m8m9m7p8p9p3s4s5s7s7s3m+14',
  '1s2s3s3s4s4s5s6s7s8s9s5z5z+5s+22',
  '2m3m4m7m8m5p6p7p2s3s4s5s5s6m+14',
  '2m3m5m5m5p6p7p2s3s4s6s7s8s+1m+14',
  '2m3m1p1p2p3p4p5p6p7p2s3s4s4m+14',
  '5m5m4p5p6p6p7p8p2s3s4s4s5s+3s+14',
  '3m4m5m6m6m7m7m8m8m1p2p8p8p+3p+13',
  '3m4m5m6m7m8m6p7p8p6s7s8s7z7z+23',
  '1m1m4m5m6m2p3p3p4p5p3s4s5s1p+14',
  '6m7m2p3p4p5p5p3s3s3s3s4s5s8m+21',
  '2m3m4m6m7m8m3p3p5s5s5s6s8s+7s+21',
  '4m4m5m5m6m6m7m8m5s5s2z2z2z+6m+13',
  '4m5m6m3p4p6p6p6p4s5s6s6s6s5p+24',
  '5m6m3p3p4p5p6p6p7p8p3s4s5s+4m+12',
  '4m5m6m4p5p6p7p8p2s3s4s6s6s6p+23',
  '1m2m3m5m5m4p5p6p2s3s5s6s7s+4s+11',
  '5p6p7p1s2s3s4s5s6s8s9s7z7z+7s+11',
  '7m8m9m3p3p4p5p6p4s5s5s6s7s6s+24',
  '3m3m4p5p5p6p7p1s2s3s7s8s9s3p+11',
  '2m3m4m2p3p4p5s6s7s8s7z7z7z+8s+24',
  '2m2m2m6m6m7m7m8m8m5p5p6p8p+7p+12',
  '2m2m3m3m4m4m9m9m5p6p2s3s4s+4p+24',
  '4m5m5m6m7m1p1p3p4p5p2s3s4s+3m+11',
  '2p2p2p5p6p7p2s2s3s5s6s7s8s4s+22',
  '3m4m5m6m6m6p7p7p8p9p5z5z5z+8p+22',
  '4m5m6m5p6p7p8p9p5s6s7s7s7s+4p+21',
  '3m5m5m5m6m7m8m3p4p5p6p7p8p+3m+24',
  '1m2m3m1p2p3p4p5p6p5z5z5z6z+6z+13',
  '2m2m3m3m9m9m4p4p1s1s2s2s7z+7z+11',
  '4m5m2p3p4p5p5p1s2s3s3s4s5s+6m+24',
  '6m7m3p4p5p1s1s1s2s3s3s4s5s+5m+11',
  '7m8m2p3p4p8p8p1s2s3s4s5s6s6m+12',
  '4m5m6m7m8m9m9p9p5s6s7s8s9s7s+21',
  '5m5m6m7m8m5p6p2s3s4s7s7s7s+4p+12',
  '2m3m4m7m7m3p4p5p7p8p4s5s6s+6p+12',
  '2m3m4m9m9m3p4p6p7p8p2s3s4s5p+12',
  '3m4m6m7m8m1p2p3p1s1s5s6s7s+5m+23',
  '2m3m4m5m6m4p4p7p8p9p3s4s5s1m+24',
  '1m3m3m4p4p5p5p5s5s6s6s9s9s+1m+13',
  '4m5m7m7m5p6p7p7p8p9p5z5z5z6m+21',
  '3m4m5m6p7p3s3s3s4s4s5s5s6s8p+23',
  '2m2m6m6m6m1p2p3p5p5p6p6p7p7p+13',
  '5m6m7m8m9m6p7p8p5s5s6s7s8s4m+14',
  '1m1m3m4m5m7m8m9m2p3p4p7z7z+7z+11',
  '3m4m6m6m7m7m8m8m4p5p6p6s6s+2m+14',
  '1m2m4m5m6m7m8m9m5p5p9p9p9p+3m+21',
  '5m6m7m2p2p2p3p5p4s4s6s7s8s+4p+14',
  '2m3m4m5m6m2p2p3p4p5p5s6s7s+7m+12',
  '3m4m6m6m6p7p8p3s4s5s6s7s8s+2m+21',
  '3m4m7m7m6p7p8p2s2s2s8s8s8s+5m+24',
  '3m3m5m6m7m7p8p9p3s4s7s8s9s2s+12',
  '3m4m5m7m7m1p1p1p3s4s5z5z5z+2s+11',
  '1p2p3p5p6p8p8p1s2s3s5s6s7s4p+21',
  '3m3m7m8m9m3p4p7p8p9p7s8s9s+2p+23',
  '6m7m8m1p2p3p3p4p5p5s6s8s8s+4s+12',
  '2m3m4m5m5m7m8m9m6p7p8p4s5s+3s+23',
  '1m2m3m4m5m6m8m9m3p3p4s5s6s+7m+24',
  '5p6p6p6p7p2s3s4s5s5s5s1z1z+1z+11',
  '1m1m4m5m1p2p3p3p4p4p5p5p6p+3m+23',
  '5m5m7p7p8p8p9p3s4s5s6s7s8s6p+11',
  '2m2m3m4m5m2p3p4p4s5s6s6s7s+8s+22',
  '3m4m5m5m6m5s5s6s7s7s8s8s9s+7m+12',
  '2m3m6m7m8m1s2s3s3s3s7s8s9s+1m+11',
  '3m4m3p4p5p2s3s4s4s5s6s7s7s+2m+22',
  '3p4p4p4p5p6p7p8p3s4s5s5s7s6s+14',
  '1m2m3m3m4m1p2p3p4p4p4s5s6s+2m+12',
  '2m3m4m6m7m8m4p5p4s5s6s4z4z6p+21',
  '1m1m4m5m7m8m9m2p3p4p4s5s6s+6m+23',
  '4m5m7m8m9m2p3p4p6s7s8s3z3z+3m+24',
  '6m7m8m5p6p7p2s2s2s4s4s6s7s+8s+13',
  '2m2m5m6m7m2p3p4p5p6p3s4s5s+1p+14',
  '4m5m6m7m8m1s2s3s3s4s5s2z2z9m+13',
  '2m3m4m5m5m4p5p2s3s4s6s7s8s+6p+14',
  '2m3m4m7m8m1s1s4s4s5s5s6s6s6m+11',
  '1p2p3p3p4p6p7p8p9p9p9p6z6z+2p+11',
  '1p2p3p6p6p2s3s4s6s7s7s8s8s+9s+23',
  '1m2m3m5p6p7p4s5s6s6s7s8s9s6s+12',
  '1m2m3m7m8m9m2p3p5p5p7p8p9p1p+23',
  '1m2m3m8m8m2p3p4p2s3s5s6s7s1s+12',
  '2m2m4m5m6m3p4p5p7p8p2s3s4s+9p+24',
  '7m8m9m9p9p1s1s4s4s5s5s6s6s+9p+13',
  '3m3m4m4m5m5m3p5p6p6p6p5s5s+4p+22',
  '2m2m6m7m3p4p5p3s4s5s6s7s8s+5m+23',
  '6m7m8m5p6p3s4s4s5s5s6s9s9s7p+24',
  '6m7m8m1p1p3p4p5p6p7p8p4s5s+6s+12',
  '3m4m5m3p4p3s4s5s6s7s8s3z3z5p+12',
  '3m4m5m5m6m9m9m3p4p5p3s4s5s+7m+23',
  '6m7m8m2p3p5p6p7p3s4s4s4s5s+1p+13',
  '2m3m4m2p3p4p5p6p7p2s2s4s5s3s+23',
  '3m3m4m4m5m6m6m2p3p4p6p7p8p+2m+11',
  '5m6m7m3p3p8p8p3s4s5s6s7s8s3p+12',
  '3m4m5m2p3p2s2s3s4s5s6s7s8s+1p+22',
  '1m2m3m5m6m3p4p5p9p9p7s8s9s7m+24',
  '2m2m3m4m5m5m6m7m2p4p4s5s6s+3p+11',
  '7m8m9m3p4p5p7p8p9p2s2s6s7s+8s+22',
  '3m4m4m5m5m6m6p6p2s3s4s7s8s+9s+12',
  '3m4m5m6m7m8m2p3p7p8p9p1s1s+4p+13',
  '3m4m5m5m6m7m1p2p3p7p8p3z3z+9p+12',
  '5m5m7m8m9m4p5p6p4s5s6s7s8s+3s+11',
  '1m2m3m5m6m7m8m8m5p6p6p7p7p8p+22',
  '6m7m8m4p4p3s3s5s6s6s7s7s8s4p+24',
  '8m8m8m4p5p6p6p8p5s5s6s7s8s+7p+14',
  '4m5m5m6m7m7p8p9p6s7s8s8s8s+6m+12',
  '4m5m6m7p8p9p3s4s4s5s6s9s9s5s+11',
  '1p2p3p4p4p6p7p8p1s2s3s7s8s+9s+12',
  '1m2m2m3m3m4m1p2p3p5s6s6s6s+4s+14',
  '9m9m5p6p7p8p9p2s3s4s4s5s6s+7p+24',
  '2m3m7m7m4p5p6p6p7p8p3s4s5s+4m+11',
  '2m3m4m5p6p7p1s2s3s7s8s1z1z+9s+23',
  '6p7p1s1s1s7s7s8s8s9s9s1z1z+8p+13',
  '8m8m1p2p3p3s3s3s5s5s4z4z4z5s+13',
  '2p2p4p4p8p8p1s7s7s1z1z2z2z+1s+14',
  '2m3m4m6m7m8m2s2s4s4s6z6z6z2s+21',
  '4m5m6m2p3p4p5p6p1s1s5s6s7s+4p+14',
  '1m1m2m3m4m6m7m8m5p6p4s5s6s7p+23',
  '2p3p4p5p5p6p6p7p7p3s3s4s4s+3s+23',
  '4m4m5m5m6m6m1p5p5p4s4s9s9s+1p+14',
  '4m4m4m3p3p4p5p6p5s6s6s7s8s+4s+23',
  '3m4m3p3p4p5p6p4s5s6s7z7z7z+2m+11',
  '3m4m3p4p5p2s2s3s4s5s6s7s8s2m+12',
  '1m1m6m7m4p5p6p3s4s5s6s7s8s+5m+14',
  '4m4m6p7p8p3s4s5s6s7s3z3z3z2s+23',
  '7m7m7m3p4p5p5p5p6p7p8p4s4s+2p+13',
  '5m5m6m6m7m4s4s7s8s9s4z4z4z4m+24',
  '5m6m7m2p2p3p3p4p1s1s4s5s6s+1p+12',
  '3m3m3m2p3p4p6p6p8p8p6s7s8s6p+12',
  '2m3m4m6m7m8m9m9m5p6p5s6s7s+7p+24',
  '1m2m3m5m6m6p7p8p4s4s5s6s7s+4m+11',
  '7m8m9m1p2p3p9p9p2s3s4s6s7s+8s+13',
  '3m4m5m2p2p2p4p4p5p6p7p5s6s+4s+11',
  '2m3m4m6m7m2p3p4p2s3s4s5s5s+5m+12',
  '4m5m6m4p4p5p5p6p6p7p8p9p9p3p+23',
  '1m2m3m4m4m2p3p3p4p5p7p8p9p1p+14',
  '3m3m4m5m6m8m8m4p5p6p3s4s5s+3m+13',
  '3m4m5m7m8m1p1p2p3p4p7s8s9s+6m+23',
  '2m3m6p7p8p4s4s5s5s6s6s8s8s+4m+24',
  '5m5m5m6m7m3p4p5p6p7p1s2s3s+2p+21',
  '6m7m8m8m5p6p6p7p7p8p3s4s5s5m+32',
  '7m7m7m4p5p6p7p8p3s4s5s8s8s+6p+11',
  '2m2m2p3p5p6p7p2s3s4s6s7s8s4p+11',
  '1m2m3m7m8m9m2p3p2s2s7s8s9s4p+11',
  '7m8m5p6p7p1s2s3s7s8s9s1z1z+6m+24',
  '7m7m7m5p5p6s7s9s9s9s1z1z1z5s+13',
  '3m4m3p3p3p4p5p4s5s6s7s8s9s+2m+14',
  '5m5m6m6m7m7m3p4p7p8p9p4z4z+2p+21',
  '2m2m7m7m8m3p3p4p4p3s3s5s5s8m+13',
  '2m3m4m7m8m3p4p5p9p9p1s2s3s+9m+23',
  '2m3m4m5m6m6m7m7m2p3p4p1s1s5m+24',
  '3m4m5m5m6m7m3p3p4p4p4s5s6s+3p+23',
  '2m2m5m6m7m7p8p9p4s5s5z5z5z6s+24',
  '2p2p3p3p4p4p5p6p7p3s7s8s9s+3s+12',
  '5m6m7m2p3p4p4p2s3s4s7s8s9s1p+14',
  '6m6m3p4p7p8p9p2s3s4s2z2z2z5p+12',
  '4m5m6m1p2p3p5p5p6p7p8p5s6s4s+14',
  '4m5m7m7m6p7p8p1s2s3s5s6s7s+3m+21',
  '3m4m5m8m8m4p5p7s7s8s8s9s9s+3p+24',
  '5m7m2p3p4p5p6p7p1s1s5s6s7s6m+22',
  '5m6m7m8m8m2p3p7p8p9p4s5s6s4p+21',
  '2m3m4m8m8m1s2s3s3s4s7s8s9s+5s+14',
  '7m8m9m1p2p9p9p1s2s3s7s8s9s+3p+23',
  '3m3m4m5m6m3p4p4p5p5p7p8p9p3p+13',
  '3m4m5m6m6m2p3p4p7p8p5s6s7s6p+11',
  '1m1m2m2m3m3m4m4m9p9p9p3s3s+1m+11',
  '8m9m2p2p2p3p4p7p8p9p7s8s9s+7m+21',
  '2m3m4m7p8p9p2s3s4s4s4s7s8s9s+12',
  '1m1m3m4m5m4p5p3s4s5s7s8s9s6p+24',
  '6m8m8m1p1p3p3p4p4p6p6p5z5z+6m+21',
  '5m6m7m8m9m7p8p9p4s5s6s2z2z+4m+13',
  '1p2p3p4p5p6p7p8p9p2s2s5s6s+4s+12',
  '2m3m4m4m5m6m2p3p4p5p5p7s8s6s+14',
  '6m7m8m2p3p4p4p5p7p8p9p8s8s+6p+13',
  '4p5p6p7p8p3s3s4s5s6s5z5z5z6p+21',
  '7m7m2p3p4p5p6p2s3s4s5s6s7s4p+23',
  '6m7m8m1p1p2p2p3p3p7p9p3s3s+8p+22',
  '5m5m3p3p6p7p8p2s3s4s6s7s8s+5m+14',
  '2m3m4m5m6m7m6p7p8p2s2s5s6s+7s+23',
  '4m4m5p5p5p6p6p7p8p8p5s6s7s+7p+12',
  '7m8m9m1p2p3p5p5p6p7p7p6s6s+6p+22',
  '6p7p3s3s4s5s5s6s6s7s7s8s9s+8p+21',
  '4m5m6m4p6p8p8p2s2s2s3s4s5s5p+13',
  '6m7m8m6p7p8p3s3s6s8s7z7z7z7s+23',
  '4m5m6m5p6p1s2s3s4s5s6s7s7s7p+24',
  '2m2m5m6m7m7m8m9m1p2p3p5s6s+7s+12',
  '3m4m5m2p3p4p4p5p7p7p6s7s8s+3p+12',
  '3m3m3m4m4m4m2p2p4p5p6p2s4s3s+13',
  '7m8m9m1p2p3p4s5s9s9s6z6z6z6s+13',
  '6m7m2p3p4p7p8p9p4s4s4z4z4z8m+24',
  '1m2m3m4s5s6s6s6s7s7s8s8s2z+2z+13',
  '4m4m5m5m6m6m5p5p7p8p9p4s5s+6s+12',
  '2m2m6m7m2p3p4p5p6p7p3s3s3s+8m+21',
  '3m4m5m6m7m8m3p3p3p4p5s6s7s4p+21',
  '3m4m5m6m6m3p4p5p3s3s5s6s7s+3s+11',
  '1p2p3p5p6p6p7p7p6s7s8s2z2z8p+11',
  '1m1m1m2m3m5m6m7m7m8m9m4s5s6s+13',
  '1m2m3m5m5m1p2p3p3p4p6p7p8p5p+22',
  '2m2m4m5m6m1p3p4p5p6p4s5s6s2p+22',
  '4m4m4m6m7m8m6p7p8p7s8s2z2z6s+13',
  '5m7m7m8m8m9m9m3p3p5p5p5s5s5m+12',
  '5m6m7m3p4p3s3s5s6s7s7s8s9s+2p+24',
  '1m1m5m6m7m3p4p6s7s7s8s8s9s5p+12',
  '7p7p7p1s2s3s4s5s6s7s8s9s6z6z+11',
  '3m4m5m6m6m7m7m8m8m4p5p9s9s6p+11',
  '6m7m5p6p7p2s3s4s4s5s6s8s8s+5m+14',
  '2m2m4m5m6m7m8m9m7p8p9p7s9s8s+21',
  '2m3m4m6m7m8m6p7p8p4s5s9s9s3s+11',
  '2m3m5m6m7m1p1p5p6p7p7p8p9p+4m+14',
  '2m3m2p3p4p7s7s8s8s9s9s9s9s4m+22',
  '1m2m3m7p7p2s3s4s4s5s5s6s6s+7s+21',
  '1m2m3m4m5m6m4p5p6p6p3s4s5s3p+22',
  '4m5m6m4p5p6p1s1s4s5s6s7s8s+9s+13',
  '1m2m3m4m5m6m5p5p6p6p7p2z2z+7p+14',
  '1p2p3p5p6p4s5s6s7s8s9s4z4z+7p+11',
  '1m2m3m5m5m7m8m9m6p7p3s4s5s+8p+12',
  '3m4m5m4p4p5p6p2s3s4s6s7s8s+4p+12',
  '4p5p3s3s4s5s6s7s8s9s6z6z6z3p+12',
  '4m4m7m8m1p2p3p1s2s3s3s4s5s9m+22',
  '6m7m8m3p4p6p7p8p6s7s8s9s9s+5p+12',
  '7m8m9m5p6p6p7p8p9p9p6s7s8s7p+24',
  '1p1p3p4p5p6p6p6p7p9p6z6z6z8p+14',
  '3m3m4m4m5m5m7m2s2s6s6s7s7s7m+22',
  '7m8m9m5p5p4s5s6s7s8s9s6z6z+6z+14',
  '2p3p4p7p7p1s1s1z1z1z2z2z2z1s+21',
  '5m5m6m6m7m7m8m8m3s4s5s7s8s6s+12',
  '2m3m5m6m7m4p5p6p3s4s5s1z1z1m+23',
  '1m2m3m6m7m2p2p3p4p5p6s7s8s8m+13',
  '1m2m3m6m7m8m4p5p5p6p6p5s5s+7p+24',
  '2m3m4m5m5m3p4p5p4s5s6s2z2z+2z+21',
  '5m5m4p4p8p8p9p3s3s5s5s9s9s+9p+13',
  '1m1m2m2m3m4m5m6m3p4p5p5s5s3m+22',
  '4m5m8m8m1p2p3p3s4s5s7s8s9s+6m+24',
  '4m4m3p3p9p9p1s1s3s3s5s5s4z+4z+13',
  '5m6m5p6p7p4s4s4s5s6s7s8s9s7m+13',
  '1p2p3p4p5p5p6p6p1z1z1z7z7z+4p+12',
  '7m8m4p5p6p4s4s5s5s6s6s3z3z6m+23',
  '2m3m4m4m5m7m7m2p3p4p2s3s4s+6m+24',
  '3m3m4m4m6p6p7p7p2s2s5s7s7s5s+13',
  '1m2m3m8m9m4p4p7p8p9p7s8s9s7m+23',
  '1m1m6m6m1p1p2p2p7p7p2s6s6s+2s+24',
  '2m2m2p3p4p5p6p7p6s7s1z1z1z5s+14',
  '5m6m7m3p3p4p4p5p1s1s1s6s6s5p+21',
  '6m7m2p2p7p7p7p2s3s3s4s4s5s8m+14',
  '1m1m5m6m7m7m8m1p2p3p5p6p7p+9m+21',
  '5m6m6m7m7m9m9m1p2p3p4p5p6p5m+12',
  '7m8m9m3p4p5p5p6p7p4s5s8s8s3s+23',
  '2m3m3m4m4m5m3p5p2s2s3s4s5s4p+23',
  '2m3m4m6m8m3p4p5p5s6s7s8s8s+7m+22',
  '5m6m7m8m8m4p5p6p4s4s5s5s6s+3s+21',
  '3m4m5m6m7m8m1p2p3p7p7p7s8s+9s+21',
  '5m5m5m9m9m9m2s2s2s5s7s1z1z6s+21',
  '4p4p6p7p8p1s2s3s4s5s6s7s8s9s+23',
  '4m5m6m8m8m1p2p3p5p6p7p8p9p+4p+22',
  '1m2m3m3m4m5m3p3p6p7p5s6s7s+8p+12',
  '8m8m8m2p3p4p6p7p5s5s6s6s6s8p+11',
  '2m3m7m8m9m3p4p5p3s4s5s4z4z1m+11',
  '6m7m8m6p7p8p1s2s3s4s4s6s7s5s+11',
  '4m5m6m4p4p6p7p8p2s3s4s7s7s+7s+11',
  '2m3m4m5m5m2p3p4p3s4s5s7s8s+9s+12',
  '4m5m6m7m8m9m9p9p5s6s6z6z6z7s+11',
  '4m5m6m9m9m6p7p8p5s6s7z7z7z+4s+12',
  '7m8m9m1p2p3p5p6p7p6s7s4z4z+8s+22',
  '2m2m8m9m9m2p2p8s8s1z1z3z3z8m+22',
  '2m3m4m6m6m3p3p4p4p5p5p3s5s4s+13',
  '5m6m4p5p6p2s3s4s6s7s8s9s9s+7m+21',
  '4m4m6m7m6p7p8p7s8s9s1z1z1z+5m+11',
  '3m4m5m4p5p6p2s3s4s6s7s3z3z+8s+14',
  '5p6p6p7p7p3s4s5s5s5s6s7s8s+5p+22',
  '1m1m3m4m5m7m8m4p5p6p6s7s8s6m+22',
  '3m4m6m7m7m8m8m9m1s2s3s4s4s2m+11',
  '3m3m6p7p7p8p8p9p4s4s5z5z5z+3m+23',
  '1m2m6m6m5p5p6p6p7p7p3s4s5s3m+24',
  '1m2m3m7m8m7p7p8p8p9p9p8s8s9m+21',
  '6m6m6m2p3p6p7p8p7s7s5z5z5z+1p+14',
  '3m3m5m6m7m2p3p5p6p7p5s6s7s+1p+22',
  '2m4m7m7m3p4p5p5s6s6s7s7s8s3m+23',
  '7m8m9m7p9p1s1s1s2s3s2z2z2z+8p+22',
  '1m1m3m4m5m7m8m9m2p3p5z5z5z+1p+13',
  '2m3m4m5m6m1p2p3p4s4s6s7s8s4m+24',
  '7m8m2p3p4p6p7p8p3s4s5s7s7s9m+12',
  '1m2m3m7p8p9p2s4s5s5s7z7z7z+3s+13',
  '5m6m2p3p4p1s2s3s5s6s7s3z3z7m+22',
  '2m3m4m6m7m8m9m9m4s5s6s7s8s3s+11',
  '1p2p5p6p7p8p8p4s5s6s7z7z7z+3p+13',
  '2m3m2p3p4p1s2s3s5s6s7s4z4z+4m+12',
  '4m5m6m2p2p6p7p2s3s4s5s6s7s8p+12',
  '2m2m2m4m6m7m8m3p4p5p7p7p7p+4m+24',
  '7m8m9m1p2p3p1s1s5s6s7s7s8s+9s+22',
  '2m2m3m4m5m4p5p6p6p7p8p5s6s+7s+12',
  '2m2m4m5m6m5p5p6p6p7p2s3s4s4p+23',
  '5m6m7m7m8m9m4p5p6p6p7p7s7s8p+13',
  '5m5m2p3p4p7p8p8p8p8p5s6s7s6p+12',
  '3m3m5m6m4p5p6p2s3s4s4s5s6s+4m+14',
  '6m7m3p3p4p5p6p6p7p8p2s3s4s8m+12',
  '4m5m4p5p5p6p6p7p3s3s5s6s7s+6m+13',
  '1m2m3m6m7m8m9m9m1z1z4z4z4z+1z+12',
  '2m3m4m4m5m6m9m9m5s6s3z3z3z+4s+31',
  '4m4m6m6m7m7p7p9p9p7s7s9s9s7m+22',
  '2m3m6m7m8m3p4p5p7p7p6s7s8s4m+11',
  '8m8m2s3s3s4s4s7s7s2z2z7z7z+2s+13',
  '2m3m4m6m7m8m6p7p5s6s7s9s9s8p+14',
  '2m2m2m4m4m5m6m6m5p5p6s6s6s5m+11',
  '4m5m6m7m8m9m3p4p5p6p6p7s8s+9s+24',
  '5m6m1p2p3p5p5p7p8p9p3s4s5s7m+12',
  '1m2m3m6m7m7p8p9p9p9p5s6s7s8m+11',
  '5m6m7m8m9m2p2p7p8p9p5s6s7s+4m+22',
  '2m3m8m8m2p3p4p2s3s4s7s8s9s4m+12',
  '4m5m6m3p3p5p6p3s4s5s6s7s8s7p+13',
  '3m4m5m4p5p2s2s3s3s4s4s5s5s+3p+11',
  '4m5m6m4p5p1s1s4s5s6s7s8s9s+3p+21',
  '3m3m4m4m4m5m6m7m5p6p5s6s7s4p+12',
  '4m5m5p6p7p3s4s5s6s6s6s8s8s+3m+14',
  '4m5m6m4p5p5p6p6p7p4s4s7s8s6s+21',
  '2m2m3p3p1s1s2s2s3s3s6s6s8s+8s+21',
  '2m2m4m5m3p4p5p7p8p9p3s4s5s+3m+12',
  '4m4m5p6p7p3s4s5s6s7s7s8s9s+2s+24',
  '3m4m5m5m5m5m6m7m2p3p4p7p8p+9p+11',
  '6m7m8m1p1p6p7p8p2s3s4s6s8s7s+13',
  '6m7m7m8m9m3p4p5p7p8p9p6s6s+5m+11',
  '2m3m3m4m4m3p3p1s2s3s3s4s5s+2m+11',
  '3m3m4m5m7m8m9m6p7p8p4s5s6s6m+34',
  '3m4m7m7m4p5p6p1s2s3s6s7s8s2m+11',
  '3p4p5p6p7p8p1s2s3s3s4s9s9s5s+14',
  '1m2m3m7m8m6p7p8p6s6s7z7z7z9m+22',
  '1p1p3p3p5p5p6p6p8p8p9p9p4z+4z+24',
  '4m5m6m2p3p4p6p8p5s5s6s7s8s+7p+12',
  '4m5m2s3s4s5s5s6s6s7s7s1z1z+3m+13',
  '1m2m3m4m5m6m7m8m9m1p2p3p2s+2s+23',
  '2m3m4m6m7m8m3p3p6s7s7z7z7z+5s+13',
  '2m3m4m6m6m7m7m8m8m1p1p5p6p7p+14',
  '1m2m3m1p1p2p3p3p4p4p5p6p7p+5p+24',
  '4m5m6m1p1p2p3p4p6p7p8p5s6s+7s+13',
  '4m5m6m7p8p9p2s2s3s4s4s6z6z+3s+23',
  '6m2p2p5p5p8p8p5s5s7s7s8s8s+6m+14',
  '2p3p4p4p5p6p1s2s3s5s5s6z6z+6z+23',
  '5m6m2p3p4p5p5p6p7p8p7s8s9s7m+22',
  '2m3m5p5p7p8p9p1s2s3s3s4s5s1m+23',
  '2m3m4m6m7m8m4p4p2s2s4s5s6s+4p+21',
  '2m2m3p5p6p7p8p3s4s4s5s5s6s+4p+13',
  '2m3m8m8m7p8p9p4s5s6s7s8s9s+1m+14',
  '1m2m2m3m3m4m2p2p2s3s4s7s8s6s+14',
  '7m8m9m2p3p4p4p4p1s2s3s5s6s+7s+23',
  '2m2m2m3m4m5m6m7m4p5p6p6s7s8s+22',
  '5m6m7m9m9m2p3p7p8p9p2s3s4s4p+11',
  '1m1m2m2m3m3m2s3s4s6s7s4z4z+8s+13',
  '4p4p5p5p6p6p1s1s3s4s5s7s8s6s+11',
  '1p2p3p4p5p6p7p2z2z2z4z4z4z1p+11',
  '4m5m6m8m8m4p5p6p2s3s6s7s8s4s+12',
  '1m2m3m4m5m6m7m7m3p4p5p6p7p+8p+13',
  '2m3m4m7m7m6p7p8p3s4s5s7s7s+7m+23',
  '5m6m7m7m8m9m2p3p3p4p5p5s5s+4p+22',
  '3m3m4m4m6m6m1p1p2p2p9p9p6z+6z+11',
  '1m2m3m6m7m8m7p7p4s4s5s6s6s+5s+13',
  '4m5m6m3p4p4p5p5p6p3s5s8s8s4s+11',
  '1m2m3m7m8m2p3p4p4p5p6p7p7p6m+13',
  '5m5m7m8m1p2p3p5p6p7p5s6s7s+6m+22',
  '4m4m5m6m6m3s4s5s6s7s8s5z5z+5m+22',
  '4p5p6p6p7p1s1s6s7s7s8s8s9s8p+13',
  '4m5m6m2p3p4p4p5p6p8p8p7s7s8p+11',
  '4m5m8m8m2p3p4p6p7p8p4s5s6s6m+14',
  '2m3m4m6m7m8m1p1p5p6p7p5s6s4s+12',
  '5m6m1p2p3p5p6p7p2s2s5s6s7s+7m+22',
  '6m7m2p3p4p2s2s3s4s5s5s6s7s8m+13',
  '4m5m6m4p5p6p5s6s7s9s9s6z6z+6z+21',
  '1p1p2p3p4p7p8p9p6s7s2z2z2z+8s+24',
  '1m2m4m5m6m8p8p1s1s2s2s3s3s3m+21',
  '3m4m5m3p4p5p6p7p8p2s3s2z2z+1s+11',
  '2m3m7m8m9m3p4p5p4s4s7s8s9s+1m+12',
  '5m5m6m6m7m1p1p3p3p4p4p3s3s+7m+21',
  '3m4m5m3p4p5p5p6p7p3s4s2z2z5s+11',
  '1m1m5m6m7m4p5p6p4s5s7s8s9s3s+13',
  '1m2m3m2p3p4p5s6s6s7s8s3z3z+4s+12',
  '2m3m3m4m4m5m5p6p7p8p2s3s4s+5p+13',
  '6m7m8m7p7p8p8p9p9p3s4s6s6s+2s+23',
  '1m2m3m4m5m2p3p4p8p8p1s2s3s6m+14',
  '2m3m3m4m4m2p3p4p7p7p2s3s4s+5m+12',
  '2m3m5m6m7m3p4p5p4s5s6s6s6s1m+12',
  '4m5m6m7m7m4p5p6p3s4s6s7s8s5s+11',
  '2m3m3m3m4m7m8m9m6p7p5s6s7s5p+11',
  '2m3m4m8m8m1p2p3p5p6p6s7s8s4p+12',
  '3m4m5m6m7m8m1p1p4s5s7s8s9s+6s+11',
  '2m3m4m6m7m8m5p5p6p7p4s4s4s+8p+22',
  '6m7m8m3p4p5p7p7p4s5s6s8s8s7p+22',
  '7m7m4p5p7p8p9p1s2s2s3s3s4s+6p+24',
  '3m4m4m5m5m6m7m8m2p3p4p3s3s6m+21',
  '2m3m4m3p4p5p1s3s9s9s6z6z6z+2s+14',
  '1m3m3m3m6m7m8m1p2p3p1s2s3s+2m+13',
  '2m3m4m4p4p6p7p4s5s5s6s6s7s+5p+12',
  '3m4m5m5m6m7m3p3p1s2s3s4s5s6s+13',
  '1m1m2m2m3m3m4m4m6p8p5s6s7s7p+21',
  '4m4m5m5m6m6m1p2p3p2s3s7z7z+4s+14',
  '1m2m3m4m5m6m7m8m2p3p4p5p5p+9m+11',
  '2m3m4m6m6m7m7m6p7p8p3s3s3s7m+13',
  '4m5m6m3p4p4p5p5p1s1s4s5s6s3p+22',
  '5m5m2p3p4p7p7p8p8p9p1s2s3s9p+21',
  '2m2m3m3m4m4m3s4s5s4z4z7z7z7z+21',
  '1s1s2s2s3s3s4s4s4s7s8s8s9s+8s+12',
  '2m3m4m7m7m5p6p7p4s5s7s8s9s3s+13',
  '1m2m3m3m4m5m6m7m8m3p3p5p6p4p+24',
  '1m1m2m3m4m4m5m6m4p5p7s8s9s6p+22',
  '5m6m8m8m4p5p6p3s3s3s7s7s7s+7m+21',
  '1m2m3m1p2p3p9p9p1s2s6s7s8s+3s+23',
  '2m3m3m4m4m5m4s5s5s5s6s7s8s+9s+14',
  '1m2m3m4m2p3p4p2s3s4s1z1z1z+4m+14',
  '1m2m3m6m7m3p4p5p6p6p4s5s6s5m+14',
  '1m2m3m4m5m5m5m6m3p4p7s8s9s5p+24',
  '3p4p5p6p6p6p7p2s3s4s6s7s8s7p+23',
  '4m5m5m6m7m7m8m9m1p1p5p6p7p+3m+14',
  '4m5m2p3p4p6p7p8p3s4s5s9s9s+6m+12',
  '4m6m3p4p5p6p7p8p4s4s6s7s8s5m+24',
  '6m7m8m3p4p4p5p5p6p9p9p6s7s5s+13',
  '3m3m1p2p3p4p5p6p4s5s5s6s6s+7s+23',
  '2m3m4m4p5p6p9p9p3s4s5s7s8s9s+14',
  '3m4m4m4m5m6m7m8m4p5p5s6s7s6p+21',
  '2m3m4m6m7m2p2p5p5p5p5s6s7s+8m+22',
  '9m2p2p1s1s2s2s3s3s4s4s6s6s+9m+11',
  '7m8m9m4p5p6p3s4s5s6s7s8s8s+2s+23',
  '2m2m5m6m6m7m8m2p3p4p2s3s4s+4m+22',
  '4m5m2p2p3p3p4p4p5p6p7p4s4s+3m+11',
  '2m3m4m6m7m8m3p4p5p5p6p8s8s4p+13',
  '5m6m7m2p3p4p5p5p5p6p7p3s4s+2s+12',
  '3m4m5m7m7m4p5p6p3s4s4s5s6s+2s+11',
  '2m3m4m5m6m7m4p5p6p4s4s6s7s8s+12',
  '2m3m3m4m4m5m8m8m3p4p4p5p6p+5p+23',
  '2m3m4m5m6m7m3p4p5p4s5s4z4z+3s+12',
  '2p2p3p6p6p9p9p2s2s5z5z6z6z+3p+23',
  '1m1m2p3p4p5p6p7p7p8p9p7s8s6s+11',
  '5m6m7m7p7p8p8p9p2s3s4s8s8s9p+24',
  '2m3m5m6m7m4p4p4s4s5s5s6s6s+1m+12',
  '6m7m8m1p2p3p6p7p8p1s1s6s7s8s+12',
  '1m1m8m8m3p3p7p7p1s1s4z4z5z5z+12',
  '1m1m2m3m4m6m7m5p6p7p1s2s3s+5m+13',
  '1m1m2m3m4m2p3p4p1s2s3s4s5s6s+21',
  '3m4m5m5m5p5p6p6p7p7p4s5s6s2m+14',
  '4m4m6m7m5p6p7p1s2s3s4s5s6s+5m+22',
  '3m4m1p2p3p4p5p6p4s5s6s8s8s+2m+11',
  '1m1m4m5m6m7m8m9m2p3p4p6s7s8s+31',
  '5m7m2p3p4p4s4s5s5s6s6s7s7s6m+12',
  '9m9m4p5p1s2s3s5s6s7s7s8s9s+6p+11',
  '6m7m8m3p3p4p5p6p6s7s5z5z5z5s+14',
  '4m5m6m4p5p5p6p7p2s2s3s4s5s3p+11',
  '2m2m5m6m7m8m9m2s3s4s6s7s8s4m+12',
  '1m1m2p3p3p4p4p6p7p8p5s6s7s2p+12',
  '5m7m2p3p4p8p8p3s4s5s4z4z4z6m+24',
  '2m3m4m2p3p4p5p6p1s2s3s9s9s+1p+24',
  '6m6m2p2p5p5p6p6p2s2s5s5s7z+7z+24',
  '3p4p5p4s5s7s7s8s8s9s9s2z2z+6s+12',
  '5m6m7m1p1p6p7p8p3s4s6s7s8s5s+13',
  '6m7m8m3p3p4p4p4s5s6s7s7s7s+3p+12',
  '1p9p9p1s1s4s4s7s7s8s8s4z4z+1p+13',
  '2p3p4p5p5p6p7p7p6s7s8s1z1z6p+22',
  '4m5m6m1p1p4p5p6s7s8s5z5z5z+3p+11',
  '5m6m7m4p5p6p3s4s4s4s7s8s9s+5s+14',
  '2m2m3m4m5m3p4p4p5p6p4s5s6s+5p+14',
  '2m3m4m5p6p7p7p8p9p3s3s6s7s5s+13',
  '7m7m7m8p8p3s4s5s7s8s9s2z2z+2z+22',
  '6m7m8m7p7p1s1s2s2s3s6s6s6s+3s+21',
  '6m7m8m2p3p4p6p7p3s4s5s3z3z8p+14',
  '6m7m8m3p4p5p5p7p4s4s5s6s7s6p+14',
  '5m6m7m1p2p3p4p4p7p8p9p3s4s+5s+12',
  '8m8m2s3s3s4s5s6s7s8s6z6z6z+1s+11',
  '7m7m8m8m9m9m4p6p2s3s4s6s6s+5p+13',
  '1m2m3m6m7m9m9m2p3p4p4s5s6s8m+11',
  '3m4m6m7m8m5p6p7p4s5s6s9s9s+5m+11',
  '5m6m6m7m7m8m6p7p5s5s5s8s8s+5p+22',
  '4m6m6m7m8m4p4p6p7p8p6s7s8s+5m+14',
  '7m8m9m1p2p3p6p7p4s5s6s8s8s+8p+14',
  '4m5m7m8m9m1s2s3s5s5s7s8s9s+6m+11',
  '6m6m7m8m9m3p4p5p3s4s5s7s8s9s+12',
  '5m7m1p2p3p4s4s4s2z2z2z6z6z+6m+23',
  '2m2m3m3m5p5p7p7p3s3s8s8s5z5z+24',
  '3p4p5p1s1s2s3s3s4s4s5s6s7s8s+13',
  '1m2m3m2p3p4p6p7p8p9p9p7s8s+9s+12',
  '5m7m2p3p4p9p9p3s4s5s4z4z4z+6m+14',
  '1m2m3m3m4m5m7m8m7p8p9p2s2s9m+11',
  '2m3m4m3p4p1s2s3s4s5s6s4z4z+5p+21',
  '1m2m3m7m8m9m5s6s7s8s9s2z2z+7s+14',
  '5m5m6m7m8m4p5p3s4s5s6s7s8s+3p+14',
  '5m6m7m2p3p4p6p6p3s4s5s6s7s2s+24',
  '1p1p1p2p2p3p3p4p5p5p2s3s4s+4p+11',
  '3m3m6m6m8p8p5s5s6s6s4z4z5z+5z+23',
  '3m4m5m4p5p6p4s5s6s6s7s8s9s+3s+22',
  '1m2m3m6m7m2p3p4p9p9p2s3s4s8m+24',
  '1m2m3m6m7m5p5p6p7p8p5s6s7s+5m+23',
  '3m4m5m5p6p7p3s3s4s4s5s8s8s+5s+22',
  '6m7m8m3p4p6p7p8p2s3s4s9s9s5p+13',
  '1m1m5p6p7p1s3s4s5s6s5z5z5z2s+23',
  '4m5m6m7m7m2p3p4p4s5s6s7z7z7z+24',
  '5m6m7m7p8p9p1s1s1s3s5z5z5z+2s+21',
  '6m7m7m8m8m9m2p2p9s9s9s2z2z+2z+23',
  '5m6m7m7m8m2p3p4p9p9p7s8s9s+9m+11',
  '4m5m6m7m7m2p3p4p2s3s4s4s6s+5s+22',
  '5m5m7m8m9m2p3p5p6p7p6s7s8s+1p+22',
  '2m2m4m4m9m9m6p6p1s2s2s5z5z+1s+21',
  '3m4m5m6m7m1p1p7p8p9p2s3s4s8m+22',
  '7m8m9m2p3p4p5p6p7p8s8s1z1z1z+21',
  '3m4m6m7m8m1p1p4p5p6p7p8p9p5m+22',
  '1m1m3m4m2p3p4p1s2s3s4s5s6s2m+24',
  '2m3m4m5m6m3p4p5p6s7s8s9s9s1m+11',
  '4m5m2p3p3p3p4p5p6p7p5s6s7s+3m+12',
  '3m4m6m7m8m2p3p4p5p5p5p3s3s+5m+23',
  '2m4m6m7m8m4p5p6p3s4s5s6s6s3m+11',
  '2m3m4m5p6p7p1s1s4s4s5s5s6s+6s+23',
  '6m7m8m1p1p7p8p2s3s4s4s5s6s+6p+21',
  '4m4m4m6m7m8m3p4p5p6s7s7s8s+7s+12',
  '1m1m3p4p5p6p7p2s3s4s6s7s8s+2p+24',
  '2m2m5m6m7m4p5p6p7p8p6s6s6s+3p+21',
  '1m8m8m2p2p3p3p5p5p1s1s5s5s+1m+12',
  '8m8m9m9m2s2s4s4s6s6s2z4z4z+2z+12',
  '3m4m7p8p9p1s1s3s4s5s7s8s9s5m+21',
  '3m3m4m4m5m5m7m7m6p7p6s7s8s+8p+21',
  '2m2m3m4m5m4p5p6p1s2s3s5s6s+7s+12',
  '4m4m6m7m8m2p3p3p3p3p4p5p6p+4p+24',
  '7m6p6p7p7p4s4s5s5s4z4z6z6z7m+22',
  '2m3m4m5m3p4p5p6p7p8p5z5z5z5m+12',
  '7m8m9m1p2p3p6p7p7s8s9s9s9s+8p+23',
  '3m3m1p2p3p3p4p1s2s3s5s6s7s+2p+13',
  '1m2m3m5p5p7p8p9p4s4s4z4z4z5p+14',
  '3m4m5m6m7m1s1s3s4s5s7s8s9s2m+12',
  '6m7m8m5p6p7p2s4s9s9s5z5z5z+3s+12',
  '3m3m4m5m6m5p6p7p2s2s5s6s7s+3m+22',
  '3m3m3m6m6m6m2p3p4p7p7p6s8s+7s+24',
  '3m4m5m6m6m7m8m9m5p6p7p6s7s+5s+24',
  '6m7m8m6p7p2s3s4s9s9s7z7z7z+8p+23',
  '4m4m7m7m8p8p1s1s6s6s4z5z5z+4z+23',
  '2m3m4m4m5m5m6m6m4p4p2s3s4s4m+23',
  '2m3m4m1p2p3p5p5p7p8p9p3s4s+5s+11',
  '3m4m5m7m8m9m4p5p3s3s7z7z7z+6p+11',
  '2m2m3m3m3m5m6m7m6p7p5s6s7s5p+14',
  '3m4m3p3p5p6p7p2s3s4s5s6s7s+2m+14',
  '3m4m5m6m7m6p7p8p3s3s4s5s6s8m+13',
  '4m4m7m7m6p6p8p8p2s2s8s6z6z+8s+22',
  '5m5m6m7m7m3p4p5p7p7p7p8p8p6m+12',
  '3m4m5m7m7m3p4p4p5p6p6s7s8s2p+22',
  '2m3m8m8m2p3p4p7p8p9p2s3s4s1m+11',
  '2m3m7m8m9m2p2p3p4p5p4s5s6s+1m+12',
  '3m4m5m6m7m8m9m9m2p3p6p7p8p+1p+23',
  '5m6m7m3p4p5p1s1s2s3s4s5s6s4s+22',
  '2m2m2p2p3p3p7p7p8s8s9s9s2z+2z+12',
  '7m8m9m3p4p6p7p8p2s3s4s5s5s+5p+24',
  '2m2m3m3m7p7p4s4s7s7s5z6z6z+5z+11',
  '2m3m4m4m5m6m7p8p5s6s7s2z2z6p+14',
  '2m5m5m6m6m8p8p5s5s6s6s8s8s2m+24',
  '6p6p7p7p8p8p1s1s2s3s4s4z4z+4z+13',
  '2m4m2p2p3p3p4p4p2s3s4s9s9s+3m+21',
  '3m3m7m8m9m3p4p2s3s4s6s7s8s+2p+22',
  '3m4m7m8m9m2p3p4p6p6p7p8p9p+2m+21',
  '6m7m8m1p1p2p3p4p6s7s6z6z6z5s+12',
  '2m3m4m4p4p5p5p6p5s5s6s7s8s3p+12',
  '2m2m5m5m7m7m9m4p4p6s6s7z7z+9m+11',
  '2m3m4m5p7p8p8p3s4s5s5s6s7s+6p+22',
  '4m4m6m7m7m6p6p8s8s9s9s1z1z+6m+24',
  '3m3m7m9m3s3s3s7s8s9s7z7z7z8m+21',
  '2m3m5m6m7m1s1s4s5s6s7s8s9s+1m+21',
  '1m2m3m1p2p2p3p3p1s2s3s4s4s1p+13',
  '8m8m1p2p3p5p6p7p6s7s2z2z2z+5s+24',
  '4m5m5m6m6m1p1p6s7s8s7z7z7z+4m+21',
  '2m3m4m4m5m6m3p3p3p8p8p2s2s+2s+21',
  '3m4m3p4p5p6p7p8p9p9p4s5s6s+5m+22',
  '5m6m4p5p6p3s4s5s7s8s9s3z3z+7m+14',
  '6m7m8m4p5p5p6p7p7p7p3s4s5s+5p+24',
  '1m2m3m4m5m6m7m8m9m3p4p3s3s+2p+22',
  '3m3m5m6m2p3p4p1s2s3s4s5s6s7m+13',
  '1m2m4m5m6m3p4p5p6p6p1z1z1z3m+13',
  '3m4m7m7m2p3p4p3s4s5s7s8s9s+2m+12',
  '1m3m4p5p6p7p7p8p8p9p9p1s1s2m+22',
  '4m5m1p2p3p6p7p8p5s6s7s8s8s+3m+24',
  '1m1m8m8m1p1p2p2p9p9p3z3z5z+5z+21',
  '2m2m3m3m4m4m6p6p6p3s3s1z1z3s+11',
  '2m3m2p3p4p6p7p8p4s4s5s5s5s4m+11',
  '5m6m1p1p5p5p6p6p7p7p4s5s6s+4m+21',
  '7m7m3p4p2s3s4s5s5s5s6s7s8s+2p+12',
  '1m2m2m2m3m3m4m5m7m7m8m8m9m+9m+14',
  '2m3m3m4m5m3p4p5p7p7p2s3s4s+4m+14',
  '2p3p4p8p8p8p1s1s1s4s4s1z1z4s+13',
  '1m1m1m2m3m4p5p6p7p8p5s6s7s6p+21',
  '6m6m5p5p6p6p9p9p6s6s2z6z6z+2z+24',
  '2m2m3m4m5m4p5p6p2s2s3s3s4s+1s+23',
  '7m8m9m3p3p4p4p5p4s4s5s6s7s5p+13',
  '6m7m8m2p3p3s3s3s4s5s7s8s9s+4p+11',
  '2m2m4m5m6m3p4p2s3s4s4s5s6s+2p+14',
  '7m7m7m8m9m7p8p9p7s8s7z7z7z+9s+12',
  '2m2m4p4p5p5p5s5s6s6s7s5z5z7s+21',
  '5m5m2p3p4p5p6p5s6s7s2z2z2z4p+22',
  '8m8m1s1s9s9s2z2z3z5z5z6z6z+3z+23',
  '8m9m4p4p7p8p9p5s6s7s7s8s9s7m+21',
  '1p2p3p4p4p5p5p6p6p8p9p1z1z7p+13',
  '3m3m3p4p5p6p7p4s5s6s7s8s9s8p+23',
  '1m2m3m6m7m6p7p8p9p9p4s5s6s+8m+14',
  '2m2m6m7m8m2p3p4p6p7p5s6s7s+8p+22',
  '2m3m4m6m7m3p3p2s3s4s6s7s8s8m+21',
  '2m2m7m8m9m4p5p2s3s3s4s4s5s+6p+13',
  '3m4m4m5m5m6m4p5p6p6p6p2s4s+3s+14',
  '5m6m7m8m5p6p7p3s3s4s4s5s5s+5m+11',
  '2m3m4m4m5m2p3p4p6s6s7s8s9s6m+24',
  '3m4m4p4p6p7p8p2s3s4s5s6s7s5m+13',
  '3m4m5m7m8m7p7p6s6s6s7z7z7z9m+21',
  '1m2m2m3m3m4m1p2p3p3p4p6p6p2p+21',
  '1m2m3m3m4m5m3p4p5p3s4s7s7s2s+24',
  '2m3m7m8m9m3p3p7p8p9p2s3s4s1m+13',
  '4m5m9m9m1p2p3p5p6p7p2s3s4s+6m+13',
  '2m4m6m7m8m2s3s4s5s6s7s8s8s3m+21',
  '4m4m1p2p3p1s2s3s3s4s5s6s7s8s+24',
  '1m1m8m9m1p2p3p7p8p9p1s2s3s+7m+23',
  '2m3m4m3p4p5p7p7p4s5s6s7s8s9s+13',
  '2m2m6m7m8m6p7p8p4s5s6s6s7s+5s+22',
  '1m1m2m2m3m8m8m7p8p9p1s2s3s+3m+13',
  '2m2m2p2p4p4p2s2s5s5s1z2z2z1z+24',
  '2m3m4m4p5p6p6p7p8p8p8p7s8s6s+21',
  '2m3m3p4p5p1s2s3s6s6s7s8s9s1m+12',
  '2m3m4m6m7m1s2s3s4s4s6s7s8s8m+22',
  '3m4m5m2p3p5p6p7p5s6s7s4z4z+4p+13',
  '1m2m3m6m6m3p4p5s6s6s7s7s8s+5p+23',
  '1m2m3m3p4p5p7p8p3s4s5s8s8s+9p+24',
  '4m4m4m6m6m3s4s5s6s6s7s8s8s7s+22',
  '3m4m5m6m7m8m2p3p5p5p5s6s7s+4p+21',
  '3m3m5m6m7m1p2p2s3s4s2z2z2z+3p+23',
  '3p4p5p6p3s4s5s7s8s9s5z5z5z+6p+13',
  '7m8m9m9m9m6p7p8p1s2s3s5s6s7s+11',
  '2m3m5m6m7m1p1p2p2p3p3p6p6p+4m+21',
  '4m5m6m4p5p6p3s4s4s5s6s4z4z2s+22',
  '5m6m8m8m3p4p5p6p7p8p3s4s5s+7m+22',
  '1m1m2m6m6m7m7m9m9m2z2z3z3z2m+14',
  '3m3m5m6m3p4p5p3s4s5s1z1z1z7m+11',
  '3m3m5m6m7m2p3p4p4p5p2s2s2s6p+11',
  '2m3m4m5p5p5p3s3s4s4s5s7s7s+2s+11',
  '3p4p5p1s2s3s5s6s6s7s8s4z4z4s+13',
  '1m1m2m2m2m3m3m4m4m5m6m4p4p3m+11',
  '2m2m2m3m4m6m7m8m3p4p5p5s7s+6s+21',
  '3p4p5p5p6p3s3s3s5s5s6s7s8s+7p+13',
  '2m3m4m5m6m6m7m7m8m6p7p5s5s+8p+22',
  '2m3m4m8m8m2p2p3p3p4p4p6s7s8s+13',
  '2m3m4m2p3p4p6p7p8p2s2s7z7z7z+13',
  '5m6m3p4p5p7p8p9p4s4s5s6s7s+4m+24',
  '7m7m8m8m9m9m7p8p9p5s6s7s2z2z+22',
  '3m3m2p3p4p6p7p8p5s6s8s8s8s+4s+11',
  '6m6m5p5p6s6s9s9s2z2z5z6z6z+5z+14',
  '1m2m2m3m3m4m6p6p3s4s7s8s9s5s+22',
  '2m3m6p6p6p7p8p6s7s7s8s8s9s+1m+13',
  '6m6m1p2p3p5p6p6s6s7s7s8s8s+7p+11',
  '3m4m5m9p9p1s2s3s4s5s6s8s9s7s+11',
  '3m4m5p5p7p8p9p1s2s3s6s7s8s5m+13',
  '1m2m3m5m6m7m4p4p7s8s8s9s9s+7s+11',
  '5m6m2p2p2p4p4p6p7p8p2s3s4s+7m+23',
  '2m3m4m6m7m4p5p6p5s6s7s8s8s8m+21',
  '2m3m4m6m7m8m2p6p7p8p5s6s7s+2p+23',
  '5m5m6p7p7p8p8p9p7s8s9s6z6z+6z+13',
  '4m5m5m6m7m4p5p6p7p7p4s5s6s3m+24',
  '3m4m5m7m8m9m5p5p2s3s4s4s5s3s+22',
  '5m6m2p2p5p6p7p5s6s7s7s8s9s+7m+22',
  '5m5m1p1p4p5p6p6s7s8s1z1z1z+1p+12',
  '2m3m4m5m6m7m1p2p3p9p9p5s6s+4s+22',
  '5m3p3p4p4p8p8p2s2s5z5z6z6z+5m+11',
  '7m8m9m1p2p3p5p6p9p9p6s7s8s7p+12',
  '6p6p6p7p8p2s3s4s4s5s6s8s8s+8s+14',
  '1m1m2p2p6p6p8p8p3s3s4s3z3z4s+14',
  '3m4m5m5m6m7m8m8m3p4p7p8p9p5p+11',
  '2m3m4m5m6m7m9m9m2p3p4p2s4s+3s+24',
  '1m1m5m6m3p4p5p3s4s5s6s7s8s7m+23',
  '2m3m4m9m9m2s3s4s4s5s7s8s9s+6s+22',
  '5m6m6p7p8p1s2s3s5s5s6s7s8s+7m+11',
  '6m7m8m1p2p3p6p7p6s6s7s8s9s8p+22',
  '2m2m5m6m7m5p6p7p2s3s7s8s9s4s+11',
  '6m6m7m8m9m1p2p3p7s8s9s2z2z+2z+21',
  '5m6m3p4p5p7p8p9p4s4s6s7s8s4m+12',
  '7m7m1p1p2p2p3p3p5p5p8p8p2z+2z+12',
  '3m5m2p3p4p4p5p6p5s5s6s7s8s+4m+22',
  '1m2m3m3p4p5p7p8p6s7s8s4z4z9p+12',
  '2m3m4m8m2p2p2p5p6p7p2s3s4s+8m+14',
  '4m4m4m5m6m7m4p5p5p6p6p7p8p+8p+12',
  '1m1m5m6m5p6p7p5s5s6s6s7s7s+4m+22',
  '3m4m5m6m6m6s7s9s9s9s2z2z2z8s+24',
  '3m4m5m7m8m3s3s5s5s6s6s7s7s6m+23',
  '7m7m7m3p3p1s2s3s4s5s6s8s9s+7s+22',
  '5m6m7m2p2p3p3p4p6s6s7s8s9s1p+14',
  '2m3m4m7m7m7m3p3p4p5p6p6p7p8p+23',
  '4m5m6m6m7m3p3p4p5p6p7s8s9s5m+11',
  '3m4m5m4p5p3s3s5s6s6s7s7s8s+3p+21',
  '5m6m7m8m8m1s2s3s4s4s4s3z3z+3z+13',
  '5m6m7m9m9m3p5p5p6p7p5s6s7s4p+14',
  '3m4m5m7m8m1p2p3p5p6p7p2s2s+9m+12',
  '2p2p4p5p6p3s4s5s7s8s6z6z6z+6s+22',
  '6m7m3p4p5p8p8p4s4s4s8s8s8s8m+22',
  '1m2m3m6m7m8m9m9m5p6p5s6s7s+7p+14',
  '6m6m7m8m9m1s2s3s3s4s1z1z1z+5s+11',
  '4m5m6m4p5p7p8p9p2s3s4s5s5s3p+21',
  '1p2p2p3p3p4p4p5p7p8p9p9p9p+6p+11',
  '3m4m5m5m6m1s1s2s3s4s5s6s7s7m+22',
  '2m3m4m7m7m7m7p8p4s5s6s7s7s6p+13',
  '3m3m1p1p6p6p1s1s5s5s4z5z5z+4z+14',
  '4m4m5m7m7m3p3p1s1s7s7s5z5z+5m+22',
  '5m5m4p5p6p7p8p9p2s3s4s6s7s5s+13',
  '3m4m2p3p4p2s3s4s6s7s8s7z7z+2m+12',
  '3p3p6p6p7p7p8p3s3s3s5s6s7s5p+21',
  '7m8m9m7p7p1s2s2s3s3s7s8s9s+1s+14',
  '1m2m3m4m5m7m8m9m3s3s7z7z7z+6m+22',
  '6m7m8m5p6p7p5s6s7s1z1z7z7z1z+13',
  '2m2m5m6m7m7m8m9m3p4p5p7p8p+6p+11',
  '7m8m9m4p4p5p6p7p3s4s5s6s7s8s+23',
  '2m3m4m5m5m6m7m8m2p3p6s7s8s+1p+13',
  '5m5m6m7m8m2p2p5p6p7p6s7s8s5m+21',
  '5m6m7m3p4p5p6p7p8p5s5s6s8s+7s+11',
  '3m4m6m7m8m1s2s3s5s6s7s8s8s2m+14',
  '1m2m3m4m4m6m7m8m3p4p5p5s6s+4s+14',
  '3m3m4m4m5m5m8m8m7p8p5s6s7s+9p+23',
  '5m6m2p3p4p2s2s6s7s7s8s8s9s7m+11',
  '4m5m6m6m7m8m4p4p3s4s1z1z1z+2s+11',
  '1m2m3m6m7m4p5p6p9p9p3s4s5s+8m+21',
  '3m4m5m3p4p5p1s2s3s2z2z7z7z+7z+12',
  '1p2p2p3p3p4p1s5s6s7s7z7z7z+1s+11',
  '2m3m4m3p5p6p6p1s2s3s7z7z7z+4p+14',
  '3m4m5m3p4p2s2s4s5s6s7s8s9s2p+22',
  '5m5m6m6m1p1s1s5s5s8s8s3z3z+1p+21',
  '2m3m3m4m4m5m2p3p4p6p7p2s2s8p+14',
  '1m2m3m1p1p6p7p8p4s5s7s8s9s3s+14',
  '2m3m4m5m5m5m3p4p5p6p8p5s5s7p+12',
  '6m6m6m3p4p6p7p8p2s2s2s5s5s5p+22',
  '3m4m5m6m7m5p6p7p9p9p7s8s9s2m+21',
  '2m3m4m1p1p1s2s3s4s6s7s8s9s5s+11',
  '7m8m9m4p5p7p8p9p3s4s5s6s6s+3p+14',
  '4m4m5m5m6m6m5s6s7s7s9s1z1z+8s+23',
  '2m3m4m5m6m7m8m2p3p4p4s5s6s8m+21',
  '2m2m3m4m5m4p6p4s4s5s5s6s6s+5p+11',
  '2m3m4m7m8m9m7p8p8p8p5s6s7s+9p+11',
  '1m2m3m6p6p1s2s3s5s6s7s8s9s+7s+13',
  '2m3m4m5m6m4p4p6p7p8p6s7s8s+7m+24',
  '6m7m8m3p4p5p7p8p5s6s7s8s8s+6p+11',
  '4m5m6m1p2p3p4p5p7p8p9p7s7s6p+14',
  '5m6m6m7m7m8m2p4p6p7p8p5s5s3p+13',
  '3m3m3m4m4m6m8m6p7p8p6s7s8s7m+14',
  '1m2m3m1p2p1s2s3s1z1z4z4z4z+3p+12',
  '2m3m4m6m6m7m8m9m3p4p5p6s7s+5s+13',
  '4m4m6m7m8m2p3p2s3s4s6s7s8s+4p+14',
  '4m5m7m8m9m4p5p6p6s7s8s3z3z6m+24',
  '3m4m5m8p8p8p2s4s5s5s7z7z7z+3s+13',
  '5m5m3p5p2s3s4s4s5s5s6s6s7s+4p+22',
  '2m2m3m4m4m4p5p5p6p6p7p1z1z+3m+12',
  '1m2m3m1p1p4p5p6p4s5s7s8s9s3s+12',
  '4m5m6m2p3p4p5p6p7p5s5s7s8s+9s+14',
  '2m3m4m7m7m6p7p8p4s5s6s7s8s9s+23',
  '4m4m5m5m6m6m6p6p7p8p9p5s6s7s+14',
  '4m5m6m7m8m9m5p6p7p2s2s3s4s5s+22',
  '1m1m2m3m4p5p6p7p8p9p5s6s7s4m+24',
  '1m2m3m4m4m3p4p5p7p8p9p6s7s8s+22',
  '5m5m7m8m9m4p5p6p6p7p6s7s8s+5p+21',
  '3m3m3m4m5m6m2p3p7p7p3s4s5s4p+21',
  '1m1m6m7m4p5p6p6p7p8p2s3s4s+8m+23',
  '1m2m3m9m9m5p6p7p4s5s6s7s8s6s+11',
  '2m3m3m4m5m7m8m9m1s2s3s3s3s1m+11',
  '5m6m7m8m9m1p2p3p6s7s8s9s9s4m+24',
  '5m6m7p8p9p2s3s4s6s7s8s4z4z+7m+22',
  '3m4m5m1p1p3p4p5p6p7p4s5s6s2p+13',
  '3m3m4m5m3p4p5p6p7p8p3s4s5s6m+22',
  '1m2m3m7m8m9m2p2p6p7p2s3s4s5p+11',
  '3m4m4m5m6m6m6m3p3p3p5s6s7s+2m+11',
  '3m4m5m7m8m9m4p5p6p5s6s7s7s4s+12',
  '1m1m3m3m9m9m8p8p5s5s1z3z3z+1z+14',
  '4m5m6p7p8p2s3s4s5s6s7s8s8s6m+13',
  '4m4m2p3p4p8p8p5s6s7s7z7z7z+8p+14',
  '7m8m9m4p4p5p6p7p7p8p4s5s6s+6p+13',
  '2m2m4m5m6p7p8p8p8p8p2s3s4s+6m+14',
  '5m6m7m3p4p5p7p7p2s3s6s7s8s+1s+14',
  '3m4m5m5m6m5p6p7p4s5s6s3z3z+7m+11',
  '1m2m3m4p4p5p6p7p6s7s7s8s8s+9s+23',
  '1p2p3p7p8p1s2s3s7s8s9s1z1z+9p+22',
  '3m4m5m6m7m8m6p6p4s5s6s7s8s9s+21',
  '3p3p4p5p6p7p7p2s3s4s4s5s6s7p+24',
  '2m3m2p2p2p5p5p6p7p8p7z7z7z4m+24',
  '3m4m7m7m5p6p7p1s2s3s6s7s8s5m+12',
  '1m3p3p1s1s8s8s1z1z3z3z5z5z1m+14',
  '2m2m3m3m9m9m4p4p7p7p7s7s4z4z+11',
  '7m7m3p4p5p5p6p3s4s5s7s8s9s7p+21',
  '1m1m1m3p4p6p6p7p7p8p8p4z4z2p+11',
  '7m8m4p5p6p2s2s3s4s5s6s7s8s+9m+11',
  '3m4m4m5m5m6m3p3p7p8p3s4s5s+6p+12',
  '2m2m2s2s3s3s8s8s4z4z5z5z6z+6z+11',
  '4m4m4p5p5p5p6p6p7p8p3s4s5s+5p+12',
  '3m4m5m7m7m3p4p5p6p7p8p6s7s+5s+12',
  '6m6m2p3p4p6p7p8p5s6s7s7s8s+9s+24',
  '1p2p3p7p8p2s2s3s4s5s7s8s9s+9p+13',
  '6m7m8m1p1p3s4s4s5s5s7s8s9s+3s+12',
  '5m6m7m1p2p3p3p3p6s7s6z6z6z5s+13',
  '4m5m6m7m8m9m4p5p6p4s5s8s8s+3s+22',
  '4m5m6m3p4p5p5p6p4s5s6s2z2z4p+11',
  '4m5m4p5p6p1s2s3s5s6s7s9s9s+3m+12',
  '1m2m3m4m5m2p3p4p6s7s8s9s9s+6m+22',
  '4p4p5p6p1s2s3s3z3z3z7z7z7z+4p+21',
  '6m7m8m7p8p9p2s2s3s4s4s7s7s+3s+23',
  '1p2p2p3p3p4p6p7p8p2s3s8s8s+4s+23',
  '2m3m4m6m8m2s3s4s6s6s7z7z7z+7m+14',
  '3m4m5m6m7m8m5p6p2s3s4s7s7s7p+11',
  '7m7m1p2p3p6p7p8p7s7s8s9s9s+8s+13',
  '3m4m1p2p3p3p4p5p3s4s5s6z6z5m+11',
  '6m6m6m3p5p6p7p8p5s5s6s7s8s+4p+21',
  '3m4m5m1p2p3p5p6p3s4s5s8s8s4p+14',
  '4m5m6m7m8m3p3p2s3s4s6s7s8s+9m+22',
  '2m2m5m6m7m6p7p8p3s4s6s7s8s+2s+13',
  '2m3m4m6m6m5p6p7p7p8p9p6s7s+8s+13',
  '4p5p6p7p8p2s2s5s6s6s7s7s8s6p+12',
  '3m3m6m7m8m2p3p4p6p7p8p6s7s+8s+22',
  '3m4m5m6m7m8m9m7p8p9p7z7z7z3m+12',
  '1m1m2m2m3m3m3s4s5s6s7s8s9s+9s+21',
  '2m2m3m4m4m8m8m8m4p5p6p7p7p3m+23',
  '2m3m6m6m6p7p8p2s3s4s5s6s7s4m+21',
  '3m4m5m5m6m7m8m9m7p7p1z1z1z+4m+21',
  '5m6m4p5p6p7p8p9p1s2s3s4s4s7m+22',
  '3p3p5p6p7p3s4s5s8s8s8s2z2z2z+12',
  '3m4m6m7m8m5p6p7p6s7s8s2z2z2m+11',
  '6m7m2p3p4p1s2s3s4s5s6s7s7s8m+12',
  '2m3m3p4p5p6p7p8p9p9p4s5s6s4m+13',
  '7m8m5p6p7p3s3s4s5s6s7s8s9s6m+21',
  '4m5m7m8m9m7p8p9p2s2s4s5s6s3m+11',
  '4m5m5m6m7m1p1p7p8p9p4s5s6s+3m+21',
  '4m4m4m5m6m7m3p4p4s5s6s8s8s+5p+12',
  '3m4m5m6m6m7m7m8m8m2s3s5s5s+1s+23',
  '2m3m8m8m1p2p3p3s4s5s5s6s7s1m+22',
  '6m6m7m8m8m1p2p3p4p5p6p6s6s+7m+12',
  '2p2p5p6p6p7p8p3s4s5s6s7s8s7p+22',
  '3m4m5m8m8m2p3p1s2s3s4s5s6s4p+23',
  '4m5m6m6m7m4p5p6p3s3s4s5s6s+8m+13',
  '1m2m3m6m6m6m6p7p8p6s6s2z2z+2z+22',
  '2m2m7m8m9m7p7p7p7s8s5z5z5z+6s+12',
  '3p3p8p8p1s1s2s2s3s3s6z7z7z+6z+21',
  '2m2m6m6m8m8m6p6p1s1s4z4z7z+7z+12',
  '1m2m3m3m4m7m8m9m2p3p4p5s5s2m+21',
  '7m7m8m8m7p7p3s3s6s6s8s8s5z+5z+24',
  '2m3m4m6m7m7m8m9m2p3p3p3p4p8m+12',
  '1m1m2p2p8p8p3s3s2z2z3z3z5z+5z+11',
  '7m8m9m5p5p6p6p7p6s7s8s8s8s4p+21',
  '5m6m7m8m8m2s2s4s4s5s5s6s6s+2s+12',
  '1m1m2m3m4m2p3p4p6p7p8p4s5s+6s+21',
  '2m3m4m2p3p4p3s4s5s6s7s3z3z8s+12',
  '4m4m7m8m9m4p5p2s3s4s5s6s7s6p+12',
  '4m5m6m6m6m2p2p5s6s6s7s7s8s+2p+13',
  '3m4m5m6m7m8m8m8m5p6p8s8s8s+4p+22',
  '2m3m4m2p2p4p5p6p7p8p9p5s6s+7s+12',
  '5m6m6m7m7m6p6p1s1s5s5s6s6s5m+13',
  '5m6m7m4p5p6p7p8p2s2s3s4s5s+9p+24',
  '4m5m6m1p2p3p6p7p8p3s4s9s9s+2s+21',
  '2m3m4m5m5m3p4p5p6p7p5s6s7s2p+11',
  '3m3m2p3p4p6p7p8p4s4s5s5s6s6s+22',
  '3m4m5m7m8m9m4p5p5p6p6p8s8s4p+21',
  '5m5m5m6m7m8m3p4p3s3s5s5s5s+2p+22',
  '3m4m5m7m7m3p4p6p7p8p5s6s7s5p+14',
  '3m4m5m9m9m7p8p9p3s4s5s5s6s7s+12',
  '4m5m5m5m1p2p3p2s3s4s7s8s9s3m+21',
  '1m1m3m4m4p5p6p7p8p9p1s2s3s+2m+12',
  '4m4m7m2p2p9p9p2s2s5s5s5z5z+7m+11',
  '4m5m7m7m6p7p8p2s3s4s5s6s7s+6m+23',
  '7m7m7m5p7p8p8p3s3s3s5s6s7s6p+22',
  '5m6m7m4p5p1s2s3s5s6s7s4z4z+3p+22',
  '4p4p4p5p5p7p8p9p1s2s2s3s3s+1s+21',
  '2m2m4m4m7m7m7m2p3p4p7s7s7s+4m+11',
  '2m3m4m5m5m2p3p4p3s4s6s7s8s+5s+21',
  '6m6m6m2p3p4p7p7p2s3s4s6s6s+7p+21',
  '4m5m6m7m8m9m3p4p5p7p8p4s4s+9p+23',
  '1m2m3m5m6m1p2p3p7p8p9p1s1s+4m+22',
  '3m3m3p4p5p6p7p8p2s3s4s6s7s+8s+13',
  '3m3m3m4m5m4p5p6p7p7p7p4s5s3s+23',
  '4m4m3p4p5p6p7p8p4s5s6s8s8s8s+11',
  '2m3m4m4p5p1s1s2s3s4s5s6s7s+3p+11',
  '2m3m5m5m3p4p5p6p7p8p2s3s4s1m+14',
  '2m3m3m3m5m6m7m6p7p8p7s7s7s+2m+14',
  '1m2m3m3m5m7m7m8m8m9m9m1z1z+4m+24',
  '5m6m7m6p7p7p8p9p6s6s7s8s9s5p+14',
  '1p2p3p4p5p9p9p3s3s4s4s5s5s+3p+22',
  '1m1m1p2p3p9p9p9p1z1z4z4z4z+1z+13',
  '2m3m4m7m8m9m9m2p3p4p7p8p9p+9m+13',
  '4m5m6m1p2p3p5p6p7p3s3s7s8s+9s+11',
  '2m2m2m4m5m6m4p6p4s5s6s8s8s+5p+12',
  '4m5m3p4p5p2s3s4s7s8s9s9s9s+3m+14',
  '6m6m6m3p3p3p4p5p6p7p8p6s7s+8s+21',
  '1m1m4m5m7m8m9m4p5p6p4s5s6s+6m+14',
  '2m3m4m1p2p3p4p5p6p7p8p7z7z9p+23',
  '2m3m4m2p3p4p2s2s5s6s7s8s9s+7s+23',
  '2m3m4m6m6m6m7m5p6p7p5s5s5s7m+13',
  '4m5m6m8m8m8m6p7p8p2s3s8s8s4s+11',
  '1p1p5p5p7p7p9p3z3z4z4z7z7z+9p+23',
  '1m2m3m5m6m7m2p3p4p4p6s7s8s4p+14',
  '3m3m6m7m1p2p3p2s3s4s4s5s6s+8m+12',
  '5m5m6p7p8p2s3s4s5s6s6s7s8s+1s+21',
  '2m3m4m1p2p3p3p4p5p9p9p4s5s+3s+23',
  '1m2m2m3m3m3m4m4m5m6p7p8s8s+5p+12',
  '5m6m7m3s4s5s6s7s7s8s9s4z4z+8s+21',
  '3m3m4m5m5m6m6m7m2p3p4p3s4s5s+11',
  '7m8m9m2p2p4p4p5p6p6p1s2s3s5p+24',
  '5m6m4p4p6p7p8p2s2s2s4s5s6s+4m+22',
  '5m5m6m7m7m3p4p5p6p6p1z1z1z+6m+14',
  '2m2m2m5p5p5p6p6p5s5s7s7s7s6p+11',
  '1m2m3m5m6m7m8m9m8p8p6s7s8s+4m+12',
  '6p7p7p8p9p1s2s3s3s4s4s4s5s5p+21',
  '1s2s3s5s6s7s7s8s9s2z4z4z4z2z+11',
  '4m5m7m8m9m3p4p5p3s4s5s5z5z+3m+21',
  '1m1m4m5m5m6m6m7m7m8m5p6p7p3m+11',
  '3m4m5m6m7m8m6p7p8p8p3s4s5s+8p+12',
  '2m3m4m6m8m5p5p5p6p7p3s4s5s+7m+23',
  '2m3m4m5m6m7m3p4p5p4s5s9s9s3s+13',
  '3m3m7m8m9m5p6p1s2s3s5s6s7s4p+22',
  '1m2m3m4m5m7p8p9p5s6s7s9s9s6m+11',
  '4m4m5m5m1z2z2z3z3z5z5z7z7z+1z+21',
  '3m4m5m5p6p7p9p9p6s6s7s7s8s5s+11',
  '3m3m7m8m8m3p3p4p4p6p6p3s3s+7m+11',
  '7m8m4p5p6p7p8p9p4s5s6s6s6s6m+13',
  '5m5m6m8p8p1s1s3s3s4s4s5s5s+6m+24',
  '1m1m2m2m3m3m4m4m2p3p5p6p7p+4p+21',
  '6m6m3p4p5p7p7p7p4s5s5s6s7s6s+24',
  '2m3m4m6m7m9m9m1p2p3p2s3s4s+8m+22',
  '3m3m5m6m7m5p6p7p3s4s5s7s8s+9s+24',
  '5m5m6m6m7m7m6p7p8p8p1s2s3s8p+11',
  '1m2m3m5m5m1p2p3p7p9p1s2s3s8p+12',
  '2m3m4m5m5m5m8p8p2s3s4s5s6s+4s+23',
  '1m2m3m4m5m6m4p5p6p1s1s3s4s5s+11',
  '4m6m2p2p3p4p5p2s2s2s3s4s5s+5m+12',
  '1m1m3m3m4m4m5m5m2s3s2z2z2z+1s+14',
  '4m5m7m7m7p8p9p3s4s5s6s7s8s6m+11',
  '1m1m4m4m7m8m9m3s4s5s7z7z7z+1m+22',
  '5m5m1p1p8p8p6s6s8s8s4z4z6z6z+23',
  '3m4m2p3p4p6p7p8p2s3s4s7s7s2m+14',
  '2m3m4m5p6p1s1s3s4s5s6s7s8s+4p+14',
  '4m4m6m6m7p7p8p8p4s4s8s8s9s+9s+21',
  '3m3m3m4m5m5p6p7p3s4s5s7s7s+7s+13',
  '8m1p1p6p6p4s4s6s6s7s7s8s8s8m+21',
  '1m2m3m4m5m6m2p3p4p5p5p6s7s+8s+22',
  '1m2m3m4m5m6m7m8m9m2s3s1z1z+1s+14',
  '5p6p7p2s3s4s4s6s7s8s2z2z2z+1s+24',
  '4m5m6m8p8p3s3s4s5s5s6s7s8s4s+11',
  '3m4m4m5m5m6m4p4p3s4s6s7s8s5s+21',
  '1p2p3p3p4p5p6p7p8p9p9p3z3z3z+23',
  '2m3m4m4p5p6p2s3s4s4s5s8s8s6s+23',
  '6m8m6p6p6p7p8p3s4s5s6s7s8s7m+13',
  '3m4m2p3p4p4p5p6p2s3s4s6s6s2m+21',
  '7m7m1p1p6p6p7p8p8p6s6s3z3z+7p+11',
  '6m7m8m5p6p3s4s5s8s8s2z2z2z+7p+21',
  '5m5m6m6m7m8p8p4s5s6s7s8s9s+4m+22',
  '5m6m7m8m9m2p3p4p2s2s5s6s7s+7m+12',
  '3m3m5m6m7m5p6p6p7p7p2s3s4s+5p+13',
  '2m3m5m6m7m3p4p5p5s5s7s8s9s1m+23',
  '3m4m4m7m7m1p1p4p4p7p7p3z3z3m+24',
  '2m2m7m8m9m5p6p7p2s3s4s5s6s7s+22',
  '1m1m3m3m8m8m8p8p1s6s6s7z7z+1s+13',
  '2m3m4m9m9m2p3p4p6p7p3s4s5s+8p+23',
  '4m5m6m8m8m1p2p3p3p4p5p6p7p+8p+13',
  '1m2m3m4p5p9p9p5s6s6s7s7s8s3p+11',
  '4m5m6m2p3p4p4p4p2s3s4s7s8s9s+13',
  '6m7m8m4p4p4p5p6p1s2s3s6s7s+8s+21',
  '4m5m6m4p4p5p6p7p3s4s5s7s8s6s+22',
  '5m6m7m1p2p3p6p6p5s6s7s7s8s9s+14',
  '4m5m5m5m6m7m8m9m7p8p9p2s3s+1s+21',
  '4m5m6m7m7m2p3p4p6p7p8p7s7s+7m+14',
  '5m6m7m8m8m6p6p6p5s6s7z7z7z7s+23',
  '2m2m8m8m9m9m6p2s2s6s6s1z1z+6p+22',
  '4m5m2p3p4p5p6p7p7p8p9p3s3s3m+23',
  '2m3m4m6m7m8m2p3p7p8p9p2s2s+1p+11',
  '2m3m1p2p3p4p4p5p6p7p2s3s4s+1m+14',
  '3m5m3p4p5p6p7p8p2s3s4s8s8s4m+21',
  '2m2m4m5m6m6m7m9m9m9m3z3z3z5m+11',
  '3m4m8m8m6p7p8p4s5s6s7s8s9s2m+22',
  '4m5m5m6m7m2p3p4p7p7p4s5s6s+6m+21',
  '1m2m3m4m5m3s3s4s5s6s5z5z5z3m+22',
  '3m4m5m6m7m8m9m9m9m5s5s6z6z+6z+22',
  '4m4m4m5m5m5p5p5p3s3s4s5s5s4s+12',
  '4m5m7m7m3p4p5p6p7p8p2s3s4s6m+12',
  '2m3m4m7m8m9m4p4p6p7p5s6s7s+8p+22',
  '7m8m8m9m9m6p7p8p5s6s7s7s7s+7m+21',
  '6m6m3p4p6p7p8p1s2s3s7s8s9s2p+11',
  '2m3m4m6m6m5p6p6p7p8p1s2s3s7p+21',
  '2m4m6m7m8m5p6p7p8p8p6s7s8s+3m+22',
  '1m1m3m4m5m6m7m8m7p8p9p6s7s+8s+22',
  '7m8m9m7p8p9p2s2s5s6s6s7s7s8s+13',
  '3m4m5m4p5p6p8p8p4s4s4s5s7s6s+22',
  '3p4p3s4s5s5s6s7s9s9s5z5z5z+5p+14',
  '3m4m5m6m7m8m7p8p4s4s5s6s7s+6p+24',
  '3p3p5p5p6p6p1s1s9s9s4z6z6z+4z+23',
  '4m4m4m8m8m2p2p2p4p5p1s1s1s+6p+11',
  '1m2m3m7m8m9m6p7p3s4s5s8s8s8p+23',
  '4m4m2p3p3p4p4p6p6p7s7s8s8s+2p+13',
  '5m5m6m6m6m5p6p7p3s3s3s5s6s7s+11',
  '3m4m4p4p5p5p6p6p4s4s6s7s8s+2m+13',
  '2m5m5m5m7m7m7m6p7p8p4z4z4z+2m+24',
  '3m4m5m3p4p5p7p8p9p4s5s7s7s6s+24',
  '4m5m6p7p8p1s2s3s4s5s6s4z4z+6m+22',
  '5m6m6p7p8p5s5s7s7s7s8s8s8s+7m+13',
  '2m3m4m5m5m5m2p2p6p8p3s4s5s+7p+23',
  '2m3m4m8m8m4p5p6p3s4s4s5s6s2s+11',
  '5m5m5m8m8m2p3p4p8p8p4s5s6s+8p+23',
  '5m6m7m2p2p2p3p6p7p8p2s3s4s1p+12',
  '2m3m4m6m7m8m4p6p2s2s5z5z5z+5p+22',
  '3m4m6m6m4p5p6p4s5s5s6s6s7s5m+21',
  '5m6m6m7m7m2p2p2p4p4p4p6s6s+8m+11',
  '5p5p9p9p4s4s8s8s9s9s3z3z5z5z+11',
  '3p4p5p7p8p9p2s2s5s6s7s8s9s+7s+22',
  '3m4m5m6m7m8m1s2s3s5s5s7s8s+6s+13',
  '3m4m5m2p4p8p8p8p3s3s7s7s7s+3p+22',
  '2m3m4m5m6m7m1p1p7p8p4s5s6s+9p+14',
  '7m7m3p5p5p2s2s3s3s5s5s7s7s+3p+22',
  '5m6m7m7m8m9m5p6p7p7p8p5s5s9p+13',
  '2m2m3m3m4m1p1p1p2p3p4p3z3z4m+11',
  '2m3m5m5m7m8m9m5p6p7p3s4s5s+1m+21',
  '2m3m4m4p4p7p8p9p3s4s5s6s7s+8s+11',
  '4m5m6m4p5p6p6p7p8p2s3s5s5s1s+22',
  '3m3m4m4m5m5m6p7p8p3s4s3z3z5s+11',
  '4m5m6m2p3p4p5p3s4s5s5s6s7s+2p+12',
  '1m2m3m1p2p3p4p4p5p6p7p7z7z+7z+12',
  '7m8m9m1p2p3p7p9p3s3s7s8s9s+8p+11',
  '1m2m3m6m7m1s1s2s3s4s5s6s7s+8m+11',
  '1m2m3m4m4m5m6m7m5p6p7p2s3s+1s+11',
  '4m5m6m2p2p8p8p4s5s6s6s7s8s+2p+24',
  '6m6m8m9m4p5p6p7p8p9p7s8s9s+7m+13',
  '2m3m4m2p2p3p4p4p5p5p4s5s6s6p+12',
  '2m3m3m4m5m4p5p6p7s8s9s3z3z+4m+11',
  '6p7p7p8p8p9p2s3s3s4s5s7s7s+1s+11',
  '3m3m7m7m8p8p3s3s4s5s5s2z2z+4s+11',
  '4m5m6m2p3p4p8p8p4s5s6s7s8s6s+21',
  '1p2p3p4p5p6p7p8p9p2z2z3z3z+2z+22',
  '3m4m5m3p4p5p6p7p8p8p5s6s7s+5p+11',
  '2m3m4m7m8m9m5p6p7p8p8p6s7s+8s+12',
  '1m2m3m5m6m7m7m3p4p5p4s5s6s+4m+11',
  '3m4m6m7m8m3p3p3p4p5p6p7p7p+2m+11',
  '2m3m4m5m5m5m5p6p7p3s4s8s8s+2s+12',
  '5m6m7m4p5p6p7p7p8p8p2s3s4s+8p+22',
  '5m5m6m7m8m7p8p9p3s4s5s4z4z+4z+14',
  '3p4p5p6p7p8p6s6s7s7s5z5z5z+6s+24',
  '4m4m1p2p3p5p6p2s3s4s6s7s8s7p+22',
  '3m4m5m7m8m9m5p5p1s2s3s5s6s+7s+24',
  '1m1m2m2m3m3m6m6m5s5s7s7s9s+9s+21',
  '2m3m4m6m7m8m3p4p5p1s1s4s5s+3s+13',
  '7m7m2p3p6p7p7p8p8p9p4s5s6s+4p+12',
  '6m6m6m3p3p4p5p6p5s5s5s6z6z+6z+23',
  '1p1p2p2p3p3p1s2s3s4s4s6s7s+5s+12',
  '4m5m6m7p8p9p2s2s3s4s7s8s9s+5s+23',
  '4m4m6p7p8p4s5s6s7s7s8s9s9s8s+11',
  '3m4m6m6m4p4p5p5p6p6p5s5s5s5m+13',
  '2m4m5p5p5p6p7p2s3s4s5s5s5s3m+12',
  '2m2m3m3m3p4p5p6p6p7p7p8p8p+3m+22',
  '5m6m6m8m8m9m9m4p4p7p7p7s7s5m+11',
  '3m4m5m4p5p6p7p8p9p3s4s5s5s+2s+14',
  '1p2p3p4p4p4p4p5p6p3z3z3z4z+4z+12',
  '4m4m4m5m6m7m2p2p4p5p3s4s5s6p+12',
  '4m4m7m7m4s4s2z2z4z5z5z7z7z+4z+12',
  '1m2m3m9m9m6p6p7p7p8p3s4s5s+8p+24',
  '3m3m6m7m8m4p4p5p5p6p6p9p9p+3m+22',
  '7m7m8m8m4p5p6p4s4s4s6s7s8s+8m+12',
  '2m3m4m2p3p4p6p7p1s1s6s7s8s5p+21',
  '1p2p3p7p7p9p9p9p4s5s6s7z7z+7z+24',
  '4m5m6m7m8m4p5p6p6s7s8s3z3z+9m+12',
  '2m3m4m4m5m6m7m7m3s4s6s6s6s5s+14',
  '6m6m2p3p4p6p7p8p4s5s5s5s6s5s+12',
  '1m2m2m3m3m4m4p4p5p6p5s6s7s7p+11',
  '3m3m5m5m1p1p1s1s6s6s3z3z7z+7z+22',
  '1p1p2p3p4p3s4s5s6s6s7s8s8s+7s+21',
  '4m5m6m7m7m6p7p2s3s4s7s8s9s+8p+11',
  '2m2m2m5m6m7m1p1p7s8s9s2z2z+2z+21',
  '7m7m3p4p5p6p7p5s6s7s7s8s9s+8p+21',
  '2m3m4m7m8m9m4p5p6p7p8p4s4s+9p+12',
  '1m2m3m6m7m2p3p4p7p8p9p4s4s8m+22',
  '3m3m3m7m7m7m1p1p7s8s9s2z2z+2z+21',
  '2m2m2m5m6m7m2p2p4p5p6s7s8s+3p+23',
  '3m4m8m8m1p2p3p6p7p8p1s2s3s+2m+11',
  '2m4m5p6p6p7p7p8p4s4s2z2z2z3m+22',
  '4m5m6m6m7m6p7p8p1s1s2s3s4s8m+13',
  '4m5m6m8m8m5p6p1s2s3s5s6s7s4p+12',
  '4m5m6m7m8m9m5p5p7p8p9p3s4s2s+14',
  '5m6m7m2p3p4p5p6p3s3s4s5s6s+1p+23',
  '4m5m7m8m9m5p5p1s2s3s7s8s9s+6m+22',
  '5m5m6m7m8m2p3p4p5p6p7p5s6s4s+24',
  '3m4m5m6m6m8p8p8p5s6s6z6z6z+7s+14',
  '2m2m3m3m4m4m2p3p4p6p8p9s9s+7p+11',
  '4p4p5p6p7p5s5s5s5s6s6s7s8s+4s+22',
  '4m5m5m6m6m7m2p2p3p4p7s8s9s+5p+12',
  '5m6m7m2p3p5p5p5p8p8p2s3s4s4p+11',
  '3m4m5m1p1p1p5s5s5s6s7s1z1z+1z+11',
  '2m3m3m3m4p5p6p6s7s8s7z7z7z2m+24',
  '7m7m8m2p2p9p9p2s2s3s3s3z3z+8m+23',
  '3m3m4m4m5m5m2s2s1z1z4z6z6z+4z+23',
  '4p4p9p9p9p1s2s4s5s6s7s8s9s+3s+13',
  '1m1m2m2m3m3m4m5m6m3p4p5p7s7s+22',
  '1m1m5m4p4p1s1s3s3s5s5s7s7s5m+14',
  '2m3m4m4p4p5p5p6p6p8p8p5s6s+4s+22',
  '6m7m8m3p4p8p8p1s2s3s6s7s8s+2p+11',
  '1m1m2m2m9m9m6p2s2s3s3s8s8s+6p+22',
  '2m3m4m6m7m8m2p2p3p4p3s3s3s+5p+14',
  '2m3m4m6m7m8m4p5p7p7p5s5s5s+3p+14',
  '3p3p5p6p7p8p9p3s4s5s7s8s9s4p+13',
  '3m3m3p3p5p5p4s4s5s5s7s7s3z3z+12',
  '1m1m3p5p5p6p7p6s6s7s7s8s8s+4p+11',
  '3m4m5p6p7p4s4s4s7s7s2z2z2z+2m+12',
  '1m2m3m7m8m9m1p1p8p9p1s1s1s+7p+13',
  '4m4m6m6m9p5s5s6s6s7s7s9s9s+9p+22',
  '3m3m7m8m9m9p9p9p9s9s7z7z7z+9s+12',
  '1p2p3p5p6p7p8p9p4s5s6s7s7s4p+24',
  '2m3m4m3p4p5p7p8p9p5s5s7s8s+9s+14',
  '4m5m6m7m8m2p2p3p4p5p8p8p8p3m+11',
  '7m8m8m8m9m7p8p9p3s4s6s7s8s+2s+13',
  '5m6m7m3p4p6p6p7p8p9p6s7s8s+2p+22',
  '1m2m3m3m4m7p8p9p1s1s3s4s5s2m+12',
  '3m3m3m3p4p5p7p7p3s4s5s7z7z+7z+21',
  '4m5m7m8m9m2p3p4p7p7p5s6s7s+6m+21',
  '1m1m2m3m4m5m6m6p7p8p5s6s7s1m+14',
  '7m8m8m8m9m1p2p3p6p7p8p7s8s9s+11',
  '6m7m8m2p3p3p4p4p5p6p7p2s2s+8p+14',
  '2m3m5m5m5m6m7m5s6s6s7s7s8s+1m+14',
  '6m7m7m8m8m8p8p5s5s7s7s3z3z+6m+11',
  '1m2m3m5m6m9m9m1p2p3p7p8p9p+7m+13',
  '6m7m1p2p3p3p4p5p8p8p2s3s4s+8m+12',
  '3m3m2p3p4p5s6s7s8s8s2z2z2z8s+24',
  '1m2m3m4m5m6m1p2p3p7p7p2s3s+4s+14',
  '3m4m3p3p5p6p7p6s7s8s6z6z6z+2m+12',
  '1m1m3m4m5m6m7m8m3s4s7s8s9s2s+12',
  '5m5m7m8m1p2p3p7p8p9p5s6s7s6m+12',
  '2m3m5p6p7p7p8p9p1s1s5s6s7s+4m+22',
  '2m2m5m6m7m3p3p4p4p5p4s5s6s2p+22',
  '5m6m7m8m8m4p5p7p8p9p5s6s7s+3p+11',
  '3m4m5m7m8m9m1p2p3p7p8p5s5s6p+12',
  '6m6m7m8m9m4p5p6p7p8p1s2s3s6p+14',
  '3m3m4m5m6m7m8m1s2s3s6s7s8s+6m+11',
  '1m1m2m2m3m3m4m4m8m8m8m1z1z+1z+14',
  '1p2p2p3p3p4p6p7p8p2s2s5s6s4s+21',
  '2m3m6m7m8m2s3s4s5s5s6s7s8s4m+11',
  '4m5m6m8m8m2p3p4p6p7p4s5s6s+8p+23',
  '1m1m5m5m5p5p5p7p7p7p4s4s4s+1m+11',
  '1m1m3m4m5m5p6p7p4s5s6s7s8s+3s+12',
  '1m1m2m3m4m1s2s3s4s5s7s8s9s+3s+21',
  '4m5m6m6m7m8m8m6p7p8p4s5s6s5m+24',
  '4m4m1p2p3p4p5p6p8p9p6s7s8s+7p+11',
  '4m5m6m7m7m3p4p1s2s3s7s8s9s5p+13',
  '2m3m4m6m7m9p9p6s6s6s2z2z2z8m+21',
  '5m6m6m7m7m6p7p8p1s2s3s7s7s5m+21',
  '4m5m6m2s2s3s3s4s4s5s7s6z6z+6s+11',
  '3m3m6m7m3p4p5p5p6p7p4s5s6s8m+14',
  '7m8m9m2p3p4p9p9p2s3s4s1z1z+1z+14',
  '1m2m3m3m3m5m6m5p6p7p7z7z7z4m+21',
  '5m5m1p1p8p8p5s5s7s7s3z4z4z+3z+12',
  '4m4m4m6m6m6m7m3p4p5p5s6s7s+5m+13',
  '7m8m9m1p2p3p6p6p5s6s7s4z4z+4z+14',
  '1m2m3m3m4m5m4p5p2s2s6s7s8s+6p+24',
  '1m1m3m3m5p7p7p4s4s5s5s9s9s+5p+11',
  '5p6p7p1s2s2s3s3s5s6s6s6s7s+4s+11',
  '3m3m3m4m5m6m2p2p7p8p6s7s8s+6p+24',
  '1m2m3m4m4m5m5m6m6m2p3p7s7s4p+24',
  '2m4m3p4p5p5p5p3s4s5s6s7s8s+3m+11',
  '6m7m7m7m8m1p2p3p1s2s3s6s7s8s+14',
  '4m5m2p3p3p3p4p5p6p7p1s2s3s+3m+14',
  '2m3m4m7m8m9m1p1p3p4p2s3s4s+5p+23',
  '1p2p3p4p5p6p8p9p3s4s5s6s6s+7p+14',
  '4m5m6m2p3p4p6p6p7p7p8p6s6s8p+22',
  '3m4m5m1p1p5p6p7p6s6s7s7s8s5s+14',
  '1m2m3m6m7m8m8m8m7p8p7s8s9s9p+24',
  '3m4m6m6m3p4p5p3s4s5s6s6s6s2m+11',
  '2m2m3p4p5p1s2s3s5s6s7s8s9s7s+12',
  '4m5m6m1p2p3p3p4p5p2s2s5s6s+7s+24',
  '2m3m4m9m9m1p2p3p4p5p6p2s3s1s+21',
  '2m3m3m4m4m5m7m7m3p4p5p6s6s+7m+12',
  '4m5m6m6m5p6p7p7p8p9p6s7s8s6m+11',
  '1p1p5p5p8p8p6s6s7s7s8s8s6z+6z+11',
  '9m9m6p6p9p9p9p5s6s7s3z3z3z9m+24',
  '5m6m7m1p1p4p5p6p1s2s3s6s7s5s+21',
  '7m7m3p4p5p6p7p7s8s9s7z7z7z+2p+13',
  '2m2m3m4m5m6p7p8p2s3s4s6s6s+6s+12',
  '1p1p2p3p3p4p4p1s2s3s6s7s8s+5p+23',
  '1p1p9p9p1s1s2s2s6s6s8s3z3z+8s+23',
  '2m3m4m6p7p8p8p8p2s3s3s4s5s+1s+24',
  '9m9m9m6p7p7s7s7s9s9s3z3z3z5p+12',
  '1m2m3m4p5p6p2s3s6s6s7s8s9s+1s+13',
  '9m9m5p5p8p8p1s1s2s2s7s7s8s+8s+13',
  '2m3m4m6m7m8m6p6p6p3s3s5s6s+4s+12',
  '7m7m2p3p3p4p4p5p5s6s6s7s7s+8s+21',
  '1m2m3m2p2p5s6s6s6s6s7s5z5z+5z+13',
  '1p1p5p5p9p9p5s5s6s6s9s9s5z5z+21',
  '1m2m3m3m3m6p7p1s2s3s4s5s6s+8p+11',
  '6m7m8m3p4p5p1s1s3s4s5s7s8s+6s+23',
  '4m5m6m8m8m4p4p5p5p6p6p7s8s+9s+13',
  '3m4m5m2p3p4p5p6p7p3s3s5s6s+7s+23',
  '3m4m5m7m8m9m4p5p6p3s4s3z3z+2s+22',
  '1m2m3m3m4m1p2p3p4p5p6p6p6p+5m+14',
  '2m3m4m6m7m8m9m9m5p6p6s7s8s+4p+12',
  '5m6m7m7m8m9m4p5p5p5p6p7s8s9s+13',
  '6m6m7m8m9m4p5p4s4s5s5s6s6s3p+14',
  '3m4m5m6m7m5p5p5s6s7s1z1z1z+8m+13',
  '2m2m3m4m5m3p5p6p7p8p3s4s5s4p+14',
  '4p5p6p7p7p2s3s4s4s5s6s7s8s9s+12',
  '1m2m3m6m7m7m8m8m4s5s6s7s7s6m+21',
  '1m1m3p4p4p6p6p9p9p8s8s5z5z+3p+23',
  '1m5m5m7p7p8p8p6s6s7s7s5z5z+1m+12',
  '1m1m3m3m4m4m5p5p7p7p9p9p4z+4z+12',
  '4m4m1p1p2p2p5p5p5s5s1z4z4z1z+14',
  '5m7m4p4p4p5p6p3s4s5s6s7s8s6m+22',
  '3m4m5m1p2p3p3p4p5p6p6p7z7z+7z+23',
  '5p5p6p6p7p7p9p9p2z2z3z3z5z5z+11',
  '2m3m4m5m6m7m4p5p6p4s4s6s6s6s+11',
  '3m4m5m1p2p3p8p8p5s6s6s7s7s+5s+11',
  '9m9m9m2p3p4p1s2s3s6s6s7z7z+7z+12',
  '7m7m1p2p3p5p6p5s6s7s5z5z5z7p+11',
  '3m4m5m6m7m8m7p7p2s3s4s4s5s+3s+14',
  '1m1m3m4m5m6m7m3s4s5s6s7s8s+2m+14',
  '7m7m4p5p6p7p8p2s3s4s7s8s9s+6p+22',
  '7m7m7m8m8m8m7p7p4s5s6s7z7z+7z+22',
  '3m4m5m5m1p2p3p3p4p5p5s6s7s+2m+12',
  '2m3m4m8m8m2p3p4p4p5p5p6p6p1p+12',
  '4m5m6m3p3p6p7p8p2s3s4s7s8s+9s+21',
  '6m7m8m5p5p6p7p8p3s4s5s6s8s+7s+14',
  '3m4m5m3p4p7p8p9p1s1s6s7s8s5p+12',
  '6m6m7m8m8m1p1p3s4s5s9s9s9s+7m+24',
  '4m5m6m2p3p4p8p8p3s3s3s5s6s7s+23',
  '5m6m7m4p5p5p6p7p8p8p4s5s6s+6p+21',
  '1m1m6m7m4p5p6p3s4s5s5s6s7s+8m+12',
  '1m2m3m4m4m6m7m7m8m8m1p2p3p+9m+22',
  '5m6m7m2p3p4p5p5p6p7p8p2s3s+1s+11',
  '4m5m6m6m7m5p5p1s2s3s7s8s9s+8m+22',
  '1m2m3m5m5m6p8p7s8s9s7z7z7z+7p+13',
  '3m4m5m3p3p4p4p5p5p7p8p1s1s6p+12',
  '3m3m1p2p3p4p5p7p8p9p5s6s7s+3p+14',
  '1m1m1p3p3p1s1s3s3s6s6s1z1z+1p+24',
  '1m2m3m6m6m7m8m8m9m9m2s3s4s+7m+14',
  '1m1m2m2m3m3s4s5s6s7s8s7z7z3m+14',
  '3m4m5m8p8p2s3s4s6s7s1z1z1z5s+13',
  '5p6p1s1s4s5s6s7s7s8s8s9s9s4p+11',
  '4m5m2p3p4p5p6p7p7p8p9p5s5s6m+12',
  '5m6m7m8m9m3p4p5p5s5s6s7s8s+4m+13',
  '7m8m9m1p2p3p6p7p8p4s5s2z2z+6s+11',
  '1m2m3m4p4p6p7p3s4s5s5s6s7s+8p+11',
  '2m2m7m8m9m4p5p7p8p9p7s8s9s6p+11',
  '4m5m6m4p4p6p7p5s6s7s7s8s9s+5p+12',
  '9m9m4p5p5p6p6p7p4s5s6s7s8s+9s+14',
  '1m2m3m5m6m7m5p6p7p6s6s4z4z4z+14',
  '6p8p2s3s4s5s5s5s6s7s7s7s8s+7p+22',
  '3p4p4p5p5p6p1s1s4s5s5s6s7s3s+22',
  '5m6m7m1p2p3p5p6p2s3s4s6s6s+7p+13',
  '5m6m6m7m8m9m9m3p4p5p5p6p7p7m+21',
  '1m2m3m3m4m5m2p3p4p1s1s6s7s+8s+14',
  '2m2m4m5m6m6m7m8m8p8p8s8s8s2m+14',
  '3m4m4m5m5m3p4p5p7p8p9p5s5s6m+11',
  '6m7m8m4p4p7p8p3s4s5s5s6s7s9p+12',
  '7m8m4p4p5p6p7p2s3s4s7s8s9s+9m+23',
  '5m5m8p8p9p9p2s2s6s6s8s8s6z+6z+24',
  '4m5m6m4p4p4p5p7p8p9p1z1z1z+6p+14',
  '6m7m4p4p5p6p6p7p7p8p3s4s5s+5m+22',
  '3m4m5m7m8m9m1p2p3p4p5p7p7p6p+12',
  '5m6m6m7m8m2p3p4p4p5p6p8s8s7m+24',
  '1m2m3m7m8m1p1p1s1s1s1s2s3s+9m+12',
  '2m2m5m6m7m2p2p2p3p3p3p4s5s+6s+14',
  '1m2m3m7m8m9m1p1p7p8p9p7s9s+8s+12',
  '7m7m8m8m9m9m1p1p7s8s9s3z3z+1p+11',
  '6m7m8m2p2p3p3p4p5p6p3s4s5s+2p+24',
  '3m4m5m5p6p7p3s3s3s4s5s6s8s+8s+12',
  '2m3m4m5m5m5m7m7m3p4p5p5p6p4p+12',
  '3m3m7m7m9m9m9p9p9s3z3z6z6z9s+11',
  '1m2m3m6m7m4p5p6p3s3s5s6s7s+8m+21',
  '4m4m5m5m6m9m9m1p2p3p6p7p8p3m+21',
  '1m2m3m1p2p1s2s3s8s8s8s3z3z3p+12',
  '1m2m3m5m6m4p5p6p3s3s7s8s9s+4m+13',
  '3m4m6m6m6m4p5p6p8p8p6s7s8s2m+12',
  '2m3m4m6m7m8m6p7p2s3s4s4z4z5p+11',
  '4m6m3p4p5p5p6p7p3s3s6s7s8s5m+11',
  '2m2m2m7m8m7p8p9p8s8s4z4z4z+6m+14',
  '1m2m3m6p7p8p9p9p1z1z2z2z2z9p+12',
  '1m2m3m5m6m1p2p3p4z4z6z6z6z4m+23',
  '3m4m6p7p8p2s3s4s6s6s6s7s8s5m+11',
  '2m3m4m2p3p4p6p7p4s4s6s7s8s5p+21',
  '5m6m3p4p5p8p8p5s6s7s7s8s9s+7m+22',
  '5m6m7m7m8m3p4p5p2s2s6s7s8s+6m+13',
  '5m6m7m2p3p4p4p5p7p7p6s6s6s+6p+23',
  '1m2m3m6m7m8m9m9m4s5s5s6s7s3s+23',
  '3m3m1p2p3p5p5p5p1s2s3s6z6z+6z+24',
  '1m2m3m6m7m1p2p3p6s6s6s7s8s+8m+12',
  '3m4m4m4m5m3p4p5p1s2s3s6s7s+5s+11',
  '2m3m3m4m4m2p3p4p6p6p2s3s4s+2m+24',
  '1p1p2p2p3p3p5p5p6p8p8p7z7z+6p+24',
  '4m5m6m5p6p8p8p3s4s5s7s8s9s+7p+13',
  '4m5m6m1p1p5p6p7p8p9p4s5s6s+4p+13',
  '1m9m1p9p9p9s1z2z3z4z5z6z7z+1s+14',
  '1m2m3m4p4p1s1s1s7s7s2z2z2z+7s+21',
  '2m3m4m5m6m7m8m8m8m9m6s7s8s+1m+12',
  '2m3m6m7m8m4p5p6p2s2s6z6z6z+1m+23',
  '6m7m8m2p3p4p1s2s3s8s5z5z5z+8s+23',
  '5m6m7m3p4p6p7p8p3s3s5s6s7s5p+11',
  '7m7m1p1p3p3p7p1s1s5s5s1z1z+7p+22',
  '1m2m3m4m5m6m6m7m8m2s2s4s5s+3s+14',
  '1m2m3m5m6m7m2p3p7s8s9s4z4z4p+11',
  '2m3m5m6m7m2p3p4p6p7p8p2s2s+4m+21',
  '1m2m3m1p2p3p6p6p7p7p7p1s2s3s+24',
  '1m2m3m7m7m7p8p2s2s2s7z7z7z6p+23',
  '4m4m6m7m8m5p6p5s6s7s7s8s9s4p+24',
  '3m4m5m7m7m7m7p7p6s6s7s8s8s+7s+24',
  '5m6m6m7m7m8m2p3p4p6p6p7p8p+9p+24',
  '1p1p2p2p3p3p7p9p4s5s6s8s8s+8p+24',
  '3m3m3m5m6m7m4p4p4p5p6p7p5s5s+12',
  '7m8m9m2p3p3p4p4p5p4s4s6s7s+8s+12',
  '3m3m1p1p5p6p7p5s5s6s6s7s7s+3m+32',
  '1m2m3m4m5m1p2p3p5p6p7p6s6s+3m+23',
  '3m3m4m5m6m3p4p4p5p5p6s7s8s+3p+32',
  '1p2p3p4p4p5p5p6p2s3s4s8s8s3p+12',
  '2m3m4m6m6m6p7p8p3s4s5s7s8s+6s+24',
  '5m6m7m3p4p6p7p8p4s5s6s7s7s+2p+22',
  '1m2m4m5m6m7m8m9m3s4s5s8s8s+3m+11',
  '3m4m5m3p4p5p6p7p4s4s7z7z7z8p+22',
  '3p3p5p5p1s5s5s6s6s8s8s9s9s+1s+12',
  '1p1p2p2p3p3p7p9p1s1s5s6s7s+8p+21',
  '7m8m5p5p6p7p8p4s4s4s6z6z6z9m+23',
  '5m6m6m7m7m8m7p7p4s5s6s7s8s+9s+22',
  '2m3m4m5m6m7m2p3p4p4p5p4z4z+3p+11',
  '3m4m5m9m9m1p2p2p3p3p6z6z6z4p+13',
  '4m5m6m7m8m9m2p3p4p3s4s5s5s+2s+13',
  '5m6m4p5p6p8p8p3s4s5s5s6s7s+7m+13',
  '4m5m6m7m7m6p6p7p7p8p7s8s9s+8p+11',
  '1m1m1p2p3p6p7p1s2s3s5s6s7s+8p+13',
  '1m2m2m4p4p1s1s5s5s2z2z3z3z+1m+11',
  '5m5m5m7p8p9p6s7s8s5z5z7z7z+7z+11',
  '2m3m5m5m6m7m8m6p7p8p2s3s4s1m+12',
  '2m3m6m6m7m7m8m8m1s2s3s6z6z+4m+23',
  '3m3m5m5m2p2p2s2s3s5s5s7s7s3s+23',
  '2m3m4m2p3p4p9p9p1s2s3s6s7s5s+13',
  '7p8p9p9p5s6s6s7s7s8s6z6z6z9p+14',
  '3m4m5m3p6p7p8p3s4s5s6s7s8s+3p+14',
  '1m2m3m5m5m1p2p3p5p6p7p3s4s5s+21',
  '4m4m5m5m2s2s3s3s5s5s1z5z5z+1z+12',
  '2m3m4m6m6m7m8m9m3p4p5p5s6s7s+23',
  '4m5m6m7m7m7m9m9m2p2p1s1s1s2p+21',
  '2m2m6m6m1s1s8s8s2z3z3z5z5z+2z+21',
  '2p3p4p6p6p6p7p8p8p9p9p8s8s7p+12',
  '6m6m3p4p6p7p8p1s2s3s6z6z6z+5p+24',
  '2m3m4m8m8m1p2p3p6p7p3s4s5s5p+23',
  '7m7m8m8m1p1p8s8s2z2z4z5z5z+4z+21',
  '3m3m4m4m5m5m5p5p7p9p9p2s2s+7p+21',
  '9m9m5p6p1s2s3s4s5s6s7s8s9s+4p+14',
  '2m3m4m3p4p5p8p8p5s5s6s7s7s+6s+23',
  '3m3m6m7m8m2p3p4p8s9s2z2z2z+7s+24',
  '5m5m5m1p2p3p5p6p6p7p7p5s5s5p+13',
  '3m4m5m7m8m3p4p5p1s2s3s9s9s+9m+23',
  '3m3m5p5p6p6p7p2s3s3s4s4s5s+4p+12',
  '4m5m6m7m7m2p4p2s3s3s4s4s5s3p+12',
  '2m3m4m4m5m8m8m8m2p3p4p5s5s+3m+13',
  '5m6m7m7m7m1p1p2p3p3p6s7s8s2p+11',
  '1m2m3m2p3p4p4p5p2s3s4s1z1z+6p+22',
  '2m2m5m6m6m7m7m8m5p6p7p8p9p4p+12',
  '2p3p5p6p7p2s3s3s3s4s6s7s8s1p+22',
  '5m6m7m8m8m7p8p9p5s6s7s7s8s6s+12',
  '3m4m5m6m7m9m9m6p7p8p6s7s8s2m+11',
  '3m4m5m7m8m9m9m9m2p3p3s4s5s+1p+12',
  '2m3m4m4m5m6m6p7p4s4s6s7s8s5p+14',
  '3m4m5m6m7m2p3p4p7p8p9p8s8s+8m+22',
  '6m7m8m2p2p3p4p5p2s3s4s5s6s4s+21',
  '6m7m9m9m2p3p4p5p6p7p6s7s8s+5m+13',
  '5m6m7m7m8m8m8m5p6p7p4s5s6s9m+11',
  '2m2m4m1p1p2p2p2s2s3z3z5z5z+4m+21',
  '2m2m6m7m8m1p2p3p6p7p4s5s6s+8p+23',
  '4p5p6p8p8p2s3s3s4s4s5s6s7s+5s+12',
  '2m3m4m8m8m1p2p3p4p5p5p6p7p3p+14',
  '5m6m2p2p3p3p4p4p6p6p3s4s5s7m+12',
  '3m4m5m5p5p7p7p8p8p9p5s6s7s6p+24',
  '1m3m3m8p8p3s3s5s5s6s6s6z6z+1m+21',
  '1m2m3m6p7p8p1s2s3s6z7z7z7z6z+22',
  '2m2m3m4m5m5m6m7m4p5p8s8s8s6p+11',
  '5m6m7m4p4p5p6p2s3s4s6s7s8s7p+11',
  '7m8m9m5p5p6p7p3s3s4s4s5s5s+8p+23',
  '3p4p5p6p7p1s2s3s4s4s1z1z1z+8p+14',
  '4m5m6m7m8m1p2p3p5p6p7p8p8p9m+21',
  '6m7m8m6p7p3s3s5s6s7s8s8s8s+8p+23',
  '3m4m5m7m7m2p3p4p4p5p6p6s7s8s+12',
  '1m1m5p6p7p4s5s8s8s8s7z7z7z+6s+12',
  '1p2p3p5p6p7p8p9p5s6s7s9s9s+4p+11',
  '3m4m5m7m8m9m1p2p3p5p6p7s7s+7p+13',
  '5m6m6m7m7m8m2p3p7p8p9p6s6s4p+12',
  '7m7m8m8m9m9m9m7p8p9p5s6s7s+6m+12',
  '5m5m7m7m9m9m8p8p5s5s1z1z4z+4z+23',
  '2p3p4p4p5p6p7p8p8p8p9p2s3s+1s+11',
  '2m3m4m5m5m7p8p9p3s4s5s6s7s+2s+24',
  '3m4m3p3p3p6p7p8p3s4s5s7s7s2m+11',
  '7m7m8m9m9m5p5p7p8p9p2z2z2z+8m+21',
  '2m3m4m7m8m3p4p5p6p6p7p8p9p6m+24',
  '1m2m3m4m5m6m6p7p9p9p6z6z6z8p+11',
  '4m5m6m6m6m4p5p6p7p8p1s2s3s+3p+22',
  '2m2m2m3m4m4m5m2p3p4p6p7p8p+6m+11',
  '3m3m5m6m7m3p4p5p6p7p8p4s5s6s+12',
  '2m2m5m6m6m7m8m2p3p4p3s4s5s+7m+11',
  '5m6m7m4p5p9p9p2s3s4s4s5s6s6p+23',
  '6m7m8m5p6p1s1s2s3s4s7s8s9s+7p+14',
  '4m4m5m5m6m6m7m7m8p8p7s8s9s4m+21',
  '1m2m3m4m6m7m8m5s6s7s5z5z5z1m+11',
  '3m3m6m8m1p2p3p6p7p8p6s7s8s+7m+13',
  '1m2m3m6m6m2p3p4p5p6p7p5s6s+7s+11',
  '3m4m5m1p1p3p4p5p2s3s4s4s5s+3s+12',
  '8m8m3p4p4p5p5p1s2s3s4s5s6s6p+13',
  '1m1m4m6m2p3p4p1s1s2s2s3s3s+5m+24',
  '1m2m3m1p2p3p4p5p2s2s4s5s6s3p+24',
  '7m7m5p5p6p6p3s5s5s6s6s3z3z3s+12',
  '8m8m8m2p2p3p4p1s1s2s2s3s3s+2p+21',
  '3m4m5m7m8m9m3p4p5p7p7p7s8s+6s+21',
  '2m3m4m2p3p4p2s2s3s4s7s8s9s+2s+23',
  '3m4m3p4p5p7p7p1s2s3s5s6s7s+2m+12',
  '4m5m6m7m7m7m4p5p6p2s2s5s6s7s+13',
  '6m8m8m2p2p5s5s7s7s4z4z7z7z6m+24',
  '5m5m5m3p3p3s4s5s7s8s4z4z4z+9s+24',
  '7m7m8m8m8m2p3p4p6p7p6s7s8s+8p+11',
  '2m3m4m4m5m6m5p6p2s3s4s4z4z+4p+12',
  '2m3m4m5m6m7m3p4p5p7p7p7s8s+9s+11',
  '5m6m6m7m7m8m3p4p5p2s2s3s4s+5s+22',
  '2m3m4m4m6m6p7p8p2s2s5s5s5s5m+21',
  '6m7m1p2p3p6p7p8p2s3s4s2z2z5m+11',
  '5m5m3p4p5p1s2s3s5s6s7s8s9s4s+23',
  '1m2m3m7m8m3p4p5p8p8p5s6s7s+9m+22',
  '2m3m4m7m8m9m3p4p6p6p2s3s4s+2p+12',
  '4m5m5m6m6m6p7p8p4s5s6s8s8s+7m+24',
  '3m4m5m3p4p4p5p3s4s5s7s8s9s+4p+23',
  '3m4m5m6m7m8m5p6p7p8p9p4z4z7p+22',
  '3m4m5m3p3p1s2s3s3s4s6s7s8s+2s+13',
  '5m6m7m8m9m6p6p3s4s5s6s7s8s+4m+13',
  '4m4m7m8m1p2p3p4p5p6p7s8s9s6m+14',
  '4m5m7m7m4p5p6p1s2s3s5s6s7s3m+21',
  '4m5m6m2p3p4p5p5p4s5s6s7s8s3s+14',
  '2p3p4p5p5p5p7p8p4s4s7z7z7z6p+12',
  '1m2m3m3p4p5p5p1s2s3s4s5s6s+2p+11',
  '2m2m6m7m5p6p7p3s4s5s6s7s8s+5m+22',
  '3m4m5m7m8m9m2p2p4p5p6p5s6s+7s+12',
  '1p2p3p1s2s3s4s5s6s7s8s9s1z+1z+11',
  '2m3m9m9m2p3p4p5p6p7p5s6s7s4m+22',
  '5m6m1p2p3p4p4p6p7p8p4s5s6s7m+11',
  '5m6m7m2p3p1s2s3s4s4s4s5s6s1p+14',
  '1m2m3m6m7m8m2s3s4s6s7s8s8s+5s+22',
  '1m2m3m3p4p5p6p7p5s6s6s6s7s5p+32',
  '2m3m4m5m5m6m7m8m6s7s7s8s9s5s+14',
  '3m4m4m5m6m7m7m3p4p5p7s8s9s2m+21',
  '5m6m8m8m6p7p8p2s2s2s4s5s6s4m+11',
  '3m4m5m6m7m8m2p3p4p6p8p8s8s+7p+23',
  '2m3m3m3m4m5m6m7m3s4s7s8s9s5s+11',
  '3m3m3m4m5m6m8m4p5p6p4s5s6s+8m+21',
  '1p1p3p4p4p5p5p6p6s8s5z5z5z+7s+14',
  '5m5m7m8m9m5p6p1s2s3s4s5s6s4p+11',
  '6m7m4p5p6p2s3s4s6s6s8s8s8s5m+14',
  '1m2m3m2p2p5p6p3s4s5s6s7s8s+7p+11',
  '6m8m6p7p8p3s4s5s6s7s8s4z4z+7m+21',
  '2p3p4p4p5p7p7p7p3s4s5s7s7s3p+22',
  '3p3p8p8p9p9p6s6s4z4z5z5z6z+6z+22',
  '3m4m5m8m8m8m6p2s2s2s5s6s7s+6p+21',
  '4m5m6m5p5p6p6p7p7p5s6s8s8s7s+13',
  '3m4m5m6m7m2p3p4p6p6p7s8s9s+8m+11',
  '3m4m5m2p3p6s6s7s8s9s1z1z1z1p+14',
  '6m7m8m7p7p2s2s2s4s5s6s6s7s8s+24',
  '3m4m5m6p7p8p2s3s4s5s6s9s9s1s+12',
  '3m3m5m6m2p3p4p7p7p7p5s6s7s+4m+23',
  '7m8m7p7p7p8p9p1s2s3s6s7s8s+9m+12',
  '1m1m2m2m7m8m8m8p8p2z2z3z3z+7m+13',
  '6m7m1p2p3p1s1s2s2s3s3s8s8s+5m+12',
  '2m2m4p5p6p7p8p7s8s9s7z7z7z3p+14',
  '2m3m4m7m7m8m8m8m4p4p5p5p6p6p+14',
  '3m4m5m5m6m7m3p4p5p5p5p6p8p+7p+24',
  '2m3m4m2p3p4p6p7p2s3s4s7s7s8p+21',
  '3m4m5m5p5p6p7p8p3s4s5s6s7s5s+23',
  '4m5m6m5p6p6p7p7p8p2s3s4s5s5s+23',
  '4m5m6m6m6m6p8p2s3s4s5s6s7s+7p+24',
  '7m8m9m5p6p7p2s3s4s1z1z7z7z7z+24',
  '1m2m3m5m6m9p9p3s4s5s6s7s8s+7m+11',
  '6m7m8m4p5p6p7p7p5s6s6s7s8s4s+13',
  '1p2p3p4p5p3s4s5s7s8s9s3z3z6p+21',
  '4m5m6m7m8m3p4p5p1s2s3s5s5s+6m+11',
  '4m5m6m3p3p4p5p6p4s5s7s7s7s3s+14',
  '1m2m3m4m5m6m7m8m9m3p4p5p5p+2p+22',
  '2m2m3m3m2p2p3p3p9p9p2s5s5s2s+11',
  '8m8m2p2p4p4p9p9p2s2s2z5z5z2z+24',
  '5m6m7m7m8m9m1z1z1z5z6z6z6z+5z+22',
  '4m5m6m7m8m3p4p5p7p7p6s7s8s3m+11',
  '1m1m3m4m5m6m6m7m7m8m8m5z5z+1m+22',
  '3m4m5m6m7m8m2p3p9p9p7s8s9s+4p+11',
  '2m3m4m6m7m8m4p6p3s3s6s7s8s+5p+21',
  '1p2p3p4p5p6p7p8p4s4s6s7s8s6p+22',
  '1m1m8m9m2p3p4p4s4s5s5s6s6s7m+14',
  '1m2m3m1p1p1p2p1s2s2s3s3s4s+3p+13',
  '2p2p3p3p4p8p8p2s3s4s7s8s9s1p+11',
  '5m6m3p4p5p5p6p7p4s5s6s3z3z7m+24',
  '5m5m2p2p5p5p6p6p8p8p5s8s8s5s+24',
  '4m5m6m2p3p6p7p8p6s7s8s3z3z1p+21',
  '5m6m2p3p4p9p9p1s2s3s4s5s6s7m+24',
  '4p5p6p6p6p9p9p9p6s7s1z1z1z+8s+13',
  '7m7m4p5p6p3s4s6s7s8s7z7z7z+2s+23',
  '1m2m3m8m9m1p1p2p2p3p3p9p9p+7m+14',
  '3m4m7m8m9m1p2p3p6p7p8p7s7s2m+13',
  '1m1m3m4m5m1p2p3p4p6p7p8p9p5p+21',
  '4m5m6m7m8m5p5p5s6s7s7s8s9s+3m+14',
  '3m4m6m6m3p4p5p5p6p7p8s8s8s2m+12',
  '4m5m6m4p5p6p2s3s3s3s4s5s5s5s+21',
  '3m4m6m7m8m6p6p3s3s3s5s6s7s+2m+24',
  '2m3m4m4p5p6p7p8p9p5s6s8s8s+4s+13',
  '4m5m3p4p5p2s3s4s5s5s6s7s8s+3m+22',
  '2m3m4m4m5m5m6m6m7m3p3p3s4s+2s+24',
  '6m6m7m8m9m7p8p9p5s6s7s7s9s8s+11',
  '3m3m3m6m7m2p3p4p5s5s6s7s8s8m+12',
  '2m2m3m3m4m4m5p6p7p3s4s6z6z+5s+11',
  '1m1m6m6m7m7m8m8m5p6p7p2s4s+3s+24',
  '5m5m5m6m8m3p3p5p6p7p4s4s4s+7m+22',
  '1m2m3m6m7m8m5p5p7p8p4s5s6s9p+23',
  '1m2m3m4m5m6m4p5p1s2s3s6s6s6p+22',
  '4m4m4m3p4p2s3s4s1z1z2z2z2z5p+24',
  '2m3m4m3p4p5p6p7p7s8s9s4z4z+2p+22',
  '3m4m5m1p2p3p2s3s4s7s8s3z3z+6s+24',
  '2m3m4m5p6p7p8p9p4s5s6s7s7s7p+22',
  '7m8m9m5p5p6p6p7p7p4s4s7s8s6s+13',
  '2m3m4m6m7m8m2p2p3s4s6s7s8s5s+12',
  '2m3m4m6m7m1p2p3p7p8p9p5s5s+8m+24',
  '7m7m2p3p4p5p6p7p5s5s6s6s7s4s+22',
  '2m2m5m6m7m2p3p4p1s2s3s5s6s+4s+11',
  '5p5p6p7p8p3s4s5s6s6s7s7s8s2s+14',
  '3m4m5m6m7m8m9m9m4p5p4s5s6s+3p+22',
  '4m4m6m7m8m5p6p3s3s3s4s5s6s+7p+13',
  '9m9m3p4p5p6p7p1s2s3s6s7s8s+2p+12',
  '4m4m5m5m6m6m8m8m5p6p7p7p8p+6p+12',
  '3m4m5m6m7m8m6p7p3s4s5s8s8s+5p+11',
  '2m3m4m5m6m7m2p2p2p5p5p2s3s+4s+23',
  '4m5m5m6m6m5p6p7p8p8p5s6s7s+7m+21',
  '2m3m4m5p6p7p2s3s5s6s6s6s7s1s+12',
  '6m8m2p3p4p7p7p2s2s3s3s4s4s+7m+23',
  '2m3m4m6m7m4p5p6p8p8p8p5s5s+5m+22',
  '2m2m5m6m7m6p7p8p1s2s3s4s5s+3s+12',
  '3m4m4m5m6m4p5p6p4s5s6s1z1z5m+23',
  '2m3m4m8m8m3p4p2s3s4s5s6s7s+2p+22',
  '8m8m1p1p2p2p3p3p5s7s7s8s9s+6s+21',
  '4m5m6m7m7m4p5p6p6p8p4s5s6s+7p+21',
  '1p2p3p5p6p7p7p1s2s3s7s8s9s+4p+23',
  '5m5m5m2p3p4p4p4p5p6p7p4s5s3s+12',
  '3m4m5m7p8p9p2s3s5s6s7s3z3z+1s+24',
  '3m3m5m6m6m7m8m2s3s4s5s6s7s+7m+34',
  '5m5m5p6p6p7p7p5s6s7s7s8s9s+8p+21',
  '7m8m2p3p4p5p6p7p1s1s2s3s4s6m+11',
  '1m2m3m2p3p4p6p7p2s2s5s6s7s5p+12',
  '4m5m6m4p5p6p3s3s3s5s7s7s7s4s+12',
  '2m3m4m6m6m3p4p3s4s5s5s6s7s5p+21',
  '6m7m1p2p3p4p4p6s7s8s7z7z7z8m+21',
  '2m3m3m4m5m8m8m2p3p4p2s3s4s1m+24',
  '6m7m7m8m8m9m3p3p4p4p5p2s2s2p+14',
  '1m2m3m7m8m2p3p4p9p9p7s8s9s6m+21',
  '1m1m4m5m6m4p5p6p1s3s4s5s6s+2s+21',
  '4m5m6m7m8m9m4p5p6p4s5s8s8s+3s+22',
  '1m1m4m4m8m8m3p3p2s2s5s5s5z5z+14',
  '5m5m7m7m4p4p4s4s5s5s3z3z6z+6z+11',
  '2m3m6m7m8m6p7p8p9p9p5s6s7s1m+11',
  '7m8m9m4p5p6p7p9p7s8s9s5z5z+8p+22',
  '3p4p5p7p8p9p5s5s7s9s1z1z1z+8s+21',
  '3m3m4m5m6m6m7m8m4p6p4s5s6s+5p+13',
  '6m7m8m4p5p6p6p7p8p6s7s3z3z8s+12',
  '1m2m3m4m5m2p3p4p1s2s3s8s8s6m+13',
  '8m8m8m2p3p5p5p5p6p7p8p7s7s+4p+21',
  '6m7m8m1p1p5p6p2s3s4s6s7s8s+7p+12',
  '2m3m4m5m7m8m8m2p3p4p2s3s4s6m+22',
  '6m7m8m2p2p2p3p3p3p6p8p5s5s+7p+11',
  '2m3m7m8m9m1p2p3p4p5p6p5s5s1m+11',
  '2m3m4m7m8m9m3p4p5p6p7p5s5s+5p+23',
  '2m3m4m3p3p4p5p6p7p8p3s4s5s+9p+21',
  '2m3m4m4m7m8m9m3p4p5p5s6s7s+1m+21',
  '1m1m3m9m9m1p1p3p3p2s2s3z3z+3m+23',
  '3m3m3m5m6m7m3s3s6s7s3z3z3z+5s+13',
  '3m4m5m5m5m6m7m8m6p7p8p2s3s+1s+13',
  '3m4m5m3p4p5p7p8p1s1s2s3s4s+9p+12',
  '5m5m8m8m6p6p2s2s4s4s5z5z7z+7z+21',
  '7m8m8m9m9m5p6p7p3s4s5s2z2z+7m+13',
  '6m7m8m2p3p3p3p6p7p8p6s7s8s+1p+22',
  '5m6m7m2p3p4p5p6p6p6p6s7s8s+1p+13',
  '2m3m4m5p5p6p6p7p1s1s6s7s8s+7p+13',
  '7m8m3p4p5p5p6p7p8p8p3s4s5s9m+23',
  '4m4m7m8m9m2p3p4p5p6p2s3s4s+1p+11',
  '5m6m2p3p4p6p7p8p6s7s8s9s9s+4m+14',
  '7m8m9m3p3p7p9p1s2s3s7s8s9s+8p+23',
  '7m8m9m1p2p3p4p5p5p5p6p4s5s6s+11',
  '5m6m7m2p3p6p7p8p1s1s7s8s9s+1p+11',
  '2m3m4m1s2s3s4s6s6s7s7s8s8s+1s+22',
  '7m7m3p4p5p1s2s3s4s5s6s7s9s8s+23',
  '2m3m4m4m5m6m7m8m9m4p4p5s6s7s+14',
  '3m3m5p5p5p6p7p8p2s3s4s4s6s+5s+22',
  '2m2m4p4p2s2s3s3s2z2z4z4z5z5z+11',
  '3p3p4p4p5p5p6p7p8p7s7s7s8s6s+13',
  '2m2m6m7m8m2p3p2s3s4s5s6s7s4p+21',
  '2m3m6m7m8m5p6p6p7p7p8p4s4s+1m+14',
  '3m3m3m2p3p4p5s5s9s9s9s5z5z+5z+12',
  '2p4p5p6p7p2s3s3s4s4s5s6s6s3p+14',
  '3m5m2p3p4p5p6p6p6p7p6s7s8s+4m+11',
  '4m4m6m7m8m6p7p7p8p8p7s8s9s+9p+12',
  '6m7m7m8m8m9m1p2p3p6p6p1z1z+1z+11',
  '4m5m6m7m8m5p6p7p5s5s5s6s7s+6m+12',
  '4m5m6m7m8m3p3p5p6p7p2s3s4s6m+11',
  '2m3m4m5m6m6m7m7m8m8m7p8p9p+8m+14',
  '6m6m6m5p6p7p8p8p3s4s5s6s7s+5s+11',
  '5m5m6p7p8p1s3s6s7s8s6z6z6z+2s+12',
  '2m3m4m7p8p1s2s3s3s4s5s8s8s+9p+23',
  '2m3m4m4p4p3s4s4s5s5s6s6s7s2s+11',
  '3m4m5m1p2p3p6p7p8p1s1s5s6s+7s+13',
  '9m9m9m2p2p3p3p4p6p6p6s7s8s+4p+22',
  '3m4m2p3p4p1s2s3s6s7s8s4z4z5m+12',
  '5m7m2p3p4p5p5p5p7p7p3s4s5s6m+12',
  '2m2m4m5m6m6p7p7p8p9p3s4s5s+5p+13',
  '6m6m3p4p5p6p7p2s3s4s7z7z7z2p+14',
  '6m7m8m3p4p5p7p8p2s3s4s8s8s+9p+11',
  '3m4m5m3p4p5p3s4s5s6s7s8s8s+8s+12',
  '2m2m2m8m8m8m2p4p4s4s6s7s8s+3p+11',
  '4m5m6m7m7m8m8m9m2p3p4p5p5p+9m+21',
  '5m5m6m7m8m6p7p7p8p9p6s7s8s5p+21',
  '7m8m1p2p3p4p4p7p8p9p7s8s9s+9m+23',
  '2m4m4p5p6p2s3s4s6s6s6z6z6z3m+13',
  '2m4m6m7m8m2p2p4p5p6p5s6s7s3m+24',
  '2m2m5m5m8p8p3s3s4s4s5s5s9s+9s+14',
  '4m4m6m7m8m3p4p5p5p6p7p8p9p+7p+24',
  '3m4m5m3p4p5p7p8p3s3s6s7s8s+9p+13',
  '3m3m2p4p6p7p8p2s3s4s5s6s7s3p+24',
  '6m7m2p3p4p9p9p6s7s7s8s8s9s8m+22',
  '1m2m3m6m7m8m5p5p7p8p9p4s5s3s+32',
  '2m4m4m5m6m2p2p2p5p5p4s5s6s3m+11',
  '5m5m5p5p6p6p4s4s5s5s2z2z3z+3z+21',
  '2m3m4m5p6p1s2s3s4s5s6s1z1z+7p+23',
  '4m4m6m7m8m2p3p4p5p5p5p3s4s+2s+13',
  '2m3m4m6m7m8m3p3p6p7p3s4s5s5p+13',
  '4m5m6m6m8m6p6p2s3s4s4s5s6s+7m+12',
  '7m8m9m2p3p4p2s3s4s7s7s2z2z+2z+23',
  '3m3m3m6m7m5p5p3s3s3s4s5s6s8m+21',
  '3m4m5m2p2p3p4p2s3s4s7s8s9s+5p+21',
  '4m4m6p6p4s4s6s6s7s7s2z2z6z+6z+22',
  '3m4m5m6m6m7m8m9m6p7p8p6s7s5s+23',
  '3m3m3m6m6m6m2p3p4p6p8p6s6s+7p+14',
  '4m5m3p4p5p6p6p2s3s4s5s6s7s+3m+14',
  '5m5m5p5p7p7p9p9p2s4s4s1z1z2s+22',
  '3m4m5m8m8m4p5p6p7p8p3s4s5s9p+22',
  '2m3m5m6m7m2p2p4p5p6p7s8s9s1m+13',
  '4m5m6m3p4p5p7p8p9p2s2s4s5s+6s+11',
  '4m5m6m7m8m1s2s3s4s4s7s8s9s3m+21',
  '4m5m5m6m7m7m8m9m7p7p7s8s9s+6m+22',
  '2m3m7m7m2p3p4p2s3s4s6s7s8s4m+21',
  '2m3m4m3p3p6p7p1s2s3s4s5s6s+8p+12',
  '3m4m5m4p4p6p7p8p1s2s3s6s7s+8s+24',
  '1m2m3m4m5m6m6p7p8p3s3s7s8s6s+13',
  '3m4m5m6m7m5p6p7p7p8p9p1z1z2m+22',
  '3m3m1p2p3p5p6p7p5s6s7s8s9s+7s+22',
  '5m6m7m1p2p3p6p6p7p8p9p2s3s+1s+24',
  '4m5m6m6m7m8m3s3s4s4s4s5s6s+3s+21',
  '3m4m5m7m7m3p5p6p7p8p6s7s8s+4p+12',
  '4m5m7m8m9m7p8p9p1s2s3s7s7s6m+23',
  '4m5m2p2p2p6p6p2s3s4s6s7s8s+3m+22',
  '5m5m5p6p7p3s3s4s5s5s6s6s7s+3s+12',
  '3m4m5m3p4p6p6p6p4s5s6s6s6s2p+12',
  '3m4m5m5m6m5p5p3s4s5s7s7s7s+7m+24',
  '4m4m1p2p3p5p6p7p2s3s5s6s7s1s+11',
  '1m2m3m1p2p3p6p7p1s2s3s4s4s5p+12',
  '5p5p6p6p7p2s3s4s6s7s8s3z3z7p+12',
  '3m3m4m5m6m7m8m5p5p5p6p6p6p6m+24',
  '1m1m3m3m6m6m3s3s7s7s3z4z4z+3z+21',
  '3m4m5m6m6m7m8m9m1s2s2s3s3s+1s+21',
  '2m3m4m6m7m8m5p6p1s2s3s1z1z+7p+24',
  '3m4m5m7m7m3p3p3p5p6p7p4s5s+3s+11',
  '2m2m4m4m6m6m7m2s2s4s4s5s5s+7m+24',
  '1m1m5m6m7m8m9m7p8p9p7s8s9s+4m+11',
  '5m6m5p5p1s2s2s3s3s4s6s7s8s4m+22',
  '3m3m3p4p5p6p6p6p2s3s5s6s7s4s+12',
  '3m5m3p4p5p8p8p2s3s4s5s6s7s4m+12',
  '2m3m4m6m7m8m3p4p5p5p6p6s6s7p+11',
  '3m3m3m5m6m7m4p5p5p5p5s6s7s+4p+11',
  '5m6m2p3p4p5p6p7p3s4s5s1z1z4m+22',
  '2m3m4m5m6m7m5p6p6p6p7p3s4s2s+13',
  '5m6m7m9m9m3p4p3s4s5s7s8s9s+5p+23',
  '4p5p6p2s3s4s4s4s5s6s7s7s8s9s+12',
  '3m3m4m4m5m7m8m9m3p4p5p7p7p5m+12',
  '3m3m4m4m5m5m2p3p4p7p7p6s7s+5s+22',
  '4m5m6m9m9m4p5p2s3s4s7s8s9s6p+21',
  '2p3p4p5p6p3s3s3s4s5s6s8s8s7p+23',
  '1m2m3m4m4m5m6m7m9m9m4z4z4z4m+12',
  '2m2m4m5m6m2p2p2p4s4s5s5s6s6s+11',
  '3m3m3p3p4p4p6p6p8p8p8s8s4z+4z+33',
  '6m7m8m2p3p4p4p5p6p4s5s9s9s3s+14',
  '5p6p6p6p5s5s6s6s7s7s4z4z4z+4p+23',
  '2m2m4m4m5m1s1s6s6s8s8s7z7z+5m+13',
  '1m2m3m4m5m7m7m4p5p6p7p8p9p3m+14',
  '4m5m9m9m2p3p4p1s2s3s5s6s7s6m+12',
  '3m3m7m8m5p5p5p5s5s6s6s7s7s+9m+13',
  '2m3m4m1p2p3p5p6p6p6p2s3s4s4p+13',
  '2m2m5m6m7m7m7m4p4p4p5s6s7s+4m+11',
  '7m8m9m2p3p8p8p3s4s5s7s8s9s+1p+11',
  '1m1m7m8m9m2s3s5s6s6s7s7s8s+4s+13',
  '2m2m6m6m8m8m2p2p6s6s5z7z7z+5z+14',
  '3m4m5m7m7m1s2s3s7s8s5z5z5z+6s+21',
  '5m6m6m7m7m8m9m9m4s5s6s7s8s6s+12',
  '3m4m5m6p6p1s2s4s5s6s7s8s9s3s+13',
  '7m8m3p4p5p6p7p8p2s3s4s6s6s+9m+11',
  '1m4m4m1p1p3p3p9p9p3s3s6z6z+1m+31',
  '5m6m7m5p6p7p7p7p2s2s2s4s5s+3s+11',
  '1m1m2m3m4m7p8p2s3s4s5s6s7s9p+13',
  '5m6m7m7m8m9m3p4p5p5p6p2s2s+7p+12',
  '5m6m1p2p3p5p6p7p1s2s3s6s6s4m+23',
  '3m4m5m3p4p5p2s2s4s5s7s8s9s6s+12',
  '2m3m4m3p3p4p5p6p3s4s4s5s6s+5s+13',
  '2p2p3p3p5p6p6p7p7p8p4s5s6s2p+13',
  '4m5m6m4p6p2s3s4s4s5s6s7s7s5p+13',
  '7m8m9m7p8p9p6s7s8s3z3z6z6z+6z+23',
  '2m3m3m4m4m3p4p5p7p8p9p7s7s5m+13',
  '3m4m5m2p3p3p4p4p2s3s4s5s5s5p+11',
  '4m5m6m1p2p3p6p6p4z4z5z5z5z+4z+22',
  '6m6m1p2p3p3p4p5p6p7p2s3s4s2p+13',
  '2m2m4m5m4p4p4p5p6p6p7p7p8p+3m+12',
  '3m4m5m6m7m6p7p8p3s3s7z7z7z2m+14',
  '3m4m4m5m5m6m3p4p9p9p6s7s8s+2p+12',
  '5m6m5p6p6p6p7p4s5s6s7s7s7s+7m+23',
  '2m2m4m4m5m5m6m6m5p6p5s6s7s4p+14',
  '3p4p5p3s3s6s7s8s8s8s6z6z6z+5s+22',
  '3m4m4m5m5m6m5p6p7p8p8p6s6s8p+12',
  '4m5m6m6m7m8m5p6p7p8p9p4s4s+7p+12',
  '1p3p6p6p7p7p8p8p2z2z2z3z3z+2p+11',
  '3m4m5m5m6m7m3p3p5p6p7p6s7s8s+12',
  '1m1m2m3m4m5m6m2p3p4p6p7p8p7m+24',
  '5m5m8m8m8m4p5p2s3s4s5s6s7s+3p+23',
  '3p4p4p5p5p6p8p8p3s4s5s7s8s9s+11',
  '3m3m7m8m9m2p3p2s3s4s7s8s9s1p+34',
  '2m4m2p3p4p2s2s2s4s4s6s7s8s3m+14',
  '3m4m5m6m6m1p2p3p4p5p7p8p9p6p+11',
  '3m4m5m7m8m9m4p4p6s7s7s8s9s8s+24',
  '3m4m5m6m7m5p6p7p4s5s6s7s7s+2m+12',
  '5m6m7m7m8m9m9m4p5p6p3s4s5s9m+13',
  '4m5m6m7m8m9m6p6p7p8p6s7s8s+9p+14',
  '4m5m1p2p3p5p6p6p7p7p8p6s6s+3m+12',
  '1m4m4m7m7m8m8m1p1p3p3p3s3s+1m+23',
  '3m3m3m6p6p3s4s5s7s7s8s9s9s8s+13',
  '5m6m7m8m8m3p4p5p3s4s7s8s9s5s+12',
  '5m6m6m7m7m7m8m8m9m4p4p7s8s+9s+13',
  '9m9m2p3p4p2s3s3s4s4s5s7s8s9s+12',
  '7m8m9m6p7p1s2s3s7s8s8s8s9s+8p+11',
  '8m3p3p4p4p5p5p2s2s4s4s7s7s+8m+12',
  '5m5m4p5p6p7p8p4s5s6s2z2z2z+9p+22',
  '4m5m6m7m7m8m8m9m3p4p5p6p6p+3m+14',
  '1m2m3m5m6m7m8m8m4p5p6p7s8s+9s+12',
  '4m5m5m6m6m7m3p4p9p9p6z6z6z2p+23',
  '4m5m6m6p7p8p3s4s5s5s6s7s7s+4s+22',
  '3m4m5m5m6m6m7m7m8m7p3s4s5s+7p+12',
  '1m2m3m8m8m4p5p6p7p8p2s3s4s+6p+12',
  '3m3m3m5m6m7m2p4p3s4s5s8s8s+3p+23',
  '2m3m4m6m6m8m8m4p5p6p6s7s8s6m+21',
  '2m3m5m6m7m7m8m9m4p4p7s8s9s+1m+13',
  '5m6m7m5p5p6p7p8p2s3s7s8s9s4s+11',
  '6m7m2p3p4p7p7p2s3s4s7s8s9s+5m+14',
  '5m5m5p5p7p7p6s6s9s9s4z4z7z7z+21',
  '2m3m4m1p1p2p3p3p4p4p2s3s4s+2p+21',
  '2m3m4m2p2p2p7p7p8p8p5s6s7s+8p+24',
  '6m7m7m8m8m2p3p4p2s3s4s6z6z+6m+12',
  '5m6m7m7p8p9p1s2s3s1z2z2z2z+1z+24',
  '3m3m3m5m3p4p5p6p7p8p7s7s7s+4m+12',
  '1m1m3m4m5m3p4p1s2s3s5s6s7s5p+13',
  '3m4m5m1p2p3p7p7p3s3s4s4s5s+2s+12',
  '1m1m9m9m6p6p7p7p9p9p8s8s1z1z+14',
  '3m4m5m4p5p5p6p6p7p4s5s6s7s+7s+23',
  '6m7m8m2p3p4p6p6p7p8p9p4s5s3s+24',
  '4m4m5m5m7p7p7p4s4s4s9s9s9s4m+11',
  '2m3m3m8m8m5p5p8s8s9s9s2z2z+2m+11',
  '3m4m5m6m7m9m9m1p2p3p2s3s4s8m+11',
  '2m3m4m4m5m6m3p4p5p6p5s6s7s+6p+11',
  '1m2m3m4m5m6p7p8p9p9p3z3z3z+6m+23',
  '3m4m5m7m7m4p5p6p6p7p8p7s8s+9s+13',
  '3m4m5m6m7m8m1p2p3p4p5p4s4s3p+12',
  '4m5m6p6p2s3s4s4s5s6s6s7s8s+3m+12',
  '4m5m6m1p2p3p5p6p1s2s3s4s4s+4p+24',
  '3m3m5m6m7m3p3p3p4p5p6p5s6s7s+14',
  '4m4m5m5m7m7m4p4p5p5p4s8s8s+4s+24',
  '3m4m7m7m7m3p3p8p8p8p4s5s6s+2m+14',
  '1m2m3m4m5m6m1p2p3p1s1s3s4s5s+21',
  '3m4m5m3p4p5p5p6p7p8p8p3s4s+2s+13',
  '6m7m8m3p3p4p5p6p8p8p3s3s3s+3p+13',
  '4m5m6m4p5p5p6p6p7p6s7s2z2z+5s+14',
  '7m8m9m2p3p4p7p8p9p5s5s7s8s6s+12',
  '3m4m5m3p3p3p4p5p5p6p6p6s6s+7p+23',
  '2m2m3m4m5m3p5p7p7p7p5s6s7s4p+11',
  '2m3m4m8m8m2p2p5p6p7p6s6s6s8m+12',
  '2m4m6m7m8m2p3p4p5p6p7p2s2s+3m+23',
  '4m5m2p3p4p5p6p7p1s2s3s3z3z+3m+22',
  '4m5m6m2p3p4p7p8p8p8p2s3s4s+9p+13',
  '3p4p5p1s1s1s2s3s4s5s6s7s8s6s+22',
  '2m3m4m5m6m7m2p3p4p4p2s3s4s+1p+21',
  '4m4m2p3p4p4p5p6p1s1s2s2s3s+3s+11',
  '1m2m3m6m7m8m9m9m2s3s5s6s7s1s+14',
  '2m3m5m5m6m6m7m7m9p9p4s5s6s+1m+22',
  '3m3m4m5m6m7p8p9p2s3s4s5s6s4s+24',
  '4m5m6m7m8m3p4p5p9p9p6s7s8s3m+22',
  '1m2m3m3m4m3p3p4p5p6p7s8s9s+2m+12',
  '2p3p5p6p7p1s2s3s4s5s6s4z4z1p+13',
  '3m4m5m2p3p4p1s2s3s5s6s7s7s+7s+22',
  '1m1m1m2m3m3p4p7p8p9p6s7s8s5p+11',
  '2m3m5m6m7m7m8m9m1p2p3p1s1s+1m+12',
  '2p2p6p6p8p8p4s4s1z1z3z3z6z+6z+12',
  '2m3m3p4p5p7p7p7p8p9p4s5s6s1m+12',
  '1m1m2m2m3m3m4m5m6m7m3s4s5s+4m+13',
  '4m5m6m7m8m2p2p7p8p9p4s5s6s9m+13',
  '2m3m4m5m6m7m9m9m2p3p5s6s7s+1p+13',
  '3m4m5m7m8m1p1p4p5p6p2s3s4s+6m+22',
  '1m1m2m3m4m1s2s2s3s3s4s5s6s+7s+21',
  '3m4m5m7m8m8p8p2s3s4s6s7s8s+6m+21',
  '2m3m4m6m7m8m6p7p4s5s6s6s6s+8p+14',
  '4m5m6m1p2p3p3p4p5p5p6p7p8p+2p+13',
  '1p2p3p6p7p8p1s1s1s2s3s3s4s5s+23',
  '2m3m4m1p2p3p4p5p7p7p2s3s4s6p+24',
  '1m2m3m4p4p5p5p6p2s2s3s4s5s3p+24',
  '5m6m7m8m9m4p5p6p9p9p5s6s7s+7m+23',
  '4m5m1p2p3p5p5p6s7s8s4z4z4z+3m+24',
  '2m3m4m5m6m7m2p3p4p5p6p3s3s+7p+14',
  '4m5m6m6m8m3p4p5p4s5s6s7s7s7m+23',
  '5m6m2p3p4p5p5p5s6s7s7s8s9s+7m+11',
  '3m4m5m6m6m7m8m9m4s5s5s6s7s+3s+13',
  '3m4m7m8m9m2p3p4p6p7p8p2s2s+2m+13',
  '3m4m5m3p4p5p7p7p2s3s4s4s6s+5s+14',
  '4m4m5m6m7m4p5p1s2s3s3s4s5s3p+11',
  '7m8m3p4p4p4p5p5p6p7p1s2s3s+9m+12',
  '3m4m5m1p1p2p2p3p7p7p3s4s5s+3p+12',
  '1m1m2m3m4m5m6m5p6p7p3s4s5s7m+22',
  '4m5m6m5p7p2s2s2s1z1z1z3z3z6p+21',
  '3m4m5m6m7m8m6p6p1s2s3s7s8s+9s+14',
  '3m4m5m7m8m9m6p6p4s5s5s6s6s+7s+13',
  '1m2m3m7m8m5p6p7p2s2s7s8s9s+9m+21',
  '1m2m3m4m5m6m3s4s7s8s8s8s9s+5s+13',
  '5m7m7m8m9m1p1p7p8p9p7s8s9s6m+14',
  '8m8m8m3p4p5p4s4s4s5s5s5s6s+6s+24',
  '1m2m3m7m8m9m2p2p4p5p5s6s7s3p+12',
  '3m3m6p7p1s1s2s2s3s3s7s8s9s+8p+11',
  '4p5p6p8p8p8p2s2s2s4s4s7s7s7s+12',
  '1m2m3m8m8m7p8p9p6s6s7s7s8s+8s+22',
  '1p2p3p4p5p6p7p8p9p3s3s5s6s4s+11',
  '5p6p6p6p7p4s5s6s7s7s8s8s9s+9s+21',
  '6m7m8m3p4p5p2s2s7s8s9s7z7z7z+11',
  '5m5m6m7m3p3p4p4p5p5p5s6s7s+8m+12',
  '4m5m2p2p7p8p9p2s3s4s5s6s7s+3m+23',
  '4m5m7m8m9m7p8p9p5s6s7s7s7s+6m+13',
  '3m4m7p8p9p2s2s2s3s4s6z6z6z+2m+12',
  '7m7m2p3p4p8p8p8p7s8s1z1z1z+6s+12',
  '6m7m8m1p2p3p5p5p2s3s5s6s7s+1s+22',
  '6m6m7m7m8m6p7p8p9p9p4s5s6s+8m+11',
  '2m4m2p3p4p7p8p9p2s3s4s6s6s+3m+22',
  '3m3m4m4m5m5m7p7p5s4z4z6z6z5s+12',
  '2p4p5p6p6p7p7p8p3s3s3s4s4s+3p+22',
  '2m3m4m7m8m9m3p4p5p1s1s4s5s+6s+13',
  '2m2m3m3m5p6p7p4s5s6s5z5z5z+2m+21',
  '2m3m4m5m7m2p3p4p5p6p7p8p8p+6m+11',
  '5m5m7m8m9m8p8p4s5s6s5z5z5z+8p+14',
  '2m3m4m6m7m1s2s3s7s8s9s3z3z+8m+12',
  '5m6m7m5p5p6p7p6s6s7s7s8s8s+8p+14',
  '7m8m3p4p5p8p8p1s2s3s7s8s9s+9m+12',
  '5m5m3p4p5p6p8p6s7s8s1z1z1z7p+21',
  '4m5m6m1s2s3s4s5s6s7s7z7z7z4s+21',
  '2m3m4m5m5m7m8m1s2s3s5s6s7s+6m+21',
  '1m2m3m6m7m5p6p7p8p8p2s3s4s+8m+11',
  '5m6m7m1p1p3p4p5p5p6p7p6s7s+8s+24',
  '7m8m9p9p1s1s2s2s3s3s4s5s6s9m+11',
  '4m6m8m8m3p4p5p6p7p8p4s5s6s+5m+13',
  '2m3m2p3p4p2s2s6s6s6s7z7z7z1m+13',
  '7m8m2p3p4p5p6p7p9s9s5z5z5z6m+14',
  '4m5m6m2p4p4p5p6p4s5s6s1z1z+3p+14',
  '5m6m7m2p3p3p4p4p6p6p2s3s4s+5p+12',
  '2m3m4m6m7m7m7m8m5s6s7s3z3z3z+13',
  '2m3m3p4p5p7p7p6s7s7s8s8s9s+1m+13',
  '1m2m3m2p3p4p7p7p1s2s2s3s3s4s+23',
  '5m6m7m3p4p5p5p5p4s5s6s7z7z+7z+21',
  '7m8m9m1p2p3p3p3p4p5p6p6s7s+5s+13',
  '2m3m4m4m5m6m5p6p5s5s7s8s9s4p+11',
  '4m5m6m1p2p3p5p6p2s3s4s7s7s7p+21',
  '5m6m8m8m2p3p4p7p7p7p2s3s4s+4m+12',
  '3m4m5m2p4p6p7p8p2s2s5s5s5s+3p+13',
  '2m3m4m9m9m6p6p6p7p8p9p6z6z6z+24',
  '3m4m7m7m4p5p6p3s4s5s6s7s8s2m+11',
  '4m5m7m8m9m2s3s4s4s5s6s7s7s+6m+13',
  '1m2m3m5m6m7m4p5p6p1s1s3s4s+5s+23',
  '1p2p3p3p4p5p6p6p6p9p9p3z3z+3z+21',
  '2m2m7p8p9p4s4s4s6s6s6s5z5z2m+12',
  '4m4m4p4p5p5p6p3s4s4s5s5s6s+3p+14',
  '1p2p3p4p5p6p8p8p8p8p9p1z1z7p+24',
  '2m3m4m7m8m7p8p9p4s5s6s6s6s+6m+22',
  '2m2m3m3m4m4m4p5p6p7s8s9s1z1z+21',
  '2m3m4m6m7m8m7p7p4s5s6s5z5z+5z+13',
  '4m5m6m2p2p4p5p6p9p9p1z1z1z+9p+21',
  '7m8m9m2p3p4p5p6p6s7s8s4z4z4p+11',
  '7m8m3p3p3p4p5p1s2s3s3s4s5s9m+14',
  '7m8m9m6p7p8p1s2s3s5s6s2z2z4s+11',
  '5m5m5m3p4p4p4p5p4s5s6s6s7s+8s+22',
  '3m3m5m6m7m5p6p7p7s8s3z3z3z6s+23',
  '5m6m7m7m8m9m5p6p7p8p8p4s5s6s+13',
  '4m4m4p5p6p6p7p8p3s4s5s5s6s7s+14',
  '1m3m4p5p6p4s4s7s8s9s7z7z7z+2m+22',
  '6m7m8m5p5p6p7p2s3s4s6s7s8s+8p+13',
  '2m3m4m6m7m8m2p3p7p7p2s2s2s4p+11',
  '3m4m5m6m7m8m4p5p2s3s4s8s8s3p+13',
  '1m1m8m8m5p5p8p8p8s8s1z2z2z+1z+21',
  '1m2m3m3m4m5m7m8m9m6p7p7s7s8p+22',
  '5m6m2p3p3p3p4p5s6s6s7s7s8s+4m+23',
  '3m4m5m1p2p3p5p6p4s5s6s7s7s7p+14',
  '3m4m5m1p2p3p5s5s6s7s7s8s9s8s+14',
  '1m1m2m3m3m1p1p2s2s7s7s6z6z+2m+21',
  '1m2m3m7m8m9p9p4s5s5s6s6s7s+6m+21',
  '1m2m3m6m7m8m5p6p7s7s5z5z5z7p+21',
  '6m7m8m3p4p5p1s2s3s5s6s2z2z+4s+11',
  '4m5m7m8m8m8m9m3p4p5p3s4s5s+6m+23',
  '1m2m3m4p5p6p7p8p9p4s4s6s7s8s+21',
  '5m6m1p2p3p2s3s4s5s6s7s4z4z4m+33',
  '2m3m5m6m7m2p3p4p1s2s3s5s5s+4m+14',
  '3p3p4p4p5p5p1s2s3s5s5s6s7s+8s+13',
  '6m7m7m8m9m9p9p2s3s4s6z6z6z+5m+14',
  '6m7m8m6p7p8p4s5s6s7s8s2z2z+9s+14',
  '4m4m4m6m7m8m4p4p6p8p6s7s8s+7p+12',
  '5m6m6m7m8m7p8p9p6s7s8s9s9s4m+12',
  '5m6m6m7m8m3p3p4p5p6p5s6s7s7m+21',
  '2m2m3m4m5m5p6p7p6s7s7z7z7z+8s+23',
  '2m3m4m2p3p3p4p4p7p7p7s8s9s5p+24',
  '4m5m5m6m6m7m6p4s4s5s5s6s6s6p+13',
  '4m4m8m8m9m9m2p2p1s1s6s6s2z+2z+14',
  '4m5m5m6m6m5p5p3s4s5s6s7s8s+4m+22',
  '3m4m5m1s1s3s4s4s5s5s6s7s8s+9s+13',
  '1m2m3m4m4m5m6m2p3p4p5s6s7s7m+12',
  '4m4m8m8m8m3p4p6p7p8p1z1z1z+5p+13',
  '8m8m9m9m5p5p6p6p3s3s7s7s8s8s+23',
  '2m3m4m5m5m7m8m5p6p7p7s8s9s+9m+24',
  '7m7m3p3p5p6p7p6s7s8s6z6z6z3p+21',
  '2p3p7p8p9p2s2s4s5s6s6s7s8s+1p+21',
  '2m3m1p2p3p5p6p7p2s2s4s5s6s1m+21',
  '1m2m2m3m3m5m6m7m4s4s5s6s7s1m+21',
  '3p4p4p5p5p6p6p7p8p3s4s4s5s+4s+13',
  '4m5m6m9m9m6p7p1s2s3s7s8s9s5p+11',
  '3m3m5p6p7p8p9p4s5s6s7s8s9s+4p+12',
  '1m2m3m6m6m5p6p7p5s6s7s6z6z6z+12',
  '1m2m3m3m4m5m1p2p1s2s3s6s6s+3p+14',
  '4m5m6m2p3p4p6p7p9p9p5s6s7s5p+13',
  '1p1p3p4p5p1s2s3s3s5s6z6z6z+4s+23',
  '3m3m4m5m6m4p5p6p2s2s4s4s4s+2s+11',
  '4p4p6p7p8p8p8p4s5s6s6s7s8s+5p+23',
  '4m5m6m2p3p4p5p5p4z4z4z7z7z+5p+24',
  '2m2m3m3m4m4m5m5m7m8m1p2p3p9m+12',
  '2m3m4m5p5p4s5s5s6s6s7s8s9s+4s+24',
  '4m5m6m9m9m7p8p2s3s4s5s6s7s9p+23',
  '3m4m5m6m6m2p3p2s3s3s4s4s5s+1p+14',
  '4m5m1p2p3p5p6p7p1s1s2s3s4s+3m+14',
  '1m2m3m8m8m3p4p5p6s7s4z4z4z5s+14',
  '5p6p6p7p7p8p2s4s4s4s6s6s6s2s+22',
  '7m8m9m7p8p9p1s1s5s6s7s8s9s4s+33',
  '2m2m3m4m7m8m9m5p6p7p4s5s6s5m+12',
  '5m5m6m6m3p3p4p4p4s9s9s6z6z+4s+13',
  '2m2m3m4m5m6m7m8m5p6p7p7p8p6p+12',
  '2m3m4m5p5p7p8p1s2s3s3s4s5s+9p+11',
  '3m4m5m4p5p7p7p3s3s3s5s6s7s+3p+11',
  '3p3p4p4p5p4s5s6s1z1z4z4z4z5p+11',
  '1m2m3m6m7m8m9m9m3p4p5p4s5s+3s+12',
  '1m2m3m7m8m9m1p2p1s1s1s2s3s3p+14',
  '2m2m3m4m5m6m6m6m4p4p4p5p6p+2m+14',
  '3m4m4m4m7p8p9p5s5s6s6s7s7s2m+21',
  '5m6m7m2p3p4p6p7p8p4s5s6s7s4s+11',
  '1m2m3m5m5m2p3p4p5p6p1s2s3s+1p+12',
  '2m2m5m5m8m8m1p1p6p6p6s6s9s+9s+23',
  '1m2m4m5m6m7m8m9m7p7p1s2s3s3m+12',
  '2m3m4m5m6m7m5p6p5s6s7s9s9s+4p+13',
  '1m2m3m3m4m4p4p7p8p9p3s4s5s2m+21',
  '1m2m3m1p2p3p6p7p6s6s7s8s9s+5p+14',
  '3m4m5m7m7m7m6p7p6s6s6s8s8s8p+23',
  '3m4m1p1p4s4s4s7s8s9s5z5z5z+2m+13',
  '2m2m5m6m5p6p7p3s4s5s6s7s8s+4m+11',
  '6m7m4p4p6p7p8p5s6s7s6z6z6z+5m+23',
  '7m8m9m1p1p2p3p4p4s5s6s6s7s8s+11',
  '4m5m2p3p4p5p6p7p2s2s3s4s5s3m+21',
  '4p5p7p8p9p2s3s4s6s7s8s3z3z+3p+24',
  '2m3m4m5m6m2p2p4p5p5p6p6p7p+1m+13',
  '3m3m4m5m6m4p5p5p6p6p7p3s4s+2s+23',
  '5m6m7m8m9m7p8p9p6s7s8s9s9s4m+22',
  '1m1m2m2m3m3m4m6m2s2s2z2z2z+5m+12',
  '2m3m4m5m6m4p5p6p8p8p4s5s6s4m+24',
  '1m1m6m7m8m7p8p5s6s7s7s8s9s9p+11',
  '5m5m6m6m7m1p2p2p3p3p4p2s2s+4m+21',
  '2p2p3p4p4p3s4s5s6s7s8s9s9s+3p+12',
  '2m3m4m6m7m8m3s4s5s5s5s6s7s2s+13',
  '2m3m4m5m5m5m6m7m8m9m1s2s3s+4m+22',
  '7m9m1s2s3s7s8s9s2z2z4z4z4z8m+21',
  '5m5m6m7m8m2p3p4p3s4s5s6s7s2s+11',
  '3p3p4p4p5p5p3s7s7s9s9s6z6z+3s+23',
  '4m5m2p3p4p9p9p1s2s3s6s7s8s6m+14',
  '6m7m7m8m9m8p8p3s4s5s7s8s9s5m+11',
  '4m5m5m6m6m7m2p3p6p6p4s5s6s1p+12',
  '6m7m4p5p6p6p7p8p4s4s5s6s7s5m+21',
  '7m8m9m2p3p4p4s5s6s7s7s6z6z6z+21',
  '1m2m3m9m9m1p2p3p7p8p9p3s4s+2s+21',
  '1m2m2m3m3m4m5m6m3s4s5s2z2z1m+12',
  '2m3m4m4m5m8p8p3s4s5s7s8s9s+6m+21',
  '5m5m6p7p8p1s2s4s5s6s7s8s9s+3s+22',
  '7m8m2p3p4p1s2s3s7s8s8s8s9s6m+23',
  '4m5m5m6m6m7m2p3p4p5s5s5s6s4s+14',
  '3m4m5m8m8m5p6p3s3s3s4s5s6s+4p+11',
  '2m5p5p1s1s2s2s5s5s6s6s1z1z+2m+11',
  '2m3m4m7m8m9m6p7p5s6s7s4z4z+8p+21',
  '5m5m6m6m7m7m5p6p7p3s4s5z5z2s+12',
  '1p1p2p3p4p5p6p7p8p9p7s8s9s+7p+22',
  '2m3m4m2p4p6p7p8p2s3s4s6z6z+3p+11',
  '9m9m4s4s8s8s9s9s1z5z5z7z7z1z+21',
  '3m4m5m1p2p3p7p8p9p9p9p7s8s+9s+32',
  '2m4m5m6m2p2p2p4p5p6p5s6s7s+2m+24',
  '2m3m4m7m8m9m5p6p7p2s2s4s5s+6s+14',
  '3m4m6m7m8m1p2p3p6p7p8p1s1s+2m+21',
  '4m5m6m7m7m4p5p3s4s5s7s8s9s3p+21',
  '5m6m7m7m8m2p3p4p5p6p7p8s8s+9m+14',
  '5m6m7m2p3p6p7p8p2s3s4s5s5s1p+14',
  '5m6m7m7m7m4p5p6p1s2s3s6s7s+8s+11',
  '1m1m2m2m3m3m7m8m9m1p3p9s9s+2p+12',
  '3p4p5p2s2s3s4s5s7s7s8s8s9s9s+14',
  '3m3m6m7m8m2p3p4p3s4s5s7s8s+9s+24',
  '1p1p2p3p3p3p4p5p5s5s7s8s9s2p+22',
  '1m1m2m3m3m9m9m9m3p3p3p7p7p+2m+11',
  '1m2m3m6m7m5p6p7p7p8p9p5s5s+5m+13',
  '7m7m7p7p7p7s8s9s4z4z5z5z5z4z+12',
  '5m6m7m7m8m9m7p8p9p3s4s9s9s5s+13',
  '4m5m4p4p5p5p6p6p4s4s6z6z6z+3m+12',
  '4m5m6m3p4p6p7p8p2s2s6s7s8s+2p+11',
  '1p1p1p2p3p3s4s4s5s6s7s8s9s5s+12',
  '5m6m7m2p3p4p9p9p6s6s7s8s8s+7s+13',
  '1m1m5m5m6m6m5p5p1s1s6s6s5z+5z+11',
  '8p8p9p9p2s4s4s8s8s2z2z7z7z+2s+24',
  '1m2m3m5m6m7m5p6p2s2s5s6s7s7p+12',
  '3m4m6m7m8m3s4s5s5s6s7s8s8s+2m+22',
  '1m2m3m1s1s3s4s5s5s6s6s7s7s+2s+13',
  '6m6m7m8m9m2p3p1s2s3s5s6s7s1p+13',
  '8m8m3p3p3s3s6s6s2z4z4z6z6z2z+12',
  '3m3m4m4m5m5m6m6m6m7m8m9m9m+9m+11',
  '1m1m1m3m4m7m7m7m4p4p7s7s7s2m+11',
  '2m2m2m4m5m5m6m6m7m4p5p3s3s6p+14',
  '2m2m4m4m5m6m7m5p6p7p2s3s4s+2m+12',
  '3p4p5p5p6p7p5s6s6s7s8s4z4z+7s+23',
  '3m4m5m9m9m3p4p7p8p9p3s4s5s+5p+21',
  '5m5m2p3p4p7p9p6s7s8s2z2z2z8p+12',
  '2m2m3m3m9m9m3p3p7s9s9s2z2z7s+12',
  '3m4m5m5m6m7m8p8p4s5s5s6s7s+6s+11',
  '3m3m4m4m5m1p2p3p4p5p6p2s2s+5m+12',
  '2m3m1p2p3p4p5p5p5p6p7s8s9s1m+13',
  '1m2m3m4m5m6m2s3s4s4s5s7s7s3s+11',
  '7m7m6p7p8p2s3s3s4s4s5s7s8s+9s+14',
  '2m3m4m1p2p3p7p8p9p1s1s5s6s+7s+22',
  '3m4m5m1p3p7p7p7s8s9s6z6z6z+2p+11',
  '4m5m6m7m8m9m5p6p3s4s5s6s6s7p+14',
  '3m4m5m1p2p3p3s4s4s5s5s5s6s+2s+13',
  '1m2m3m7m7m4p5p6p2s3s4s6z6z6z+23',
  '2m2m6m7m8m4p5p6p4s5s7s8s9s+6s+13',
  '7m8m9m3p4p5p1s2s3s5s6s7s7s4s+11',
  '3m4m5m6m7m8m4p5p3s4s5s6s6s6p+21',
  '2m3m4m6m7m8m5p5p2s3s4s6s7s5s+21',
  '4m5m6m4p5p6p7p8p4s5s6s6s6s3p+14',
  '7m8m9m2p3p4p7p9p2s2s7s8s9s+8p+11',
  '2m2m5m5m9m9m9p9p3s3s6s6s3z+3z+13',
  '5p6p6p7p7p8p1s2s3s4s4s5s6s+7s+14',
  '3m4m5m2p3p5p6p7p6s7s8s2z2z+1p+14',
  '7m8m9m3p4p6p7p8p2s2s5s6s7s5p+12',
  '4m5m6m2p3p4p5p6p7p1s1s2s3s+4s+13',
  '4m4m7p7p8p9p9p1s1s1s5s6s7s8p+21',
  '4m5m1p2p3p1s2s3s5s6s7s8s8s+6m+13',
  '2m5m5m6m6m2p2p4p4p5s5s5z5z+2m+12',
  '3m4m5m4p5p6p6s7s8s8s5z5z5z+5s+23',
  '1m2m3m4m5m6m1p2p8p8p1s2s3s3p+12',
  '3m4m5m5m4s5s6s7s8s9s5z5z5z+2m+11',
  '2m2m3m4m5m2p2p2p4s6s7z7z7z+5s+21',
  '6m6m4p4p5p5p6p6p1s2s3s3z3z3z+11',
  '5m6m4p5p6p8p8p8p3s3s8s8s8s4m+21',
  '1m1m4m5m3p3p4p4p5p5p7s8s9s3m+12',
  '5m6m7m3p3p4p5p6p2s3s4s6s8s+7s+14',
  '5m6m7m3p4p6p6p2s3s4s5s6s7s5p+12',
  '5m6m7m4p4p6p7p8p2s3s4s7s7s7s+23',
  '1m2m3m4m5m6m7s8s9s2z2z2z3z3z+23',
  '7m8m9m4p5p7p8p9p4s5s6s4z4z6p+23',
  '4m5m6m9m9m6p7p8p5s6s7s8s9s+7s+12',
  '2m3m4m4m5m7m7m3p4p5p6s7s8s+3m+23',
  '3m3m3m4m5m7m8m9m3p4p7s8s9s5p+21',
  '3m4m6m7m8m1s2s3s4s4s5s6s7s+5m+22',
  '2s3s4s4s4s5s5s6s7s8s4z4z4z+5s+22',
  '1m1m1m2m3m1p2p3p7p8p9p2s3s1s+12',
  '4m5m6m7m8m4p4p5p6p7p6s7s8s+9m+14',
  '6m6m3p3p4p4p5p5p4s4s5s6s6s5s+14',
  '5m6m6m7m7m8m4p4p7p7p3s4s5s+4p+13',
  '1m2m3m3m4m5m9m9m1s2s3s6s7s8s+13',
  '3m3m6m6m5p5p1s1s2s2s5s5s8s8s+13',
  '1m2m3m4m5m6m3p4p5p5s6s9s9s4s+14',
  '4m5m6m1p2p3p5p6p4s5s6s2z2z4p+12',
  '5m6m1p2p3p4p5p6p7p8p9p1s1s4m+13',
  '2m3m4m4p5p6p2s3s3s4s5s9s9s4s+23',
  '1m2m3m3m4m5m8m8m5p6p4s5s6s4p+13',
  '2m3m3m4m4m5m5s6s6s7s7s9s9s+5s+23',
  '5m6m7m4p5p5p5p6p5s6s7s8s9s4s+21',
  '6m7m8m5p5p7p8p2s3s4s6s7s8s+9p+14',
  '5m6m2p3p4p5p6p7p4s5s6s8s8s4m+11',
  '2m3m4m5m6m7m3p3p3p6p6p4s5s+6s+13',
  '1m2m3m4m5m6m7m8m1p2p3p8p8p+3m+24',
  '2m3m4m7m7m3p4p5p2s3s3s3s4s7m+14',
  '5m5m6m7m8m2p2p2p5p6p4s5s6s+4p+23',
  '5m6m7m1p2p3p7p8p4s4s5s6s7s+6p+12',
  '4m5m6m1p2p3p4p5p6p3s4s7s7s5s+11',
  '2m3m3m4m4m2p3p4p6p6p6s7s8s2m+14',
  '4m5m5m6m7m5p6p7p1s1s5s6s7s3m+11',
  '3m4m5m6m7m3p4p5p5s5s5s6s7s+8m+12',
  '5m5m6m6m4p4p6p6p7p7p5s6s6s+5s+11',
  '3m4m5m5m3p4p5p7p8p9p3s4s5s+2m+13',
  '1s1s3s3s4s4s5s6s7s8s2z2z2z+2s+23',
  '7m7m1p2p3p7p7p8p8p9p2s3s4s+6p+23',
  '5m5m6m6m7m7m6p6p7p7p8p4s4s8p+13',
  '8m8m8p8p5s5s7s7s8s8s6z7z7z+6z+21',
  '3m4m5m2p2p3p3p4p4p5p6p1z1z+1p+22',
  '5m5m7m8m2p3p4p1s2s3s4s5s6s+9m+12',
  '3m4m5m5m5m7m7m7m2p3p5p6p7p4p+14',
  '4m4m4m7m7m2p3p4p4p5p6p6s6s6s+21',
  '6m7m7m8m8m9m3s4s4s4s5s6s7s8s+24',
  '1m2m3m7p8p1s1s2s3s4s5s6s7s+6p+12',
  '1m2m3m6m7m8m5p6p7p8p9p8s8s+4p+22',
  '2m3m4m5m6m9p9p2s3s4s5s6s7s4m+13',
  '5m6m7m5p6p7p7p8p9p3s3s5s6s7s+11',
  '4m5m6m7m8m9m7p8p4s4s5s6s7s+6p+22',
  '5m6m7m6p7p8p2s3s4s5s6s8s8s7s+13',
  '1p1p7p7p8p8p9p9p6s6s7s7s8s5s+14',
  '3m4m5m5p6p6p7p7p4s4s1z1z1z8p+14',
  '5m6m7m2p2p3p3p4p6p7p8p2s2s+1p+23',
  '3m5m6m7m8m2s2s3s4s5s6s7s8s+4m+13',
  '1m1m2m2m3m4m4m7p7p3s3s1z1z+3m+13',
  '2m3m4m9m9m2p3p4p5p6p5s6s7s+4p+11',
  '5m6m7m2p2p2p4p4p5p6p7p4s5s+3s+14',
  '4m5m6m5p6p7p8p8p2s3s5s6s7s1s+11',
  '1m2m3m1p1p1s2s3s5s6s7s8s9s4s+14',
  '1m1m5m5m1p1p4s4s6s6s3z3z5z5z+22',
  '4m4m5m5m5m6m7m8m2p2p2p8s8s+8s+11',
  '3m4m5m3p3p5p6p7p2s3s4s6s6s6s+24',
  '5m5m3p4p5p7p8p9p4s5s5s6s7s3s+23',
  '3m3m5m7m7m5p5p5s5s7s7s2z2z+5m+24',
  '2p3p4p4p5p5p6p6p6s7s8s9s9s+1p+14',
  '3m5m6m6m7m7m7m4p5p6p5s5s5s+4m+11',
  '4m5m3p4p5p2s3s4s6s6s7s8s9s+3m+11',
  '2m3m4m3p3p3p6p6p5s5s6s6s7s+4s+13',
  '3m4m5m6m7m8m3p4p5p6s7s9s9s+5s+14',
  '2m3m4m1p1p6p7p8p5s6s7s8s9s4s+11',
  '5p5p6p7p2s2s2s3s4s5s5s6s7s5p+11',
  '1m2m3m5m6m5p5p1s2s3s5s6s7s4m+22',
  '5m5m6m7m7m8m8m9m5s6s7s7s8s9s+11',
  '4p4p5p5p6p6p6p7p8p9p4s5s6s+9p+21',
  '1m2m3m5m5m1p2p3p4s5s6s7s8s+9s+14',
  '4m4m4m5m6m3s4s5s5s6s8s8s8s+4s+22',
  '1m1m7m8m9m3p4p6p7p8p5s6s7s2p+13',
  '7m8m9m1p2p3p6p6p8p9p7s8s9s+7p+12',
  '2m4m2p3p4p5p6p7p3s3s4s5s6s+3m+13',
  '5m5m5m6m7m8m4s4s5s6s6s7s7s5s+22',
  '3m4m4m5m5m6m5p6p5s6s7s1z1z4p+22',
  '6m7m8m3s3s3s6s7s1z1z6z6z6z+8s+13',
  '3p3p6p7p3s3s3s5s6s6s7s7s8s8p+21',
  '2m3m4m2p2p3p3p3p5p6p7p6s7s8s+11',
  '7m7m8m9m9m2p3p4p3s3s4s5s6s+8m+11',
  '1m2m3m5m6m7m3p4p5p1s1s5s6s+4s+21',
  '9m9m1p2p2p3p3p4p5s6s7s8s9s+7s+12',
  '2m3m4m5m7m1p1p3p3p4p4p5p5p6m+23',
  '2p3p4p5p5p2s3s3s3s4s4s5s5s+1s+24',
  '3m3m5m6m7m4p5p7p8p9p3s4s5s+3p+11',
  '3m4m5m2p5p6p7p7s8s9s6z6z6z+2p+24',
  '2m3m4m7p8p3s3s4s4s5s5s7s7s+6p+24',
  '3m4m7m7m3p4p5p3s4s4s5s5s6s5m+12',
  '3m4m5m5m6m6p6p1s2s3s3s4s5s4m+12',
  '2m3m4m4m5m6m5p6p7p8p5s6s7s+8p+23',
  '4m5m6m7m8m9m7p8p9p1s1s3s4s+2s+13',
  '1m2m3m3m3m5m6m7m8m9m6s7s8s+4m+23',
  '5m6m7m2p3p1s2s3s4s4s6s7s8s+4p+12',
  '2m3m4m5m6m7m4p5p6p3s4s7s7s+2s+23',
  '5m6m7m2p3p6p6p2s3s4s5s6s7s+1p+13',
  '3m4m4m5m5m6m6m7m2p3p4p1s1s5m+22',
  '5m6m7m5p6p9p9p1s2s3s4s5s6s+7p+13',
  '7m8m9m9p9p1s2s3s4s5s6s7s8s+9s+14',
  '3m4m5m7m7m4p5p6p8p8p8p2s3s+4s+12',
  '7m8m9m2s2s4s5s7s8s9s1z1z1z+3s+14',
  '4m5m6m2p2p2p3p4s5s6s6s7s8s3p+21',
  '3m3m4m4m5m7m7m5p6p7p6s7s8s5m+12',
  '1m2m3m7m8m9m2p2p4p5p3s4s5s6p+11',
  '2m3m4m4m5m4p5p6p9p9p3s4s5s6m+21',
  '5m6m7m2p3p4p5p7p8p8p5s6s7s+6p+12',
  '4m5m6m4p5p6p2s2s2s3s4s8s8s+5s+23',
  '2m3m4m1p2p3p4p5p6p4s5s9s9s3s+11',
  '1m2m3m4m4m5m6m7m3p4p7s8s9s+5p+12',
  '4m5m6m8m8m6p7p8p6s8s7z7z7z+7s+24',
  '2m3m4m6m6m5p6p7p2s3s4s6z6z+6z+11',
  '5m5m7m7m8m8m9m9m1z1z2z7z7z2z+22',
  '6m6m7m8m9m7p8p2s3s4s7s8s9s9p+14',
  '4m5m6m3p4p4p5p5p6p1s1s7s8s+9s+14',
  '3m4m5m2p3p7p8p9p5s6s7s1z1z+4p+24',
  '3m4m5m5m6m3p4p5p8p8p4s5s6s+4m+12',
  '3m5m5m6m7m3p4p5p7p7p3s4s5s4m+14',
  '1m1m3p4p5p7p8p3s4s5s7s8s9s+6p+21',
  '4m5m6m3p4p7p7p1s2s3s4s5s6s+2p+11',
  '1m2m3m5m6m7m7p8p3s4s4s4s5s6p+23',
  '5m6m7m5p5p6p7p7p8p9p6s7s8s5p+14',
  '4p5p5p5p6p7p8p9p4s5s7s8s9s+6s+13',
  '2m3m4m4m5m6m4p5p6p2s2s4s6s+5s+21',
  '1m1p1p3p3p8p8p9p9p7s7s2z2z1m+11',
  '4p5p6p3s3s4s4s5s5s6s6s7z7z7z+22',
  '1m2m3m8m8m7p8p9p3s4s5s5z5z+5z+13',
  '1m2m3m4m5m6m3p4p5p3s3s6s7s8s+21',
  '2m3m4m6m7m4p5p6p7p7p4s5s6s+5m+13',
  '3m4m5m7p9p2s2s4s4s5s5s6s6s+8p+13',
  '2m2m7m9m7p8p9p2s3s4s7s8s9s+8m+14',
  '3m4m5m7m8m4p5p6p1s2s3s9s9s+6m+12',
  '3m4m2p3p3p4p4p5p2s3s4s1z1z+5m+23',
  '2m2m3m4m6m7m8m2p3p4p4s5s6s+2m+22',
  '2m2m2m8m8m8m2p2p5s5s5s9s9s9s+12',
  '6m6m7m8m9m2p3p5p5p6p6p7p7p1p+13',
  '2m3m6m6m7m7m8m8m8p8p1s2s3s+1m+12',
  '2m3m4m6m7m2p2p6p6p6p6s7s8s8m+22',
  '4m4m3p4p5p6p7p8p4s5s6s7s8s+9s+14',
  '1m2m3m6m7m8m8m3p4p5p7p8p9p+5m+24',
  '4p5p5p6p7p8p8p8p3s4s5s6s6s+6p+22',
  '2m3m4m7m8m5p6p7p5s6s7s8s8s9m+12',
  '7p7p3s3s6s6s7s7s3z5z5z7z7z+3z+14',
  '6m7m8m5p5p6p6p7p6s7s8s3z3z+7p+11',
  '4p5p6p3s4s4s4s5s5s6s6s7s8s4s+11',
  '3m4m5m1p2p3p3p4p5p7p8p4s4s+9p+21',
  '2m3m4m5m6m7m1p1p5p6p7s8s9s+7p+12',
  '3m4m5m6m7m8m4p4p6p6p6p3s4s2s+14',
  '1m1m4p5p7p8p9p1s2s3s5s6s7s+3p+21',
  '5m6m7m9m9m3p4p5p5p6p6p7p8p+7p+14',
  '1m2m3m4m5m8m8m5p6p7p6s7s8s6m+22',
  '3m3m1p2p3p2s3s4s6s8s3z3z3z+7s+23',
  '2m3m4m3p3p3p4p4p5p5p6p6z6z+6p+14',
  '4m5m6m6m7m8m2p3p5p5p6p7p8p+1p+22',
  '1m2m3m5m6m7m1p1p1s2s3s6s7s5s+14',
  '6m6m7m8m9m6p7p8p7s8s9s7z7z+7z+22',
  '3m3m3p4p5p1s2s3s5s6s6s7s8s4s+24',
  '5m6m7m3p3p4p4p5p3s3s6s7s8s2p+24',
  '7m7m9m9m3s3s8s8s1z1z2z5z5z2z+12',
  '2m3m4m6m7m8m1p2p3p5p6p9s9s+4p+14',
  '3m3m4m4m5m5m5s6s8s8s4z4z4z7s+11',
  '1m2m3m6m7m8m2p3p4p2s2s6s7s8s+11',
  '3p3p4p4p5p5p4s8s8s3z3z6z6z+4s+12',
  '3m4m5m3p4p5p3s4s5s7s8s2z2z+6s+11',
  '4m5m6m7p7p7p8p1s2s3s4s5s6s6p+23',
  '7m8m9m2p3p3p4p4p3s4s5s5s5s2p+23',
  '4m5m7m8m9m2p3p4p1s2s3s5s5s6m+13',
  '5m6m7m7m8m7p8p9p1s2s3s5s5s9m+14',
  '4m5m6m3p4p1s2s3s5s6s7s9s9s5p+31',
  '6m7m8m2p3p4p6p7p7p7p2s3s4s5p+14',
  '6m7m8m2p3p4p6p7p4s5s6s2z2z5p+11',
  '2m3m4m4m5m5m6m6m7m8m3s4s5s2m+12',
  '3m3m1p2p3p4p6p7p8p9p4s4s4s5p+24',
  '8m8m9m9m2p2p4p4p7p8p8p4z4z7p+12',
  '2m3m4m5m5m2p2p2p7p8p2s3s4s6p+14',
  '5m6m1p2p3p4p5p6p8p8p3s4s5s+7m+23',
  '2m2m3m4m5m2p2p3p3p4p6s7s8s+1p+21',
  '5m6m7m8m9m2s3s4s4s5s6s7s7s+4m+14',
  '3m4m6p7p8p9p9p2s3s4s6s7s8s5m+23',
  '1m1m2m2m3m3m8m4s5s6s7s8s9s+8m+11',
  '5m6m7m3p4p5p4s5s6s6s7s8s8s8s+22',
  '2m2m4m5m7m8m9m7p8p9p7s8s9s+6m+24',
  '2m3m4m6p7p7p8p9p3s4s5s8s8s5p+12',
  '1m1m6m6m8m8m1p1p6p6p1z5z5z+1z+23',
  '3m4m4m5m6m6m6m1p2p3p6s7s8s+5m+14',
  '6m7m8m2p2p4s6s6s7s8s4z4z4z+5s+14',
  '4m5m6m6m7m4p5p6p6s7s8s2z2z+8m+13',
  '8m8m9m9m4p4p5s5s7s7s3z3z7z+7z+23',
  '5p5p6p7p7p2s2s8s8s8s5z5z5z6p+11',
  '3m3m2p3p4p4p5p6p7p8p9p2s3s1s+23',
  '1m2m3m5m5m7m8m9m1p2p3p3s4s+2s+12',
  '1m2m3m5m6m7m2p3p9p9p7s8s9s+1p+22',
  '5m6m7m4p4p5p6p6p7p8p1s2s3s+7p+21',
  '3m3m3p4p5p3s3s6s6s7s7s8s8s+3m+23',
  '5m6m7m3p4p6p7p8p3s3s6s7s8s+5p+12',
  '2m2m4m4m5p5p7p7p3s6s6s8s8s+3s+23',
  '2m3m4m7m8m9m4p4p3s4s5s5z5z+5z+21',
  '5m5m4p5p6p5s6s7s8s9s6z6z6z4s+21',
  '3m5m4p5p6p3s3s7s8s9s5z5z5z+4m+11',
  '4m4m4m8m8m1s2s3s7s8s1z1z1z+9s+12',
  '6m7m8m8m8m3p4p4p5p5p7p8p9p+6p+12',
  '1m2m2m3m3m4m4m5m6m4s4s6s7s+8s+14',
  '1m2m3m5m6m7m5p5p4s5s6s5z5z+5z+23',
  '4m5m6m6m7m8m1p1p2s3s4s7s8s6s+24',
  '3m4m4m4m5m5p6p7p2s3s6s7s8s+4s+24',
  '2m3m3p3p6p7p8p5s6s6s7s7s8s+4m+24',
  '2m3m4m6m7m4p4p7p8p9p4s5s6s+5m+12',
  '1m2m3m3p4p5p7p7p7p8p9p3s4s5s+12',
  '6m7m5p5p6p6p7p7p1s1s7s8s9s+5m+11',
  '4m5m6m2p3p4p4p5p2s3s4s5s5s6p+21',
  '1m1m4m5m6m3p4p7p8p9p6s7s8s+5p+14',
  '1m1m3p4p5p6p7p7s8s9s6z6z6z8p+23',
  '4m5m6m2p3p1s1s2s2s3s3s6s6s4p+12',
  '6m7m8m2p3p7p7p6s7s7s8s8s9s+1p+13',
  '2m2m2m5p6p8p8p5s6s7s5z5z5z+4p+24',
  '2p3p4p7p8p9p1s1s1s5s5s7z7z+7z+14',
  '2m3m4m6m6m3p5p2s2s3s3s4s4s4p+14',
  '3m3m4m4m5m7m8m9m3s3s6s7s8s2m+13',
  '1m2m3m5m5m2p3p4p3s4s7s8s9s2s+23',
  '4m5m6m6m3p4p5p1s2s3s6s7s8s+6m+24',
  '6m7m8m9p9p1s2s3s5s6s7s7s8s+6s+21',
  '5m5m6m7m8m2p4p5p6p7p5s6s7s+3p+14',
  '5m6m3p4p5p7p7p8p8p9p9p1s1s7m+21',
  '1m2m3m5p6p7p2s2s5s6s1z1z1z+7s+14',
  '2m3m4m1p2p3p3p4p1s2s3s6s6s+2p+12',
  '1m2m3m3m4m5m7p8p2s3s4s9s9s+9p+11',
  '3m3m4m1p1p3p3p2s2s5s5s9s9s+4m+13',
  '3m4m5m4p5p6p7s8s9s1z1z2z2z2z+24',
  '1m1m2m6m6m5s5s6s6s3z3z5z5z2m+24',
  '3m4m5m7m8m9m4p5p6p7p8p4s4s+9p+24',
  '3m4m5m6m7m4p5p5p6p6p7p7s7s+2m+12',
  '3m4m5m6m7m8m1p1p1p2s2s2z2z+2z+21',
  '2m5m5m9m9m1p1p4s4s6s6s5z5z+2m+11',
  '3m4m5m1p1p3p4p5p6p7p3s4s5s+8p+12',
  '4m4m2p3p4p5p6p7p5s6s7s7s8s+6s+11',
  '2m4m6m6m6m4s4s5s6s6s7s7s8s3m+22',
  '4m5m6m1p1p2p3p4p6p7p8p3s4s2s+12',
  '2m3m4m4m5m6m1p2p3p5s5s6s7s+8s+21',
  '2m3m3p4p4p5p5p6p7p8p9p3s3s4m+13',
  '1p1p1p3p4p5p6p6p7p7p7p8s8s8s+21',
  '5m6m7m2p3p4p6p7p4s5s6s8s8s8p+14',
  '3m3m6m7m6p7p8p3s3s3s7s7s7s+5m+14',
  '4m4m5m4p4p5p5p9p9p4s4s7s7s+5m+12',
  '3m3m7m7m8m2p2p5p5p5s5s7z7z+8m+12',
  '3m4m5m5m5m8m8m8m8p8p5s6s7s2m+21',
  '2m2m5m5m2p3p3p8p8p2s2s7s7s+2p+14',
  '3m7p7p8p8p7s7s8s8s1z1z3z3z+3m+12',
  '5m6m7m7m8m3p3p2s3s4s7s8s9s+9m+11',
  '2m3m3m4m4m5m6m6m2p4p2s3s4s+3p+14',
  '2m3m3m3m4m6m7m8m6p7p8p6s7s5s+12',
  '1m2m3m2s3s4s7s8s9s9s7z7z7z9s+11',
  '2m3m4m4p4p5p5p6p6p5s5s6s7s+5s+12',
  '3p3p4p4p4p5p5p2s3s4s6s7s8s+5p+22',
  '3m3m6m7m6p7p8p2s3s4s5s6s7s5m+21',
  '2m3m4m6m7m4p4p2s3s4s7s8s9s5m+12',
  '2m2m3m4m5m2p3p4p2s2s6s7s8s+2m+12',
  '1m2m3m4m5m6m9m9m2s3s4s6s7s5s+14',
  '1m1m1m2m3m2p3p4p6p7p8p7s8s6s+11',
  '5m6m5p6p7p6s7s8s9s9s1z1z1z+4m+13',
  '1m2m3m4m5m6m7m7m6p6p7p7p8p8p+22',
  '7m8m9m3p4p5p6p7p8p2s2s7s8s+9s+13',
  '3p4p6p7p8p1s2s3s4s5s6s8s8s2p+13',
  '3m3m4m5m6m6p7p2s3s4s5s6s7s8p+21',
  '2m3m4m7m8m6p6p7p8p9p5s6s7s+9m+12',
  '5m6m3p4p5p5p6p7p8p8p2s3s4s7m+12',
  '4m5m5m6m6m7m1p2p3p2s2s7s8s+6s+12',
  '2m3m4m7m8m9m4p5p9p9p2s3s4s3p+24',
  '5m5m6m6m7m7m2s3s4s7s8s2z2z9s+13',
  '2m3m3m4m4m2p3p4p5s5s6s7s8s2m+23',
  '2m3m4m6m7m8m5p6p2s2s3s4s5s7p+14',
  '2m7m8m9m5p6p7p8s8s8s1z1z1z+2m+12',
  '3m4m5m6m7m8m7p8p9p5s6s9s9s4s+23',
  '3m4m5m6m7m7m8m9m4s5s6s8s8s2m+24',
  '3m4m5m4p5p9p9p3s4s5s7s8s9s6p+21',
  '1m3m1p2p3p4p5p6p1s2s3s1z1z2m+22',
  '1m2m3m3m4m5m7m7m6p7p8p2z2z+2z+12',
  '5m6m6m7m7m8m4p5p6p7p5s6s7s+4p+21',
  '2m3m4m7m8m9m6p6p7p8p9p5s6s4s+11',
  '1m2m3m3m4m5m5m6m7m3p4p6p6p5p+21',
  '1m2m3m3m4m5m3p3p4p5p7p8p9p6p+23',
  '2m3m4m6m7m8m6p7p4s5s6s8s8s+8p+12',
  '3m3m5m6m7m4p5p1s2s3s2z2z2z+3p+12',
  '4m5m6m7m8m9m2p3p4p7p8p3s3s+9p+14',
  '3m4m5m7m8m4p5p6p7p8p9p5s5s+6m+13',
  '2m2m5m6m1p2p3p7p8p9p4s5s6s+7m+22',
  '8m8m7p7p8p8p9p1s2s3s7s8s9s9p+13',
  '4m4m7m7m8m8m2p2p8s8s2z5z5z+2z+13',
  '7m7m7m4p5p6p4s4s5s5s6s7s7s6s+21',
  '6m7m8m4p4p2s3s4s5s6s7s8s9s4s+13',
  '1p2p3p5p7p7p8p9p8s8s3z3z3z6p+13',
  '2m3m2p3p4p4p5p6p1s2s3s8s8s+1m+23',
  '2m3m4m6m7m8m8m1p2p3p6s7s8s+8m+12',
  '2m2m3m3m5m5m6m6m3s5s5s9s9s3s+14',
  '2m3m7m8m9m2p3p4p7p7p4s5s6s+1m+11',
  '3m4m5m3p3p4p4p5p6p6p7p8p9p+2p+23',
  '1m1m6m6m1p1p2p2p7s7s4z6z6z4z+12',
  '5m5m4p4p5p5p6p6p8p8p3s6s6s3s+23',
  '3m3m6p7p8p2s2s3s3s4s4s5s6s+1s+12',
  '2m2m4m4m4m6m7m3p3p3p3s4s5s+8m+21',
  '3m3m2p3p4p6p7p8p3s4s6s7s8s2s+12',
  '4m4m5m6m7m5p6p6p7p7p7p8p9p8p+23',
  '1m2m3m8m8m8m9p9p5s6s6z6z6z+7s+12',
  '7m8m9m1p2p3p2s3s3s4s5s6s6s+1s+11',
  '1m2m3m5m6m7m4p5p6p4s5s8s8s3s+14',
  '3m3m4m4m5m5m6m7m8m5p6p9s9s+4p+11',
  '4m5m6m1p2p2p3p3p6p7p8p5s5s1p+24',
  '2m3m4m7m8m3p3p4p5p6p6s7s8s+9m+13',
  '2m3m4m7m8m9m2p3p4p1s2s3s4s1s+11',
  '2m3m4m2p3p4p1s1s2s3s4s4s5s+3s+22',
  '7m8m8m9m9m2p3p4p4p5p6p5z5z+7m+13',
  '2m3m4m2p3p4p6p7p8p3s4s9s9s2s+11',
  '2m3m4m6m7m3p3p4p5p6p6s7s8s5m+14',
  '1m2m3m1p2p3p1s2s4s5s6s9s9s3s+13',
  '2m3m4m8m8m4p5p6p2s3s4s7s8s6s+13',
  '4m4m1p1p1s1s6s6s9s9s3z3z7z+7z+13',
  '5m5m6m6m5p5p4s4s5s5s3z4z4z3z+21',
  '5m5m5p6p6p7p7p8p3s5s7s7s7s+4s+23',
  '2m2m7m8m9m4p5p7p8p9p3s4s5s+3p+22',
  '4m5m6m2p3p3p4p5p2s3s3s3s4s+1p+23',
  '3m4m5m4p5p6p2s3s4s5s5s6s8s+7s+11',
  '4m4m4m3p4p5p6p7p4s4s7z7z7z+8p+24',
  '4m5m6m2p3p4p3s3s4s4s5s4z4z5s+22',
  '2m2m3p3p4p4p6p6p2s8s8s9s9s+2s+23',
  '2m5m5m6m6m7m7m9p9p4s4s5s5s+2m+14',
  '5m6m7m8m8m9p9p9p2s2s3s3s4s+4s+14',
  '1m2m3m4m5m1p1p5p6p7p5s6s7s6m+21',
  '2m3m4m5m6m7m8m9m7p8p9p4s4s+1m+11',
  '1m2m3m4m5m6m7m8m6p6p3s4s5s+9m+21',
  '6m7m8m2s3s3s4s4s6s7s8s9s9s+2s+12',
  '4m5m6m6p7p8p1s1s6s7s2z2z2z5s+12',
  '1m1m8m8m2p2p3p3p4p7p7p7z7z+4p+14',
  '1m2m3m5m6m8m8m4p5p6p7p8p9p+4m+12',
  '4m4m5p6p8p8p8p2s3s4s5s6s7s7p+11',
  '6m6m6m3p4p5p7p7p5s5s6s6s7s+4s+11',
  '3m3m5m5m7m7m1s1s6s6s1z3z3z1z+21',
  '4m5m6m3p5p6p6p5s5s5s6s6s6s+4p+13',
  '2m3m3s4s5s7s7s9s9s9s7z7z7z4m+21',
  '1m2m3m5m6m3p4p5p4s5s6s7s7s7m+23',
  '3m3m3m5m5m5p5p7p8p9p1s1s1s5m+21',
  '6m6m2p2p5p5p6p6p2s2s5z6z6z+5z+24',
  '5m6m7m2p3p1s2s3s4s5s6s8s8s1p+22',
  '5m6m7m7m8m9m5p6p7p7s7s7s8s9s+12',
  '2m3m4m6m7m5p6p7p1s2s3s8s8s8m+11',
  '6m7m7m8m8m9m3p4p5p2s2s6s7s5s+11',
  '2m3m4m5p6p7p2s3s4s5s6s9s9s1s+13',
  '4m5m6m6m7m8m2s3s6s6s7s8s9s4s+21',
  '1m1m2m2m3m3m4m5m9p9p4s5s6s+6m+22',
  '5m5m6m6m7m7m3p3p3p5p6p7p5s5s+14',
  '4m4m5m6m7m8m8m8m5p6p6s7s8s+7p+13',
  '7m7m2p3p3p4p5p7p8p9p5s6s7s1p+12',
  '2m3m4m9m9m3p4p5p7s7s8s8s9s9s+14',
  '4m5m6m7m8m9m1p1p5p6p4s5s6s+4p+11',
  '6m7m8m1p1p5p6p2s3s4s7z7z7z+4p+13',
  '2m2m7m8m9m7p8p1s2s3s5s6s7s9p+13',
  '3m4m5m7m7m8m8m8m3p5p6s7s8s4p+14',
  '8m8m8m5p6p7p3s4s4s5s6s8s8s2s+14',
  '6m7m8m4p4p6p7p8p4s4s4s6s7s+8s+13',
  '2m2m2p2p9p9p1s1s4s4s3z3z5z5z+12',
  '5m6m7m3p4p6p7p8p7s7s1z1z1z+2p+13',
  '2m3m4m3p3p7p8p9p3s4s5s5s6s7s+24',
  '7m8m3p4p5p6p6p3s3s3s5s5s5s6m+23',
  '5m6m7m5p6p1s2s3s3s4s5s7s7s4p+14',
  '2m2m5p5p6p6p7p2s3s4s6s7s8s7p+12',
  '1m2m3m7m8m9m3p3p3s4s4s5s5s+6s+21',
  '1m2m3m4m4m6p7p8p7s9s5z5z5z+8s+12',
  '3m3m4m8m8m6p6p2s2s4s4s7z7z+4m+24',
  '6m7m8m3p4p5p7p8p9p1s1s7s8s9s+22',
  '2m3m4m7m8m3p4p5p7p7p7s8s9s9m+13',
  '2m3m4m7m8m9m5p5p7p8p5s6s7s+6p+23',
  '5p6p7p8p1s2s3s4s5s6s7s8s9s5p+22',
  '5m6m7m4p5p5p5p6p6s7s7s8s8s+9s+11',
  '2m3m5m5m1p2p3p3p4p5p2s3s4s1m+11',
  '4m5m6m7m8m9m7p8p9p2z2z5z5z+2z+24',
  '1p1p5p7p7p3s3s5s5s7s7s6z6z5p+22',
  '3p3p4p4p5p5p6p7p7p7p8p7s8s+9s+22',
  '2m3m6p6p3s4s5s7s7s8s8s9s9s+1m+14',
  '4m5m6m8m8m4p5p5p6p7p1s2s3s+3p+13',
  '1m2m2m7m7m9p9p8s8s1z1z3z3z+1m+23',
  '3m4m5m2p3p3p4p4p5p7p8p5s5s+9p+24',
  '6m6m1p2p2p3p3p1s2s3s4s5s6s1p+24',
  '2m3m4m4m5m6m4p4p6p6p6p3s4s+2s+13',
  '7m8m9m2p3p4p1s2s3s4s5s9s9s6s+13',
  '4m5m6m8m8m5p6p7p8p9p5s6s7s+4p+13',
  '2m3m4m5m6m7m8p2s3s4s5s6s7s+8p+12',
  '1p1p3p3p7p7p8p8p3s3s9s9s4z+4z+14',
  '2m3m4m5m5m4p5p6p7s7s8s9s9s+8s+13',
  '6m6m2s3s4s4s5s6s7s7s8s8s9s+9s+11',
  '4m5m6m3p3p5p6p3s4s5s6s7s8s+7p+23',
  '2m3m3m4m4m5m8m8m5p6p4s5s6s+4p+14',
  '2m3m4m7m8m9m7p8p1s1s5s6s7s6p+23',
  '3m3m2p3p4p8p8p5s6s6s7s7s8s+8p+24',
  '5m6m7m2p2p2p7p7p4s5s6z6z6z6s+23',
  '2m3m4m6m7m2s3s4s6s6s7s8s9s+8m+14',
  '1m2m3m4m5m6m7m8m4p5p6p3s3s+9m+13',
  '6m7m8m9m9m6p7p3s4s5s6s7s8s+8p+12',
  '7m8m2p3p4p1s1s5s6s6s7s7s8s+9m+24',
  '4m5m3p4p5p5p6p7p8p8p8s8s8s3m+23',
  '9m9m4p5p6p7p7p8p8p9p7s8s9s+9p+22',
  '2m2m3m3m3p3p9p9p7s7s1z7z7z+1z+24',
  '4m5m6m5p6p7p2s2s3s4s5s7s8s+9s+23',
  '6m7m8m4p5p6p7p8p9p9p2s3s4s+3p+23',
  '2m3m4m5m6m7m7m8m2s2s5s6s7s9m+13',
  '3m4m5m8m8m4p5p7p8p9p4s5s6s+6p+12',
  '4m4m3p4p5p7p8p9p1s2s3s6s7s5s+12',
  '2m2m3m5m3p4p5p6p7p8p4s5s6s+4m+13',
  '7m7m4p4p5p5p6p6p7p8p9p6s8s7s+22',
  '2m2m4m5m6m2p3p4p3s4s5s6s7s+2s+13',
  '3m3m2p3p4p4p5p5p6p6p7p5s6s+4s+14',
  '3m4m4m4m5m6p7p8p3s4s5s6s7s8s+14',
  '1m2m3m2p3p4p1s2s3s6s7s4z4z8s+12',
  '1m2m3m5m6m7m1p1p3p4p5s6s7s+5p+11',
  '2m3m4m6m7m1p2p3p2s3s4s7s7s+8m+21',
  '1m3m1p2p3p6p7p8p1s2s3s7z7z+2m+14',
  '5m5m3p4p5p6p6p7p7p8p3s4s5s+5p+14',
  '3p4p6p7p8p1s1s3s4s5s5s6s7s2p+23',
  '6m7m2p3p4p5p6p7p3s3s5s6s7s5m+11',
  '2m3m4m6m7m6p7p8p6s7s8s9s9s5m+12',
  '7m8m9m7p8p1s1s2s3s4s7s8s9s9p+11',
  '2m2m3m4m5m2p2p6p7p8p3s4s5s+2m+24',
  '4m4m7m8m5p6p7p1s2s3s7s8s9s+9m+12',
  '3m4m5m2p4p3s4s5s6s6s5z5z5z+3p+13',
  '1m1m2m3m4m5m6m1p2p3p3p4p5p4m+11',
  '1p3p6p6p7p7p8p8p2s3s4s6s6s+2p+12',
  '6m7m1p2p3p4p5p6p8p8p7s8s9s8m+21',
  '1p2p3p4p5p6p8p9p5s6s7s4z4z+7p+22',
  '1m1m2m2m3m3m9m9m7p8p9p3s4s+2s+11',
  '3m4m5m8m8m8m4p5p5s5s6s7s8s+6p+23',
  '5m5m1p2p3p5p6p2s2s3s3s4s4s+7p+12',
  '7m7m1p2p3p5p6p7p3s4s6s7s8s+2s+13',
  '2m2m3m4m5m6m7m8m4s6s6s7s8s+5s+11',
  '5m6m9m9m5p6p7p1s2s3s6s7s8s+7m+23',
  '3m5m5p6p7p2s2s2s3s3s6s7s8s+4m+12',
  '2m2m1p2p3p4p5p6p6p7p8p2s3s1s+11',
  '2m3m4m7m8m3p4p5p6p6p4s5s6s+6m+11',
  '2m3m4m6m7m8m5p5p5p7p8p4s4s6p+14',
  '2p3p4p5p5p3s4s5s7s8s9s1z1z1z+21',
  '4m5m6m6m7m3p4p5p8p8p3s4s5s+5m+14',
  '3m4m5m6m7m6p7p8p1s2s3s9s9s+8m+13',
  '1m2m3m4p4p6p7p2s3s4s7s8s9s+8p+23',
  '1m2m3m6p7p8p8p7s7s8s8s9s9s+8p+22',
  '2m4m5m6m7m6p7p8p2s2s3s4s5s3m+21',
  '1m2m3m7m8m9m2p3p4p5p6p6s6s+1p+13',
  '4m5m2p3p4p2s2s4s5s6s8s8s8s6m+11',
  '5m6m2p2p6p7p7p8p8p9p1s2s3s4m+11',
  '5m5m3p4p5p2s3s3s4s4s6s7s8s+2s+22',
  '3m4m5m6m6m6p7p8p3s4s6s7s8s2s+14',
  '2m3m4m7m8m9m3p4p5p2s2s4s5s3s+24',
  '7m7m4p5p6p6p7p1s2s3s6s7s8s8p+21',
  '1m2m3m4m5m3p4p5p1s1s3s4s5s+3m+14',
  '4m5m6m7m8m9m4p5p5s6s7s7s7s+3p+11',
  '6m7m8m2p3p4p5p6p7p8p9p2s2s1p+11',
  '5m5m6m7m8m2p3p4p5s5s6s6s7s+4s+14',
  '2m3m4m5m6m9m9m3s4s5s7s8s9s4m+13',
  '5m6m6m7m7m8m9m9m3s4s6s7s8s2s+24',
  '3m4m5m7m7m1p2p3p4p5p6s7s8s+6p+21',
  '2m2m3m3m4m4m2p3p1s2s3s8s8s+4p+22',
  '3m3m4m5m6m3p4p5p7p8p2s3s4s+9p+12',
  '9m9m1p2p3p2s2s3s4s5s1z1z1z9m+21',
  '4m5m6m7m8m9m1s1s7s8s6z6z6z+9s+13',
  '6m6m6m1p1p1p4p4p4p8p8p6s7s8s+14',
  '3m4m6m7m7m8m8m9m6p7p8p7s7s+5m+11',
  '4m5m2p3p4p5p5p1s2s3s6s7s8s3m+23',
  '1p2p3p7p7p8p8p9p2z2z5z5z5z+9p+33',
  '2p3p4p5p6p7p1s1s3s4s6s7s8s2s+22',
  '2m3m4m6p7p8p1s2s8s8s3z3z3z3s+23',
  '1m1m7m7m9m9m4p4p1s1s8s8s2z2z+14',
  '8m8m2p3p5p6p6p7p7p8p5s6s7s4p+22',
  '2m2m7m7m5p5p9p9p7s3z3z7z7z+7s+12',
  '3p4p5p5p6p9p9p1s2s3s6s7s8s4p+22',
  '4m4m4p5p6p4s5s6s7s7s8s8s9s3s+21',
  '1m1m2m3m4m5m5m6m6m7m7m3z3z3z+14',
  '2m3m4m5m6m5p5p6p6p7p7p8p8p1m+21',
  '6m7m8m8m8m2p3p7p8p9p4s5s6s+1p+14',
  '3m3m7m8m9m1p2p3p4p5p4s5s6s+6p+14',
  '4m5m3p3p5p6p7p3s4s5s6s6s6s+3m+14',
  '2m2m2m4m5m6m3p4p5p4s5s7s7s+3s+22',
  '4m5m6m3p3p4p5p3s4s5s7s8s9s3p+14',
  '2m3m4m4m5m6m2p3p4p4p5p7p7p3p+22',
  '4m5m5m6m7m4p5p6p1s2s3s6s6s+3m+14',
  '2m2m3p4p5p7p8p9p2s3s4s5s6s+7s+14',
  '6m7m4p4p6p7p8p4s4s4s5s6s7s+8m+13',
  '2m3m4m1p2p3p5p6p2s3s4s2z2z7p+14',
  '4m5m4p5p6p8p8p4s5s6s7s8s9s+6m+23',
  '5m6m7m2p3p4p2s5s6s7s7z7z7z2s+12',
  '6m7m8m4p5p6p1s1s4s5s5s6s7s6s+11',
  '6m7m5p6p7p1s1s5s6s7s7s8s9s+8m+22',
  '3m4m5m2p3p4p8p8p7s8s9s5z5z+5z+12',
  '2m3m5m6m7m2p3p4p6p6p7p8p9p+1m+22',
  '2p3p3p3p6p6p6p9p9p9p6s7s8s+2p+13',
  '2m2m4m5m6m6m8m4p5p6p5s6s7s+7m+13',
  '2m2m1p2p3p7p8p3s4s5s5z5z5z+9p+22',
  '1m2m3m5m6m7m3p4p6s7s8s9s9s+5p+22',
  '4m4m5m5m6m3p4p5p5s5s6s7s8s+3m+23',
  '6m7m8m9m9m2p3p4p5p6p7p5z5z+5z+12',
  '1m2m3m4m4m6p7p8p2s3s4s6s7s+5s+24',
  '3m4m5m7m8m1p2p3p3s4s5s9s9s6m+21',
  '5m5m7m9m9m4p4p1s1s2s2s6s6s7m+11',
  '1m2m3m4p5p6p2s3s5s6s7s9s9s+1s+14',
  '2m3m4m6m7m8m3p4p7p7p4s5s6s5p+24',
  '4m5m6m8m8m3p4p1s2s3s3s4s5s+2p+23',
  '5m6m7m3p4p5p6p6p6p8p8p6s8s+7s+34',
  '5m6m7m8m9m1p2p3p2s3s4s8s8s+4m+11',
  '2m3m4m6m7m8m4p4p2s3s5s6s7s1s+23',
  '1m1m5m6m7m5p6p7p4s5s6z6z6z6s+21',
  '2m3m4m4m5m6m7m8m1p2p3p5p5p3m+12',
  '3m4m5m5m6m7m3p4p8p8p8p7s7s+2p+14',
  '2m2m4m5m6m5p6p7p1s2s3s6s7s+8s+22',
  '2m2m2m3m4m5m6m6m3p4p5s6s7s+5p+21',
  '2m2m6m6m8m8m7p7p8p2z2z6z6z+8p+12',
  '8m8m9m9m9m2p3p4p5p5p1s1s1s8m+22',
  '5m6m1p2p3p5p6p7p1s2s3s8s8s7m+11',
  '5m6m3p4p5p6p7p8p5s5s7s8s9s7m+13',
  '1m2m3m1p2p3p1s2s3s7s8s4z4z+9s+23',
  '5m5m8m8m1p1p4p4p3z3z4z4z5z+5z+13',
  '2m3m4m4p4p5p6p3s3s4s4s5s5s+7p+24',
  '5m5m3p4p4p5p6p7p8p9p3s4s5s+2p+11',
  '5m6m7m3p4p5p7p8p4s5s6s1z1z6p+23',
  '3m4m5m5p7p8p8p2s2s2s6s6s6s+6p+11',
  '3m4m5m6p6p6p7p8p2s3s7s8s9s+1s+13',
  '1m2m3m4m4m6m7m2p3p4p3s4s5s5m+21',
  '5m6m7m2p3p4p1s2s3s4s7z7z7z1s+14',
  '4p5p6p7p8p9p2s3s4s7s8s3z3z+9s+11',
  '1p1p3p3p3p4p5p5p6p6p7p8p9p+7p+12',
  '4m5m6m3p3p5p6p7p8p8p8p4s5s+6s+21',
  '1m2m3m5m6m7m6p6p1s2s3s5s6s+4s+11',
  '3m3m6m7m3p4p5p4s5s5s6s6s7s+5m+14',
  '5m5m5p5p6p6p7p1s2s3s5s6s7s4p+22',
  '3m4m5m7m8m5p5p2s2s3s3s4s4s+6m+11',
  '5m6m7m7m1p2p3p1s2s3s5s6s7s4m+23',
  '3m3m4p5p6p1s2s3s5s6s6s7s7s+8s+13',
  '1m2m3m6m7m5p6p7p3s4s5s3z3z8m+24',
  '5m5m5p6p7p2s3s4s4s5s6s7s8s+9s+21',
  '2m2m3m4m5m6p6p7p7p8p7s8s9s+8p+14',
  '1m2m3m6m6m3p4p5p6p7p3s4s5s8p+11',
  '3m3m9m9m3p3p4p4p7p6s6s1z1z+7p+14',
  '1m1m2m7p7p1s1s6s6s8s8s9s9s2m+13',
  '4m5m6m6m7m8m2p2p5p6p7p3s5s4s+13',
  '3m4m6m7m8m2p3p4p7p7p6s7s8s2m+12',
  '3m4m5m5m6m7m1p1p2p3p4p5p6p+4p+11',
  '1s1s1s2s2s2s3s5s6s6s7s7s8s+2s+12',
  '2m2m4m4m5m5m6m6m6p6p6s6s4z+4z+13',
  '1m2m3m5m6m4p5p6p4s5s6s7s7s+4m+23',
  '4m5m2p3p4p6p7p8p6s6s4z4z4z+3m+14',
  '3m3m2p3p4p6p7p8p4s5s5s6s6s7s+13',
  '3m4m5m5m6m7m6p7p8p4s5s9s9s3s+14',
  '3m3m4s4s6s6s8s8s9s9s3z7z7z+3z+12',
  '2m3m4m5m2p3p4p2s2s3s3s4s4s2m+14',
  '4m5m6m5p5p6p6p7p6s6s7s7s7s7p+22',
  '5m5m7m8m9m3p4p6p7p8p4s5s6s+2p+11',
  '2m3m4m2p3p4p8p8p3s3s4s4s5s+2s+11',
  '1m2m3m6p6p3s3s4s4s5s5s8s8s+8s+24',
  '1m1m2m2m3m3m5m6m7m8m8m5s6s7s+13',
  '2m3m4m8m8m4p4p5p5p6p7s8s9s+3p+12',
  '4m5m1p1p7p8p9p4s5s6s7s8s9s+3m+21',
  '7m8m9m3p3p7p8p9p2s3s3s4s5s4s+13',
  '4m5m6m7m8m9m4s5s6s6s7s8s8s+8s+13',
  '6m6m6m7p7p6s6s6s8s8s8s3z3z+3z+22',
  '7m8m9m1p2p3p7p8p9p5s5s8s9s7s+23',
  '2m3m4m6m7m8m3p4p5p9p9p7s8s+9s+22',
  '1m1m1p2p3p3p4p5p2s3s4s7s8s+6s+23',
  '2m2m7m8m9m7p8p3s4s5s5s6s7s+6p+23',
  '2m3m4m5m6m7m7p8p3s4s5s1z1z+9p+23',
  '4m5m6m6m2p3p4p6p7p8p3s4s5s+6m+23',
  '2m3m4m5m6m7m5p5p6p7p5s6s7s+5p+14',
  '7m8m9m2p3p4p5p5p7p8p9p3s4s+5s+23',
  '4m5m2p2p6p7p8p5s5s5s6s7s8s+3m+21',
  '1m2m3m6m7m8m5p5p3s3s4s5s5s+4s+22',
  '1m1m6m6m2p2p9p3s3s8s8s6z6z+9p+21',
  '6p6p1s2s3s6s7s9s9s9s5z5z5z5s+11',
  '3m4m5m7p8p9p9p3s4s5s6s7s8s+6p+21',
  '5m6m7m2p3p4p6p6p2s3s7s8s9s4s+13',
  '3m3m5m5m3p3p6p6p8p8p4s4s6z+6z+14',
  '3m4m5m2p3p4p5p6p6p6p7p8s8s+8s+21',
  '7m8m9m4p6p4s4s5s5s6s6s2z2z5p+22',
  '7m8m9m4p5p6p7p8p9p5s5s7s9s+8s+14',
  '3m4m4m4m5m6m7m8m3p4p6p7p8p+5p+24',
  '3m4m8m8m8m4p5p6p3z3z3z4z4z2m+13',
  '3m4m5m7m8m9m2s3s4s4s5s7s7s+3s+23',
  '2m2m5m5m9m9m4p5s5s7s7s7z7z4p+24',
  '2p3p3p4p4p5p2s2s3s4s5s7s7s+7s+24',
  '3m3m4m5m6m7m8m9m3p4p5p5p6p+7p+12',
  '4m4m5m6m3p4p5p1s2s3s5s6s7s+7m+11',
  '2m3m4m9m9m2p3p4p1s2s3s4s5s+3s+13',
  '4m5m6m9m9m3p4p5p3s4s6s7s8s+2s+12',
  '5m6m7m1p1p3p4p5p3s4s4z4z4z2s+24',
  '4m4m3p4p5p5p6p7p8p9p2s3s4s+7p+23',
  '6m7m1p2p3p4p4p4p5p6p5s6s7s5m+22',
  '6m8m8m8m7p8p9p2s3s4s6z6z6z+7m+21',
  '6m7m8m3p4p5p6p7p8p3s4s4z4z+2s+11',
  '2m3m4m5m6m7m8m9m4p5p6p4s4s+1m+22',
  '4m4m6m6m8m8m2p2p4p4p8p5s5s+8p+24',
  '4m5m6m7m8m9m4p4p6p7p3s4s5s8p+12',
  '5m5m5m6m7m4p5p5p6p6p7s8s9s+7p+23',
  '4m4m4p5p6p7p8p9p2s3s6s7s8s+1s+11',
  '1m1m2m2m3m3m6p7p7p8p9p6s6s+8p+21',
  '4m5m6m8m8m5p6p7p2s3s4s6s7s+8s+24',
  '4m5m6m9m9m5p5p7s8s9s6z6z6z+5p+12',
  '2m2m3m4m5m6m7m8m4p5p6p2s4s3s+21',
  '1m2m3m6m7m8m2p2p5p6p7p2s3s4s+14',
  '5m6m6m6m7m4p4p4p6p7p6s7s8s+8p+23',
  '3m5m2p2p4p5p6p6p7p8p3s4s5s+4m+11',
  '5m5m6p7p8p2s3s3s4s4s5s7s8s9s+14',
  '2m3m4m3p4p2s3s3s4s4s5s6s6s+2p+21',
  '3m3m3m5m6m7m8m5p6p7p5s6s7s+4m+13',
  '2p3p3p4p4p2s2s2s3s4s4s5s6s2p+12',
  '2m2m2m3p4p5p7s9s2z2z2z7z7z8s+12',
  '5m6m7m1p1p2s3s3s4s5s6s7s8s+1s+21',
  '5m5m3p4p5p3s4s6s7s8s2z2z2z2s+12',
  '2m3m4m6m7m8m2p3p4p5p5p7p8p+6p+12',
  '2m2m9m9m9m8p8p8p2s2s2s7s7s7s+14',
  '2m2m2m3m4m5m3p4p4s5s6s8s8s2p+24',
  '3m4m3p4p5p7p8p9p3s4s5s7s7s+2m+13',
  '3m4m5m5m3p4p5p2s3s4s5z5z5z+2m+11',
  '6m7m8m4p4p5p6p7p5s6s6s7s8s+7s+11',
  '1p2p3p4p5p8p8p1s2s3s6s7s8s+3p+14',
  '7m7m5p6p7p2s2s3s4s5s6s7s8s+7m+23',
  '2m2m3m3m4m4m2p2p3s3s3s4s5s+6s+24',
  '3m4m7m8m9m1p1p4p5p6p6s7s8s2m+11',
  '3m4m5m6m7m6p7p8p3s3s5s6s7s2m+11',
  '2m3m4m7m7m8m8m9m2s3s4s8s8s+6m+12',
  '5m6m1p2p3p5p6p7p2s3s4s5s5s+7m+13',
  '1m2m3m4m5m3p4p5p2s3s4s1z1z6m+24',
  '4m5m6m5p7p3s4s5s5s6s7s7s7s+6p+14',
  '3m4m5m6p7p8p2s2s3s4s4s5z5z3s+11',
  '4m5m1p2p3p1s1s2s3s4s5s6s7s+3m+14',
  '3m3m7m9m4s4s4s6s7s8s7z7z7z+8m+21',
  '3p3p7p8p9p2s3s5s6s7s7s8s9s4s+11',
  '1m2m3m6m6m1p2p2p3p3p4p7s8s+9s+14',
  '2m2m3m4m5m5m6m2p3p4p7p8p9p7m+22',
  '3m4m5m6m6m4p5p6p7p8p9p3s4s5s+11',
  '5m6m7m1p2p3p7p7p1s2s2s3s3s1s+11',
  '1m1m3m4m5m3p4p5p5p6p7p5s6s+7s+11',
  '4m5m9m9m1s1s2s2s3s3s2z2z2z6m+11',
  '4p5p6p3s3s3s4s5s6s3z3z3z4z+4z+23',
  '5p6p6p7p7p8p8p5s6s7s5z5z5z5p+22',
  '3m3m4p5p6p4s4s4s4s5s5s6s7s3s+14',
  '2m3m3m4m4m2p3p4p3s3s5s6s7s5m+22',
  '3m4m6m6m3p4p5p1s2s3s5s6s7s+5m+23',
  '2p3p7p8p9p6s7s7s8s8s9s4z4z1p+13',
  '7m8m9m2p3p4p6p7p8p9p9p3s4s+5s+22',
  '2m3m4m5m6m7m1p1p4p5p2s3s4s6p+11',
  '2m2m8m8m9m9m3p3p5s5s1z1z7z+7z+12',
  '1m2m3m4m5m6m9m9m5p6p6s7s8s+7p+13',
  '5m7m5p5p6p7p8p2s3s4s5s6s7s+6m+21',
  '5m6m2p3p3p4p4p5p4s5s6s7s7s4m+14',
  '3m4m5m7m8m9m9p9p1s2s3s6s7s+5s+23',
  '2m3m4m2p3p6p7p8p2s3s4s6s6s+1p+24',
  '2m2m3m4m5m4p5p6p9s9s9s2z2z+2z+24',
  '6m6m8m8m1p1p2p2p8s8s9s9s5z+5z+23',
  '4m5m6m7m5p5p5p2s2s2s3z3z3z+4m+22',
  '5m6m8m8m1s2s3s4s5s6s7s8s9s+7m+14',
  '1m2m3m6m7m8m4p5p6p4s5s9s9s+6s+12',
  '2m2m2m5m6m3p4p5p5p6p7p2s2s+7m+21',
  '6m6m9m9m1s1s3s3s5s5s3z3z4z+4z+22',
  '6m8m2p3p4p6p7p8p3s3s6s7s8s+7m+11',
  '1m1m5m6m6p7p7p8p8p9p4s5s6s4m+22',
  '3p4p5p7p7p1s2s3s4s5s6s7s8s9s+12',
  '2m3m4m2p3p4p5p6p7p8p3s4s5s8p+23',
  '5m5m7m8m9m6p7p2s3s4s6s7s8s+5p+14',
  '4m5m1p2p3p3p4p5p7p8p9p1s1s6m+13',
  '6m6m4p5p6p4s5s7s8s9s7z7z7z+3s+21',
  '5m5m6m6m1p1p9p9p2s2s7s7s4z4z+13',
  '6m6m7m7m8m8m2p4p9p9p4s5s6s+3p+21',
  '4m5m7m8m9m9m9m4p5p6p4s5s6s+3m+23',
  '1m1m3m4m5m1p2p3p3p4p4s5s6s+5p+11',
  '2m3m7m7m2p3p4p5p5p5p4s5s6s4m+23',
  '2m2m4m5m6p7p7p8p8p9p3s4s5s+6m+23',
  '5m5m5m6m7m8m2p2p3p4p5p6s7s5s+23',
  '5m6m7m2s3s5s5s7s8s9s6z6z6z+4s+21',
  '5m5m3p4p5p5p6p4s5s6s8s8s8s4p+12',
  '6m7m3p4p5p6p7p8p1s1s5s6s7s5m+23',
  '3m4m5m2p3p4p5p5p7p8p3s4s5s+6p+11',
  '2p3p6p7p8p1s1s4s5s6s7s8s9s+1p+13',
  '1m1m5m5m6m6m1p1p4s4s9s2z2z+9s+13',
  '4m5m6m3p3p4s5s6s8s9s5z5z5z+7s+12',
  '3m4m5m2p3p5p6p7p5s5s6s7s8s+1p+12',
  '3m3m1p3p4p5p6p7p8p9p1s2s3s+2p+13',
  '3m4m5m5p5p2s3s4s5s6s7s8s9s+1s+23',
  '4m5m6m9m9m5p6p2s3s4s6s7s8s4p+11',
  '5m5m6m6m7m3p4p5p5p6p7p7s7s+7m+22',
  '6m7m8m4p5p6p2s3s5s5s6s7s8s+1s+13',
  '1m1m5p6p7p1s2s3s6s7s7s8s9s+5s+13',
  '6m7m8m7p8p9p3s3s5s5s6s7s7s+6s+13',
  '3m3m4m5m6m1p2p3p4p5p6p5s6s+7s+21',
  '4m5m6m7m7m1p2p3p6p7p8p3s4s5s+13',
  '6m6m2p3p3p4p5p7p8p9p6s7s8s+1p+22',
  '1m2m3m2p2p6p7p8p2s3s6z6z6z+1s+11',
  '4m5m6m7m7m7p8p9p2s3s4s4s5s6s+13',
  '6m7m8m3p3p6p7p1s2s3s7s8s9s+8p+13',
  '1m1m3m4m5m2p3p3s4s5s7s8s9s4p+21',
  '4m4m4m6m6m6m7m7m8m9m2z2z2z5m+11',
  '1m2m3m4m5m6m7m7m6p7p1s2s3s+8p+13',
  '3m4m5m5m5m7m7m2p3p4p6s7s8s+2m+13',
  '2m2m2m3m4m5m4p5p7p7p2s3s4s+6p+12',
  '7m8m9m1p2p3p3p4p6p7p8p9s9s+2p+21',
  '2m3m4m5m6m7m4p5p5s5s7s8s9s+6p+13',
  '5m6m7m5p6p7p7p2s3s4s6s7s8s+4p+12',
  '5m6m7m5p6p6p7p8p4s5s6s9s9s7p+11',
  '1m1m4m5m6p7p8p5s6s7s7z7z7z+6m+12',
  '5p5p7p7p4s4s5s5s6s6s3z3z7z7z+23',
  '1m2m3m4m4m6m7m7m8m9m5p6p7p8m+11',
  '1m2m3m4m5m6m5p6p2s2s5s6s7s+7p+12',
  '6m7m1p2p2p3p3p4p5p6p7p5s5s+8m+11',
  '2m2m2m6m6m6m8m8m4p5p6p5s6s+7s+21',
  '7m7m5p5p3s3s6s6s7s7s3z4z4z+3z+22',
  '7m8m9m5p6p1s2s3s4s5s6s7s7s4p+21',
  '2m4m7m7m4p5p6p2s3s4s4s5s6s3m+11',
  '1m2m3m3m4m5m2p3p1s1s3s4s5s4p+14',
  '2m3m4m4m6m3p3p4p4p4p6p7p8p5m+14',
  '1p1p3p3p4p4p5p5p6p7p7s8s9s8p+11',
  '3m4m6m7m8m5p5p6p7p8p1s2s3s2m+22',
  '3p4p5p7p7p8p8p9p1s2s3s8s8s+9p+11',
  '2m3m4m6m6m6m7m2s3s4s5s6s7s5m+24',
  '4m4m4p5p7p8p9p6s7s7s8s8s9s+3p+22',
  '3m4m5m8m8m2p3p4p6p7p7p8p8p+9p+24',
  '1p2p6p6p7p8p9p4s4s5s5s6s6s+3p+13',
  '4m5m6m1p2p3p4p5p6p7p8p7s7s+3p+14',
  '2m3m7p8p9p5s6s6s7s7s8s3z3z+1m+12',
  '4m5m6m1p2p3p3p4p5p6p7p3s3s+8p+13',
  '2m3m4m7p7p7p2s2s2s3s3s4s6s+5s+21',
  '2m2m1p2p3p5p6p3s4s5s7s8s9s4p+12',
  '3m3m3p3p3p4p5p6p4s5s6s7s8s6s+13',
  '4m5m6m6m7m2p3p4p7p8p9p5s5s8m+12',
  '1m2m3m6m7m7m8m9m4p5p6p9s9s+5m+12',
  '2m3m3m4m4m5m5m6m7m2p3p5p5p1p+14',
  '6m7m8m9m9m2p3p4p7p8p9p7s8s9s+14',
  '3m4m5m6p7p8p1s1s3s4s5s7s8s9s+14',
  '2m2m4p5p6p7p8p9p4s5s6s6s7s+8s+13',
  '1m2m3m5m6m8m8m1p2p3p3p4p5p4m+14',
  '1m2m3m6m7m8m1p2p3p3s3s7s8s+9s+21',
  '3m4m5m7m8m9m3p4p5p6p7p8s8s2p+14',
  '2m3m4m3p4p5p7p8p2s2s7s8s9s6p+11',
  '3m4m5m1p2p3p7p7p1s2s3s4s5s3s+13',
  '3s3s5s5s6s7s7s8s8s2z2z3z3z+6s+12',
  '3m3m3m7m8m9m7p8p9p3s3s8s9s7s+12',
  '7m7m4p5p5p6p6p7p7p9p9p8s8s4p+23',
  '4m4m5m6m7m2p3p5p6p7p5s6s7s+1p+12',
  '1m2m3m4m4m2p3p4p4p5p6p3s4s5s+21',
  '4m5m6m7p8p9p1s1s3s4s5s7s8s+6s+24',
  '4m5m6m5p5p7p8p2s3s4s4s5s6s9p+24',
  '2m3m7m8m9m7p8p9p4s5s6s7s7s+1m+13',
  '2m3m4m5m6m7m2p3p4p6p6p2s3s+4s+12',
  '3m4m5m5m5m4p6p2s3s4s6s7s8s+5p+11',
  '1m2m3m9m9m4p5p6p7p8p5s6s7s3p+22',
  '4m5m6m1p2p3p4s5s6s6s7s8s9s3s+24',
  '3m4m5m8m3p4p5p1s2s3s6z6z6z+8m+23',
  '6m6m6m8p8p2s3s4s5s6s6z6z6z7s+21',
  '3m4m7m8m9m2p3p4p5p6p7p2s2s+5m+13',
  '5m6m7m6p7p1s2s3s5s6s7s9s9s5p+11',
  '2m3m1p1p2p3p4p1s2s3s5s6s7s+1m+14',
  '2m3m4p4p6p7p8p3s4s4s5s5s6s+4m+21',
  '7m8m9m1p2p3p4p5p7p8p9p4s4s6p+22',
  '3m3m4m5m6m5p6p7p6s7s7s8s8s+9s+23',
  '5p5p6p7p7p1s2s3s6s7s8s8s8s6p+12',
  '3m4m5m6m7m8m2p3p4s5s6s9s9s+1p+13',
  '2m3m4m2p3p4p2s3s3s3s4s5s6s1s+11',
  '1m1m5m8p8p2s2s7s7s1z1z3z3z+5m+11',
  '2m3m4m6m7m8m3p4p5p7p8p1s1s9p+11',
  '3m4m5m1p1p6p7p2s3s4s1z1z1z8p+12',
  '5m6m1p2p3p5p6p7p9p9p3s4s5s+7m+12',
  '3m5m6m6m3p4p5p6p7p8p3s4s5s+4m+13',
  '4m5m7m8m9m3p4p4p4p5p5p6p7p6m+11',
  '2m3m4m5m6m1p2p3p7p7p3s4s5s+1m+12',
  '9m6p6p9p9p4s4s7s7s9s9s1z1z9m+12',
  '2m2m2m4p5p5p6p6p2s2s6z6z6z4p+21',
  '3m4m4m5m5m2p3p4p1s1s5s6s7s+6m+21',
  '6m6m7m7m7m7p7p7p3s4s5s2z2z6m+12',
  '7m8m9m7p8p9p1s2s3s8s9s3z3z+7s+24',
  '1m2m3m5m6m7m8p8p4s5s6s6s7s+5s+23',
  '6m7m8m2p3p3p4p5p2s2s6s7s8s4p+21',
  '5m5m7m7m8m2p2p7p7p8p8p6s6s+8m+12',
  '5m5m6m7m8m2p2p5p6p7p5s6s7s+5m+11',
  '1m2m3m3m3m4m5m7m8m9m6z6z6z3m+23',
  '5m5m5m6m7m2p3p5p6p7p5s6s7s+1p+23',
  '2m3m4m8m8m3p4p5p8p8p8p5s6s7s+12',
  '3m4m4m4m5m6p7p8p3s4s4s5s6s+2s+23',
  '3m4m6m6m7p7p7p5s6s6s7s7s8s5m+13',
  '4m4m6m7m1s2s2s3s3s4s7s8s9s+5m+13',
  '1m1m6m6m1p1p8p9p9p9s9s5z5z+8p+22',
  '1m2m3m7m8m9m4p4p5p5p6p2s2s+3p+14',
  '4m5m6m1p2p3p7p8p1s2s3s5s5s9p+12',
  '6m6m7m8m8m7p7p5s5s7s7s4z4z+7m+14',
  '2m3m4m6m8m1s1s5s6s7s5z5z5z7m+11',
  '4m5m6m6m7m8m6p7p3s4s5s9s9s8p+11',
  '4m5m5m7m7m9m9m7p7p6s6s7s7s4m+13',
  '8m8m1p2p3p3p4p5p7p8p9p3s4s2s+14',
  '5m6m8m8m2p3p4p3s4s5s7s8s9s4m+12',
  '2m3m4m5m5m7m8m9m4p5p6p2s3s+4s+12',
  '2p2p7p7p6s6s8s8s1z1z5z6z6z5z+23',
  '2m2m5m5m9p9p5s5s9s9s5z5z7z+7z+12',
  '4m4m5m6m7m2p3p4p4p5p2s3s4s6p+13',
  '2m2m3m5m3p4p5p7s8s9s7z7z7z+4m+12',
  '1p1p4p4p7p7p8p8p9p9p3s3s9s+9s+11',
  '3m4m5m4p5p6p1s1s2s2s3s4z4z+3s+22',
  '2m2m5m6m7m3p4p6p7p8p3s4s5s+2p+21',
  '1m2m3m4m5m6m6p6p3s4s2z2z2z2s+12',
  '1m2m3m3p4p5p5p7p8p9p2s3s4s2p+23',
  '3m3m5m6m7m1p2p3p5p5p6p7p7p6p+12',
  '4m5m6m4p4p4p6p6p4s5s7s7s7s+6s+12',
  '5m5m5m6m4p5p6p2s3s4s6s7s8s4m+13',
  '2m3m4m4m6m3p4p5p4s4s4s7s7s+5m+22',
  '5m6m3p3p5p6p7p1s2s3s4s5s6s7m+14',
  '1m1m4m4m6m6m8m8m9p9p5z7z7z+5z+12',
  '2m2m3m3m4m4m5p6p2s3s4s1z1z4p+21',
  '2m3m4m8m8m8m3p4p4p4p2s3s4s5p+11',
  '3m4m4p4p8p8p8p2s3s4s5s6s7s+5m+12',
  '3m3m2p3p4p4p6p6p7p8p4s5s6s+5p+24',
  '2m3m4m1p1p2p3p4p3s4s6s7s8s+5s+13',
  '1m1m8m8m2p2p5p5p8s2z2z6z6z8s+14',
  '4m4m4m6p6p3s3s3s6s6s7s7s8s5s+14',
  '2m3m4m6m7m8m2p3p4p4p4p6p7p5p+14',
  '5m6m7m2p3p4p3s4s6s6s7s7s7s5s+24',
  '3m4m5m7m8m3p4p4p4p5p5s6s7s+6m+11',
  '3m3m4m5m5m6p6p4s5s6s7s8s9s+4m+23',
  '3m4m6m6m6m5p5p6p7p8p3s4s5s5m+21',
  '1m2m3m6m7m7m8m9m2p3p4p1s1s+5m+12',
  '4m5m6m1p1p2p2p3p3p4s5s9s9s+3s+22',
  '4m5m6m2p3p4p6p6p5s6s7s7s7s+4s+14',
  '7m7m2p3p4p6p7p8p5s5s6s6s7s+7s+23',
  '2m3m4m1p2p3p1s2s3s5s6s7s7s+4s+13',
  '5m5m5m7m2p3p4p2s2s2s4s5s6s+7m+14',
  '4p4p5p5p6p1s1s3s4s5s5s6s7s+6p+14',
  '3m4m5m2p3p4p5p5p7p8p6s7s8s+9p+13',
  '4m5m6m6m7m8m6p7p3s3s4s5s6s5p+14',
  '3m4m5m1p2p3p7p8p9p2s3s9s9s+1s+14',
  '9m9m5p5p6p6p2s2s3z3z6z7z7z6z+13',
  '1m1m2m3m4m6m7m8m6p8p6s7s8s7p+12',
  '5m5m5m6m7m8m4p5p6p7p5s6s7s+7p+21',
  '5m6m6p7p8p3s4s5s6s7s8s4z4z+4m+22',
  '4m5m6m2p3p4p5p6p4s5s6s8s8s+1p+23',
  '2m2m4m5m6m6m7m8m4p5p1s2s3s+3p+13',
  '2m3m4m5p6p7p7s8s8s9s9s4z4z+7s+13',
  '1m2m3m3m4m5m7p8p9p1s1s6s7s+5s+13',
  '5m5m6m6m7m7m4p5p8p8p7s8s9s3p+14',
  '2m3m4m5m6m7m4p5p6p7p8p3s3s+6p+24',
  '5m6m7m7m7m7m5p6p4s4s4s5s6s7p+12',
  '6m6m7m8m9m3s3s4s4s5s5s6s7s8s+14',
  '6m7m8m3p4p5p6p7p8p5s6s2z2z4s+11',
  '2m3m4m8m8m4p5p3s4s5s5s6s7s6p+24',
  '1m2m3m4p5p5p6p6p7p9p9p3s4s2s+21',
  '6m7m8m2p3p4p4p5p6p4s5s6s7s7s+23',
  '2m3m4m5s6s6s6s8s8s8s5z5z5z+7s+22',
  '2m3m3m4m4m4p4p5p5p5p6p6p6p+5m+13',
  '2m2m3m4m5m7p8p3s4s5s6s7s8s+9p+12',
  '1m2m3m4m5m6m2p2p5p6p1s2s3s+4p+14',
  '1m2m3m4m4m7m8m5p6p7p5s6s7s9m+23',
  '4m6m7m8m6p6p6p2s3s4s6s7s8s+4m+23',
  '7m8m8m8m3p4p5p6p7p8p6s7s8s+7m+22',
  '7m8m9m1p1p8p9p3s4s5s7s8s9s+7p+24',
  '2m3m3m4m5m6p6p6p7p8p4s5s6s+1m+23',
  '2m2m3p4p5p6p7p4s4s4s6s7s8s+8p+13',
  '1m1m7m8m9m4p5p3s4s5s7s8s9s6p+22',
  '3m4m5m7m8m9m5p6p7p2s3s7s7s+1s+11',
  '4m5m5m6m6m2p3p4p5p6p7p9p9p+7m+14',
  '1m1m4m5m7p8p9p3s4s5s6s7s8s+6m+14',
  '2m2m8m8m8m4p5p1s1s2s2s3s3s+6p+22',
  '7m8m9m7p7p2s3s4s6s7s7s8s8s+6s+21',
  '5m5m5m6m7m8m3p3p5p6p3s4s5s+7p+13',
  '2m2m6m7m8m4s5s6s7s7s7s3z3z3z+23',
  '1m2m3m3m4m5p5p7p8p9p3s4s5s+2m+12',
  '4m4m1p2p3p5p6p1s2s3s6s7s8s+4p+12',
  '2m3m3m4m4m6m6m3p3p7p7p8p8p+2m+13',
  '6m7m8m4p4p5p5p6p6p8p8p3s4s5s+12',
  '3m4m5m4p5p6p7p7p8p8p8p4s6s5s+24',
  '5m5m6m7m8m2p3p4p4p5p3s4s5s+6p+21',
  '2m2m3m4m4m5m5m8m8m5p5p6p6p+3m+14',
  '3m4m5m7m7m3p4p6p7p8p3s4s5s5p+21',
  '5m5m7m8m4p5p6p2s3s3s4s4s5s+9m+14',
  '4m5m6m4p5p5p6p6p3s4s5s7s7s4p+12',
  '3m4m5m7m8m2p2p6p7p8p1s2s3s+9m+24',
  '3p4p5p1s1s2s3s3s4s4s7s8s9s+5s+12',
  '6m7m8m5p6p7p8p8p8p2s3s8s8s+4s+12',
  '4m5m7m8m9m2p2p7p8p9p3s4s5s3m+13',
  '6m7m8m2s3s4s5s6s7s7s8s9s9s+6s+21',
  '6m6m7p8p9p2s3s3s4s4s7s8s9s2s+21',
  '2m3m1p2p3p5p5p1s2s3s3s4s5s+4m+11',
  '4m5m3p4p5p3s4s5s5s5s7s8s9s+6m+14',
  '5m5m9m9m6p6p9p4s4s7s7s4z4z9p+12',
  '3m4m5m3s4s5s5s6s6s6s4z4z4z5s+24',
  '4m5m6m6m7m8m3p3p3p6p7p2s2s+8p+13',
  '5m6m7m2p3p4p5p5p7p8p9p6s7s+5s+11',
  '3m4m5m6m7m7m8m9m4s4s6s7s8s+5m+11',
  '2m3m8m8m7p8p9p1s2s3s5s6s7s+1m+21',
  '2m3m4m2p2p3p4p5p6p8p3s4s5s+7p+24',
  '4m2p2p2p3p4p5p4s4s5s5s6s6s4m+23',
  '6m6m7p8p5s5s5s2z2z2z6z6z6z9p+23',
  '1m1m4p5p7p8p9p1s2s3s5z5z5z6p+13',
  '2m3m4m5m6m7m1p2p3p2s3s8s8s+1s+11',
  '7m8m2p2p3p4p5p4s5s6s6s7s8s+6m+22',
  '3m4m5m6m6m5p5p6p6p7p4s5s6s+4p+13',
  '4m4m6m7m8m2p3p4p7p8p6s7s8s+9p+14',
  '2m2m2m3m4m5m4p5p5p5p5p6s6s+6p+24',
  '1m2m3m3m4m5m6m7m8m2s3s5s5s+4s+13',
  '5m6m6m7m8m1s1s2s3s4s6s7s8s4m+14',
  '4m5m6m7m8m9m4p5p1s2s3s4s4s6p+11',
  '7m8m9m2p3p4p7p8p9p7s9s6z6z+8s+13',
  '3m4m4m5m5m6m6p7p3s3s5s6s7s5p+22',
  '2m3m6m6m2p3p4p5p6p7p3s4s5s4m+21',
  '3m4m5m6m7m2p3p4p4s5s6s7s7s8m+22',
  '4m4m5m6m7m4p5p6p7p8p9p6s7s5s+34',
  '4m5m6m1p2p3p2s3s4s4s7z7z7z+1s+21',
  '1p2p3p4p5p5s6s7s5z5z7z7z7z+3p+14',
  '4m5m7m7m3p4p5p6p7p8p2s3s4s+3m+24',
  '4m5m6m7m7m7m8m2s3s4s7s8s9s9m+12',
  '1m2m3m4m4m1p2p3p1s2s4s5s6s3s+11',
  '4m4m9m9m2p2p4s4s5s5s1z3z3z+1z+22',
  '3p4p5p6p6p7p8p9p2s3s4s5s6s1s+14',
  '8m8m8m2p2p2p5p6p7p5s5s4z4z4z+14',
  '1m1m1p2p3p7p8p4s5s6s7s8s9s+6p+11',
  '6m6m7m8m9m2p3p4p7p8p9p3s4s5s+11',
  '1p2p3p3p4p4p5p5p6p5s5s6s7s+8s+11',
  '4m5m6m8m8m3p4p2s3s4s5z5z5z2p+21',
  '1m2m3m5m5m5p6p2s3s4s6s7s8s+4p+22',
  '5m6m7m2p2p3p4p4p5p5p2s3s4s3p+11',
  '1m2m3m4m4m7m8m3p4p5p7p8p9p9m+21',
  '5m6m7m8m8m6p7p8p6s7s7z7z7z5s+23',
  '2m3m4m6m6m4p5p1z1z1z4z4z4z+6p+24',
  '5m6m7m8m8m3p4p3s4s5s7z7z7z+5p+11',
  '2m3m4m6m7m8m2s3s4s5s5s6z6z+6z+14',
  '4m4m1p1p7p7p2s2s4s4s5s7z7z+5s+24',
  '2m3m3m4m4m6m7m8m6p6p5s6s7s2m+12',
  '8m8m1p2p3p4p5p6p7p8p9p4s5s6s+11',
  '5m5m5m3p3p4p4p2s3s4s5s6s7s+4p+11',
  '1m2m2m7m7m6p6p1s1s3z3z7z7z+1m+22',
  '1m2m3m1p2p3p4p5p7p8p9p2s2s+6p+11',
  '4m4m3p4p4p5p6p8s8s8s1z1z1z2p+14',
  '3m4m5m5m6m7m6p7p1s1s2s3s4s+8p+21',
  '1m2m3m5m6m7m5p6p3s3s5s6s7s7p+24',
  '2m2m3m3m4m5m5m4p4p6p6p3s3s+4m+21',
  '3m4m5m7m7m2p2p5p6p7p4s5s6s2p+11',
  '3m4m3p4p5p6p7p8p2s3s4s8s8s+2m+21',
  '7m7m8m8m9m4p4p5p6p7p6s7s8s+9m+23',
  '4p5p1s2s3s4s5s6s7s8s9s3z3z6p+13',
  '3p3p7p7p8p8p9p9p4s6s7s8s9s5s+14',
  '2m3m4m8m8m3p3p4s4s4s7s7s7s+8m+23',
  '3m4m5m7m8m9m9m4p5p6p7s8s9s+6m+14',
  '2m3m4m6m7m8m5p6p6s7s8s1z1z+4p+23',
  '4m4m7m9p9p3s3s5s5s9s9s1z1z+7m+13',
  '2m2m3m4m3p4p5p3s4s5s6s7s8s+5m+22',
  '1m1m7m7m1p1p4p4p5p5p3s3s4s4s+11',
  '3m3m4m5m3p4p5p1s2s3s4s5s6s+3m+21',
  '5m6m9m9m2p3p4p7s7s8s8s9s9s+4m+24',
  '1m1m1m4m5m7m8m9m2p2p1z1z1z+3m+12',
  '4m4m5m6m7m4p5p5s5s5s6s7s8s+6p+24',
  '2m2m3m4m5m7m8m6p7p8p4s5s6s+9m+22',
  '7p7p8p8p9p2s3s3s4s4s5s8s8s+6p+24',
  '5m5m7m7m8m8m2s2s3s3s8s8s4z+4z+22',
  '5m5m6m7m8m7p8p2s3s4s6s7s8s+9p+13',
  '5m6m8m8m8m3p4p5p6p7p8p8s8s+4m+22',
  '8m8m3p4p5p5p6p1s2s3s6z6z6z+4p+13',
  '2m3m4m3p3p4p5p6p2s3s4s7s7s7s+21',
  '6m7m7m8m9m6p6p3s4s5s5s6s7s+5m+13',
  '6m6m1p2p3p6p7p8p2s3s4s4s5s+6s+11',
  '4m4m5m5m6m6m3p3p1s1s3s3s5s5s+13',
  '2p2p5p6p7p3s4s5s6s7s7s8s9s+2s+11',
  '4m5m5m6m7m7p7p2s3s4s6s7s8s+6m+11',
  '1m2m3m6m6m3p3p4p5p5p7p8p9p+4p+13',
  '1m2m3m7m8m2p3p4p1s1s7s8s9s+6m+22',
  '2m3m4m4m5m4p5p6p5s6s7s8s8s+6m+23',
  '2m2m3m3m4m7m7m2p3p4p6s7s8s+4m+11',
  '1p2p3p5p6p6p7p8p3s3s5s6s7s+7p+11',
  '2m3m4m7m8m6p6p3s4s5s6z6z6z6m+21',
  '2m3m5m5m4p5p6p7p8p9p5s6s7s+4m+23',
  '6m7m8m2p3p4p4p5p9p9p5s6s7s+3p+21',
  '1m2m3m7p8p1s2s3s3s4s5s8s8s+9p+14',
  '2m3m4m6m6m7m7m8m8m8p8p5s5s+8p+22',
  '2m3m4m9m9m4p5p6p7p8p9p4s5s6s+12',
  '4m5m6m6m8m4p4p4p6p6p3s3s3s7m+24',
  '2m2m4p5p5p6p7p2s3s4s5s6s7s3p+12',
  '7m8m9m3p4p6p7p8p9p9p6s7s8s+2p+11',
  '1m2m3m7m8m4p4p4s5s6s7s8s9s9m+11',
  '8m8m1p1p7p7p4s4s6s6s1z1z6z+6z+23',
  '4m5m5m5m6m5p6p1s1s2s2s3s3s4p+11',
  '2m3m4m2p4p9p9p2s3s4s7s8s9s+3p+11',
  '5m5m3p4p5s6s7s7s8s9s1z1z1z2p+12',
  '3m4m5m5m6m7m7p7p2s3s4s4s5s3s+12',
  '4m5m2p3p4p7p8p9p3s4s5s6s6s3m+12',
  '2m3m4m6m8m5p6p7p3s3s4s5s6s+7m+22',
  '9m9m1p1p3p3p7p7p3s3s5s3z3z+5s+14',
  '3m3m4m5m6m5p6p2s3s4s4s5s6s4p+22',
  '4m5m2p3p4p6p6p2s3s4s6s7s8s+6m+11',
  '2m3m4m8m8m7p8p2s3s4s4s5s6s+9p+11',
  '4m5m6m2p3p4p6p7p8p2s2s2s4s+4s+23',
  '5m6m7m8p8p8p7s8s8s8s6z6z6z7s+11',
  '2m3m1p2p3p4p4p3s4s5s7s8s9s4m+14',
  '1m1m3m3m4m4m5m5m5p6p7p7s9s+8s+23',
  '6m7m8m4p5p6p7p8p9p2s3s6s6s+1s+14',
  '4m5m6m3p4p5p5p7p3s3s6s7s8s+6p+13',
  '3m4m5m5p5p6p7p8p5s6s6s7s8s+7s+12',
  '4m4m4m7m7m2s2s3s3s4s4s5s6s7s+14',
  '1m2m3m5p6p2s3s4s5s6s7s4z4z+4p+22',
  '4m5m6m2p3p4p4s4s5s5s6s4z4z6s+11',
  '2m3m4m6m7m5p5p1s2s3s4s5s6s+8m+12',
  '2m2m6m7m8m2p3p4p7p7p7p6s7s+5s+24',
  '1p2p3p4p5p6p8p9p3s4s5s2z2z+7p+23',
  '4m5m6m2p3p4p6p7p3s4s5s7s7s+8p+11',
  '3m5m3p4p5p7p8p9p3s4s5s6s6s+4m+23',
  '4m5m6m7m8m2p3p4p6p7p8p3s3s+9m+21',
  '2m3m4m9m9m5p6p6p7p7p4s5s6s+8p+14',
  '1m2m3m4s4s5s5s6s2z2z2z6z6z3s+22',
  '4m4m5p5p5p6p8p4s5s6s7s7s7s+7p+22',
  '3m4m5m6m7m8m7p8p3s3s6s7s8s+9p+13',
  '2m2m7m8m9m1p2p3p7p8p7s8s9s6p+22',
  '5m6m6m7m7m8p8p3s3s9s9s3z3z+5m+24',
  '1m1m2m2m3m8p8p2s2s4s4s7s7s+3m+23',
  '1m2m3m3p4p1s2s3s6s6s7s8s9s2p+14',
  '2m2m2p3p2s3s3s4s4s5s6s7s8s1p+12',
  '4m4m1p3p1s1s1s8s8s8s1z1z1z+2p+21',
  '2m3m4m6m7m8m5p6p5s6s7s9s9s7p+22',
  '2m3m4m5m6m7m3p4p5p8p8p4s5s+3s+23',
  '1m2m3m6m6m7m7m8m4s5s6s2z2z5m+14',
  '2m3m4m4m5m7m7m2p2p2p4p5p6p+3m+13',
  '3m3m3m7m8m9m1s1s3s3s4s4s5s5s+23',
  '1p2p3p6p7p8p1s1s2s3s5s6s7s+1s+11',
  '5m6m7m7m8m5p6p7p9p9p3s4s5s+6m+22',
  '2m2m2m7m7m7m3p4p3s3s7s7s7s+2p+14',
  '1m2m3m4p5p6p8p8p3s4s5s6s7s+8s+22',
  '6m7m8m1s2s4s4s5s6s7s1z1z1z3s+14',
  '6m6m7m7m8m8m6p7p2s2s5s6s7s5p+13',
  '5m6m7m2p3p4p3s4s5s6s7s4z4z+2s+12',
  '5m6m7m8m9m2s3s4s6s7s8s9s9s4m+13',
  '3m3m3p5p6p7p8p5s5s6s6s7s7s4p+13',
  '3m3m8m8m3s3s1z2z2z4z4z6z6z1z+23',
  '2p2p2p3p4p6p7p8p6s7s8s2z2z+2z+22',
  '2m3m7m8m9m6p7p8p3s4s5s6s6s+4m+24',
  '1m1m2m3m4m5m6m7m7m8m9m7z7z+7z+22',
  '3m4m5m3p4p5p5p6p8p8p3s4s5s+4p+24',
  '2m3m4m5m5m6m7m3p3p3p5s6s7s5m+22',
  '5m6m7m8m8m4p5p5p6p6p6s7s8s+4p+11',
  '2m3m4m4m5m6m2s3s3s4s5s6s6s+1s+24',
  '5m5m6p6p7p3s3s6s6s9s9s3z3z+7p+11',
  '3m4m5m7m1s2s3s5s6s7s5z5z5z7m+24',
  '5m6m7m9m9m3p4p5p6p7p8p7s8s6s+14',
  '4m4m8m8m8m9m9m9m4z4z4z7z7z+7z+14',
  '3m4m5m7m7m3p4p5p2s2s2s4s5s+6s+12',
  '3m4m3p3p5p6p7p4s5s6s6s7s8s+2m+13',
  '4m5m5m6m6m7m7p8p9p1s1s4s5s3s+12',
  '2m2m3m4m5m2p3p3s4s5s6s7s8s1p+14',
  '7m8m9m2p3p7p8p9p4s5s6s3z3z1p+21',
  '1m2m3m1p2p3p4p4p4p1s2s6s6s+3s+12',
  '2m3m8m8m1p2p3p3s4s5s6s7s8s4m+12',
  '6m6m2p2p4p4p2s3s3s4s4s3z3z+2s+24',
  '3m3m5m5m7m7m2p3p3p7p7p5z5z2p+11',
  '4m6m6m7m8m3s4s5s5z5z7z7z7z+5m+24',
  '3m4m5m5m5m4p5p6p7p8p9p5s6s7s+24',
  '5m6m7m7p8p9p2s3s5s5s7s8s9s4s+14',
  '3m4m6m6m3p4p5p6p7p8p3s4s5s5m+23',
  '6m6m6m7p7p8p8p9p5s6s7s1z1z+9p+22',
  '3m4m5m8m8m3p4p5p2s3s5s6s7s4s+21',
  '3m3m8m9m2s3s4s4s5s6s2z2z2z+7m+23',
  '4m4m6m6m3p3p9p9p3s3s6z7z7z+6z+13',
  '5m6m3p4p5p6p7p8p1s2s3s5s5s7m+12',
  '1m2m3m7m7m2p3p3p4p4p4s5s6s+2p+21',
  '8m8m8m6p6p6p2s2s7s8s4z4z4z+6s+11',
  '4m5m6p7p8p2s3s4s5s6s7s8s8s6m+22',
  '3m4m4m5m5m6m5p6p7p7p8p7s7s+6p+14',
  '4m4m5p5p5p7p8p9p5s6s7s6z6z+6z+11',
  '1m1m5m6m7m7p8p9p3s4s5s5s6s+4s+23',
  '2m3m4m6m7m8m3p4p4p5p6p8s8s2p+21',
  '2m2m2p3p4p4p6p2s3s4s5s6s7s+5p+24',
  '5m6m7m2p3p4p5p6p7p3s3s6s7s5s+11',
  '3m4m5m1p2p3p9p9p3s4s4s5s5s+3s+12',
  '1m2m3m5m5m3p4p5p2s3s4s6s7s+8s+23',
  '3m4m5m6m6m3p4p5p5p6p6s7s8s+7p+11',
  '2m3m4m1p2p2p3p3p4p2s4s6z6z3s+12',
  '7m8m2p2p7p8p9p1s2s3s5s6s7s9m+11',
  '1m1m2m3m3m4m4m5m4p5p5p6p7p6p+11',
  '3p3p6p7p7p8p8p1s2s3s5s6s7s6p+11',
  '5m5m9m9m3p7p7p8p8p5s5s8s8s+3p+22',
  '1m1m4m4m7m7m5p5p7p7p5s6z6z+5s+24',
  '2m3m4m2p3p4p6p7p8p5s5s6z6z6z+21',
  '7m7m7m6p6p1s2s2s3s3s5s6s7s+1s+14',
  '5m5m5p6p6p7p8p5s6s7s7s8s9s+4p+13',
  '4m5m6m6m7m8m3p3p5p6p2s3s4s7p+11',
  '1p2p3p5p6p7p1s2s3s7s8s3z3z+9s+24',
  '3m4m5m3p3p6p7p8p5s6s6s7s8s7s+13',
  '3m4m5m7m7m7m4p5p6p2s2s5s6s7s+13',
  '5m5m1p2p3p5p6p7p7p8p6s7s8s+9p+24',
  '1m1m5m6m7m7m8m3s4s5s7s8s9s+9m+22',
  '5m6m7m2p2p2p3p3p3p4s5s7s7s3s+23',
  '3m4m5m9m9m5p6p7p8p9p5s6s7s4p+13',
  '2m3m4m6m7m5p5p2s3s4s6s7s8s+8m+23',
  '2m2m4m5m6m7m8m9m4s5s7s8s9s+3s+14',
  '1m2m3m4m5m6m1p2p3p4p4p5s6s7s+13',
  '2m3m4m5m6m7m4p5p6p3s3s5s6s7s+23',
  '4m5m6m5p7p4s4s5s5s6s6s3z3z6p+22',
  '5m6m7m5p6p5s6s6s7s7s8s5z5z7p+11',
  '3m4m5m6m7m8m4p5p2s3s4s8s8s3p+14',
  '4m5m6m6m7m2p2p6p7p8p2s3s4s+8m+14',
  '2m3m4m4p5p6p7p8p5s5s8s8s8s3p+11',
  '2m2m3m4m5m9m9m2p3p4p1z1z1z+2m+13',
  '5m6m7m1p1p2p3p3p5p6p7p7s7s+2p+12',
  '6m7m8m5p6p7p7p8p9p3s3s5s6s+4s+22',
  '2m3m4m4m7m8m9m4p5p6p6s7s8s1m+23',
  '5m6m1p2p3p3p4p5p1s2s3s5s5s+4m+22',
  '7m8m8p8p1s2s3s4s5s6s6z6z6z+6m+11',
  '1m1m4m5m6m1s1s2s2s3s3s6s7s+8s+21',
  '9m9m1p2p3p6p7p7p8p9p2s3s4s+5p+12',
  '2m3m6m7m8m3p4p4p5p5p6p3s3s1m+14',
  '5m6m7m1p1p2p3p4p5p6p5s6s7s+4p+23',
  '1m2m3m3m4m4p5p6p8p8p2s3s4s5m+11',
  '5m6m7m5p6p7p8p9p1s2s3s7s7s7p+11',
  '4m5m5m6m6m7m8m8m7p8p9p6s7s+8s+14',
  '2m3m3m4m4m5m6m6m3p4p5s6s7s+5p+22',
  '4m5m5m6m7m2s3s4s7s8s9s4z4z6m+21',
  '1m2m3m4m5m6m6m7m8m3p3p6p7p+8p+24',
  '4m4m5m5m5p5p5p2s3s4s5s6s7s5m+22',
  '4m4m6m7m8m2p3p4p4p5p6p5s6s7s+11',
  '5m6m2p2p4p5p6p6p7p8p3s4s5s+7m+12',
  '1p6p6p9p9p7s7s9s9s2z2z6z6z+1p+23',
  '3m4m5m3p3p5p6p2s3s4s3z3z3z4p+23',
  '2m2m2m4p5p6p6p7p8p3s3s5s6s+7s+14',
  '5m6m7m4p4p5p5p6p7p7p2s3s4s3p+12',
  '3m3m6m7m8m3p4p5p3s5s5s6s7s+4s+11',
  '1m2m3m7m7m8m9m9m5p5p5p1s1s+8m+22',
  '4m5m6m1p2p3p7p8p4s4s6s7s8s9p+24',
  '3m4m3p4p5p6p7p8p4s5s6s3z3z+2m+21',
  '3m4m5m4p4p6p7p7p8p9p2s3s4s+8p+22',
  '2m3m4m6m7m5p5p2s3s4s6s7s8s+8m+21',
  '1m1m5p6p7p7p8p9p5s6s7s7s8s+9s+22',
  '5m5m5m8m8m6p7p4s5s5s6s6s7s+8p+21',
  '5m5m6m7m7m8m9m1p2p3p6p7p8p8m+13',
  '5m6m7m7m7m2p3p4p3s4s6s7s8s2s+11',
  '4m5m6m6m6m2p3p4p6p7p1s2s3s+8p+11',
  '1m2m3m5m5m2p3p3s4s5s6s7s8s+1p+23',
  '6m7m8m4p4p5p5p6p4s5s6s7s7s3p+22',
  '1m2m3m6m7m5p5p7p8p9p1s2s3s5m+23',
  '4m5m7m7m6p7p8p3s4s5s6s6s6s+6m+13',
  '2p3p4p4p4p1s3s4s5s6s7s8s9s+2s+24',
  '2m3m4m5m6m2p3p4p5p6p7p2s2s+1m+22',
  '4m5m1p2p3p5p6p7p1s2s3s6s6s+6m+11',
  '1p2p3p5p6p7p7p8p9p2s2s4s5s6s+23',
  '8m8m2p3p4p2s3s3s4s4s7s8s9s+2s+11',
  '4m4m4m5m6m6p7p8p4s5s6s8s8s+8s+14',
  '3m4m2p3p4p5p6p7p2s3s4s2z2z2m+11',
  '3m4m5m4p5p6p2s2s2s3s5s6s7s4s+14',
  '2m3m4m4m2p3p4p5p6p7p7s8s9s+4m+21',
  '5m5m5m1p2p3p5p6p6s6s2z2z2z+4p+22',
  '4m5m6m3p4p4p5p6p1s2s3s8s8s2p+23',
  '4m6m8m8m8m2p3p4p6p7p8p5s5s+5m+23',
  '2m2m4m4m5m5m2p3p3p3s3s5s5s2p+13',
  '5m5m7m7m6p6p7p7p9p9p2s2s4z+4z+12',
  '4m5m6m3p3p7p7p7p3s4s5s6s6s+6s+22',
  '3m4m5m6m6m4p5p6p6p7p8p6s7s+8s+21',
  '4m4m4m5m6m6m6m4p5p6p5s6s7s5m+13',
  '7m7m5p6p6p6p7p2s3s4s4s5s6s6p+14',
  '5m6m7m3p4p5p6p6p7p8p9p5s6s+7s+23',
  '2m2m2m2m4m3p4p5p7p7p5s6s7s+3m+14',
  '3m4m5m7m7m7m2p2p3p5p5s6s7s+4p+22',
  '2p2p6p7p8p1s2s3s7s8s5z5z5z+9s+13',
  '3m4m5m5m6m5p5p6p6p6p4s5s6s7m+11',
  '4m4m5m6m7m5p6p6p7p7p8p7s8s9s+11',
  '5m6m7m8m8m2p2p4p5p6p5s6s7s8m+13',
  '2m3m4m5m6m9m9m3p4p5p2s3s4s+1m+13',
  '1m2m3m6p7p8p3s4s5s5s7s8s9s+5s+23',
  '3m4m5m7m7m1p2p3p5p6p3s4s5s7p+12',
  '2m3m4m5m6m7m4p4p6p7p8p4s5s6s+14',
  '4m5m6m4p4p6p7p8p2s3s4s6s8s+7s+21',
  '2m3m4m1s1s3s4s4s5s5s7s8s9s6s+21',
  '3m3m5m6m7m6p7p8p1s2s3s5s6s4s+21',
  '6m7m8m2p2p6p8p3s4s5s6s7s8s+7p+11',
  '3p3p5p6p7p2s3s4s4s5s8s8s8s3s+13',
  '3m3m5m6m4p5p6p2s3s4s4s5s6s+4m+14',
  '3m4m7m7m1p2p3p6p7p8p4s5s6s+2m+22',
  '2m3m3m4m5m2p3p4p5p5p6p7p8p+1m+14',
  '6m7m8m1p1p2p3p4p5p6p7p6s7s5s+24',
  '3m3m4m5m6m3p4p1s2s3s4s5s6s+2p+24',
  '2m3m3m3m5m6m7m3p4p5p4s5s6s+1m+12',
  '7p7p8p9p9p1s1s6s6s7s7s8s8s+8p+12',
  '3m4m6m7m7m7m8m4p5p6p3s4s5s+2m+12',
  '5m6m7m7p8p9p2s3s4s4s4s5s6s+1s+24',
  '7m8m9m9m9m5p6p7p3s4s7s8s9s5s+14',
  '5p5p6p6p7p8p8p4s5s5s6s6s7s+4p+11',
  '3m4m5m7m7m7p8p9p1s2s3s5s6s4s+14',
  '3m4m5m4p4p5p6p7p3s4s5s7s8s9s+24',
  '5m6m7m7m8m3p4p5p7s8s9s4z4z+6m+21',
  '3m3m8m8m5p5s5s8s8s1z1z3z3z+5p+24',
  '1s1s1s2s3s3s4s6s7s8s9s9s9s+5s+24',
  '8m8m9m9m1p1p2p2p5s5s8s9s9s+8s+11',
  '4m5m6m6m7m7p8p9p1s1s2s3s4s5m+11',
  '4m4m7m7m2p3p3p4p4p5p5p5s5s2p+12',
  '3m4m5m3p3p5p6p7p7p8p6s7s8s9p+21',
  '3m3m4m4m5m5m7m8m1p2p3p2s2s6m+24',
  '2m3m4m4p4p5p5p5p1s2s3s7z7z+7z+11',
  '2m2m7m7m4p6p6p2s2s5s5s7z7z4p+24',
  '5m6m7m7m8m5p5p7p8p9p1s2s3s+6m+11',
  '6m6m7m8m9m1s2s3s4s5s6s8s9s+7s+14',
  '3m4m5m5m6m7m6p6p3s4s5s6s6s+6s+11',
  '4m5m6m2p3p4p5p5p4s5s6s7s8s+9s+11',
  '4m4m6p6p7p1s1s3s3s7s7s7z7z+7p+11',
  '4p4p5p5p6p6p7s7s1z1z3z3z7z+7z+14',
  '1m2m3m5m6m7m8m8m2p3p4p2s3s+4s+12',
  '2m2m6m8m9m9m9m7p7p8p8p9p9p+7m+23',
  '1m1m6m7m8m3p4p5p6p7p8p7s8s6s+23',
  '1m2m3m5m5m5m3p3p5s6s7s6z6z6z+23',
  '4m5m6m3p4p5p4s5s6s2z2z6z6z+2z+12',
  '3m4m4m5m5m5m6m7m3p3p3s3s3s6m+13',
  '3m4m5m3p4p4p4p5p6p8p4s5s6s+7p+13',
  '3m4m3p3p1s2s3s4s5s5s6s6s7s+2m+11',
  '1m2m3m3m4m5m6m7m8m5p6p3z3z7p+12',
  '6m7m8m2p2p5p6p7p2s3s6s7s8s4s+13',
  '3m3m3m6m6m4s5s6s6s6s6s8s8s+6m+11',
  '4m5m6m6m7m2p3p4p5p5p1s2s3s+8m+11',
  '1m1m7m8m9m7p8p9p4s5s7s8s9s3s+14',
  '4m5m2p2p2p3p4p5p6p7p1s2s3s+3m+13',
  '4m4m3p4p5p7p8p1s2s3s6s7s8s+6p+11',
  '5m6m7m2p3p4p2s2s4s5s6s7s7s7s+14',
  '1m2m3m1p1p2p3p4p4p5p7p8p9p6p+23',
  '1m2m3m4m4m5m5m6m4p5p6p8p8p+6m+23',
  '4m5m6m3p3p3p5p6p7p4s4s7s8s+6s+11',
  '7m7m8m8m4p4p5p5p7p7p8p8p5s+5s+14',
  '3m4m5m6p7p8p6s6s6s7s7s8s9s5s+12',
  '3m3m3m4m5m6m4p4p3s4s5s5s6s+4s+13',
  '2m2m6m7m8m6p8p5s5s5s6s7s8s+7p+24',
  '8m8m3s3s4s4s6s6s9s9s3z3z6z+6z+14',
  '2m3m5m6m7m7m8m9m1s2s3s5s5s+4m+13',
  '3m3m5m6m7m7m8m9m2s2s6z6z6z+3m+31',
  '4m5m5p6p7p2s3s4s5s5s6s7s8s+3m+12',
  '2p2p2p5p6p7p2s2s2s5s6s7s8s+8s+21',
  '1m2m3m5m5m1p2p3p4p5p6p4s5s+3s+23',
  '2m3m4m6m7m8m2p3p7p8p9p4s4s+4p+13',
  '2m3m5m6m7m2p3p4p6p7p8p6s6s+4m+13',
  '2m3m4m5m6m7m3p3p5p6p4s4s4s+7p+23',
  '3m4m5m7m7m2p3p4p4s5s7s8s9s+6s+12',
  '3m4m5m3p4p5p7p7p2s3s4s5s6s+1s+24',
  '1m2m3m3m4m5m3s3s4s5s7s8s9s+6s+13',
  '3m4m5m7m8m9m2p3p4p5s5s6s7s+8s+13',
  '2m3m4m5p6p7p8p8p2s3s4s7z7z+7z+12',
  '5m6m7m7m8m1p1p2p3p4p5s6s7s9m+11',
  '1m2m3m5m6m7m1p2p3p4p4p4s5s+3s+24',
  '4m5m7m8m9m3p3p5p6p7p6s7s8s6m+11',
  '6m7m8m2p3p4p2s3s4s5s5s6s7s+8s+23',
  '7m7m2p3p4p5p6p7p2s3s4s5s6s1s+12',
  '4m4m5m5m6m6m7m8m4p5p6p8p8p+9m+23',
  '6m6m7m7m3p3p7p7p1s3s3s7s7s+1s+12',
  '2m3m4m1p2p3p6p7p8p1s1s3s4s+5s+22',
  '2p3p4p4p5p6p1s2s3s6s7s2z2z8s+13',
  '5m6m7m4p5p7p7p4s5s6s6s7s8s+6p+14',
  '1m1m5p6p7p7p8p3s4s5s6s7s8s+9p+12',
  '2m3m9m9m2p3p4p7p8p9p1s2s3s+4m+13',
  '3m3m5m5m9m7p7p5s5s6s6s8s8s+9m+21',
  '1m2m3m4m5m7m8m9m1p1p2s3s4s6m+21',
  '7m8m9m3p3p7p8p9p3s4s7s8s9s+2s+13',
  '2m3m4m7m8m5p6p7p1s1s3s4s5s+9m+14',
  '3m4m3p3p4p4p5p5p7p7p2s3s4s+2m+23',
  '1m2m3m6m7m8m5p5p5p6p7p2s3s+4s+11',
  '5m5m9m9m9m2p2p2p4s4s4s4s5s+6s+14',
  '3s3s4s5s6s6s7s8s9s9s2z2z2z+9s+21',
  '1m2m2m3m3m4m4m5m6m2p3p3p3p4p+24',
  '2m2m3m4m5m7m8m9m2p2p3p3p4p+4p+14',
  '1m2m3m3p4p5p7p7p5s6s6z6z6z7s+23',
  '3m4m5m7m8m9m1s2s3s5s6s7s7s+4s+11',
  '5m5m5m7m3p4p5p6p7p8p4s5s6s+6m+11',
  '8m8m2p2p1s7s7s8s8s4z4z5z5z1s+11',
  '2m3m4m8m8m2p2p2p5p6p3s4s5s+7p+22',
  '2m3m5m5m6p7p8p5s6s6s7s7s8s+4m+24',
  '4m4m5m6m7m3p4p2s3s4s4s5s6s2p+12',
  '2m3m4m3p4p6p7p8p9p9p4s5s6s5p+24',
  '3m4m5m5m6m7m8m1p2p3p4s5s6s2m+21',
  '2p2p4p5p6p6p6p7p7p8p6s7s8s+5p+14',
  '3m3m3m4m5m2p3p4p5p6p7p8p9p+1p+13',
  '1m2m3m3m4m8m8m5p6p7p7s8s9s+2m+22',
  '2p3p4p5p6p2s3s4s5s5s6s7s8s+1p+22',
  '2m3m4m7m8m9m1s2s3s4s5s6s6s+6s+11',
  '2m3m4m6m7m8m5p5p7p8p9p5s6s+7s+21',
  '4m4m7m8m9m4p5p7p8p9p6s7s8s3p+22',
  '2m3m4m5m6m7m7m8m2p3p4p9p9p+9m+23',
  '2m3m4m1p1p2p3p3p4p5p4s5s6s+4p+21',
  '1m2m3m5m6m7m7p8p1s1s7s8s9s9p+21',
  '3m4m5m6m7m8m6p6p3s3s4s4s5s+2s+12',
  '7m7m4p5p5p6p6p3s4s5s7s8s9s7p+12',
  '5m6m7m1p2p3p4p4p7p8p9p5s6s7s+13',
  '4m5m8m8m5p6p7p6s7s7s8s8s9s+3m+13',
  '6m7m2p3p4p6p7p8p9p9p3s4s5s8m+12',
  '5m6m7m9m9m1s2s3s6s7s7s8s8s9s+21',
  '3m4m4m5m5m8p8p3s4s5s6s7s8s+6m+11',
  '1m1m1p1p3p3p9p9p2s2s4s4s2z2z+14',
  '3m3m3m7m8m9m3p3p5s6s7s3z3z+3z+23',
  '4m5m5m6m6m7m7s7s7s4z4z6z6z+6z+22',
  '4m5m7m8m9m2p2p4p5p6p7p8p9p3m+21',
  '2m3m4m5m6m7m3p6p7p8p2s3s4s3p+22',
  '2m2m1p2p3p4p5p7p8p9p2s3s4s+6p+23',
  '2m2m1p2p3p5p6p1s2s3s6s7s8s+4p+23',
  '2m3m4m4m5m6m5p6p6s6s6s7s7s4p+12',
  '3m4m7m8m9m3p4p5p2s3s4s5s5s+2m+13',
  '4m5m6m2p3p5p6p6p6p7p5s6s7s+4p+21',
  '4m5m6m6m7m8m2p3p4p5s5s5z5z+5z+11',
  '3m4m5m8m8m3p5p2s3s4s4s5s6s4p+23',
  '4m5m6m3p3p7p8p9p1s2s3s3s4s+2s+14',
  '1m2m3m4p5p6p7p7p2s3s4s6s7s8s+11',
  '5m6m7m3p3p3p9p9p9p5s5s7z7z+7z+23',
  '3m4m6m7m8m5p6p7p5s5s6s7s8s2m+12',
  '1m1m4m4m9m9m3p3p8p8p5z7z7z+5z+21',
  '1m1m3m3m3p3p7p4s4s5s5s7s7s+7p+23',
  '6m6m5p5p6p7p7p3s4s5s7s7s7s6p+13',
  '1p2p3p4p4p6p7p8p4s5s6s6s7s+8s+14',
  '1m1m5m5m6m6m9m9m8p8p9s9s5z+5z+14',
  '5m6m2p3p4p4s5s6s6s7s8s9s9s4m+12',
  '1m1m2m2m3m3m7m7m8m8m3p9p9p+3p+12',
  '3m4m4m5m5m3p4p5p7p7p1s2s3s+6m+24',
  '5m6m7m7m8m8m8m9m5p6p7p6s7s+8s+13',
  '1m2m3m7m7m3p4p5p4s5s5s6s7s3s+24',
  '6m7m8m6p6p7p7p8p8p6s7s7z7z+8s+11',
  '3m4m5m7m8m9m3p3p7p8p5s6s7s+9p+14',
  '4m4m4m6m7m8m2p3p4p4s4s5s5s4s+23',
  '4m4m4m5m6m1p1p2p3p3p6p7p8p+2p+14',
  '5m6m7m3p4p5p7p8p9p9p2s3s4s+9p+24',
  '6m7m8m4p5p6p6p8p6s7s8s9s9s7p+24',
  '6m7m8m2p3p4p2s3s7s8s9s9s9s+1s+21',
  '5m6m2p3p4p2s2s6s6s6s7s7s7s+4m+24',
  '4m5m6m6m7m8m8m8m3p4p4p5p6p2p+13',
  '2p3p4p5p6p7p8p9p2s3s4s8s8s+1p+22',
  '6m7m8m4p4p4p5p6p5s6s7s2z2z+2z+22',
  '3m4m5m6m7m6p7p8p3s3s5s5s5s8m+22',
  '1m2m3m4m6m7m8m9m3p3p3s4s5s5m+13',
  '4m5m6m7m8m9m5p6p8p8p6s7s8s7p+12',
  '1p1p2p2p5p5p6p6p4s4s7s2z2z+7s+11',
  '2m3m3m4m4m4m5m6m6p7p8p5s5s+2m+22',
  '3m4m5m6m7m7m7m2s3s4s6s7s8s+6m+13',
  '4m5m5m6m6m7m6p7p4s4s5z5z5z+5p+11',
  '1m2m3m5m6m6m6m5p6p7p6s7s8s+4m+21',
  '2m3m4m7m8m9m3p4p9p9p3s4s5s+2p+12',
  '6m7m8m4p5p6p2s4s4s4s5s6s7s+3s+11',
  '4m4m3p4p4p5p5p2s3s4s7s8s9s6p+22',
  '2m3m4m2p3p5s5s6s7s7s8s8s9s4p+14',
  '6m7m8m9m9m3p4p5p7p8p9p4s5s+3s+21',
  '4m4m1p2p3p5p6p2s3s4s6s7s8s+7p+22',
  '3m4m5m6m7m3p4p5p4s5s6s7s7s+8m+11',
  '1m2m3m5m6m7m6p7p5s6s7s9s9s5p+24',
  '2p2p3p4p4p9p9p9p1s2s3s1z1z+3p+11',
  '1p1p2p3p4p6p8p6z6z6z7z7z7z+7p+24',
  '2m2m3m3m4m4m5m6m3s4s5s6z6z4m+13',
  '6m6m1p2p4p5p6p7p8p9p1s2s3s+3p+24',
  '1m2m3m7p8p9p3s4s5s6s7s9s9s+5s+12',
  '5m5m7m7m2p2p8s8s9s9s6z7z7z+6z+12',
  '1m2m3m3m4m5m6m7m8m6p7p8s8s+5p+21',
  '2m2m3m3m4m4m4m5m6m8p8p3s3s+8p+12',
  '2m3m4m4m5m4p5p6p9p9p6s7s8s6m+22',
  '3m4m6m7m8m1s2s3s3s4s5s3z3z+5m+14',
  '4m5m6m3p4p5p1s1s2s3s3s8s8s2s+23',
  '4m5m4p5p6p9p9p1s1s2s2s3s3s6m+24',
  '3m3m6p7p8p4s5s6s6s7s7s7s8s+7s+13',
  '2m3m4m6m7m8m2p2p6p7p4s5s6s+8p+24',
  '2m3m4m6m7m8m4p5p6p4s5s8s8s+6s+13',
  '6m7m8m2p4p5p6p7p8p8p6s7s8s+3p+24',
  '2m3m4m5m6m7m8m8m5s6s7s8s9s7s+21',
  '1m2m3m3m4m5m6m7m7m8m9m6z6z8m+14',
  '5m5m2s2s4s5s5s7s7s8s8s1z1z+4s+14',
  '2m2m2m6m7m8m6p7p8p6s7s2z2z+8s+14',
  '1m2m3m5m6m7m8m9m6p6p7s8s9s4m+14',
  '1m1m1m3m3m3m4m4m4m7m7m4s4s4s+14',
  '7m8m9m3p4p7p7p2s3s4s4s5s6s+2p+14',
  '1m2m3m5m6m7m7m2p3p4p3s4s5s+7m+13',
  '5m7m2p3p4p5p5p6p7p8p4s5s6s+6m+12',
  '9m9m4p4p5p5p6p3s4s5s3z3z3z6p+21',
  '4m5m6m3p4p5p6p7p8p6z6z7z7z6z+23',
  '3m4m5m7m7m1p2p3p4p5p6p5s6s+4s+23',
  '2m3m5m5m1p2p3p4p5p6p7s8s9s+1m+11',
  '3m4m5m6m7m8m3p3p4p5p6p3s3s3p+23',
  '6m7m8m8m1p2p3p1s2s3s4s5s6s+5m+21',
  '1m2m3m2p2p6p7p2s3s4s4s5s6s8p+21',
  '4m5m6m4p4p6p7p2s3s4s5s6s7s8p+31',
  '2m3m4m7m7m7m6p8p4s5s6s8s8s7p+21',
  '2m3m4m4m5m6m5p5p7p8p1s2s3s9p+21',
  '2m2m5m6m7m7m8m9m2p3p4p3s4s5s+13',
  '1m3m7m8m9m1p2p3p7s8s9s3z3z+2m+12',
  '1m2m3m7m8m4p4p3s4s5s6s7s8s9m+12',
  '1m1m4p5p6p2s3s4s5s6s7s8s9s+1s+13',
  '1m2m3m1p1p2p3p4p7p8p9p3s4s+5s+24',
  '1m1m7m8m9m3p4p5p6s7s7s8s9s+5s+13',
  '4m4m5m6m1p2p3p5p6p7p7s8s9s7m+12',
  '7m7m4p5p6p1s2s3s4s5s7s8s9s+3s+21',
  '1m2m3m5m5m6m7m8m5p6p7p7s8s+9s+13',
  '2m3m4m7m7m7m2p2p2s3s4s5s6s+7s+12',
  '2m2m7m7m8m8m5p5p7p7p2s2s1z+1z+11',
  '3m4m5m7m8m3p3p5p6p7p5s6s7s+9m+24',
  '2m3m4m7m8m4p5p6p9p9p4s5s6s+9m+24',
  '4m5m1p2p3p4p5p6p5s5s7s8s9s3m+23',
  '3p3p7p7p5s5s6s8s8s9s9s6z6z6s+23',
  '3m3m2p4p5p6p7p3s4s5s6s7s8s+3p+13',
  '2m2m4p4p6p6p7p3s3s7s7s3z3z+7p+22',
  '4p5p6p1s2s2s3s4s6s6s7z7z7z+3s+12',
  '3m4m4m5m5m6m3p4p5p4s6s7s7s+5s+24',
  '7m7m8m8m9m4p4p4s5s6s6s7s8s+6m+24',
  '3m4m5m6m7m8m8m8m6p7p8p1z1z1z+11',
  '1m2m3m5m6m7m1p1p4s4s4s6z6z+6z+22',
  '3m4m5m1p2p3p4p4p5p6p4s5s6s+7p+13',
  '7m7m8m8m3p3p3s3s4s6s6s7s7s+4s+11',
  '5m6m7m7m8m9m5p6p7p4z6z6z6z4z+14',
  '4m5m6m7p7p7p5s6s7s7s7s8s8s+4s+22',
  '3m4m5m7m8m5p6p7p2s2s4s5s6s6m+11',
  '4m4m6m6m9m7p7p8p8p9p9p1s1s9m+13',
  '7m8m9m7p8p9p5s6s7s8s9s4z4z7s+21',
  '2m3m4m2p4p7p7p2s3s4s5s6s7s+3p+24',
  '2m3m4m9m9m5p6p2s3s4s4s5s6s4p+12',
  '4m5m2p3p4p7p8p9p3s3s4s5s6s3m+14',
  '1m2m3m8m8m1s1s2s2s3s3s6s7s5s+21',
  '3m3m4m5m6m7m7m8m8m9m6p7p8p6m+13',
  '1m1m3m3m1p1p2p2p3s3s7s7s7z+7z+22',
  '1m1m1m1p1p2p2p3p3p2s3s4s5s+5s+24',
  '3m4m5m6m7m8m5p5p2s3s4s8s8s+5p+14',
  '5m5m5m2s3s4s8s8s1z1z2z2z2z+1z+23',
  '4p5p6p9p9p3s4s5s6s7s6z6z6z5s+11',
  '7m8m2p3p4p6p6p1s2s3s5s6s7s9m+14',
  '7m8m1p1p2p3p4p6p7p8p7s8s9s+6m+12',
  '7m8m1p2p3p7p8p9p5s5s5z5z5z+9m+22',
  '5m6m7m5p6p7p8p9p5s6s6s6s7s+4p+22',
  '2m2m2m3m4m7p8p9p5s6s7s8s9s7s+14',
  '1p2p3p8p8p8p2s3s5s5s7z7z7z1s+23',
  '3p4p5p6p7p2s3s4s7s7s8s8s8s+5p+24',
  '1m2m3m3p3p4p4p5p5s6s7s7s7s+2p+12',
  '1m2m3m6m7m8m6p7p4s5s6s8s8s8p+11',
  '1m1m2m2m3m3m7p8p9p3s4s7s7s2s+13',
  '2m3m4p5p6p2s3s4s4s4s2z2z2z+1m+24',
  '1m2m3m4m5m7m8m9m5p6p7p1z1z6m+14',
  '1m2m3m3p3p4p5p6p3s4s5s7s8s+9s+24',
  '2m3m4m7m8m9m9m9m6p7p6s7s8s+8p+13',
  '4m5m6m3p4p4p5p6p7p8p9p1s1s+2p+24',
  '1m2m3m6m7m8m4p4p5p6p2s3s4s4p+21',
  '2m3m4m5p5p2s2s3s3s4s5s6s7s4s+11',
  '6m7m8m4p4p5p5p6p6p7p7s8s9s+7p+22',
  '6m6m6m1p1p3p3p1s2s3s1z1z1z+1p+11',
  '1m2m3m5m6m1s2s3s3s4s5s8s8s+7m+24',
  '3m4m5m6m7m8m4p4p1s2s5z5z5z3s+21',
  '4m4m4m5m6m2p3p4p4s5s6s1z1z+1z+14',
  '4m5m9m9m1p2p3p3p4p5p7p8p9p+6m+12',
  '4m5m6m7m7m8m8m8m7p7p3s4s5s+7m+13',
  '2m2m3m4m5m7m8m3p4p5p4s5s6s+6m+24',
  '2m3m4m5m8m8m8m1z1z1z6z6z6z+2m+23',
  '2m3m4m3p3p6p7p3s4s5s2z2z2z5p+21',
  '4m5m6m6m7m8m2p2p6p7p4s5s6s+8p+13',
  '6m7m7m7m8m8m8m9m9m6p6p7z7z+7z+12',
  '6m7m8m4p5p6p4s5s5s6s6s7s8s8s+14',
  '6m7m8m5p6p4s4s2z2z2z3z3z3z+7p+13',
  '5m6m7m4p5p6p7p7s7s8s8s9s9s+4p+21',
  '7m8m9m2p3p3p4p5p7p7p3s4s5s+1p+24',
  '2m2m6m7m8m4p5p8p8p8p6s7s8s+3p+11',
  '4m5m6m6m7m8m4p5p8p8p6s7s8s6p+12',
  '3m4m2p3p4p1s2s3s4s5s6s8s8s+2m+12',
  '5m6m6m7m7m8m5p6p7p7s8s4z4z6s+11',
  '1m1m3m3m6m6m1p2p2p6s6s4z4z+1p+11',
  '2m3m4m2p2p3p4p4p5s6s7s5z5z+3p+11',
  '7m8m9m4p4p7p8p1s2s3s4s5s6s+6p+14',
  '5m5m6m7m8m3p4p5p5p6p7p2s3s1s+12',
  '1m1m1m2m2m4m5m6m6m7m7m8m9m+5m+12',
  '3m4m1p1p3p4p5p6p7p8p5s6s7s+2m+13',
  '6m7m8m4p5p6p2s2s3s3s5s6s7s2s+14',
  '4m4m5m6m7m2p3p4p5p6p1s2s3s+1p+24',
  '2m3m4m4p5p6p6p7p8p5s5s6z6z+6z+22',
  '2m2m4m4m6m7m8m2p2p2p5s6s7s+2m+24',
  '4m4m7p7p8p8p2s2s6s6s8s8s4z+4z+13',
  '5m6m7m7m8m9m6p7p9p9p5s6s7s5p+22',
  '2m2m2m3m3m5m7m7s7s8s8s9s9s+6m+23',
  '1m2m3m4m4m5m6m7p8p9p7s8s9s7m+22',
  '2m2m6m7m8m2p3p4p4s5s6s6s7s+5s+11',
  '4m5m2p3p4p6p6p7p7p8p8p9s9s3m+22',
  '3m3m4m6m2s3s4s5s5s6s6s7s7s+5m+12',
  '4m4m5m5m6m4p4p1s2s3s5s6s7s+6m+14',
  '1m2m3m1p2p3p1s2s3s7s8s6z6z9s+14',
  '3m3m3m4m7m7m7m2p3p4p3s4s5s+2m+24',
  '4m4m2p3p4p5p6p7p4s5s5s6s7s6s+23',
  '1m2m3m5m5m7m8m3p4p5p7p8p9p+9m+14',
  '2p2p4p5p5p6p6p7p1s2s3s4s5s3s+21',
  '4m4m8m8m3p3p4p4p6p6p9p9p5s+5s+11',
  '2m3m4m5m7m8m8m2s3s4s6s6s6s6m+11',
  '1m1m4m4m6m6m3p4p4p1s1s9s9s+3p+13',
  '2m3m4m5m6m2s3s4s6s6s7z7z7z+1m+24',
  '9m9m1s1s3s3s9s9s2z5z5z6z6z2z+21',
  '2m3m4m7m8m3p4p5p6p7p8p8s8s6m+12',
  '6m7m8m3s4s5s6s7s9s9s1z1z1z8s+13',
  '6m7m8m2p3p4p6p7p8p6s8s9s9s7s+22',
  '2p3p4p4p5p7p7p3s4s5s5s6s7s6p+13',
  '5m5m6m6m7m2p3p4p4p5p6p1z1z+7m+23',
  '7m8m9m2s3s4s5s5s6s6s7s8s8s4s+24',
  '5m5m9m9m2p2p6p6p8s8s9s9s7z+7z+11',
  '2m2m4m5m6m4p5p6p3s4s5s7s8s+6s+24',
  '5m6m7m5p6p6p7p8p8p8p4s5s6s+4p+21',
  '5m5m8m8m9m9m4p4p3s4s4s4z4z3s+14',
  '4m5m1p1p1s2s3s4s5s6s6s7s8s+3m+21',
  '2m3m4m6m7m8m2p3p4p3s3s4s4s+3s+12',
  '7m8m5p6p6p6p7p1s2s3s3s4s5s6m+12',
  '7m7m1p2p3p3p4p5p7p8p2s3s4s9p+11',
  '7m8m9m2p3p4p7p8p9p6s6s8s9s+7s+13',
  '4p5p6p8p8p2s3s4s5s5s6s7s8s+8p+21',
  '1m2m3p4p5p6p6p6s7s8s5z5z5z+3m+23',
  '5m6m4p5p6p8p8p2s3s4s7z7z7z4m+24',
  '3m4m4m5m6m7m7m2p2p2p4p4p4p2m+21',
  '5m6m2p3p4p1s2s3s1z1z1z7z7z+7m+13',
  '3m4m5m2p3p3p4p4p5p6p6p2z2z+2z+12',
  '3m4m5m6m6m7m8m2p3p4p1s2s3s6m+12',
  '3m4m5m4p4p5p6p6p7p7p7s8s9s5p+11',
  '4m4m5m5m6m6m6m7m8m2p2p7s9s+8s+22',
  '3m3m7m8m9m1p2p3p2s3s4s5s6s+7s+14',
  '2m3m4m2p3p5s6s6s6s7s7s8s9s+1p+21',
  '3m3m6p7p7p8p8p9p3s4s5s5s6s4s+23',
  '6m7m8m9m9m2p3p4p2s3s5s6s7s+4s+21',
  '2m2m6m8m2p2p2p4s5s6s3z3z3z+7m+13',
  '4m5m3p4p5p7p7p4s5s6s7s8s9s6m+12',
  '3m4m5m6p6p6p4s5s6s1z1z3z3z+1z+11',
  '1m2m3m5m6m7m7m8m9m5p5p7s8s9s+14',
  '2m3m4m4m5m6m3p3p3p4p1s2s3s2p+14',
  '6m7m8m6p7p8p3s4s6s7s8s8s8s+5s+14',
  '2m3m6m7m8m5p6p7p6s7s8s9s9s+1m+24',
  '1m2m3m3p4p5p6p6p7p8p4s5s6s6p+12',
  '2m3m4m2p3p3p4p4p5p5s6s7s7s4s+24',
  '3m4m5m6m6m7m8m9m1p2p3p4s5s3s+13',
  '2m3m4m5m6m7m2p2p3p3p6p7p8p3p+11',
  '9m9m4p5p1s2s3s5s6s7s7s8s9s3p+12',
  '2m3m4m6m7m8m6p7p8p5s5s6s7s+8s+13',
  '3p4p5p7p8p9p1s2s3s5s6s9s9s+4s+23',
  '5m6m5p5p2s2s3s3s4s4s4s5s6s4m+12',
  '2m2m5p5p7p8p8p7s7s8s8s9s9s+7p+11',
  '2m2m5m6m7m4p5p6p6p7p5s6s7s+8p+12',
  '2m2m3m4m5m8m8m8m3z3z7z7z7z2m+23',
  '3m4m5m5m6m7m3p4p3s3s7s8s9s2p+23',
  '5m6m7m1p2p3p6p7p8p2s3s5s5s4s+12',
  '4m5m6m2p3p4p2s2s7s7s8s9s9s8s+24',
  '5m6m7m1p2p3p4p5p6p4s5s3z3z6s+11',
  '2m3m4m6m7m6p6p2s3s3s4s4s5s5m+13',
  '4m5m5m6m6m8m8m8m8p8p3s4s5s+7m+23',
  '1m1m4m4m8m8m8p8p9p7s7s7z7z9p+11',
  '6m6m7m8m9m7p8p5s6s7s7s8s9s9p+21',
  '2m3m3m4m4m5m5m5m6p7p7p8p9p8p+11',
  '3m4m5m5m5m7m8m9m2s3s6s7s8s+4s+22',
  '3m3m5p6p7p7p8p3s4s5s7s8s9s9p+23',
  '6m7m7m8m9m5p5p2s3s4s6z6z6z5m+23',
  '3m4m5m5m6m7m7p7p5s6s6s7s8s+4s+23',
  '2m3m4m6m7m5p6p7p4s4s7s7s7s+8m+23',
  '4m4m4m9m9m2p2p3p3p4p4p4s6s5s+23',
  '5m7m4p4p4p6p7p8p2s2s3s4s5s+6m+13',
  '5m5m6m2s2s5s5s7s7s1z1z5z5z+6m+14',
  '4m5m6m6m7m8m1p2p3p5p5p2s3s+4s+22',
  '4m5m6m4p4p3s4s4s5s6s7s8s9s+2s+22',
  '1m2m3m4m5m4p4p1s2s3s4s5s6s+6m+23',
  '2m3m4m6m7m9m9m3p4p5p6s7s8s+5m+13',
  '3m4m5m2p2p3s4s5s5s6s7s5z5z5z+11',
  '1p2p3p4p5p6p2s3s2z2z2z3z3z1s+23',
  '9m9m2p3p4p2s3s3s4s5s6s7s8s+1s+22',
  '5m6m7m7p7p7p8p8p2s3s4s4s5s+6s+21',
  '5m6m7m2p3p4p4p5p6p6s7s8s8s+5s+22',
  '5m6m7m2p3p3p4p4p7p8p9p2z2z2p+23',
  '8m8m1p2p3p4p5p5s6s7s7s8s9s6p+13',
  '5m6m1p2p3p5p6p7p2s3s4s8s8s+7m+13',
  '5m5m2p3p4p2s3s4s6s6s6s7s7s+5m+13',
  '3m3m6m6m8m5p5p3s3s5s5s6s6s+8m+11',
  '2p2p4p5p3s4s5s7s8s9s6z6z6z6p+12',
  '3m3m4m4m1p1p3p3p3s4z4z6z6z3s+23',
  '4m5m6m7m7m4p5p6p6p7p6s7s8s+8p+24',
  '3s4s5s6s7s8s8s8s9s9s9s7z7z+7z+22',
  '3m3m3m4p4p4p2s3s4s5s5s7s8s+6s+24',
  '2m4m3p4p5p7p7p7p7s7s2z2z2z+3m+24',
  '1m2m3m3m4m5m1p1p4p5p3s4s5s+3p+13',
  '1m2m3m8m9m1s2s3s7s8s9s3z3z7m+21',
  '2m3m3m4m4m5m2p2p5p6p7p4s4s+2p+13',
  '3p4p5p5p6p7p1s1s6s7s7z7z7z5s+23',
  '6m7m3p4p5p7p8p9p4s5s6s9s9s+5m+12',
  '4m4m6m7m8m6p7p8p7s8s1z1z1z+9s+13',
  '2m2m4p5p6p7p8p9p2s3s4s7s8s+9s+13',
  '5m5m6m6m7p7p9p9p1s7s7s4z4z1s+12',
  '6m6m2p3p3p4p4p5p6p8p4s5s6s7p+12',
  '3m4m5m5p6p6p6p8p8p8p7z7z7z7p+12',
  '2m2m7m8m9m1p2p2p3p3p4p4s5s+6s+12',
  '3m3m5m5m7m7m9m9m9p1s1s2s2s+9p+12',
  '6m7m8m9m9m2p4p5s5s6s6s7s7s3p+21',
  '5m6m6m7m8m2p3p4p5p6p7p2s2s+4m+14',
  '2p2p2p3p3p3p7p9p2s2s4z4z4z+8p+12',
  '1m2m3m3m4m4m4m6m7m8m1p2p3p5m+11',
  '2m3m3m4m4m5m7m8m9m9m1s2s3s9m+23',
  '2m3m7m7m5p6p7p2s3s4s7s8s9s+1m+21',
  '1m2m3m4m4m5m6m7m4p5p3s4s5s6p+22',
  '6m7m8m3p4p1s1s4s5s6s6s7s8s+2p+14',
  '7m8m9m4p5p6p2s2s4s5s6s1z1z+1z+14',
  '2m4m7m8m9m2p3p4p6p6p2s3s4s+3m+11',
  '4m4m5m5m6m6m2p3p4s4s4s7s7s+1p+13',
  '2m3m4m2p3p4p7p8p9p4s4s6s7s+8s+12',
  '6m6m4p5p6p1s2s2s3s3s7s8s9s+1s+21',
  '5m6m6m7m7m8m3p4p5s5s5s6s7s+5p+14',
  '1m2m2m3m3m4m2p3p4p2s3s4s5z+5z+21',
  '3m4m5m7m8m1p2p3p4p4p1s2s3s+9m+22',
  '2m3m4m3p4p5p1s2s3s5s6s8s8s+4s+13',
  '1m2m3m3m4m5m7m8m7p7p3s4s5s6m+23',
  '4m4m4p5p6p3s4s5s6s7s7s8s9s8s+21',
  '2m3m4m7m8m9m4p5p6p4s5s3z3z6s+22',
  '1m1m9p9p1s1s6s6s8s8s5z5z6z+6z+13',
  '5m6m7m1p2p3p4p4p2s3s4s6s7s8s+13',
  '5m6m7m8m3s4s5s5s6s7s6z6z6z5m+14',
  '4m5m6m7m7m6p7p8p2s3s4s5s6s+7s+14',
  '7m8m9m9m9m4p5p7p8p9p7s8s9s+6p+14',
  '4m4m5m5m6m4p5p5p6p6p7p4s4s+3m+23',
  '6m7m3s3s4s5s6s6s7s8s5z5z5z+8m+12',
  '5m6m7m1p2p3p5p6p5s6s7s8s8s+4p+11',
  '4m4m5m5m6m2s2s4s5s6s7s8s9s6m+22',
  '8m8m2p3p4p4p5p6p5s5s6s6s7s+7s+13',
  '4m4m5m6m7m1s2s3s4s4s4s5z5z+5z+13',
  '2m3m4p5p6p1s1s4s5s6s6s7s8s+1m+12',
  '2m3m4m6m7m8m6p7p8p8p2s3s4s+8p+11',
  '2m3m4m6m7m5p6p7p1s1s7s8s9s+8m+21',
  '5m5m5m6m7m8m9p9p5s5s7z7z7z9p+12',
  '2m3m4m7m8m2p2p3p4p5p3s4s5s+6m+23',
  '1m3m7m7m8m8m9m9m7p7p7s7s7s+2m+22',
  '6m7m8m5p5p6p6p7p7p2s2s3s4s+2s+21',
  '5m7m3p3p3p7p7p2s3s4s5s6s7s+6m+21',
  '2m2m7m8m1p2p3p6p7p8p4s5s6s6m+21',
  '7m8m9m2p3p6p6p7p7p8p8p7z7z4p+22',
  '5m6m7m2p3p4p6p6p9s9s9s6z6z+6z+11',
  '1m2m2m3m3m4m5m6m1s1s4s5s6s+4m+22',
  '4m5m4p5p6p1s2s3s4s4s6s7s8s+3m+11',
  '4m4m4m4p4p6p7p4s5s6s1z1z1z+5p+11',
  '7m8m8m8m6p7p8p3s4s5s6s7s8s+6m+31',
  '2m3m4m5m6m7m2p3p4p4s4s6s7s+8s+13',
  '5m5m2p3p4p6p7p4s4s4s6s6s6s+8p+22',
  '2m3m4m6m7m8m5p6p7p3s4s5s6s+6s+11',
  '9m9m1p3p4p5p6p7p8p9p1s2s3s2p+24',
  '2m2m4m4m5m5m7m8m8m2p2p5z5z7m+14',
  '7m8m9m1p1p2p3p3s4s5s7s8s9s+4p+12',
  '2m2m2p3p4p5p6p7p5s6s7s5z5z+5z+12',
  '2m3m4m7m7m2p3p4p6p7p8p4s6s+5s+12',
  '5m5m6m7m2s3s4s8s8s8s5z5z5z+5m+13',
  '3m3m3m7m7m1s2s3s6s7s7s8s8s6s+21',
  '3m3m8m8m8m3p4p5p6p6p6p5s7s+6s+11',
  '6m6m5p6p6p7p8p2s3s4s7s8s9s+7p+12',
  '1m2m3m4m5m6m8m9m9p9p2s3s4s+7m+12',
  '6m7m7m8m8m9m6p7p8p8p7s8s9s+8p+21',
  '3m4m5m3p4p5p3s4s4s5s5s9s9s+3s+14',
  '2m2m4m5m5m6m6m7m3p4p5p6p8p7p+12',
  '1m2m3m5m6m7p8p9p1s1s4s5s6s4m+21',
  '3m4m5m5m6m7m8m2p3p4p3s4s5s+2m+13',
  '2m2m4m5m6m6m7m8m2s2s4s5s6s2s+21',
  '1m2m2m3m3m2p2p6s7s8s1z1z1z4m+21',
  '2m3m4m6m7m8m8m8m7p8p7s8s9s9p+11',
  '4m5m5m6m6m7m7m8m9m9m4p5p6p6m+11',
  '2m2m3m3m4m1s2s3s5s6s7s9s9s4m+22',
  '2m2m6m7m2p3p4p4p5p6p6z6z6z+5m+13',
  '7m8m9m4p5p1s2s3s6s7s8s3z3z6p+14',
  '1m2m3m4m5m6m5p5p2s3s4s7s8s+9s+21',
  '4m5m6m6m8m3p4p5p5p6p7p4s4s7m+22',
  '2m3m5m6m7m3p4p5p7p8p9p2s2s4m+21',
  '1m2m3m1p2p3p1s3s5s5s6s7s8s2s+24',
  '3m4m5m6m6m4p4p5p6p7p3s4s5s6m+11',
  '5m6m2p2p3p4p5p2s3s4s5s6s7s+4m+22',
  '3m4m5m8m8m4p5p6p2s3s6s7s8s4s+22',
  '5m6m7m9m9m2p3p4p6p7p8p2s3s+4s+13',
  '5m5m7m7m1p1p1s1s5s5s2z2z5z+5z+23',
  '4m4m5m5m6m6m7m7m2p3p4p4s5s+6s+21',
  '6m7m8m3p4p5p9p9p2s3s4s5s6s4s+24',
  '1m2m3m8p9p1s1s3s3s4s4s5s5s+7p+24',
  '2m3m4m3p4p8p8p3s4s5s7s8s9s+5p+22',
  '1p2p3p3p4p5p3s3s5s7s5z5z5z+6s+13',
  '3m4m3p4p5p7p8p9p5s5s6s7s8s5m+21',
  '5m5m7m8m9m4s4s5s5s6s6s4z4z+5m+21',
  '1m2m3m5m6m4p5p6p7p7p6s7s8s+7m+13',
  '4m4m5m5m6m3p3p7p7p8p8p4z4z6m+13',
  '4m5m5m6m6m7m8m9m9m9m5p6p7p4m+24',
  '2m2m1p2p3p4p4p5p5p6p4s5s6s+3p+21',
  '7m8m9m2p3p4p9p9p1s2s3s5s6s+7s+24',
  '2m3m4m1p2p3p8p8p3s4s5s6s7s8s+21',
  '2m3m6m7m8m2p3p4p3s4s5s6s6s4m+21',
  '3p3p5p6p7p1s2s3s5s6s3z3z3z+7s+23',
  '5m6m7m8m8m3p4p1s2s3s5z5z5z5p+21',
  '4m5m6m6m7m8m3p4p5p3s5s5s5s4s+14',
  '2m3m5m6m7m1p2p3p5p6p7p3z3z+1m+14',
  '3m4m4m4m2p3p4p4p5p6p6s7s8s5m+14',
  '1m1m2m2m3m2p2p4p5p6p3s4s5s+3m+13',
  '3m4m5m5m5m5p6p7p6s7s7s8s8s+9s+21',
  '5m5m5m4p4p6p7p8p2s4s6s7s8s+3s+11',
  '1m1m1m3m5s6s7s4z4z4z6z6z6z+3m+22',
  '2m3m4m6m6m6m7m8m4p5p6p2s4s+3s+14',
  '2p2p2p7p7p1s2s3s5s6s2z2z2z+4s+23',
  '7m8m6p7p7p8p8p9p5s6s7s9s9s9m+22',
  '4m5m6m6m7m8m8m2s3s4s6s7s8s+5m+11',
  '6m7m6p7p8p2s3s4s6s7s8s8s8s+5m+12',
  '2m3m9m9m3p4p5p1s2s3s7s8s9s+1m+23',
  '2m3m4m8m8m4p5p6p7p8p9p3s4s2s+14',
  '2m3m4m5m6m7m7m8m6p6p5s6s7s+6m+11',
  '4m5m6m4p5p2s2s4s5s6s6s7s8s3p+13',
  '4m5m3p4p5p5p6p7p2s3s4s5s5s+3m+22',
  '2m2m3p4p5p5p6p7p2s3s5s6s7s+1s+11',
  '1m2m3m7m7m2p3p4p5p6p7p4s5s3s+24',
  '4m5m5m6m6m7m7m8m6p7p8p4s4s6m+21',
  '2m2m3m3m1p1p2p2p8p8p4z4z6z+6z+13',
  '4m5m6m4p5p6p7p8p1s2s3s6s6s6p+11',
  '3m4m5m4p5p7p7p3s4s5s7s8s9s+6p+22',
  '2m2p2p7p7p1s1s2s2s5s5s7s7s+2m+23',
  '4p5p1s2s2s3s3s4s5s5s6s7s8s+3p+13',
  '2m2m3m3m4m5m5m6m7m8m4p5p6p1m+13',
  '4m5m6m6p8p8p8p3s4s5s6s7s8s+7p+22',
  '7m7m8m8m9m9m5p6p7p4s5s7s7s3s+23',
  '2m3m6m6m4p5p6p7p8p9p6s7s8s4m+24',
  '2m2m2m3m4m2s3s4s5s6s6s7s8s7s+12',
  '7m8m9m4p4p7p8p2s3s3s4s4s5s+9p+13',
  '1m2m3m5m5m6m6m7m6p7p8p3s3s4m+12',
  '6m7m2p3p4p4p5p5p6p6p7p1s1s+5m+13',
  '6m7m8m2p4p5p6p7p8p8p4s5s6s3p+23',
  '6m6m3p3p5p6p7p2s3s4s5s6s7s3p+21',
  '4m5m6m7m7m7m2p3p4p6s6s7z7z+7z+22',
  '2m3m4m4m5m6m8m8m1p2p3p5s6s+7s+23',
  '3m3m4m4m5m5m7m7m7p8p9p3s4s+2s+23',
  '2m3m4m8m8m2p3p3p4p5p7s8s9s1p+12',
  '5m6m7m7m8m8m8m2s3s4s4z4z4z+7m+24',
  '2m2m3m4m5m1s1s1s3s4s5z5z5z+2s+13',
  '2m3m4m7m8m9m9m9m7p8p1s2s3s9p+11',
  '2p3p3p4p4p7p7p7p8p9p6s7s8s5p+12',
  '2m3m3m4m4m5m3p4p1s1s6s7s8s+2p+12',
  '2m3m4m4p5p6p1s2s3s5s5s7s8s+6s+22',
  '3m4m5m2p2p5p5p5p5s6s6s7s7s+8s+24',
  '4m5m7m8m9m7p8p9p3s3s7s8s9s3m+21',
  '2m3m4m2p3p4p4p5p6p4s4s6s7s+8s+12',
  '2m3m4m2p3p4p4p5p3s4s5s4z4z+6p+23',
  '1m2m3m5m6m7m7m8m9m5p6p5s5s+4p+12',
  '1m2m3m1p2p3p5p5p1s2s3s1z1z+1z+24',
  '3m4m5m3p4p5p8p8p4s5s7s8s9s+6s+12',
  '5m6m7m1p2p3p4p5p6p7p8p4s4s9p+21',
  '2m3m4m1p2p3p5p5p3s4s5s7z7z7z+21',
  '4m5m6m3p4p5p2s2s3s4s7s8s9s5s+13',
  '4m5m6m7p8p2s3s4s6s6s7s8s9s+6p+24',
  '4m5m6m6m7m8m3p3p3p8p8p8s8s+8s+23',
  '4m4m1p2p3p6p7p8p4s5s6s7s8s+9s+21',
  '2m3m4m8m9m4p4p7p8p9p7s8s9s+7m+21',
  '3m4m5m6m7m8m2p2p1s2s3s4s5s+6s+24',
  '6m7m5p6p7p3s4s5s6s7s8s3z3z5m+11',
  '1p2p3p5p6p7p4s5s5s6s6s3z3z+4s+22',
  '5m5m8m8m2p2p4p4p1s6s6s2z2z1s+21',
  '3m3m5m6m7m3p5p6p6p6p6s7s8s+4p+12',
  '3m4m5m6m6m7m7m7m6p7p3s4s5s+8p+11',
  '2m3m4m3p4p5p5p6p7p7p5z5z5z+4p+11',
  '4m5m6m6p4s4s4s7s8s9s6z6z6z6p+23',
  '2m2m2m5m6m7m6p7p8p3s5s6s6s+4s+11',
  '7m7m8m8m9m3p4p4p4p5p6p7p8p+6m+14',
  '1m1m2m3m4m5m6m7m5s6s7s8s9s+7s+13',
  '1m2m3m4m5m6m7m8m9m6s7s3z3z5s+11',
  '1m2m3m4m4m5m6m6m7m7m4z4z4z8m+22',
  '9m9m3p4p5p7p8p2s3s4s6s7s8s9p+24',
  '6m7m8m5p5p7p8p9p7s8s9s1z1z+1z+11',
  '7m8m4p5p6p7p7p7p8p8p8s8s8s6m+14',
  '2m3m4m4m5m6m3p4p9p9p4s5s6s5p+11',
  '1m2m3m4m5m6m8m9m1s1s5s5s5s+7m+11',
  '8m8m8m6p6p7p8p9p7s8s9s5z5z+5z+22',
  '1m2m3m5m6m7m2p3p7p8p9p3z3z1p+22',
  '3m4m5m6m7m5s6s6s7s7s8s9s9s+8m+14',
  '7p7p8p9p1s1s2s2s3s3s5s6s7s+7p+14',
  '4m5m6m4p6p6p6p7p7p7p6s7s8s5p+13',
  '3m3m6m7m7m8m8m9m2s3s7s8s9s1s+11',
  '2m3m4m5m6m7m2p3p5s5s6s7s8s4p+23',
  '2m3m4m1p1p1p7p7p8p8p9p9p6z+6z+12',
  '2m3m2p3p4p6p7p8p7s8s9s4z4z4m+12',
  '4m5m6m4p5p5p5p6p5s5s6s7s7s+6s+13',
  '5m6m7m2s2s3s3s4s6s6s7s8s9s+1s+11',
  '6m7m8m2p2p3p4p4p4p5p6p1s1s+3p+11',
  '4m4m3p4p5p4s5s7s7s8s8s9s9s6s+21',
  '4m4m5m6m7m3p5p2s3s4s6s7s8s+4p+23',
  '6m7m8m4p5p5p6p6p7p6s7s3z3z+8s+22',
  '6m6m7m7m8m7p7p2s2s2s1z1z1z+8m+23',
  '4m5m5m5m5m3p3p3p8p8p6s6s6s+3m+11',
  '3m4m5m3p4p5p8p8p8p2s2s7s8s6s+14',
  '5m6m7m3p4p4p5p5p6p7p7p4s5s6s+14',
  '4m5m6m2p3p4p2s4s5s6s8s8s8s+2s+21',
  '5m6m1p2p3p3p4p5p3s4s5s8s8s+7m+13',
  '3m4m6m7m8m6p7p8p3s4s5s6s6s+5m+12',
  '1m9m1p9p1s9s1z2z3z4z5z6z7z+2z+13',
  '3m3m7m7m2s2s4s6s6s7s7s1z1z4s+22',
  '3m3m6p7p8p3s4s8s8s8s5z5z5z2s+12',
  '2m4m6m7m8m2p3p4p3s4s5s7s7s+3m+23',
  '1m2m3m5m6m7m3p3p6p7p1s2s3s5p+13',
  '5m6m7m1p2p3p4p5p6p3s3s5s6s4s+23',
  '5m6m7m7m8m4p5p6p5s5s5z5z5z+9m+22',
  '1m1m1p1p1p7p8p9p1s2s7s8s9s+3s+24',
  '1m1m4p5p6p2s3s4s6s7s7s8s9s+5s+23',
  '9m9m9m1p1p2p3p3p7p7p8p8p8p2p+21',
  '4m5m5m6m6m7m7p7p4s5s6s8s8s+8s+21',
  '6m7m8m1p2p3p6p7p8p3s3s7s8s+6s+24',
  '4m4m4m6m7m2p3p4p6p6p2s3s4s+5m+23',
  '3m4m3p4p5p6p7p7p7p7p8p6s6s+5m+24',
  '1p1p1p2p3p4p6s7s8s5z7z7z7z5z+21',
  '6m6m7m7m9p9p2s2s4s4s5s5s6s6s+12',
  '7m8m9m7p7p7p3s4s7s7s5z5z5z2s+21',
  '5m6m7m3p4p5p7p8p9p3s4s9s9s+5s+21',
  '2m3m4m7m7m3p4p7p8p9p2s3s4s+2p+21',
  '5m6m7m7m8m9m2p3p8p8p5s6s7s1p+21',
  '4m4m2p2p2p5p6p7p5s5s6s6s6s+4m+12',
  '4m5m6m9m9m2s3s4s7s8s9s5z5z+5z+14',
  '9m9m2p2p5p5p7p8s8s1z1z4z4z+7p+12',
  '4m4m4p5p6p7p7p7p5s5s5s6s6s+6s+12',
  '2m3m4m3p3p5p6p7p7p8p9p6s7s+8s+23',
  '6m6m5p5p9p1s1s2s2s3s3s9s9s9p+11',
  '3m3m5m5m8m3p3p5s5s6s6s7s7s+8m+21',
  '7m7m7m8p8p8p9p9p9p1s1s4z4z+4z+24',
  '5m6m8m8m3p4p5p5p6p7p6s7s8s+4m+12',
  '3m4m5m4p5p5p6p6p7p2s2s4s5s+3s+23',
  '5m6m7m3p4p5p2s2s3s3s5s6s7s+2s+21',
  '1m2m3m4m5m6m8m9m4s4s5s6s7s7m+12',
  '7m7m3p4p5p7p8p9p4s4s5s6s6s5s+22',
  '2m2m3m3m4m2p2p2s3s4s9s9s9s4m+13',
  '4m4m6m6m2p2p7p7p1s1s7s7s6z6z+22',
  '4m5m6m4p5p8p8p2s3s4s6s6s6s6p+12',
  '2m2m4m5m6m7m8m9m5s6s7s8s9s+4s+12',
  '3m3m5m6m7m2p3p4p5p6p5s6s7s4p+11',
  '5m5m1p1p2p2p3p3p3p4p5s6s7s5p+24',
  '1m1m5m5m1p4p4p5p5p1s1s8s8s+1p+13',
  '2m2m2m3m3m6m6m3p4p5p6p7p8p6m+23',
  '3m4m5m3p4p4p5p6p9p9p6s7s8s+5p+12',
  '5m6m7m5p6p7p2s3s6s6s7s8s9s+1s+23',
  '4m5m7m8m9m8p8p2s3s4s7s8s9s+3m+22',
  '4m5m6m6m7m8m3p3p7p8p3s4s5s+9p+23',
  '6m7m7m8m8m9m5p6p7p8p1z1z1z+8p+12',
  '2p3p4p4p5p6p4s5s6s9s5z5z5z9s+13',
  '5m5m3p4p5p2s3s5s5s5s7s7s7s4s+21',
  '2p3p4p5p5p6p6p7p7p6s6s7s8s+6s+21',
  '7m7m2p3p4p7p8p1s2s3s5s6s7s6p+21',
  '1m2m3m4m5m9m9m4p5p6p7s8s9s3m+13',
  '1m1m2m3m4m5m6m7m5p7p5s6s7s+6p+21',
  '3m3m3m5m5m7p7p7p2s3s5s6s7s4s+24',
  '6m7m8m2p3p4p3s4s6s7s8s1z1z+5s+24',
  '7m8m9m9m9m2p3p4p7p8p7s8s9s6p+11',
  '7m8m8m8m1s2s3s4s5s6s7s8s9s+6m+11',
  '6m7m8m9m9m2p3p4p5s6s7s6z6z+6z+12',
  '1m2m3m1p2p3p9p9p1s2s3s6s7s+5s+24',
  '4m5m6m6m6m1p2p3p7p8p9p6s7s5s+11',
  '2m3m7m8m9m5p6p7p2s2s3s4s5s+4m+13',
  '6m7m1p2p3p6p7p8p9p9p1s2s3s+8m+23',
  '4m5m6m8m8m5p6p6p7p7p2s3s4s+5p+13',
  '6m7m8m3p4p6p6p4s5s6s7s8s9s5p+23',
  '7m8m7p8p9p1s1s2s3s4s4s5s6s6m+11',
  '4m5m6m3p4p5p5p6p3s3s6s7s8s+4p+23',
  '4m5m6m7m7m1p2p3p4s4s5s5s6s3s+23',
  '3m4m1p2p3p4p5p5p5p6p2s3s4s5m+23',
  '2m3m5m6m7m2p2p3p4p5p7p8p9p+4m+11',
  '4m4m6m7m4p5p6p7p8p9p3s4s5s+8m+13',
  '1m1m4m5m6m6p7p8p6s7s8s5z5z+5z+24',
  '2m3m4m4p5p6p7p2s3s4s4s5s6s+4p+14',
  '4m5m6m7m8m3p3p4p5p6p7p8p9p+6m+12',
  '4m5m6p7p8p3s4s4s5s5s6s2z2z+3m+13',
  '1m1m2m2m3m3m5m5m2s3s2z2z2z1s+11',
  '1p2p2p3p3p4p2s3s3s4s4s5s5s5s+13',
  '3m3m5m6m7m2p4p2s2s2s3s4s5s+3p+13',
  '2m2m1p2p3p4p5p6p7p8p5s6s7s3p+11',
  '5m6m3p3p5p6p7p3s4s5s6s6s6s+7m+11',
  '1m1m2m2m2m1s2s3s4s4s6s6s6s4s+14',
  '4m4m6m7m8m4p5p3s3s4s4s5s5s3p+12',
  '8m8m2p3p4p5p6p8p8p8p2s3s4s+7p+13',
  '1m2m2m3m3m4m2p3p1s1s3s4s5s+1p+13',
  '3m3m4m5m5m2p2p3p3p3s3s8s8s4m+23',
  '4m5m6m7m8m9m6p6p3s4s5s7s8s+9s+22',
  '1m2m3m5m6m7m3p4p5s5s5s6s7s+5p+11',
  '2m3m4m2p2p3p3p4p4p6p7p4s4s5p+13',
  '1m2m3m7m7m1p2p3p1s2s4s5s6s+3s+13',
  '2m2m3m3m4m4m6m6m6m7m8s8s8s5m+13',
  '3m4m2p3p4p5p5p3s4s5s7s8s9s2m+12',
  '3m4m5m5m6m7m2p2p2p3p3p4s5s+6s+22',
  '3m3m4m5m6m6m7m6p7p8p7z7z7z5m+23',
  '6m7m8m2p3p5p6p7p4s4s4s5s6s1p+23',
  '4m4m5m6m6m7m8m2s3s4s6s7s8s+7m+23',
  '2m3m4m5m6m7m8p8p1s2s3s4s5s+6s+31',
  '3m3m6m7m8m6p7p8p1s2s3s4s5s3s+13',
  '4m4m2p3p4p6p7p8p5s6s7s8s9s7s+13',
  '2p2p3p3p4p3s3s4s5s6s8s8s8s+4p+22',
  '1m2m3m4m6m7m8m9m4p4p2s2s2s+5m+11',
  '4m4m2s3s4s5s6s6s7s8s6z6z6z1s+24',
  '2m3m4m3p4p5p5p6p7p8p6s7s8s8p+12',
  '2m3m4m6m7m1p1p2p3p4p7s8s9s5m+11',
  '2m3m1p2p3p5p6p7p2s3s4s9s9s4m+11',
  '5m5m6m6m7m7m6p7p8p4s4s7z7z7z+22',
  '3m4m5m5m6m7m2p3p4s4s7s8s9s1p+13',
  '6m7m8m4p4p5p6p7p4s4s5s6s7s4s+24',
  '1m2m2m3m3m4m4m5m6m6m3s4s5s+6m+24',
  '3m3m5m6m6m7m7m2p3p4p8p8p8p5m+23',
  '7m8m9m3p4p5p5s6s7s8s9s3z3z4s+22',
  '6m7m8m1p2p3p3p4p5p8p8p4s5s+3s+23',
  '4m5m1p2p2p3p3p4p9p9p3s4s5s6m+14',
  '4m5m6m3p3p4p5p6p6p7p3s4s5s+8p+23',
  '4m5m4p5p5p6p6p7p9p9p6s7s8s3m+21',
  '1m2m3m3m4m6m6m7m8m9m5p6p7p5m+13',
  '4m5m5m6m7m3p3p4p5p6p3s4s5s3m+12',
  '6p7p8p1s2s2s2s3s5s5s6s6s7s4s+11',
  '1m2m3m4m5m6m6p6p3s4s5s6s7s+8s+13',
  '2p2p4p4p3s3s4s4s6s6s2z6z6z+2z+22',
  '3m4m5m2p3p4p6p7p9p9p5s6s7s8p+21',
  '5p5p5p6p6p6p3s4s5s8s8s8s9s9s+12',
  '3m4m5m6m6m6m6p6p7p7p7p5s5s6p+11',
  '4m5m7m8m9m1s1s3s4s5s6s7s8s6m+11',
  '5m6m7m1s1s2s3s3s4s4s7s8s9s+2s+24',
  '2m2m7m8m2p2p2p6p7p8p2z2z2z+9m+22',
  '8m8m3p4p5p6p7p8p1s2s3s3s4s+2s+12',
  '4m5m5m6m6m7m4p5p6p4s6s7z7z+5s+21',
  '4m4m6m7m7m8m9m4p5p6p2s3s4s8m+13',
  '1m2m3m4m4m6m7m8m4s5s6s7s8s9s+11',
  '2m3m4m2p2p4p6p2s3s4s6s7s8s5p+21',
  '4s5s6s7s8s8s9s9s2z2z4z4z4z+7s+21',
  '1m2m3m7m8m9m6p6p3s4s6s7s8s2s+11',
  '3m3m5m6m7m6p7p8p2s3s4s4s5s+3s+14',
  '3m3m8m8m7p7p1s1s4s4s5z6z6z5z+13',
  '2m3m4m8m8m4p4p4p8p8p5s6s7s8m+11',
  '1m2m3m4m5m2p3p4p4p5p6p6s6s3m+14',
  '2m3m4m5p3s4s5s7s7s8s8s9s9s+5p+14',
  '1m2m3m4m5m6m7m8m2p3p4p3s3s3m+14',
  '3m4m6m7m8m1p2p3p4p4p2s3s4s+5m+23',
  '3m3m3m4m5m6m5p5p4s5s6s8s8s+8s+24',
  '1m2m3m4m5m6m8m9m2p3p4p2s2s+7m+14',
  '2m3m4m5m6m4p5p6p6s7s8s3z3z1m+14',
  '2m3m4m2p3p5p6p7p5s6s7s7s7s4p+21',
  '4m4m4m2p3p4p5p6p7p8p8p3s4s+2s+14',
  '5m6m6m6m7m1p2p3p7p8p9p6s7s+8s+23',
  '5m6m7m3p4p5p5p6p7p1s1s3s4s+2s+22',
  '2m3m3m4m4m3p3p6p6p6p5s6s7s5m+24',
  '2m3m3m4m5m5m6m7m9m9m4p5p6p+1m+11',
  '2m3m4m6m7m6p7p8p4s4s6s7s8s+8m+22',
  '4m5m6m4p5p5p6p7p9p9p1s2s3s+3p+22',
  '2m3m6m7m8m9m9m2p3p4p4s5s6s4m+23',
  '2m3m4m6m7m1p2p3p4p5p6p7p7p+8m+23',
  '2m3m4m5m5m7m8m9m6p7p8p5s6s7s+23',
  '3m4m5m6m7m2p3p4p4p4p6z6z6z5m+24',
  '2m3m4m5m6m7m6p6p2s3s5s6s7s+1s+11',
  '2m2m4p4p4p8p8p8p3s3s3s7z7z+2m+14',
  '4p5p6p3s4s6s7s8s2z2z7z7z7z+2s+11',
  '2m3m4m5m6m7m6p6p6p7p1s2s3s5p+22',
  '3m3m6m7m8m2p3p4p2s2s2s3s4s5s+13',
  '6m7m8m9m9m3s5s7s7s8s8s9s9s4s+12',
  '3m4m7m8m9m3s4s5s5s5s6s7s8s2m+12',
  '7m8m9m1p2p3p7s8s9s1z1z6z6z+6z+23',
  '6p7p8p9p9p1s2s6s7s8s6z6z6z3s+12',
  '7m7m8m8m9m9m3p3p5p6p7p4s5s+3s+12',
  '3m3m4m5m6m7m9m3p4p5p7z7z7z+8m+22',
  '5m6m7m4p5p6p2s3s4s4s6s7s7s+5s+13',
  '2m3m6m6m7m8m9m6p7p8p4s5s6s+4m+13',
  '4p5p6p6p7p8p9p9p4s5s5s6s6s+4s+21',
  '1m1m8m8m3p9p9p1s1s2s2s8s8s3p+13',
  '1m2m3m6m6m8p9p7s7s8s8s9s9s+7p+24',
  '2m3m3m4m4m5m6m7m8m9m9m6p7p+5p+23',
  '2m2m6m7m8m6s7s7s8s8s7z7z7z+9s+13',
  '3m3m2p3p4p5p6p7p8p9p2s3s4s+1p+23',
  '2m3m9p9p3s4s5s3z3z3z6z6z6z4m+22',
  '1m1m3m4m4m5m5m6m6m8m9m9m9m+7m+12',
  '3m4m7m7m5p6p7p7p8p9p2s3s4s5m+23',
  '6m6m1p1p2p2p3p3p3s4s6s7s8s+5s+13',
  '5m6m7m2p3p4p4p5p6p7p8p5s5s+3p+11',
  '2m3m4m5m5m7m8m9m3p4p3s4s5s+5p+22',
  '3m4m5m4p5p6p2s2s4s6s8s8s8s5s+12',
  '6m6m7m7m8m8m5p6p4s5s6s9s9s4p+11',
  '5m5m9m9m3p3p1s2s2s5s5s7s7s+1s+23',
  '5m5m6m6m7m3p3p6p7p8p6s7s8s4m+21',
  '2m4m1p1p5p6p7p1s2s3s1z1z1z+3m+11',
  '6m7m8m1p2p3p3p4p5p7p8p9s9s+6p+13',
  '2m3m4m6m6m1s2s1z1z1z6z6z6z3s+13',
  '7m8m9m4p5p6p2s3s6s6s7s8s9s1s+11',
  '7m8m9m7p9p1s2s3s5s5s7s8s9s8p+13',
  '4m5m6m3p4p7p8p9p3s4s5s6s6s+2p+12',
  '3m4m5m3p4p2s2s3s4s5s6s7s8s+2p+23',
  '4m5m6m5p6p7p8p9p1s2s3s4z4z7p+13',
  '4m4m5m6m7m7m8m9m5p6p7p4s5s+3s+23',
  '1p2p3p6p7p8p2s3s4s6s6s7s8s6s+11',
  '3m3m4m4m3s4s4s5s5s6s5z5z5z3m+13',
  '2m3m4m4m5m6m2p3p2s3s4s9s9s+4p+21',
  '6m7m2p3p4p6p7p8p3s4s5s7s7s5m+23',
  '1m2m3m5p6p6p7p8p9p9p3s4s5s+4p+24',
  '2m3m7m8m9m3p4p5p7p7p6s7s8s4m+21',
  '4m4m5m6m6m7m7m8m7p8p3s4s5s+9p+12',
  '1p1p2p3p4p6p7p8p4s5s6s2z2z+2z+12',
  '6m7m3p4p5p7p7p2s3s4s6s7s8s+5m+11',
  '3m3m4m4m5m5m6m6m3p3p3p2s4s+3s+21',
  '4m2p3p4p4p5p6p4s5s6s5z5z5z+4m+24',
  '1m2m3m5m6m7m1p2p3p4p5p7p7p3p+13',
  '4m4m4m5m5m9m9m9m2p2p2p4p4p4p+13',
  '6m6m2p2p3p4p4p6p7p8p5s6s7s3p+11',
  '4m5m6m6p7p8p1s2s3s7s8s8s8s9s+13',
  '3m3m7m7m9m9m5s8s8s6z6z7z7z5s+23',
  '3m3m3m6m7m8m2p3p4p7p7p6s6s+6s+11',
  '1p2p2p3p3p6p7p8p4s5s6s2z2z4p+11',
  '1m2m3m4m5m6m7m8m3p3p2s3s4s9m+23',
  '3m4m5m5m5m6m6m7m7m9p9p6s7s8s+23',
  '6m7m8m2p2p3s3s3s5s6s6s7s8s4s+22',
  '2m3m4m7m8m9m4p7p8p9p7s8s9s+4p+13',
  '1m1m6m7m5p6p7p4s5s6s7s8s9s8m+12',
  '3m4m5m1p2p3p5p5p6s7s5z5z5z+5s+14',
  '3m4m5m6m7m8m1p2p3p8p8p6s7s+5s+14',
  '3m3m3m4m5m7m8m9m4p5p3s4s5s+6p+24',
  '2m3m3p3p4p5p6p7p7p8p8p9p9p4m+22',
  '2m3m5m6m7m9m9m4p5p6p4s5s6s1m+24',
  '6m7m8m3p4p4p5p6p3s4s5s7s7s+2p+22',
  '1m2m3m6m7m2p3p4p4p4p6s7s8s+5m+21',
  '8m8m5p6p7p2s2s2s5s5s5s6s7s+8s+13',
  '4m4m6m7m1p2p3p3s3s4s4s5s5s+5m+14',
  '1m2m3m5m6m9p9p5s6s6s7s7s8s+4m+13',
  '2m2m3m3m9m9m1p1p4p4p3s3s1z+1z+14',
  '7m7m8m8m9m9m3p3p5p6p3s4s5s4p+11',
  '1m2m3m4p5p5p6p6p5s5s7s8s9s+4p+14',
  '3m3m4m4m5m5m2p3p4p3s4s5s6z+6z+14',
  '3m4m4m5m5m6m7m8m5s6s7s4z4z+9m+23',
  '3m4m5m2p2p5p6p6p7p8p4s5s6s4p+12',
  '1m2m3m1p2p3p5p6p7p6s7s8s8s+8s+21',
  '7m7m8p8p9p9p1s1s1z1z3z3z7z+7z+22',
  '6m7m8m4p5p6p8p2s3s4s6s7s8s8p+12',
  '2m2m2p4p4p5p6p4s5s6s6s7s8s+3p+11',
  '5m6m7m2p3p6p7p8p9p9p6s7s8s4p+11',
  '2p3p4p6p6p6p2s3s4s8s8s6z6z6z+23',
  '2m3m4m4p5p6p7p8p2s3s4s8s8s6p+31',
  '3m4m5m2p3p4p4p5p5s5s7s8s9s+6p+22',
  '2m2m4m5m3p4p5p3s3s3s4s5s6s+3m+21',
  '4m5m4p5p6p2s2s2s3s4s4s5s6s3m+12',
  '1m2m3m4m5m6m2p3p4p3s4s5s5s2s+14',
  '5m6m7m1p2p3p5p6p7p2s3s4z4z+1s+22',
  '4m4m5p5p7p7p1s1s5s5s4z7z7z+4z+23',
  '4m4m2p3p4p6p7p8p4s5s6s7s8s+9s+11',
  '3m4m5m6m7m8m6p7p8p4s5s7s7s6s+11',
  '4m4m7m7m1p1p8p8p5s5s6s6s5z+5z+11',
  '2m2m2m3m4m5m5m6m3s4s5s6s6s7m+22',
  '5m6m7m8m8m2p3p4p5p6p7p2s4s3s+21',
  '1m2m3m5m6m8p8p3s4s5s6s7s8s4m+24',
  '3m3m7m7m9m9m2p2p3p3p6s6s7s+7s+23',
  '2m2m4m5m1s2s2s3s3s4s6s7s8s+3m+22',
  '2m4m9m9m2p3p4p2s3s4s5s6s7s3m+24',
  '1m1m4p4p5p5p6p6p3s4s4s5s5s+3s+22',
  '6m6m3p4p5p6p6p3s4s5s6s7s8s+6p+13',
  '3m3m1p1p2p2p1s3s3s4z4z7z7z+1s+11',
  '3m3m7m8m9m1p2p3p6p7p8p7s8s+6s+24',
  '6m7m5p5p6p7p8p3s4s5s5s6s7s8m+23',
  '2m3m4m8m8m4p4p5p5p6p1s2s3s+6p+13',
  '3m3m7m8m9m5p6p7p1s2s3s3s4s+2s+24',
  '4m5m6m4p4p2s3s4s4s6s6z6z6z+5s+13',
  '7m8m9m2p3p4p4p5p5s6s7s3z3z+6p+12',
  '2m3m3m4m5m8m8m4p5p6p5s6s7s+1m+23',
  '3m4m5m7m8m9m1p1p6s6s6s1z1z+1z+11',
  '5m6m7m2p3p4p4p5p6p2s2s7s8s6s+24',
  '1m2m3m4p4p6p7p1s2s3s5s6s7s8p+14',
  '5m6m6m7m7m8m9p9p2s3s7s8s9s1s+14',
  '2m2m3m4m5m6m7m8m1p2p3p3s4s+5s+11',
  '1m1m7m8m9m3p4p4p5p5p1s2s3s+6p+23',
  '1m2m3m4m5m5m6m6m3p4p5p8p8p+4m+23',
  '5m6m7m2p2p2p5p6p3s4s5s7s7s4p+11',
  '1m2m3m4m5m6m7m8m9m4p4p2s4s+3s+23',
  '3p3p3p5p6p7p8p8p3s4s6s7s8s+2s+14',
  '2m2m5m6m7m3p4p5p3s4s5s6s7s+8s+23',
  '1m1m2m3m3m4p5p6p8p8p2s3s4s+2m+14',
  '4m5m6m7m8m6p6p7p8p9p1s2s3s+9m+13',
  '1m2m3m1p3p5p5p1s2s2s3s3s4s2p+24',
  '1m1m2m2m3m3m5p5p7p9p5s6s7s+8p+22',
  '5m5m7m8m9m4p5p6p1s2s3s5s6s4s+12',
  '5m5m2p3p6p7p8p3s4s5s6s6s6s4p+11',
  '6m6m8m8m5p5p7p7p4s4s5s5s8s+8s+12',
  '2p2p3p3p4p4p5p6p6s6s7s8s9s+7p+12',
  '1m2m3m4m5m1p2p3p4p5p6p6s6s6m+11',
  '1m2m3m6m6m8m9m7p8p9p7s8s9s+7m+22',
  '1m2m3m3m4m2p3p4p1s2s3s4s4s+2m+23',
  '3m4m5m5p6p7p8p8p3s4s5s6s7s+8s+21',
  '1m1m8m8m9m9m7p7p6s6s1z7z7z+1z+24',
  '2m3m7p8p9p2s3s3s4s4s5s7s7s+1m+23',
  '7m8m9m3p3p4p4p5p6s6s6s7s8s+2p+13',
  '4m5m5m6m6m7m4p4p4p5p6p3s4s+5s+24',
  '7m7m3p4p5p6p7p8p3s4s5s6s7s+2s+23',
  '2m3m6m7m8m4p5p6p4s4s5s6s7s+1m+13',
  '6m7m7m8m8m9m3p3p2s3s4s5z5z+5z+14',
  '2m3m4m6m6m7p8p2s3s4s5z5z5z+9p+23',
  '3p3p5p5p6p6p1s5s5s6s6s7z7z1s+13',
  '2m2m8m8m9m9m6s6s9s9s4z4z7z+7z+24',
  '6m7m8m9m9m2p3p4p7p8p3s4s5s+9p+24',
  '1m2m3m5m6m6m7m7m9p9p1s2s3s+8m+24',
  '3m4m5m7m8m9m9m5p6p7p6s7s8s+9m+12',
  '3m4m5m6m7m5p5p6p7p8p5z5z5z2m+22',
  '5m6m7m8m9m1s2s3s4s5s6s7s7s7m+11',
  '4m5m6m6p7p8p1s1s3s4s4s5s5s+6s+13',
  '4m5m6m7m8m2p3p4p5p5p5p6p7p+9m+12',
  '1m2m3m3p4p7p7p6s7s8s7z7z7z5p+12',
  '2m2m2m4p5p6p7p7p3s4s5s5s6s7s+11',
  '2m3m4m5m6m6m7m7m8m4p4p7p8p+6p+11',
  '3m4m5m3p3p4p5p6p7p7p6s7s8s+3p+11',
  '4m5m6m1p2p3p5p6p7p9p6z6z6z+9p+13',
  '1m2m3m7m8m9m1p1p3p4p7p8p9p+2p+21',
  '4m4m5m5m3p3p6p6p3s4s4s6z6z+3s+22',
  '5m6m7m9m9m3p4p6s7s7s8s8s9s2p+11',
  '7m8m9m2p3p4p7p8p9p9p2s3s4s9p+11',
  '7m8m9m1p2p3p9p9p1s1s9s9s9s+9p+22',
  '5m6m6p7p8p8p8p2s3s4s5s6s7s4m+11',
  '4m5m5m6m7m2p3p4p6s7s8s2z2z3m+14',
  '7m8m9m1p1p6p7p8p3s4s5s7z7z+7z+13',
  '1p2p3p4p5p6p7p8p9p1s3s1z1z+2s+22',
  '5m6m7m8m1s2s3s3s4s5s5z5z5z5m+13',
  '7m8m9m2p3p4p5p6p8p8p4s5s6s4p+13',
  '2m3m4m2p3p4p8p8p4s5s6s7s8s3s+13',
  '4m5m1p2p3p5p6p7p2s3s4s7s7s6m+24',
  '2m3m4m2p2p4p5p6p5s6s7s4z4z4z+14',
  '3p4p5p6p7p8p3s4s4s5s5s7s7s+3s+23',
  '5m6m4p5p5p6p6p7p5s6s7s4z4z+4m+21',
  '2m3m3m4m4m4p5p5p6p6p7p8s8s+2m+14',
  '4m4m4m5m1p2p3p6p7p8p6s7s8s6m+23',
  '2s3s4s7s7s8s9s9s5z5z6z6z6z+8s+13',
  '4m4m2p3p4p5p6p7p7p8p9p6s7s8s+22',
  '4m4m6m6m7m7m9p9p4s7s7s9s9s4s+22',
  '1m2m3m4m5m7m8m9m3p3p5s5s5s6m+21',
  '1m2m3m7m7m7m8m3s4s5s6z6z6z8m+11',
  '3m4m6m7m8m3p4p5p6p7p8p1z1z+2m+24',
  '2m3m4m6m7m4s4s4s5s6s7s8s9s5m+22',
  '1m1m2m3m3m3m4m5m1p2p3p4s4s2m+22',
  '4m5m6m3p3p4p4p3s4s5s6s7s8s+4p+11',
  '6m7m8m3p3p4p4p5p5p4s4s5s6s+4s+24',
  '4m5m6m7m7m3p4p6p7p8p2s3s4s5p+22',
  '1m2m3m7m8m8p8p1s2s3s5s6s7s+6m+12',
  '6m6m8m8m5p5p7p2s2s3s3s6z6z+7p+23',
  '4m4m5m5m6m6m7m8m9m2p4p5p5p+3p+14',
  '8m8m4p5p6p4s4s5s5s5s6s7s8s+4s+12',
  '3m4m5m3p4p5p6p7p1s1s3s4s5s+2p+34',
  '2m3m4m5m5m7m8m5s6s7s7s8s9s6m+14',
  '5m5m5p5p6p6p7p7p1s2s3s3s5s4s+21',
  '4m5m6m7m8m9m2p3p7p7p1s2s3s1p+23',
  '6p6p7p7p3s4s5s8s8s8s9s9s9s7p+14',
  '4m5m6m7m8m1p2p3p7p7p7s8s9s+9m+14',
  '1m2m3m4m4m5m6m6m7m7m1z1z1z+5m+14',
  '4m5m3p4p5p7p8p9p4s5s6s8s8s6m+12',
  '1m1m4m4m7m7m5p9p9p6s6s8s8s5p+11',
  '6m7m8m1p1p3p3p3p4p5p1z1z1z+3p+11',
  '4m5m6m7m8m1s2s3s7s8s9s4z4z+9m+21',
  '4m5m6m2p3p7p8p9p3s4s5s6s6s1p+24',
  '2m3m4m4m5m6m5p7p3s3s6s7s8s6p+23',
  '1m6m6m8m8m1p1p2s2s5s5s6s6s+1m+14',
  '4m5m6m6p7p8p6s7s7s8s8s2z2z6s+21',
  '1m2m3m3m4m5m6m6m7p8p9p5s6s+4s+14',
  '5m6m7m1p2p3p6p7p3s4s5s6s6s+8p+12',
  '7m8m9m3p4p5p8p9p7s8s9s5z5z7p+21',
  '2m3m4m7m8m9m4p5p6p8p8p7s8s+9s+11',
  '4m5m3p4p5p6p6p6p2s3s4s5s5s3m+11',
  '1p2p3p4p6p7p8p9p1z1z5z5z5z+5p+14',
  '6m7m8m7p8p9p4s4s6s7s8s2z2z+2z+12',
  '2m3m4m2p2p2p5p6p7p2s3s6s6s4s+24',
  '3m4m7p8p9p2s3s4s4s5s6s7s7s5m+13',
  '6m7m8m4p4p5p6p7p7p8p9p3s4s+2s+21',
  '8m8m4p5p6p7p8p3s4s5s7s8s9s9p+11',
  '4m3p4p5p6p7p8p4s5s6s6s7s8s+4m+12',
  '2m3m4m7m8m2p2p4p5p6p3s4s5s+9m+24',
  '1m2m3m1p1p2p3p4p6p7p8p3s4s+2s+13',
  '3m4m4m5m6m6m7m8m9p9p1s2s3s2m+11',
  '3m4m5m8m2p3p4p4p5p6p6s7s8s8m+21',
  '2m3m4m5m5m2p3p4p7p8p9p2s3s4s+12',
  '3m3m1p2p3p6p7p7p8p8p6s7s8s6p+23',
  '4m5m5m6m6m3p3p5p6p7p1s2s3s+4m+14',
  '1p1p2p3p3p4p4p5p2s3s4s6z6z6z+22',
  '2m2m5m6m7m3p4p5p4s4s5s6s7s4s+13',
  '3m4m8p8p3s3s3s4s5s6s6z6z6z+5m+14',
  '3m4m7m7m8m8m8m2p3p4p5p6p7p+2m+11',
  '3m4m5m7m8m8m8m9m7p8p2s3s4s6p+13',
  '8m8m2p2p1s1s4s4s8s8s6z6z7z+7z+11',
  '4m5m7m8m9m4p4p5p6p7p3s4s5s+3m+23',
  '3m3m4m5m6m4p5p5p6p6p7p5s6s7s+14',
  '1p2p3p4p5p6p3s4s4s5s6s7s7s5s+12',
  '1m2m3m7m7m7p8p1s2s3s5s6s7s9p+11',
  '6m7m8m8m2p3p4p5p6p7p1s2s3s5m+11',
  '1m2m2m3m3m4m5m6m7m6s7s9s9s5s+11',
  '4m4m5p6p7p7p8p9p5s6s7s8s9s4s+21',
  '3m4m5m7m8m9m3s3s5s6s7s8s9s7s+11',
  '1m2m3m6p7p8p6s7s5z5z6z6z6z5s+23',
  '2m2m1p2p3p1s2s3s4s5s6s7s9s8s+12',
  '4m5m6m7m8m9m5p6p7p8p9p9p9p4p+14',
  '6m7m8m8m8m2p3p4p6p7p8p5s7s+6s+11',
  '3m4m5m2p3p5p6p7p4s5s6s3z3z+1p+12',
  '2m3m4m5m5m5m9m9m3p3p6z6z6z+3p+23',
  '3m3m2p2p2s2s3s3s5s5s9s9s5z+5z+14',
  '1p1p2p3p5p6p7p7p8p9p6s7s8s4p+12',
  '4m5m5m6m7m8m8m5p6p7p5s5s5s+6m+24',
  '3m3m4m4m7m7m7s7s8s8s9s9s7z7z+13',
  '4m4m5m6m7m3p4p5p6p8p5s6s7s+7p+14',
  '3m4m5m7m8m9m1s1s2s3s3s4s5s1s+22',
  '2m3m4m3p4p5p4s5s7s8s9s3z3z+6s+21',
  '4m4m5m6m7m5p6p7p1s1s5s6s7s+1s+12',
  '2m3m4m1p1p2p3p3p4p4p5s6s7s2p+11',
  '1m2m3m3m4m3p4p5p3s4s5s6s6s+2m+12',
  '1m1m2m6m6m7m7m7p7p3s3s3z3z2m+22',
  '5m6m7m2s3s4s5s5s6s7s7s8s8s+9s+23',
  '3m3m7p7p9p9p2s2s5s5s1z1z5z+5z+14',
  '3p4p6p7p7p7p8p3s4s5s7s7s7s+5p+24',
  '3m4m5m7m7m3p4p3s4s5s6s7s8s2p+13',
  '2m3m4m2p3p3p4p4p5p6p7p7s7s8p+14',
  '6m7m7m8m9m4p5p5p6p6p7p6s6s8m+12',
  '3m4m4m5m5m6m6m7m3p4p5p3z3z+8m+12',
  '7m8m3p3p4p4p4p3s4s5s6s7s8s+6m+24',
  '7m8m2p2p5p5p5p4s5s6s6s7s8s6m+14',
  '3m4m5m3p4p5p6p7p8p3s3s6s6s+6s+21',
  '3m4m5m4p4p7p8p1s2s3s4s5s6s+9p+21',
  '2m2m3m3m5m5m7m7m5p6p6p7p7p+5p+24',
  '4m5m6m7m8m9m2s3s4s4s5s7s7s3s+23',
  '2m3m4m2p3p4p5p6p7p8p3s4s5s8p+12',
  '2m3m3m3m4m3p4p6p7p8p4s5s6s+2p+23',
  '5m6m7m4p4p2s3s4s5s6s7s8s9s+1s+21',
  '7m7m7m7p8p6s7s8s4z4z5z5z5z+9p+14',
  '1m2m3m5m6m1p1p3p4p5p7p8p9p+7m+24',
  '2m3m4m6m8m2p3p4p5p5p4s5s6s7m+11',
  '5m6m7m7m8m9m2p3p4p1s1s6s7s5s+21',
  '4m5m7m8m9m4p5p6p7p7p1s2s3s+3m+14',
  '1p1p2p3p4p2s2s3s3s4s4s7s8s+6s+22',
  '2m3m4m2p3p4p2s2s1z1z3z3z3z+1z+12',
  '5m5m2p2p3p3p5p5p8s8s9s9s1z+1z+22',
  '1m2m3m7m7m7p8p4s5s5s6s6s7s6p+14',
  '5p5p6p6p7p7p8p8p4s5s6s7s8s9s+21',
  '1m2m3m4m5m6m1p2p3p7p7p3s4s+2s+13',
  '2m4m2p3p4p6p7p8p1s1s2s3s4s+3m+21',
  '1m2m3m1p2p3p5s6s7s7s7s8s9s+4s+21',
  '3m4m4m5m6m6m7m8m6p6p3s4s5s+2m+12',
  '5m6m7m8m8m6p7p8p2s3s4s5s6s7s+13',
  '2m3m4m3p3p3p4p5p6p8s8s4z4z+4z+14',
  '4m6m6m7m8m8p8p8p4s5s6s7s7s5m+13',
  '1m2m3m4m5m6m7m8m9m6p7p7s7s5p+11',
  '2m3m7m8m9m7p8p9p2s2s5z5z5z1m+12',
  '4m4m6p7p8p3s3s4s4s5s5z5z5z5s+23',
  '9m9m2p2p3p3p3s3s5s5s9s5z5z+9s+23',
  '1m3m1p2p3p5p5p5p8p8p1s2s3s+2m+12',
  '3m4m5m6m7m3p4p5p8p8p3s4s5s5m+14',
  '5m6m7m2p3p4p5p5p3s4s5s6s7s8s+23',
  '2m3m4m6m6m7m8m9m2p3p4p4s5s+6s+22',
  '1m2m3m4m5m6m7m8m9m9p6s7s8s+9p+14',
  '3m3m5m6m6m7m8m3p4p5p7s8s9s+7m+14',
  '2m3m1p2p3p4p5p6p2s3s4s2z2z1m+11',
  '1m1m2m2m3m3m9m9m1p2p3p1s2s3s+21',
  '4m5m4p4p5p5p5p6p7p8p2s3s4s3m+12',
  '1m2m3m6m7m7m8m9m6p7p8p8s8s8m+23',
  '5m6m7m3s3s5s6s7s9s9s9s3z3z+3z+23',
  '1m2m2m3m3m4m5s6s7s1z1z2z2z+1z+12',
  '1m1m3p3p9p9p5s6s6s7s7s8s8s+5s+13',
  '3m4m5m7m8m9m6p6p4s5s6s7s8s9s+12',
  '7m8m9m3p4p5p5s6s7s8s9s3z3z+7s+14',
  '1m2m3m4m4m6m7m8m3p4p4p5p6p+2p+11',
  '2m3m4m4m5m6m2p3p4p1s1s6s7s8s+11',
  '4p4p5p5p6p6p2s2s4s5s7s8s9s6s+21',
  '5m5m5p6p6p7p8p6s7s8s5z5z5z+4p+13',
  '2p3p4p6p7p8p1s1s2s3s4s4s5s+3s+21',
  '1m1m2m3m4m3p4p3s4s5s7s8s9s2p+24',
  '1m2m3m1p2p3p2s3s6s6s7s8s9s+4s+13',
  '4m5m6m7m8m9m1p1p3p4p4s5s6s+2p+21',
  '1m1m4m5m6m7m8m1p2p3p7s8s9s6m+21',
  '3m4m5m8m8m3p4p4p5p6p5s6s7s+5p+13',
  '3m4m5m5m6m7m6p7p8p4z4z5z5z+5z+12',
  '3m4m5m7m8m9m6p7p1s2s3s5s5s5p+22',
  '6m7m8m4p4p6p7p8p1s2s3s4s5s+6s+22',
  '5m6m9m9m1p2p3p5p6p7p5s6s7s4m+23',
  '2m3m4m5m6m7m2p3p4p2s4s5s5s3s+13',
  '2m3m4m7m8m9m9m6p7p8p6s7s8s+6m+11',
  '4m5m7m8m9m5p6p7p2s2s4s5s6s3m+13',
  '5m6m1p1p5p6p7p2s3s4s6s7s8s4m+23',
  '5m6m7m3p4p5p2s2s6s6s7s7s7s+6s+11',
  '4m5m6m8m8m2p3p4s5s6s7s8s9s+4p+21',
  '2m3m4m5m6m7m7m8m9m5z5z6z6z+5z+23',
  '1p1p1p9p9p9p1s1s1s8s8s6z6z6z+11',
  '6m7m8m2p3p4p5p6p7p8p9p4s4s1p+12',
  '3m3m6m7m8m1p1p2p2p3p3p4p5p+6p+13',
  '2m2m2m2m3m4m3p4p5p4s4s4s6s6s+11',
  '2m3m7m8m9m5p5p1s2s3s5s6s7s4m+12',
  '3p3p3p4p5p6p6p6p2z2z4z4z4z2z+24',
  '2m2m3m4m5m5p5p5p2s3s4s4s6s5s+11',
  '2m3m3p3p6p7p8p1s2s3s5s6s7s1m+21',
  '1m2m3m7p8p9p9p4s5s6s7s8s9s+6p+22',
  '3m4m5m7m8m2p2p4p5p6p6s7s8s+9m+11',
  '7m8m9m3p4p5p6p7p5s6s7s2z2z+8p+11',
  '6m7m8m4p5p6p4s5s7s7s7z7z7z+3s+22',
  '6m7m8m2p2p7s9s3z3z3z4z4z4z+8s+24',
  '2m3m4m5m6m7m6p7p8p5s5s6s7s+5s+13',
  '5m5m6m6m7m5p6p7p3s4s5s3z3z+4m+11',
  '6m7m7m8m8m9m7p8p9p1s1s7s9s8s+24',
  '4m5m6m7p8p9p3s3s4s5s7s8s9s+6s+23',
  '6m6m3p4p5p2s3s4s5z5z5z7z7z7z+11',
  '4m4m6m6m7m8m8m5p5p4s4s2z2z+7m+22',
  '2m2m3m4m1p2p3p4p5p6p4s5s6s5m+13',
  '3m4m5m7m8m9m2p2p6p7p5s6s7s+8p+14',
  '4m5m6m4p4p1s2s3s5s6s7s8s9s+4s+21',
  '1m2m3m5m6m7m2p3p4p6s7s2z2z5s+13',
  '1p1p2p3p5p6p6p7p7p8p2s3s4s+1p+22',
  '2m2m3m3m4m4m6m7m8m2s2s5s5s+2s+11',
  '3m4m5m1p2p3p5p6p7p1s1s4s5s+6s+22',
  '2m2m2m1p1p5p6p6p7p7p7s8s9s5p+11',
  '2m3m4m4p5p6p3s3s3s4s6s7s8s5s+23',
  '2m2m6p7p8p2s2s3s4s5s6s7s8s+2m+12',
  '5m6m7m1p2p3p4p5p4s4s7s8s9s+6p+23',
  '3p4p5p9p9p1s2s4s5s6s7s8s9s3s+12',
  '2m4m2p3p4p6p7p8p4s4s4s5s6s+3m+21',
  '6m6m6m2p2p2p4p5p6p7p7p6s7s+8s+21',
  '5m5m1p1p9p9p7s7s2z4z4z7z7z+2z+12',
  '6m7m8m5p6p7p1s2s3s5s6s8s8s+7s+21',
  '9m1p1p5p5p8p8p4s4s5s5s6s6s+9m+11',
  '5m6m7m3p4p1s2s3s5s6s7s9s9s2p+11',
  '2m3m4m6m7m4p5p6p2s2s3s4s5s+8m+24',
  '9p9p9p3s4s5s5s5s6s7s7s7z7z+6s+22',
  '4m4m4m6m7m3p3p3p6p6p1z1z1z5m+23',
  '5m6m7m4p5p4s4s5s5s6s6s7s7s+6p+14',
  '2m3m3m4m5m2p3p4p7p7p4s5s6s+1m+22',
  '6m7m8m3p4p3s4s5s5s6s7s3z3z+2p+24',
  '4m5m7m7m4p5p6p4s5s6s6s7s8s+3m+12',
  '5m6m7m8m8m8m3p4p5p5p5p5s6s4s+13',
  '1m9m1p9p1s9s9s1z2z4z5z6z7z+3z+12',
  '1m2m3m5m6m7m3p3p1s2s3s4s5s+6s+11',
  '2m4m4m5m6m2p2p4p5p6p5s6s7s+3m+24',
  '3m3m6m7m8m1s2s3s3s4s7s8s9s+5s+12',
  '3m4m7m8m9m1p2p3p7p7p3s4s5s2m+13',
  '2m3m5m6m7m9m9m3s4s5s7s8s9s4m+11',
  '1m1m2m2m3m2p3p4p2s2s3s4s5s3m+12',
  '4m5m6m4p5p6p8p8p3s4s5s5s6s+4s+24',
  '2p3p2s2s3s4s5s6s7s8s1z1z1z+1p+12',
  '3m4m5m6p7p8p2s2s2s3s5z5z5z4s+21',
  '5m6m7m2p3p4p5p6p7p6s7s2z2z5s+14',
  '6m6m2p3p4p7p9p3s4s5s7z7z7z8p+21',
  '1m2m3m5m6m7p8p9p9p9p5s6s7s7m+13',
  '4m4m6m6m5p5p5s5s6s6s8s8s7z+7z+21',
  '4m5m6m7m8m7p8p9p4s5s6s9s9s6m+12',
  '4m5m6m7m8m9m2s3s4s8s8s6z6z6z+23',
  '1m2m3m7m8m8m9m9m7p8p9p9s9s+7m+13',
  '6m7m8m9m9m4p5p5p6p6p7s8s9s7p+14',
  '2m3m7m8m9m9m9m7p8p9p6s7s8s+4m+24',
  '3m4m5m3p4p5p6p7p7p8p9p7s7s2p+14',
  '3m4m6m7m8m2p3p4p5p5p5p8s8s+5m+14',
  '7p8p9p4s4s5s5s6s6s7s7s2z2z+4s+21',
  '3m4m5m6p6p7p8p9p2s3s4s7s8s9s+14',
  '6m7m8m4p5p6p2s3s4s5s6s7s7s1s+23',
  '5m6m7m2p3p4p5p5p5p6p5s6s7s+6p+13',
  '1m2m3m5m5m2p3p4p5p6p3s4s5s7p+22',
  '2m3m4m2p3p4p6p7p7p8p9p3s3s+5p+23',
  '1m2m3m5m6m1p2p3p4p4p3s4s5s4m+13',
  '3m3m3p3p4p4p7s7s8s1z1z5z5z+8s+12',
  '6m6m7m7m1s4s4s8s8s9s9s3z3z1s+22',
  '4m4m5m7m4p5p6p2s3s4s7s7s7s+6m+11',
  '2m2m3m3m3m2p3p4p2z2z6z6z6z2z+13',
  '4m5m1p2p3p6p6p3s4s5s5s6s7s+6m+11',
  '3m4m5m6m7m5p6p7p5s6s7s3z3z2m+24',
  '1m1m1m2m3m1p1p2p2p3p3p8s9s+7s+24',
  '8p8p8p1s2s3s4s5s7s8s9s4z4z+6s+12',
  '2m2m6m7m3p4p5p2s3s4s7s8s9s+8m+12',
  '3m4m5m5p6p7p7p8p3s4s5s6s6s+9p+11',
  '7m8m9m2p2p3p4p5p7p8p9p6s7s+8s+11',
  '2p3p4p4p5p6p7p1s2s3s4s5s6s1p+23',
  '5m6m7m3p4p8p8p3s4s5s7s8s9s2p+11',
  '3m4m6m6m6m8m8m3p4p5p4s5s6s+2m+23',
  '3m4m5m5m6m2p3p4p6s7s8s4z4z4m+23',
  '3m4m5m5m5m6m7m4p5p6p4s5s6s+2m+22',
  '1m2m3m6p7p8p1s2s2s3s3s7s7s+4s+22',
  '1m3m4m5m3p4p5p3s4s5s6s7s8s+1m+14',
  '5m5m6m7m8m6p6p8p8p8p4s5s6s6p+13',
  '3m3m5m6m7m1p2p3p6p7p2s3s4s+5p+12',
  '2m3m3m3m4m6m7m8m5p5p5p4s6s5s+21',
  '3m4m5m7m8m9m3p4p5p5p6p7p8p+2p+22',
  '1m1m7m7m9m9m3s3s4s4s6s6s2z2z+21',
  '3m3m3p4p6p7p8p1s2s3s6s7s8s+2p+14',
  '1m2m3m7m8m1p2p3p5p5p4s5s6s+6m+23',
  '2m3m4m2p4p1s1s2s3s4s7s8s9s+3p+22',
  '5m6m7m8m9m7p7p2s3s4s6s7s8s+4m+12',
  '3m4m5m2p2p6p7p4s4s5s5s6s6s+8p+21',
  '7m8m9m3p4p5p6p7p1s2s2s2s3s+5p+12',
  '1p1p3p3p4p4p5p6p6p6p4z4z4z+5p+12',
  '1m2m3m5m6m7m3p3p1s2s2s3s3s+1s+13',
  '2m3m4m2p3p4p7p8p3s4s5s1z1z6p+22',
  '2m3m4m5m6m5p6p7p2s3s4s5s5s+7m+13',
  '5m6m7m3p4p5p6p6p7p8p8p1s1s7p+11',
  '5m6m7m8m8m8m5p5p5s5s6s6s7s4s+12',
  '5m6m7m8m9m3p4p5p3s4s5s6s6s+4m+12',
  '2m3m4m3p3p4p5p6p5s5s6s6s7s+7s+12',
  '2m3m4m6p7p3s4s5s6s7s8s8s8s+5p+14',
  '2m3m3m4m5m2p3p4p6p6p2s3s4s1m+12',
  '2m3m4m5m6m7m3p4p5p4s4s5s5s5s+22',
  '2m2m4m4m4m6m7m3p4p5p6p6p6p+8m+23',
  '4m5m6m4p5p6p3s3s3s4s4s5s5s6s+23',
  '6m7m8m6p7p1s2s3s6s7s8s9s9s+8p+21',
  '7m8m9m2p3p3p4p4p9p9p5s6s7s2p+12',
  '4m5m6m3p3p5p6p4s5s6s8s8s8s+7p+22',
  '4m5m5m5m6m5p6p7p3s5s6s7s8s+4s+21',
  '4m5m6m3p3p7p8p9p6s7s8s2z2z+2z+12',
  '5m5m7m7m9m3p3p8p8p2s2s5z5z9m+12',
  '3m4m4m5m5m7m7m8m8m1p1p4s4s+3m+12',
  '3p4p4p5p5p6p3s4s5s5s6s8s8s+7s+12',
  '3m4m5m2p3p5p6p7p9p9p6s7s8s4p+12',
  '6m7m2s2s2s3s4s4s5s5s6s8s8s5m+11',
  '3m4m5m7m8m2p3p4p4p5p6p5s5s+9m+11',
  '3m4m5m7p2s2s3s3s4s4s7s8s9s+7p+23',
  '2m3m6m7m8m5p5p3s4s4s5s5s6s+4m+23',
  '2m3m4m6m7m2s2s3s4s5s5s6s7s8m+22',
  '7m8m9m1p2p3p3p4p3s4s5s6s6s+2p+23',
  '1m2m3m6m7m8m1p2p3p5p5p1s2s+3s+12',
  '2m3m4m1p2p3p5p5p6p6p7p7z7z7p+22',
  '1p2p3p5p6p2s3s4s6z6z6z7z7z+7p+22',
  '3m3m7m8m9m1p2p3p5p6p7p3s4s+2s+21',
  '5m6m7m5p6p7p9p9p2s3s4s5s7s6s+11',
  '1m2m3m7m8m3p4p5p1s1s4s5s6s9m+14',
  '6p7p7p8p8p6s7s8s1z1z1z6z6z+6p+12',
  '1m2m3m4m5m4p5p6p6s7s8s4z4z6m+12',
  '6m6m4p5p6p3s4s4s5s6s7s8s9s+2s+11',
  '6m7m8m8m5p6p7p3s4s5s8s8s8s+5m+12',
  '1m2m3m2p3p3p4p5p9p9p3s4s5s4p+13',
  '2m3m4m3p3p3p4p5p6p8p2s3s4s+8p+23',
  '3m4m5m2p2p2p7p7p2s3s4s6s7s8s+13',
  '5m5m6m7m7m8m8m9m5p6p4s5s6s7p+11',
  '3m5m5m5m6m7m8m2s2s2s6s7s8s+4m+21',
  '2m2m3m4m5m5m6m3s4s5s6s7s8s7m+14',
  '6m6m6m4p5p6p2s2s3s4s6s7s8s5s+12',
  '3m4m5m6m7m8m4p5p2s2s2s4s4s3p+14',
  '5p5p6p6p7p4s5s5s6s6s7s8s8s+7p+14',
  '3p3p4p4p5p5p5s6s7s8s9s1z1z4s+24',
  '5m5m6m7m2p3p4p6p7p8p3s3s3s+5m+21',
  '2m3m4m6m7m8m6p6p4s5s5s6s6s+7s+13',
  '5p5p6p6p7p2s2s4s5s6s6s7s8s4p+14',
  '2m2m7m7m7m2p3p4p6s6s7s8s8s+7s+24',
  '6m7m8m4p5p6p2s2s3s4s5s5s6s+7s+12',
  '2m4m5m6m7m8m8m5p6p7p4s5s6s+3m+24',
  '4m5m5m6m6m7m8m9m3s3s6s7s8s+7m+23',
  '6m6m7m7m8m8m5p6p7p7s8s4z4z9s+11',
  '3m4m5m5p6p7p1s2s3s4s5s6s6s3s+11',
  '4m5m6m1p2p3p4p5p6p1s1s3s4s2s+12',
  '4m5m6m4p5p6p7p8p9p4s6s4z4z5s+11',
  '3m4m5m2p2p6p7p2s3s4s7s7s7s+8p+23',
  '4m4m4m8m8m8m2p3p7p7p4s5s6s+4p+21',
  '9m9m1p1p2p2p2s2s4s4s9s9s4z+4z+21',
  '4m5m6m3p4p5p6p7p7p7p8p2s4s+3s+13',
  '6m6m3p3p4p4p5p1s2s3s7s8s9s+2p+21',
  '6m7m8m4p4p5p6p7p3s4s4s5s5s6s+21',
  '3m4m5m6m7m8m6p7p4s5s6s2z2z8p+14',
  '4m4m6m7m8m2p3p4p7p8p9p6s7s+5s+22',
  '2m3m6m7m8m1p2p3p3p4p5p9s9s+1m+23',
  '3m3m7m7m3p3p2s2s3s3s6z6z7z+7z+24',
  '2m3m4m7m8m9m4s4s6s7s8s5z5z+5z+12',
  '6m6m8m8m9m9m2p2s2s4s4s8s8s+2p+24',
  '2m3m4m2p3p4p6p7p2s2s5s6s7s5p+23',
  '4m4m2p2p4p6p6p7p7p4s4s5s5s+4p+23',
  '1m2m3m5m6m7m5p7p5s6s7s6z6z6p+11',
  '4m5m3p4p5p7p8p9p1s2s3s9s9s+6m+13',
  '1m2m3m6m7m8m2p3p4p4s4s7s8s9s+13',
  '6m7m7m7m8m1p2p3p4s5s7s8s9s+6s+23',
  '3m4m5m6m7m8m3p4p5p3s3s7s7s+7s+13',
  '1m2m3m5m6m7m9m9m7p8p6s7s8s+6p+21',
  '1m2m3m5m6m7m9m9m1p2p3p7p8p9p+23',
  '3m4m5m5m6m7m6p8p2s2s2s7s7s7p+21',
  '2p3p5p6p7p7p8p9p2s3s4s4s4s+4p+14',
  '4m5m6m8m8m5p6p2s3s4s7s8s9s7p+11',
  '5m6m5p6p6p7p7p8p2s3s4s6s6s+4m+23',
  '4m5m6m6m7m8m2p3p4s4s5s6s7s4p+21',
  '3m4m6m7m8m3p4p5p2s2s6s7s8s5m+12',
  '7m7m8m9m9m8p8p8p9p9p9p7s7s+8m+11',
  '1m2m3m5p6p7p1s2s3s5s6s3z3z4s+22',
  '7m8m9m1p2p3p6p7p8p2s5z5z5z+2s+23',
  '5m5m5m2p3p4p5p6p7p6s6s8s8s+6s+21',
  '5m5m3p4p5p6p7p8p3s4s5s7s8s9s+22',
  '3m4m4m5m6m4p5p6p7p8p9p6s6s5m+14',
  '2m3m4m5m6m7m8m5p6p7p6s7s8s5m+13',
  '2m4m4m4m5m6m7m2s3s4s8s8s8s+2m+23',
  '6m7m7m7m8m2s3s3s4s4s6s6s6s+2s+11',
  '6m6m6m1p2p2p3p3p7p8p9p7s7s+1p+11',
  '7m8m7p8p9p5s5s5s4z4z6z6z6z9m+12',
  '5m6m8m8m1p2p3p5s6s6s7s7s8s+4m+12',
  '2m3m4m5m6m7m5p6p5s6s7s8s8s+4p+14',
  '1m2m3m3m4m5m5p6p6s7s8s3z3z4p+11',
  '1m2m3m4m5m8m8m2p3p4p3s4s5s+6m+13',
  '3m4m5m1p2p3p5p6p2s3s4s5s5s7p+13',
  '1m2m3m1p2p3p7p8p9p2s3s6z6z1s+13',
  '3p4p5p2s3s3s3s4s6s7s8s6z6z6z+11',
  '6p6p7p7p8p8p2s6s6s1z1z7z7z+2s+21',
  '5m6m7m4p5p6p2s2s2s4s6s8s8s5s+22',
  '1p2p3p4p5p6p7p8p9p2z2z4z4z+4z+14',
  '4m5m6m1p2p3p4p5p8p8p4s5s6s+3p+23',
  '4m5m6m1p1p1p2p3p3p4p4p5p5p3p+22',
  '1m2m3m6m7m5p6p7p1s1s6s7s8s+8m+12',
  '1m1m1m4m5m6m5p5p3s4s4s5s5s3s+11',
  '7m8m9m1p2p3p6p7p8p9s4z4z4z9s+14',
  '5m5m6m7m7m4p4p2s3s4s6s7s8s+6m+11',
  '1m1m3m4m5m5m6m7m8m9m6p7p8p7m+14',
  '2m2m4m5m6m6m7m8m5p6p7p4s4s+2m+12',
  '4m4m4p5p6p1s2s3s3s4s5s6s7s2s+11',
  '5m6m2p2p4p5p6p3s4s5s6s7s8s+4m+13',
  '1m2m3m5m6m3p4p5p9p9p1s2s3s+4m+23',
  '7m7m3p4p5p1s2s3s6s7s8s6z6z6z+21',
  '3m3m3m4m5m6m3p4p5p2s2s6s8s7s+13',
  '2m3m4m4p5p1s2s3s5s5s6s7s8s+3p+11',
  '3m4m5m6m7m3p3p4p5p6p7p8p9p+2m+21',
  '4m4m5m6m7m5p7p5s6s6s7s7s8s+6p+13',
  '2m3m4m6p7p2s2s3s3s4s4s8s8s8p+23',
  '7m8m9m7p8p9p4s4s5s5s6s6z6z6s+13',
  '6m7m8m6p6p7p8p8p1s1s3s4s5s+7p+12',
  '3m4m5m6m8m3p3p3p4p5p6p7p8p+7m+21',
  '2m3m4m4p5p5p6p6p2s3s4s3z3z4p+24',
  '2m3m4m4m7m8m9m2p3p4p3s4s5s+1m+11',
  '3m4m2p2p3p4p5p5p6p7p1s2s3s+2m+22',
  '5m5m7m8m9m2p3p3p4p4p7p8p9p2p+13',
  '4m5m1p1p4p5p6p2s3s4s7s8s9s3m+21',
  '1m2m3m4m5m3p4p5p6s6s7s8s9s+3m+22',
  '1m2m3m2p2p4p4p5p5p6p5s6s7s+3p+12',
  '2m3m4m5m6m6m7m7m9m9m7p8p9p+5m+12',
  '3m3m5m6m7m3p4p5p5p6p5s6s7s7p+14',
  '1m1m1p2p3p5p6p7p6s7s8s1z1z+1z+11',
  '5m6m7m7m8m4p4p6p7p8p5s6s7s+9m+12',
  '3m4m5m5m6m7m6p7p5s6s7s2z2z+8p+11',
  '1m2m3m5m6m7m2s3s4s6s7s9s9s+8s+21',
  '2m2m3m3m4m1p2p3p6p7p8p4s4s+1m+22',
  '5m6m7m7m7m7m2p4p5p5p4s5s6s3p+21',
  '2m3m4m4m4m6m7m3p4p5p4s5s6s+8m+12',
  '2m2m4m4m8m8m2p2p4p4p8p1z1z+8p+22',
  '4m4m1p1p1p2p4p2s2s3s3s4s4s3p+24',
  '3p4p5p7p8p9p1s2s3s4s5s8s8s+6s+11',
  '3p3p7p8p1s2s3s4s5s6s7s8s9s+9p+24',
  '1p2p3p6p7p8p1s2s3s5s6s8s8s+7s+11',
  '2m2m6m7m8m1p2p3p3s4s5s7s8s+6s+23',
  '1m2m3m4m5m5s5s5s5z5z6z6z6z6m+23',
  '3m4m6m7m8m2p3p4p5p5p1s2s3s5m+14',
  '2m3m4m7m7m6p7p8p2s2s6s7s8s+2s+12',
  '4m5m6m3p3p4p5p6p2s4s6s7s8s3s+12',
  '7m7m5p6p6p7p8p3s3s3s4s5s6s+4p+12',
  '1m2m3m5p6p7p3s4s5s3z3z6z6z+6z+12',
  '3m4m5m3p3p1s2s3s4s5s6s8s9s7s+21',
  '1p2p3p7p7p7p8p9p6s6s7s7s8s+5s+21',
  '4m5m6m5p5p5p3s5s5s5s7s7s7s3s+22',
  '6m6m5p6p7p8p9p2s3s4s5s6s7s+7p+14',
  '2m2m4m4m9p9p1s3s3s5z5z6z6z1s+22',
  '1m1m4m4m3p3p5p5p2s4s4s6z6z2s+23',
  '2m2m2m4m5m6m4p5p6p2s2s4s4s2s+11',
  '2m3m4m5m5m5m6m7m7p8p7s8s9s+6p+24',
  '1m2m3m4m5m3p4p5p7p8p9p3z3z3m+12',
  '6m7m4p5p6p9p9p5s6s7s7s8s9s+5m+14',
  '4m5m6m6m7m8m6p6p4s5s6s7s8s+6s+12',
  '1m1m2m3m4m5m6m5p6p7p5s6s7s+1m+22',
  '2p3p4p4p5p6p6p7p7p8p8p4z4z+9p+23',
  '2m2m4m4m5m6m6m7p8p9p6s7s8s+5m+13',
  '2m3m5m6m7m7m8m9m6p7p8p8p8p+1m+11',
  '3m4m5m1p2p3p5p6p7p5s6s4z4z7s+11',
  '1m2m3m6p7p8p4s5s6s6s6s7s8s+9s+13',
  '4m5m6m2p2p6p6p7p7p8p2s3s4s+8p+23',
  '3m4m5m7m8m9m1p2p3p2s3s7s7s+4s+22',
  '2m3m4m7m8m9m5p6p7p7s8s4z4z+9s+13',
  '1m1m6m7m8m1p2p3p7p8p9p4s5s6s+22',
  '1m2m3m7m8m9m5p6p7p7s8s3z3z6s+14',
  '3m3m3m4m4m4m3p4p6p6p5s5s5s+5p+21',
  '2p3p4p6p8p2s3s4s5s5s7z7z7z7p+13',
  '2m2m4m5m6m3p3p3p6p7p4s5s6s+5p+14',
  '1m2m3m3p3p4p5p6p4s5s6s3z3z+3z+23',
  '2m3m3m4m4m4p5p6p5s5s6s7s8s+2m+11',
  '3m3m1p2p3p3s4s5s7s8s9s7z7z+7z+14',
  '2m3m4m4m5m5m5m7m8m9m4s5s6s6m+21',
  '3m4m5m5m6m4p4p6p7p8p4s5s6s+4m+14',
  '4m5m5m6m6m9s9s5z5z6z6z7z7z4m+14',
  '4m4m4m2s2s3s3s3s6s7s7s8s8s6s+23',
  '2m3m4m2p3p4p6p7p7p7p8p3s4s5s+21',
  '3m3m5p6p7p1s2s3s4s5s6s7s8s6s+31',
  '2m3m2p3p4p1s2s3s5s5s6s7s8s+4m+22',
  '5m5m2p2p5p5p5p6p6p6p4s5s6s+2p+12',
  '3p4p5p6p7p8p2s3s7s8s9s2z2z+4s+14',
  '2m2m2m3m4m4p5p6p4s5s6s7z7z7z+21',
  '1m1m3m4m5m2p3p4p6p7p1s2s3s+8p+13',
  '2m2m5m6m6m7m7m8m4p5p7p8p9p+6p+13',
  '3m4m5m7m8m2p3p4p5s6s7s7s7s6m+21',
  '7m8m3p4p5p4s5s5s6s6s7s9s9s+6m+11',
  '4m5m6m6p7p1s2s3s4s4s7s8s9s8p+21',
  '5m6m7m7m8m9m4p5p5p6p7p4s4s3p+23',
  '4p5p5p5p6p7p8p9p4s5s6z6z6z6s+11',
  '7m8m1p2p3p1s2s3s5s6s7s9s9s+9m+22',
  '4m5m1p2p3p7p8p9p2s3s4s6s6s+6m+11',
  '2m3m4m6m7m8m1p2p3p1s1s5s6s4s+23',
  '3p4p5p5p6p7p7p8p9p9p9p7s8s+9s+11',
  '4m5m6m8m8m8m3p4p5p7p7p6s8s+7s+22',
  '4m5m9m9m6p7p8p2s3s4s6s7s8s3m+12',
  '4m4m1p2p3p6p7p8p5s6s7s4z4z+4z+14',
  '3m4m9m9m2p3p4p4p5p6p6s7s8s2m+14',
  '4m5p5p7p7p3s3s4s4s5s5s5z5z+4m+24',
  '3m4m5m2p4p6p6p2s3s4s7z7z7z+3p+23',
  '7m8m9m5p6p7p1s1s2s3s4s4s5s6s+21',
  '2m4m4m3p3p7p7p8p8p1s1s1z1z2m+22',
  '2m3m4m8p8p4s5s6s7s8s7z7z7z6s+12',
  '3m4m2p3p4p5p6p7p2s2s7s8s9s5m+14',
  '1m2m3m6m7m7m8m9m2p2p4p5p6p+8m+14',
  '5m5m6m6m9p2s2s5s5s8s8s9s9s9p+11',
  '2m3m4m6m6m7m8m9m3p4p2s3s4s5p+22',
  '1m2m3m4m5m6m6p7p3s4s5s7s7s5p+12',
  '2m2m2m5m5m1p1p2p2p3p3p2s3s1s+13',
  '2m3m7m7m2p3p4p4p5p6p5s6s7s+4m+23',
  '5m5m2p3p3p4p5p5p6p7p5s6s7s4p+21',
  '2m2m3m3m4m4m1p2p3p7p7p5s6s4s+14',
  '3p4p5p5p6p7p1s1s3s3s5z5z5z1s+12',
  '5m6m7m4p4p7p8p9p2s3s7s8s9s1s+14',
  '2m3m4m6m6m7m7m8m1p1p3s4s5s5m+14',
  '2m3m5m5m2p3p4p8p8p8p3s4s5s4m+22',
  '5m5m5m2p2p2p4s4s5s6s7s8s8s+8s+11',
  '3m4m5m6p7p8p3s4s5s5s6s7s8s+8s+11',
  '1m2m3m8m8m4p5p6p4s5s6s6s7s5s+12',
  '3m4m5m8m8m2p3p4p5p6p7p7s8s6s+21',
  '3m4m5m7m7m5p6p7p8p9p7s8s9s+4p+11',
  '4m5m6m2p3p6p7p8p7s7s7z7z7z4p+12',
  '3m4m5m7p8p9p1s1s6s7s7z7z7z8s+11',
  '1p2p2p3p3p4p4p5p6p7p7p3z3z+3z+21',
  '6m6m9m9m9m3p4p5s6s7s7z7z7z+5p+24',
  '4m5m6m7m8m9m3p3p2s3s5s6s7s4s+13',
  '5m6m7m7m8m5p6p7p7s8s9s1z1z+9m+23',
  '5p6p1s1s2s2s3s3s6s6s2z2z2z+4p+21',
  '3m3m4m5m6m2s3s4s5s6s7s8s9s4s+22',
  '1m2m3m3p3p4p4p5p1z1z1z4z4z+2p+13',
  '7m7m8m8m9m9m5p5p1s1s4s4s6s+6s+11',
  '4m4m5m5m3p3p3s3s4s4s9s9s5z5z+14',
  '1m2m3m3m4m5m6m6m3p4p5p3s5s+4s+11',
  '6m7m8m9m9m2p3p4p4p5p6p7z7z+7z+23',
  '7m8m9m2p3p4p7p8p9p3s3s6s7s+5s+14',
  '3m4m5m2p3p4p4s5s6s7s8s4z4z+9s+11',
  '1m2m3m4m5m9m9m4p5p6p7p8p9p+6m+12',
  '9m9m6p7p7p8p8p9p5s6s7s5z5z+5z+21',
  '2m3m4m7m8m1p2p3p3s4s5s2z2z9m+11',
  '3m4m5m7m7m3p4p5p1s2s3s4s5s+6s+12',
  '1m2m3m5m6m7m8m9m1p2p3p4z4z7m+13',
  '1p1p1p3p4p5p6p6s6s6s4z4z4z+2p+24',
  '1m1m4m5m5m6m7m2s2s3s3s4s4s+6m+11',
  '6m6m7m7m8m1p2p3p6p7p8p4s4s+5m+14',
  '5m5m3p4p5p9p9p4s5s6s7z7z7z9p+14',
  '2m4m7m7m7m2s3s4s7s7s7s8s8s3m+14',
  '3m4m5m7m8m9m5s6s7s1z6z6z6z1z+11',
  '2m3m7m7m5p6p7p2s3s4s5s6s7s+4m+12',
  '4m5m6m7m7m7m8m2p3p4p5p6p7p+9m+21',
  '8m8m8m3p4p5p6p7p3s3s5s6s7s+5p+12',
  '3m4m5m5m5m5p6p7p5s6s7s1z1z+1z+14',
  '2m3m4m7m8m9m7p8p2s2s3s4s5s+9p+24',
  '1m1m4m5m6m1p2p3p7p8p1s2s3s+9p+24',
  '5m5m7m8m4p5p6p5s6s7s6z6z6z+9m+22',
  '3m3m3m5m6m6m6m7m4p5p4s5s6s+3p+14',
  '4m5m6m9m9m2p3p2s3s4s5s6s7s4p+14',
  '4m5m6m8m8m5p6p7p5s6s7s7s8s6s+22',
  '1m2m3m4m4m5m6m7m7p8p7s8s9s9p+22',
  '7m7m2p3p3p4p5p5s6s7s7s8s9s+1p+22',
  '6m7m8m2p2p3p4p5p4s5s7s8s9s+6s+23',
  '5m5m5m8m8m2p3p3s3s4s4s5s5s+4p+12',
  '2m3m4m1p2p3p7p8p4s4s6s7s8s+9p+21',
  '7m7m7m2p2p2p3s3s3s4s5s7z7z+7z+12',
  '2m2m2m3m4m2p3p4p5p6p4s5s6s7p+11',
  '6m6m7m8m9m7p9p1s1s2s2s3s3s8p+21',
  '3m3m4m4m5m5m5p6p7p7p8p6s6s+9p+22',
  '2m3m3m4m5m5p6p7p7s7s7s8s9s4m+23',
  '3m3m3m2p3p4p6p7p4s4s5s6s7s+8p+13',
  '2m3m5m6m7m1p2p3p9p9p4s5s6s+1m+12',
  '2m3m7m8m9m2p2p7p8p9p6s7s8s+1m+21',
  '1m2m3m6m7m1p1p4s5s6s7s8s9s8m+11',
  '2m3m3m4m4m5m4p5p1s1s2s3s4s+3p+22',
  '2m2m7p7p9p9p9s9s5z6z6z7z7z+5z+24',
  '3m4m5m6m6m7p8p3s4s5s7s8s9s+6p+14',
  '2m3m4m6p7p1s1s5s6s7s7z7z7z5p+21',
  '6m7m7m7m8m2p3p4p6p7p7s8s9s5p+22',
  '1m1m2m3m4m2s3s4s5s6s5z5z5z+7s+13',
  '4m5m6m2p3p6p6p3s4s5s6s7s8s4p+14',
  '3m3m2p3p4p5p6p6p6p6p7p3s5s+4s+12',
  '4m5m6m9m9m5p6p2s3s4s4s5s6s+7p+13',
  '2m3m4m9m9m7p8p1s2s3s3s4s5s+9p+11',
  '2m2m5m6m7m4p5p6p1s2s3s5s6s7s+22',
  '3m4m5m8p8p5s7s7s8s9s6z6z6z6s+11',
  '2m4m6m6m7m8m9m4p5p6p7z7z7z+3m+21',
  '1m1m1p2p3p4p5p6p7p8p9p7s8s+9s+13',
  '7m8m9m1p2p3p6p6p3s3s4s4s5s2s+23',
  '6m7m8m8m9m2p2p6s7s8s7z7z7z+7m+21',
  '2p3p4p4p5p6p6p6p6p3s4s7s7s+5s+21',
  '2m4m6m7m8m6p7p8p8p8p6s7s8s3m+24',
  '4m4m7m8m9m7p8p1s2s3s4s5s6s6p+13',
  '3m4m5m3p4p5p6p7p8p2s2s4s5s+3s+22',
  '3m3m4p5p6p7p8p2s3s4s6s7s8s+3p+12',
  '5m5m1p2p3p6p7p3s4s5s6s7s8s+8p+11',
  '2m2m3m4m5m5m6m7m7m8m9m3s4s+2s+13',
  '2m3m4m7m8m9m3p4p5p9p9p5s6s7s+14',
  '6m7m8m3p4p5p8p8p2s3s4s6s7s5s+21',
  '3m4m5m5p6p7p7p8p9p6s7s4z4z+5s+12',
  '3m4m6m7m8m2p3p4p3s3s6s7s8s5m+14',
  '7m8m1p2p2p3p3p4p2s3s4s9s9s+9m+21',
  '8m8m8m1p2p3p9p9p9p8s8s5z5z+5z+23',
  '2m3m4m6m7m8m3p4p8p8p6s7s8s5p+11',
  '2p3p4p6p7p8p2s3s3s4s5s8s8s4s+11',
  '2m3m4m1p1p4p5p7p8p9p1s2s3s3p+11',
  '2m3m4m5m5p5p6p6p7p7p4s5s6s2m+21',
  '1m1m2m2m3m2p3p4p2s2s5s6s7s+3m+14',
  '3m3m3m7m7m7m4p6p2s2s6s7s8s5p+13',
  '7m8m9m1p2p3p3p4p5p4s4s6s7s+8s+22',
  '2m3m3m3m4m6m7m8m4p5p2s3s4s+3p+24',
  '3m4m5m1p1p3p4p5p3s4s5s7s8s9s+12',
  '4m5m6m1p2p3p4p4p2s3s4s6s7s+8s+11',
  '1m1m4m5m6m1p2p3p5s5s6s7s7s+6s+11',
  '4m5m7m8m9m5p5p7p8p9p6s7s8s3m+12',
  '7m7m4p5p7p8p9p2s3s4s7s8s9s+3p+12',
  '2m3m4m4m5m4p4p6p7p8p4s5s6s3m+11',
  '7m7m7m3p3p3p6p7p3s3s3s6s6s+8p+13',
  '2m2m3m3m4m2p3p4p2s3s4s6s6s1m+22',
  '1m1m7m7m4p4p6s6s9s9s1z1z5z5z+12',
  '1m1m2m3m3m9m9m7p8p9p1s2s3s+2m+11',
  '4m5m6m2p3p4p5p6p4s5s6s7s7s+1p+11',
  '2m2m3m3m4m4m5m6m7m7p7p7s8s+9s+11',
  '6m7m8m3p3p5p6p7p5s6s7s5z5z5z+24',
  '2m3m4m6m7m8m1s1s2s3s4s6s7s5s+22',
  '1m2m2m3m3m6m7m8m6p6p6s7s8s+1m+24',
  '6m7m8m3p4p5p6p7p8p5s1z1z1z5s+11',
  '2m3m4m2p3p1s2s3s9s9s2z2z2z+4p+24',
  '2m2m7m7m2p2p3p3p7p9p9p1z1z7p+11',
  '1m2m3m5m5m6m6m7m7m8m9m3z3z+4m+12',
  '3m4m5m6m7m7m7m4p5p6p1s2s3s5m+23',
  '1m2m3m5m6m1s2s3s6s7s7s7s8s+7m+12',
  '2m3m4m8m8m6p6p7s8s9s6z6z6z6p+21',
  '1m1m3m4m5m3p4p4p5p5p6p6p7p+8p+11',
  '4m5m6m6m6m7m7m7m3p4p6s7s8s5p+23',
  '1m2m3m6m6m3p4p6p7p8p3s4s5s+5p+11',
  '5m6m6m7m7m8m8p8p2s3s6s7s8s+4s+13',
  '2p2p3p4p5p8p8p4s5s5s6s6s7s+8p+11',
  '1m2m3m5m6m5p6p7p3s3s4s5s6s+7m+23',
  '1m2m3m4m5m6m4p5p6p4s6s4z4z+5s+12',
  '2m3m4m4m5m6m4p5p6s6s7s8s9s+3p+11',
  '3m4m5m3p4p5p7p7p3s3s4s5s6s+3s+24',
  '2m2m5m6m7m1p3p3s3s3s2z2z2z+2p+22',
  '4m4m4m5m6m6m7m7m8m2p3p4p8p+8p+12',
  '2m3m4m7m7m4p5p2s3s4s6s7s8s6p+24',
  '2m3m4p4p5p6p7p2s3s4s4s5s6s+1m+23',
  '1m2m3m3p4p5p6p7p8p3s4s4s4s2s+11',
  '3m4m5m3p4p7p7p4s5s6s7s8s9s+2p+22',
  '3m3m5m6m7m7m8m3p4p5p5s6s7s6m+11',
  '3m3m5m6m4p5p6p2s3s4s6s7s8s+4m+14',
  '6m6m8m8m1p3p3p8p8p3s3s1z1z+1p+23',
  '1m1m6p6p7p7p8s8s9s2z2z5z5z+9s+11',
  '2m3m1p2p3p4p4p7p8p9p4s5s6s4m+23',
  '5m6m7m1p2p3p7p8p9p2s3s2z2z1s+11',
  '1m2m3m4m4m6m8m1p2p3p1s2s3s7m+21',
  '3m4m5m6m6m3p4p5p2s3s4s6s7s8s+24',
  '2m3m4m6m7m8m6p7p8p3s5s7s7s+4s+24',
  '2m2m2m5m5m3p3p3p3s3s4s5s5s4s+13',
]
